import React from 'react';
import { NamedLink, IconAccountManaged } from '../../components';
import config from '../../config';

import css from './ListingPage.css';

const SectionHeading = props => {
  const {
    richTitle,
    sports
  } = props;

  return (
    <div className={css.section}>
      <div className={css.heading}>
        <h1 className={css.title}>{richTitle}</h1>
      </div>
      <div className={css.sportsLinksContainer}>
        {/* <IconAccountManaged className={css.managedAccount}/> */}
        {
          sports && sports.map((s, i) => (
            <NamedLink key={i} className={css.sportsLink} name="SearchPage">{config.sportsConfig[s].name}</NamedLink>
          ))
        }
      </div>
    </div>
  );
};

export default SectionHeading;
