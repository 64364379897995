import React, { useEffect, useMemo } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import Switcher from '../../components/Switcher/Switcher';
import { useForm } from 'react-hook-form';
import { useFormContext } from '../context';
import { useSwitcher } from '../../components/Switcher/useSwitcher';
import { getComponents } from './utils';
import css from './style.css';
import { addEvent, addEventDate, updateEvent } from '../api';
import RequestStatusModal from '../RequestStatusModal';

const GeneralInfo = () => {
  const [updateSucces, setUpdateSucces] = React.useState(null);
  const { updateFormData, isLastStep, formData, type, listingId } = useFormContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    watch,
  } = useForm({
    values: { ...formData.generalInfo, files: formData.generalInfo.files },
  });

  const eventTypeSwitcher = useSwitcher(
    ['Wydarzenie', 'Biletowanie'],
    formData.generalInfo.isEvent ? 'Wydarzenie' : 'Biletowanie'
  );

  useEffect(() => {
    updateFormData({ isEvent: eventTypeSwitcher.selected === 'Wydarzenie' }, 'isEvent');
  }, [eventTypeSwitcher.selected]);

  const content = getComponents(
    eventTypeSwitcher.selected,
    register,
    errors,
    control,
    setValue,
    watch
  );

  const onSubmit = async data => {
    let filesIds = [];
    if (
      data.files &&
      (typeof data.files === 'string' ? JSON.parse(data.files) : data.files).some(
        file => typeof file.id === 'object'
      )
    ) {
      filesIds = (typeof data.files === 'string' ? JSON.parse(data.files) : data.files)
        .filter(file => file.id.uuid)
        .map(file => file.id.uuid);
    }

    updateFormData(data, 'generalInfo');

    const saveData = {
      description: data.description,
      title: data.eventTitle,
      price: Number(data.price) * 100 || 0,
      images: data.files || [],
      paymentEnabled: data.paymentEnabled,
      reducedPrice: Number(data.discountPrice) * 100 || 0,
      publicData: {
        // priceWithDiscount: data.discountPrice,
        event_type: data.eventType || 'Mecze',
        sport: data.sport,
        isEvent: eventTypeSwitcher.selected === 'Wydarzenie',
        selectedDate: data.selectedDate,
        stadium: data.stadium,
      },
    };

    if (type === 'edit' || listingId !== '00000000-0000-0000-0000-000000000000') {
      const updateStatus = await updateEvent({ ...saveData, id: listingId }, filesIds);
      updateStatus === 'success' ? setUpdateSucces('success') : setUpdateSucces('error');
    } else {
      addEvent(saveData);
    }
  };

  const submitButtonLabel = useMemo(
    () => (isLastStep ? 'Opublikuj' : type === 'edit' ? 'Zapisz' : 'Dalej'),
    [isLastStep, type]
  );

  const listingTitle = getValues('eventTitle');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {type === 'edit' && updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => setUpdateSucces(null)}
          listingId={listingId}
          slug={listingTitle}
        />
      )}

      <div className={css.container}>
        <h1 className={css.header}>
          <LeftArrowIcon />
          Tworzenie oferty
        </h1>

        <Switcher {...eventTypeSwitcher} />

        {content.map((component, index) => (
          <div key={index}>{component}</div>
        ))}
      </div>
      <div className={css.buttonContainer}>
        <button type="submit" className={css.submitClasses}>
          {submitButtonLabel}
        </button>
      </div>
    </form>
  );
};

export default GeneralInfo;
