import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { sdkGo } from '../../queries/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/ProfilePage/SET_INITIAL_STATE';

export const SHOW_USER_REQUEST = 'app/ProfilePage/SHOW_USER_REQUEST';
export const SHOW_USER_SUCCESS = 'app/ProfilePage/SHOW_USER_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const QUERY_LISTINGS_REQUEST = 'app/ProfilePage/QUERY_LISTINGS_REQUEST';
export const QUERY_LISTINGS_SUCCESS = 'app/ProfilePage/QUERY_LISTINGS_SUCCESS';
export const QUERY_LISTINGS_ERROR = 'app/ProfilePage/QUERY_LISTINGS_ERROR';

export const QUERY_REVIEWS_REQUEST = 'app/ProfilePage/QUERY_REVIEWS_REQUEST';
export const QUERY_REVIEWS_SUCCESS = 'app/ProfilePage/QUERY_REVIEWS_SUCCESS';
export const QUERY_REVIEWS_ERROR = 'app/ProfilePage/QUERY_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  userId: null,
  userListingRefs: [],
  userShowError: null,
  queryListingsError: null,
  reviews: [],
  queryReviewsError: null,
};

export default function profilePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SHOW_USER_REQUEST:
      return { ...state, userShowError: null, userId: payload.userId };
    case SHOW_USER_SUCCESS:
      return state;
    case SHOW_USER_ERROR:
      return { ...state, userShowError: payload };

    case QUERY_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_LISTINGS_SUCCESS:
      return { ...state, userListingRefs: payload.listingRefs };
    case QUERY_LISTINGS_ERROR:
      return { ...state, userListingRefs: [], queryListingsError: payload };
    case QUERY_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null };
    case QUERY_REVIEWS_SUCCESS:
      return { ...state, reviews: payload };
    case QUERY_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const showUserRequest = userId => ({
  type: SHOW_USER_REQUEST,
  payload: { userId },
});

export const showUserSuccess = () => ({
  type: SHOW_USER_SUCCESS,
});

export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});

export const queryListingsRequest = userId => ({
  type: QUERY_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryListingsSuccess = listingRefs => ({
  type: QUERY_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const queryListingsError = e => ({
  type: QUERY_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryReviewsRequest = () => ({
  type: QUERY_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: QUERY_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: QUERY_REVIEWS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryUserListings = userId => (dispatch, getState, sdk) => {
  dispatch(queryListingsRequest(userId));
  return (
    sdkGo.listings
      .query({
        // own: true,
        authorId: userId.uuid,
        include: ['author', 'images', 'dates'],
      })
      //  sdk.listings
      //   .query({
      //     author_id: userId,
      //     pub_type: 'event',
      //     include: ['author', 'images'],
      //     'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      //   })
      .then(response => {
        // Pick only the id and type properties from the response listings
        const listingRefs = response.data; //.map(({ id, type }) => ({ id, type }));
        const formatedData = listingRefs.data.reduce((acc, item) => {
          const datesPerEventId = item.relationships.dates.data.map(date => date.id.uuid);
          const datesPerEvent = listingRefs.included.filter(
            item => item.type === 'listing-date' && datesPerEventId.includes(item.id.uuid)
          );
          const firstPossibleEventDate = datesPerEvent
            .filter(date => new Date(date.attributes.date) >= new Date())
            .sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date))[0];
          const mainImagePerEventId = item.relationships.images.data[0].id.uuid;
          const mainImage = listingRefs.included.filter(
            item => item.type === 'image' && item.id.uuid === mainImagePerEventId
          )[0];
          return firstPossibleEventDate
            ? [
                ...acc,
                {
                  ...item.attributes,
                  id: item.id.uuid,
                  isMultiDates: datesPerEvent.length > 1,
                  image: mainImage,
                  eventDate: firstPossibleEventDate,
                },
              ]
            : acc;
        }, []);
        dispatch(addMarketplaceEntities(response));
        dispatch(queryListingsSuccess(formatedData));
        return response;
      })
      .catch(e => dispatch(queryListingsError(storableError(e))))
  );
};

export const queryUserReviews = userId => (dispatch, getState, sdk) => {
  sdkGo.reviews
    .query({
      providerId: userId.uuid,
      // state: 'public',
      include: ['author'],
      //  'author.profileImage'],
      // 'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(response => {
      const reviews = denormalisedResponseEntities(response);
      dispatch(queryReviewsSuccess(reviews));
    })
    .catch(e => dispatch(queryReviewsError(e)));
};

export const showUser = userId => (dispatch, getState, sdk) => {
  dispatch(showUserRequest(userId));
  return (
    sdkGo.users
      .show({
        id: userId.uuid,
        include: ['image'],
      })
      //  sdk.users
      //   .show({
      //     id: userId,
      //     include: ['profileImage'],
      //     'fields.image': ['variants.square-small', 'variants.square-small2x'],
      //   })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        dispatch(showUserSuccess());
        return response;
      })
      .catch(e => dispatch(showUserError(storableError(e))))
  );
};

export const loadData = userId => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return Promise.all([
    dispatch(fetchCurrentUser()),
    dispatch(showUser(userId)),
    dispatch(queryUserListings(userId)),
    dispatch(queryUserReviews(userId)),
  ]);
};
