import React,{ useState } from 'react';
import classNames from 'classnames';
import searchIcon from './searchIcon.svg'
import css from './SearchInput.css';

const KEY_CODE_ENTER = 13;

SearchInput.defaultProps = {
  onSubmit: () => {}
}

function SearchInput({ placeholder, onSubmit, className }) {
  const [searchValue, setSearchValue] = useState('')

  const handleSearchInputChange = event => {
    setSearchValue(event.target.value)
  }
  const onKeyUp = event => {
    if (event.keyCode === KEY_CODE_ENTER && searchValue) {
      onSubmit(searchValue)
    }
  }
  
  const handleSearchSubmit = () => {
    onSubmit(searchValue)
  }
  
  return (
    <div className={classNames(css.searchInputWrapper, className)}>
      <input
        className={css.searchInput}
        name="searchValue"
        placeholder={placeholder}
        type="text"
        value={searchValue}
        onChange={handleSearchInputChange}
        onKeyUp={onKeyUp} />
      <button disabled={!searchValue} onClick={handleSearchSubmit} type="button" className={css.searchButton}>
        <img src={searchIcon} alt="searchIcon" />
      </button>
    </div>
  )
}

export default SearchInput