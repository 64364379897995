import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FormattedMessage } from 'react-intl';

import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldCheckboxGroup, Form } from '../../components';
import { maxLength, required, requiredFieldArrayCheckbox, composeValidators } from '../../util/validators';
import { FieldTextInput } from '../../components';

import css from './EditListingFeaturesForm.css';

const EditListingFeaturesFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={fieldRenderProps => {
      const {
        disabled,
        rootClassName,
        className,
        name,
        handleSubmit,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = fieldRenderProps;

      const classes = classNames(rootClassName || css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.updateFailed" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingFeaturesForm.showListingFailed" />
        </p>
      ) : null;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}

          <FieldCheckboxGroup
            className={css.features}
            id="levels"
            name="levels"
            label="Poziom"
            options={config.custom.sportLevels}
          />
          <FieldCheckboxGroup
            className={css.features}
            id="gender"
            name="gender"
            label="Dla kogo?"
            options={config.custom.gender}
            minLenght={1}
            validate={composeValidators(requiredFieldArrayCheckbox("Pole jest wymagane"))}
          />
          <FieldTextInput
            id="level_desc"
            name="level_desc"
            className={css.textInput}
            type="textarea"
            label="Dodatkowy opis poziomu (opcjonalnie)"
            placeholder="Podaj przykładowe potrzebne umiejętności"
          // validate={composeValidators(required("Wymagane jest podanie organizatora wydarzenia"))}
          />

          <FieldTextInput
            id="min_age"
            name="min_age"
            className={css.textInput}
            type="number"
            label="Dolna granica wieku (opcjonalnie)"
            placeholder="Dolna granica wieku"
          // validate={composeValidators(required("Wymagane jest podanie organizatora wydarzenia"))}
          />

          <FieldTextInput
            id="max_age"
            name="max_age"
            className={css.textInput}
            type="number"
            label="Górna granica wieku (opcjonalnie)"
            placeholder="Górna granica wieku"
          // validate={composeValidators(required("Wymagane jest podanie organizatora wydarzenia"))}
          />

          <FieldTextInput
            id="age_desc"
            name="age_desc"
            className={css.textInput}
            type="textarea"
            label="Dodatkowy opis wieku (opcjonalnie)"
            placeholder="Podaj dodatkowe warunki dotyczące wieku"
          // validate={composeValidators(required("Wymagane jest podanie organizatora wydarzenia"))}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingFeaturesFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
};

EditListingFeaturesFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

const EditListingFeaturesForm = EditListingFeaturesFormComponent;

export default EditListingFeaturesForm;
