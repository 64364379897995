import React from 'react';
import { node, object, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import { IconCheckmark } from '../../components';

import css from './FieldCheckboxWithDesc.css';

const FieldCheckboxWithDescComponent = props => {
  const { rootClassName, className, id, option, ...rest } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  const { checkboxComponent, desc, label } = option;

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <div className={css.checkboxWrapper}>
          <IconCheckmark className={css.checked} />
          <span className={css.box}>{checkboxComponent()}</span>
        </div>
        <div className={css.text}>
          <div className={css.textLabel}>{ label }</div>
          <div className={css.desc}>{ desc }</div>
        </div>
      </label>
    </span>
  );
};

FieldCheckboxWithDescComponent.defaultProps = {
  className: null,
  rootClassName: null,
  label: null,
};

FieldCheckboxWithDescComponent.propTypes = {
  className: string,
  rootClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  option: object.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxWithDescComponent;
