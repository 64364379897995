import PropTypes from 'prop-types';
import React from 'react';

import css from './Skeleton.css';

function Skeleton({ children }) {
  return (
    <div className={css.skeleton}>
      {children}
      <span className={css.shine}></span>
    </div>
  );
}

const { node } = PropTypes;

Skeleton.propTypes = {
  children: node,
};

export default Skeleton;
