import React, { useEffect, useState } from "react";

export const useSwitcher = (options, option) => {
    const [selected, setSelected] = useState(option || options[0]);

    useEffect(() => {
        setSelected(option || options[0]);
    }, [option])
    const toggleSelected = () => {
        const newIndex = options.indexOf(selected) === 0 ? 1 : 0;
        setSelected(options[newIndex]);
    };

    return { selected, toggleSelected, options };
}