import React from 'react';
import css from './TrainerOfTheMonth.css';

function TrainerOfTheMonthSection() {
  return (
    <section className={css.section}>
      <div className={css.textContent}>
        <h2 className={css.title}>Dołącz do drużyny Decathlon GO</h2>
        <p className={css.desc}>Nie możesz prowadzić treningów personalnych? Dotrzyj do odbiorców tam, gdzie są - w domu. Teraz dzięki Decathlon GO możesz w łatwy sposób organizować treningi online i zwiększyć liczbę użytkowników. Sprawdź jakie to proste!</p>
        <div className={css.linksWrapper}>
          <div className={css.linkContainer}>
            <a href="https://partner.decathlon.pl" target="_blank" className={css.link}>Dołącz do Decathlon GO</a>
          </div>
        </div>
      </div>
      <div className={css.imgWrapper} />
    </section>
  )
}

export default TrainerOfTheMonthSection