import React from 'react';
import { ReactComponent as LocalizationIcon } from '../../../../assets/listingPage/localization.svg';
import { ReactComponent as PeopleIcon } from '../../../../assets/listingPage/people.svg';
import { ReactComponent as WalkIcon } from '../../../../assets/listingPage/walk.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/listingPage/calendar.svg';
import InfoItem from './InfoItem.js';
import css from '../styles.css';
import { sportsConfig } from '../../../../sportsConfig.js';
import AddressFromCoordinates from '../../../../components/Map/AddressFromCoordinates.js';

const checkArray = numbers => {
  if (numbers.length === 0) return [];

  const firstNumber = numbers[0];
  const allSame = numbers.every(num => num === firstNumber);
  if (allSame) {
    return firstNumber ? `${firstNumber}` : '0';
  } else {
    const minNumber = Math.min(...numbers);
    const maxNumber = Math.max(...numbers);
    return `${minNumber} - ${maxNumber}`;
  }
};

const getEventDuration = dates => {
  const durations = dates.map(item => item.attributes.duration);

  return checkArray(durations);
};

const gender = data =>
  data.data.attributes.publicData.gender &&
  data.data.attributes.publicData.gender
    .map(item => {
      switch (item) {
        case 'male':
          return 'Mężczyzna';
        case 'female':
          return 'Kobieta';
        case 'children':
          return 'Dziecko';
      }
    })
    .join(', ');

const GeneralInfo = ({ data, isEvent }) => {
  const flexDate = data.data.attributes.publicData.flex_date;
  return (
    <section className={css.infoItemContainer}>
      <InfoItem
        icon={<LocalizationIcon />}
        title={
          isEvent ? (
            <AddressFromCoordinates
              lat={data.data.attributes.geolocation.lat}
              lng={data.data.attributes.geolocation.lng}
            />
          ) : (
            <p>{data.data.attributes.publicData.stadium.split('_')[1]}</p>
          )
        }
        description="Adres"
      />

      <InfoItem
        icon={<CalendarIcon />}
        title={
          !flexDate
            ? `${getEventDuration(data.included.filter(item => item.type === 'listing-date'))} min`
            : ''
        }
        description={flexDate ? flexDate.split(',').map(item => <p>{item}</p>) : 'Czas trwania'}
      />
      {isEvent && <InfoItem icon={<PeopleIcon />} title={gender(data)} description="Dla kogo" />}
      <InfoItem
        icon={<WalkIcon />}
        title={sportsConfig[data.data.attributes.publicData.sport].name}
        description="Dyscyplina sportu"
      />
    </section>
  );
};

export default GeneralInfo;
