import React from 'react';
import { StaticPage, TopbarContainer } from '..';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import config from '../../config';
import StepsSection from './StepsSection';
import steps from './steps.js';

import FooterV2 from '../../components/Footer/FooterV2.js';
import css from './HowToPage.css';

const PartnersPage = () => {
  const { siteTwitterHandle } = config;

  return (
    <StaticPage
      title="Jak opisać perfekcyjne wydarzenie w Decathlon GO?"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description:
          'Zobacz nasze wskazówki i przykłady, które pomogą Ci stworzyć idealne wydarzenie | Decathlon GO',
        name: 'Stwórz perfekcyjne wydarzenie w Decathlon GO',
      }}
    >
      <LayoutSingleColumn className={css.partnersPageWrapper}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.headerSection}>
            <h1>Jak dodać idealne wydarzenie sportowe w Decathlon GO?</h1>
            <p>
              Przeczytaj nasze wskazówki i przykłady dotyczące tworzenia wydarzenia sportowego na
              naszej platformie. Pomogą Ci stworzyć idealne wydarzenie sportowe. Dzięki nim o Twoim
              wydarzeniu dowie się zdecydowanie więcej potencjalnych uczestników. Jeżeli
              organizujesz trening, rozgrywki sportowe, turnieje, czy biegi koniecznie sprawdź co
              dla Ciebie przygotowaliśmy.
            </p>
          </div>
          <StepsSection
            header={steps[0].header}
            stepId={steps[0].id}
            mainImg={steps[0].mainImg}
            mainImgBig={steps[0].mainImgBig}
            steps={steps[0].steps}
          />
          <StepsSection
            header={steps[1].header}
            stepId={steps[1].id}
            mainImg={steps[1].mainImg}
            mainImgBig={steps[1].mainImgBig}
            steps={steps[1].steps}
          />
          <StepsSection
            header={steps[2].header}
            stepId={steps[2].id}
            mainImg={steps[2].mainImg}
            mainImgBig={steps[2].mainImgBig}
            steps={steps[2].steps}
          />
          <StepsSection
            header={steps[3].header}
            stepId={steps[3].id}
            mainImg={steps[3].mainImg}
            mainImgBig={steps[3].mainImgBig}
            steps={steps[3].steps}
          />
          <StepsSection
            header={steps[4].header}
            stepId={steps[4].id}
            mainImg={steps[4].mainImg}
            mainImgBig={steps[4].mainImgBig}
            steps={steps[4].steps}
          />
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <FooterV2 />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default PartnersPage;
