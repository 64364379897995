import GeneralPromo from './images/generalPromo.png';
import GeneralPromoBig from './images/generalPromo@2x.png';
import TennisPromo from './images/tennisPromo.png';
import TennisPromoBig from './images/tennisPromo@2x.png';
import TableTennisPromo from './images/tabletennisPromo.png';
import TableTennisPromoBig from './images/tabletennisPromo@2x.png';
import BadmintonPromo from './images/badmintonPromo.png';
import BadmintonPromoBig from './images/badmintonPromo@2x.png';
import SquashPromo from './images/squashPromo.png';
import SquashPromoBig from './images/squashPromo@2x.png';

export const sportsContent = {
  general: {
    className: 'generalBackground',
    pSportsName: '',
    pSportName: 'sportu',
    bgClassName: 'generalBg',
    imgSrc: GeneralPromo,
    imgSrcRetina: `${GeneralPromo}, ${GeneralPromoBig} 2x`
  },
  tennis: {
    className: 'tennisBackground',
    pSportsName: 'tenisa ziemnego',
    pSportName: 'tenisa ziemnego',
    bgClassName: 'tennisBg',
    imgSrc: TennisPromo,
    imgSrcRetina: `${TennisPromo}, ${TennisPromoBig} 2x`
  },
  tabletennis: {
    className: 'tableTennisBackground',
    pSportsName: 'tenisa stołowego',
    pSportName: 'tenisa stołowego',
    bgClassName: 'tabletennisBg',
    imgSrc: TableTennisPromo,
    imgSrcRetina: `${TableTennisPromo}, ${TableTennisPromoBig} 2x`
  },
  badminton: {
    className: 'badmintonBackground',
    pSportsName: 'badmintona',
    pSportName: 'badmintona',
    bgClassName: 'badmintonBg',
    imgSrc: BadmintonPromo,
    imgSrcRetina: `${BadmintonPromo}, ${BadmintonPromoBig} 2x`
  },
  squash: {
    className: 'squashBackground',
    pSportsName: 'squasha',
    pSportName: 'squasha',
    bgClassName: 'squashBg',
    imgSrc: SquashPromo,
    imgSrcRetina: `${SquashPromo}, ${SquashPromoBig} 2x`
  }
}