import React from 'react';
import useImageGallery from './useImageGallery';
import css from './ImageGallery.css';

const ImageGallery = ({ images }) => {
    const {
        modalIsOpen,
        displayedImageIndex,
        modalRef,
        openModal,
        closeModal,
        moveBack,
        moveForward,
    } = useImageGallery(images);

    const renderImages = () => {
        switch (images.length) {
            case 1:
                return <div onClick={() => openModal(0)} style={{ backgroundImage: `url(${images[0]})` }} className={css.singleImage} />
            case 2:
                return (
                    <div className={css.manyImagesContainer}>
                        <div onClick={() => openModal(0)} style={{ backgroundImage: `url(${images[0]})` }} className={css.singleImage} />

                        <button onClick={() => openModal(0)} className={css.seeMoreButton}>Zobacz więcej zdjęć</button>
                    </div>
                );
            case 3:
                return (
                    <div >
                        <div onClick={() => openModal(0)} style={{ backgroundImage: `url(${images[0]})` }} className={css.singleImage} />

                        <div className={css.twoImages}>
                            <img onClick={() => openModal(1)} src={images[1]} alt="Image 2" className={css.halfImage} />
                            <img onClick={() => openModal(2)} src={images[2]} alt="Image 3" className={css.halfImage} />
                        </div>
                    </div>
                );
            default:
                return (
                    <div className={css.manyImagesContainer}>
                        <div onClick={() => openModal(0)} style={{ backgroundImage: `url(${images[0]})` }} className={css.singleImage} />

                        <div className={css.twoImages}>
                            <img onClick={() => openModal(1)} src={images[1]} alt="Image 2" className={css.halfImage} />
                            <img onClick={() => openModal(2)} src={images[2]} alt="Image 3" className={css.halfImage} />
                        </div>
                        <button onClick={() => openModal(0)} className={css.seeMoreButton}>Zobacz więcej zdjęć</button>
                    </div>
                );
        }
    };

    return (
        <div>
            {renderImages()}
            {modalIsOpen && (
                <div
                    className={css.modalOverlay}
                    onClick={closeModal}
                    ref={modalRef}
                    tabIndex={-1}
                >
                    <button onClick={moveBack} className={css.moveButton}>{`<`}</button>

                    <div className={css.modalContent} onClick={(e) => e.stopPropagation()}>
                        <button onClick={closeModal} className={css.closeButton}>x</button>

                        <div className={css.modalSlider}>
                            {images.filter((image, index) => index === displayedImageIndex).map((image, index) => (
                                <div key={index} className={css.modalSlide}>
                                    <img src={image} alt={`Image ${index + 1}`} className={css.modalImage} />
                                </div>
                            ))}
                        </div>
                    </div>

                    <button onClick={moveForward} className={css.moveButton}>{`>`}</button>
                </div>
            )}
        </div>
    );
};

export default ImageGallery;
