import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { parse } from '../../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_CONSOLE_REVIEWS_REQUEST = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_REQUEST';
export const FETCH_CONSOLE_REVIEWS_SUCCESS = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_SUCCESS';
export const FETCH_CONSOLE_REVIEWS_ERROR = 'app/ConsoleReviewPage/FETCH_CONSOLE_REVIEWS_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchReviewListError: null,
  pagination: null,
  reviewList: [],
};

export default function consoleUserReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_REVIEWS_REQUEST:
      return { ...state, fetchInProgress: true, fetchReviewListError: null };
    case FETCH_CONSOLE_REVIEWS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        reviewList: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_REVIEWS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchReviewListError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchReviewListRequest = () => ({ type: FETCH_CONSOLE_REVIEWS_REQUEST });

const fetchReviewListSuccess = response => ({
  type: FETCH_CONSOLE_REVIEWS_SUCCESS,
  payload: response,
});

const fetchReviewListError = e => ({
  type: FETCH_CONSOLE_REVIEWS_ERROR,
  error: true,
  payload: e,
});


// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 20;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1 } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const queryParams = {
    ...paginationParams,
    include: ['author', 'subject', 'transaction', 'listing'],
    sort: '-createdAt',
  };

  dispatch(fetchReviewListRequest());

  return sdk.goSdk.reviews
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchReviewListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchReviewListError(e));
      throw e;
    });
};

export const downloadReviews = params => {
  return async (dispatch, getState, sdk) => {
    const data = [['id', 'authorId', 'authorEmail', 'providerId', 'providerEmail', 'listingId', 'listingTitle', 'transactionId', 'rating', 'content', 'createdAt']];

    const iterator = sdk.goSdk.reviews.createAsyncIterator({
      ...params,
      include: ['author', 'subject', 'transaction', 'listing'],
    });

    for await (const review of iterator) {
      data.push([
        review.id.uuid,
        review.rels.author.id.uuid,
        review.rels.author.attributes.email,
        review.rels.subject.id.uuid,
        review.rels.subject.attributes.email,
        review.rels.listing.id.uuid,
        review.rels.listing.attributes.title,
        review.rels.transaction.id.uuid,
        review.attributes.rating,
        review.attributes.content,
        review.attributes.createdAt
      ]);

      // if (transaction.attributes.participants.length) {
      //   for (const participant of transaction.attributes.participants) {
      //     data.push([
      //       transaction.rels.customer.attributes.profile.displayName,
      //       participant.name,
      //       participant.age,
      //     ]);
      //   }
      // } else {
      //   data.push([
      //     transaction.rels.customer.attributes.profile.displayName,
      //     '',
      //     '',
      //   ]);
      // }
    }

    await sdk.goSdk.xlsx.createFile(data, 'recenzje.xlsx');
  };
}