import React from 'react';
import css from './styles.css';

const groupSeatsBySectorAndRow = data => {
  return data.reduce((acc, curr) => {
    const { sectorName, row, seat } = curr;
    if (!acc[sectorName]) {
      acc[sectorName] = {};
    }
    if (!acc[sectorName][row]) {
      acc[sectorName][row] = [];
    }
    acc[sectorName][row].push(seat);
    return acc;
  }, {});
};

const ReservedSeatsList = ({ reservedSeats }) => {
  const groupedSeats = groupSeatsBySectorAndRow(reservedSeats);

  return (
    <div className={css.reservedSeatsList}>
      <div>
        {Object.keys(groupedSeats).map(sectorName => (
          <ul key={sectorName}>
            <p className={css.reservedSeatsList_sector_name}>Sektor {sectorName}</p>
            {Object.keys(groupedSeats[sectorName]).map(row => (
              <li className={css.reservedSeatsList_place_info} key={`${sectorName}_${row}`}>
                {`Rząd ${row}, miejsca: ${groupedSeats[sectorName][row].join(', ')}`}
              </li>
            ))}
          </ul>
        ))}
      </div>
    </div>
  );
};

export default ReservedSeatsList;
