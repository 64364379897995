import React, { useEffect, useState } from 'react';
import { ReactComponent as InfoIcon } from '../../../../assets/searchPage/info.svg';
import { ReactComponent as PinIcon } from '../../../../assets/searchPage/pin.svg';
import css from './styles.css';

import { sportsConfig, sportsDictionary } from '../../../../sportsConfig';
import { useIsMobile } from '../../../../util/hooks/useIsMobile';
import SimpleSearchForm from '../../../LandingPage/SimpleSearchForm';
import { cities } from '../../constants';
import useRemoveSearchParam from '../../hooks/useRemoveSearchParam';
import { useReplacePathParam } from '../../hooks/useReplacePathParam';
import { useUpdateQueryParams } from '../../hooks/useUpdateQueryParams';
import { useUrlParams } from '../../hooks/useUrlParams';
import { removePolishCharacters } from '../../utils';

const Header = ({ city = '', eventsAmount, setIsMapOpen }) => {
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const params = useUrlParams();

  const isMobile = useIsMobile();

  const updateQueryParams = useUpdateQueryParams();
  const replacePathParam = useReplacePathParam();
  const removeSearchParam = useRemoveSearchParam();
  const [bounds, setBounds] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSearchSubmit = data => {
    setIsSubmitted(true);
    const { startDate, endDate, sport } = data;
    const { selectedPlace } = data.location || {};
    const regex = /\d/;

    const city =
      selectedPlace &&
      Object.keys(selectedPlace).length > 0 &&
      !regex.test(selectedPlace.address.split(',')[0])
        ? selectedPlace.address.split(',')[0]
        : !!data.location && !!data.location.search
        ? data.location.search.split(',')[0]
        : null;

    const bounds =
      selectedPlace &&
      `${selectedPlace.bounds.ne.lat},${selectedPlace.bounds.ne.lng},${selectedPlace.bounds.sw.lat},${selectedPlace.bounds.sw.lng}`;

    if (startDate) {
      setStartDate(new Date(startDate).toISOString());
    } else {
      setStartDate(null);
    }
    if (endDate) {
      setEndDate(new Date(endDate).toISOString());
    } else {
      setEndDate(null);
    }
    if (bounds && city) {
      setBounds(bounds);
    } else if (!city) {
      setBounds('55.98904748, 24.05383751, 47.89042442, 14.14416954');
    }

    replacePathParam({
      sport: sport && !!sport.length ? sportsConfig[sport].name.toLowerCase() : 'wszystkie-sporty',
      city: city ? removePolishCharacters(city.toLowerCase()) : 'cala-polska',
    });
  };

  useEffect(() => {
    if (isMobile && isSubmitted && bounds) updateQueryParams('bounds', bounds);
  }, [bounds]);
  useEffect(() => {
    if (isMobile && isSubmitted && startDate) {
      updateQueryParams('start', startDate);
    } else if (isMobile && startDate) {
      removeSearchParam(['start']);
    }
  }, [startDate]);
  useEffect(() => {
    if (isMobile && isSubmitted && endDate) {
      updateQueryParams('end', endDate);
    } else if (isMobile && endDate) {
      removeSearchParam(['end']);
    }
  }, [endDate]);

  const toggleMap = () => {
    setIsMapOpen(prev => !prev);
  };

  return (
    <div className={css.headerContainer}>
      {isMobile && (
        <div className={css.generalInfoMobile}>
          <div style={{ position: 'relative', zIndex: '2', pointerEvents: 'none' }}>
            <p style={{ textTransform: 'capitalize' }}>
              {params.sport === 'wszystkie-sporty'
                ? 'Wszystkie sporty'
                : sportsDictionary[params.sport]}{' '}
              <span>{`(${eventsAmount})`}</span>
            </p>
            <p>{cities[params.city] ? cities[params.city] : params.city}</p>
          </div>
          <SimpleSearchForm
            onSubmit={handleSearchSubmit}
            initialValues={{
              eventTypes: 'all',
              sport: [],
            }}
          />
        </div>
      )}
      <div className={css.titleWrapper}>
        <h1 className={css.headerTitle}>
          {!eventsAmount && 'Brak wydarzeń o podanych parametrach'}
          {!isMobile && !!eventsAmount && (
            <span style={{ textTransform: 'capitalize' }}>{`${
              params.sport === 'wszystkie-sporty'
                ? 'Wydarzenia sportowe'
                : sportsDictionary[params.sport]
            }  ${cities[city] ? cities[city] : city}`}</span>
          )}
        </h1>
        {!isMobile && (
          <p
            style={{
              position: 'relative',
              marginBlock: '0',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {!!eventsAmount ? `${eventsAmount} wydarzeń` : 'Zobacz inne wydarzenia w okolicy'}
            <InfoIcon
              style={{ marginLeft: '5px' }}
              onMouseEnter={() => setIsInfoModalOpen(true)}
              onMouseLeave={() => setIsInfoModalOpen(false)}
            />
          </p>
        )}
      </div>
      {isInfoModalOpen && (
        <p className={css.infoModal}>
          Wyniki wyszukiwania pokazują wszystkie oferty, które odpowiadają Twoim kryteriom. Możesz
          zawęzić wyniki używając dostępnych filtrów. Wyniki wyszukiwania są uporządkowane
          chronologicznie, od wydarzeń startujących najwcześniej.
        </p>
      )}
      <button onClick={toggleMap} className={css.showMap}>
        <PinIcon style={{ marginRight: '5px' }} />
        Pokaż mapę
      </button>
    </div>
  );
};

export default Header;
