import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { PrimaryButton, SecondaryButton } from '../../components';

import css from './TransactionPanel.css';

// Functional component as a helper to build ActionButtons for
// provider when state is preauthorized
const SaleActionButtonsMaybe = props => {
  const {
    className,
    rootClassName,
    showButtons,
    acceptInProgress,
    declineInProgress,
    acceptSaleError,
    declineSaleError,
    onAcceptSale,
    onDeclineSale,
    onCancelSale,
    status,
    cancelInProgress
  } = props;

  const [isModalOpen, setIsModalOpen] = useState()

  const buttonsDisabled = acceptInProgress || declineInProgress;

  const acceptErrorMessage = acceptSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.acceptSaleFailed" />
    </p>
  ) : null;
  const declineErrorMessage = declineSaleError ? (
    <p className={css.actionError}>
      <FormattedMessage id="TransactionPanel.declineSaleFailed" />
    </p>
  ) : null;

  const classes = classNames(rootClassName || css.actionButtons, className);

  return showButtons ? (
    <>
      {isModalOpen && <div style={{ width: '100%', height: '100vh', position: 'fixed', top: '0%', left: '0%' }}>
        <div style={{ width: '100%', height: '100vh', position: 'absolute', zIndex: 25, background: '#333', opacity: '0.5', blur: '0.2' }}></div>
        <div style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 30, transform: 'translate(-50%,-50%)', margin: '0 auto', background: '#fff', border: 'solid 1px #333', zIndex: 100, padding: '0 30px', width: 'fit-content' }}>
          <h2 style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>Czy chcesz dokonać zmiany?<span onClick={() => setIsModalOpen(false)}>x</span></h2>
          <span>Anulacja jest nieodwracalna. Uczestnik otrzyma wiadomość z potwierdzeniem usunięcia z wydarzenia oraz automatyczny zwrot środków.</span>
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '30px 0' }}>
            <button style={{ padding: '10px 30px', margin: 0, marginRight: '20px' }} onClick={() => setIsModalOpen(false)} >Wróć</button>
            <button
              onClick={() => {
                onCancelSale()
                setIsModalOpen(false);
              }}
              style={{ background: '#007DBC', color: '#fff', padding: '10px 30px', margin: 0 }}>Potwierdź</button>
          </div>
        </div>
      </div>}
      <div className={classes}>
        <div className={css.actionErrors}>
          {acceptErrorMessage}
          {declineErrorMessage}
        </div>
        <div className={css.actionButtonWrapper}>
          {/* {status === 'requested' && <>
          <SecondaryButton
            inProgress={declineInProgress}
            disabled={buttonsDisabled}
            onClick={onDeclineSale}
          >
            <FormattedMessage id="TransactionPanel.declineButton" />
          </SecondaryButton>
          <PrimaryButton
            inProgress={acceptInProgress}
            disabled={buttonsDisabled}
            onClick={onAcceptSale}
          >
            <FormattedMessage id="TransactionPanel.acceptButton" />
          </PrimaryButton>
        </>}
        { */}
          {status === 'accepted' &&
            <>
              <SecondaryButton
                inProgress={cancelInProgress}
                disabled={buttonsDisabled}
                // onClick={onCancelSale}
                onClick={() => setIsModalOpen(true)}
                confirmationNeeded
              >
                <FormattedMessage id="TransactionPanel.cancelButton" />
              </SecondaryButton>

            </>
          }
        </div>
      </div>
    </>
  ) : null;
};

export default SaleActionButtonsMaybe;

