import React from 'react';
import { required } from '../../util/validators';
import { Field } from 'react-final-form';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import css from './EditDateTimeField.css';
registerLocale('pl', pl)

const DateTimeField = props => {
  const { name, id, intl } = props;
  const sportLabel = intl.formatMessage({
    id: 'SportSelectField.sportLabel',
  });
  const sportPlaceholder = intl.formatMessage({
    id: 'SportSelectField.sportPlaceholder',
  });
  const sportRequired = required(
    intl.formatMessage({
      id: 'SportSelectField.sportRequired',
    })
  );

  const isDateValid = date => {
    // Sprawdzamy, czy data nie jest równa dzisiejszej
    return date && date.toDateString() !== new Date().toDateString();
  };


  return (
    <div className={css.dateTimeField}>
      <label>Data i godzina</label>
      <DatePicker
        selected={props.input.value}
        onChange={(x) => props.input.onChange(x)}
        locale="pl"
        showTimeSelect
        timeCaption="Czas"
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat="d MMMM yyyy, HH:mm"
        className={css.dateTimeInput}
        filterDate={isDateValid}
      />
    </div>
  );
};

const DateTimeInput = props => {
  return <Field component={DateTimeField} {...props} />;
};

export default DateTimeInput;
