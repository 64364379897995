import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState, useEffect } from 'react';

import css from '../styles.css';
import { bookSeats } from '../../../../../util/api';

const PaymentForm = ({ priceData, customerData, seats }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const {
    unitPrice,
    unitPriceQuantity,
    reducedUnitPrice,
    reducePriceQuantity,
    provission,
    provissionQuantity,
    transactionId,
  } = priceData;

  const totalNormalPrice = unitPrice * unitPriceQuantity;
  const totalReducedPrice = reducedUnitPrice * reducePriceQuantity;
  const totalProvission = provission * provissionQuantity;
  const totalPrice = (totalNormalPrice + totalReducedPrice + totalProvission) / 100;

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setPaymentProcessing(true);
    setError(null);

    if (seats && seats.length) {
      const holdToken = JSON.parse(sessionStorage.getItem('seatsio'));
      const objects = seats.map(({ label }) => label);
      const res = bookSeats({ objects, holdToken: holdToken.holdToken });
    }

    const { error: paymentError } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://go.decathlon.pl/order/${transactionId}/details-success`,
      },
    });

    if (paymentError) {
      setError(paymentError.message);
      setPaymentProcessing(false);
      return;
    }

    setSuccess(true);
    setPaymentProcessing(false);
  };

  const paymentElementOptions = {
    layout: 'tabs',
    defaultValues: {
      billingDetails: {
        name: `${customerData.firstName} ${customerData.lastName}`,
        email: customerData.email,
      },
    },
  };

  // useEffect(() => {
  //   if (seats && seats.length && success) {
  //     const holdToken = sessionStorage.getItem('seatsio');
  //     const objects = seats.map(({ label }) => label);
  //     console.log('Seats', seats, objects, holdToken);
  //     const res = bookSeats({ objects, holdToken: holdToken.holdToken });
  //     console.log('res', res);
  //   }
  // }, [seats, success]);

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={css.stripeContainer}>
        <PaymentElement options={paymentElementOptions} />
      </div>
      <div className={css.summaryContainer}>
        <p className={css.summaryTitle}>Podsumowanie</p>
        <div className={css.priceContainer}>
          {!!unitPriceQuantity && (
            <p className={css.price}>
              {`${unitPriceQuantity} X Bilet normalny`} <span>{totalNormalPrice / 100} zł</span>
            </p>
          )}
          {!!reducePriceQuantity && (
            <p className={css.price}>
              {`${reducePriceQuantity} X Bilet ulgowy`} <span>{totalReducedPrice / 100} zł</span>
            </p>
          )}
          <p className={css.price}>
            Opłata serwisowa <span>{totalProvission / 100} zł</span>
          </p>
        </div>
        <p className={css.totalPrice}>{`Do zapłaty ${totalPrice} zł`}</p>
      </div>
      <button
        style={{
          width: '100%',
          maxWidth: '440px',
          margin: '0 auto',
          background: '#3643ba',
          borderRadius: '999px',
          border: 'none',
          padding: '10px 30px',
          color: '#fff',
        }}
        type="submit"
        disabled={!stripe || paymentProcessing}
      >
        {paymentProcessing ? 'Płatność w trakcie...' : `Zapłać (${totalPrice} zł)`}
      </button>
      {error && (
        <div className={css.errorMessage} role="alert" aria-live="assertive">
          {error}
        </div>
      )}
      {success && (
        <div className={css.successMessage} role="status" aria-live="polite">
          Płatność zakończona sukcesem!
        </div>
      )}
    </form>
  );
};

export default PaymentForm;
