import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { parse } from '../../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_CONSOLE_USERS_REQUEST = 'app/ConsoleUserPage/FETCH_CONSOLE_USERS_REQUEST';
export const FETCH_CONSOLE_USERS_SUCCESS = 'app/ConsoleUserPage/FETCH_CONSOLE_USERS_SUCCESS';
export const FETCH_CONSOLE_USERS_ERROR = 'app/ConsoleUserPage/FETCH_CONSOLE_USERS_ERROR';

export const UPDATE_USER_REQUEST = 'app/ConsoleUserPage/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'app/ConsoleUserPage/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'app/ConsoleUserPage/UPDATE_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchUserListError: null,
  pagination: null,
  userList: [],
  updateUserInProgress: false,
  updateUserError: null,
};

export default function consoleUserReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_USERS_REQUEST:
      return { ...state, fetchInProgress: true, fetchUserListError: null };
    case FETCH_CONSOLE_USERS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        userList: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_USERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchUserListError: payload };
    case UPDATE_USER_REQUEST:
      return { ...state, updateUserInProgress: true, updateUserError: null };
    case UPDATE_USER_SUCCESS: {
      const updatedUser = payload.data.data;
      return {
        ...state,
        updateUserInProgress: false,
        userList: state.userList.map(user =>
          user.id === updatedUser.id ? updatedUser : user
        ),
      };
    }
    case UPDATE_USER_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, updateUserInProgress: false, updateUserError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchUserListRequest = () => ({ type: FETCH_CONSOLE_USERS_REQUEST });

const fetchUserListSuccess = response => ({
  type: FETCH_CONSOLE_USERS_SUCCESS,
  payload: response,
});

const fetchUserListError = e => ({
  type: FETCH_CONSOLE_USERS_ERROR,
  error: true,
  payload: e,
});

const updateUserRequest = () => ({ type: UPDATE_USER_REQUEST });

const updateUserSuccess = response => ({
  type: UPDATE_USER_SUCCESS,
  payload: response,
});

const updateUserError = e => ({
  type: UPDATE_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 20;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1, phrase, entityId } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const queryParams = {
    ...paginationParams,
    phrase,
    include: ['image'],
    sort: '-createdAt',
  };

  dispatch(fetchUserListRequest());

  if (entityId) {
    return sdk.goSdk.users
      .show({ id: entityId })
      .then(response => {
        const res = {
          ...response,
          data: {
            ...response.data,
            data: [response.data.data],
          },
        }
        dispatch(addMarketplaceEntities(res));
        dispatch(fetchUserListSuccess(res));
        return res;
      })
      .catch(e => {
        dispatch(fetchUserListError(e));
        throw e;
      });
  }

  return sdk.goSdk.users
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchUserListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchUserListError(e));
      throw e;
    });
};

export const updateUser = data => {
  return (dispatch, getState, sdk) => {
    dispatch(updateUserRequest());

    return sdk.goSdk.users
      .update(data)
      .then(response => {
        dispatch(updateUserSuccess(response));
        return response;
      })
      .catch(e => {
        dispatch(updateUserError(e));
        throw e;
      });
  };
};

export const downloadUsers = params => {
  return async (dispatch, getState, sdk) => {
    const data = [['id', 'firstName', 'lastName', 'email', 'dktUserId', 'banned', 'deleted', 'createdAt', 'updatedAt', 'metadata', 'protectedData', 'publicData', 'privateData' ]];

    const iterator = sdk.goSdk.users.createAsyncIterator({
      ...params,
    });

    console.log(iterator)

    for await (const user of iterator) {
      console.log(user)
      data.push([
        user.id.uuid,
        user.attributes.profile.firstName,
        user.attributes.profile.lastName,
        user.attributes.email,
        user.attributes.dktUserId,
        user.attributes.banned,
        user.attributes.deleted,
        user.attributes.createdAt,
        user.attributes.updatedAt,
        JSON.stringify(user.attributes.profile.metadata),
        JSON.stringify(user.attributes.profile.protectedData),
        JSON.stringify(user.attributes.profile.publicData),
        JSON.stringify(user.attributes.profile.privateData)
      ]);

      // if (transaction.attributes.participants.length) {
      //   for (const participant of transaction.attributes.participants) {
      //     data.push([
      //       transaction.rels.customer.attributes.profile.displayName,
      //       participant.name,
      //       participant.age,
      //     ]);
      //   }
      // } else {
      //   data.push([
      //     transaction.rels.customer.attributes.profile.displayName,
      //     '',
      //     '',
      //   ]);
      // }
    }

    await sdk.goSdk.xlsx.createFile(data, 'uzytkownicy.xlsx');
  };
}