import classNames from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { CustomButton, IconChevronRight, PrimaryButton } from '../../components';
import { SelectedEventDateContext } from '../../containers/ListingPage/ListingPage';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  initiateTransactionForSTSEvent,
  showCurrentUserData,
} from '../../util/stsIntitateTransaction';
import IconInfoSecondary from '../IconInfo/IconInfoSecondary';
import styles from './BookingWithoutPayment.css';
import DecathlonLogo from './DecathlonLogo.svg';
import StripeLogo from './StripeLogo.svg';
import TimeSlotsModal from './TimeSlotsModal';

const { Money } = sdkTypes;

function BookingWithoutPayment({
  availabilityType,
  bookingRequired,
  discountParam,
  onlinePaymentAvailable,
  infoClassName,
  intl,
  onSubmit,
  price,
  priceWithDiscount,
  fetchTimeSlotsError,
  timeSlots,
  isSingleBookingError,
  setIsSingleBookingError,
  termins,
  eventDate,
  isSTSEvent,
  // onInitiateOrder,
  listingId,
  hidePrice,
  flexDate,
  onlySubmitButton,
}) {
  const [seats, setSeats] = useState(1);
  const [seatsWithDiscount, setSeatsWithDiscount] = useState(0);
  const [activeDiscount, setDiscount] = useState(false);

  const nearestTimeslot = timeSlots.filter(timeSlot => {
    return (
      timeSlot &&
      timeSlot.attributes &&
      new Date().getTime() < new Date(timeSlot.attributes.end).getTime()
    );
  });
  const [date, setDate] = useState(
    new Date(
      timeSlots && termins && timeSlots.length && nearestTimeslot
        ? nearestTimeslot[0].attributes.start
        : eventDate
    )
  );

  const [isOtherSlotsModalOpen, setIsOtherSlotsModalOpen] = useState(false);
  const setSelectedEvetDate = useContext(SelectedEventDateContext);

  const isButtonInactive = isSingleBookingError || (!seats && !seatsWithDiscount);

  useEffect(() => {
    setSelectedEvetDate(date);
  }, [date]);
  useEffect(() => {
    // const nearestTimeslot = timeSlots.filter(timeSlot => {
    //   return (new Date()).getTime() < (new Date(timeSlot.attributes.start)).getTime()
    // })

    setDate(
      new Date(
        timeSlots && termins && timeSlots.length && nearestTimeslot && nearestTimeslot[0]
          ? nearestTimeslot[0].attributes.start
          : eventDate
      )
    );
  }, [timeSlots && timeSlots.length]);

  useEffect(() => {
    setIsSingleBookingError(false);
  }, [date]);

  const isEventFree = !price || !price.amount;
  const priceLabel = !isEventFree && onlinePaymentAvailable && (
    <div>{formatMoney(intl, price)}</div>
  );

  const toggleDiscount = () => setDiscount(!activeDiscount);

  const handleSubmit = () => {
    const location = window.location.pathname;
    localStorage.setItem('redirectUrl', location);

    const timeSlot = timeSlots.find(
      timeslot => new Date(timeslot.attributes.start).getTime() === new Date(date).getTime()
    ); //
    showCurrentUserData();
    if (isSTSEvent) {
      Promise.all([
        showCurrentUserData(),
        initiateTransactionForSTSEvent({
          listingId,
          bookingStart: new Date(date),
          bookingEnd: new Date(timeSlot ? timeSlot.attributes.end : null),
        }),
      ]).then(res => {
        if (res) {
          const { email, firstName, lastName } = res[0];
          window.location.href = `${isSTSEvent}?imie=${firstName}&nazwisko=${lastName}&email=${email}&go=1`;
        }
      });
    } else {
      // const timeSlot = timeSlots.find(timeslot => (new Date(timeslot.attributes.start)).getTime() === (new Date(date)).getTime()) //
      onSubmit({
        seats,
        seatsWithDiscount,
        priceWithDiscount,
        discount: activeDiscount,
        date,
        end: timeSlot ? timeSlot.attributes.end : null,
      });
    }
  };

  return onlySubmitButton ? (
    <PrimaryButton
      onClick={handleSubmit}
      rootClassName={styles.submitButton}
      disabled={isSingleBookingError || (!seats && !seatsWithDiscount)}
      style={{ opacity: `${isButtonInactive ? '0.6' : ''}`, margin: '0 auto' }}
    >
      {isButtonInactive && 'Zapisano'}
      {!isButtonInactive && (onlinePaymentAvailable ? `Przejdź do płatności` : 'Zapisz się')}
      <IconChevronRight />
    </PrimaryButton>
  ) : (
    <div className={styles.wrapper}>
      {isSTSEvent && (
        <div className={styles.bookingInfo}>
          <p>
            Po zapisaniu się, przejdziesz do uzupełnienia wymaganych danych do startu w zawodach.
          </p>
        </div>
      )}
      {!!isEventFree && !isSTSEvent && !bookingRequired && (
        <div className={styles.bookingInfo}>
          <p>Udział w wydarzeniu jest darmowy i nie wymaga wcześniejszej rejestracji.</p>
          <p>Wystarczy, że przyjdziesz na wskazane miejsce w dniu wydarzenia.</p>
        </div>
      )}
      {!isEventFree && !isSTSEvent && !bookingRequired && (
        <div className={styles.bookingInfo}>
          <p>To wydarzenie nie wymaga wcześniejszej rejestracji.</p>
          <p>
            Przyjdź na wskazane miejsce w dniu wydarzenia i zapłać{' '}
            <span className={styles.price}>{formatMoney(intl, price)}</span> za udział bezpośrednio
            u organizatora.
          </p>
        </div>
      )}
      {!!isEventFree && !isSTSEvent && !!bookingRequired && (
        <div className={styles.bookingInfo}>
          <p>
            Udział w wydarzeniu jest darmowy, jednak wymagana jest wcześniejsza rejestracja online.
          </p>
        </div>
      )}
      {!isEventFree && !isSTSEvent && !!bookingRequired && !onlinePaymentAvailable && (
        <div className={styles.bookingInfo}>
          <p>Skorzystanie z oferty wymaga wcześniejszej rejestracji online.</p>
          <p>
            Opłatę {!hidePrice && <span className={styles.price}>{formatMoney(intl, price)}</span>}{' '}
            uiścisz na miejscu u organizatora.
          </p>
        </div>
      )}
      {//bookingRequired && availabilityType === 'regular' && (
      !!bookingRequired && isOtherSlotsModalOpen && (
        // <BookingDateForm
        //   setDate={setDate}
        //   timeSlots={timeSlots}
        //   fetchTimeSlotsError={fetchTimeSlotsError}
        //   onSubmit={() => { }}
        //   id="dateForm"
        // />

        <TimeSlotsModal
          timeSlots={timeSlots}
          setDate={setDate}
          setIsOtherSlotsModalOpen={setIsOtherSlotsModalOpen}
        />
      )}
      {!isEventFree && !!bookingRequired && !!onlinePaymentAvailable && (
        <BookingPaymentForm
          intl={intl}
          activeDiscount={activeDiscount}
          discountParam={discountParam}
          priceWithDiscount={priceWithDiscount}
          price={price}
          seats={seats}
          setSeats={setSeats}
          seatsWithDiscount={seatsWithDiscount}
          setSeatsWithDiscount={setSeatsWithDiscount}
          toggleDiscount={toggleDiscount}
        />
      )}
      {!!bookingRequired && (
        <div>
          {isSingleBookingError && (
            <p style={{ color: 'red', fontSize: '16px' }}>
              Ups… Jesteś już zapisany na to wydarzenie. Zaproś nową osobę, udostępniając jej to
              wydarzenie.
            </p>
          )}
          {date && !isSTSEvent && !flexDate && (
            <p>
              {new Intl.DateTimeFormat('pl-PL', {
                timeStyle: 'short',
                dateStyle: 'medium',
              }).format(new Date(date))}
            </p>
          )}

          {isSTSEvent && <p>Liczba dostępnych miejsc: ograniczona</p>}
          {timeSlots && !isSTSEvent && !!timeSlots.length && new Date(date) >= new Date() && (
            <p>
              Liczba dostępnych miejsc:{' '}
              {termins &&
              termins.filter(
                termin => new Date(termin.date).getTime() === new Date(date).getTime()
              )[0].limit == ''
                ? 'bez limitu'
                : timeSlots.filter(
                    timeslot =>
                      new Date(timeslot.attributes.start).getTime() === new Date(date).getTime()
                  )[0] &&
                  timeSlots.filter(
                    timeslot =>
                      new Date(timeslot.attributes.start).getTime() === new Date(date).getTime()
                  )[0].attributes.seats}
            </p>
          )}
          {timeSlots && !isSTSEvent && timeSlots.length > 1 && (
            <CustomButton
              rootClassName={styles.hoveButton}
              disabled={false}
              style={{ marginBottom: '20px', color: '#0082C3', border: 'none' }}
              onClick={() => setIsOtherSlotsModalOpen(true)}
            >
              Sprawdź pozostałe terminy
            </CustomButton>
          )}

          <PrimaryButton
            onClick={handleSubmit}
            rootClassName={styles.submitButton}
            disabled={isSingleBookingError || (!seats && !seatsWithDiscount)}
            style={{ opacity: `${isButtonInactive ? '0.6' : ''}` }}
          >
            {isButtonInactive && 'Zapisano'}
            {!isButtonInactive && (onlinePaymentAvailable ? `Przejdź do płatności` : 'Zapisz się')}
            <IconChevronRight />
          </PrimaryButton>
          {!!onlinePaymentAvailable && (
            <div className={styles.security}>
              <div className={styles.text}>Bezpieczną płatność zapewnia</div>
              <div className={styles.logos}>
                <img src={DecathlonLogo} />
                <div />
                <img src={StripeLogo} />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

// const mapDispatchToProps = (dispatch) => ({
//   dispatch,
//   onInitiateOrder: (params, transactionId) =>
//     dispatch(initiateOrder(params, transactionId))
// })

export default BookingWithoutPayment;
// compose(
//   withRouter,
//   connect(null, mapDispatchToProps),
//   injectIntl
// )(BookingWithoutPayment);

// connect(null, mapDispatchToProps)(BookingWithoutPayment);

function BookingPaymentForm({
  intl,
  price,
  discountParam,
  activeDiscount,
  seats,
  setSeats,
  seatsWithDiscount,
  setSeatsWithDiscount,
  toggleDiscount,
  priceWithDiscount,
}) {
  const handleChangeSeats = e => {
    setSeats(parseInt(e.target.value));
    // setSeats(parseInt(e.target.value));
    setSeats(1);
    setSeatsWithDiscount(0);
  };

  const handleChangeSeatsWithDiscouunt = e => {
    setSeatsWithDiscount(parseInt(e.target.value));
    // setSeatsWithDiscount(parseInt(e.target.value));
    setSeatsWithDiscount(1);
    setSeats(0);
  };

  const handleChangeDiscount = e => {
    toggleDiscount();
  };

  const totalPrice = formatMoney(
    intl,
    new Money(
      (price.amount * seats +
        (priceWithDiscount ? seatsWithDiscount * priceWithDiscount : 0) -
        (activeDiscount ? discountParam.value : 0)) *
        1.06 +
        200,
      price.currency
    )
  );

  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  return (
    <div className={styles.bookingInfo}>
      {/* <p>Koszt uczestnictwa wynosi <span className={styles.price}>{formatMoney(intl, price)}</span>. Wejściówkę kupisz przez Decathlon GO klikając przycisk poniżej.</p> */}
      {/* <div>
        <label htmlFor="participants" className={styles.seatsLabel}>
          Liczba wejściówek
        </label>
        <select
          id="participants"
          value={seats}
          onChange={handleChangeSeats}
          className={styles.seatsField}
        >
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
        </select>
      </div> */}

      {/* {priceWithDiscount && <div>
        <label htmlFor="participants" className={styles.seatsLabel}>
          Liczba wejściówek ulgowych
        </label>
        <select
          id="participants_with_discount"
          value={seatsWithDiscount}
          onChange={handleChangeSeatsWithDiscouunt}
          className={styles.seatsField}
        >
          <option value={0}>0</option>
          <option value={1}>1</option>
          <option value={2}>2</option>
          <option value={3}>3</option>
          <option value={4}>4</option>
          <option value={5}>5</option>
          <option value={6}>6</option>
          <option value={7}>7</option>
          <option value={8}>8</option>
          <option value={9}>9</option>
          <option value={10}>10</option>
        </select>
      </div>} */}

      {!!priceWithDiscount && (
        <div>
          Rodzaj biletu:
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              style={{ marginRight: '10px', width: 'fit-content' }}
              checked={seats === 1}
              onChange={handleChangeSeats}
            />
            Normalny
          </label>
          <label style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <input
              type="radio"
              style={{ marginRight: '10px', width: 'fit-content' }}
              checked={seatsWithDiscount === 1}
              onChange={handleChangeSeatsWithDiscouunt}
            />
            Ulgowy
          </label>
        </div>
      )}

      {/* <p className={styles.quantityInfo}>
        Możesz od razu kupić wejściówki dla siebie i innych. Wybierz tyle ile
        potrzebujesz.
      </p> */}

      {discountParam && (
        <div className={styles.discountSection}>
          <label>
            <input type="checkbox" checked={activeDiscount} onChange={handleChangeDiscount} />
            <span className={styles.discountName}>{discountParam.name}</span>
          </label>
          <p className={styles.discountInfo}>{discountParam.info}</p>
        </div>
      )}

      <div className={styles.summary}>Podsumowanie</div>
      {!!seats && (
        <div className={styles.line}>
          <span className={styles.lineTitle}>Cena jednostkowa</span>
          <span>{formatMoney(intl, price)}</span>
        </div>
      )}
      {priceWithDiscount && !!seatsWithDiscount && (
        <div className={styles.line}>
          <span className={styles.lineTitle}>Cena za bilet ulgowy</span>
          <span>{formatMoney(intl, { ...price, amount: Number(priceWithDiscount) || 0 })}</span>
        </div>
      )}
      {(!!seats || !!seatsWithDiscount) && (
        <div className={styles.line}>
          <span style={{ position: 'relative' }} className={styles.lineTitle}>
            Opłata serwisowa{' '}
            <span
              onMouseLeave={() => setIsInfoModalOpen(false)}
              onMouseOver={() => setIsInfoModalOpen(true)}
            >
              {' '}
              <IconInfoSecondary />{' '}
            </span>
            {isInfoModalOpen && (
              <span
                style={{
                  color: '#fff',
                  background: '#333',
                  fontSize: '14px',
                  padding: '10px',
                  borderRadius: '4px',
                  position: 'absolute',
                  right: '-100%',
                  top: '0%',
                  transform: 'translateY(-110%)',
                }}
              >
                Dzięki opłacie serwisowej możemy umożliwić Tobie bezpieczne
                przeprowadzenie transakcji.
              </span>
            )}
          </span>
          <span>
            {formatMoney(intl, {
              ...price,
              amount:
                (price.amount * seats +
                  (priceWithDiscount ? seatsWithDiscount * priceWithDiscount : 0) -
                  (activeDiscount ? discountParam.value : 0)) *
                  0.06 +
                200,
            })}
          </span>
        </div>
      )}
      <div
        className={classNames(styles.line, {
          [styles.bottomLine]: !activeDiscount,
        })}
      >
        <span className={styles.lineTitle}>Liczba</span>
        <span>x{seats + seatsWithDiscount}</span>
      </div>
      {activeDiscount && (
        <div className={classNames(styles.line, styles.bottomLine)}>
          <span className={styles.lineTitle}>{discountParam.name}</span>
          <span>-{formatMoney(intl, new Money(discountParam.value, price.currency))}</span>
        </div>
      )}

      <div className={classNames(styles.line, styles.lastLine)}>
        <span>Razem</span>
        <span>{!!seats || !!seatsWithDiscount ? totalPrice : 0}</span>
      </div>
    </div>
  );
}
