import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { EditOrganizerPhotosForm } from '../../forms';

import css from './EditOrganizerPhotosPanel.css';

class EditOrganizerPhotosPanel extends Component {
  render() {
    const {
      className,
      rootClassName,
      errors,
      fetchInProgress,
      newListingPublished,
      images,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
    } = this.props;

    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);

    return (
      <div className={classes}>
        <EditOrganizerPhotosForm
          className={css.form}
          disabled={fetchInProgress}
          ready={newListingPublished}
          fetchErrors={errors}
          initialValues={{ images }}
          images={images}
          onImageUpload={onImageUpload}
          onSubmit={values => {
            const { addImage, ...updateValues } = values;
            onSubmit(updateValues);
          }}
          onChange={onChange}
          onUpdateImageOrder={onUpdateImageOrder}
          onRemoveImage={onRemoveImage}
          saveActionMsg={submitButtonText}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
        />
      </div>
    );
  }
}

EditOrganizerPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditOrganizerPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  fetchInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditOrganizerPhotosPanel;
