import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Section from '../../../../components/Section/Section';
import SlideShow from '../../../../components/SlideShow/SlideShow';
import { apiSdk } from '../../../../queries/api';
import EventCard from '../../../SearchPage/components/EventCard/EventCard';
import EventsCardSkeleton from '../../../SearchPage/components/EventCard/EventsCardSkeleton';

const RecommendedEvents = () => {
  const [page, setPage] = useState(1);
  const [promotedEvents, setPromotedEvents] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const { data, isSuccess, isLoading, isError } = useQuery('promotedlistings.query', () =>
    apiSdk.listings.query({
      pub_type: 'promoted_event',
      sort: 'firstAvailableDate',
      state: 'published',
      include: ['images', 'dates'],
      page: page,
      per_page: 12,
    })
  );

  useEffect(() => {
    if (isSuccess) {
      const promotedEventsData = data.data
        .reduce((acc, item) => {
          const datesPerEventId = item.relationships.dates.data.map(date => date.id.uuid);
          const datesPerEvent = data.included.filter(
            item => item.type === 'listing-date' && datesPerEventId.includes(item.id.uuid)
          );
          const firstPossibleEventDate = datesPerEvent
            .filter(date => {
              const eventDate = new Date(date.attributes.date);
              eventDate.setMinutes(eventDate.getMinutes() + date.attributes.duration);
              return eventDate >= new Date();
            })
            .sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date))[0];

          const mainImagePerEventId = item.relationships.images.data[0].id.uuid;
          const mainImage = data.included.filter(
            item => item.type === 'image' && item.id.uuid === mainImagePerEventId
          )[0];

          return firstPossibleEventDate
            ? [
                ...acc,
                {
                  ...item.attributes,
                  id: item.id.uuid,
                  isMultiDates: datesPerEvent.length > 1,
                  image: mainImage,
                  eventDate: firstPossibleEventDate,
                },
              ]
            : acc;
        }, [])
        .sort(
          (a, b) => new Date(a.eventDate.attributes.date) - new Date(b.eventDate.attributes.date)
        );

      setPromotedEvents(prev => {
        if (page === 1) {
          return promotedEventsData;
        }

        const existingIds = new Set(prev.map(event => event.id));
        const filteredPromotedEvent = promotedEventsData.filter(
          event => !existingIds.has(event.id)
        );

        return [...prev, ...filteredPromotedEvent];
      });

      setHasMore(data.meta.page < data.meta.totalPages);
    }
  }, [isSuccess, data]);

  const loadMore = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <>
      {!isError ? (
        <>
          {isLoading && (
            <Section title="Polecane wydarzenia" isSliderContainer>
              <SlideShow>
                {[...Array(4)].map((_, index) => (
                  <EventsCardSkeleton key={index} />
                ))}
              </SlideShow>
            </Section>
          )}

          {isSuccess && promotedEvents.length > 0 && (
            <Section title="Polecane wydarzenia" isSliderContainer>
              <SlideShow>
                {promotedEvents.map(eventData => (
                  <EventCard key={eventData.id} eventData={eventData} />
                ))}
              </SlideShow>
            </Section>
          )}
        </>
      ) : null}
    </>
  );
};

export default RecommendedEvents;
