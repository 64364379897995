import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconFacebook from './IconFacebook';
import IconInstagram from './IconInstagram';
import IconPhone from './IconPhone';
import IconWeb from './IconWeb';
import { richText } from '../../util/richText';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionAdditionalInfo = props => {
  const { website, phone, facebookUrl, instagramUrl } = props;

  return (
    <div>
      {phone && <div>
        <IconPhone className={css.mapIcon} />
        <span className={css.contactInfo}>
          {phone}
        </span>
      </div>}
      {website && <div>
        <IconWeb className={css.mapIcon} />
        <span className={css.contactInfo}>
          {website}
        </span>
      </div>}
      {facebookUrl && <div>
        <IconFacebook className={css.mapIcon}/>
        <span className={css.contactInfo}>
          {facebookUrl}
        </span>
      </div>}
      {instagramUrl && <div>
        <IconInstagram className={css.mapIcon}/>
        <span className={css.contactInfo}>
          {instagramUrl}
        </span>
      </div>}
    </div>
  );
};

export default SectionAdditionalInfo;
