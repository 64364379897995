import React, { useState } from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';

import { NamedLink } from '../../components';
import bmiHelper from './bmiHelper';

import css from './CalculatorPage.css';

const BMI = () => {
  const [bmi, setBmi] = useState(null);

  const result = bmiHelper(bmi);

  return (
    <div className={css.calculator}>
      <Formik
        initialValues={{ height: '', weight: '' }}
        onSubmit={(values) => {
          const bmi = Math.round((values.weight / Math.pow(values.height / 100, 2)) * 100) / 100;
          setBmi(bmi);       
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>

            <label className={css.label} htmlFor="height">Wzrost</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="height"
                name="height"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.height}
              />
              <span className={css.inputIcon}>CM</span>
            </div>
            
            <label className={css.label} htmlFor="weight">Waga</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="weight"
                name="weight"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.weight}
              />
              <span className={css.inputIcon}>KG</span>
            </div>
            <div className={css.btnContainer}>
              <button className={css.submitCalcBtn} type="submit" disabled={!values.height || !values.weight}>
                Oblicz
              </button>
            </div>
          </form>
        )}
      </Formik>
      <div className={css.resultsContainer}>
        <div className={css.calcResults}>
          <h2 className={css.paragraphHeader}>Twoje BMI wynosi</h2>
          <span className={classNames(css.resultNumber, result.css)}>{bmi || '-'}</span>
          <span className={classNames(css.advice, css.adviceHeader)}>Wynik:</span>
          <p className={classNames(css.resultHeader, result.css)}>{result.header}</p>
          {/* <p className={css.advice}>{result.text}</p> */}
        </div>
        <div className={css.otherCalculators}>
          <span>Sprawdź inne kalkulatory: </span>
          <NamedLink name="RFMCalculator">
            RFM
          </NamedLink>
          <NamedLink name="WHRCalculator">
            WHR
          </NamedLink>
          <NamedLink name="BMRCalculator">
            BMR
          </NamedLink>
        </div>
      </div>
    </div>
  )
}

export default BMI;
