import React from 'react';
import PropTypes from 'prop-types';

const BackIcon = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="8" viewBox="0 0 16 8" fill="none">
            <path d="M0.646445 3.64645C0.451183 3.84171 0.451183 4.15829 0.646445 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.73079 7.34027 4.73079 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.73079 0.976311 4.73079 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646445 3.64645ZM16 3.5L0.999999 3.5V4.5L16 4.5V3.5Z" fill="#0082C3" />
        </svg>
    );
};

BackIcon.defaultProps = { className: null };

const { string } = PropTypes;

BackIcon.propTypes = { className: string };

export default BackIcon;
