import React from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { ListingLink, NamedLink } from '../../components';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

// import generateAvailabilityPlan from './availabilityPlan';

import css from './EditListingDescriptionPanel.css';

const EditListingDescriptionPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser
  } = props;

  const { Money } = sdkTypes;


  const paymentsEnabledField = currentUser && currentUser.attributes.profile.metadata && currentUser.attributes.profile.metadata.set_payments_enabled;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, price, publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <div className={css.header}>
        <h1 className={css.title}>{panelTitle}</h1>
        <NamedLink
          className={css.howToLink}
          name="HowToPage"
          to={{ hash: 'step1' }}
          title="Wskazówki"
          target="_blank"
        >
          ?
        </NamedLink>
      </div>

      <EditListingDescriptionForm
        className={css.form}
        initialValues={{
          bookingRequired: publicData.bookingRequired == null ? true : publicData.bookingRequired,
          title,
          description,
          duration: publicData.duration,
          sport: publicData.sport,
          event_type: publicData.event_type,
          training_url: publicData.training_url,
          discuntTicketRequirements: publicData.discuntTicketRequirements,
          priceWithDiscount: publicData.priceWithDiscount && price ? new Money(publicData.priceWithDiscount, price.currency) : '',
          // dateAndTime: publicData.date,
          // participants_limit: publicData.participants_limit,
          price,
          website: publicData.website,
          paymentEnabled: publicData.paymentEnabled ? [publicData.paymentEnabled] : null
        }}
        saveActionMsg={submitButtonText}
        onSubmit={values => {
          const {
            bookingRequired, priceWithDiscount, discuntTicketRequirements, dateAndTime, title, description, duration, participants_limit, sport, event_type, price, website, paymentEnabled, training_url
          } = values;
          // const eventDate = new Date(dateAndTime).getTime();
          const updateValues = {
            title: title.trim(),
            description,
            price,

            // availabilityPlan: generateAvailabilityPlan(eventDate, participants_limit),
            publicData: {
              bookingRequired,
              // date: eventDate,
              priceWithDiscount: priceWithDiscount ? priceWithDiscount.amount : '',
              discuntTicketRequirements: discuntTicketRequirements ? discuntTicketRequirements : null,
              duration,
              event_type,
              training_url: event_type === 'online' ? training_url : null,
              // participants_limit,
              listing_hide: false,
              sport,
              website,
              type: 'event',
              reward_active: paymentEnabled && paymentEnabled.length && paymentEnabled[0] ? true : false,
              reward_points: paymentEnabled && paymentEnabled.length && paymentEnabled[0] ? 1000 : 0,
              paymentEnabled: paymentEnabled && paymentEnabled.length && paymentEnabled[0]
            },
          };

          onSubmit(updateValues);
        }}
        onChange={onChange}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        categories={config.custom.categories}
        paymentsEnabledField={paymentsEnabledField}
      />
    </div>
  );
};

EditListingDescriptionPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDescriptionPanel;
