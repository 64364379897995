import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import css from './ReferencingPostsSection.css'

const formatDate = (publishingDate) => {
  if (!publishingDate) return null;
  const date = new Date(publishingDate);
  return `${('0' + (date.getDate())).slice(-2)}.${ ('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`
}

function ReferencingPostsSection({ posts, className, title }) {
  return (
    <div className={className}>
      <div className={css.mainNavTitle}>
        <span className={css.line} />
        {title}
      </div>
      <div className={css.mobileLinkWrapper}>
      {
        posts.map((post, i) => (
          <Link to={`/blog/${post.fields.slug}`} key={i} className={css.navItem}>
            <div className={css.navItemDesc}>
              <>
                <p className={classNames(css.navItemTitle, css.truncateOverflow)}>{post.fields.title}</p>
                <p className={css.navItemInfo}>{formatDate(post.fields.publishingDate)}, {post.fields.minutesToRead} min czytania</p>
              </>
            </div>
          </Link>
        ))
      }
      </div>
    </div>
  )
}

export default ReferencingPostsSection