export const recommendationApi = {


    getCatalogItemsData: async (listingId) => {
        const items = await (await fetch(`https://api.synerise.com/catalogs/itemDetail?catalogName=decathlon-go-reco&key=${listingId}`, {
            method: 'GET',
            headers: {
                // 'x-api-key': '576B50AE-A9AA-F66D-5161-26EC6204D9C2'
                Authorization: 'Basic NGQzNDFiYjQtNThkZC00MGYyLWZmNjYtODhjYjgyOTg0ZDEyOmU4OGUwMDlhLWQ4NmQtNGQ2NC04NmI2LThkYTMyYjE3MTg2Ng=='
            },
        })).json()
        return items.data
    },

    getProducts: async (campaignId, uuid, category2, gender, kategoriawiekowa, natureofproduct) => {
        const natureOfProductParam = natureofproduct ? natureofproduct.split(',').map(item => `"${item}"`) : null
        const genderParam = gender ? gender.split(',').map(item => `"${item}"`) : null
        const kategoriawiekowaParam = kategoriawiekowa ? kategoriawiekowa.split(',').map(item => `"${item}"`) : null
        const additionalFiltersAll = !!kategoriawiekowa ? `attributes.category2 IN ["${category2}"] AND attributes.natureofproduct IN [${natureOfProductParam}] AND gender IN [${genderParam}] AND attributes.kategoriawiekowa IN [${kategoriawiekowaParam}]`
            : `attributes.category2 IN ["${[category2]}"] AND attributes.natureofproduct IN [${natureOfProductParam}] AND gender IN [${genderParam}]`

        const additionalFiltersOnlyCategory = `attributes.category2 IN ["${[category2]}"]`

        const additionalFilters = natureOfProductParam && genderParam ? additionalFiltersAll : additionalFiltersOnlyCategory


        const products = await (await fetch(`https://synerise.decathlon.pl/ai/recommendations/v2/recommend/campaigns/${campaignId}?clientUUID=${uuid}&additionalFilters=${additionalFilters}&filtersJoiner=AND`, {
            method: 'GET',
            headers: {
                'x-api-key': '576B50AE-A9AA-F66D-5161-26EC6204D9C2'
            },
        })).json()

        const correlationId = products.extras ? products.extras.correlationId : null

        return { products, correlationId }
    },


    sendViewEvent: (correlationId, uuid, items) => fetch(`https://synerise.decathlon.pl/ai/events/v1/single/recommendation.view`, {
        method: 'POST',
        headers: {
            'x-api-key': '576B50AE-A9AA-F66D-5161-26EC6204D9C2'
        },
        body: JSON.stringify({
            correlationId: correlationId,
            clientUUID: uuid,
            items: items
        })
    }),
    sendClickEvent: (correlationId, uuid, itemId) => fetch(`https://synerise.decathlon.pl/ai/events/v1/single/recommendation.click`, {
        method: 'POST',
        headers: {
            'x-api-key': '576B50AE-A9AA-F66D-5161-26EC6204D9C2'
        },
        body: JSON.stringify({
            correlationId: correlationId,
            clientUUID: uuid,
            item: itemId
        })
    })
}