import React from 'react';
import css from './Buttons.css';

const Buttons = ({
  setSelectedTypeOfQuestion,
  setIsOpenAddQuestion,
  setStep,
  hadnleCreateSelectElement,
  isEdited,
}) => {
  const handleBack = () => {
    setSelectedTypeOfQuestion(null);
    setStep(null);
  };

  const handleCancel = () => {
    setIsOpenAddQuestion(false);
    setSelectedTypeOfQuestion(null);
    setStep(null);
  };

  return (
    <div className={css.buttonscontainer}>
      {!isEdited && (
        <button className={css.cancel} onClick={handleBack}>
          Wstecz
        </button>
      )}
      <button className={css.next} onClick={hadnleCreateSelectElement}>
        {isEdited ? 'Zapisz' : 'Utwórz'}
      </button>
      <button className={css.cancel} onClick={handleCancel}>
        Anuluj
      </button>
    </div>
  );
};

export default Buttons;
