import React from 'react';
import YouTube from 'react-youtube';
import css from './ListingPage.css';
 
export default class YoutubePlayer extends React.Component {
  render() {
    const opts = {
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: this.props.autoplay
      }
    };
 
    return (
      <YouTube
        className={css.youtubePlayer}
        containerClassName={this.props.containerClassName}
        videoId={this.props.youtubeId}
        opts={opts}
        onReady={this._onReady}
      />
    );
  }
 
  _onReady(event) {
    // access to player in all event handlers via event.target
    // event.target.pauseVideo();
  }
}

YoutubePlayer.defaultProps = {
  autoplay: true,
  containerClassName: ''
}