import { LINE_ITEM_DAY, LINE_ITEM_UNITS } from '../../util/types';
import { dateFromAPIToLocalNoon, daysBetween } from '../../util/dates';
import moment from 'moment';
import { formatMoney } from '../../util/currency';


export const formatDate = (intl, date) => {
    return {
        short: intl.formatDate(date, {
            month: 'short',
            day: 'numeric',
        }),
        long: `${intl.formatDate(date)} ${intl.formatTime(date)}`,
    };
};

export const bookingData = (unitType, tx, isOrder, intl) => {
    // Attributes: displayStart and displayEnd can be used to differentiate shown time range
    // from actual start and end times used for availability reservation. It can help in situations
    // where there are preparation time needed between bookings.
    // Read more: https://www.sharetribe.com/api-reference/#bookings
    const { start, end, displayStart, displayEnd } = tx.booking.attributes;
    const startDate = dateFromAPIToLocalNoon(displayStart || start);
    const endDateRaw = dateFromAPIToLocalNoon(displayEnd || end);
    const isDaily = unitType === LINE_ITEM_DAY;
    const isUnits = unitType === LINE_ITEM_UNITS;
    const isSingleDay = isDaily && daysBetween(startDate, endDateRaw) === 1;
    const bookingStart = formatDate(intl, startDate);

    // Shift the exclusive API end date with daily bookings
    const endDate =
        isDaily || isUnits
            ? moment(endDateRaw)
                .subtract(1, 'days')
                .toDate()
            : endDateRaw;
    const bookingEnd = formatDate(intl, endDate);
    // const bookingPrice = isOrder ? tx.attributes.payinTotal : tx.attributes.payoutTotal;
    const bookingPrice = tx.listing.attributes.price
    const price = bookingPrice ? formatMoney(intl, bookingPrice) : 'Free';
    return { bookingStart, bookingEnd, price, isSingleDay };
};