import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { TRANSITIONS } from '../../util/transaction';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { apiSdk, sdkGo } from '../../queries/api';

const sortedTransactions = txs =>
  reverse(
    sortBy(txs, tx => {
      return tx.attributes ? tx.attributes.lastTransitionedAt : null;
    })
  );

// ================ Action types ================ //

export const FETCH_ORDERS_OR_SALES_REQUEST = 'app/InboxPage/FETCH_ORDERS_OR_SALES_REQUEST';
export const FETCH_ORDERS_OR_SALES_SUCCESS = 'app/InboxPage/FETCH_ORDERS_OR_SALES_SUCCESS';
export const FETCH_ORDERS_OR_SALES_ERROR = 'app/InboxPage/FETCH_ORDERS_OR_SALES_ERROR';

export const FETCH_MESSAGES_REQUEST = 'app/InboxPage/FETCH_MESSAGES_REQUEST';
export const FETCH_MESSAGES_SUCCESS = 'app/InboxPage/FETCH_MESSAGES_SUCCESS';
export const FETCH_MESSAGES_ERROR = 'app/InboxPage/FETCH_MESSAGES_ERROR';

// ================ Reducer ================ //

const entityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
  messages: []
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_OR_SALES_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_OR_SALES_SUCCESS: {
      const transactions = sortedTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: entityRefs(transactions),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_OR_SALES_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    case FETCH_MESSAGES_REQUEST:
      return { ...state, fetchInProgress: true, messages: [] }
    case FETCH_MESSAGES_SUCCESS: {
      return { ...state, fetchInProgress: false, messages: [...state.messages, ...payload] }
    }
    case FETCH_MESSAGES_ERROR:
      console.error(payload)
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_OR_SALES_REQUEST });
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_OR_SALES_SUCCESS,
  payload: response,
});
const fetchOrdersOrSalesError = e => ({
  type: FETCH_ORDERS_OR_SALES_ERROR,
  error: true,
  payload: e,
});


const fetchMessagesRequest = () => ({ type: FETCH_MESSAGES_REQUEST });
const fetchMessagesSuccess = response => ({
  type: FETCH_MESSAGES_SUCCESS,
  payload: response,
});
const fetchMessagesError = e => ({
  type: FETCH_MESSAGES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const INBOX_PAGE_SIZE = 10;


export const fetchMessages = (userId) => async (dispatch, getState, sdk) => {

  dispatch(fetchMessagesRequest());

  let totalPages = 1
  let page = 1

  do {
    await sdk.transactions
      .query({
        userId,
        include: ['provider', 'customer', 'messages', 'listing', 'messages.sender'],
        page,
        per_page: 100
      })
      .then(response => {
        totalPages = response.data.meta.totalPages
        page = page + 1


        dispatch(fetchMessagesSuccess(response.data.data.filter(item => item.relationships.messages.data.length).map(item => {
          const messagesIds = item.relationships.messages.data.map(message => message.id.uuid);
          const messages = response.data.included.filter(message => {
            return message.type === 'message' && messagesIds.includes(message.id.uuid)
          })
          const listingId = item.relationships.listing.data.id.uuid
          const eventTitle = response.data.included.filter(item => item.type === 'listing' && item.id.uuid === listingId)[0].attributes.title

          const customerId = item.relationships.customer.data.id.uuid
          const providerId = item.relationships.provider.data.id.uuid

          return messages.filter(message => {
            const senderId = message.relationships.sender.data.id.uuid
            return senderId
          }).map(message => {
            const senderId = message.relationships.sender.data.id.uuid

            let sender
            if (senderId !== userId) {
              sender = response.data.included.filter(item => item.type === 'user' && item.id.uuid === senderId)[0]
            } else if (providerId !== userId) {
              sender = response.data.included.filter(item => item.type === 'user' && item.id.uuid === providerId)[0]
            } else {
              sender = response.data.included.filter(item => item.type === 'user' && item.id.uuid === customerId)[0]
            }

            const senderName = sender.attributes.profile.displayName
            return {
              sender,
              senderId,
              senderName,
              transactionId: item.id.uuid,
              eventTitle,
              isRead: item.attributes.metadata[message.id.uuid],
              messageId: message.id.uuid,
              ...message.attributes
            }
          })
        })));
      })
      .catch(e => {
        dispatch(fetchMessagesError(storableError(e)));
        throw e;
      });
  } while (page <= totalPages)

}


export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { tab } = params;

  const state = getState();
  console.log('state', state)

  const onlyFilterValues = {
    orders: 'order',
    sales: 'sale',
    messages: 'messages'
  };

  const onlyFilter = onlyFilterValues[tab];

  if (!onlyFilter) {
    return Promise.reject(new Error(`Invalid tab for InboxPage: ${tab}`));
  } else if (onlyFilterValues.messages === tab) {
    return Promise.resolve(null)
  }
  else {

    dispatch(fetchOrdersOrSalesRequest());

    const { page = 1 } = parse(search);

    const apiQueryParams = {
      // only: onlyFilter,
      // lastTransitions: TRANSITIONS,
      include: ['provider', 'customer', 'listing', 'chat', 'date'],
      providerId: '',
      customerId: '',
      // 'fields.transaction': [
      //   'lastTransition',
      //   'lastTransitionedAt',
      //   'transitions',
      //   'payinTotal',
      //   'payoutTotal',
      //   'processName'
      // ],
      // 'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      // 'fields.image': ['variants.square-small', 'variants.square-small2x'],
      // page,
      // per_page: INBOX_PAGE_SIZE,
    };

    // return sdk.transactions
    //   .query(apiQueryParams)
    //   .then(response => {
    //     dispatch(addMarketplaceEntities(response));
    //     dispatch(fetchOrdersOrSalesSuccess(response));
    //     return response;
    //   })
    //   .catch(e => {
    //     dispatch(fetchOrdersOrSalesError(storableError(e)));
    //     throw e;
    //   });

    // return sdkGo.transactions.query(apiQueryParams)
    //   // .query(apiQueryParams)
    //   .then(response => {
    //     console.log('response', response)
    //     dispatch(addMarketplaceEntities(response));
    //     dispatch(fetchOrdersOrSalesSuccess(response));
    //     return response;
    //   })
    //   .catch(e => {
    //     dispatch(fetchOrdersOrSalesError(storableError(e)));
    //     throw e;
    //   });
  }
};
