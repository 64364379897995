import React from 'react';
import PropTypes from 'prop-types';

const IconFacebook = props => {
  const { className } = props;

  return (
    <svg className={className} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
      <path d="M437 0H75C33.648 0 0 33.648 0 75v362c0 41.352 33.648 75 75 75h151V331h-60v-90h60v-61c0-49.629 40.371-90 90-90h91v90h-91v61h91l-15 90h-76v181h121c41.352 0 75-33.648 75-75V75c0-41.352-33.648-75-75-75zm0 0"/>
    </svg>
  );
};

const { string } = PropTypes;

IconFacebook.defaultProps = {
  className: null,
  rootClassName: null,
};

IconFacebook.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconFacebook;