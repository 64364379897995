import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconWeb = props => {
  const { className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
      <path d="M202.105 96.266a75.322 75.322 0 00-19.011-13.437c3.395 5.159 6.383 11.129 8.867 17.787a88.443 88.443 0 0010.144-4.35zM112.603 144.813h32.207v-27.29c-9.625-.418-18.931-1.774-27.601-3.989-2.596 9.482-4.222 20.1-4.606 31.279zM116.579 183.953c8.862-2.324 18.394-3.758 28.231-4.194v-24.572h-32.205c.359 10.203 1.754 19.934 3.974 28.766zM120.412 103.666c7.648 1.904 15.87 3.079 24.398 3.47V75.3a74.434 74.434 0 00-4.464.444c-7.972 4.834-14.954 14.811-19.934 27.922zM96.922 202.79a75.24 75.24 0 0023.456 15.989c-4.272-6.095-7.939-13.456-10.841-21.799-4.528 1.67-8.755 3.613-12.615 5.81zM120.288 81.26a75.133 75.133 0 00-21.586 14.239c3.613 1.937 7.521 3.639 11.656 5.125 2.74-7.348 6.094-13.863 9.93-19.364zM107.195 110.479c-5.638-2.002-10.906-4.396-15.707-7.158-9.231 11.547-15.113 25.866-16.189 41.492h26.914c.381-12.359 2.13-23.952 4.982-34.334zM102.216 155.187H75.3a74.485 74.485 0 0014.721 39.627c4.995-3.001 10.551-5.61 16.542-7.773-2.469-9.736-3.989-20.468-4.347-31.854zM140.536 224.283c1.413.176 2.837.319 4.274.418v-34.554c-8.831.415-17.359 1.681-25.262 3.719 5.056 14.383 12.46 25.341 20.988 30.417zM195.766 187c5.335 1.932 10.338 4.212 14.913 6.837 7.98-11.018 13.033-24.279 14.021-38.647h-24.595c-.357 11.367-1.872 22.087-4.339 31.81zM183.011 217.213a75.286 75.286 0 0020.858-15.25c-3.439-1.849-7.156-3.507-11.095-4.954-2.659 7.627-5.953 14.436-9.763 20.204z"/>
      <path d="M149.997 0C67.158 0 .003 67.161.003 149.997S67.158 300 149.997 300s150-67.163 150-150.003S232.837 0 149.997 0zM150 240.462c-49.88 0-90.462-40.579-90.462-90.462S100.12 59.538 150 59.538s90.462 40.579 90.462 90.462S199.88 240.462 150 240.462z"/>
      <path d="M162.719 76.202a75.332 75.332 0 00-7.534-.903v31.937c9.334-.275 18.352-1.512 26.711-3.597-4.822-12.687-11.521-22.444-19.177-27.437zM195.121 110.471c2.856 10.382 4.604 21.981 4.985 34.342h24.592c-1.045-15.227-6.658-29.209-15.484-40.595-4.36 2.378-9.075 4.474-14.093 6.253zM155.185 224.7a75.032 75.032 0 007.368-.871c8.201-5.262 15.307-16.002 20.212-29.948-8.613-2.223-17.955-3.543-27.58-3.833V224.7zM185.102 113.508c-9.384 2.402-19.493 3.813-29.917 4.103v27.202h34.534c-.387-11.186-2.016-21.815-4.617-31.305zM189.716 155.187h-34.531v24.486c10.732.288 21.052 1.722 30.573 4.207 2.212-8.81 3.6-18.516 3.958-28.693z"/>
    </svg>
  );
};

const { string } = PropTypes;

IconWeb.defaultProps = {
  className: null,
  rootClassName: null,
};

IconWeb.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconWeb;
        