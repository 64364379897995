/*
 * Marketplace specific configuration.
 */

export const amenities = [
  {
    key: 'volleyball',
    label: 'Siatkówka',
  },
  {
    key: 'squash',
    label: 'Squash',
  },
  {
    key: 'boxing',
    label: 'Boks',
  },
];

export const categories = [
  { key: 'smoke', label: 'Smoke' },
  { key: 'electric', label: 'Electric' },
  { key: 'wood', label: 'Wood' },
  { key: 'other', label: 'Other' },
];

export const sports = [
  { key: 'squash', label: 'Squash' },
  { key: 'volleyball', label: 'Siatkówka' },
  { key: 'fishing', label: 'Wędkarstwo' },
  { key: 'boxing', label: 'Boks' },
  { key: 'running', label: 'Bieganie' },
  { key: 'basketball', label: 'Koszykówka' },
  { key: 'cycling', label: 'Kolarstwo' },
  { key: 'sailing', label: 'Żeglarstwo' },
  { key: 'windsurfing', label: 'Windsurfing' },
  { key: 'martialarts', label: 'Sztuki walki' },
  { key: 'handball', label: 'Piłka ręczna' },
  { key: 'fitness', label: 'Fitness' }
]

export const sportLevels = [
  { key: 'novice', label: 'Nowicjusz', desc: 'Stawia swoje pierwsze kroki' },
  { key: 'beginner', label: 'Początkujący', desc: 'Zna zasady, ale potrzebuje sporo praktyki' },
  { key: 'intermediate', label: 'Średniozaawansowany', desc: 'Dobrze orientuje się o co w tym wszystkim chodzi' },
  { key: 'advanced', label: 'Zaawansowany', desc: 'Doświadczenie i technika bez dwóch zdań' },
  { key: 'professional', label: 'Profesjonalista', desc: 'Ten sport nie ma przed nim tajemnic.' }
]

export const gender = [
  { key: 'female', label: 'Kobieta' },
  { key: 'male', label: 'Mężczyzna' },
  { key: 'children', label: 'Dziecko' },
]

// Price filter configuration
// Note: unlike most prices this is not handled in subunits
export const priceFilterConfig = {
  min: 0,
  max: 1000,
  step: 5,
};

// Activate booking dates filter on search page
export const dateRangeFilterConfig = {
  active: true,
};

// Activate keyword filter on search page

// NOTE: If you are ordering search results by distance the keyword search can't be used at the same time.
// You can turn off ordering by distance in config.js file
export const keywordFilterConfig = {
  active: true,
};

export const eventTypes = [
  // { key: 'competition', label: 'rozgrywki' },
  { key: 'activity', label: 'zajęcia' },
  // { key: 'local', label: 'lokalnie' },
  { key: 'training', label: 'treningi' },
  { key: 'competition', label: 'zawody i turnieje' },
  { key: 'contest', label: 'konkursy' },
  { key: 'match', label: 'mecze' },
  // { key: 'camp', label: 'obozy' },
  { key: 'other', label: 'inne' },
  // { key: 'online', label: 'online' }
]


export const businessTypes = [
  { key: 'coach', label: 'Trener', desc: 'trener fitness, badmintona, sportów zespołowych etc.' },
  { key: 'club', label: 'Klub', desc: 'klub fitness, siłownia, szkoła tańca, studio jogi, klub tenisowy etc.' },
  { key: 'organizer', label: 'Organizator eventów', desc: 'organizator turniejów, obozów, wyjazdów, biegów etc.' },
  { key: 'practicioner', label: 'Wellness', desc: 'dietetyk, fizjoterapeuta, masażysta etc.' },
  { key: 'teacher', label: 'Szkoleniowiec', desc: 'szkoleniowiec, trener, edukator etc.' }
]

export const sportSupportFilterConfig = {
  active: true,
};

export const daysOfWeek = [
  { key: 'mon', label: 'Poniedziałek' },
  { key: 'tue', label: 'Wtorek' },
  { key: 'wed', label: 'Środa' },
  { key: 'thu', label: 'Czwartek' },
  { key: 'fri', label: 'Piątek' },
  { key: 'sat', label: 'Sobota' },
  { key: 'sun', label: 'Niedziela' },
];

export const eventTypesAvailable = [
  {
    key: 'onetime',
    label: 'Wydarzenie jednorazowe'
  },
  {
    key: 'permanent',
    label: 'Wydarzenie stałe'
  },
  {
    key: 'regular',
    label: 'Wydarzenie regularne'
  }
];