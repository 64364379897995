import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import logoImg from './new-logo.svg';
import css from './Logo.css';

const Logo = props => {
  const { alt, className, format, ...rest } = props;
  const mobileClasses = classNames(css.logoMobile, className);

  if (format === 'desktop') {
    return <img src={logoImg} alt={alt} className={mobileClasses} {...rest} />;
  }

  return <img src={logoImg} alt={alt} className={mobileClasses} {...rest} />;
};

const { oneOf, string } = PropTypes;

Logo.defaultProps = {
  alt: 'Logo Decathlon GO',
  className: null,
  format: 'desktop',
};

Logo.propTypes = {
  alt: string,
  className: string,
  format: oneOf(['desktop', 'mobile']),
};

export default Logo;
