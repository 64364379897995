import React from "react"
import { createShowedFormElement } from '../../../src/components/EditListingAdditionalFormPanel/utils'
import { useForm } from "react-hook-form"
import { PrimaryButton } from '../../components';



const AdditionalUserForm = ({ additionalForm, handleSubmitRegisterForm, cta }) => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: 'onChange'
    });


    const onSubmit = (data) => {
        handleSubmitRegisterForm(data)
    }

    return <form onSubmit={handleSubmit(onSubmit)} >
        {additionalForm.map(item => createShowedFormElement(item, register, errors))}

        <PrimaryButton type="submit">
            {cta}
        </PrimaryButton>
    </form >
}

export default AdditionalUserForm