import React from 'react';
import { NamedLink } from '../../../../components';
import css from './TopNavigation.css';

const TopNavigation = ({ currentPage }) => {
  return (
    <nav className={css.topNav}>
      <ul className={css.navList}>
        <li className={css.navItem}>
          <NamedLink className={currentPage === 'ConsoleListingPage' ? css.activeNavLink : css.navLink} name="ConsoleListingPage">
            Listings
          </NamedLink>
        </li>
        <li className={css.navItem}>
          <NamedLink className={currentPage === 'ConsoleUserPage' ? css.activeNavLink : css.navLink} name="ConsoleUserPage">
            Users
          </NamedLink>
        </li>
        <li className={css.navItem}>
          <NamedLink className={currentPage === 'ConsoleReviewPage' ? css.activeNavLink : css.navLink} name="ConsoleReviewPage">
            Reviews
          </NamedLink>
        </li>
        <li className={css.navItem}>
          <NamedLink className={currentPage === 'ConsoleTransactionPage' ? css.activeNavLink : css.navLink} name="ConsoleTransactionPage">
            Transactions
          </NamedLink>
        </li>
      </ul>
    </nav>
  );
};

export default TopNavigation;
