import React, { useState } from 'react';
import DatePicker, { registerLocale, } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
import './DateTimeInput.css';
import { Controller } from 'react-hook-form';
import css from "./styles.css";

registerLocale('pl', pl)

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
        onClick={onClick}
        value={value}
        readOnly
        ref={ref}
    />
));

const sixMonthsFromNow = new Date();
sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);


const DateTimeInput = props => {
    const { label, register } = props;

    const handleDateChange = (date, onChange) => {
        onChange(date);
    };

    return (
        <div className={css.dateContainer}>
            <label style={{ fontSize: '14px' }}>{label}</label>
            <Controller
                control={props.control}
                name="selectedDate"
                rules={{ required: 'Data jest wymagana' }}
                placeholderText="Wybierz datę wydarzenia"
                render={({ field }) => (
                    <DatePicker
                        selected={field.value}
                        onChange={(date) => handleDateChange(date, field.onChange)}
                        locale="pl"
                        showTimeSelect
                        timeCaption="Czas"
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="d MMMM yyyy, HH:mm"
                        minDate={new Date()}
                        shouldCloseOnSelect={true}
                        maxDate={sixMonthsFromNow}
                        customInput={<CustomInput />}
                        className="max-w-[100%] "
                    />
                )}
            />
        </div>
    );
};

export default DateTimeInput;
