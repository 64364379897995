import React from 'react';
import calendarImg from '../images/calendar.svg';
import clockImg from '../images/clock.svg';
import locationImg from '../images/location.svg';
import moment from 'moment';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { IconSpinner, NamedLink, ResponsiveImage } from '../../../components';
import css from './NextEventSection.css';
import { createSlug } from '../../../util/urlHelpers';
import { withViewport } from '../../../util/contextHelpers';
import { compose } from 'redux';

const NextEventSection = props => (
  <div className={css.container}>
    <h2 className={css.title}>Najbliższe wydarzenie</h2>

    {props.data ? <NextEvent {...props}  /> :
      <div className={css.loading}>
       <IconSpinner />
     </div>
    }

  </div> 
);

export default compose(withViewport)(NextEventSection);

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const { sportsConfig } = config;

const NextEvent = props => {
  const {
    data: {
      attributes: {
        publicData: {
          date,
          price,
          sport
        },
        title
      },
      id,
      images,
      type
    }, 
    intl,
    viewport
  } = props;

  const { formattedPrice } = priceData(price, intl);
  const firstImage = images ? images[0] : null;
  const slug = createSlug(title);

  const mobileViewport = viewport.width < 768;

  return (
    <div className={css.eventContainer}>

      <div className={css.imageContainer}>
        <div className={css.eventIcon} style={{ backgroundColor: sportsConfig[sport].color }}>
          <img src={sportsConfig[sport].icon} />
        </div>
        <ResponsiveImage
          className={css.image}
          alt={title}
          image={firstImage}
          variants={['landscape-crop', 'landscape-crop2x']}
        />
      </div>

      <div className={css.descContainer}>
        <h3 className={css.eventTitle}>{title}</h3>
        <div className={css.desc}>
          <img src={calendarImg} alt="clock icon" />
          {moment(date).format('D.MM.YYYY')}
        </div>
        <div className={css.desc}>
          <img src={clockImg} alt="time icon" />
          {moment(date).format('H:mm')}
        </div>
        <div className={css.desc}>
          <img src={locationImg} alt="location icon" />
          Online
        </div>
        <div className={css.price}>{price && price.amount ? formattedPrice : 'Darmowe'}</div>
        <div className={css.linkContainer}>
          <NamedLink className={css.link} name={type === 'business_card' ? 'OrganizerPage' : 'ListingPage'} params={{ id: id.uuid, slug }}>Zapisz się na trening</NamedLink>
        </div>
      </div>
    </div>
)}
