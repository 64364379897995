import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/RecommendedEventsSection/SET_INITIAL_STATE';

export const QUERY_RECOMMENDED_LISTINGS_REQUEST = 'app/RecommendedEventsSection/QUERY_RECOMMENDED_LISTINGS_REQUEST';
export const QUERY_RECOMMENDED_LISTINGS_SUCCESS = 'app/RecommendedEventsSection/QUERY_RECOMMENDED_LISTINGS_SUCCESS';
export const QUERY_RECOMMENDED_LISTINGS_ERROR = 'app/RecommendedEventsSection/QUERY_RECOMMENDED_LISTINGS_ERROR';

// ================ Reducer ================ //

const initialState = {
  recommendedListingRefs: []
};

export default function recommendedLisitingsSectionReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_RECOMMENDED_LISTINGS_REQUEST:
      return {
        ...state,

        // Empty listings only when user id changes
        userListingRefs: payload.userId === state.userId ? state.userListingRefs : [],

        queryListingsError: null,
      };
    case QUERY_RECOMMENDED_LISTINGS_SUCCESS:
      return { ...state, recommendedListingRefs: payload.recommendedListingRefs };
    case QUERY_RECOMMENDED_LISTINGS_ERROR:
      return { ...state, recommendedListingRefs: [], queryListingsError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryRecommendedListingsRequest = userId => ({
  type: QUERY_RECOMMENDED_LISTINGS_REQUEST,
  payload: { userId },
});

export const queryRecommendedListingsSuccess = recommendedListingRefs => ({
  type: QUERY_RECOMMENDED_LISTINGS_SUCCESS,
  payload: { recommendedListingRefs },
});

export const queryRecommendedListingsError = e => ({
  type: QUERY_RECOMMENDED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryRecommendedListings = () => (dispatch, getState, sdk) => {
  // dispatch(queryRecommendedListingsRequest());
  let lastMidnight = new Date();
  lastMidnight.setHours(0, 0, 0, 0);

  return sdk.listings
    .query({
      meta_hp_promoted: true,
      pub_date: `${lastMidnight.getTime()},`,
      include: ['author', 'images'],
      'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      // 'fields.listing': ['metadata'],
    })
    .then(response => {
      const recommendedListingRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryRecommendedListingsSuccess(recommendedListingRefs));
      return response;
    })
    .catch(e => dispatch(queryRecommendedListingsError(storableError(e))));
};
