/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape } from 'react-intl';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import {
  Button,
  IconArrowRight,
  IconCalendar,
  IconInbox,
  IconLogin,
  IconLogout,
  IconPartnership,
  IconPlus,
  IconSearchSpinner,
  IconSettings,
  IconSignup,
  IconUser,
  InlineTextButton,
  Logo,
  NamedLink,
} from '../../components';
import { getAuth } from '../../util/api';
import logoImg from './images/decathlon.png';
import css from './TopbarMobileMenu.css';

const TopbarMobileMenu = props => {
  const {
    currentPage,
    currentUser,
    currentUserHasListings,
    handleMobileSearchOpen,
    intl,
    isAuthenticated,
    notificationCount,
    onLogout,
  } = props;

  const currentYear = new Date().getFullYear();

  const footer = <div className={css.footer}>Copyright by Decathlon GO {currentYear}</div>;

  const listing = (
    <>
      <NamedLink name="NewListingPage" className={css.mainLink}>
        <span className={css.mainIcon}>
          <IconPlus />
        </span>
        <FormattedMessage id="TopbarMobileMenu.newListingLink" />
      </NamedLink>

      {/* <a
        name="PartnersPage"
        className={css.mainLink}
        href="https://partner.decathlon.pl"
      >
        <span className={css.mainIcon}>
          <IconPartnership />
        </span>
        <FormattedMessage id="TopbarMobileMenu.partnership" />
      </a> */}
    </>
  );

  const logo = (
    <NamedLink
      className={css.logoLink}
      name="LandingPage"
      title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
    >
      <Logo
        format="mobile"
        className={css.logo}
        alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
      />
    </NamedLink>
  );

  const searchMenu = (
    <Button
      rootClassName={css.searchMenu}
      onClick={handleMobileSearchOpen}
      title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
    >
      <IconSearchSpinner />
    </Button>
  );

  const shopLink = (
    <a
      href="https://decathlon.pl?utm_source=decathlon_go&utm_medium=referral&utm_campaign=top_menu"
      className={css.mainLink}
      target="_blank"
    >
      <span className={css.mainIcon}>
        <IconArrowRight />
      </span>
      <FormattedMessage id="TopbarMobileMenu.shop" />
      <img src={logoImg} alt="Decathlon" className={css.logoShop} />
    </a>
  );

  const loginLink = isAuthenticated ? null : (
    <a onClick={() => getAuth()} className={`${css.mainLink} ${css.loginLink}`}>
      <span className={css.mainIcon}>
        <IconLogin />
      </span>
      <FormattedMessage id="TopbarDesktop.login" />
    </a>
  );

  if (!isAuthenticated) {
    return (
      <div className={css.root}>
        <div className={css.mainNav}>
          {logo}
          <div className={css.navContrainer}>{searchMenu}</div>
        </div>
        <div className={css.content}>
          {listing}
          {loginLink}
          {shopLink}
        </div>
        {footer}
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? <div className={css.notificationBadge}>{notificationCount}</div> : null;

  return (
    <div className={css.root}>
      <div className={css.mainNav}>
        {logo}
        <div className={css.navContrainer}>
          {searchMenu}
          <NamedLink
            className={css.inboxLink}
            name="InboxPage"
            // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
            params={{ tab: 'messages' }}
          >
            <span className={css.iconInbox}>
              <IconInbox />
              {notificationCountBadge}
            </span>
          </NamedLink>
        </div>
      </div>

      <div className={css.content}>
        {listing}
        <NamedLink name="ManageListingsPage" className={css.mainLink}>
          <span className={css.mainIcon}>
            <IconCalendar className={css.icon} />
          </span>
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
        <a
          href="https://membership.decathlon.com/pl-PL/home/"
          target="_blank"
          className={css.mainLink}
        >
          <span className={css.mainIcon}>
            <IconSettings />
          </span>
          <FormattedMessage id="TopbarMobileMenu.yourRewardsLink" />
        </a>
        <NamedLink name="ProfileSettingsPage" className={css.mainLink}>
          <span className={css.mainIcon}>
            <IconUser className={css.icon} />
          </span>
          <FormattedMessage id="TopbarMobileMenu.profileSettingsLink" />
        </NamedLink>
        <NamedLink name="AccountSettingsPage" className={css.mainLink}>
          <span className={css.mainIcon}>
            <IconSettings />
          </span>
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        <InlineTextButton onClick={onLogout} rootClassName={css.mainLink}>
          <span className={css.mainIcon}>
            <IconLogout />
          </span>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>
        {shopLink}
      </div>
      {footer}
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentPage: null,
  currentUser: null,
  notificationCount: 0,
};

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  currentPage: string,
  currentUser: propTypes.currentUser,
  currentUserHasListings: bool.isRequired,
  handleMobileSearchOpen: func.isRequired,
  intl: intlShape.isRequired,
  isAuthenticated: bool.isRequired,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
