import React from 'react';
import css from './style.css';

const Buttons = ({
  // setSelectedTypeOfQuestion,
  // setIsOpenAddQuestion,
  // setStep,
  hadnleCreateSelectElement,
  isEdited,
}) => {
  // const handleBack = () => {
  //   setSelectedTypeOfQuestion(null);
  //   setStep(null);
  // };

  // const handleCancel = () => {
  //   setIsOpenAddQuestion(false);
  //   setSelectedTypeOfQuestion(null);
  //   setStep(null);
  // };

  return (
    <div >
      {/* {!isEdited && (
        <button className={'css.cancel'} onClick={handleBack}>
          Wstecz
        </button>
      )} */}
      <button
        // className={'absolute left-4 right-4 bottom-5  border rounded-full bg-[#3643BA] text-white py-3 border-[#3643BA]'}
        className={css.button}
        onClick={hadnleCreateSelectElement}>
        {isEdited ? 'Zapisz' : 'Zatwierdź i przejdź dalej'}
      </button>
      {/* <button className={'css.cancel'} onClick={handleCancel}>
        Anuluj
      </button> */}
    </div>
  );
};

export default Buttons;
