import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const SearchField = ({ paramName }) => {
  const location = useLocation();
  const history = useHistory();

  // Initialize the query state from the URL
  const searchParams = new URLSearchParams(location.search);
  const initialQuery = searchParams.get(paramName) || '';
  const [query, setQuery] = useState(initialQuery);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = () => {
    const searchParams = new URLSearchParams(location.search);
    if (query) {
      searchParams.set(paramName, query);
    } else {
      searchParams.delete(paramName);
    }

    history.push({
      pathname: location.pathname,
      search: searchParams.toString(),
    });
  };

  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <input
        type="text"
        value={query}
        onChange={handleChange}
        placeholder={`Search by ${paramName}`}
        style={{ border: '1px solid #ccc', padding: '8px', borderRadius: '4px', marginRight: '8px' }}
      />
      <button
        onClick={handleSubmit}
        style={{ padding: '8px 16px', border: 'none', borderRadius: '4px', backgroundColor: '#007bff', color: '#fff', cursor: 'pointer' }}
      >
        Search
      </button>
    </div>
  );
};

export default SearchField;
