import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput } from '../../components';
import { required } from '../../util/validators';
import { Field } from 'react-final-form';


import css from './EditListingPoliciesForm.css';
import { uploadFileToAWS } from '../../util/api';


export const EditListingPoliciesFormComponent = props => (


  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
      } = fieldRenderProps;

      const rulesLabelMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesLabel',
      });
      const rulesPlaceholderMessage = intl.formatMessage({
        id: 'EditListingPoliciesForm.rulesPlaceholder',
      });

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
        </p>
      ) : null;

      const bucketName = process.env.REACT_APP_S3_BUCKETNAME

      const handleUploadFile = async (e) => {
        const file = e.currentTarget.files[0]
        const { url } = await uploadFileToAWS(bucketName, file.name)

        fetch(url, {
          method: 'PUT',
          body: file,
        })

        const fileurl = `https://${bucketName}.s3.eu-west-1.amazonaws.com/${file.name}`
        props.setFileUrl(fileurl)
      }

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress //|| (!props.publicData.term_of_use && !props.fileurl);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {errorMessageShowListing}
          {!!props.price && <FieldTextInput
            id="returns_policy"
            name="returns_policy"
            className={css.policy}
            type="textarea"
            label="Zasady anulowania oraz zwrotów (wymagane)"
            placeholder="Określ do kiedy uczestnik może anulować swój zapis"
            validate={props.publicData.returns_policy ? null : required('Pole jest wymagane')}
          />}

          <FieldTextInput
            id="rules"
            name="rules"
            className={css.policy}
            type="textarea"
            label="Zasady oferty (opcjonalnie)"
            placeholder="Podaj zasady oferty"
          />

          <FieldTextInput
            id="advices"
            name="advices"
            className={css.policy}
            type="textarea"
            label="Zalecenia dla uczestników (opcjonalnie)"
            placeholder="Co powinien zrobić uczestnik przed wzięciem udziału?"
          />

          <FieldTextInput
            id="remakrs"
            name="remarks"
            className={css.policy}
            type="textarea"
            label="Uwagi dodatkowe (opcjonalnie)"
            placeholder="Uwagi dodatkowe"
          />
          {props.publicData.term_of_use && <div>
            Załączony <strong>regulamin</strong>, <a href={props.publicData.term_of_use} target='_blank' >{props.publicData.term_of_use.split(/[\\/]/)[props.publicData.term_of_use.split(/[\\/]/).length - 1]}</a>
            <p style={{ margin: 0 }}>Jeśli chcesz zmienić plik z regulaminem wybierz nowy regulamin w polu poniżej i naciśnij zapisz</p>
          </div>}

          <label>
            Regulamin wydarzenia
            {/* {!props.publicData.term_of_use && !props.fileurl && <span style={{ color: 'red' }}> Załącz regulamin</span>} */}
            <input
              id="term_of_use"
              name="term_of_use"
              className={css.policy}
              type="file"
              label="Regulamin wydarzenia"
              placeholder="Załącz dokument z regulaminem wydarzenia"
              // validate={props.publicData.term_of_use ? null : required('Załącz regulamin wydarzenia')}
              accept="application/pdf"
              onChange={handleUploadFile}
            />
          </label>

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
