import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom';
// import './Modal.css'; // Opcjonalnie, jeśli chcesz dodać stylizację

const Modal = ({ isOpen, message, onClose, status }) => {
  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [isOpen, onClose]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      style={{
        position: 'fixed',
        zIndex: 500,
        top: '80px',
        left: '0%',
        right: '0',
        backgroundColor: `${status === 'error' ? 'red' : '#7AFFA6'}`,
        padding: '10px 0',
        textAlign: 'center',
        color: `${status === 'error' ? '#fff' : '#000'}`,
      }}
      className="modal-overlay"
    >
      <div className="modal">
        <p>{message}</p>
      </div>
    </div>,
    document.getElementById('modal-root')
  );
};

const RequestStatusModal = ({
  status,
  resetStatus,
  slug = '',
  listingId = '',
  infoSuccess,
  infoError,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState(infoSuccess || '');
  const [messageError, setMessageError] = useState(infoError || '');

  useEffect(() => {
    if (status === 'success' || status === 'error') {
      if (!infoError && !infoSuccess) {
        status === 'success'
          ? setMessage(
              <p>
                Zmiany zostały opublikowane!{' '}
                <Link to={`/l/${slug}/${listingId}`}>Zobacz podgląd wydarzenia</Link>
              </p>
            )
          : setMessageError('Upss, coś poszło nie tak');
      }
      setIsOpen(true);
      if (!infoError && status === 'error') {
        setMessageError('Upss, coś poszło nie tak');
      }
      if (!infoSuccess && status === 'success') {
        setMessageError('Zmiany zostały opublikowane!');
      }
    }
  }, [status]);

  const handleClose = () => {
    setIsOpen(false);
    resetStatus();
  };

  return (
    <Modal
      isOpen={isOpen}
      message={status === 'success' ? message : messageError}
      onClose={handleClose}
      status={status}
    />
  );
};

export default RequestStatusModal;
