import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ResponsiveImage, Modal, ImageCarousel } from '../../components';
import ActionBarMaybe from './ActionBarMaybe';
import classNames from 'classnames';
import css from './ListingPage.css';

const SectionImages = props => {
  const {
    title,
    listing,
    isOwnListing,
    editParams,
    handleViewPhotosClick,
    imageCarouselOpen,
    onImageCarouselClose,
    onManageDisableScrolling,
    selectedImageIndex
  } = props;

  const hasImages = listing.images && listing.images.length > 0;
  const firstImage = hasImages ? listing.images[0] : null;
  const secondImage = hasImages ? listing.images[1] : null;

  const firstImageWrapperClass = classNames(css.singleImageWrapper);
  const firstImageClass = classNames(css.singleImage)
  const imagesContainerClass = secondImage && css.imagesContainer;
  
  // Action bar is wrapped with a div that prevents the click events
  // to the parent that would otherwise open the image carousel
  const actionBar = listing.id ? (
    <div onClick={e => e.stopPropagation()}>
      <ActionBarMaybe isOwnListing={isOwnListing} listing={listing} editParams={editParams} />
    </div>
  ) : null;

  const viewPhotosButton = hasImages ? (
    <button className={css.viewPhotos} onClick={handleViewPhotosClick}>
      <FormattedMessage
        id="ListingPage.viewImagesButton"
        values={{ count: listing.images.length }}
      />
    </button>
  ) : null;

  return (
    <div className={css.sectionImages}>
      <div className={css.threeToTwoWrapper}>
        <div className={css.aspectWrapper} >
          {actionBar}
          {/* <div className={imagesContainerClass}> */}
            <div className={firstImageWrapperClass} onClick={handleViewPhotosClick}>
              <ResponsiveImage
                rootClassName={firstImageClass}
                alt={title}
                image={firstImage}
                variants={[
                  'landscape-crop',
                  'landscape-crop2x',
                  'landscape-crop4x',
                  'landscape-crop6x',
                ]}
              />
            </div>
          {/* </div> */}
          {viewPhotosButton}
        </div>
      </div>
      <Modal
        id="ListingPage.imageCarousel"
        scrollLayerClassName={css.carouselModalScrollLayer}
        containerClassName={css.carouselModalContainer}
        lightCloseButton
        isOpen={imageCarouselOpen}
        onClose={onImageCarouselClose}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {imageCarouselOpen && 
          <ImageCarousel images={listing.images} selectedImageIndex={selectedImageIndex} />
        }
      </Modal>
    </div>
  );
};

export default SectionImages;
