import React, { useEffect, useState } from "react";
import css from './ManageCustomerPage.css';
import {
    sendMessages,
    clearMessagesStatus
} from './ManageListingsPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import IconSuccessSend from "../../components/IconsSendModal/IconSuccessSend";
import IconErrorSend from "../../components/IconsSendModal/IconErrorSend";
import { IconSpinner } from "../../components";

function filterUniqueEmails(objects) {
    const uniqueEmails = new Set();
    const result = [];

    for (const obj of objects) {
        if (!uniqueEmails.has(obj.email)) {
            uniqueEmails.add(obj.email);
            result.push(obj);
        }
    }

    return result;
}


const SendMessageModalComponent = ({ onMessagesSend, listingDateId, isModalOpen, setIsModalOpen, customers, sendMessagesInProgress,
    sendMessages, sendMessagesError, onClear }) => {
    // const txIds = customers ? filterUniqueEmails(customers).map(item => item.tx) : []
    // const [txIds, setTxIds] = useState(singleCustomers)

    const [message, setMessage] = useState('')
    const [isInvalidValue, setIsInvalidValue] = useState(false)

    const handleCancel = () => {
        setMessage('')
        setIsModalOpen(false)
        onClear()
    }

    const handleSend = () => {
        if (!!message) {
            onMessagesSend(listingDateId, message)
        } else {
            setIsInvalidValue(true)
        }
    }

    return isModalOpen && <>
        <div className={css.curtain}></div>
        <div className={css.modalcontainer}>
            {sendMessagesInProgress && <div className={css.statusMessage}>
                <IconSpinner />
            </div>}
            {sendMessages && <div className={css.statusMessage}>
                <IconSuccessSend />
                <h2 >Udało się!</h2>
                <p>Wiadomość wysłana poprawnie</p>
                <button onClick={handleCancel} className={css.cancelbuton}>
                    Zamknij
                </button>
            </div>}
            {sendMessagesError && <div className={css.statusMessage}>
                <IconErrorSend />
                <h2 >Ups... coś nie wyszło</h2>
                <p>Wyślij wiadomość jeszcze raz</p>
                <button onClick={handleSend} className={css.cancelbuton}>
                    Wyślij ponownie
                </button>
            </div>}
            {!sendMessagesInProgress && !sendMessages && !sendMessagesError && <>
                <h2 className={css.modalheader}>Wiadomość do uczestników
                    <button onClick={handleCancel}>X</button>
                </h2>
                <label>
                    Treść wiadomości {isInvalidValue && <span style={{ color: 'red' }} >Pole jest wymagane</span>}
                    <textarea className={css.modalinput} value={message} onChange={(e) => setMessage(e.currentTarget.value)} />

                </label>
                <div className={css.buttonscontainer}>
                    <button onClick={handleCancel} className={css.cancelbuton}>
                        Anuluj
                    </button>
                    <button onClick={handleSend} className={css.acceptbutton}>
                        Wyślij
                    </button>
                </div>
            </>}
        </div>
    </>
}


const mapStateToProps = state => {
    const {
        sendMessagesInProgress,
        sendMessages,
        sendMessagesError
    } = state.ManageListingsPage;

    return {
        sendMessagesInProgress,
        sendMessages,
        sendMessagesError
    };
};


const mapDispatchToProps = dispatch => ({
    onMessagesSend: (listingDateId, message) => dispatch(sendMessages(listingDateId, message)),
    onClear: () => dispatch(clearMessagesStatus())
});

const SendMessageModal = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    ),
    injectIntl
)(SendMessageModalComponent);

export default SendMessageModal