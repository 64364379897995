import React from 'react';
import PropTypes from 'prop-types';

const IconSettings = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2A374A"
        fillRule="nonzero"
        stroke="none"
        d="M8 0a2 2 0 0 1 2 2v.057c0 .173.104.329.27.4a.431.431 0 0 0 .472-.082l.04-.04a2 2 0 1 1 2.83 2.83l-.035.035a.433.433 0 0 0-.087.477.667.667 0 0 1 .043.133c.079.117.211.19.354.19H14a2 2 0 1 1 0 4h-.057a.433.433 0 0 0-.397.263l-.003.006a.431.431 0 0 0 .082.473l.04.04a2 2 0 1 1-2.83 2.83l-.035-.035a.436.436 0 0 0-.484-.084.432.432 0 0 0-.263.394V14a2 2 0 1 1-4 0v-.044c-.004-.178-.116-.336-.322-.413a.431.431 0 0 0-.473.082l-.04.04a2 2 0 1 1-2.83-2.83l.035-.035a.436.436 0 0 0 .084-.484.432.432 0 0 0-.394-.263H2a2 2 0 0 1 0-4h.044c.178-.004.336-.116.413-.322a.431.431 0 0 0-.082-.473l-.04-.04a2 2 0 1 1 2.83-2.83l.035.035a.433.433 0 0 0 .477.087.667.667 0 0 1 .133-.043.432.432 0 0 0 .19-.354V2a2 2 0 0 1 2-2zm0 1.333A.667.667 0 0 0 7.333 2v.116a1.767 1.767 0 0 1-1.07 1.617.667.667 0 0 1-.176.048 1.77 1.77 0 0 1-1.825-.41l-.04-.04a.667.667 0 1 0-.944.944l.045.045c.505.517.645 1.288.37 1.91-.251.681-.892 1.14-1.633 1.157H2A.667.667 0 1 0 2 8.72h.116c.704.003 1.34.424 1.614 1.064a1.77 1.77 0 0 1-.359 1.954l-.04.04a.667.667 0 1 0 .944.944l.045-.045c.517-.505 1.288-.645 1.91-.37.681.251 1.14.892 1.157 1.633V14a.667.667 0 0 0 1.333 0v-.116a1.764 1.764 0 0 1 1.064-1.614 1.77 1.77 0 0 1 1.954.359l.04.04a.667.667 0 1 0 .944-.944l-.045-.045a1.767 1.767 0 0 1-.355-1.946 1.768 1.768 0 0 1 1.618-1.067H14a.667.667 0 0 0 0-1.334h-.116a1.767 1.767 0 0 1-1.617-1.07.667.667 0 0 1-.048-.176 1.77 1.77 0 0 1 .41-1.825l.04-.04a.667.667 0 1 0-.944-.944l-.045.045a1.764 1.764 0 0 1-1.943.356 1.768 1.768 0 0 1-1.07-1.619V2A.667.667 0 0 0 8 1.333zm0 4a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334zm0 1.334a1.333 1.333 0 1 0 0 2.666 1.333 1.333 0 0 0 0-2.666z"
      />
    </svg>
  );
};

IconSettings.defaultProps = { className: null };

const { string } = PropTypes;

IconSettings.propTypes = {
  className: string,
};

export default IconSettings;
