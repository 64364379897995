import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";

export const useUrlParams = () => {
    const params = useParams();
    const query = new URLSearchParams(useLocation().search);

    const searchParams = {};
    for (const [key, value] of query.entries()) {
        searchParams[key] = value;
    }
    return { ...params, ...searchParams };
};

