export const filtersData = [
  // {
  //   title: 'Sport',
  //   queryParamName: 'sport',
  //   searchParamName: null,
  //   options: sports.map(sport => ({
  //     value: sport,
  //     label: sportsConfig[sport].name
  //   }))
  // },
  {
    title: 'Rodzaj',
    queryParamName: null,
    searchParamName: 'pub_event_type',
    options: [
      { label: 'Zajęcia', value: 'activity' },
      { label: 'Treningi', value: 'training' },
      { label: 'Zawody i turnieje', value: 'competition' },
      { label: 'Konkursy', value: 'contest' },
      { label: 'Mecze', value: 'match' },
      { label: 'Inne', value: 'other' },
    ],
  },
  {
    title: 'Dla kogo',
    queryParamName: null,
    searchParamName: 'pub_gender',
    options: [
      { label: 'Kobieta', value: 'female' },
      { label: 'Męźczyzna', value: 'male' },
      { label: 'Dziecko', value: 'children' },
    ],
  },
];
export const cities = {
  warszawa: 'Warszawa',
  krakow: 'Kraków',
  poznan: 'Poznań',
  katowice: 'Katowice',
  bydgoszcz: 'Bydgoszcz',
  wroclaw: 'Wrocław',
  gdansk: 'Gdańsk',
  'cala-polska': 'Cała Polska',
};
