import React, { useState } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import { withViewport } from '../../../util/contextHelpers';
import { createSlug } from '../../../util/urlHelpers';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import config from '../../../config';

import { NamedLink } from '../../../components';
import Prev from '../Schedule/Prev';
import Next from '../Schedule/Next';

import calendarImg from './calendar.svg';
import locationImg from './location.svg';
import personImg from './person.svg';
import clockImg from './clock.svg'

import css from './Webinars.css'

const { sportsConfig } = config;

function TrainingsOverviewSection({ data, viewport }) {
  const [ startIndex, setStartIndex ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(1)

  let device = 'desktop'
  if (viewport.width >= 1024) {
    device = 'desktop'
  } else if (viewport.width >= 768) {
    device = 'tablet'
  } else {
    device = 'mobile'
  }
  
  const size = {
    mobile: 1,
    tablet: 2,
    desktop: 3
  }[device]

  const totalPages = Math.ceil(data.filter(item => item.attributes.publicData.date >= moment().unix() * 1000 && item.attributes.metadata && item.attributes.metadata.webinar ).length / size)
  
  const filteredData = orderBy(
    data.filter(item => item.attributes.publicData.date >= moment().unix() * 1000 && item.attributes.metadata && item.attributes.metadata.webinar),
    item => item.attributes.publicData.date,
    ['asc']
  ).slice(startIndex, pageNumber * size)

  const handlePageChange = page => {
    setPageNumber(page)
    const newStartIndex = (page - 1) * size
    setStartIndex(newStartIndex)
  }

  return (
    <section className={css.section}>
      <div className={css.wrapper}>
        { filteredData.map(({ attributes, id, images }) =>
          <div className={css.item}>
            <Training {...attributes} img={images[0]} id={id} />
          </div>
        )}
      </div>
      <Pagination isMobile={size === 1} pageNumber={pageNumber} totalPages={totalPages} onPageChange={handlePageChange} />
    </section>
  )
}

export default compose(withViewport)(TrainingsOverviewSection)

function Training({ publicData, title, img, id, metadata }) {
  const { date } = publicData

return (
  <>
    <div>
      <div className={css.imgWrapper} style={{ backgroundImage: `url(${img.attributes.variants['landscape-crop2x'].url})` }} />
      <div className={css.trainingTitle}><span>{title}</span></div>
    </div>
    <div className={css.center}>
      <div className={css.row}>
        <div className={css.info}>
          <img src={calendarImg} alt="calendar icon" />
          {moment(date).format('D.MM.YYYY')}
        </div>
        <div className={css.info}>
          <img src={clockImg} alt="clock icon" />
          {moment(date).format('H:mm')}
        </div>
        <div className={css.info}>
          <img src={personImg} alt="person icon" />
          {metadata.webinar}
        </div>
      </div>
      <NamedLink name="ListingPage" params={{ id: id.uuid, slug: createSlug(title)}} className={css.signUp}>
        Zapisz się
      </NamedLink>
    </div>
  </>
)}

function Pagination({ isMobile, pageNumber, totalPages, onPageChange }) {
  const createPagination = () => {
    let pagination = []
    for (let i = 0; i < totalPages; i++) {
      pagination.push(
        <Number
          key={i + 1}
          active={i + 1 === pageNumber}
          page={i+1} onPageChange={onPageChange} />
      )
    }
    return !isMobile && pagination
  }

  const onPrev = () => {
    if (pageNumber > 1) {
      onPageChange(pageNumber -1)
    }
  }

  const onNext = () => {
    if(pageNumber < totalPages) {
      onPageChange(pageNumber + 1)
    }
  }
  
  return (
    <div className={css.pagination}>
      <a className={css.prev} onClick={onPrev}><Prev {...isMobile && { color: '#fff'}} /></a>
      {createPagination()}
      <a className={css.next} onClick={onNext}><Next {...isMobile && { color: '#fff'}} /></a>
    </div>
  )
}

function Number({ active, page, onPageChange }) {
  const handlePageChange = () => onPageChange(page)

  return (
    <a 
      className={classNames(css.pageNumber, active && css.activePageNumber)}
      onClick={handlePageChange}>
      {page}
    </a>
  )
}