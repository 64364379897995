import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';

import css from './EditListingPoliciesPanel.css';
import { uploadFileToAWS } from '../../util/api';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const [fileurl, setFileUrl] = useState()

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const duplicate = query.get('duplicate');

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      {!!duplicate && <div>
        <p><strong>KOPIA WYDARZENIA </strong>Aby je opublikować na stronie:</p>
        <p>1. Dodaj regulamin wydarzenia</p>
        <p>2. Zmień datę wydarzenia</p>
        <p>3. Dodaj zdjęcia</p>
        <p>4. Naciśnij “opublikuj wydarzenie"</p>
      </div>}
      <h1 className={css.title}>{panelTitle}</h1>
      <EditListingPoliciesForm
        setFileUrl={setFileUrl}
        fileurl={fileurl}
        className={css.form}
        publicData={publicData}
        initialValues={{
          advices: publicData.advices,
          rules: publicData.rules,
          remarks: publicData.remarks,
          term_of_use: publicData.term_of_use,
          returns_policy: publicData.returns_policy
        }}
        onSubmit={(values) => {
          const { rules = '', advices = '', remarks = '', returns_policy = '' } = values;

          const updateValues = {
            publicData: {
              advices,
              rules,
              remarks,
              term_of_use: fileurl ? fileurl : publicData.term_of_use,
              returns_policy
            },
          };
          onSubmit(updateValues);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateInProgress={updateInProgress}
        fetchErrors={errors}
        price={currentListing.attributes.price.amount}
      />
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
