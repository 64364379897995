import classNames from 'classnames';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import { array, func, oneOf, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  PaginationLinks,
  SearchMap,
  SearchResultsPanelExpand,
} from '../../components';
import FooterV2 from '../../components/Footer/FooterV2';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { withViewport } from '../../util/contextHelpers';
import { parse } from '../../util/urlHelpers';
import SectionSports from './SectionSports';
import css from './SportLocationPage.css';
import { searchListings, searchMapListings } from './SportLocationPage.duck';
import { createSearchResultSchema } from './SportLocationPage.helpers';

const MOBILE_BREAKPOINT = 768;
const MEDIUM_BREAKPOINT = 1024;
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const PER_PAGE = 12;

export class SportLocationPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isListingOpen: false,
      isMapOpen: props.tab === 'map',
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMap = this.onOpenMap.bind(this);
    this.onOpenListing = this.onOpenListing.bind(this);
    this.togglePanel = this.togglePanel.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {}

  onOpenMap() {
    this.setState({ isMapOpen: true });
  }

  onOpenListing() {
    this.setState({ isMapOpen: false });
  }

  togglePanel() {
    this.setState({ isListingOpen: true });
  }

  render() {
    const {
      intl,
      listings,
      landingData,
      location,
      mapListings,
      scrollingDisabled,
      viewport,
      pagination,
    } = this.props;

    const page = parse(location.search).page || 1;
    const query = parse(location.search).query;
    const sport = parse(location.search).sport;

    const {
      articles,
      city,
      city2,
      description,
      mainImgSrc,
      searchParams,
      sports,
      title,
      lead,
      header,
      hideMap,
      landingClassName,
    } = landingData;
    const { bounds, origin } = searchParams || {};
    const schema = createSearchResultSchema(listings, city, intl);

    const products = searchParams.sport
      ? config.sportsConfig[searchParams.sport].externalProducts
      : [
          ...config.sportsConfig['yoga'].externalProducts,
          ...config.sportsConfig['running'].externalProducts,
        ];

    const isMobileShow = viewport.innerWidth < MOBILE_BREAKPOINT ? 1 : null;
    const isTabletShow =
      viewport.innerWidth < MEDIUM_BREAKPOINT && viewport.innerWidth >= MOBILE_BREAKPOINT
        ? 3
        : null;

    const slidesToShow = isMobileShow || isTabletShow || 4;

    return (
      <StaticPage
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={`${title} | Decathlon GO`}
        schema={schema}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <div
              className={classNames(css.mainBannerContainer, landingClassName)}
              style={{ backgroundImage: `url(${mainImgSrc})` }}
            >
              <div className={css.mask} />
              <TopbarContainer
                atHomePage={true}
                className={css.topbarBg}
                currentPage="SportLocationPage"
              />
              <div className={css.mainBannerWrapper}>
                <h1 className={css.mainTitle}>{header}</h1>
                <span className={css.divider} />
                <div className={css.mainTitleDesc}>{lead}</div>
              </div>
            </div>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.containerPanel}>
              {listings.length && !hideMap ? (
                <div className={css.filtersContainer}>
                  <button
                    className={classNames(css.filterBtn, {
                      [css.filterBtnActive]: !this.state.isMapOpen,
                    })}
                    onClick={this.onOpenListing}
                  >
                    Lista
                  </button>
                  <button
                    className={classNames(css.filterBtn, {
                      [css.filterBtnActive]: this.state.isMapOpen,
                    })}
                    onClick={this.onOpenMap}
                  >
                    Mapa
                  </button>
                </div>
              ) : null}

              {location.pathname === '/cwicze-w-domu' && (
                <>
                  <h2 className={css.titleWithLine}>Wybierz trening</h2>
                  {/* <div className={css.filtersWrapper}>
                    <div className={css.row}>
                      <button className={css.dropdownTrigger}>Sport</button>
                      <input className={css.input} type="text" placeholder="Szukaj po nazwie" />
                      <button className={css.submitButton}>Szukaj</button>
                    </div>
                    <NamedLink name="TrainAtHomeLanding" className={css.resetFilters}>Wyczyść filtry</NamedLink>
                  </div> */}
                </>
              )}

              {this.state.isMapOpen ? (
                <div className={css.mapSportLocationPanel}>
                  <SearchMap
                    reusableContainerClassName={css.mapSportLocation}
                    bounds={bounds}
                    center={origin}
                    location={location}
                    listings={mapListings || []}
                    onMapMoveEnd={this.onMapMoveEnd}
                    messages={intl.messages}
                  />
                </div>
              ) : (
                <>
                  <div className={css.searchResultSportContainer}>
                    <SearchResultsPanelExpand
                      isListingOpen={this.state.isListingOpen}
                      listingClassName={css.listingContainer}
                      listings={listings}
                    />
                  </div>
                  <div className={css.paginationWrapper}>
                    {pagination && pagination.totalPages > 1 && (
                      <PaginationLinks
                        className={css.pagination}
                        pageName={landingData.name}
                        pageSearchParams={{ page, query, sport }}
                        pagination={{ page, totalPages: pagination.totalPages }}
                      />
                    )}
                  </div>
                </>
              )}
            </div>

            <div className={css.linksContainer}>
              <NamedLink
                name="SearchPage"
                to={{
                  search:
                    'address=Polska&bounds=55.98904748%2C24.05383751%2C47.89042442%2C14.14416954',
                }}
                className={css.link}
              >
                Wszystkie wydarzenia
              </NamedLink>
              {searchParams.sport && (
                <NamedLink
                  name="SearchPage"
                  to={{
                    search: `address=Polska&bounds=55.98904748%2C24.05383751%2C47.89042442%2C14.14416954&pub_sport=${searchParams.sport}`,
                  }}
                  className={css.link}
                >
                  {config.sportsConfig[searchParams.sport].name} - cała Polska
                </NamedLink>
              )}
            </div>
            {/* <SectionExternalProducts
              category="Sports Landing Page"
              description={true}
              products={products}
              sport={searchParams.sport}
              urlCampaign="sports_landing_page"
              slidesToShow={slidesToShow}
            /> */}
            {!!sports.length && (
              <SectionSports city={city} city2={city2} intl={intl} sports={sports} />
            )}
            <div className={css.articlesSection}>
              {articles.map((article, key) => (
                <div key={key}>
                  <h2 className={css.sectionHeading}>{article.title}</h2>
                  {article.text.map((paragraph, k) => {
                    if (typeof paragraph === 'string') {
                      return (
                        <p style={{ display: 'inline' }} key={k}>
                          {paragraph}
                        </p>
                      );
                    }
                    return paragraph;
                  })}
                </div>
              ))}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
    /* eslint-enable  */
  }
}

SportLocationPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  tab: 'listings',
};

SportLocationPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onSearchMapListings: func.isRequired,
  tab: oneOf(['listings', 'map']).isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentPageResultIds, searchMapListingIds, pagination } = state.SportLocationPage;

  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    listings: pageListings,
    mapListings,
    pagination,
  };
};

const mapDispatchToProps = dispatch => ({
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SportLocationPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SportLocationPageComponent);

SportLocationPage.loadData = (params, search) => {
  const queryParams = {
    ...params.searchParams,
  };

  let lastMidnight = new Date();
  lastMidnight.setHours(0, 0, 0, 0);

  //const { origin, pub_date = `${lastMidnight.getTime()},`, ...rest } = queryParams;
  const { origin, sort = '-pub_date', ...rest } = queryParams;
  const { page = 1 } = parse(search);
  const allEventsMaybe = params.searchParams.allEvents
    ? {}
    : { pub_date: `${lastMidnight.getTime()},` };
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};
  const sportMaybe = params.searchParams.sport ? { pub_sport: params.searchParams.sport } : {};

  return searchListings({
    ...rest,
    ...originMaybe,
    page,
    perPage: PER_PAGE,
    //pub_date,
    ...allEventsMaybe,
    ...sportMaybe,
    sort,
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default SportLocationPage;
