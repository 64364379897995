import React from 'react';
import PropTypes from 'prop-types';

const IconMap = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        fill="#2A374A"
        fillRule="nonzero"
        stroke="none"
        d="M6 0a5 5 0 0 1 5 5c0 1.686-.901 3.338-2.387 4.906a15.566 15.566 0 0 1-2.336 2.01.5.5 0 0 1-.554 0 14.208 14.208 0 0 1-.737-.548 15.566 15.566 0 0 1-1.599-1.462C1.901 8.338 1 6.686 1 5a5 5 0 0 1 5-5zm0 1a4 4 0 0 0-4 4c0 1.377.786 2.818 2.113 4.219A14.578 14.578 0 0 0 6 10.886a14.578 14.578 0 0 0 1.887-1.668C9.214 7.819 10 6.378 10 5a4 4 0 0 0-4-4zm0 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm0 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
      />
    </svg>
  );
};

IconMap.defaultProps = { className: null };

const { string } = PropTypes;

IconMap.propTypes = {
  className: string,
};

export default IconMap;
