import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { IconCalendar, IconMap, IconLevel, NamedLink, ResponsiveImage } from '../../components';
import humanDate from './humanDate';
import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl, isPriceEnabled) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(
      intl,
      isPriceEnabled ? { ...price, amount: price.amount * 1.06 + 200 } : price
    );
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const locationLabel = address => {
  if (!address) return 'nie podano';
  const locationArray = address.split(',');
  const addressInfo =
    locationArray.length > 1
      ? locationArray[locationArray.length - 2].replace(/\d{2}-\d{3}/g, '')
      : locationArray[locationArray.length - 1];
  return addressInfo;
};

const locationLabelCity = address => {
  if (!address) return 'nie podano';
  const locationArray = address.split(',');
  if (locationArray.length > 2) {
    return (
      locationArray[locationArray.length - 3].replace(/\d{2}-\d{3}/g, '') +
      ', ' +
      locationArray[locationArray.length - 2].replace(/\d{2}-\d{3}/g, '')
    );
  }
  if (locationArray.length > 1) {
    return locationArray[locationArray.length - 2].replace(/\d{2}-\d{3}/g, '');
  }
  return locationArray[locationArray.length - 1];
};

const levelLabel = levels => {
  if (!levels || !levels.length || levels.length === 5) return 'Dla wszystkich';
  const levelSport = config.custom.sportLevels
    .filter(item => levels.includes(item.key))
    .map(level => level.label);
  if (levelSport.length === 1) return levelSport[0];
  return 'Różne poziomy zaawansowania';
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    homePageCard,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const {
    title = '',
    price,
    publicData: { termins, sport, date, levels, location, type },
    metadata: { sport_support, train_at_home } = {},
  } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(
    price,
    intl,
    currentListing.attributes.paymentEnabled
  );

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';

  const terminDisplay = () => {
    if (train_at_home) return 'video';
    return termins
      ? termins.filter(({ date }) => new Date(date) >= new Date()).length
        ? humanDate(termins.filter(({ date }) => new Date(date) >= new Date())[0].date, intl)
        : 'różne terminy'
      : humanDate(date, intl);
  };

  return (
    <NamedLink
      className={classes}
      name={type === 'business_card' ? 'OrganizerPage' : 'ListingPage'}
      params={{ id, slug }}
    >
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={classNames(css.info, { [css.lowerPadding]: type === 'business_card' })}>
        {type === 'event' && (
          <div className={classNames(css.sportIcon, { [css.homePageCardSportIcon]: homePageCard })}>
            {config.sportsConfig[sport] ? (
              <img alt={config.sportsConfig[sport].name} src={config.sportsConfig[sport].icon} />
            ) : null}
          </div>
        )}
        <div className={css.title}>
          {richText(title, {
            longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
            longWordClass: css.longWord,
          })}
        </div>
        {/* <div className={css.authorInfo}>
          <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
        </div> */}
        <div className={css.mainInfo}>
          <div className={css.mainInfoItem}>
            <IconMap className={css.icon} />
            {type === 'business_card' ? (
              <span>{locationLabelCity(location.address)}</span>
            ) : (
              <span>{train_at_home ? 'Online' : locationLabel(location.address)}</span>
            )}
          </div>
          {type === 'event' && !train_at_home && !currentListing.attributes.metadata.flex_date && (
            <div className={css.mainInfoItem}>
              <IconCalendar className={css.iconCalendar} />
              <span>{terminDisplay()}</span>
            </div>
          )}
        </div>
        {type === 'event' && (
          <div className={css.levelsContainer}>
            <IconLevel />
            <span>{levelLabel(levels)}</span>
          </div>
        )}
        {type === 'event' && (
          <div className={css.bottomPrice}>
            {listing.attributes.publicData.priceWithDiscount &&
              listing.attributes.publicData.price_hide && (
                <p>
                  cena od {(listing.attributes.publicData.priceWithDiscount * 1.06 + 200) / 100}
                </p>
              )}
            {!listing.attributes.publicData.price_hide
              ? !listing.attributes.publicData.priceWithDiscount && price && price.amount
                ? formattedPrice
                : 'Darmowe'
              : null}
            {listing.attributes.publicData.price_hide && 'różne ceny'}
          </div>
        )}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
