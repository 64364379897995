import React from 'react'
import classNames from 'classnames';
import css from './ProfilePage.css';


const InfoModal = () => {

    return <div className={classNames(css.infoModal)}>
        <p className={classNames(css.infoModalText)}>
            Wszyskie opinie ukazane na platformie Decathlon GO są zweryfikowane. Możliwość pozostawienia opinii przysługuje wyłącznie użytkownikowi, który wziął udział w wydarzeniu na platformie Decathlon GO. Komentarze mają na celu pomóc internautom korzystającym ze strony internetowych w dokonaniu wyboru.        </p>
    </div>
}

export default InfoModal