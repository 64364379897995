import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import css from '../../SearchPage.css';
import useRemoveSearchParam from '../../hooks/useRemoveSearchParam';
import { useUpdateQueryParams } from '../../hooks/useUpdateQueryParams';

const ButtonFilter = ({ type, content, params, defaultChecked, unCheckParams }) => {
  const [isChecked, setIsChecked] = useState(defaultChecked || false);
  const updateQueryParams = useUpdateQueryParams();
  const removeSearchParam = useRemoveSearchParam();

  const handleClick = () => setIsChecked(prev => !prev);
  useEffect(() => {
    setIsChecked(!!defaultChecked);
  }, [defaultChecked]);

  useEffect(() => {
    if (isChecked) {
      params.forEach(param => updateQueryParams(param.type, param.value));
    } else if ((type === 'children' || type === 'adults') && unCheckParams) {
      updateQueryParams(params[0].type, unCheckParams);
    } else if (type === 'free' && !unCheckParams) {
    } else {
      removeSearchParam(params.map(param => param.type));
    }
  }, [isChecked, type]);

  return (
    <button
      className={classNames(css.searchButton, isChecked ? css.active : '')}
      // style={isChecked ? { background: '#111111' } : {}}
      onClick={() => handleClick(type)}
    >
      {content}
    </button>
  );
};

export default ButtonFilter;
