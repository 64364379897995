import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';
import classNames from 'classnames';
import { isScrollingDisabled } from '../../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { loadData, updateTransaction, cancelTransaction, downloadTransactions } from './ConsoleTransactionPage.duck';
import { PaginationLinks, IconSpinner, Page, NamedLink } from '../../../components';
import { parse, stringify } from '../../../util/urlHelpers';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { TopbarContainer } from '../..';

import css from './ConsoleTransactionPage.css';
import SearchField from '../utils/SearchField/SearchField';
import ExtendedDataComponent from '../utils/ExtendedData/ExtendedData';
import TopNavigation from '../utils/TopNavigation/TopNavigation';
import ToggleEditField from '../utils/ToggleField/ToggleField';

const ConsoleTransactionPageComponent = props => {
  const {
    transactions,
    fetchInProgress,
    scrollingDisabled,
    pagination,
    intl,
    location: { search, pathname },
    history,
    onUpdateTransaction,
    onCancelTransaction,
    onDownloadTransactions,
    isAdmin
  } = props;
  const { entityId, userId, listingId } = parse(search);

  const [fieldOpened, setFieldOpened] = useState(null);
  const [entityOpened, setEntityOpened] = useState(entityId || null);

  useEffect(() => {
    if (!entityId) {
      setEntityOpened(null);
    }
  }, [entityId]);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ConsoleTransactionPage"
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const handleUpdateTransaction = (data) => {
    const { id, ...rest } = data;
    onUpdateTransaction({ id, updateFields: { ...rest } });
  };

  const selectedEntity = entityId || userId || listingId;

  const handleDownload = () => {
    const entityIdMaybe = entityId ? { entityId } : {};
    const userIdMaybe = userId ? { userId } : {};
    const listingIdMaybe = listingId ? { listingId } : {};

    onDownloadTransactions({
      ...entityIdMaybe,
      ...userIdMaybe,
      ...listingIdMaybe,
    })
  }

  if (!isAdmin) {
    return (
      <Page title="Console" scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
      </Page>
    );
  }

  return (
    <Page title="Console" scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        className={css.topbar}
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        mobileClassName={css.mobileTopbar}
      />
      <div className={css.container}>
        <TopNavigation currentPage="ConsoleTransactionPage" />
        <div>
          {/* <SearchField paramName="keywords" /> */}
        </div>
        <div className={css.header}>
          {
            (!selectedEntity) ?
              <h1 className={css.header1}>Transakcje</h1>
              :
              <NamedLink name="ConsoleTransactionPage" className={css.header1}>Transakcje  {`>`}</NamedLink>
          }
          {(selectedEntity) && <h2>{selectedEntity}</h2>}
          <span>{pagination && pagination.totalItems}</span>
          <button onClick={handleDownload} className={css.downloadBtn}>Pobierz</button>
        </div>
        {fetchInProgress ? (
          <div className={css.listItemsLoading}>
            <IconSpinner />
          </div>
        ) : (
          <ul>
            {transactions.map(l => (
              <div
                key={l.id.uuid}
                className={classNames(
                  css.listingWrapper,
                  {
                    [css.entityCollapsed]: entityOpened && entityOpened !== l.id.uuid,
                    [css.entityExpanded]: entityOpened === l.id.uuid,
                  }
                )}
              >
                <div
                  className={classNames(css.listingRow, { [css.deleted]: l.attributes.deleted })}
                  onClick={() => setEntityOpened(entityOpened === l.id.uuid ? null : l.id.uuid)}
                >
                  <div>
                    <div>
                      {l.listing.images && l.listing.images[0] ? (
                        <img src={l.listing.images[0].attributes.variants['square-small'].url} />
                      ) : (
                        <div className={css.imagePlaceholder} />
                      )}
                    </div>
                    <div>
                      {l.attributes.state}
                    </div>
                  </div>
                  <div className={css.titleWrapper}>
                    <h2 className={css.title}>
                      {l.listing.attributes.title}
                      {" - "}
                      {l.customer.attributes.profile.displayName}
                      {" - "}
                      {l.provider.attributes.profile.displayName}
                    </h2>
                  </div>
                  <span className={css.createdAt}>
                    {intl.formatDate(l.attributes.lastTransitionAt, { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })}
                  </span>
                  <div className={css.createdAt}>
                    {l.attributes.processState === 'accepted' && <button onClick={() => onCancelTransaction(l.id.uuid)} className={css.cancelTransactionBtn}>Anuluj</button>}
                  </div>
                </div>
                {entityOpened === l.id.uuid && (
                  <div className={css.more}>
                    <div className={css.additionalInfo}>
                      <div />
                      <div className={css.additionalContent}>
                        <div>
                          Uczestnik:
                          <NamedLink
                            name="ConsoleUserPage"
                            to={{
                              search: `?entityId=${l.customer.id.uuid}`,
                            }}
                            className={css.entityLink}
                          >
                            {l.customer.attributes.profile.displayName}
                          </NamedLink>
                        </div>
                        <div>
                          Organizator:
                          <NamedLink
                            name="ConsoleUserPage"
                            to={{
                              search: `?entityId=${l.provider.id.uuid}`,
                            }}
                            className={css.entityLink}
                          >
                            {l.provider.attributes.profile.displayName}
                          </NamedLink>
                        </div>
                      </div>
                      <div className={css.timeline}>
                        <h3>Timeline</h3>
                        {l.attributes.transitions.map(t => (
                          <div className={css.transition}>
                            <div>
                              <div>{t.transition}</div>
                              <div className={css.transitionDate}>{intl.formatDate(t.createdAt, { day: 'numeric', month: 'numeric', year: 'numeric' })}</div>
                              <div className={css.transitionBy}>{t.by}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <ExtendedDataComponent
                      data={l.attributes.protectedData}
                      dataType="privateData"
                      onUpdate={handleUpdateTransaction}
                      fieldOpened={fieldOpened}
                      setFieldOpened={setFieldOpened}
                      entityId={l.id.uuid}
                    />
                    <ExtendedDataComponent
                      data={l.attributes.metadata}
                      dataType="metadata"
                      onUpdate={handleUpdateTransaction}
                      fieldOpened={fieldOpened}
                      setFieldOpened={setFieldOpened}
                      entityId={l.id.uuid}
                    />
                  </div>
                )}
              </div>
            ))}
          </ul>
        )}
        {paginationLinks}
      </div>
    </Page>
  );
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchUserListError, pagination, transactionList, updateUserInProgress } =
    state.ConsoleTransactionPage;
  const isAdmin = state.user.currentUser && state.user.currentUser.attributes.isAdmin;
  return {
    fetchInProgress,
    fetchUserListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionList),
    updateUserInProgress,
    isAdmin
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateTransaction: params => dispatch(updateTransaction(params)),
  onCancelTransaction: params => dispatch(cancelTransaction(params)),
  onDownloadTransactions: params => dispatch(downloadTransactions(params)),
});

const ConsoleTransactionPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleTransactionPageComponent);

ConsoleTransactionPage.loadData = loadData;

export default ConsoleTransactionPage;
