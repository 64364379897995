import React from 'react';
import { Link } from 'react-router-dom';
import css from './ModalMissingInformation.css';
import profileImg from '../../containers/AuthenticationPage/images/profile.png';
import profile2xImg from '../../containers/AuthenticationPage/images/profile@2x.png';

export default function NameMissing({ closeModal, data }) {
  const linkTo = data && data.eventId ? `/profile-settings?fromDraft=${data.eventId}` : '/profile-settings'
  return (
    <div className={css.onboardingWrapper}>
      <img src={profileImg} srcSet={`${profileImg}, ${profile2xImg} 2x`} className={css.onboardingImg} />
      <h2 className={css.onnoardingHeader}>Uzupełnij profil</h2>
      <p className={css.onboardingDesc}>Przed opublikowaniem wydarzenia wymagane jest uzupełnienie profilu o imię i nazwisko, aby użytkownicy widzieli z kim mogą się kontaktować.</p>
      <Link to={linkTo} onClick={closeModal} className={css.seeMoreBtn}>Uzupełnij profil</Link>
    </div>
  );
}