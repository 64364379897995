import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FormItem from '../components/FormItem';
import css from './style.css';

const AddChildForm = ({ handleAddChild, setIsChildChecked, editedChildData }) => {

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();


    const onSubmit = (data) => {
        handleAddChild(({ ...data, isChecked: true }));
    };

    useEffect(() => {
        if (editedChildData) {
            setValue('firstName', editedChildData.firstName);
            setValue('lastName', editedChildData.lastName);
            setValue('age', editedChildData.age);
        }
    }, [editedChildData])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem label={'Imię'} >
            <input className={css.eventTypeContainer} {...register('firstName', { required: true })} />
            {errors.firstName && <span style={{ color: 'red' }}>To pole jest wymagane</span>}
        </FormItem>

        <FormItem label={'Nazwisko'} >
            <input className={css.eventTypeContainer}  {...register('lastName', { required: true })} />
            {errors.lastName && <span style={{ color: 'red' }}>To pole jest wymagane</span>}
        </FormItem>

        <FormItem label={'Wiek'} >
            <input className={css.eventTypeContainer} max={17} type="number" {...register('age', { required: true })} />
            {errors.age && <span style={{ color: 'red' }}>To pole jest wymagane</span>}
        </FormItem>

        <div className={css.buttons}>
            <button className={css.cancelButton} onClick={() => setIsChildChecked(false)} type="button">ANULUJ</button>
            <button className={css.confirmButton} type="submit">ZAPISZ</button>
        </div>
    </form>
}

export default AddChildForm;