import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, useEffect, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';
import { injectIntl, intlShape } from 'react-intl';
import onClickOutside from 'react-onclickoutside';
import { withRouter } from 'react-router-dom';
import { ReactComponent as SearchIcon } from '../../assets/searchPage/search.svg';
import {
  FieldDateRangeController,
  Form,
  InlineTextButton,
  LocationAutocompleteInput,
  PrimaryButton,
} from '../../components';
import config from '../../config';
import { cities } from '../SearchPage/constants';
import { findKeyByName } from '../SearchPage/utils';
import FieldCheckboxCustom from './FieldCheckboxCustom';
import css from './SimpleSearchForm.css';

const popularSportsArray = [
  'fitness',
  'badminton',
  'football',
  'volleyball',
  'basketball',
  'running',
  'yoga',
  'swimming',
  'cycling',
  'strength_sports',
  'tabletennis',
];

const today = new Date();

const start = new Date();
// start.setUTCHours(0, 0, 0, 0);

const end = new Date(today);
end.setUTCHours(23, 59, 59, 999);
const ranges = [
  { key: 'today', dateFrom: start, dateTo: end, label: ' Dzisiaj' },
  // { key: 'tomorrow', ...getTommorow(), label: 'Jutro' },
  { key: 'weekend', ...getWeekend(), label: 'Weekend' },
  { key: 'week', ...getWeek(), label: 'Tydzień' },
];
const { sportsConfig } = config;

const sportsGeneral = Object.keys(sportsConfig).map(sport => ({
  key: sport,
  label: sportsConfig[sport].name,
  icon: sportsConfig[sport].icon,
}));

const popularSports = sportsGeneral
  .filter(sport => popularSportsArray.indexOf(sport.key) !== -1)
  .sort((a, b) => (popularSportsArray.indexOf(a.key) < popularSportsArray.indexOf(b.key) ? -1 : 1));

const otherSports = sportsGeneral
  .filter(sport => popularSportsArray.indexOf(sport.key) === -1)
  .sort((a, b) => a.label.localeCompare(b.label));

const sports = [...popularSports, ...otherSports];

class SimpleSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSportModalOpen: false,
      isLocationModalOpen: false,
      isDateModalOpen: false,
      isCalendarOpen: false,
      isMounted: false,
      initialValues: {},
      isSearchPage: false,
      isLandingPage: false,
    };
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
    this.popupControllerRef = null;
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const startDateFromParams = params.get('start') ? new Date(params.get('start')) : '';
    const endDateFromParams = params.get('end') ? new Date(params.get('end')) : '';
    const location = (this.props.params.city !== 'cala-polska' && this.props.params.city) || ''; //locationFromParams
    this.setState({
      initialValues: {
        sport:
          this.props.params.sport && this.props.params.sport !== 'wszystkie-sporty'
            ? !findKeyByName(sportsConfig, this.props.params.sport)
              ? []
              : [findKeyByName(sportsConfig, this.props.params.sport)]
            : [],
        location: {
          search: location ? `${cities[location] ? cities[location] : location}, Polska` : '',
        },
        startDate: startDateFromParams,
        endDate: endDateFromParams,
      },
      isMounted: true,
    });
  }

  componentDidUpdate(prevProps) {
    // Check if URL parameters have changed
    if (
      prevProps.location.search !== this.props.location.search ||
      prevProps.match.params.sport !== this.props.match.params.sport ||
      prevProps.match.params.city !== this.props.match.params.city
    ) {
      this.updateInitialValues();
    }
  }

  updateInitialValues() {
    const params = new URLSearchParams(window.location.search);
    const startDateFromParams = params.get('start') ? new Date(params.get('start')) : '';
    const endDateFromParams = params.get('end') ? new Date(params.get('end')) : '';
    const location = (this.props.params.city !== 'cala-polska' && this.props.params.city) || ''; // locationFromParams
    this.setState({
      initialValues: {
        sport:
          this.props.params.sport && this.props.params.sport !== 'wszystkie-sporty'
            ? !findKeyByName(sportsConfig, this.props.params.sport)
              ? []
              : [findKeyByName(sportsConfig, this.props.params.sport)]
            : [],
        location: {
          search: location ? `${cities[location] ? cities[location] : location}, Polska` : '',
        },
        startDate: startDateFromParams,
        endDate: endDateFromParams,
      },
    });
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }
  render() {
    const isModalOpen =
      this.state.isDateModalOpen || this.state.isLocationModalOpen || this.state.isSportModalOpen;
    const isMobile = this.state.isMounted
      ? window.matchMedia('(max-width: 1023px)').matches
      : false;
    const isSearchPage = this.state.isMounted ? window.location.pathname.includes('/s/') : false;
    const isLandingPage = this.state.isMounted ? window.location.pathname === '/' : false;

    const firstInput = typeof document !== 'undefined' && document.querySelector('#firstInput');
    const firstInputWidth = firstInput && firstInput.clientWidth + 18;

    return (
      <FinalForm
        {...this.props}
        initialValues={this.state.initialValues}
        render={formRenderProps => {
          const { rootClassName, className, form, handleSubmit, values } = formRenderProps;
          const classes = classNames(rootClassName, className);

          const openSportModal = event => {
            event.stopPropagation();
            event.preventDefault();

            if (isMobile) {
              return this.setState({ isMobileModalOpen: true });
            } else {
              this.setState({
                isSportModalOpen: !this.state.isSportModalOpen,
                isLocationModalOpen: false,
                isDateModalOpen: false,
              });
            }
          };

          const openLocationModal = event => {
            event.stopPropagation();
            event.preventDefault();

            if (isMobile) {
              return this.setState({ isMobileModalOpen: true });
            } else {
              this.setState({
                isLocationModalOpen: !this.state.isLocationModalOpen,
                isSportModalOpen: false,
                isDateModalOpen: false,
              });
            }
          };

          const openDateModal = event => {
            event.stopPropagation();
            event.preventDefault();

            if (isMobile) {
              this.setState({ isMobileModalOpen: true });
            } else {
              this.setState({
                isDateModalOpen: !this.state.isDateModalOpen,
                isLocationModalOpen: false,
                isSportModalOpen: false,
              });
            }
          };
          const closeDateModal = () => {
            this.setState({ isDateModalOpen: false, isMobileModalOpen: false });
          };
          const closeModalMobile = () => {
            this.setState({ isMobileModalOpen: false });
          };

          const closeModalSport = () => {
            // form.change('sport', []);
            // form.change('eventTypes', 'all');
            form.change('sports', '');
            this.setState({ isSportModalOpen: false });
          };

          const closeModalLocation = () => {
            // form.change('location', '');
            this.setState({ isLocationModalOpen: false });
          };

          const closeModalDate = () => {
            this.setState({ isDateModalOpen: false });
          };

          const handleSubmitLocation = () => {
            this.setState({ isLocationModalOpen: false });
          };

          // Allow form submit only when the place has changed
          // const handleSubmit = (e, values) => {
          //   e.preventDefault();
          //   console.log(values)
          // };
          const valuesSport =
            values.sport && values.sport.length
              ? values.sport.map(item => {
                  const sport = sportsGeneral.find(el => el.key === item);
                  return sport.label;
                })
              : null;

          const onCheck = value => {
            form.change('sport', [value]);
            closeModalSport();
          };

          const onDateRangeSelect = valueObj => {
            form.change('startDate', valueObj.startDate);
            form.change('endDate', valueObj.endDate);
            // this.setState({isCalendarOpen: false});
            closeModalDate();
          };

          const handleCalendarCancel = () => {
            form.change('startDate', '');
            form.change('endDate', '');
            this.setState({ isCalendarOpen: false });
            closeModalDate();
          };

          const handleCalendarConfirm = () => {
            this.setState({ isCalendarOpen: false });
            closeModalDate();
          };

          const handleCalendarVisibility = () => {
            this.setState({ isCalendarOpen: !this.state.isCalendarOpen });
          };

          const handleFormClear = () => {
            form.change('sport', []);
            form.change('location', {});
            form.change('startDate', '');
            form.change('endDate', '');
          };

          const handleSportClear = e => {
            e.stopPropagation();
            form.change('sport', []);
          };

          const handleDatesClear = e => {
            e.stopPropagation();
            form.change('startDate', '');
            form.change('endDate', '');
          };

          return (
            <Form
              className={classes + css.insetForm}
              style={isLandingPage ? { position: 'static' } : {}}
              onSubmit={handleSubmit}
            >
              <div className={css.formContainer}>
                <div
                  className={css.fieldsContainer}
                  // style={isLandingPage ? { flexGrow: '1', placeContent: 'center' } : {}}
                >
                  <>
                    <div
                      id="firstInput"
                      className={classNames(
                        css.inputField,
                        css.inputSportField,
                        isModalOpen && !this.state.isSportModalOpen ? css.inputGray : '',
                        isModalOpen && this.state.isSportModalOpen ? css.active : ''
                      )}
                      style={isLandingPage ? { display: 'flex', minWidth: '190px' } : {}}
                      onClick={openSportModal}
                      {...(isModalOpen && {
                        style: { borderRadius: 0, display: 'flex', minWidth: '190px' },
                      })}
                    >
                      <span className={css.label}>Co</span>
                      {valuesSport ? (
                        <span className={css.inputValue}>
                          <span>{valuesSport.join(', ')}</span>
                          <ClearIcon onClick={handleSportClear} />
                        </span>
                      ) : (
                        <span className={css.placeholder}>Wybierz wydarzenie</span>
                      )}
                    </div>

                    <span className={css.divider}></span>

                    <div
                      className={classNames(
                        css.inputField,
                        css.inputLocationField,
                        isModalOpen && !this.state.isLocationModalOpen ? css.inputGray : '',
                        isModalOpen && this.state.isLocationModalOpen ? css.active : ''
                      )}
                      style={isLandingPage ? { display: 'flex', minWidth: '190px' } : {}}
                      onClick={openLocationModal}
                    >
                      <span className={css.label}>Gdzie</span>
                      {this.state.isLocationModalOpen || this.state.isMobileModalOpen ? null : (
                        <>
                          <span
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            <input
                              type="text"
                              className={css.inputValue}
                              value={values.location ? values.location.search : ''}
                              placeholder="Wybierz lokalizację"
                            ></input>
                            {values.location && values.location.search && (
                              <ClearIcon
                                onClick={e => {
                                  e.stopPropagation();
                                  form.change('location', '');
                                }}
                              />
                            )}
                          </span>

                          <svg
                            className={css.pinIcon}
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M14.0803 4.88659C11.9617 2.34237 8.10787 2.34585 5.99402 4.89705L5.9908 4.90094C4.47509 6.70535 4.47508 9.38173 5.99078 11.1861L5.99974 11.1968L10.0041 16.1479L14.0016 11.2783C15.5259 9.38892 15.5296 6.78061 14.0803 4.88659ZM4.84059 3.93808C7.55754 0.661371 12.528 0.662017 15.244 3.94002L15.2527 3.95048L15.261 3.96125C17.1361 6.39892 17.1375 9.78295 15.1666 12.2231L15.1629 12.2278L9.99553 18.5225L4.83761 12.1454C2.85847 9.78391 2.85947 6.29848 4.84059 3.93808ZM6.9165 8.0852C6.9165 6.37256 8.21823 4.91853 9.99984 4.91853C11.7975 4.91853 13.0832 6.47299 13.0832 8.0852C13.0832 9.74941 11.6641 11.1685 9.99984 11.1685C8.35319 11.1685 6.9165 9.8501 6.9165 8.0852ZM9.99984 6.41853C9.11478 6.41853 8.4165 7.13118 8.4165 8.0852C8.4165 8.98697 9.14648 9.66853 9.99984 9.66853C10.8356 9.66853 11.5832 8.92099 11.5832 8.0852C11.5832 7.19741 10.8688 6.41853 9.99984 6.41853Z"
                              fill="#3643BA"
                            />
                          </svg>
                        </>
                      )}
                    </div>

                    <span className={css.divider}></span>

                    <div
                      className={classNames(
                        css.inputField,
                        css.inputDateField,
                        isModalOpen && !this.state.isDateModalOpen ? css.inputGray : '',
                        isModalOpen && this.state.isDateModalOpen ? css.active : ''
                      )}
                      style={isLandingPage ? { display: 'flex', minWidth: '190px' } : {}}
                      onClick={openDateModal}
                    >
                      <span className={css.label}>Kiedy</span>
                      {values.startDate && values.endDate ? (
                        <span className={css.inputValue}>
                          <span>{formatDateRange(values.startDate, values.endDate)}</span>
                          <ClearIcon onClick={handleDatesClear} />
                        </span>
                      ) : (
                        <span className={css.placeholder}>Dodaj daty</span>
                      )}
                    </div>
                  </>
                  {!isLandingPage && (
                    <button className={css.submitButton} onClick={openSportModal}>
                      <SearchIcon />
                    </button>
                  )}
                  {this.state.isMobileModalOpen ? (
                    <FieldsModal modalClassName={css.mobileModal} onClickOutside={closeModalMobile}>
                      <MobileModalContent
                        {...{ form }}
                        onSubmit={handleSubmit}
                        onClear={handleFormClear}
                        onModalClose={closeModalMobile}
                        values={values}
                      />
                    </FieldsModal>
                  ) : null}
                  {this.state.isLocationModalOpen ? (
                    <FieldsModal
                      modalClassName={classNames(css.sportModal, {
                        [css.isSearchDropdown]: values && values.location && values.location.search,
                      })}
                      onClickOutside={closeModalLocation}
                    >
                      <Field
                        name="location"
                        format={null}
                        render={({ input, meta }) => {
                          return (
                            <LocationAutocompleteInput
                              autoFocus
                              placeholder="Wpisz lokalizację"
                              inputRef={node => {
                                this.searchInput = node;
                              }}
                              styles={{ left: `${firstInputWidth}px` }}
                              input={input}
                              inputClassName={classNames(css.inputValue, css.locationSearch)}
                              meta={meta}
                              modalView={true}
                              closeModalLocation={closeModalLocation}
                              handleSubmitLocation={handleSubmitLocation}
                            />
                          );
                        }}
                      />
                    </FieldsModal>
                  ) : null}
                  {this.state.isSportModalOpen ? (
                    <FieldsModal modalClassName={css.sportModal} onClickOutside={closeModalSport}>
                      <div className={css.optionsContainer}>
                        <div className={classNames(css.optionsTitle, css.title)}>
                          Szukaj według sportu
                        </div>
                        <div className={css.sportsContainer}>
                          <ShowMoreToggleWrapper limit={8}>
                            {sports
                              .filter(
                                sport =>
                                  !values.sports ||
                                  sport.label.toLowerCase().includes(values.sports.toLowerCase())
                              )
                              .map(({ key, label }) => {
                                return (
                                  <Field
                                    key={key}
                                    name="sport"
                                    type="checkbox"
                                    value={key}
                                    render={({ input, meta }) => {
                                      const { name, onChange, ...restInput } = input;

                                      const handleCheck = e => {
                                        onCheck(e.target.value);
                                      };

                                      const checkboxInput = { ...restInput, onChange: handleCheck };

                                      return (
                                        <FieldCheckboxCustom
                                          id={key}
                                          label={label}
                                          name={name}
                                          {...checkboxInput}
                                        />
                                      );
                                    }}
                                  />
                                );
                              })}
                          </ShowMoreToggleWrapper>
                        </div>
                        {/* <div className={css.moreButton}><span onClick={()=>this.setState({ sportsToChoose: sports })}>Więcej sportów</span></div> */}
                      </div>
                    </FieldsModal>
                  ) : null}
                  {this.state.isDateModalOpen ? (
                    <FieldsModal
                      modalClassName={classNames(css.sportModal, css.dateModal)}
                      onClickOutside={closeModalSport}
                    >
                      <div className={css.optionsContainer}>
                        {/* <div className={classNames(css.optionsTitle, css.title)}>Szukaj według sportu</div> */}
                        <div className={css.sportsContainer}>
                          {ranges.map(({ key, label, dateFrom, dateTo }) => {
                            return (
                              <Field
                                key={key}
                                name="range"
                                type="checkbox"
                                value={{ dateFrom, dateTo }}
                                render={({ input, meta }) => {
                                  const { name, onChange, ...restInput } = input;

                                  const handleChange = () =>
                                    onDateRangeSelect({ startDate: dateFrom, endDate: dateTo });

                                  const checkboxInput = { ...restInput, onChange: handleChange };

                                  return (
                                    <FieldCheckboxCustom
                                      id={key}
                                      label={label}
                                      name={name}
                                      {...checkboxInput}
                                    />
                                  );
                                }}
                              />
                            );
                          })}
                          <FieldCheckboxCustom
                            id={'custom'}
                            label="Wybierz datę"
                            name={'custom'}
                            onChange={handleCalendarVisibility}
                          />
                        </div>
                        {this.state.isCalendarOpen ? (
                          <>
                            <FieldDateRangeController onChange={onDateRangeSelect} />
                            <div className={css.calendarBtnsContainer}>
                              <a onClick={handleCalendarCancel} className={css.cancelLink}>
                                Pomiń
                              </a>
                              <PrimaryButton
                                rootClassName={css.confirmButton}
                                onClick={handleCalendarConfirm}
                              >
                                Zatwierdź
                              </PrimaryButton>
                            </div>
                          </>
                        ) : null}
                      </div>
                    </FieldsModal>
                  ) : null}
                  {!(isMobile && isSearchPage) && (
                    <PrimaryButton rootClassName={css.addButton} onSubmit={handleSubmit}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.2998 3.4502C6.61402 3.4502 3.5498 6.51441 3.5498 10.2002C3.5498 13.886 6.61402 16.9502 10.2998 16.9502C13.9856 16.9502 17.0498 13.886 17.0498 10.2002C17.0498 6.51441 13.9856 3.4502 10.2998 3.4502ZM2.0498 10.2002C2.0498 5.68598 5.78559 1.9502 10.2998 1.9502C14.814 1.9502 18.5498 5.68598 18.5498 10.2002C18.5498 14.7144 14.814 18.4502 10.2998 18.4502C5.78559 18.4502 2.0498 14.7144 2.0498 10.2002ZM18.0301 16.9699L21.9301 20.8699L20.8695 21.9305L16.9695 18.0305L18.0301 16.9699Z"
                          fill="white"
                        />
                      </svg>
                      <span>Wyszukaj</span>
                    </PrimaryButton>
                  )}
                </div>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

SimpleSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

SimpleSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SimpleSearchForm = injectIntl(SimpleSearchFormComponent);

// export default SimpleSearchForm;
export default withRouter(props => {
  const params = new URLSearchParams(props.location.search);
  return (
    <SimpleSearchForm
      {...props}
      params={{
        sport: props.match.params.sport,
        city: props.match.params.city,
      }}
    />
  );
});
class FieldsModalPure extends React.Component {
  handleClickOutside = () => {
    this.props.onClickOutside();
  };

  render() {
    const { children, modalClassName } = this.props;

    return <div className={modalClassName}>{children}</div>;
  }
}

const FieldsModal = onClickOutside(FieldsModalPure);

function SearchList({ options, property, searchQuery, onSelect, order }) {
  const filteredOptions = options.filter(option =>
    option[property].toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={css.searchDropdown} style={{ left: 16 + order * 190 }}>
      {filteredOptions.map(el => (
        <SearchOption {...{ onSelect, searchQuery }} {...el} key={el[property]} value={el.key} />
      ))}
    </div>
  );
}

function SearchOption({ label, value, onSelect, searchQuery }) {
  const handleSelect = () => {
    onSelect(value);
  };

  return (
    <div onClick={handleSelect} className={css.searchOption}>
      <span dangerouslySetInnerHTML={{ __html: boldString(label, searchQuery) }} />
    </div>
  );
}

export function boldString(str, substring) {
  // Escape special characters in the substring for use in a regular expression
  const escapedSubstring = substring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a case-insensitive regular expression to find the substring
  const regex = new RegExp(escapedSubstring, 'gi');

  // Split the string at the substring (case-insensitive)
  const parts = str.split(regex);

  // Use a case-insensitive match to reassemble the string
  const matches = str.match(regex) || [];

  // Reassemble the string with the substring in between, wrapping the parts in <b> tags
  return parts
    .map(
      (part, index) =>
        `${index < parts.length - 1 ? `<b>${part}</b>${matches[index]}` : `<b>${part}</b>`}`
    )
    .join('');
}

// function getTommorow() {
//   const date = new Date();
//   const tommorow = date.setDate(date.getDate() + 1);
//   return { dateFrom: tommorow, dateTo: tommorow }
// }

function getWeekend() {
  const saturday = 6; // for Saturday
  const sunday = 7; // for Sunday

  const today = moment().isoWeekday();

  if (today === saturday) {
    return {
      dateFrom: moment().toDate(),
      dateTo: moment()
        .add(1, 'days')
        .toDate(),
    };
  } else if (today === sunday) {
    return {
      dateFrom: moment()
        .add(-1, 'days')
        .toDate(),
      dateTo: moment().toDate(),
    };
  }
  return {
    dateFrom: moment()
      .isoWeekday(saturday)
      .toDate(),
    dateTo: moment()
      .isoWeekday(sunday)
      .toDate(),
  };
}

function getWeek() {
  const dateFrom = moment().toDate();
  const dateTo = moment()
    .add(7, 'days')
    .toDate();
  return { dateFrom, dateTo };
}

function formatDateRange(startDate, endDate) {
  if (!startDate || !endDate) return '';

  if (moment(startDate).isSame(moment(endDate))) {
    return moment(startDate).format('DD.MM.YYYY');
  } else {
    return `${moment(startDate).format('DD.MM')} - ${moment(endDate).format('DD.MM')}`;
  }
}

function MobileModalContent({ onModalClose, onSubmit, onClear, values, form }) {
  const [view, setView] = useState('home');

  const onGoBack = () => setView('home');

  useEffect(() => {
    document.documentElement.classList.add('no-scroll');

    return () => document.documentElement.classList.remove('no-scroll');
  });

  const content = {
    home: <HomeContent {...{ onModalClose, onSubmit, setView, onClear, values, form }} />,
    sports: <SportsContent {...{ onGoBack, form }} />,
    location: <LocationContent {...{ onGoBack, form }} />,
    date: <DateContent {...{ onGoBack, form, values }} />,
  }[view];

  return content;
}

function HomeContent({ onModalClose, onSubmit, onClear, setView, values, form }) {
  return (
    <div className={css.mobileHomeContent}>
      <div>
        <div className={css.mobileModalHeader} onClick={onModalClose}>
          <div className={css.backIconWrapper}>
            <Chevron />
          </div>
          Wyszukiwanie
        </div>
        <div className={css.mobileFieldsWrapper}>
          <MobileSportsField {...{ setView, form }} value={values.sport} />
          <MobileLocationField {...{ setView, form }} value={values.location} />
          <MobileDateField
            {...{ setView, form }}
            startDate={values.startDate}
            endDate={values.endDate}
          />
        </div>
      </div>
      <div className={css.mobileModalFooter}>
        <InlineTextButton type="button" rootClassName={css.clearButton} onClick={onClear}>
          <span>Wyczyść</span>
        </InlineTextButton>
        <PrimaryButton
          rootClassName={css.addButton}
          onClick={() => {
            onSubmit();
            onModalClose();
          }}
          // onSubmit={() => {
          //   onSubmit();
          //   // onModalClose();
          // }}
        >
          <span>Wyszukaj</span>
        </PrimaryButton>
      </div>
    </div>
  );
}

function SportsContent({ onGoBack, form }) {
  return (
    <div className={css.mobileSportsContent}>
      <div className={css.mobileModalHeader}>
        <div className={css.backIconWrapper} onClick={onGoBack}>
          <Chevron />
        </div>
        Wyszukiwanie
      </div>
      <div className={css.optionsContainer}>
        <div className={classNames(css.optionsTitle, css.title)}>Szukaj według sportu</div>
        <div className={classNames(css.sportsContainer)}>
          <ShowMoreToggleWrapper limit={8}>
            {sports.map(({ key, label }) => {
              return (
                <Field
                  key={key}
                  name="sport"
                  type="checkbox"
                  value={key}
                  render={({ input, meta }) => {
                    const { name, onChange, ...restInput } = input;

                    const handleCheck = e => {
                      form.change('sport', [e.target.value]);
                      onGoBack();
                    };

                    const checkboxInput = { ...restInput, onChange: handleCheck };

                    return (
                      <FieldCheckboxCustom id={key} label={label} name={name} {...checkboxInput} />
                    );
                  }}
                />
              );
            })}
          </ShowMoreToggleWrapper>
        </div>
      </div>
    </div>
  );
}

function LocationContent({ onGoBack }) {
  return (
    <div className={css.mobileLocationContent}>
      <div className={css.mobileModalHeader}>
        <div className={css.backIconWrapper} onClick={onGoBack}>
          <Chevron />
        </div>
      </div>
      <Field
        name="location"
        format={null}
        render={({ input, meta }) => {
          return (
            <LocationAutocompleteInput
              placeholder="Wpisz lokalizację"
              input={input}
              inputClassName={classNames(css.mobileSearchInput, css.locationSearchMobile)}
              meta={meta}
              modalView={true}
              closeModalLocation={onGoBack}
              handleSubmitLocation={onGoBack}
            />
          );
        }}
      />
    </div>
  );
}

function DateContent({ onGoBack, form, values }) {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleCalendarVisibility = () => {
    setIsCalendarOpen(!isCalendarOpen);
  };

  const handleDateRangeSelect = val => {
    form.change('startDate', val.startDate);
    form.change('endDate', val.endDate);
  };

  const handleDateRangeSelectWithClose = val => {
    form.change('startDate', val.startDate);
    form.change('endDate', val.endDate);
    onGoBack();
  };

  const handleCancel = () => {
    form.change('startDate', '');
    form.change('endDate', '');
    onGoBack();
  };

  return (
    <div className={css.mobileDateContent}>
      <div className={css.mobileModalHeader}>
        <div className={css.backIconWrapper} onClick={onGoBack}>
          <Chevron />
        </div>
        Wyszukiwanie
      </div>
      <div className={css.optionsContainer}>
        <div className={classNames(css.optionsTitle, css.title)}>Szukaj według sportu</div>
        <div className={css.sportsContainer}>
          {ranges.map(({ key, label, dateFrom, dateTo }) => {
            return (
              <Field
                key={key}
                name="range"
                type="checkbox"
                value={{ dateFrom, dateTo }}
                render={({ input, meta }) => {
                  const { name, onChange, ...restInput } = input;

                  const handleChange = () =>
                    handleDateRangeSelectWithClose({ startDate: dateFrom, endDate: dateTo });

                  const checkboxInput = { ...restInput, onChange: handleChange };

                  return (
                    <FieldCheckboxCustom id={key} label={label} name={name} {...checkboxInput} />
                  );
                }}
              />
            );
          })}
          <FieldCheckboxCustom
            id={'custom'}
            label="Wybierz datę"
            name={'custom'}
            onChange={handleCalendarVisibility}
          />
        </div>
      </div>
      {isCalendarOpen ? (
        <>
          <FieldDateRangeController onChange={handleDateRangeSelect} />
          <div className={css.calendarBtnsContainer}>
            <a onClick={handleCancel} className={css.cancelLink}>
              Pomiń
            </a>
            <PrimaryButton rootClassName={css.confirmButton} onClick={onGoBack}>
              Zatwierdź
            </PrimaryButton>
          </div>
        </>
      ) : null}
    </div>
  );
}

function MobileDateField({ setView, startDate, endDate, form }) {
  const handleClear = e => {
    e.stopPropagation();
    form.change('startDate', '');
    form.change('endDate', '');
  };

  return (
    <div className={css.mobileField} onClick={() => setView('date')}>
      <span className={css.label}>Kiedy</span>
      <div>
        <span className={css.value}>
          {startDate && endDate ? formatDateRange(startDate, endDate) : 'Dodaj daty'}
        </span>
        {startDate && endDate && (
          <span className={css.clear} onClick={handleClear}>
            <ClearIcon />
          </span>
        )}
      </div>
    </div>
  );
}

function MobileLocationField({ setView, value, form }) {
  return (
    <div className={css.mobileField} onClick={() => setView('location')}>
      <span className={css.label}>Gdzie</span>
      <div>
        <span className={css.value}>{(value && value.search) || 'Wybierz lokalizację'}</span>
        {value && value.search && (
          <span
            className={css.clear}
            onClick={e => {
              e.stopPropagation();
              form.change('location', '');
            }}
          >
            <ClearIcon />
          </span>
        )}
      </div>
    </div>
  );
}

function MobileSportsField({ setView, value, form }) {
  const getLabel = () => {
    const sport = sportsGeneral.find(el => el.key === value[0]);
    return sport.label;
  };

  return (
    <div className={css.mobileField} onClick={() => setView('sports')}>
      <span className={css.label}>Co</span>
      <div>
        <span className={css.value}>{value.length ? getLabel() : 'Wybierz wydarzenie'}</span>
        {value.length > 0 && (
          <span
            className={css.clear}
            onClick={e => {
              e.stopPropagation();
              form.change('sport', []);
            }}
          >
            <ClearIcon />
          </span>
        )}
      </div>
    </div>
  );
}

function Chevron() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
      <path
        d="M2.828 6.99999L7.778 11.95L6.364 13.364L0 6.99999L6.364 0.635986L7.778 2.04999L2.828 6.99999Z"
        fill="#101010"
      />
    </svg>
  );
}

function ShowMoreToggleWrapper({ children, limit }) {
  const [isOpen, setIsOpen] = useState(false);

  const toggler = (
    <div className={css.seeMoreButton}>
      <span onClick={() => setIsOpen(!isOpen)}>Pokaż wszystkie</span>
    </div>
  );

  if (isOpen) return children;

  const limitedChildren = React.Children.toArray(children).slice(0, limit);

  return (
    <div>
      <div className={css.sportsContainer}>{limitedChildren}</div>
      {toggler}
    </div>
  );
}

function ClearIcon(props) {
  return (
    <svg
      {...props}
      style={{ marginLeft: '8px', flex: '0 0 auto' }}
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <path
        d="M8.66747 7.05732L11.9675 3.75732L12.9101 4.69999L9.61014 7.99999L12.9101 11.3L11.9675 12.2427L8.66747 8.94266L5.36747 12.2427L4.4248 11.3L7.7248 7.99999L4.4248 4.69999L5.36747 3.75732L8.66747 7.05732Z"
        fill="#101010"
      />
    </svg>
  );
}
