import React from 'react';
import SingleFormElement from './SingleFormElement';
import { Reorder } from 'framer-motion/dist/framer-motion';

const QuestionsList = ({ formElements, setFormElements, handleEditQuestion }) => {
    const handleRemoveElement = (elemnentId) => {
        setFormElements((prev) => prev.filter(({ id }) => id !== elemnentId));
    };

    const hadleIsRequiredField = (isChecked, elemnentId) => {
        setFormElements(prev => {
            const requiredElement = prev.find(({ id }) => id === elemnentId)
            requiredElement.elementData.isRequired = isChecked
            return [...prev.filter(({ id }) => id !== elemnentId), requiredElement]
        })
    }

    return (
        <div >
            {!!formElements.length && 'Twoje pytania:'}
            <Reorder.Group
                axis="y"
                values={formElements}
                onReorder={setFormElements}
            >
                {formElements.map((value, index) => (
                    <SingleFormElement
                        value={value}
                        index={index}
                        handleEditQuestion={handleEditQuestion}
                        handleRemoveElement={handleRemoveElement}
                        hadleIsRequiredField={hadleIsRequiredField}
                        key={value.id}
                    />
                ))}
            </Reorder.Group>

        </div>
    );
};

export default QuestionsList;