import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import css from './AddImages.css';

const RemoveImageRedButton = props => {
  const { className, rootClassName, onClick } = props;
  const classes = classNames(rootClassName || css.removeImage, className);
  return (
    <button className={classes} onClick={onClick}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill="#FF585F" d="M8 .667c4.05 0 7.333 3.283 7.333 7.333 0 4.05-3.283 7.333-7.333 7.333C3.95 15.333.667 12.05.667 8 .667 3.95 3.95.667 8 .667zM8 2C4.686 2 2 4.686 2 8s2.686 6 6 6 6-2.686 6-6-2.686-6-6-6zm2.471 3.529c.26.26.26.682 0 .942l-1.528 1.53 1.528 1.528c.26.26.26.682 0 .942-.26.26-.682.26-.942 0L8 8.943l-1.529 1.528c-.26.26-.682.26-.942 0-.26-.26-.26-.682 0-.942L7.057 8 5.53 6.471c-.26-.26-.26-.682 0-.942.26-.26.682-.26.942 0l1.53 1.528L9.528 5.53c.26-.26.682-.26.942 0z"/>
      </svg>
    </button>
  );
};

RemoveImageRedButton.defaultProps = { className: null, rootClassName: null };

const { func, string } = PropTypes;

RemoveImageRedButton.propTypes = {
  className: string,
  rootClassName: string,
  onClick: func.isRequired,
};

export default RemoveImageRedButton;