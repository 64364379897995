// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/PostPage/SET_INITIAL_STATE';

export const QUERY_POST_REQUEST = 'app/PostPage/QUERY_POST_REQUEST';
export const QUERY_POST_SUCCESS = 'app/PostPage/QUERY_POST_SUCCESS';
export const QUERY_POST_ERROR = 'app/PostPage/QUERY_POST_ERROR';

// ================ Reducer ================ //

const initialState = {
  post: {},
  queryPostError: null,
};

export default function postPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_POST_REQUEST:
      return { ...state, queryPostError: null };
    case QUERY_POST_SUCCESS:
      return { ...state, post: payload };
    case QUERY_POST_ERROR:
      return { ...state, post: {}, queryPostError: payload };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryPostRequest = () => ({
  type: QUERY_POST_REQUEST,
});

export const queryPostSuccess = post => ({
  type: QUERY_POST_SUCCESS,
  payload: post,
});

export const queryPostError = e => ({
  type: QUERY_POST_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryPost = postSlug => (dispatch, getState, sdk) => {
  const contentfulUrl = `https://cdn.contentful.com/spaces/jrml4xfrfnmx/environments/master/entries?access_token=Clc-Qo9iyiXSMpr5BEOtuFEL_lBw9jIKubvwz2EoxoA&content_type=blogPost&fields.slug=${postSlug}`;

  if(typeof window !== 'undefined' && window.fetch) {
    return fetch(contentfulUrl) 
      .then(response => {
        return response.json();
      })
      .then(response => {
        dispatch(queryPostSuccess(preparePost(response)));
      })
      .catch(e => dispatch(queryPostError(e)));
  } else {
    return sdk.injectedNodeAxios.get(contentfulUrl)
      .then(response => {
          return response.data;
      })
      .then(response => {
        dispatch(queryPostSuccess(preparePost(response)));
      })
      .catch(e => dispatch(queryPostError(e)));
  }
};

export const loadData = postSlug => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  // dispatch(setInitialState());

  return Promise.all([
    dispatch(queryPost(postSlug)),
  ]);
};

function preparePost(response) {
  const post = response.items[0];
  const authorId = post.fields.author.sys.id;
  const referencingPostIds = post.fields.referencingPosts ? post.fields.referencingPosts.map(post => post.sys.id) : [];

  return {
    ...post.fields,
    author: response.includes.Entry.filter(entry => entry.sys.id === authorId)[0],
    referencingPosts: response.includes.Entry.filter(entry => referencingPostIds.includes(entry.sys.id)),
    inlineReferences: response.includes.Entry,
    assets: response.includes.Asset
  }
}