import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import css from './IconChevronRight.css';

const IconChevronRight = props => {
  const { rootClassName, className, ...rest } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      className={classes}
      {...rest}
    >
      <path
        stroke="currentColor"
        strokeWidth="1.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.53022 18.5303L8.46956 17.4696L13.9392 11.9999L8.46956 6.53027L9.53022 5.46961L16.0605 11.9999L9.53022 18.5303Z"
      />
    </svg>
  );
};

IconChevronRight.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconChevronRight.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconChevronRight;
