import React from 'react';
import BookingWithoutPayment from './BookingWithoutPayment';


import css from './BookingPanel.css';



const MobileWithoutModal = (props) => {
    const {
        availabilityType,
        listing,
        timeSlots,
        fetchTimeSlotsError,
        intl,
        classes,
        onSubmitBookingRequest,
        price,
        isSingleBookingError,
        setIsSingleBookingError,
        hidePrice
    } = props;


    return (
        <div className={classes}>
            <div className={css.openBookingFormWithoutModal} >
                <BookingWithoutPayment
                    availabilityType={availabilityType}
                    bookingRequired={listing.attributes.publicData.bookingRequired}
                    discountParam={listing.attributes.publicData.discount}
                    onlinePaymentAvailable={
                        (listing.attributes.metadata &&
                            !!listing.attributes.metadata.online_payment) ||
                        listing.attributes.publicData.paymentEnabled
                    }
                    intl={intl}
                    infoClassName={css.bookingHelp}
                    onSubmit={onSubmitBookingRequest}
                    price={price}
                    priceWithDiscount={listing.attributes.publicData.priceWithDiscount}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    isSingleBookingError={isSingleBookingError}
                    setIsSingleBookingError={setIsSingleBookingError}
                    termins={listing.attributes.publicData.termins}
                    eventDate={listing.attributes.publicData.date}
                    isSTSEvent={listing.attributes.metadata.sts_run}
                    listingId={listing.id.uuid}
                    hidePrice={hidePrice}
                    flexDate={listing.attributes.publicData.flex_date}
                    onlySubmitButton={true}
                />
            </div>
        </div>
    )
};


export default (MobileWithoutModal);
