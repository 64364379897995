import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';
import classNames from 'classnames';
import { isScrollingDisabled } from '../../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { loadData, updateUser, downloadUsers } from './ConsoleUserPage.duck';
import { PaginationLinks, IconSpinner, Page, NamedLink } from '../../../components';
import { parse, stringify } from '../../../util/urlHelpers';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { TopbarContainer } from '../..';

import css from './ConsoleUserPage.css';
import SearchField from '../utils/SearchField/SearchField';
import ExtendedDataComponent from '../utils/ExtendedData/ExtendedData';
import TopNavigation from '../utils/TopNavigation/TopNavigation';

const ConsoleUserPageComponent = props => {
  const {
    users,
    fetchInProgress,
    scrollingDisabled,
    pagination,
    intl,
    location: { search, pathname },
    history,
    onUpdateUser,
    onDownloadUsers,
    isAdmin
  } = props;
  const { entityId } = parse(search);

  const [fieldOpened, setFieldOpened] = useState(null);
  const [entityOpened, setEntityOpened] = useState(entityId || null);

  useEffect(() => {
    if (!entityId) {
      setEntityOpened(null);
    }
  }, [entityId]);

  const handleDownload = () => {
    const entityIdMaybe = entityId ? { entityId } : {};

    onDownloadUsers({
      ...entityIdMaybe,
    })
  }

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ConsoleUserPage"
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  if (!isAdmin) {
    return (
      <Page title="Console" scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
      </Page>
    );
  }

  return (
    <Page title="Console" scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        className={css.topbar}
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        mobileClassName={css.mobileTopbar}
      />
      <div className={css.container}>
        <TopNavigation currentPage="ConsoleUserPage" />
        <div>
          <SearchField paramName="phrase" />
        </div>
        <div className={css.header}>
          {
            !entityId ?
              <h1 className={css.header1}>Uzytkownicy</h1>
              :
              <NamedLink name="ConsoleUserPage" className={css.header1}>Uzytkownicy  {`>`}</NamedLink>
          }
          <span>{pagination && pagination.totalItems}</span>
          {entityId && <h2>{entityId}</h2>}
          <button onClick={handleDownload} className={css.downloadBtn}>Pobierz</button>
        </div>
        {fetchInProgress ? (
          <div className={css.listItemsLoading}>
            <IconSpinner />
          </div>
        ) : (
          <ul>
            {users.map(user => (
              <div
                key={user.id.uuid}
                className={classNames(
                  css.listingWrapper,
                  {
                    [css.entityCollapsed]: entityOpened && entityOpened !== user.id.uuid,
                    [css.entityExpanded]: entityOpened === user.id.uuid,
                  }
                )}
              >
                <div
                  className={classNames(css.listingRow, { [css.deleted]: user.attributes.deleted })}
                  onClick={() => setEntityOpened(entityOpened === user.id.uuid ? null : user.id.uuid)}
                >
                  {(user.image && user.image.attributes && user.image.attributes.variants) ? (
                    <img src={user.image.attributes.variants['square-small'].url} />
                  ) : (
                    <div className={css.imagePlaceholder} />
                  )}
                  <div className={css.titleWrapper}>
                    <h2 className={css.title}>{user.attributes.profile.displayName}</h2>
                  </div>
                  <div className={css.createdAt}>
                    {intl.formatDate(user.attributes.createdAt, { day: 'numeric', month: 'numeric', year: 'numeric' })}
                  </div>
                </div>
                {entityOpened === user.id.uuid && (
                  <div>
                    <div className={css.additionalInfo}>
                      <div className={css.userEntities}>
                        <NamedLink name="ConsoleListingPage" className={css.entityLink} to={{ search: `authorId=${user.id.uuid}` }}>
                          Wydarzenia
                        </NamedLink>
                        <NamedLink name="ConsoleTransactionPage" className={css.entityLink} to={{ search: `userId=${user.id.uuid}` }}>
                          Transakcje
                        </NamedLink>
                      </div>
                      <div className={css.additionalInfo}>
                        <h3>Email</h3>
                        <div className={css.description}>{user.attributes.email}</div>
                        <h3>Id</h3>
                        <NamedLink name="ProfilePage" params={{ id: user.id.uuid }} className={css.entityLink}>
                          {user.id.uuid}
                        </NamedLink>
                      </div>
                    </div>
                    <ExtendedDataComponent
                      data={user.attributes.profile.publicData}
                      dataType="publicData"
                      onUpdate={onUpdateUser}
                      fieldOpened={fieldOpened}
                      setFieldOpened={setFieldOpened}
                      entityId={user.id.uuid}
                    />
                    <ExtendedDataComponent
                      data={user.attributes.profile.privateData}
                      dataType="privateData"
                      onUpdate={onUpdateUser}
                      fieldOpened={fieldOpened}
                      setFieldOpened={setFieldOpened}
                      entityId={user.id.uuid}
                    />
                    <ExtendedDataComponent
                      data={user.attributes.profile.metadata}
                      dataType="metadata"
                      onUpdate={onUpdateUser}
                      fieldOpened={fieldOpened}
                      setFieldOpened={setFieldOpened}
                      entityId={user.id.uuid}
                    />
                  </div>
                )}
              </div>
            ))}
          </ul>
        )}
        {paginationLinks}
      </div>
    </Page>
  );
};

ConsoleUserPageComponent.defaultProps = {
  fetchInProgress: false,
  fetchUserListError: null,
  pagination: null,
  scrollingDisabled: false,
};

ConsoleUserPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchUserListError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  users: arrayOf(propTypes.user).isRequired,
  intl: intlShape.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
  onUpdateUser: func.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchUserListError, pagination, userList, updateUserInProgress } =
    state.ConsoleUserPage;
  const isAdmin = state.user.currentUser && state.user.currentUser.attributes.isAdmin;

  return {
    fetchInProgress,
    fetchUserListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    users: getMarketplaceEntities(state, userList),
    updateUserInProgress,
    isAdmin
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateUser: params => dispatch(updateUser(params)),
  onDownloadUsers: params => dispatch(downloadUsers(params)),
});

const ConsoleUserPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleUserPageComponent);

ConsoleUserPage.loadData = loadData;

export default ConsoleUserPage;
