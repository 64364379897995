import React from 'react';

Next.defaultProps = {
  color: '#3643BA',
}

function Next({ color }) {
  return (
    <svg width="13" height="18" viewBox="0 0 13 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="-1" x2="14.2127" y2="-1" transform="matrix(0.773957 -0.633238 0.773957 0.633238 2 18)" stroke={color} strokeWidth="2" />
      <line y1="-1" x2="14.2127" y2="-1" transform="matrix(0.773957 0.633238 0.773957 -0.633238 2 0)" stroke={color} strokeWidth="2" />
    </svg>
  )
}

export default Next
