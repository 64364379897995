import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import css from './styles.css';
function FileUploadComponent({ control, setValue, watch, uploadfile }) {

    const file = watch("pdfFile");
    const [previewUrl, setPreviewUrl] = useState('');

    const [initialFileInfo, setInitialFileInfo] = useState(null);

    useEffect(() => {
        // if (uploadfile && uploadfile[0]) {
        //     setValue("pdfFile", [uploadfile[0]]);
        //     setPreviewUrl(URL.createObjectURL(uploadfile[0]));
        //     setInitialFileInfo({ name: uploadfile[0].name, size: uploadfile[0].size });
        // }
        setPreviewUrl(uploadfile);
    }, [uploadfile]);

    useEffect(() => {
        if (file && file[0]) {
            const newPreviewUrl = URL.createObjectURL(file[0]);
            setPreviewUrl(newPreviewUrl);

            return () => URL.revokeObjectURL(newPreviewUrl);
        }
    }, [file]);


    const handleRemoveFile = (e) => {
        e.preventDefault();
        setValue("pdfFile", null);
        setInitialFileInfo(null);
        setPreviewUrl('');
    };
    return (
        <div>
            {(file || previewUrl) && (
                <div>
                    {/* {initialFileInfo && initialFileInfo.name === file[0] && file[0].name && (
                        <div style={{ margin: '8px 0' }}>
                            <p><strong>Wgrany regulamin:</strong> {initialFileInfo.name} ({Math.round(initialFileInfo.size / 1024)} KB)</p>
                            <p style={{ fontSize: '12px' }} >Jeśli chesz zmienić ten plik wybierz nowy plik lub najpierw usuń obecny</p>
                        </div>
                    )} */}
                    {previewUrl ? (
                        <>
                            <p style={{ fontSize: '14px' }}><strong>Wgrany regulamin: </strong></p>

                            <div className={css.previewMobile}>
                                <iframe src={previewUrl} title="Podgląd PDF" style={{ width: '100%', height: '500px', border: 'none' }}></iframe>
                            </div>
                            <p className={css.previewDesktop}>Nie można wyświetlić podglądu. <a href={previewUrl} download="Podglad.pdf">Kliknij
                                <span className='underline'>tutaj</span>, aby pobrać plik PDF.</a></p>
                            <p style={{ fontSize: '12px' }} >Jeśli chesz zmienić ten plik wybierz nowy plik lub najpierw usuń obecny</p>

                        </>
                    ) : (
                        <p>Nie można wyświetlić podglądu. <a href={previewUrl} download="Podglad.pdf">Kliknij tutaj, aby pobrać plik PDF.</a></p>
                    )}
                    <button className={css.removeButton} onClick={handleRemoveFile}>Usuń plik</button>
                </div>
            )}
            <Controller
                name="pdfFile"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, onBlur, name, ref } }) => (
                    <input
                        type="file"
                        style={{ border: 'none' }}
                        onChange={e => {
                            const file = e.target.files[0];
                            if (file && file.type === "application/pdf") {
                                if (file.size <= 10485760) {
                                    onChange(e.target.files);
                                } else {
                                    alert('Plik musi być mniejszy niż 10MB.');
                                }
                            } else {
                                alert('Proszę wybrać plik PDF.');
                            }
                        }}
                        onBlur={onBlur}
                        name={name}
                        ref={ref}
                        accept="application/pdf"
                    />
                )}
            />


        </div>
    );
}

export default FileUploadComponent;
