import React, { useMemo, useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { useFormContext } from '../context';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';

import css from './styles.css';
import SelectSeatsModal from './SelectSeatsModal';
import ReservedSeatsList from './ReservedSeatsList';
import { updateEvent } from '../api';
import RequestStatusModal from '../RequestStatusModal';

const VIPBooking = () => {
  const { updateFormData, isLastStep, formData, listingId, type } = useFormContext();
  const [updateSucces, setUpdateSucces] = React.useState(null);

  const [isMapOpen, setIsMapOpen] = React.useState(false);

  const listingTitle = formData.generalInfo.eventTitle;
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    watch,
  } = useForm({
    values: formData.VIPBooking,
  });

  const [bookedSeats, setBookedSeats] = React.useState(formData.VIPSeats.data || []);
  const onSubmit = async data => {
    updateFormData({ data: bookedSeats }, 'VIPSeats');
    const updateStatus = await updateEvent({
      id: listingId,
      publicData: {
        VIPSeats: bookedSeats,
      },
    });
    updateStatus === 'success' ? setUpdateSucces('success') : setUpdateSucces('error');
  };

  const submitButtonLabel = useMemo(
    () => (isLastStep ? 'Opublikuj' : type === 'edit' ? 'Zapisz' : 'Dalej'),
    [isLastStep, type]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {type === 'edit' && updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => setUpdateSucces(null)}
          listingId={listingId}
          slug={listingTitle}
        />
      )}

      <ReservedSeatsList reservedSeats={bookedSeats} />

      <h1 className={css.header}>
        <LeftArrowIcon />
        Dodaj miejsca VIP (opcjonalnie)
        <button
          type="button"
          className={css.header_button}
          onClick={() => setIsMapOpen(prev => !prev)}
        >
          {' '}
          +
        </button>
      </h1>

      <p className={css.paragraph}>
        Zarezerwowane miejsca VIP nie będą dostępne do zakupu po publikowaniu oferty. Zarezerwowane
        bilety wyślemy na maila
      </p>

      {isMapOpen && (
        <SelectSeatsModal
          pricing={formData.sectorsPrice.data}
          setBookedSeats={setBookedSeats}
          setIsMapOpen={setIsMapOpen}
        />
      )}

      <div>
        <button className={css.confirmButton} style={{ maxWidth: '422px' }} type="submit">
          {submitButtonLabel}
        </button>
      </div>
    </form>
  );
};

export default VIPBooking;
