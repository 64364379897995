import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl } from 'react-intl';
import {
  arrayOf,
  array,
  bool,
  func,
  node,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '../../components';
import { openMissingInfoModal } from '../../components/ModalMissingInformation/MissingModalInformation.duck';
import BookingWithoutPayment from './BookingWithoutPayment';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';

import css from './BookingPanel.css';
import MobileWithoutModal from './MobileWithoutModal';
import MobileWithModal from './MobileWithModal';

export const useIsMobile = (threshold = 1024) => {
  const isSSR = typeof window === 'undefined';

  const [isMobile, setIsMobile] = useState(!isSSR && window.innerWidth <= threshold);

  useEffect(() => {
    if (isSSR) return;

    const checkIfMobile = () => setIsMobile(window.innerWidth <= threshold);

    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, [threshold]);

  return isMobile;
};


export function adjustTimeForPoland(endDateHour) {
  // Determine if the current date is in daylight saving time in Poland
  var startDST = new Date(endDateHour.getFullYear(), 2, 31); // Last day of March
  var endDST = new Date(endDateHour.getFullYear(), 9, 31); // Last day of October
  startDST.setDate(31 - (startDST.getDay()) % 7); // Last Sunday of March
  endDST.setDate(31 - (endDST.getDay() + 1) % 7); // Last Sunday of October
  if (endDateHour >= startDST && endDateHour < endDST) {
    // Daylight Saving Time (Summer)
    endDateHour.setHours(endDateHour.getHours() - 2);

  } else {
    // Standard Time (Winter)
    endDateHour.setHours(endDateHour.getHours() - 1);
  }

  return endDateHour;
}


// This defines when ModalInMobile shows content as Modal
// const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl, isPriceEnabled) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, isPriceEnabled ? { ...price, amount: price.amount * 1.06 + 200 } : price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};



const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const BookingPanel = (props) => {
  const {
    availabilityType,
    currentUser,
    rootClassName,
    classNameBP,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    subTitle,
    authorDisplayName,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    bookedDates,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
  } = props;

  const isMobile = useIsMobile();

  const [showBookingModal, setShowBookingModal] = useState(false);
  const [isSingleBookingError, setIsSingleBookingError] = useState(false)

  useEffect(() => {
    if (
      ((isMobile && timeSlots && timeSlots.length === 1) ||
        (isMobile && !(listing.attributes.publicData.termins &&
          listing.attributes.publicData.termins.length > 1))) &&
      !(listing.attributes.publicData.paymentEnabled)
    ) {
      setShowBookingModal(false)
    } else {
      setShowBookingModal(true)
    }
  }, [isMobile, timeSlots, listing.attributes.publicData.termins, listing.attributes.publicData.paymentEnabled])

  const price = listing.attributes.price;
  const hidePrice = listing.attributes.publicData.price_hide
  // const hasListingState = !!listing.attributes.state;
  // const isClosed =
  //   hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  // const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl, listing.attributes.publicData.paymentEnabled);
  const isBook = !!parse(location.search).book;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const classes = classNames(rootClassName || css.root, classNameBP);
  const titleClasses = classNames(titleClassName || css.bookingTitle);

  const paymentType =
    (listing.attributes.metadata &&
      listing.attributes.metadata.online_payment) ||
      listing.attributes.publicData.paymentEnabled
      ? 'online_payment'
      : 'stationary_payment';



  const onSubmitBookingRequest = (bookingData) => {
    const registrationsAmountOfDate = bookedDates.map((date) => (new Date(date)).getTime()).filter((date) => {
      return date === (new Date(bookingData.date)).getTime()
    }).length

    const registrationLimitPaymentEvent = listing.attributes.publicData.paymentEnabled ? 200 : null;

    const registrationLimitChildren = listing.attributes.publicData.gender.includes('children') ? 5 : 1;

    const registrationLimit = registrationLimitPaymentEvent || registrationLimitChildren;

    const { seats, seatsWithDiscount, priceWithDiscount, discount, date, end } = bookingData;

    // we have date only for onetime events
    const dateToSubmit = date ? date : listing.attributes.publicData.date;

    const isOneTimeAvailibilityEvent = listing.attributes.publicData.availabilityType && listing.attributes.publicData.availabilityType.trim() == 'onetime' && !!listing.attributes.publicData.date && !listing.attributes.publicData.termins
    const isRegularAvailibilityEvent = listing.attributes.publicData.availabilityType && listing.attributes.publicData.availabilityType.trim() == 'regular' && !!listing.attributes.publicData.date && !listing.attributes.publicData.termins

    let startDate = new Date(dateToSubmit);
    // // Add one hour
    startDate = adjustTimeForPoland(startDate)

    let bookingDates = {}
    if (isOneTimeAvailibilityEvent || isRegularAvailibilityEvent) {
      bookingDates = {
        startDate: new Date(dateToSubmit).setHours(0, 0, 0, 0),
        endDate: new Date(dateToSubmit).setHours(24, 0, 0, 0),
      }
    } else {
      bookingDates = {
        startDate,
        endDate: adjustTimeForPoland(end)
      }
    }

    if (!currentUser) {
      localStorage.setItem('bookingData', JSON.stringify({
        values: {
          bookingDates: bookingDates,
          seatsWithDiscount,
          priceWithDiscount,
          quantity: seats,
          discount
        },
        paymentType
      }
      ));

      const state = { from: `${location.pathname}${location.search}${location.hash}` };
      // signup and return back to listingPage.
      history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
    } else if (
      currentUser.attributes.profile.firstName === 'Nowy' ||
      currentUser.attributes.profile.lastName === 'Użytkownik'
    ) {
      closeBookModal(history, location);
      props.onMissingNameModalOpen();
    } else if (registrationsAmountOfDate >= registrationLimit) {
      setIsSingleBookingError(true)
    } else {


      onSubmit(
        {
          bookingDates: bookingDates,
          seatsWithDiscount,
          priceWithDiscount,
          quantity: seats,
          discount,
        },
        paymentType
      );
    }
  };


  return !showBookingModal ? (

    <MobileWithoutModal {...props} onSubmitBookingRequest={onSubmitBookingRequest} isSingleBookingError={isSingleBookingError} price={price} hidePrice={hidePrice} classes={classes} setIsSingleBookingError={setIsSingleBookingError} />

  ) : (
    <MobileWithModal {...props}
      isOwnListing={isOwnListing} formattedPrice={formattedPrice}
      authorDisplayName={authorDisplayName} titleClasses={titleClasses}
      isBook={isBook} onSubmitBookingRequest={onSubmitBookingRequest} isSingleBookingError={isSingleBookingError} price={price} hidePrice={hidePrice} classes={classes} setIsSingleBookingError={setIsSingleBookingError} />
    // null
  )

};

BookingPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.bookingUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  bookedDates: []
};

BookingPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.bookingUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  bookedDates: propTypes.array,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl,
  connect(null, {
    onMissingNameModalOpen: () => openMissingInfoModal('NAME_MISSING'),
  })
)(BookingPanel);
