import React from "react";
import FormItem from "../../../components/FormItem";
import css from './styles.css'

const EventTitle = ({ register, errors }) =>
    <FormItem label={'Tytuł wydarzenia'}>
        <input className={css.eventTypeContainer} {...register("eventTitle", { required: true })} />
        {errors.eventTitle && errors.eventTitle.type === "required" && (
            <p style={{ color: '#f00' }} role="alert">Tytuł wydarzenia jest wymagany</p>
        )}
    </FormItem>

export default EventTitle;