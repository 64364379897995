import { useHistory, useLocation } from 'react-router-dom';

export const useUpdateQueryParams = () => {
  const history = useHistory();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const updateQueryParams = (searchParamName, selectedOptions) => {
    console.log('useUpdateQueryParams', searchParamName);

    params.set(
      searchParamName,
      typeof selectedOptions === 'object' ? selectedOptions.join(',') : selectedOptions
    );
    history.replace({ search: params.toString() });
  };

  return updateQueryParams;
};
