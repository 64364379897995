import React, { useEffect, useRef, useState } from 'react';
import css from './styles.css';
import SidePanelContainer from '../../../containers/ListingPage/components/bookingPanel/SidePanelContainer';
import ReservedSeatsList from './ReservedSeatsList';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import { bookSeats } from '../../../util/api';

const SelectSeatsModal = ({ pricing, setBookedSeats, setIsMapOpen }) => {
  const [selectedVIPSeats, setSelectedVIPSeats] = useState([]);

  const workspaceKey = process.env.REACT_APP_WORKSPACE_KEY;

  const handleOnObjectSelected = (object, selectedTicketType) => {
    setSelectedVIPSeats(prev =>
      !!prev.filter(({ id }) => id === object.id).length
        ? prev.filter(({ id }) => id === object.id)
        : [...prev, object]
    );
  };

  const dropdownRef = useRef(null);
  const [, setIsClickedOutsideSIdePanel] = useState(false);

  const handleCloseSidePanel = event => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      setIsClickedOutsideSIdePanel(false);
    } else {
      setIsMapOpen(false);
    }
  };

  const bookTickets = async () => {
    const objects = selectedVIPSeats.map(({ id }) => id);
    const res = await bookSeats({ objects });
    setBookedSeats(prev => [
      ...prev,
      ...selectedVIPSeats.map(({ label }) => {
        const [sectorName, row, seat] = label.split('-');
        return { sectorName, row, seat };
      }),
    ]);
    setIsMapOpen(false);
  };

  useEffect(() => {
    async function loadScript() {
      try {
        const response = await fetch('/stadium/map/asset-manifest.json');
        const manifest = await response.json();
        const mainScript = manifest['files']['main.js'];
        const mainCss = manifest.files['main.css'];

        if (mainCss) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = `/stadium/map${mainCss}`;
          document.head.appendChild(link);
        }

        if (mainScript) {
          const script = document.createElement('script');
          script.src = `/stadium/map${mainScript}`;
          script.onload = () => {
            window.renderMap('mapContainerVipId', {
              pricing: pricing.map(item => {
                return {
                  category: item.category,
                  ticketTypes: [
                    {
                      ticketType: 'vip',
                      price: 0,
                      label: 'Bilet VIP',
                    },
                  ],
                };
              }),
              handleOnObjectSelected: handleOnObjectSelected,
              session: 'none',
              mode: null,
              workspaceKey,
              event: '274b8ab1-6e10-431a-a4a2-57b13b8de921',
            });
          };
          document.body.appendChild(script);
        }
      } catch (error) {
        console.error('Błąd podczas ładowania skryptu:', error);
      }
    }

    loadScript();
  }, []);

  return (
    <div onClick={handleCloseSidePanel}>
      <SidePanelContainer ref={dropdownRef}>
        <div className={css.map_header}>
          <h1 className={css.header}>
            <LeftArrowIcon onClick={() => handleCloseSidePanel(false)} />
            Dodaj miejsca VIP
          </h1>
        </div>

        <div id="mapContainerVipId"></div>

        <ReservedSeatsList
          reservedSeats={selectedVIPSeats.map(({ label }) => {
            const [sectorName, row, seat] = label.split('-');
            return { sectorName, row, seat };
          })}
        />
        <button
          disabled={!selectedVIPSeats.length}
          onClick={bookTickets}
          className={css.confirmButton}
          type="button"
        >
          Zatwierdź
        </button>
      </SidePanelContainer>
    </div>
  );
};

export default SelectSeatsModal;
