import React from 'react';
import css from './InboxPage.css';
import classNames from 'classnames';
import { Avatar, NamedLink } from '../../components';
import { sdkGo } from '../../util/api';

const MessageItem = ({ message, currentUser }) => {
  const senderData = message.senderData;
  return (
    <li key={message.listingId} className={css.listItem}>
      <div
        className={css.item}
        onClick={() => {
          sdkGo.chats.update({
            id: message.chatId,
            unreadSince: new Date(),
          });
        }}
      >
        <div className={css.itemAvatar}>
          {/* <Avatar user={message.senderId === currentUser.id.uuid ? currentUser : message.sender} /> */}
          <Avatar user={senderData.id.uuid === currentUser.id.uuid ? currentUser : senderData} />
        </div>
        {message.transactionData && message.transactionData.id.uuid ? (
          <NamedLink
            className={css.itemLink}
            name={'MessageDetailsPage'}
            params={{ id: message.transactionData.id.uuid }}
            // params={{ id: (message.transactionData && message.transactionData.id.uuid) || message.listingData.id.uuid }}
          >
            <div className={css.itemInfo}>
              <div className={classNames(css.itemUsernameMessage)}>
                {`Wiadomość ${
                  senderData.id.uuid === currentUser.id.uuid
                    ? `wysłana do ${senderData.attributes.profile.displayName}`
                    : `od ${senderData.attributes.profile.displayName}`
                }`}
                {senderData.id.uuid !== currentUser.id.uuid && (
                  <span style={{ margin: 0 }}>
                    {new Date(message.attributes.createdAt) <=
                    new Date(message.chatData.attributes.unreadSince) ? (
                      <span style={{ color: '#ABABAB' }}>Odczytane</span>
                    ) : (
                      <span style={{ color: '#C30000' }}>Nieodczytane</span>
                    )}{' '}
                  </span>
                )}
              </div>
              <div className={css.messagedescription}>
                {`dotycząca wydarzenia ${message.listingData.attributes.title} ${new Date(
                  message.attributes.createdAt
                ).toLocaleDateString()}`}
                {/* {`${new Date(message.attributes.createdAt).toLocaleDateString()}`} */}
              </div>
            </div>
            <div className={css.itemState}>
              {/* <div
                        className={classNames(css.lastTransitionedAt)}
                        title={message.listingData.attributes.title}
                    >
                        {message.listingData.attributes.title}
                    </div> */}
            </div>
          </NamedLink>
        ) : (
          <NamedLink
            className={css.itemLink}
            name={'ListingMessageDetailsPage'}
            params={{ listingId: message.listingData.id.uuid, chatId: message.chatId }}
          >
            <div className={css.itemInfo}>
              <div className={classNames(css.itemUsernameMessage)}>
                {`Wiadomość ${
                  senderData.id.uuid === currentUser.id.uuid
                    ? `wysłana do ${senderData.attributes.profile.displayName}`
                    : `od ${senderData.attributes.profile.displayName}`
                }`}
                {senderData.id.uuid !== currentUser.id.uuid && (
                  <span style={{ margin: 0 }}>
                    {new Date(message.attributes.createdAt) <=
                    new Date(message.chatData.attributes.unreadSince) ? (
                      <span style={{ color: '#ABABAB' }}>Odczytane</span>
                    ) : (
                      <span style={{ color: '#C30000' }}>Nieodczytane</span>
                    )}{' '}
                  </span>
                )}
              </div>
              <div className={css.messagedescription}>
                {`dotycząca wydarzenia ${message.listingData.attributes.title} ${new Date(
                  message.attributes.createdAt
                ).toLocaleDateString()}`}
                {/* {`${new Date(message.attributes.createdAt).toLocaleDateString()}`} */}
              </div>
            </div>
            <div className={css.itemState}>
              {/* <div
                        className={classNames(css.lastTransitionedAt)}
                        title={message.listingData.attributes.title}
                    >
                        {message.listingData.attributes.title}
                    </div> */}
            </div>
          </NamedLink>
        )}
      </div>
    </li>
  );
};

export default MessageItem;
