import React, { useState } from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import css from '../../ConsoleListingPage/ConsoleListingPage.css';
import style from './ExtendedData.css';
import CodeEditor from '../CodeEditor/CodeEditor';

const Badge = ({ type }) => (
  <div className={css.badge}>
    {type === 'object' ? 'JSON' : type}
  </div>
);

const ExtendedDataComponent = ({ data, dataType, entityId, onUpdate, fieldOpened, setFieldOpened }) => {
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');

  const handleRemoveKeyValue = (key) => {
    onUpdate({ id: entityId, [dataType]: { [key]: null } });
  };

  const handleAddKeyValue = () => {
    try {
      const parsedValue = JSON.parse(newValue);
      onUpdate({ id: entityId, [dataType]: { [newKey]: parsedValue } });
      setNewKey('');
      setNewValue('');
      setIsFormVisible(false);
    } catch (error) {
      console.error('Invalid JSON format', error);
    }
  };

  const dataArray = Object.keys(data || {});

  return (
    <div>
      <h3 className={style.title}>{dataType.charAt(0).toUpperCase() + dataType.slice(1)}</h3>
      {dataArray.map((key, index) => (
        <div key={index} className={css.keyWrapper}>
          <div>
            <div className={css.keyTitle}>
              {key}
              <Badge type={typeof data[key]} />
              <button onClick={() => handleRemoveKeyValue(key)} className={style.deleteButton}>X</button>
            </div>
            <div>
              {fieldOpened !== `${entityId}-${dataType}-${key}` ? (
                <div className={css.valueWrapper}>
                  <pre className={css.json}>{JSON.stringify(data[key], null, 2)}</pre>
                </div>
              ) : (
                <CodeEditor
                  code={data[key]}
                  id={entityId}
                  keyName={key}
                  onUpdateEntity={onUpdate}
                  fieldType={typeof data[key]}
                  dataType={dataType}
                  setFieldOpened={setFieldOpened}
                />
              )}
            </div>
          </div>
          <div className={css.actionButtons}>
            <a onClick={() => setFieldOpened(`${entityId}-${dataType}-${key}`)} className={style.editButton}>Edit</a>
          </div>
        </div>
      ))}
      <div>
        <button onClick={() => setIsFormVisible(!isFormVisible)} className={style.addButton}>
          {isFormVisible ? 'Hide Form' : `+ Dodaj nowe pole`}
        </button>
        {isFormVisible && (
          <div className={css.form}>
            <div className={css.formField}>
              <label htmlFor={`newKey-${dataType}`}>Key:</label>
              <input
                type="text"
                id={`newKey-${dataType}`}
                value={newKey}
                onChange={e => setNewKey(e.target.value)}
              />
            </div>
            <div className={css.formField}>
              <label htmlFor={`newValue-${dataType}`}>Value:</label>
              <AceEditor
                mode="json"
                theme="github"
                name={`newValue-${dataType}`}
                value={newValue}
                onChange={val => setNewValue(val)}
                editorProps={{ $blockScrolling: true }}
                setOptions={{ useWorker: false }}
              />
            </div>
            <button onClick={handleAddKeyValue} className={style.confirmButton}>Confirm</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ExtendedDataComponent;
