import React from 'react';
import CustomCheckboxFormElement, { CustomCheckboxGroup } from '../../../components/CustomCheckbox';
import { CustomCheckbox } from '../../../components/CustomCheckbox';
import { Controller, useFieldArray } from 'react-hook-form';

export const createFormElement = (elementData) => {

    const {
        title,
        description,
        options,
        selectedTypeOfQuestion
    } = elementData

    switch (selectedTypeOfQuestion) {
        case 'textareaField':
            return <label style={{ fontWeight: '600' }} >
                {title}
                <textarea placeholder={description} disabled style={{ width: '100%', padding: '12px 8px', border: 'solid 1px #949494' }} />
            </label>

        case 'checkboxField':
            return <label style={{ display: 'flex', flexDirection: 'column', width: '100%', fontWeight: '600' }} >
                {title}
                {options.map((option) => {
                    return (
                        <CustomCheckbox key={option} label={option} onChange={() => null} />
                    );
                })}
            </label>


        case 'selectField':
            return <label style={{ display: 'flex', flexDirection: 'column', width: '100%', fontWeight: '600' }} >
                {title}
                <select disabled style={{ width: '100%', padding: '12px', border: 'solid 1px #949494' }}>
                    <option disabled value="">
                        {description}
                    </option>
                    {options.map((c) => (
                        <option key={c} value={c}>
                            {c}
                        </option>
                    ))}
                </select>
            </label>

        default:
            return null
    }
}



export const createShowedFormElement = (elementData, register, control, errors, setError, clearErrors) => {

    const {
        title,
        description,
        options,
        selectedTypeOfQuestion
    } = elementData


    switch (selectedTypeOfQuestion) {
        case 'textareaField':
            return <label key={title} style={{ margin: '10px 0', display: 'flex', flexDirection: 'column', width: '100%', fontWeight: '600' }} >
                <p>{title}
                    {!!elementData.isRequired && <sup style={{ color: 'red' }}>*</sup>}
                </p>
                <textarea placeholder={description} {...register(title.split('.').join('_'), { required: !!elementData.isRequired })} rows={2} maxLength={100} style={{ width: '100%', padding: '12px 8px', boxSizing: 'border-box', border: 'solid 1px #949494' }} />
                {!!errors[title] && <p style={{ color: 'red' }}>Pole jest wymagane</p>}
            </label>



        case 'checkboxField':
            return <CustomCheckboxGroup key={title} control={control} name={title} options={options} errors={errors} setError={setError} clearErrors={clearErrors} />


        case 'selectField':
            return <label key={title} style={{ margin: '10px 0', display: 'flex', flexDirection: 'column', width: '100%', fontWeight: '600' }} >
                <p>{title}
                    {!!elementData.isRequired && <sup style={{ color: 'red' }}>*</sup>}
                </p>
                <select defaultValue={""} {...register(title.split('.').join('_'), { required: !!elementData.isRequired })} style={{ width: '100%', padding: '12px', border: 'solid 1px #949494' }}>
                    <option disabled value="">
                        {description}
                    </option>
                    {options.map((c) => (
                        <option key={c} value={c}>
                            {c}
                        </option>
                    ))}
                </select>
                {!!errors[title] && <p style={{ color: 'red' }}>Pole jest wymagane</p>}
            </label>


        default:
            return null
    }
}