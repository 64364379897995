import React from 'react';
import PropTypes from 'prop-types';

const IconLocationArrow = props => {
  const { className } = props;
  return (   
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#9DAFC2" d="M22.904 2.428l-9 19c-.402.849-1.646.725-1.874-.185l-1.855-7.418-7.418-1.855c-.91-.228-1.034-1.472-.185-1.874l19-9c.85-.402 1.734.483 1.332 1.332zm-3.006 1.674L5.953 10.708l5.29 1.322c.358.09.638.37.727.727l1.322 5.29 6.606-13.945z"/>
    </svg>
  );
};

IconLocationArrow.defaultProps = { className: null };

const { string } = PropTypes;

IconLocationArrow.propTypes = {
  className: string,
};

export default IconLocationArrow;
