import React from 'react';
import PropTypes from 'prop-types';

const IconSuccessSend = (props) => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
            <path d="M30 60C34.1077 60 37.9661 59.2124 41.5753 57.6371C45.2044 56.0818 48.3948 53.9282 51.1466 51.1765C53.8983 48.4048 56.0618 45.2144 57.6371 41.6052C59.2124 37.9761 60 34.1077 60 30C60 25.8923 59.2124 22.0339 57.6371 18.4247C56.0618 14.7956 53.8983 11.6052 51.1466 8.85344C48.3948 6.08175 45.2044 3.91825 41.5753 2.36291C37.9462 0.787637 34.0778 0 29.9701 0C25.8624 0 21.994 0.787637 18.3649 2.36291C14.7557 3.91825 11.5753 6.08175 8.82353 8.85344C6.07178 11.6052 3.90828 14.7956 2.333 18.4247C0.777667 22.0339 0 25.8923 0 30C0 34.1077 0.777667 37.9761 2.333 41.6052C3.90828 45.2144 6.07178 48.4048 8.82353 51.1765C11.5952 53.9282 14.7856 56.0818 18.3948 57.6371C22.0239 59.2124 25.8923 60 30 60ZM26.7099 44.4467C26.3111 44.4467 25.9521 44.3669 25.6331 44.2074C25.3141 44.0279 25.005 43.7488 24.7059 43.3699L16.5703 33.6191C16.3908 33.3799 16.2512 33.1406 16.1515 32.9013C16.0518 32.6421 16.002 32.3829 16.002 32.1236C16.002 31.6052 16.1815 31.1565 16.5404 30.7777C16.8993 30.3988 17.328 30.2094 17.8265 30.2094C18.1655 30.2094 18.4646 30.2792 18.7238 30.4187C19.003 30.5583 19.2822 30.8076 19.5613 31.1665L26.6201 39.8405L40.3789 18.0359C40.8175 17.338 41.3659 16.989 42.0239 16.989C42.5025 16.989 42.9412 17.1486 43.34 17.4676C43.7388 17.7866 43.9382 18.2054 43.9382 18.7238C43.9382 18.9831 43.8784 19.2423 43.7587 19.5015C43.659 19.7607 43.5394 20 43.3998 20.2193L28.5942 43.3699C28.3549 43.7288 28.0758 43.998 27.7567 44.1775C27.4377 44.3569 27.0887 44.4467 26.7099 44.4467Z" fill="#3FCA83" />
        </svg>
    );
};

IconSuccessSend.defaultProps = { className: null };

const { string } = PropTypes;

IconSuccessSend.propTypes = {
    className: string,
};

export default IconSuccessSend;
