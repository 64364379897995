import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { ReactComponent as LeftArrowIcon } from '../../assets/addEventForm/leftArrowIcon.svg';
import { createShowedFormElement } from '../AddEventForm/AdditionalForm/components/utils';
import css from './style.css';

const AdditionalForm = ({
  data,
  additionalFormData,
  setHasAdditionalFormOpen,
  participantsData,
  setAdditionalFormData,
  handleBack,
  handleClose,
  hasAdditionalFormOpen,
}) => {
  const [currentParticipantIndex, setCurrentParticipantIndex] = useState(0);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isEditMode = searchParams.get('mode');

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = participantData => {
    setAdditionalFormData(prev => {
      const updatedData = [...prev];
      const participantKey = `${participantsData[currentParticipantIndex].firstName}-${participantsData[currentParticipantIndex].lastName}`;
      const participantIndex = updatedData.findIndex(
        data => Object.keys(data)[0] === participantKey
      );

      if (participantIndex !== -1) {
        updatedData[participantIndex] = { [participantKey]: participantData };
      } else {
        updatedData.push({ [participantKey]: participantData });
        reset();
      }

      return updatedData;
    });

    if (currentParticipantIndex < participantsData.length - 1) {
      setCurrentParticipantIndex(currentParticipantIndex + 1);
    } else {
      setHasAdditionalFormOpen(false);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      const savedValues =
        additionalFormData &&
        !!additionalFormData.length &&
        additionalFormData.length > currentParticipantIndex
          ? Object.values(additionalFormData[currentParticipantIndex])[0]
          : {};
      Object.keys(savedValues).forEach(key => {
        setValue(key, savedValues[key]);
      });
    }
  }, [currentParticipantIndex, additionalFormData, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.formContainer}>
      <h2 style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            onClick={handleBack}
            style={{
              display: 'flex',
              alignItems: 'center',
              border: 'none',
              background: 'none',
              fontSize: '20px',
              color: '#000',
              margin: '5px 0 auto',
              padding: '0 5px 0 0',
            }}
          >
            <LeftArrowIcon />
          </button>

          {`Wypełnij Formularz  ${
            participantsData.length > 1
              ? '-' + participantsData[currentParticipantIndex].firstName
              : ''
          }`}
        </div>

        <span onClick={handleClose}>x</span>
      </h2>
      {data.map(item =>
        createShowedFormElement(item, register, control, errors, setError, clearErrors)
      )}
      {hasAdditionalFormOpen && (
        <button style={{ width: '100%', marginTop: '20px' }} type="submit">
          {`${
            currentParticipantIndex < participantsData.length - 1
              ? 'Zapisz i przejdź dalej'
              : 'Zapisz się'
          }`}
        </button>
      )}
    </form>
  );
};

export default AdditionalForm;
