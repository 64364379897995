import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCurrencyInput,
  FieldCheckbox,
} from '../../components';
import CustomCategorySelectFieldMaybe from './CustomCategorySelectFieldMaybe';
import config from '../../config';

import css from './EditListingDescriptionForm.css';
import SportSelectField from './EditListingSportField';
import EventTypeSelectField from './EditListingEventTypeField';
import DateTimeField from './EditDateTimeField';

const TITLE_MAX_LENGTH = 60;

const EditListingDescriptionFormComponent = (props) => {

  const [isTicketWithDiscount, setIsTicketWithDiscount] = useState(props.initialValues.priceWithDiscount ? true : false);

  return (


    <FinalForm
      {...props}
      render={(fieldRenderProps) => {
        const {
          className,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          paymentsEnabledField,
        } = fieldRenderProps;

        const priceRequired = required(
          intl.formatMessage({
            id: 'EditListingPricingForm.priceRequired',
          })
        );

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;


        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            {/* <FieldTextInput
            id="title"
            name="title"
            className={css.title}
            type="text"
            label={titleMessage}
            placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.description}
            type="textarea"
            label={descriptionMessage}
            placeholder={descriptionPlaceholderMessage}
            validate={composeValidators(required(descriptionRequiredMessage))}
          /> */}

            {/* <CustomCategorySelectFieldMaybe
            id="category"
            name="category"
            categories={categories}
            intl={intl}
          /> */}

            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label="Nazwa / tytuł oferty"
              placeholder="Podaj nazwę wydarzenia"
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(
                required('Musisz podać nazwę wydarzenia'),
                maxLength(
                  'Za długa nazwa wydarzenia - maksymalnie 60 znaków.',
                  60
                )
              )}
              autoFocus
            />

            <FieldCurrencyInput
              id="price"
              name="price"
              className={css.priceInput}
              label="Cena (wpisz 0 jeżeli oferta jest darmowa)"
              placeholder="Cena"
              currencyConfig={config.currencyConfig}
              validate={priceRequired}
            />

            {document.querySelector('#paymentEnabled') && document.querySelector('#paymentEnabled').checked && <div >
              <label style={{ marginRight: '10px', display: 'flex', alignItems: 'center', minWidth: '150px' }}>
                <input style={{ width: 'fit-content', marginRight: '5px' }} type='checkbox' checked={isTicketWithDiscount} onChange={() => setIsTicketWithDiscount(prev => !prev)} />
                Bilet Ulgowy
              </label>
              <FieldCurrencyInput
                id="priceWithDiscount"
                name="priceWithDiscount"
                className={css.priceInput}
                placeholder="Cena biletu ulgowego"
                currencyConfig={config.currencyConfig}
                validate={isTicketWithDiscount ? priceRequired : null}
                hidden={!isTicketWithDiscount}
              />
              {isTicketWithDiscount && <FieldTextInput
                id="discuntTicketRequirements"
                name="discuntTicketRequirements"
                className={css.description}
                type="textarea"
                label="Opisz komu przysługuje bilet ulgowy"
                placeholder="Opisz komu przysługuje bilet ulgowy"
                validate={isTicketWithDiscount ? composeValidators(
                  required('Pole jest wymagane')
                ) : null}
              // hidden={!isTicketWithDiscount}
              />}
            </div>}


            <EventTypeSelectField id="event_type" name="event_type" intl={intl} />

            {values.event_type === 'online' && (
              <FieldTextInput
                id="training_url"
                name="training_url"
                className={css.title}
                type="text"
                label="Link do treningu online"
                placeholder="Zostanie on udostępniony osobom, które zapisza się na trening"
              />
            )}

            <SportSelectField id="sport" name="sport" intl={intl} />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label="Opis oferty"
              placeholder="Podaj opis oferty"
              validate={composeValidators(
                required('Przynajmniej krótki opis oferty jest wymagany')
              )}
            />

            {/* <DateTimeField
            id="dateAndTime"
            name="dateAndTime"
            intl={intl}
          /> */}

            <FieldTextInput
              id="duration"
              name="duration"
              className={css.title}
              type="text"
              label="Czas trwania (opcjonalnie)"
              placeholder="Orientacyjny czas trwania"
            />

            {/* <FieldTextInput
            id="participants_limit"
            name="participants_limit"
            className={css.title}
            type="number"
            label="Limit uczestników (opcjonalnie)"
            placeholder="Limit uczestników"
          /> */}

            {/* <FieldTextInput
            id="website"
            name="website"
            className={css.title}
            type="text"
            label="Strona internetowa oferty (opcjonalnie)"
            placeholder="Strona internetowa oferty"
            maxLength={200}
            validate={composeValidators(maxLength("Zbyt długi link - maksymalnie 200 znaków.", 200))}
          /> */}

            {paymentsEnabledField && (
              <FieldCheckbox
                id="paymentEnabled"
                className={css.paymentEnabledCheckbox}
                name="paymentEnabled"
                label="Uruchom płatności online dla wydarzenia"
                value={true}
              // onChange={(e) => { console.log('onChange', e.currentTarget.checked) }}
              />
            )}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  )
};

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
