import React from 'react';
import AcademyPage from './AcademyPage/AcademyPage';
import PostPage from './PostPage/PostPage';

export const academyRoutes = [
  {
    path: '/blog',
    name: 'Academy',
    component: props => <AcademyPage {...props} />,
    loadData: AcademyPage.loadData,
  },
  {
    path: '/academy',
    name: 'Academy',
    component: AcademyPage,
  },
  {
    path: '/blog/:id',
    name: 'PostPage',
    component: props => <PostPage {...props} />,
    loadData: PostPage.loadData,
  },
  {
    path: '/academy/:id',
    name: 'PostPage',
    component: props => <PostPage {...props} />,
    loadData: PostPage.loadData,
  }
];