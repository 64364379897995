import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import css from './IconChevronLeft.css';

const IconChevronLeft = props => {
  const { rootClassName, className, ...rest } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      className={classes}
      {...rest}
    >
      <path
        stroke="currentColor"
        strokeWidth="1.1"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4698 5.46973L15.5304 6.53039L10.0608 12.0001L15.5304 17.4697L14.4698 18.5304L7.93945 12.0001L14.4698 5.46973Z"
      />
    </svg>
  );
};

IconChevronLeft.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconChevronLeft.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconChevronLeft;
