import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import css from './price.css';
import SidePanelContainer from '../../../containers/ListingPage/components/bookingPanel/SidePanelContainer';

const SectorsPriceModal = ({ pricing, handleCloseStadiumMap }) => {
  const dropdownRef = useRef(null);
  const [, setIsClickedOutsideSIdePanel] = useState(false);

  const workspaceKey = process.env.REACT_APP_WORKSPACE_KEY;

  const handleCloseSidePanel = event => {
    if (dropdownRef.current && dropdownRef.current.contains(event.target)) {
      setIsClickedOutsideSIdePanel(false);
    } else {
      handleCloseStadiumMap(false);
    }
  };

  useEffect(() => {
    async function loadScript() {
      try {
        const response = await fetch('/stadium/map/asset-manifest.json');
        const manifest = await response.json();
        const mainScript = manifest['files']['main.js'];
        const mainCss = manifest.files['main.css'];

        if (mainCss) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = `/stadium/map${mainCss}`;
          document.head.appendChild(link);
        }

        if (mainScript) {
          const script = document.createElement('script');
          script.src = `/stadium/map${mainScript}`;
          script.onload = () => {
            window.renderMap('mapContainerId', {
              pricing: pricing,
              handleOnObjectSelected: null,
              session: 'none',
              mode: 'static',
              workspaceKey,
              event: '274b8ab1-6e10-431a-a4a2-57b13b8de921',
            });
          };
          document.body.appendChild(script);
        }
      } catch (error) {
        console.error('Błąd podczas ładowania skryptu:', error);
      }
    }
    loadScript();
  }, []);

  return (
    <div className={css.map}>
      <div onClick={handleCloseSidePanel}>
        <SidePanelContainer ref={dropdownRef}>
          <div className={css.map_header}>
            <h1 className={css.header}>
              <LeftArrowIcon onClick={() => handleCloseStadiumMap(false)} />
              Podgląd sektorów
            </h1>
          </div>
          <div id="mapContainerId"></div>
          <button className={css.map_submitButton} onClick={() => handleCloseStadiumMap(false)}>
            Zamknij
          </button>
        </SidePanelContainer>
      </div>
    </div>
  );
};

export default React.memo(SectorsPriceModal);
