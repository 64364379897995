/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 */

import React from 'react';
import { arrayOf, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { FieldCheckboxGreen, FieldTextInput } from '../../components';

import css from './FieldCheckboxGroupWithLimits.css';

const FieldCheckboxRenderer = props => {
  const {
    checkName,
    className,
    fields,
    id,
    label,
    options,
    rootClassName,    
    textLabel,
    textName
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      
      <ul className={css.list}>

        {options.map(option => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckboxGreen
                id={fieldId}
                name={`${fields.name}.${checkName}`}
                label={option.label}
                value={option.key}
              />
              <FieldTextInput
                id={`${id}.${textName}`}
                name={`${fields.name}.${textName}.${option.key}`}
                className={css.title}
                type="number"
                label={textLabel}
                placeholder={textLabel}
              />
            </li>
          );
        })}

      </ul>
    </fieldset>
  );
};

FieldCheckboxRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  textLabel: null
};

FieldCheckboxRenderer.propTypes = {
  rootClassName: string,
  className: string,
  checkName: string.isRequired,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  textLabel: string,
  textName: string.isRequired
};

const FieldCheckboxGroupWithLimits = props => <FieldArray component={FieldCheckboxRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
FieldCheckboxGroupWithLimits.propTypes = {
  name: string.isRequired,
};

export default FieldCheckboxGroupWithLimits;