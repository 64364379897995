import React, { useState } from 'react';
import css from './styles.css';

const TextDisplay = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const formatDescription = (text) => {
        return text.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div>
            <div style={{ whiteSpace: 'pre-wrap' }}>
                {isExpanded ? formatDescription(text) : formatDescription(text.slice(0, 750))}
                {!isExpanded && text.length > 750 && '...'}
            </div>
            {text.length > 750 && (
                <button onClick={toggleExpand} className={css.showMoreButton}>
                    {isExpanded ? 'Pokaż mniej' : 'Pokaż więcej'}
                </button>
            )}
        </div>
    );
};
export default TextDisplay;