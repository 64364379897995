
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { parse } from '../../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_CONSOLE_TRANSACTIONS_REQUEST = 'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_REQUEST';
export const FETCH_CONSOLE_TRANSACTIONS_SUCCESS = 'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_SUCCESS';
export const FETCH_CONSOLE_TRANSACTIONS_ERROR = 'app/ConsoleTransactionPage/FETCH_CONSOLE_TRANSACTIONS_ERROR';

export const UPDATE_USER_REQUEST = 'app/ConsoleTransactionPage/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'app/ConsoleTransactionPage/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'app/ConsoleTransactionPage/UPDATE_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchListingListError: null,
  pagination: null,
  transactionList: [],
  updateUserInProgress: false,
  updateUserError: null,
};

export default function consoleUserReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_TRANSACTIONS_REQUEST:
      return { ...state, fetchInProgress: true, fetchListingListError: null };
    case FETCH_CONSOLE_TRANSACTIONS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        transactionList: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_TRANSACTIONS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchListingListError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchListingListRequest = () => ({ type: FETCH_CONSOLE_TRANSACTIONS_REQUEST });

const fetchListingListSuccess = response => ({
  type: FETCH_CONSOLE_TRANSACTIONS_SUCCESS,
  payload: response,
});

const fetchListingListError = e => ({
  type: FETCH_CONSOLE_TRANSACTIONS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 20;

export const loadData = (params, search) => (dispatch, getState, sdk) => {
  const { page = 1, keywords, entityId, userId, listingId } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const userIdMaybe = userId ? { userId } : {};
  const listingIdMaybe = listingId ? { listingId } : {};

  const queryParams = {
    ...paginationParams,
    keywords,
    include: ['listing', 'customer', 'provider', 'listing.images'],
    sort: '-createdAt',
    ...userIdMaybe,
    ...listingIdMaybe,
  };

  dispatch(fetchListingListRequest());

  if (entityId) {
    return sdk.goSdk.transactions
      .show({ id: entityId })
      .then(response => {
        const res = {
          ...response,
          data: {
            ...response.data,
            data: [response.data.data],
          },
        }
        dispatch(addMarketplaceEntities(res));
        dispatch(fetchListingListSuccess(res));
        return res;
      })
      .catch(e => {
        dispatch(fetchListingListError(e));
        throw e;
      });
  }

  return sdk.goSdk.transactions
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingListError(e));
      throw e;
    });
};

export const updateTransaction = data => {
  return (dispatch, getState, sdk) => {
    return sdk.goSdk.transactions
      .update(data)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        return response;
      })
      .catch(e => {
        // dispatch(updateUserError(e));
        throw e;
      });
  };
};

export const cancelTransaction = id => {
  return (dispatch, getState, sdk) => {
    return sdk.goSdk.transactions
      .update({ id, cancel: {} })
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        return response;
      })
      .catch(e => {
        // dispatch(updateUserError(e));
        throw e;
      });
  };
};

export const downloadTransactions = params => {
  return async (dispatch, getState, sdk) => {
    const data = [['id', 'customerId', 'customerEmail', 'customerDktUserId', 'providerId', 'listingId', 'listingName', 'state', 'processState', 'payinTotal', 'seats', 'reducedSeats', 'participants','customerData', 'metadata', 'protectedData','createdAt', 'lastTransitionAt','updatedAt']];

    const iterator = sdk.goSdk.transactions.createAsyncIterator({
      ...params,
      state: 'accepted',
      include: ['customer', 'listing', 'provider'],
      createdAtStart: '2024-01-01T00:00:00Z',
    });

    for await (const transaction of iterator) {

      data.push([
        transaction.id.uuid,
        transaction.rels.customer.id.uuid,
        transaction.rels.customer.attributes.email,
        transaction.rels.customer.attributes.dktUserId,
        transaction.rels.provider.id.uuid,
        transaction.rels.listing.id.uuid,
        transaction.rels.listing.attributes.title,
        transaction.attributes.state,
        transaction.attributes.processState,
        transaction.attributes.payinTotal ? transaction.attributes.payinTotal.amount : '',
        transaction.attributes.seats,
        transaction.attributes.reducedSeats,
        JSON.stringify(transaction.attributes.participants),
        JSON.stringify(transaction.attributes.customerData),
        JSON.stringify(transaction.attributes.metadata),
        JSON.stringify(transaction.attributes.protectedData),
        transaction.attributes.createdAt,
        transaction.attributes.lastTransitionAt,
        transaction.attributes.updatedAt
      ]);

      // if (transaction.attributes.participants.length) {
      //   for (const participant of transaction.attributes.participants) {
      //     data.push([
      //       transaction.rels.customer.attributes.profile.displayName,
      //       participant.name,
      //       participant.age,
      //     ]);
      //   }
      // } else {
      //   data.push([
      //     transaction.rels.customer.attributes.profile.displayName,
      //     '',
      //     '',
      //   ]);
      // }
    }

    await sdk.goSdk.xlsx.createFile(data, 'transakcje.xlsx');
  };
}