import React, { useState } from 'react';
import css from './styles.css';
import SidePanelContainer from './SidePanelContainer';
import { useIsMobile } from '../../../../util/hooks/useIsMobile';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

const ItemList = ({ items }) => {
    const [showAll, setShowAll] = useState(false);

    const isMobile = useIsMobile()

    const history = useHistory()
    const location = useLocation()

    const handleClick = () => {
        setShowAll(!showAll);
    };

    const handleClose = () => {
        setShowAll(false)
        history.push({
            pathname: location.pathname,
            search: ''
        });
    }

    const itemsToShow = showAll ? items : items.slice(0, 2);

    return (
        <div>
            {showAll ? (
                <SidePanelContainer>
                    <p className={css.header}>Dostępne terminy <span onClick={handleClose}>x</span></p>
                    <ul>
                        {itemsToShow.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </SidePanelContainer>
            ) : (
                isMobile ? (
                    itemsToShow[0]

                ) : (
                    <ul>
                        {itemsToShow.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>)
            )}
            {(isMobile ? items.length > 1 : items.length > 2) && <button onClick={handleClick} className={css.showMore}>
                {showAll ? 'Pokaż mniej' : 'Pokaż więcej terminów'}
            </button>}
        </div>
    );
};

export default ItemList;