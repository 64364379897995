import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconInfo = (props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      height="496pt"
      viewBox="0 0 20 20"
      width="20pt"
      xmlns="http://www.w3.org/2000/svg"
    >
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="248"
        x2="248"
        y1="496"
        y2="0"
      >
        <stop offset="0" stopColor="#9f2fff" />
        <stop offset="1" stopColor="#0bb1d3" />
      </linearGradient>
      <path
        d="m280 160c0-17.648438-14.351562-32-32-32s-32 14.351562-32 32 14.351562 32 32 32 32-14.351562 32-32zm-48 0c0-8.824219 7.175781-16 16-16s16 7.175781 16 16-7.175781 16-16 16-16-7.175781-16-16zm-24 160v48h80v-48h-16v-112h-64v48h16v64zm16-80v-16h32v112h16v16h-48v-16h16v-96zm24 256c136.742188 0 248-111.257812 248-248s-111.257812-248-248-248-248 111.257812-248 248 111.257812 248 248 248zm0-480c127.929688 0 232 104.070312 232 232s-104.070312 232-232 232-232-104.070312-232-232 104.070312-232 232-232zm-106.800781 82.214844-9.296875-13.023438c34.066406-24.335937 74.210937-37.191406 116.097656-37.191406 110.28125 0 200 89.71875 200 200 0 41.886719-12.855469 82.03125-37.191406 116.097656l-13.023438-9.296875c22.382813-31.335937 34.214844-68.273437 34.214844-106.800781 0-101.457031-82.542969-184-184-184-38.527344 0-75.464844 11.832031-106.800781 34.214844zm-93.199219 149.785156c0-41.886719 12.863281-82.03125 37.199219-116.097656l13.015625 9.296875c-22.382813 31.335937-34.214844 68.265625-34.214844 106.800781 0 101.457031 82.542969 184 184 184 38.527344 0 75.464844-11.832031 106.800781-34.214844l9.296875 13.023438c-34.066406 24.335937-74.210937 37.191406-116.097656 37.191406-110.28125 0-200-89.71875-200-200zm0 0"
      // fill="url(#a)"
      />
    </svg>
  );
};

const { string } = PropTypes;

IconInfo.defaultProps = {
  className: null,
  // rootClassName: null,
};

IconInfo.propTypes = {
  className: string,
  // rootClassName: string,
};

export default IconInfo;
