import React from 'react';
import { Reorder, useMotionValue } from 'framer-motion/dist/framer-motion';
import { CustomCheckbox } from '../../../components/CustomCheckbox';


const SingleFormElement = ({
    value,
    index,
    handleEditQuestion,
    handleRemoveElement,
    hadleIsRequiredField
}) => {
    const y = useMotionValue(0);

    const { id, element, elementData } = value;

    return (
        <Reorder.Item style={{ y, display: 'flex' }} value={value}>
            <span style={{ marginRight: '8px' }} >{index + 1}.</span>{' '}
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                <div style={{ width: '60%' }} >{element}</div>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-start' }} >

                    <CustomCheckbox value={elementData.isRequired} onChange={(e) => hadleIsRequiredField(e, id)} label={'Wymagane'} />

                    <button
                        onClick={(e) => handleEditQuestion(elementData, e)}
                        style={{ color: '#3643BA', textDecoration: 'underline', border: 'none', fontSize: '14px' }}
                    >
                        Edytuj
                    </button>
                    <button
                        onClick={() => handleRemoveElement(id)}
                        style={{ color: '#3643BA', textDecoration: 'underline', border: 'none', fontSize: '14px' }}
                    >
                        Usuń
                    </button>
                </div>
            </div>
        </Reorder.Item>
    );
};

export default SingleFormElement;