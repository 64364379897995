import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import css from './FixedBottom.css';

const FixedBottom = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.promo}>
        <div className={css.textContainer}>
          <span className={css.header1}>Wypożyczaj i uprawiaj sport</span>
          <span className={css.text1}>Skorzystaj z wypożyczalni i ciesz się dostępem do sportowych produktów</span>
        </div>
        <a href="https://rent.decathlon.pl?utm_source=decathlon_go&utm_medium=referral&utm_campaign=blog_mobile_bottom" target="_blank"><img src="https://kgcodego.b-cdn.net/rent-logo.png" className={css.image1}/></a>
      </div>         
    </div>
  );
};
  
FixedBottom.defaultProps = {
  rootClassName: null,
  className: null
};
  
FixedBottom.propTypes = {
  rootClassName: string,
  className: string,
};

export default FixedBottom;
  