import React from "react";
import css from './InboxPage.css';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { bookingData } from "./utils";
import {
    txIsEnquired,
} from '../../util/transaction';
import { intlShape } from 'react-intl';

const { bool } = PropTypes;

// Functional component as internal helper to print BookingInfo if that is needed
const BookingInfoMaybe = props => {

    const { bookingClassName, isOrder, intl, tx, unitType } = props;
    const isEnquiry = txIsEnquired(tx);

    if (isEnquiry) {
        return null;
    }
    const dateInfo = tx.date ? new Date(tx.date.attributes.date).toLocaleDateString() : 'brak daty'
    const price = tx.attributes.payinTotal ? tx.attributes.payinTotal.amount : 'darmowe'

    return (
        <div className={classNames(css.bookingInfo, bookingClassName)}>
            {dateInfo}
            <span className={css.itemPrice}>{price}</span>
        </div>
    );
};

BookingInfoMaybe.propTypes = {
    intl: intlShape.isRequired,
    isOrder: bool.isRequired,
    tx: propTypes.transaction.isRequired,
    unitType: propTypes.bookingUnitType.isRequired,
};

export default BookingInfoMaybe