import classNames from 'classnames';
import React from 'react';

import { LayoutWrapperFooter, LayoutWrapperTopbar } from '../../components';
import { TopbarContainer } from '../../containers';
import css from './LowInformationPage.css';

import lowInformationBanner from '../../assets/lowInformationBanner.png';
import FooterV2 from '../../components/Footer/FooterV2';

const LowInformation = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div style={{ position: 'relative' }}>
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="LowInformationPage" />
      </LayoutWrapperTopbar>
      <div className={classes}>
        <img src={lowInformationBanner} className={css.banner} />
      </div>
      <section className={css.section} style={{ background: '#F1F1F1' }}>
        <h2>Oferta platformy</h2>
        <article className={css.article}>
          W Decathlon GO znajdziesz zarówno wydarzenia całkowicie bezpłatne, jak i takie gdzie
          pobierana jest opłata za uczestnictwo. Informacja o cenie umieszczona jest zawsze na
          karcie wydarzenia.
        </article>
      </section>
      <section className={css.section}>
        <h2>Dane firmy</h2>
        <article className={css.article}>
          <p>Decathlon spółką z ograniczoną odpowiedzialnością</p>
          <p>adres: ul. Geodezyjna 76, 03-290 Warszawa</p>
          <p>numer KRS 0000007163,</p>
          <p>REGON: 013102058, NIP: 9511855233</p>
          <p>BDO: 000005259</p>
        </article>
      </section>
      <section className={css.section} style={{ background: '#F1F1F1' }}>
        <h2>Średnia liczba aktywnych odbiorców</h2>
        <article className={css.article}>
          Średnia liczba aktywnych miesięcznie odbiorców naszej platformy internetowej działającej
          pod adresem www.go.decathlon.pl wynosi 225 064. Data ostatniej aktualizacji: 11.2023).
          Informacja ta jest publikowana wyłącznie na potrzeby Aktu o Usługach Cyfrowych (tj.
          Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2022/2065){' '}
        </article>
      </section>
      <LayoutWrapperFooter>
        <FooterV2 />
      </LayoutWrapperFooter>
    </div>
  );
};

export default LowInformation;
