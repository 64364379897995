import React from "react";
import { useQuery } from "react-query";
import { apiSdk } from "../../queries/api";
import css from './styles.css';
import { Avatar } from "../../components";

const EventSummary = ({ transactionData, listingData, eventDate }) => {

    const { data, isSuccess } = useQuery('listing.query', () => apiSdk.listings.show(listingData.id.uuid, { include: ['images', 'author'] }));
    const image = isSuccess && data.included.filter(i => i.type === 'image')[0].attributes.variants['square-small2x'].url || null;
    const price = listingData.attributes.price.amount * transactionData.attributes.seats;

    return <div className={css.summaryContainer}>
        {/* <img src={image} alt="event" /> */}
        <div className={css.summaryText}>
            {/* <div className={css.avatar}>
                <Avatar user={isSuccess && data.included.filter(item => item.type === 'user')[0]} />
            </div> */}
            {/* <p className={css.title}>{listingData.attributes.title}</p>
            <p>Liczba wejsciówek: <span>{transactionData.attributes.seats}</span></p>
            <p>Suma: <span>{!!price ? `${price} zł` : 'darmowe'}</span></p>
            <p>Data wydarzenia: <br /><span>{formatDate(eventDate.attributes.date)}</span></p> */}

            {!listingData.attributes.paymentEnabled ? (
                <div>
                    <p style={{ paddingBottom: '10px', borderBottom: 'solid 1px #616161' }} className={css.title}>Podsumowanie</p>
                    <p className={css.item}>Cena za 1 bilet normalny <span>{listingData.attributes.price.amount / 100} zł</span></p>
                    {!!listingData.attributes.reducedPrice && <p className={css.item}>Cena za 1 bilet ulgowy <span>{listingData.attributes.reducedPrice.amount / 100} zł</span></p>}
                    <p className={css.title}>Opłatę uiścisz na miejscu u organizatora</p>
                </div>
            ) : null}
        </div>
    </div>
}

export default EventSummary;


const formatDate = (d) => {
    const date = new Date(d);

    const optionsDate = { weekday: 'long', day: 'numeric', month: 'long', };
    const optionsTime = { hour: '2-digit', minute: '2-digit' };

    const formattedDate = date.toLocaleDateString('pl-PL', optionsDate);
    const formattedTime = date.toLocaleTimeString('pl-PL', optionsTime);

    return `${formattedDate} godz. ${formattedTime}`
}