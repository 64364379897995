import React from 'react';
import PropTypes from 'prop-types';

const IconShoppingBag = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#FFF"
        d="M18 1c.315 0 .611.148.8.4l3 4c.13.173.2.384.2.6v14c0 1.657-1.343 3-3 3H5c-1.657 0-3-1.343-3-3V6c0-.216.07-.427.2-.6l3-4c.189-.252.485-.4.8-.4h12zm-.5 2h-11L4 6.333V20c0 .552.448 1 1 1h14c.552 0 1-.448 1-1V6.333L17.5 3zM16 9c.552 0 1 .448 1 1 0 2.761-2.239 5-5 5s-5-2.239-5-5c0-.552.448-1 1-1s1 .448 1 1c0 1.657 1.343 3 3 3s3-1.343 3-3c0-.552.448-1 1-1zm5-4c.552 0 1 .448 1 1s-.448 1-1 1H3c-.552 0-1-.448-1-1s.448-1 1-1h18z"
      />
    </svg>
  );
};

IconShoppingBag.defaultProps = { className: null };

const { string } = PropTypes;

IconShoppingBag.propTypes = {
  className: string,
};

export default IconShoppingBag;
