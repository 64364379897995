import React from 'react';
import css from './LoadingStatePlaceholder.css';
import styles from './ListingPage.css';
import cx from 'classnames';

export default function LoadingStatePlaceholder() {
  return (
    <div className={styles.section}>
      {/* <h2 className={css.header2}>Aktualne wydarzenia</h2> */}
      <div className={cx(css.header2, css.loads)}  />
      <div className={css.placeload}>
        <div className={css.header}>
          <div className={cx(css.img, css.loads)} />
          <div className={css.headerContent}>
            <div className={cx(css.contentShape, css.loads)} />
            <div className={cx(css.contentShape, css.loads)} />
          </div>
          <div className={cx(css.address, css.loads)} />
          <div className={cx(css.price, css.loads)} />
        </div>
      </div>
      <div className={css.placeload}>
        <div className={css.header}>
          <div className={cx(css.img, css.loads)} />
          <div className={css.headerContent}>
            <div className={cx(css.contentShape, css.loads)} />
            <div className={cx(css.contentShape, css.loads)} />
          </div>
          <div className={cx(css.address, css.loads)} />
          <div className={cx(css.price, css.loads)} />
        </div>
      </div>
      <div className={css.placeload}>
        <div className={css.header}>
          <div className={cx(css.img, css.loads)} />
          <div className={css.headerContent}>
            <div className={cx(css.contentShape, css.loads)} />
            <div className={cx(css.contentShape, css.loads)} />
          </div>
          <div className={cx(css.address, css.loads)} />
          <div className={cx(css.price, css.loads)} />
        </div>
      </div>
    </div>
  );
}