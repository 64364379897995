import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Section from '../../../../components/Section/Section';
import SlideShow from '../../../../components/SlideShow/SlideShow';

import css from './PopularActivities.css';

import bieganie from '../../images/sliders/activities/bieganie.png';
import fitness from '../../images/sliders/activities/fitness.png';
import kolarstwo from '../../images/sliders/activities/kolarstwo.png';
import siatkowka from '../../images/sliders/activities/siatkowka.png';
import turystyka from '../../images/sliders/activities/turystyka.png';

const activities = [
  {
    image: bieganie,
    imageHeight: 350,
    label: 'Bieganie',
    url: '/s/wydarzenia-sportowe/bieganie/cala-polska',
  },
  {
    image: kolarstwo,
    imageHeight: 350,
    label: 'Kolarstwo',
    url: '/s/wydarzenia-sportowe/kolarstwo/cala-polska',
  },
  {
    image: siatkowka,
    imageHeight: 350,
    label: 'Siatkówka',
    url: '/s/wydarzenia-sportowe/siatkowka/cala-polska',
  },
  {
    image: fitness,
    imageHeight: 350,
    label: 'Fitness',
    url: '/s/wydarzenia-sportowe/fitness/cala-polska',
  },
  {
    image: turystyka,
    imageHeight: 350,
    label: 'Turystyka',
    url: '/s/wydarzenia-sportowe/turystyka/cala-polska',
  },
];

const PopularActivities = () => {
  return (
    <Section title="Popularne aktywności" isSliderContainer>
      <SlideShow>
        {activities.map(({ label, url, image, imageHeight }) => (
          <li key={label}>
            <a href={url} className={css.activityLink}>
              <div className={css.imageContainer} style={{ height: `${imageHeight}px` }}>
                <LazyLoadImage alt={label} src={image} height={imageHeight} width="100%" />
              </div>
              <p className={css.activityLabel}>{label}</p>
            </a>
          </li>
        ))}
      </SlideShow>
    </Section>
  );
};

export default PopularActivities;
