import React from 'react';

import css from './PostPage.css';

const categories = [
  { labels: ['Wędkarstwo'], text: 'sprzęt wędkarski', url: 's?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&keywords=wędka' },
  { labels: ['Kajakarstwo', 'Triathlon', 'Sporty wodne', 'Pływanie', 'Dieta', 'Sporty wodne'], text: 'kajak', url: 's?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&keywords=kajak' },
  { labels: ['Turystyka', 'Góry', 'Trekking'], text: 'namiot', url: 's?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&keywords=namiot' },
  { labels: ['Rower', 'Rowery'], text: 'rower', url: 's?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&pub_category=bikes' }
]

const RentAd = ({ category }) => {
  const ad = categories.find(cat => cat.labels.includes(category)) || categories[Math.floor(Math.random() * 4)];

  return (
    <div>
      <div className={css.rentHeader}><span>{`Wypożycz ${ad.text}`}</span></div>
      <div className={css.rentSubheader}>na <a href={`https://rent.decathlon.pl/${ad.url}&utm_source=go.decathlon.pl&utm_medium=referral&utm_camopaign=blog-post-banner&utm_term=${category}`}>rent.decathlon.pl</a></div>
    </div>
  );
}

export default RentAd;