import React from 'react';
import PropTypes from 'prop-types';

const IconPinMap = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill="#AAA"
        d="M19.25 9.512l2 1c.15.05.25.2.25.35l2.5 12v.6c0 .3-.2.5-.5.5H23c-.1 0-.15 0-.25-.05l-5.25-2.85-5.25 2.85c-.15.1-.35.1-.5 0l-5.25-2.85-5.25 2.85c-.05.05-.15.05-.25.05H.5c-.3 0-.5-.2-.5-.5v-.6l2.5-12c.05-.15.15-.3.25-.35l2-1 .45.9-1.8.9-2.4 11.6 5.25-2.85c.15-.1.35-.1.5 0l5.25 2.85 5.25-2.85c.15-.1.35-.1.5 0l5.25 2.85-2.4-11.6-1.8-.9.45-.9zm-6.75 10.95v1h-1v-1h1zm0-2v1h-1v-1h1zM12.05.013c3.6 0 6.5 2.9 6.5 6.5 0 1.1-.35 2.1-.6 2.75-1.45 3.45-5.35 7.9-5.5 8.05-.15.1-.3.15-.45.15-.15 0-.3-.05-.35-.15-.15-.2-4.05-4.6-5.5-8.05-.4-1.05-.6-1.9-.6-2.75 0-3.6 2.9-6.5 6.5-6.5zm-.05.95c-3.05 0-5.5 2.45-5.5 5.5 0 .7.15 1.5.55 2.4 1.1 2.7 3.95 6.15 4.95 7.35 1-1.2 3.85-4.65 4.95-7.35.4-.9.55-1.65.55-2.4 0-3.05-2.45-5.5-5.5-5.5zm0 1.5c2.2 0 4 1.8 4 4s-1.8 4-4 4-4-1.8-4-4 1.8-4 4-4zm0 1c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm.5 2.5v1h-1v-1h1z"
      />
    </svg>
  );
};

IconPinMap.defaultProps = { className: null };

const { string } = PropTypes;

IconPinMap.propTypes = {
  className: string,
};

export default IconPinMap;
