export const competitions = {
  general : {
    name: 'trening, rozgrywki lub turniej',
    promoSection: 'swoje wydarzenia',
    headerSection: 'treningów, turniejów oraz rozgrywek',
    benefitsSection: 'wydarzeń'
  },
  league: {
    name: 'rozgrywki',
    promoSection: 'swoje rozgrywki',
    headerSection: 'rozgrywek',
    benefitsSection: 'rozgrywek'
  },
  training: {
    name: 'trening',
    promoSection: 'swoje treningi',
    headerSection: 'treningów',
    benefitsSection: 'treningów'
  },
  tournament: {
    name: 'turniej',
    promoSection: 'swój turniej',
    headerSection: 'turniejów',
    benefitsSection: 'turniejów'
  }
}