import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconArrowLeft.css';

const IconArrowLeft = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#2A374A" fillRule="nonzero" d="M12.707 4.293a1 1 0 0 1 0 1.414L7.413 11H19a1 1 0 1 1 0 2H7.415l5.292 5.293a1 1 0 0 1-1.414 1.414l-7-7-.073-.082a1.005 1.005 0 0 1-.007-.008l.08.09A1.008 1.008 0 0 1 4 12.02v-.037c0-.024.002-.048.004-.071L4 12a1.008 1.008 0 0 1 .213-.617c.025-.031.051-.062.08-.09l7-7a1 1 0 0 1 1.414 0z"/>
    </svg>
  );
};

IconArrowLeft.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconArrowLeft.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconArrowLeft;
