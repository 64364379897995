import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { InlineTextButton, NamedLink, ResponsiveImage } from '../../components';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  LINE_ITEM_DAY,
  LINE_ITEM_NIGHT,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  LISTING_STATE_PENDING_APPROVAL,
  propTypes,
} from '../../util/types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';

import { requestCreateListingDraft } from '../../containers/EditListingPage/EditListingPage.duck';

import { stringify } from 'querystring';
import CheckIcon from '../../containers/OrganizerPage/CheckIcon';
import CloseIcon from '../../containers/OrganizerPage/CloseIcon';
import DuplicateIcon from '../../containers/OrganizerPage/DuplicateIcon';
import EditIcon from '../../containers/OrganizerPage/EditIcon';
import ShowUsersIcon from '../../containers/OrganizerPage/ShowUsersIcon';
import { sdkGo } from '../../queries/api';
import css from './ManageListingCard.css';

// Menu content needs the same padding
const MENU_CONTENT_OFFSET = -12;
const MAX_LENGTH_FOR_WORDS_IN_TITLE = 7;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);
  const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const isBusinessCard = listing.attributes.publicData.type === 'business_card';
  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : null;

  const linkProps =
    isPendingApproval || isDraft
      ? {
          name: isBusinessCard ? 'OrganizerPageVariant' : 'ListingPageVariant',
          params: {
            id,
            slug,
            variant,
          },
        }
      : {
          name: isBusinessCard ? 'OrganizerPage' : 'ListingPage',
          params: {
            id,
            slug,
          },
        };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    hasClosingError,
    hasOpeningError,
    history,
    intl,
    isMenuOpen,
    actionsInProgressListingId,
    listing,
    onCloseListing,
    onOpenListing,
    onToggleMenu,
    renderSizes,
    availabilityEnabled,
    onCreateListingDraft,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, state } = currentListing.attributes;
  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const isBusinessCard = currentListing.attributes.publicData.type === 'business_card';
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const menuItemClasses = classNames(css.menuItem, {
    [css.menuItemDisabled]: !!actionsInProgressListingId,
  });

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const hasError = hasOpeningError || hasClosingError;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === id;

  const titleClasses = classNames(css.title, {
    [css.titlePending]: isPendingApproval,
    [css.titleDraft]: isDraft,
  });

  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ManageListingCard.perNight'
    : isDaily
    ? 'ManageListingCard.perDay'
    : 'ManageListingCard.perUnit';

  return (
    <div className={classes}>
      <div className={css.threeToTwoWrapper} tabIndex={0}>
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>

        <div className={css.container}>
          <div className={css.info}>
            <div className={css.mainInfo}>
              <div className={css.titleWrapper}>
                <InlineTextButton
                  rootClassName={titleClasses}
                  style={isDraft || isClosed ? { color: '#A0A0A0' } : null}
                  onClick={event => {
                    event.preventDefault();
                    event.stopPropagation();
                    history.push(createListingURL(routeConfiguration(), listing));
                  }}
                >
                  {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
                </InlineTextButton>
              </div>
            </div>

            <div className={css.price}>
              {formattedPrice ? (
                <React.Fragment>
                  <div className={css.priceValue} title={priceTitle}>
                    {formattedPrice}
                  </div>
                </React.Fragment>
              ) : (
                <div className={css.noPrice}>
                  {isBusinessCard ? null : <FormattedMessage id="ManageListingCard.priceNotSet" />}
                </div>
              )}
            </div>
          </div>

          <div className={css.actionscontainer}>
            <div className={css.buttons}>
              {isClosed && (
                <p
                  style={{
                    fontSize: '16px',
                    color: '#A0A0A0',
                    marginRight: '20px',
                    width: '100%',
                    maxWidth: '260px',
                    marginTop: 0,
                    marginBottom: 0,
                    lineHeight: '110%',
                  }}
                >
                  To wydarzenie zostało zakończone lub zamknięte
                </p>
              )}
              {isDraft && (
                <p
                  style={{ fontSize: '16px', color: '#A0A0A0', marginRight: '20px', width: '100%' }}
                >
                  Wersja robocza - dokończ edycję
                </p>
              )}

              <div
                className={css.manageLinks}
                style={isClosed ? { display: 'none' } : { display: 'flex', alignItems: 'center' }}
              >
                <EditIcon className={css.icon} />
                <NamedLink
                  className={css.manageLink}
                  name={isBusinessCard ? 'EditOrganizerPage' : 'EditListingPage'}
                  params={{
                    id,
                    slug,
                    type: editListingLinkType,
                    tab: 'description',
                    step: 0,
                  }}
                >
                  <FormattedMessage id="ManageListingCard.editListing" />
                </NamedLink>
              </div>
              {
                <div
                  style={
                    isDraft
                      ? { display: 'none' }
                      : { display: 'flex', position: 'relative', alignItems: 'center' }
                  }
                >
                  <DuplicateIcon className={css.icon} />
                  <InlineTextButton
                    // rootClassName={menuItemClasses}
                    className={css.manageLink}
                    onClick={async event => {
                      event.preventDefault();
                      event.stopPropagation();

                      const listingData = await sdkGo.listings.create({
                        duplicateOf: currentListing.id.uuid,
                      });
                      //duplicateEvent(currentListing.id.uuid)
                      // window.location.href = listingData
                      history.push(
                        `/l/event/${listingData.data.data.id.uuid}/edit/4?duplicate=true`
                      );
                    }}
                  >
                    <FormattedMessage id="ManageListingCard.duplicateListing" />
                  </InlineTextButton>
                  {isClosed && (
                    <InlineTextButton
                      type="button"
                      // className={css.openListingButton}
                      className={css.manageLinkRestore}
                      disabled={!!actionsInProgressListingId}
                      onClick={async event => {
                        event.preventDefault();
                        event.stopPropagation();
                        if (!actionsInProgressListingId) {
                          await onOpenListing(currentListing.id.uuid);
                          history.go(0);
                        }
                      }}
                    >
                      <CheckIcon />
                      <FormattedMessage id="ManageListingCard.openListing" />
                    </InlineTextButton>
                  )}
                </div>
              }
              {
                <div
                  style={
                    isDraft || isClosed
                      ? { display: 'none' }
                      : { display: 'flex', alignItems: 'center' }
                  }
                >
                  <CloseIcon className={css.icon} />
                  <InlineTextButton
                    type="button"
                    // rootClassName={menuItemClasses}
                    className={css.manageLink}
                    onClick={async event => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (!actionsInProgressListingId) {
                        onToggleMenu(null);
                        await onCloseListing(currentListing.id.uuid);
                        history.go(0);
                      }
                    }}
                  >
                    <FormattedMessage id="ManageListingCard.closeListing" />
                  </InlineTextButton>
                </div>
              }
            </div>
            {/* {isClosed && <p style={{ fontSize: '12px', color: '#A0A0A0' }}>To wydarzenie zostało zakończone</p>} */}

            {isDraft ? (
              <p style={{ fontSize: '12px', color: '#A0A0A0', marginRight: '20px' }}></p>
            ) : (
              <div style={{ marginRight: '20px' }}>
                {/* <InlineTextButton
                className={css.manageLink}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
              > */}
                {/* {currentListing.attributes.publicData.termins && currentListing.attributes.publicData.termins.length > 1 ? ( */}
                {currentListing.dates.length > 1 ? (
                  <NamedLink
                    name={'ManageListingsPage'}
                    // params={{ listingId: listing.id.uuid }}
                    to={{
                      hash: 'dates',
                      search: stringify({
                        listingId: listing.id.uuid,
                        // dates: currentListing.attributes.publicData.termins.map(({ date }) => date).join('-')
                      }),
                    }}
                    title={intl.formatMessage({ id: 'ManageListingCard.chooseDate' })}
                  >
                    <FormattedMessage id="ManageListingCard.chooseDate" />
                  </NamedLink>
                ) : (
                  <NamedLink
                    name={'ManageListingsPage'}
                    params={{ listingId: listing.id.uuid }}
                    to={{ search: stringify({ listingId: listing.id.uuid }) }}
                    title={intl.formatMessage({ id: 'ManageListingCard.seeConsumers' })}
                  >
                    <FormattedMessage id="ManageListingCard.seeConsumers" />
                  </NamedLink>
                )}
                {/* </InlineTextButton> */}
                <ShowUsersIcon className={css.icon} />
              </div>
            )}
          </div>
        </div>

        {/* <div
          className={classNames(css.menuOverlayWrapper, {
            [css.menuOverlayOpen]: isMenuOpen,
          })}
        >
          <div className={classNames(css.menuOverlay)} />
          <div className={css.menuOverlayContent}>
            <FormattedMessage id="ManageListingCard.viewListing" />
          </div>
        </div> */}

        {/* <div className={css.menubarWrapper}>
          <div className={css.menubarGradient} />
          <div className={css.menubar}>
            <Menu
              className={classNames(css.menu, { [css.cardIsOpen]: !isClosed })}
              contentPlacementOffset={MENU_CONTENT_OFFSET}
              contentPosition="left"
              useArrow={false}
              onToggleActive={(isOpen) => {
                const listingOpen = isOpen ? currentListing : null;
                onToggleMenu(listingOpen);
              }}
              isOpen={isMenuOpen}
            >
              <MenuLabel
                className={css.menuLabel}
                isOpenClassName={css.listingMenuIsOpen}
              >
                {!isBusinessCard && (
                  <div className={css.iconWrapper}>
                    <MenuIcon className={css.menuIcon} isActive={isMenuOpen} />
                  </div>
                )}
              </MenuLabel>
              <MenuContent rootClassName={css.menuContent}>
                <MenuItem key="close-listing">
                  <InlineTextButton
                    rootClassName={menuItemClasses}
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      if (!actionsInProgressListingId) {
                        onToggleMenu(null);
                        onCloseListing(currentListing.id);
                      }
                    }}
                  >
                    <FormattedMessage id="ManageListingCard.closeListing" />
                  </InlineTextButton>
                </MenuItem>
                <MenuItem key="close-listing">
                  <InlineTextButton
                    rootClassName={menuItemClasses}
                    onClick={async (event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      const listingData = await duplicateEvent(currentListing.id.uuid)
                      window.location.href = listingData
                    }}
                  >
                    <FormattedMessage id="ManageListingCard.duplicateListing" />
                  </InlineTextButton>
                </MenuItem>
              </MenuContent>
            </Menu>
          </div>
        </div> */}

        {/* {isDraft ? (
          <React.Fragment>
            <div className={classNames({ [css.draftNoImage]: !firstImage })} />
            <Overlay
              message={intl.formatMessage(
                { id: 'ManageListingCard.draftOverlayText' },
                { listingTitle: title }
              )}
            >
              <NamedLink
                className={css.finishListingDraftLink}
                name={isBusinessCard ? 'EditOrganizerPage' : 'EditListingPage'}
                params={{
                  id,
                  slug,
                  type: LISTING_PAGE_PARAM_TYPE_DRAFT,
                  tab: 'photos',
                }}
              >
                <FormattedMessage id="ManageListingCard.finishListingDraft" />
              </NamedLink>
            </Overlay>
          </React.Fragment>
        ) : null} */}
        {/* {isClosed ? (
          <Overlay
            message={intl.formatMessage(
              { id: 'ManageListingCard.closedListing' },
              { listingTitle: title }
            )}
          >
            <button
              className={css.openListingButton}
              disabled={!!actionsInProgressListingId}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (!actionsInProgressListingId) {
                  onOpenListing(currentListing.id);
                }
              }}
            >
              <FormattedMessage id="ManageListingCard.openListing" />
            </button>
          </Overlay>
        ) : null} */}
        {/* {isPendingApproval ? (
          <Overlay
            message={intl.formatMessage(
              { id: 'ManageListingCard.pendingApproval' },
              { listingTitle: title }
            )}
          />
        ) : null}
        {thisListingInProgress ? (
          <Overlay>
            <IconSpinner />
          </Overlay>
        ) : hasError ? (
          <Overlay
            errorMessage={intl.formatMessage({
              id: 'ManageListingCard.actionFailed',
            })}
          />
        ) : null} */}
      </div>

      <div className={css.info}>
        <div>
          {/* <div className={css.price}>
            {formattedPrice ? (
              <React.Fragment>
                <div className={css.priceValue} title={priceTitle}>
                  {formattedPrice}
                </div>
              </React.Fragment>
            ) : (
              <div className={css.noPrice}>
                {isBusinessCard ? null : (
                  <FormattedMessage id="ManageListingCard.priceNotSet" />
                )}
              </div>
            )}
          </div> */}

          {/* <div className={css.mainInfo}>
            <div className={css.titleWrapper}>
              <InlineTextButton
                rootClassName={titleClasses}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  history.push(createListingURL(routeConfiguration(), listing));
                }}
              >
                {formatTitle(title, MAX_LENGTH_FOR_WORDS_IN_TITLE)}
              </InlineTextButton>
            </div>
          </div> */}

          {/* <div className={css.manageLinks}>
            <NamedLink
              className={css.manageLink}
              name={isBusinessCard ? 'EditOrganizerPage' : 'EditListingPage'}
              params={{
                id,
                slug,
                type: editListingLinkType,
                tab: 'description',
              }}
            >
              <FormattedMessage id="ManageListingCard.editListing" />
            </NamedLink>
            {false ? (
              <React.Fragment>
                <span className={css.manageLinksSeparator}>{' • '}</span>

                <NamedLink
                  className={css.manageLink}
                  name="EditListingPage"
                  params={{
                    id,
                    slug,
                    type: editListingLinkType,
                    tab: 'availability',
                  }}
                >
                  <FormattedMessage id="ManageListingCard.manageAvailability" />
                </NamedLink>
              </React.Fragment>
            ) : null}
          </div> */}
        </div>
        {/* {currentListing.attributes.publicData.availabilityType ===
          'onetime' && (
            <div>
              <p>Pobierz listę uczestników</p>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <button
                  className={css.getdetailsbutton}
                  onClick={() => getEventDetailsData(currentListing.id.uuid)}
                >
                  <IconDownload />
                  <span>CSV</span>
                </button>
                <button
                  className={css.getdetailsbutton}
                  onClick={() => getEventDetailsDataPdf(currentListing.id.uuid)}
                >
                  <IconDownload />
                  <span>PDF</span>
                </button>
              </div>
            </div>
          )} */}
      </div>
    </div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  availabilityEnabled: config.enableAvailability,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  hasClosingError: bool.isRequired,
  hasOpeningError: bool.isRequired,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onToggleMenu: func.isRequired,
  availabilityEnabled: bool,
  onCreateListingDraft: func.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  onCreateListingDraft: values => dispatch(requestCreateListingDraft(values)),
});

export default compose(
  withRouter,
  connect(mapDispatchToProps)
)(injectIntl(ManageListingCardComponent));
