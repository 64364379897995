import { useState, useEffect, useRef } from 'react';

const useImageGallery = (images) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [displayedImageIndex, setDisplayedImageIndex] = useState(0);
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const modalRef = useRef(null);

    const openModal = (imageIndex = 0) => {
        setModalIsOpen(true);
        setDisplayedImageIndex(imageIndex);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const moveBack = (e) => {
        e.stopPropagation();
        setDisplayedImageIndex((displayedImageIndex - 1 + images.length) % images.length);
    };

    const moveForward = (e) => {
        e.stopPropagation();
        setDisplayedImageIndex((displayedImageIndex + 1) % images.length);
    };

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (modalIsOpen) {
                if (e.key === 'ArrowLeft') {
                    moveBack(e);
                } else if (e.key === 'ArrowRight') {
                    moveForward(e);
                } else if (e.key === 'Escape') {
                    closeModal();
                }
            }
        };

        const handleTouchStart = (e) => {
            touchStartX.current = e.touches[0].clientX;
        };

        const handleTouchMove = (e) => {
            touchEndX.current = e.touches[0].clientX;
        };

        const handleTouchEnd = () => {
            if (modalIsOpen) {
                if (touchStartX.current - touchEndX.current > 50) {
                    moveForward();
                }

                if (touchStartX.current - touchEndX.current < -50) {
                    moveBack();
                }
            }
        };

        if (modalRef.current) {
            modalRef.current.addEventListener('keydown', handleKeyDown);
            modalRef.current.addEventListener('touchstart', handleTouchStart);
            modalRef.current.addEventListener('touchmove', handleTouchMove);
            modalRef.current.addEventListener('touchend', handleTouchEnd);

            if (modalIsOpen) {
                modalRef.current.focus();
            }
        }

        return () => {
            if (modalRef.current) {
                modalRef.current.removeEventListener('keydown', handleKeyDown);
                modalRef.current.removeEventListener('touchstart', handleTouchStart);
                modalRef.current.removeEventListener('touchmove', handleTouchMove);
                modalRef.current.removeEventListener('touchend', handleTouchEnd);
            }
        };
    }, [modalIsOpen, displayedImageIndex]);

    return {
        modalIsOpen,
        displayedImageIndex,
        modalRef,
        openModal,
        closeModal,
        moveBack,
        moveForward,
    };
};

export default useImageGallery;
