import React from 'react';
import PropTypes from 'prop-types';
 

const IconInbox = props => {
  const { className, ...rest } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#FFF" fillRule="nonzero" d="M20 3c1.652 0 3 1.348 3 3v12c0 1.652-1.348 3-3 3H4c-1.652 0-3-1.348-3-3V6c0-1.652 1.348-3 3-3h16zm1 4.92l-8.427 5.9a1 1 0 0 1-1.146 0L3 7.92V18c0 .548.452 1 1 1h16c.548 0 1-.452 1-1V7.92zM20 5H4c-.388 0-.728.227-.893.554L12 11.779l8.893-6.225A1.006 1.006 0 0 0 20 5z"/>
    </svg>
  );
};

const { string } = PropTypes;

IconInbox.defaultProps = {
  className: null,
};

IconInbox.propTypes = {
  className: string,
};

export default IconInbox;
