import React, { createContext, useContext, useState, useReducer, useEffect } from 'react';
import GeneralInfo from './GeneralInfo/GeneralInfo';
import PriceForm from './PriceForm/PriceForm';
import Requirements from './Requirements/Requirements';
import Regulations from './Regulations/Regulations';
import Location from './Location/Location';
import EventDates from './EventDates/EventDates';
import AdditionalForm from './AdditionalForm/AdditionalForm';
import { useHistory, useParams } from 'react-router-dom';
import { apiSdk } from '../../queries/api';
import { useQuery } from 'react-query';
import VIPBooking from './VIPBooking/VIPBooking';

const getSteps = (isTicketing = false) =>
  isTicketing
    ? {
        0: {
          id: 0,
          name: 'Tworzenie oferty',
          component: <GeneralInfo isTicketing={isTicketing} />,
        },
        1: { id: 1, name: 'Wybór ceny', component: <PriceForm /> },
        2: { id: 2, name: 'Dodaj miejsca VIP', component: <VIPBooking /> },
        3: { id: 3, name: 'Zasady i regulamin', component: <Regulations /> },
        // 4: { id: 4, name: 'AdditionalForm', component: <AdditionalForm register={register} /> },
        // 4: { id: 4, name: 'EventComplete', component: <EventComplete register={register} /> },
      }
    : {
        0: {
          id: 0,
          name: 'Opis i cena',
          component: <GeneralInfo />,
        },
        1: { id: 1, name: 'Wymagania', component: <Requirements /> },
        2: { id: 2, name: 'Zasady i regulamin', component: <Regulations /> },
        3: { id: 3, name: 'Lokalizacja', component: <Location /> },
        4: { id: 4, name: 'Dostępność', component: <EventDates /> },
        5: { id: 5, name: 'Formularz pytań dodatkowych', component: <AdditionalForm /> },
        // 4: { id: 4, name: 'EventComplete', component: <EventComplete register={register} /> },
      };

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

const initialState = {
  generalInfo: {
    description: '',
    discountPrice: null,
    isForFree: false,
    isEvent: true,
    eventTitle: '',
    eventType: null,
    files: '[]',
    price: null,
    sport: '',
  },
  requirements: {
    forWho: {
      female: false,
      male: false,
      children: false,
    },
    level: {
      novice: false,
      beginner: false,
      intermediate: false,
      advanced: false,
      professional: false,
    },
  },
  regulations: {
    cancel: '',
    isPrivate: false,
    qrEnabled: false,
    pdfFile: null,
    pdfFileUrl: '',
  },
  eventDates: {
    termins: [],
  },
  additiionalForm: {},
  sectorsPrice: [],
  VIPSeats: {},
  isEvent: { isEvent: true },
};

const formDataReducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FORM_DATA':
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          ...action.data,
        },
      };
    default:
      return state;
  }
};

export const FormProvider = ({ children, isDraftURI }) => {
  const [formData, dispatch] = useReducer(formDataReducer, initialState);
  const isTicketing = !formData.isEvent.isEvent;
  const steps = getSteps(isTicketing);

  const { step, id, type } = useParams();
  const history = useHistory();
  const { data, isSuccess } = useQuery(['listing.query', id], () =>
    apiSdk.listings.show(id, { include: ['dates', 'author', 'images', 'termsFile'] })
  );

  useEffect(() => {
    const eventData = isSuccess ? data.data : null;
    const included = isSuccess ? data.included : null;
    console.log(eventData)
    if (eventData && id !== '00000000-0000-0000-0000-000000000000') {
      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'generalInfo',
        data: {
          description: eventData.attributes.description,
          discountPrice: eventData.attributes.publicData.priceWithDiscount,
          isForFree: !eventData.attributes.price || eventData.attributes.price === 0 ? true : false,
          isEvent: eventData.attributes.publicData.isEvent,
          selectedDate: eventData.attributes.publicData.date ? new Date(eventData.attributes.publicData.date) : null,
          eventTitle: eventData.attributes.title,
          eventType: eventData.attributes.publicData.event_type,
          files: JSON.stringify(included.filter(item => item.type === 'image')),
          price: eventData.attributes.price ? eventData.attributes.price.amount / 100 : null,
          reducedPrice: eventData.attributes.reducedPrice
            ? eventData.attributes.reducedPrice.amount / 100
            : null,
          stadium: eventData.attributes.publicData.stadium,
          sport: eventData.attributes.publicData.sport,
          paymentEnabled: eventData.attributes.paymentEnabled,
        },
      });

      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'isEvent',
        data: { isEvent: eventData.attributes.publicData.isEvent },
      });

      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'additiionalForm',
        data: { data: eventData.attributes.publicData.additionalForm },
      });

      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'sectorsPrice',
        data: { data: eventData.attributes.publicData.sectorsPrice },
      });

      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'VIPSeats',
        data: { data: eventData.attributes.publicData.VIPSeats },
      });

      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'eventDates',
        data: {
          termins: included
            .filter(item => item.type === 'listing-date')
            .map(item => {
              return {
                date: item.attributes.date,
                duration: item.attributes.duration,
                limit: item.attributes.participantsLimit,
                id: `${item.attributes.date}_${item.attributes.participantsLimit}_${item.attributes.duration}`,
                dateId: item.id.uuid,
              };
            }),
        },
      });

      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'requirements',
        data: {
          forWho: {
            children:
              eventData.attributes.publicData.gender &&
              eventData.attributes.publicData.gender.includes('children'),
            female:
              eventData.attributes.publicData.gender &&
              eventData.attributes.publicData.gender.includes('female'),
            male:
              eventData.attributes.publicData.gender &&
              eventData.attributes.publicData.gender.includes('male'),
          },
          level: {
            novice:
              eventData.attributes.publicData.levels &&
              eventData.attributes.publicData.levels.includes('novice'),
            beginner:
              eventData.attributes.publicData.levels &&
              eventData.attributes.publicData.levels.includes('beginner'),
            intermediate:
              eventData.attributes.publicData.levels &&
              eventData.attributes.publicData.levels.includes('intermediate'),
            advanced:
              eventData.attributes.publicData.levels &&
              eventData.attributes.publicData.levels.includes('advanced'),
            professional:
              eventData.attributes.publicData.levels &&
              eventData.attributes.publicData.levels.includes('professional'),
          },
        },
      });

      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'regulations',
        data: {
          cancel: eventData.attributes.publicData.returns_policy,
          isPrivate: eventData.attributes.hidden, //eventData.attributes.publicData.listing_hide,
          pdfFileUrl:
            included &&
            !!included.filter(item => item.type === 'file').length &&
            included.filter(item => item.type === 'file')[0].attributes.url,
          pdfFile: null, //included && !!included.filter(item => item.type === 'file').length && included.filter(item => item.type === 'file')[0].attributes.url,
          qrEnabled: eventData.attributes.publicData.qrEnabled,
        },
      });

      dispatch({
        type: 'UPDATE_FORM_DATA',
        section: 'location',
        data: {
          location: eventData.attributes.geolocation,
          directions: eventData.attributes.publicData.directions,
        },
      });
    }
  }, [id, isSuccess]);

  const stepIndex = Number(step);

  const navigate = step => {
    history.push(`/l/event/${id}/${type}/${step}`);
  };

  const nextStep = () => navigate(stepIndex + 1);
  const prevStep = () => navigate(stepIndex - 1);
  const moveToStep = step => navigate(step);

  const updateFormData = (data, section) => {
    dispatch({ type: 'UPDATE_FORM_DATA', section: section, data: data });
    if (step < Object.keys(steps).length - 1 && type !== 'edit' && section !== 'isEvent')
      nextStep();
  };

  return (
    <FormContext.Provider
      value={{
        formData,
        updateFormData,
        moveToStep,
        prevStep,
        step,
        steps,
        listingId: id,
        type,
        isLastStep: Number(step) === Object.keys(steps).length - 1,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
