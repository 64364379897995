import React from 'react';
import calendarImg from '../images/calendar.svg';
import clockImg from '../images/clock.svg';
import locationImg from '../images/location.svg';
import moment from 'moment';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { IconChevronBigLeft, IconChevronBigRight, NamedLink } from '../../../components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import css from './OurTrainersSection.css';
import Slider from "react-slick";
import classNames from 'classnames';
import Prev from '../Schedule/Prev';
import Next from '../Schedule/Next';
import { withViewport } from '../../../util/contextHelpers';
import { compose } from 'redux';

const PrevArrow = props => {
  const { className, color, onClick } = props;
  return (
    <div className={classNames(className, css.carousel, css.left)} onClick={onClick}>
      <Prev color={color} />
    </div>
  );
};

const NextArrow = props => {
  const { className, color, onClick } = props;
  return (
    <div className={classNames(className, css.carousel, css.right)} onClick={onClick}>
      <Next color={color} />
    </div>
  );
}

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

const OurTrainersSection = props => {
  const { trainers, viewport } = props;

  const isMobileShow = viewport.width < MAX_MOBILE_SCREEN_WIDTH ? 1 : null;
  const isTabletShow = viewport.width < MAX_TABLET_SCREEN_WIDTH && viewport.width >= MAX_MOBILE_SCREEN_WIDTH ? 2 : null;
  const slidesToShow = isMobileShow || isTabletShow || 3;

  const settings = {
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow color={isMobileShow ? '#fff' : '#3643BA'} />,
    prevArrow: <PrevArrow color={isMobileShow ? '#fff' : '#3643BA'} />
  };

  if (!trainers.length) return null;

  return (
    <div className={css.container}>
      <h2 className={css.title}>Nasi trenerzy</h2>

      <Slider {...settings} className={css.slider}>
        {
          trainers.map((trainer, i) =>
            <NamedLink className={css.link} key={i} name="LandingPage">
              <h3 className={css.name}>{trainer.name}</h3>
              <div className={css.desc}>{trainer.description}</div>

              <div className={css.imageContainer}>
                <img
                  alt={trainer.name}
                  src={trainer.image}
                />
              </div>
              {/* <NamedLink className={css.trainingsLink} name="LandingPage">Zobacz treningi</NamedLink> */}
            </NamedLink>
          )
        }
      </Slider>
    </div>
  )
};

export default compose(withViewport)(OurTrainersSection);