import SingleFieldType from "./SigleFieldType";
import React from "react";

import checkboxUrl from '../../../../assets/addEventForm/checkbox.png';
import selectUrl from '../../../../assets/addEventForm/select.png';
import textareaUrl from '../../../../assets/addEventForm/textarea.png';

import css from '../styles.css';

const FieldsChoose = ({
    selectedTypeOfQuestion,
    setSelectedTypeOfQuestion,
    setStep,
    isOpenAddQuestion,
    setIsOpenAddQuestion
}) => {
    return (
        <>
            <h2 onClick={() => setIsOpenAddQuestion(prev => !prev)} className={css.hoverItem}>+ Wybierz typ pola</h2>
            <div
                className={`${isOpenAddQuestion ? 'h-full' : 'h-0'} transition-height duration-500 overflow-hidden`}
                style={isOpenAddQuestion ? { height: '100%', overflow: 'hidden' } : { height: 0, overflow: 'hidden', transition: '.5s' }}

            >
                <SingleFieldType
                    onClick={() => {
                        setSelectedTypeOfQuestion('checkboxField');
                        setStep('editField');
                    }}
                    selectedTypeOfQuestion={selectedTypeOfQuestion}
                    picture={checkboxUrl}
                    title={'Pole wielokrotnego wyboru'}
                    description={
                        <span>
                            Uczestnik może wybrać{' '}
                            <span style={{ textDecoration: 'underline' }}>1 lub więcej</span>{' '}
                            odpowiedzi
                        </span>
                    }
                    fieldType={'checkboxField'}
                />
                <SingleFieldType
                    onClick={() => {
                        setSelectedTypeOfQuestion('selectField');
                        setStep('editField');
                    }}
                    selectedTypeOfQuestion={selectedTypeOfQuestion}
                    picture={selectUrl}
                    title={'Pole jednokrotnego wyboru'}
                    description={
                        <span>
                            Uczestnik może wybrać{' '}
                            <span style={{ textDecoration: 'underline' }}>tylko 1</span>{' '}
                            odpowiedź
                        </span>
                    }
                    fieldType={'selectField'}
                />
                <SingleFieldType
                    onClick={() => {
                        setSelectedTypeOfQuestion('textareaField');
                        setStep('editField');
                    }}
                    selectedTypeOfQuestion={selectedTypeOfQuestion}
                    picture={textareaUrl}
                    title={'Pole tekstowe'}
                    description={'Uczestnik wpisuje odpowiedź na pytanie'}
                    fieldType={'textareaField'}
                />
            </div>
        </>
    );
};

export default FieldsChoose;