import React, { Component, useState } from 'react';
import { object, string, bool, number, func, shape, array } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';
import InfoModal from './Modal';

import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import {
  ModalInMobile,
  Button,
  KeywordFilter,
  PriceFilter,
  SelectSingleFilter,
  SelectMultipleFilter,
  BookingDateRangeFilter,
  EventDateRangeFilter,
  IconInfo,
  IconSettings,
} from '../../components';
import { propTypes } from '../../util/types';
import CustomCheckbox from './CustomCheckbox';
import css from './SearchFiltersMobile.css';

const RADIX = 10;

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFiltersOpenOnMobile: false,
      initialQueryParams: null,
      isHovered: false,
    };

    this.openFilters = this.openFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleSelectSingle = this.handleSelectSingle.bind(this);
    this.handleSelectMultiple = this.handleSelectMultiple.bind(this);
    this.handlePrice = this.handlePrice.bind(this);
    this.handleDateRange = this.handleDateRange.bind(this);
    this.handleKeyword = this.handleKeyword.bind(this);
    this.handleSportSupport = this.handleSportSupport.bind(this);
    this.initialValue = this.initialValue.bind(this);
    this.initialValues = this.initialValues.bind(this);
    this.initialPriceRangeValue = this.initialPriceRangeValue.bind(this);
    this.initialDateRangeValue = this.initialDateRangeValue.bind(this);
  }

  // Open filters modal, set the initial parameters to current ones
  openFilters() {
    const { onOpenModal, urlQueryParams } = this.props;
    onOpenModal();
    this.setState({
      isFiltersOpenOnMobile: true,
      initialQueryParams: urlQueryParams,
    });
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, landingData } = this.props;
    const landingDataName = landingData || 'SearchPage';

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        this.state.initialQueryParams
      )
    );
    onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false, initialQueryParams: null });
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
    this.setState({ isFiltersOpenOnMobile: false });
  }

  handleSelectSingle(urlParam, option) {
    const { urlQueryParams, history, landingData } = this.props;
    const landingDataName = landingData || 'SearchPage';
    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  handleSelectMultiple(urlParam, options) {
    const { urlQueryParams, history, landingData } = this.props;
    const landingDataName = landingData || 'SearchPage';

    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  handleSelectAnyOptions = (urlParam, options) => {
    const { urlQueryParams, history, landingData } = this.props;
    const landingDataName = landingData || 'SearchPage';

    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: `has_any:${options.join(',')}` }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  };

  handlePrice(urlParam, range) {
    const { urlQueryParams, history, landingData } = this.props;
    const landingDataName = landingData || 'SearchPage';
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  handleDateRange(urlParam, dateRange) {
    const { urlQueryParams, history, landingData } = this.props;
    const landingDataName = landingData || 'SearchPage';
    // const hasDates = dateRange && dateRange.dates;
    // const { startDate, endDate } = hasDates ? dateRange.dates : {};

    // const start = startDate ? stringifyDateToISO8601(startDate) : null;
    // const end = endDate ? stringifyDateToISO8601(endDate) : null;

    // const queryParams =
    //   start != null && end != null
    //     ? { ...urlQueryParams, [urlParam]: `${start},${end}` }
    //     : omit(urlQueryParams, urlParam);
    // history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
    const hasDates = dateRange && dateRange.dates;
    const { startDate, endDate } = hasDates ? dateRange.dates : {};

    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;

    const queryParams =
      start != null && end != null
        ? {
            ...urlQueryParams,
            pub_date: `${new Date(start).getTime()},${new Date(end).getTime()}`,
          }
        : omit(urlQueryParams, 'pub_date');
    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  handleKeyword(urlParam, keywords) {
    const { urlQueryParams, history, landingData } = this.props;
    const landingDataName = landingData || 'SearchPage';

    const queryParams = urlParam
      ? { ...urlQueryParams, [urlParam]: keywords }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  handleSportSupport() {
    const {
      urlQueryParams,
      history,
      landingData,
      sportSupportFilter,
    } = this.props;

    const landingDataName = landingData || 'SearchPage';

    const urlParam = sportSupportFilter.paramName;

    const queryParams = !this.initialValue(urlParam)
      ? { ...urlQueryParams, [urlParam]: true }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  }

  // Reset all filter query parameters
  resetAll(e) {
    const {
      urlQueryParams,
      history,
      filterParamNames,
      landingData,
    } = this.props;
    const landingDataName = landingData || 'SearchPage';

    const queryParams = omit(urlQueryParams, filterParamNames);
    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  // resolve initial value for a single value filter
  initialValue(paramName) {
    return this.props.urlQueryParams[paramName];
  }

  // resolve initial values for a multi value filter
  initialValues(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    return !!urlQueryParams[paramName]
      ? urlQueryParams[paramName].split(',')
      : [];
  }

  initialPriceRangeValue(paramName) {
    const urlQueryParams = this.props.urlQueryParams;
    const price = urlQueryParams[paramName];
    const valuesFromParams = !!price
      ? price.split(',').map((v) => Number.parseInt(v, RADIX))
      : [];

    return !!price && valuesFromParams.length === 2
      ? {
          minPrice: valuesFromParams[0],
          maxPrice: valuesFromParams[1],
        }
      : null;
  }

  initialDateRangeValue(paramName) {
    // const urlQueryParams = this.props.urlQueryParams;
    // const dates = urlQueryParams[paramName];
    // const rawValuesFromParams = !!dates ? dates.split(',') : [];
    // const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
    // const initialValues =
    //   !!dates && valuesFromParams.length === 2
    //     ? {
    //         dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
    //       }
    //     : { dates: null };

    // return initialValues;
    const dates = this.props.urlQueryParams.pub_date;
    const rawValuesFromParams = !!dates ? dates.toString().split(',') : [];
    const valuesFromParams = rawValuesFromParams.map((v) => {
      const date = new Date(v * 1);
      return parseDateFromISO8601(date).toString();
    });
    const initialValues =
      !!dates && valuesFromParams.length === 2
        ? {
            dates: {
              startDate: valuesFromParams[0],
              endDate: valuesFromParams[1],
            },
          }
        : { dates: null };

    return initialValues;
  }

  handleTypeChange = (type) => {
    const { history, landingData } = this.props;
    const landingDataName = landingData || 'SearchPage';

    // const { pub_sport, pub_sports, pub_event_type, pub_business_type, pub_date, price, ...rest } = urlQueryParams;
    // const queryParams = !initialSportSupport.length
    // ? { ...urlQueryParams, [urlParam]: true }
    // : omit(urlQueryParams, urlParam);
    const typeSpecificParams =
      type === 'event'
        ? omit(this.props.urlQueryParams, ['pub_sports', 'pub_business_type'])
        : omit(this.props.urlQueryParams, [
            'pub_event_type',
            'pub_sport',
            'pub_date',
            'price',
          ]);
    const queryParams = { ...typeSpecificParams, pub_type: type };
    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );

    // e.target.blur();
  };

  render() {
    const {
      rootClassName,
      className,
      listingsAreLoaded,
      resultsCount,
      searchInProgress,
      showAsModalMaxWidth,
      onMapIconClick,
      onManageDisableScrolling,
      selectedFiltersCount,
      eventTypeFilter,
      businessTypeFilter,
      sportFilter,
      sportsFilter,
      priceFilter,
      dateRangeFilter,
      intl,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const businessCardsView =
      this.props.urlQueryParams.pub_type === 'business_card';

    const resultsFound = businessCardsView ? (
      <FormattedMessage
        id="SearchFilters.foundResultsBusinessCard"
        values={{ count: resultsCount }}
      />
    ) : (
      <FormattedMessage
        id="SearchFilters.foundResults"
        values={{ count: resultsCount }}
      />
    );

    const noResults = <FormattedMessage id="SearchFilters.noResultsMobile" />;
    const loadingResults = (
      <FormattedMessage id="SearchFilters.loadingResultsMobile" />
    );
    const filtersHeading = businessCardsView
      ? intl.formatMessage({ id: 'SearchFiltersMobile.headingBusiness' })
      : intl.formatMessage({ id: 'SearchFiltersMobile.heading' });
    const modalCloseButtonMessage = intl.formatMessage({
      id: 'SearchFiltersMobile.cancel',
    });

    const showListingsLabel = businessCardsView
      ? intl.formatMessage(
          { id: 'SearchFiltersMobile.showBusinesses' },
          { count: resultsCount }
        )
      : intl.formatMessage(
          { id: 'SearchFiltersMobile.showListings' },
          { count: resultsCount }
        );

    const filtersButtonClasses =
      selectedFiltersCount > 0 ? css.filtersButtonSelected : css.filtersButton;

    const sportLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.sportLabel',
    });

    const initialSport = sportFilter
      ? this.initialValues(sportFilter.paramName)
      : null;

    const sportFilterElement =
      sportFilter && !businessCardsView ? (
        <SelectMultipleFilter
          id="SearchFiltersMobile.sportFilter"
          name="sport"
          urlParam={sportFilter.paramName}
          label={sportLabel}
          onSubmit={this.handleSelectMultiple}
          liveEdit
          options={sportFilter.options}
          initialValues={initialSport}
        />
      ) : null;

    const initialSports = sportsFilter
      ? this.initialValues(sportsFilter.paramName)
      : null;

    const sportsBusinessType =
      sportsFilter && businessCardsView ? (
        <SelectMultipleFilter
          id="SearchFilters.sportsFilter"
          name="sports"
          urlParam={sportsFilter.paramName}
          label="Sport"
          onSubmit={this.handleSelectAnyOptions}
          options={sportsFilter.options}
          liveEdit
          initialValues={initialSports}
        />
      ) : null;

    const eventTypeLabel = intl.formatMessage({
      id: 'SearchFiltersMobile.eventTypeLabel',
    });

    const initialEventTypes = eventTypeFilter
      ? this.initialValues(eventTypeFilter.paramName)
      : null;

    const eventTypeElement =
      eventTypeFilter && !businessCardsView ? (
        <SelectMultipleFilter
          name="event_type"
          id="SearchFiltersMobile.eventTypeFilter"
          urlParam={eventTypeFilter.paramName}
          label={eventTypeLabel}
          onSubmit={this.handleSelectMultiple}
          liveEdit
          options={eventTypeFilter.options}
          initialValues={initialEventTypes}
        />
      ) : null;

    const initialBusinessTypes = businessTypeFilter
      ? this.initialValues(businessTypeFilter.paramName)
      : null;

    const businessType =
      businessTypeFilter && businessCardsView ? (
        <SelectMultipleFilter
          id="SearchFilters.businessTypeFilter"
          name="business_type"
          urlParam={businessTypeFilter.paramName}
          label="Rodzaj działalności"
          onSubmit={this.handleSelectAnyOptions}
          options={businessTypeFilter.options}
          liveEdit
          initialValues={initialBusinessTypes}
        />
      ) : null;

    const initialPriceRange = this.initialPriceRangeValue(
      priceFilter.paramName
    );

    const priceFilterElement =
      priceFilter && !businessCardsView ? (
        <PriceFilter
          id="SearchFiltersMobile.priceFilter"
          urlParam={priceFilter.paramName}
          onSubmit={this.handlePrice}
          liveEdit
          {...priceFilter.config}
          initialValues={initialPriceRange}
        />
      ) : null;

    const initialDateRange = this.initialDateRangeValue(
      dateRangeFilter.paramName
    );

    const dateRangeFilterElement =
      false &&
      dateRangeFilter &&
      dateRangeFilter.config.active &&
      !businessCardsView ? (
        <EventDateRangeFilter
          id="SearchFiltersMobile.dateRangeFilter"
          urlParam={dateRangeFilter.paramName}
          onSubmit={this.handleDateRange}
          liveEdit
          showAsPopup={false}
          initialValues={initialDateRange}
        />
      ) : null;

    return (
      <>
        <span></span>
        <div className={classes}>
          <div
            onClick={() =>
              this.setState((prev) => {
                return { ...prev, isHovered: !prev.isHovered };
              })
            }
            style={{ position: 'relative', width: '100%' }}
          >
            <span className={classNames(css.searchFilterProductsOrder)}>
              Kolejność produktów{' '}
              <IconInfo className={classNames(css.searchFilterInfoIcon)} />
            </span>
            {this.state.isHovered && <InfoModal />}
          </div>
          <div className={css.searchResultSummary}>
            {/* {listingsAreLoaded && resultsCount > 0 ? resultsFound : null}
          {listingsAreLoaded && resultsCount === 0 ? noResults : null}
          {searchInProgress ? loadingResults : null} */}
            <select
              value={businessCardsView ? 'business_card' : 'event'}
              onChange={(e) => this.handleTypeChange(e.target.value)}
              className={css.typeSelect}
            >
              <option value="event">Wydarzenia</option>
              <option value="business_card">Organizatorzy</option>
            </select>
          </div>

          <div className={css.buttons}>
            <Button
              rootClassName={filtersButtonClasses}
              onClick={this.openFilters}
            >
              <FormattedMessage
                id="SearchFilters.filtersButtonLabel"
                className={css.mapIconText}
              />
            </Button>
            <div className={css.mapIcon} onClick={onMapIconClick}>
              <FormattedMessage
                id="SearchFilters.openMapView"
                className={css.mapIconText}
              />
            </div>
          </div>

          <ModalInMobile
            id="SearchFiltersMobile.filters"
            isModalOpenOnMobile={this.state.isFiltersOpenOnMobile}
            onClose={this.cancelFilters}
            showAsModalMaxWidth={showAsModalMaxWidth}
            onManageDisableScrolling={onManageDisableScrolling}
            containerClassName={css.modalContainer}
            closeButtonMessage={modalCloseButtonMessage}
          >
            <div className={css.modalHeadingWrapper}>
              <span className={css.modalHeading}>{filtersHeading}</span>
              <button
                className={css.resetAllButton}
                onClick={(e) => this.resetAll(e)}
              >
                <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
              </button>
            </div>
            {this.state.isFiltersOpenOnMobile ? (
              <div className={css.filtersWrapper}>
                {sportFilterElement}
                {sportsBusinessType}
                {eventTypeElement}
                {businessType}
                {priceFilterElement}
                {dateRangeFilterElement}
              </div>
            ) : null}

            <div className={css.showListingsContainer}>
              <Button
                className={css.showListingsButton}
                onClick={this.closeFilters}
              >
                {showListingsLabel}
              </Button>
            </div>
          </ModalInMobile>
        </div>
      </>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  landingData: null,
  resultsCount: null,
  searchingInProgress: false,
  selectedFiltersCount: 0,
  filterParamNames: [],
  categoryFilter: null,
  eventTypeFilter: null,
  sportFilter: null,
  sportSupportFilter: null,
  priceFilter: null,
  levelsFilter: null,
  dateRangeFilter: null,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  landingData: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onMapIconClick: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  selectedFiltersCount: number,
  filterParamNames: array,
  categoriesFilter: propTypes.filterConfig,
  eventTypeFilter: propTypes.filterConfig,
  sportFilter: propTypes.filterConfig,
  sportSupportFilter: propTypes.filterConfig,
  priceFilter: propTypes.filterConfig,
  dateRangeFilter: propTypes.filterConfig,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = injectIntl(
  withRouter(SearchFiltersMobileComponent)
);

export default SearchFiltersMobile;
