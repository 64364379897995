import css from './CalculatorPage.css';

const result = {
  default: {
    css: css.default,
    header: '',
    text: ''
  },
  starvation: {
    css: css.starvation,
    header: 'Wygłodzenie',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  emaciation: {
    css: css.emaciation,
    header: 'Wychudzenie',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  underweight: {
    css: css.underweight,
    header: 'Niedowaga',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  correct: {
    css: css.correct,
    header: 'Waga prawidłowa',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  overweight: {
    css: css.overweight,
    header: 'Nadwaga',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  obesity1: {
    css: css.obesity1,
    header: 'I stopień otyłości',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  obesity2: {
    css: css.obesity2,
    header: 'II stopień otyłości',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  extreme: {
    css: css.extreme,
    header: 'Otyłość skrajna',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
}

export default function bmiHelper(bmi) {
  let state = null;

  if (!bmi) return result.default;

  if(bmi < 16) return result.starvation;
  if(bmi >= 16 && bmi < 17) return result.emaciation;
  if(bmi >= 17 && bmi < 18.5) return result.underweight;
  if(bmi >= 18.5 && bmi < 25) return result.correct;
  if(bmi >= 25 && bmi < 30) return result.overweight;
  if(bmi >= 30 && bmi < 35) return result.obesity1;
  if(bmi >= 35 && bmi < 40) return result.obesity2;
  if(bmi >= 40) return result.extreme;  

  return result[state];
}

