import React, { Component } from 'react';
import Decimal from 'decimal.js';
import {
  bool,
  func,
  instanceOf,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { propTypes, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
} from '../../util/data';
import { dateFromLocalToAPI, minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { formatMoney } from '../../util/currency';
import {
  TRANSITION_ENQUIRE,
  txIsPaymentPending,
  txIsPaymentExpired,
} from '../../util/transaction';
import {
  AvatarMedium,
  BookingBreakdown,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
} from '../../components';
import { StripePaymentForm } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  handleCardPayment,
  handleP24Payment,
  retrievePaymentIntent,
} from '../../ducks/stripe.duck.js';

import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  confirmPayment,
  sendMessage,
} from './CheckoutPage.duck';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';

import { nightsBetween, daysBetween } from '../../util/dates';
import { types as sdkTypes } from '../../util/sdkLoader';
import AdditionalUserForm from './AdditionalUserForm';
import css from './CheckoutPage.css';

import { getLoyalityConsent, setLoyalityConsents } from '../../util/api';

const STORAGE_KEY = 'CheckoutPage';
const { Money } = sdkTypes;

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = [
  'processing',
  'requires_capture',
  'succeeded',
];

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = (existingTransaction) => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
      ? minutesBetween(
        existingTransaction.attributes.lastTransitionedAt,
        new Date()
      ) >= 15
      : false;
};

export class CheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      subbittingAdditionalForm: false,
      additionalFormData: null,
      // isLoyalityActive: false,
      // isLoyalityChecked: null,
      // accesToken: null,
    };
    this.stripe = null;

    this.onStripeInitialized = this.onStripeInitialized.bind(this);
    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitAdditionalForm = this.handleSubmitAdditionalForm.bind(
      this
    );
  }


  componentWillMount() {
    if (window) {
      this.loadInitialData();
    }
  }

  customPricingParams(params) {
    const {
      bookingStart,
      bookingEnd,
      listing,
      quantity,
      seatsWithDiscount,
      discount,
      ...rest
    } = params;

    const { amount, currency } = listing.attributes.price;

    const discountLine = discount
      ? {
        code: 'line-item/voucher',
        unitPrice: new Money(
          listing.attributes.publicData.discount.value,
          'PLN'
        ),
        quantity: -1,
        name: 'test',
      }
      : null;

    const discountLineMaybe = discountLine ? [discountLine] : [];


    const discountTicketsLine = seatsWithDiscount ? [{
      code: 'line-item/event-participation-discount',
      unitPrice: new Money(listing.attributes.publicData.priceWithDiscount, currency),
      quantity: seatsWithDiscount,
    }] : []

    return {
      listingId: listing.id,
      bookingStart,
      bookingEnd,
      seats: quantity,
      seatsWithDiscount,
      lineItems: [
        {
          code: 'line-item/event-participation',
          unitPrice: new Money(amount, currency),
          quantity,
        },
        ...discountTicketsLine,
        ...discountLineMaybe,
      ],
      ...rest,
    };
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      history,
    } = this.props;

    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink =
      history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps =
      !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      // const listingId = pageData.listing.id;
      const { bookingStart, bookingEnd } = pageData.bookingDates;
      // Convert picked date to date that will be converted on the API as
      // a noon of correct year-month-date combo in UTC
      const bookingStartForAPI = dateFromLocalToAPI(bookingStart);
      const bookingEndForAPI = dateFromLocalToAPI(bookingEnd);

      const transactionId = tx ? tx.id : null;

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      const speculatedTransactionParams = this.customPricingParams({
        listing: pageData.listing,
        bookingStart: bookingStartForAPI,
        bookingEnd: bookingEndForAPI,
        quantity: pageData.bookingData ? pageData.bookingData.quantity : 1,
        discount: pageData.bookingData ? pageData.bookingData.discount : null,
        seatsWithDiscount: pageData.bookingData ? pageData.bookingData.seatsWithDiscount : null,
      });
      fetchSpeculatedTransaction(speculatedTransactionParams, transactionId);
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams, additionalFormvalues) {
    const {
      onInitiateOrder,
      onHandleCardPayment,
      onHandleP24Payment,
      onConfirmPayment,
      onSendMessage,
    } = this.props;
    const {
      pageData,
      speculatedTransaction,
      message,
      paymentMethod,
    } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = (fnParams) => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData &&
        storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: pay using Stripe SDK
    const fnHandleCardPayment = (fnParams) => {
      // fnParams should be returned transaction entity

      const order = ensureTransaction(fnParams);
      if (order.id) {
        // Store order.
        const { bookingData, bookingDates, listing } = pageData;
        storeData(bookingData, bookingDates, listing, order, STORAGE_KEY);
        this.setState({ pageData: { ...pageData, transaction: order } });
      }

      const hasPaymentIntents =
        order.attributes.protectedData &&
        order.attributes.protectedData.stripePaymentIntents;

      if (!hasPaymentIntents) {
        throw new Error(
          `Missing StripePaymentIntents key in transaction's protectedData. Check that your transaction process is configured to use payment intents.`
        );
      }

      const { stripePaymentIntentClientSecret } = hasPaymentIntents
        ? order.attributes.protectedData.stripePaymentIntents.default
        : null;

      const {
        stripe,
        card,
        billingDetails,
        paymentIntent,
      } = handlePaymentParams;

      const params = {
        stripePaymentIntentClientSecret,
        orderId: order.id,
        stripe,
        card,
        paymentParams: {
          payment_method_data: {
            billing_details: billingDetails,
          },
        },
      };

      const handlePaymentFn =
        paymentMethod === 'p24' ? onHandleP24Payment : onHandleCardPayment;

      // If paymentIntent status is not waiting user action,
      // handleCardPayment has been called previously.
      const hasPaymentIntentUserActionsDone =
        paymentIntent &&
        STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);
      return hasPaymentIntentUserActionsDone
        ? Promise.resolve({ transactionId: order.id, paymentIntent })
        : handlePaymentFn(params);
    };

    // Step 3: complete order by confirming payment to Marketplace API
    // Parameter should contain { paymentIntent, transactionId } returned in step 2
    const fnConfirmPayment = onConfirmPayment;

    // Step 4: send initial message
    const fnSendMessage = (fnParams) => {
      return onSendMessage({ ...fnParams, message });
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => (x) =>
      funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnHandleCardPayment,
      fnConfirmPayment,
      fnSendMessage
    );

    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;
    const orderParams = {
      listingId: pageData.listing.id,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
    };

    const requestParams = this.customPricingParams({
      listing: pageData.listing,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      quantity: pageData.bookingData ? pageData.bookingData.quantity : 1,
      discount: pageData.bookingData ? pageData.bookingData.discount : null,
      seatsWithDiscount: pageData.bookingData ? pageData.bookingData.seatsWithDiscount : null,
      additionalFormData: additionalFormvalues,
      qrConfirmation: pageData.listing.attributes.publicData.qr_confirmation,
    });

    return handlePaymentIntentCreation({ ...requestParams, paymentMethod });
  }
  handleSubmitAdditionalForm(values) {
    const currentListing = ensureListing(this.state.pageData.listing);
    // const isRewardActive = currentListing.attributes.publicData.reward_active || false
    if (this.state.subbittingAdditionalForm) {
      return;
    }
    // if (this.state.isLoyalityChecked && !this.state.isLoyalityActive) {
    //   setLoyalityConsents(this.state.accesToken);
    // }
    this.setState({
      subbittingAdditionalForm: true,
      additionalFormData: values,
    });
  }

  handleSubmit(values) {
    const currentListing = ensureListing(this.state.pageData.listing);
    // const isRewardActive = currentListing.attributes.publicData.reward_active || false
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const {
      history,
      speculatedTransaction,
      currentUser,
      paymentIntent,
      dispatch,
    } = this.props;
    const { additionalValues, card, message, formValues } = values;
    const {
      name,
      addressLine1,
      addressLine2,
      postal,
      city,
      state,
      country,
      paymentMethod,
    } = formValues;

    // Billing address is recommended.
    // However, let's not assume that <StripePaymentAddress> data is among formValues.
    // Read more about this from Stripe's docs
    // https://stripe.com/docs/stripe-js/reference#stripe-handle-card-payment-no-element
    const addressMaybe =
      addressLine1 && postal
        ? {
          address: {
            city: city,
            country: country,
            line1: addressLine1,
            line2: addressLine2,
            postal_code: postal,
            state: state,
          },
        }
        : {};
    const billingDetails = {
      name,
      email: ensureCurrentUser(currentUser).attributes.email,
      ...addressMaybe,
    };

    const requestPaymentParams = {
      additionalValues,
      pageData: this.state.pageData,
      speculatedTransaction,
      stripe: this.stripe,
      card,
      billingDetails,
      message,
      paymentIntent,
      paymentMethod,
    };

    this.handlePaymentIntent(requestPaymentParams, additionalValues)
      .then((res) => {
        const { orderId, messageSuccess } = res;
        this.setState({ submitting: false });

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess
          ? null
          : orderId;
        const orderDetailsPath = pathByRouteName('OrderDetailsPage', routes, {
          id: orderId.uuid,
        });

        initializeOrderPage(
          { initialMessageFailedToTransaction },
          routes,
          dispatch
        );
        clearData(STORAGE_KEY);

        const amount = speculatedTransaction.attributes.payinTotal.amount / 100;
        //fb event
        window.fbq('trackSingle', '383270155928431', 'Purchase', {
          value: amount,
          currency: 'PLN',
        });
        history.push(orderDetailsPath);
      })
      .catch((err) => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  onStripeInitialized(stripe) {
    this.stripe = stripe;

    const { paymentIntent, onRetrievePaymentIntent } = this.props;
    const tx = this.state.pageData ? this.state.pageData.transaction : null;

    // We need to get up to date PI, if booking is created but payment is not expired.
    const shouldFetchPaymentIntent =
      this.stripe &&
      !paymentIntent &&
      tx &&
      tx.id &&
      tx.booking &&
      tx.booking.id &&
      txIsPaymentPending(tx) &&
      !checkIsPaymentExpired(tx);

    if (shouldFetchPaymentIntent) {
      const { stripePaymentIntentClientSecret } =
        tx.attributes.protectedData &&
          tx.attributes.protectedData.stripePaymentIntents
          ? tx.attributes.protectedData.stripePaymentIntents.default
          : {};

      // Fetch up to date PaymentIntent from Stripe
      onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
    }
  }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(
      speculatedTransactionMaybe,
      {},
      null
    );
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;

    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error(
        'Missing or invalid data for checkout, redirecting back to listing page.',
        {
          transaction: speculatedTransaction,
          bookingDates,
          listing,
        }
      );
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking
      ? existingTransaction
      : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const breakdown =
      tx.id && txBooking.id ? (
        <BookingBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={tx}
          booking={txBooking}
          availabilityType={listing.attributes.publicData.availabilityType}
        />
      ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.
    const showPaymentForm = !!(
      currentUser &&
      hasRequiredData &&
      !listingNotFound &&
      !initiateOrderError &&
      !speculateTransactionError &&
      !retrievePaymentIntentError &&
      !isPaymentExpired
    );

    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage(
      { id: 'CheckoutPage.title' },
      { listingTitle }
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0
        ? currentListing.images[0]
        : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(
      initiateOrderError
    );
    const isChargeDisabledError = isTransactionChargeDisabledError(
      initiateOrderError
    );
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(
      initiateOrderError
    );

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderError"
            values={{ listingLink }}
          />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (
      isTransactionInitiateMissingStripeAccountError(speculateTransactionError)
    ) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (
      isTransactionInitiateBookingTimeNotAvailableError(
        speculateTransactionError
      )
    ) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'CheckoutPage.perNight'
      : isDaily
        ? 'CheckoutPage.perDay'
        : 'CheckoutPage.perUnit';

    const price = currentListing.attributes.price;
    const formattedPrice = formatMoney(intl, price);

    const showInitialMessageInput = false;

    const pageProps = { title, scrollingDisabled };

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent &&
      STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}
    const paymentMethodFromPaymentIntent =
      paymentIntent &&
        paymentIntent.payment_method_types &&
        paymentIntent.payment_method_types.length === 1
        ? paymentIntent.payment_method_types[0]
        : undefined;
    const initalValuesForStripePayment = {
      name: userName,
      paymentMethod:
        paymentMethodFromPaymentIntent ||
        (currentAuthor.attributes.profile.metadata &&
          currentAuthor.attributes.profile.metadata.p24Enabled
          ? undefined
          : 'card'),
    };

    return (currentListing.attributes.publicData.additionalForm && !!currentListing.attributes.publicData.additionalForm.length) || isPaymentExpired || showPaymentForm ? (
      <Page {...pageProps}>
        {topbar}
        <div className={css.contentContainer}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={listingTitle}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
            <AvatarMedium user={currentAuthor} disableProfileLink />
          </div>
          <div className={css.bookListingContainer}>
            <div className={css.heading}>
              <h1 className={css.title}>{title}</h1>

            </div>

            <div className={css.priceBreakdownContainer}>
              <h3 className={css.priceBreakdownTitle}>
                <FormattedMessage id="CheckoutPage.priceBreakdownTitle" />
              </h3>
              {speculateTransactionErrorMessage}
              {breakdown}
            </div>

            <section className={css.paymentContainer}>
              {initiateOrderErrorMessage}
              {listingNotFoundErrorMessage}
              {speculateErrorMessage}
              {retrievePaymentIntentError ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.retrievingStripePaymentIntentFailed"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
              {!this.state.subbittingAdditionalForm &&
                currentListing.attributes.publicData.additionalForm && !!currentListing.attributes.publicData.additionalForm.length && (
                  <AdditionalUserForm
                    handleSubmitRegisterForm={this.handleSubmitAdditionalForm}
                    additionalForm={
                      currentListing.attributes.publicData.additionalForm
                    }
                    cta="Przejdź do płatności"
                  />
                )}
              {showPaymentForm &&
                (this.state.subbittingAdditionalForm ||
                  !currentListing.attributes.publicData.additionalForm.length) ? (
                <StripePaymentForm
                  additionalForm={this.state.additionalFormData} //{listing.attributes.publicData.additionalForm}
                  className={css.paymentForm}
                  onSubmit={this.handleSubmit}
                  inProgress={this.state.submitting}
                  formId="CheckoutPagePaymentForm"
                  paymentInfo={intl.formatMessage({
                    id: 'CheckoutPage.paymentInfo',
                  })}
                  p24Enabled={
                    !!currentAuthor.attributes.profile.metadata.p24Enabled
                  }
                  authorDisplayName={
                    currentAuthor.attributes.profile.displayName
                  }
                  showInitialMessageInput={showInitialMessageInput}
                  initialValues={initalValuesForStripePayment}
                  initiateOrderError={initiateOrderError}
                  handleCardPaymentError={handleCardPaymentError}
                  confirmPaymentError={confirmPaymentError}
                  hasHandledCardPayment={hasPaymentIntentUserActionsDone}
                  paymentIntent={paymentIntent}
                  onStripeInitialized={this.onStripeInitialized}
                />
              ) : null}
              {isPaymentExpired ? (
                <p className={css.orderError}>
                  <FormattedMessage
                    id="CheckoutPage.paymentExpiredMessage"
                    values={{ listingLink }}
                  />
                </p>
              ) : null}
            </section>
          </div>

          <div className={css.detailsContainerDesktop}>
            <div className={css.detailsAspectWrapper}>
              <ResponsiveImage
                rootClassName={css.rootForImage}
                alt={listingTitle}
                image={firstImage}
                variants={['landscape-crop', 'landscape-crop2x']}
              />
            </div>
            <div className={css.avatarWrapper}>
              <AvatarMedium user={currentAuthor} disableProfileLink />
            </div>
            <div className={css.detailsHeadings}>
              <h2 className={css.detailsTitle}>{listingTitle}</h2>
              <div className={css.author}>
                <FormattedMessage
                  id="CheckoutPage.hostedBy"
                  values={{
                    name: currentAuthor.attributes.profile.displayName,
                  }}
                />
              </div>

              {listing.attributes.publicData.term_of_use && <div className={css.author} style={{ fontWeight: 'bold' }}>
                <FormattedMessage
                  id="CheckoutPage.termsOfUse"
                />
              </div>}
            </div>
            <h3 className={css.bookingBreakdownTitle}>
              <FormattedMessage id="CheckoutPage.priceBreakdownTitle" />
            </h3>
            {speculateTransactionErrorMessage}
            {breakdown}
          </div>
        </div>
      </Page>) : null

  }
}

CheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

CheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    listing,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
  } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onInitiateOrder: (params, transactionId) =>
    dispatch(initiateOrder(params, transactionId)),
  // fetchSpeculatedTransaction: params => dispatch(speculateTransaction(params)),
  fetchSpeculatedTransaction: (params, transactionId) =>
    dispatch(speculateTransaction(params, transactionId)),
  onRetrievePaymentIntent: (params) => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: (params) => dispatch(handleCardPayment(params)),
  onHandleP24Payment: (params) => dispatch(handleP24Payment(params)),
  onConfirmPayment: (params) => dispatch(confirmPayment(params)),
  onSendMessage: (params) => dispatch(sendMessage(params)),
});

const CheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CheckoutPageComponent);

CheckoutPage.setInitialValues = (initialValues) =>
  setInitialValues(initialValues);

CheckoutPage.displayName = 'CheckoutPage';

export default CheckoutPage;
