import React from 'react';
import PropTypes from 'prop-types';

const IconLogout = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2A374A"
        fillRule="nonzero"
        stroke="none"
        d="M6 1.333a.667.667 0 1 1 0 1.334H3.333a.667.667 0 0 0-.666.666v9.334c0 .368.298.666.666.666H6a.667.667 0 0 1 0 1.334H3.333a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2H6zm5.138 2.862l3.333 3.334.054.06.026.035a.697.697 0 0 1 .086.179.664.664 0 0 1-.086.573l-.026.036a.685.685 0 0 1-.037.042l-.017.017-3.333 3.334a.667.667 0 1 1-.943-.943l2.194-2.196H6a.667.667 0 0 1 0-1.333h6.39l-2.195-2.195a.667.667 0 1 1 .943-.943z"
      />
    </svg>


  );
};

IconLogout.defaultProps = { className: null };

const { string } = PropTypes;

IconLogout.propTypes = {
  className: string,
};

export default IconLogout;