import React, { useEffect } from 'react';
import FormItem from '../../../components/FormItem';
import { getCharts } from '../../../../util/api';

const EventArea = ({ register, errors }) => {
  const [charts, setCharts] = React.useState([]);
  useEffect(() => {
    const fetchCarts = async () => {
      try {
        const data = await getCharts();
        setCharts(data.items);
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchCarts();
  }, []);

  return (
    <FormItem className={'Wybór obiektu'}>
      <select
        defaultValue={''}
        className="p-3 w-full border relative"
        {...register('stadium', { required: true })}
      >
        <option value={''} disabled>
          wybierz stadion
        </option>

        {charts.map(({ id, name, key }) => (
          <option value={`${key}_${name}`} key={id}>
            {name}
          </option>
        ))}
      </select>
      {errors.stadium && errors.stadium.type === 'required' && (
        <p className="text-[#f00]" role="alert">
          Miejsce wydarzenia jest wymagane
        </p>
      )}
    </FormItem>
  );
};

export default EventArea;
