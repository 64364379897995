import React from 'react';
import { richText } from '../../util/richText';
import cx from 'classnames';

import css from './ListingPage.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionOfferDescriptionMaybe = props => {
  const { serviceDescription } = props;

  return serviceDescription ? (
    <div className={cx(css.section, css.withBorder)}>
      <h2 className={css.header2}>Opis oferty</h2>
      <p className={css.description}>
        {richText(serviceDescription, {
          longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION,
          longWordClass: css.longWord,
        })}
      </p>
    </div>
  ) : null;
};

export default SectionOfferDescriptionMaybe;
