import React from 'react';
import BookingWithoutPayment from './BookingWithoutPayment';
import { FormattedMessage } from 'react-intl';
import { ModalInMobile, Button } from '../../components';
import { parse, stringify } from '../../util/urlHelpers';
import omit from 'lodash/omit';
import {
    LISTING_STATE_CLOSED,
} from '../../util/types';


import css from './BookingPanel.css';

const openBookModal = (isOwnListing, isClosed, history, location,) => {
    if (isOwnListing || isClosed) {
        window.scrollTo(0, 0);
    } else {
        const { pathname, search, state } = location;
        const searchString = `?${stringify({ ...parse(search), book: true })}`;
        history.push(`${pathname}${searchString}`, state);
    }
};
const closeBookModal = (history, location) => {
    const { pathname, search, state } = location;
    const searchParams = omit(parse(search), 'book');
    const searchString = `?${stringify(searchParams)}`;
    history.push(`${pathname}${searchString}`, state);
};

const MODAL_BREAKPOINT = 1023;


const MobileWithModal = (props) => {
    const {
        availabilityType,
        listing,
        timeSlots,
        fetchTimeSlotsError,
        intl,
        classes,
        onSubmitBookingRequest,
        price,
        isSingleBookingError,
        setIsSingleBookingError,
        hidePrice,
        history,
        location,
        isBook,
        // isClosed,
        isOwnListing,
        formattedPrice,
        priceTitle,
        title,
        onManageDisableScrolling,
        authorDisplayName,
        titleClasses
    } = props;


    const hasListingState = !!listing.attributes.state;
    const isClosed =
        hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;

    return (
        <div className={classes}>

            <ModalInMobile
                className={css.container}
                containerClassName={css.modalContainer}
                id="BookingDateFormInModal"
                isModalOpenOnMobile={isBook}
                onClose={() => closeBookModal(history, location)}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
            >
                <div className={css.modalHeading}>
                    <div className={css.title}>{title}</div>
                    <div className={css.author}>
                        <FormattedMessage
                            id="BookingPanel.hostedBy"
                            values={{ name: authorDisplayName }}
                        />
                    </div>
                </div>

                <div className={css.bookingHeading}>
                    <div className={titleClasses}>{title}</div>
                </div>
                <BookingWithoutPayment
                    availabilityType={availabilityType}
                    bookingRequired={listing.attributes.publicData.bookingRequired}
                    discountParam={listing.attributes.publicData.discount}
                    onlinePaymentAvailable={
                        (listing.attributes.metadata &&
                            !!listing.attributes.metadata.online_payment) ||
                        listing.attributes.publicData.paymentEnabled
                    }
                    intl={intl}
                    infoClassName={css.bookingHelp}
                    onSubmit={onSubmitBookingRequest}
                    price={price}
                    priceWithDiscount={listing.attributes.publicData.priceWithDiscount}
                    timeSlots={timeSlots}
                    fetchTimeSlotsError={fetchTimeSlotsError}
                    isSingleBookingError={isSingleBookingError}
                    setIsSingleBookingError={setIsSingleBookingError}
                    termins={listing.attributes.publicData.termins}
                    eventDate={listing.attributes.publicData.date}
                    isSTSEvent={listing.attributes.metadata.sts_run}
                    listingId={listing.id.uuid}
                    hidePrice={hidePrice}
                    flexDate={listing.attributes.publicData.flex_date}
                    onlySubmitButton={false}
                />

            </ModalInMobile>
            <div className={css.openBookingForm}>
                {!listing.attributes.publicData.flex_date && !hidePrice && <div className={css.priceContainer}>
                    <div className={css.priceValue} title={priceTitle}>
                        {formattedPrice}
                    </div>
                </div>}
                {listing.attributes.publicData.bookingRequired ? (
                    <Button
                        rootClassName={css.bookButton}
                        onClick={() => {
                            // if (!(listing.attributes.publicData.termins && listing.attributes.publicData.termins.length > 1) && !(listing.attributes.publicData.paymentEnabled)) {
                            //   onSubmitBookingRequest({
                            //     seats: 1,
                            //     seatsWithDiscount: 0,
                            //     priceWithDiscount: 0,
                            //     discount: 0,
                            //     date: timeSlots[0] ? timeSlots[0].attributes.start : null,
                            //     end: timeSlots[0] ? timeSlots[0].attributes.end : null
                            //   })
                            // } else {
                            openBookModal(isOwnListing, isClosed, history, location)

                            // }

                        }
                        }
                    >
                        <FormattedMessage id="BookingPanel.ctaButtonMessage" />
                    </Button>
                ) : (
                    <div className={css.closedListingButton}>
                        Rejestracja nie jest wymagana.
                    </div>
                )}
            </div>
        </div>
    )
};


export default (MobileWithModal);
