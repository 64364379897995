

export default function humanDate(date, intl) {
  const now = new Date();
  now.setHours(0,0,0,0);
  const eventDate = new Date(date);
  eventDate.setHours(0,0,0,0);
  const daysBetween = (eventDate.getTime() - now.getTime())/ (1000 * 60 * 60 * 24);

  if (daysBetween === 0) return 'Dzisiaj';
  if (daysBetween === 1) return 'Jutro';
  if (daysBetween === 2) return 'Pojutrze';
  if (daysBetween > 2 && daysBetween <= 6) return `Za ${Math.round(daysBetween)} dni`;
  if (daysBetween === 7) return `Za tydzień`;

  return intl.formatDate(date, { day: 'numeric', month: 'numeric', year: 'numeric' });
}