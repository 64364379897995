import React, { useEffect, useState } from 'react';
import { queryOwnListing } from './ManageListingsPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { formatMoney } from '../../util/currency';
import { useHistory } from 'react-router-dom';

import css from './ManageCustomerPage.css';

import { ResponsiveImage, IconDownload, Avatar, NamedLink } from '../../components';
import {
  getCustomersData,
  getEventDetailsData,
  getEventDetailsDataPdf,
} from '../../../src/util/api';
import { txState } from '../InboxPage/InboxPage';
import { formatDate } from '../InboxPage/utils';
import BackIcon from './BackIcon';
import MesssageIcon from '../../components/messageIcon/MessageIcon';
import SendMessageModal from './SendMessageModal';
import { useQuery } from 'react-query';
import { apiSdk, sdkGo } from '../../queries/api';

const ManageCustomersPageComponent = ({ listingId, intl }) => {
  let history = useHistory();

  const termin = history.location.search.includes('termin=')
    ? history.location.search.split('termin=')[history.location.search.split('termin=').length - 1] //.split('T')[0]
    : null;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [noCustomersData, setNoCustomersData] = useState(false);

  const [customersData, setCustomersData] = useState([]);
  const [filteredCustomersData, setFilteredCustomersData] = useState(customersData);

  const [firstName, setFirsName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const { data: selectedListing, isSuccess: isSuccessListing } = useQuery('listing.show', () =>
    apiSdk.listings.show(listingId, {
      include: ['images', 'dates'],
    })
  );
  const listingDateId =
    isSuccessListing && selectedListing.data.relationships.dates.data[0].id.uuid;
  const listingDateSinleEvent =
    isSuccessListing &&
    selectedListing.included.filter(
      item => item.type === 'listing-date' && item.id.uuid === listingDateId
    )[0];

  const listingDateEventWithManyDates =
    termin &&
    isSuccessListing &&
    selectedListing.included.filter(item => {
      return (
        item.type === 'listing-date' && item.id.uuid === termin
        // new Date(item.attributes.date).toISOString().split('T')[0] ===
        //   new Date(termin).toISOString().split('T')[0]
      );
    })[0];

  const listingDate = isSuccessListing
    ? termin
      ? listingDateEventWithManyDates.attributes.date
      : listingDateSinleEvent.attributes.date
    : null;

  const listingDateIdEventWithManyDates =
    listingDateEventWithManyDates && listingDateEventWithManyDates.id.uuid;

  const transactionEnabled = !!listingDateIdEventWithManyDates || !!listingDateId ? true : false;

  const dateId = !!termin ? listingDateIdEventWithManyDates : listingDateId;

  const { data: transactions, isSuccess } = useQuery(
    ['transactions.query', dateId],
    () =>
      apiSdk.transactions.query({
        // providerId: 'me',
        listingDateId: dateId,
        include: ['customer'],
      }),
    {
      enabled: transactionEnabled,
    }
  );
  useEffect(() => {
    if (isSuccess) {
      setCustomersData(
        transactions.data
          .map(transaction => {
            return transaction.attributes.participants.map(participant => {
              const additionalFormData = transaction.attributes.metadata.additionalFormData
                ? transaction.attributes.metadata.additionalFormData.map(transformObjectKeys)
                : [];

              const oldAdditionalFormData = !transaction.attributes.metadata.additionalFormData
                ? transaction.attributes.metadata
                : null;
              if (oldAdditionalFormData && oldAdditionalFormData.ticketsNumbers) {
                delete oldAdditionalFormData.ticketsNumbers;
              }
              if (oldAdditionalFormData) {
                const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
                for (let key in oldAdditionalFormData) {
                  if (uuidRegex.test(key)) {
                    delete oldAdditionalFormData[key];
                  }
                  if (Array.isArray(oldAdditionalFormData[key])) {
                    oldAdditionalFormData[key] = oldAdditionalFormData[key].map(item => ({
                      [item]: true,
                    }))[0];
                  }
                }
              }

              return {
                user: transactions.included.filter(
                  item =>
                    item.type === 'user' &&
                    item.id.uuid === transaction.relationships.customer.data.id.uuid
                )[0],
                firstName: participant.name.split(' ')[0],
                lastName: participant.name.split(' ')[1],
                email: transaction.attributes.customerData
                  ? transaction.attributes.customerData.email
                  : 'brak adresu email',
                age: participant.age,
                tx: transaction,
                id: participant.name,
                userState: transaction.attributes.state,
                date: `${new Date(
                  transaction.attributes.lastTransitionAt
                ).toLocaleDateString()}, ${new Date(
                  transaction.attributes.lastTransitionAt
                ).toLocaleTimeString()}`,
                cratedDate: new Date(transaction.attributes.createdAt).toLocaleDateString(),
                additionalForm: additionalFormData.filter(
                  item =>
                    item[
                      participant.name
                        .split(' ')
                        .join('')
                        .split('-')
                        .join('')
                    ]
                ).length
                  ? additionalFormData.filter(
                      item =>
                        item[
                          participant.name
                            .split(' ')
                            .join('')
                            .split('-')
                            .join('')
                        ]
                    )[0][
                      participant.name
                        .split(' ')
                        .join('')
                        .split('-')
                        .join('')
                    ]
                  : oldAdditionalFormData
                  ? oldAdditionalFormData
                  : [],
              };
            });
          })
          .flat()
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    const filteredData = customersData.filter(user => {
      return (
        user.firstName.toLowerCase().includes(firstName.toLowerCase()) &&
        user.lastName.toLowerCase().includes(lastName.toLowerCase())
      );

      // return user.firstName.toLowerCase().includes(firstName.toLowerCase()) && user.lastName.toLowerCase().includes(lastName.toLowerCase()) && user.email.toLowerCase().includes(email.toLowerCase())
    });

    setFilteredCustomersData(filteredData.sort((a, b) => a.userState.localeCompare(b.userState)));
  }, [customersData, firstName, lastName]);

  const image =
    selectedListing && selectedListing.included && selectedListing.included.length > 0
      ? selectedListing.included[0]
      : null;

  const title = selectedListing && selectedListing.data.attributes.title;
  const date = new Date(listingDate).toLocaleDateString(); //selectedListing && new Date(selectedListing.data.attributes.publicData.date).toLocaleDateString()
  const price =
    selectedListing && selectedListing.data.attributes.price
      ? formatMoney(intl, selectedListing.data.attributes.price)
      : 0;

  return (
    <div>
      <SendMessageModal
        listingDateId={dateId}
        customers={
          !!customersData.length && customersData.filter(item => item.userState === 'accepted')
        }
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />

      <span>
        <BackIcon />
        <button className={css.back} onClick={history.goBack}>
          Powrót
        </button>
      </span>

      <div className={css.headercontainer}>
        <header className={css.header}>
          <div className={css.imagecontainer}>
            <ResponsiveImage
              alt={'title'}
              image={image}
              variants={['landscape-crop', 'landscape-crop2x']}
              sizes={null}
            />
          </div>
          <div className={css.info}>
            <h2 className={css.title}>{title}</h2>

            <p>
              {' '}
              {termin ? new Date(listingDate).toLocaleString(0) : date} <span>{price}</span>
            </p>
          </div>
        </header>
        <div>
          <p
            className={css.sendMessages}
            onClick={() =>
              !!customersData.filter(({ userState }) => userState !== 'cancelled').length
                ? setIsModalOpen(true)
                : setNoCustomersData(true)
            }
          >
            {' '}
            <MesssageIcon /> Wyślij wiadomość do uczestników
          </p>
          {noCustomersData && (
            <p style={{ color: 'red', fontSize: '12px', margin: '2px' }}>
              Brak zapisanych użytkowników
            </p>
          )}

          <p>Pobierz listę uczestników</p>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <button
              className={css.getdetailsbutton}
              onClick={
                () => selectedListing && createXLSMembersListForListingDate(customersData)
                // getEventDetailsData(selectedListing.data.id.uuid, (new Date(decodeURIComponent(termin))).getTime()
              }
            >
              <IconDownload />
              <span style={{ marginLeft: '10px' }}>CSV</span>
            </button>
            <button
              className={css.getdetailsbutton}
              onClick={
                () => selectedListing && createPDFMembersListPDFForListingDate(customersData)
                // getEventDetailsDataPdf(selectedListing.data.id.uuid, (new Date(decodeURIComponent(termin))).getTime()
              }
            >
              <IconDownload />
              <span style={{ marginLeft: '10px' }}>PDF</span>
            </button>
          </div>
        </div>
      </div>
      <div className={css.form}>
        <p>Wyszukaj uczestnika</p>
        <div className={css.labelscontainer}>
          <label className={css.label}>
            Imię
            <input
              style={{ border: 'solid 1px #919191', padding: '5px 10px' }}
              value={firstName}
              onChange={e => setFirsName(e.currentTarget.value)}
              type="text"
            />
          </label>
          <label className={css.label}>
            Nazwisko
            <input
              style={{ border: 'solid 1px #919191', padding: '5px 10px' }}
              value={lastName}
              onChange={e => setLastName(e.currentTarget.value)}
              type="text"
            />
          </label>
          <label className={css.label}>
            Adres e-mail
            <input
              style={{ border: 'solid 1px #919191', padding: '5px 10px' }}
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
              type="text"
            />
          </label>
        </div>
      </div>
      <div>
        <p>{`Zapisanych użytkowników: ${
          filteredCustomersData.filter(item => item.userState === 'accepted').length
        }`}</p>
        <ul>
          {filteredCustomersData.map(user => {
            const dataState = txState(intl, user.tx, 'sales');
            const lastTransitionedAt = user.date; //formatDate(intl, user.date);

            return (
              <>
                <li key={user.id} className={css.usercontainer}>
                  <Avatar user={user.user} />

                  <div style={{ marginLeft: '20px' }}>
                    <NamedLink
                      // className={css.itemLink}
                      name={'SaleDetailsPage'}
                      params={{ id: user.tx.id.uuid }}
                    >
                      <p className={css.name}>{`${user.firstName} ${user.lastName}`}</p>
                    </NamedLink>
                    <p className={css.date}>{user.cratedDate}</p>
                    <p className={css.email}>{user.email}</p>
                  </div>
                  <div className={css.itemState}>
                    <div
                      className={classNames(
                        css.stateName,
                        dataState ? dataState.stateClassName : ''
                      )}
                    >
                      {dataState ? dataState.state : ''}
                    </div>
                    <div
                      className={classNames(
                        css.lastTransitionedAt,
                        dataState ? dataState.lastTransitionedAtClassName : ''
                      )}
                      // title={lastTransitionedAt}
                    >
                      {lastTransitionedAt}
                    </div>
                  </div>
                </li>
                {!!Object.keys(user.additionalForm).length && (
                  <>
                    <p>Dodatkowy formularz: </p>
                    <p>
                      {Object.keys(user.additionalForm).map(key => (
                        <div key={key}>
                          <span>{key}: </span>
                          <span>
                            {typeof user.additionalForm[key] === 'object'
                              ? Object.keys(user.additionalForm[key])
                                  .filter(key2 => user.additionalForm[key][key2] === true)
                                  .join(', ')
                              : user.additionalForm[key]}
                          </span>
                        </div>
                      ))}
                    </p>
                  </>
                )}
                <div className={css.line}></div>
              </>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { selectedListing, queryInProgress } = state.ManageListingsPage;

  return {
    selectedListing,
    queryInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  // onGetOwnListing: (queryParams) => dispatch(queryOwnListing(queryParams)),
});

const ManageCustomersPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ManageCustomersPageComponent);

export default ManageCustomersPage;

function transformObjectKeys(obj) {
  let newObj = {};
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      // Usunięcie separatorów z klucza
      let newKey = key.replace(/[- ]/g, '');
      // Dodanie nowej pary klucz-wartość do nowego obiektu
      newObj[newKey] = obj[key];
    }
  }
  return newObj;
}

const createPDFMembersListPDFForListingDate = async customersData => {
  const content = [];

  const tableBody = [];

  tableBody.push([
    { text: 'Imię', bold: true },
    { text: 'Nazwisko', bold: true },
    { text: 'Email', bold: true },
    { text: 'Wiek', bold: true },
    { text: 'Dodatkowy formularz', bold: true },
  ]);

  customersData
    .filter(item => item.userState === 'accepted')
    .forEach(user => {
      const additionalForms = Object.keys(user.additionalForm)
        .map(key => {
          return `${key} : ${
            typeof user.additionalForm[key] === 'object'
              ? Object.keys(user.additionalForm[key])
                  .filter(key2 => user.additionalForm[key][key2] === true)
                  .join(', ')
              : user.additionalForm[key]
          }`;
        })
        .join('\n');

      tableBody.push([user.firstName, user.lastName, user.email, user.age, additionalForms]);
    });

  content.push({
    table: {
      headerRows: 1,
      widths: ['auto', 'auto', 'auto', 'auto', '*'],
      body: tableBody,
    },
  });

  await sdkGo.pdf.createFile({
    pageOrientation: 'portrait',
    pageSize: 'A4',
    defaultStyle: {
      fontSize: 8,
    },
    content,
  });
};

const createXLSMembersListForListingDate = async customersData => {
  const data = [];

  data.push(['Imię', 'Nazwisko', 'Email', 'Wiek', 'Dodatkowy formularz']);

  customersData
    .filter(item => item.userState === 'accepted')
    .forEach(user => {
      const additionalForms = Object.keys(user.additionalForm)
        .map(key => {
          return `${key} : ${
            typeof user.additionalForm[key] === 'object'
              ? Object.keys(user.additionalForm[key])
                  .filter(key2 => user.additionalForm[key][key2] === true)
                  .join(', ')
              : user.additionalForm[key]
          }`;
        })
        .join('\n');

      data.push([user.firstName, user.lastName, user.email, user.age, additionalForms]);
    });

  await sdkGo.xlsx.createFile(data, 'uczestnicy.xlsx');
};
