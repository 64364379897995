import React from 'react';
import classNames from 'classnames';

import P24Logo from './logos/p24.svg';
import MCLogo from './logos/mc.svg';
import VisaLogo from './logos/visa.svg';
import BlikLogo from './logos/blik.svg';

import css from './StripePaymentForm.css';

const PaymentMethodSelector = ({ onChangePaymentMethod, selectedPaymentMethod, paymentMethodTypes }) => {
  return (
    <div className={css.methodSelector}>
      <div
        onClick={() => onChangePaymentMethod('p24')}
        className={classNames(css.paymentMethodButton, { [css.paymentMethodSelected]: selectedPaymentMethod === 'p24', [css.disabled]: !paymentMethodTypes.includes('p24') })}
      >
        <span>Przelew / BLIK</span>
        <div className={css.images}>
          <img src={P24Logo} className={css.paymentImg} />
          <img src={BlikLogo} className={css.paymentImg} />
        </div>
      </div>
      <div
        onClick={() => onChangePaymentMethod('card')}
        className={classNames(css.paymentMethodButton, { [css.paymentMethodSelected]: selectedPaymentMethod === 'card', [css.disabled]: !paymentMethodTypes.includes('card') })}
      >
        <span>Karta płatnicza</span>
        <div className={css.images}>
          <img src={MCLogo} className={css.paymentImg} />
          <img src={VisaLogo} className={css.paymentImg} />
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodSelector;