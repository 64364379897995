import React from 'react';
import { withRouter } from 'react-router-dom';
import { NamedLink, SearchInput } from '../../../../components';
import ReferencingPostsSection from '../../components/ReferencingPostsSection'
import css from '../AcademyPage.css';

const format = {
  month: 'long',
  day: 'numeric',
  year: 'numeric'
};

function MainPost({ post, intl, onSearch, displayMainPost }) {
  const referencingPosts = [
    { title: 'Bieganie zimą - jak się przygotować?' , slug: 'bieganie-zima', mainImage: 'https://kgcodego.b-cdn.net/blog/bieganie-zima.jpg', minutesToRead: 7, publishingDate: intl.formatDate(new Date(2020, 9, 28), format)},
    { title: 'Gdzie wybrać się w Tatry z dzieckiem zimą?' , slug: 'tatry-zima-z-dzieckiem', mainImage: 'https://kgcodego.b-cdn.net/blog/tatry-dziecko.webp', minutesToRead: 6, publishingDate: intl.formatDate(new Date(2020, 10, 2), format)},
    { title: 'Jak zacząć jazdę na nartach?' , slug: 'nauka-jazdy-na-nartach', mainImage: 'https://kgcodego.b-cdn.net/blog/nauka-narty.jpg', minutesToRead: 6, publishingDate: intl.formatDate(new Date(2020, 9, 16), format)}
  ];

  return (
    <div className={css.articlesTwoWrapper}>
      {
        post && displayMainPost &&
          <NamedLink
            className={css.articleLink}
            name="PostPage"
            params={{ id: post.fields.slug }}
            >
            <div className={css.articleWrapper}>
              <div className={css.articleImageContainer} style={{ backgroundImage: `url(${post.fields.mainImage})`}}></div>
              <div className={css.articleContainer}>
                <h3 className={css.articleTitle}>{post.fields.title}</h3>
                <p className={css.articleText}>
                  {post.fields.metaDescription}
                </p>
                <div className={css.firstArticleFooter}>
                  <span className={css.articleTag}>{post.fields.label}</span>
                  <p className={css.articleDate}>{intl.formatDate(post.fields.publishingDate, format)}, {post.fields.minutesToRead} min czytania</p>
                </div>
              </div>
            </div>
          </NamedLink>
      }
      <div className={css.actionsWrapper}>
        {/* <SearchInput placeholder="Szukaj na blogu" onSubmit={onSearch} /> */}
        {/* <ReferencingPostsSection title="Popularne artykuły" posts={referencingPosts} /> */}
      </div>
    </div>
  )
}

export default MainPost;
            