import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { apiSdk } from '../../../queries/api';
import { sportsConfig } from '../../../sportsConfig';
import { findKeyByName } from '../utils';
import { useUrlParams } from './useUrlParams';

export const useGetData = () => {
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [allEventsData, setAllEventsData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [rawListings, setRawListings] = useState(null);

  const params = useUrlParams();

  const selectedSportsParam =
    params.sport === 'wszystkie-sporty'
      ? null
      : params.sport
          .split('_')
          .map(sport => {
            const searchValue = sport.replaceAll('-', ' ');
            return findKeyByName(sportsConfig, searchValue);
          })
          .join(',');

  const priceParam =
    !!params.min_price && !!params.max_price
      ? `${!!Number(params.min_price) ? params.min_price * 100 : ''},${
          !!Number(params.max_price) ? params.max_price * 100 : 0
        }`
      : null;

  const pubGender = decodeURIComponent(params.pub_gender);
  const eventType = decodeURIComponent(params.pub_event_type);

  const searchParams = {
    ...(!!params.bounds && { bounds: params.bounds }),
    ...{ start: params.start ? params.start : new Date().toISOString() },
    // ...(!!params.start && { start: params.start }),
    ...(!!params.end && { end: params.end }),
    ...(!!priceParam && { price: priceParam }),
    ...(!!params.pub_gender && { pub_gender: `has_any:${pubGender}` }),
    ...(!!params.pub_event_type && { pub_event_type: `in:${eventType}` }),
    ...(!!selectedSportsParam && { pub_sport: selectedSportsParam }),
  };

  const fetchListings = async page => {
    return await apiSdk.listings.query({
      state: 'published',
      sort: 'firstAvailableDate',
      page,
      per_page: 12,
      include: ['images', 'dates'],
      ...searchParams,
    });
  };

  useEffect(() => {
    setAllEventsData([]);
    setPage(1);
  }, [
    params.bounds,
    priceParam,
    params.pub_gender,
    params.pub_event_type,
    selectedSportsParam,
    params.start,
    params.end,
  ]);

  const { data, isSuccess, isFetching, isError } = useQuery(
    [
      'listings.query',
      page,
      params.bounds,
      priceParam,
      params.pub_gender,
      params.pub_event_type,
      params.start,
      params.end,
      selectedSportsParam,
      //   selectedButtonFilterType,
    ],
    () => fetchListings(page)
  );

  useEffect(() => {
    if (isSuccess) {
      setRawListings(data);
      const totalItems = data.meta.totalItems;
      setTotalItems(totalItems);

      const newEventsData = data.data.reduce((acc, item) => {
        const datesPerEventId = item.relationships.dates.data.map(date => date.id.uuid);
        const datesPerEvent = data.included.filter(
          item => item.type === 'listing-date' && datesPerEventId.includes(item.id.uuid)
        );
        const firstPossibleEventDate = datesPerEvent
          .filter(date => {
            const eventDate = new Date(date.attributes.date);
            eventDate.setMinutes(eventDate.getMinutes() + date.attributes.duration);
            return eventDate >= new Date();
          })
          .sort((a, b) => new Date(a.attributes.date) - new Date(b.attributes.date))[0];

        const mainImagePerEventId = item.relationships.images.data[0].id.uuid;
        const mainImage = data.included.filter(
          item => item.type === 'image' && item.id.uuid === mainImagePerEventId
        )[0];

        return firstPossibleEventDate
          ? [
              ...acc,
              {
                ...item.attributes,
                id: item.id.uuid,
                isMultiDates: datesPerEvent.length > 1,
                image: mainImage,
                eventDate: firstPossibleEventDate,
              },
            ]
          : acc;
      }, []);

      setAllEventsData(prev => {
        if (page === 1) {
          return newEventsData;
        }

        const existingIds = new Set(prev.map(event => event.id));
        const filteredNewEvents = newEventsData.filter(event => !existingIds.has(event.id));

        return [...prev, ...filteredNewEvents];
      });

      setHasMore(data.meta.page < data.meta.totalPages);
    }
  }, [isSuccess, data]);

  const loadMore = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return {
    isSuccess: isSuccess && rawListings && allEventsData, // && data.data.length && allEventsData.length,
    eventsData: allEventsData,
    isFetching,
    isError,
    hasMore,
    loadMore,
    totalItems,
    rawListings,
  };
};
