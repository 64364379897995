import React from 'react';
import Skeleton from '../../../../components/Skeleton/Skeleton';

function EventCardSkeleton() {
  return (
    <Skeleton>
      <div style={{ width: '100%', aspectRatio: '1/1' }}></div>
      <div style={{ width: '90%', height: '16px', margin: '10px 0' }}></div>
      <span style={{ display: 'flex', gap: '5px' }}>
        <div style={{ width: '14px', height: '14px', margin: '5px 0' }}></div>
        <div style={{ width: '50%', height: '14px', margin: '5px 0' }}></div>
      </span>
      <span style={{ display: 'flex', gap: '5px' }}>
        <div style={{ width: '14px', height: '14px', margin: '5px 0' }}></div>
        <div style={{ width: '50%', height: '14px', margin: '5px 0' }}></div>
      </span>
      <div style={{ width: '60%', height: '16px', margin: '10px 0' }}></div>
    </Skeleton>
  );
}

export default EventCardSkeleton;
