import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearchSpinner.css';

const IconSearchSpinner = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName || css.rootSearchIcon, className);

  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="#FFF" fillRule="nonzero" d="M11 2a9 9 0 0 1 7.032 14.617l3.675 3.676a1 1 0 1 1-1.414 1.414l-3.676-3.675A9 9 0 1 1 11 2zm0 2a7 7 0 1 0 0 14 7 7 0 0 0 0-14z"/>
    </svg>
  ); 
  
};

const { string } = PropTypes;

IconSearchSpinner.defaultProps = {
  className: null,
  rootClassName: null,
};

IconSearchSpinner.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconSearchSpinner;
