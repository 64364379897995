import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  UserNav,
} from '../../components';
import FooterV2 from '../../components/Footer/FooterV2';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { ProfileSettingsForm } from '../../forms';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import css from './ProfileSettingsPage.css';
import { updateProfile, uploadImage } from './ProfileSettingsPage.duck';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export class ProfileSettingsPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      image,
      onImageUpload,
      onUpdateProfile,
      scrollingDisabled,
      onManageDisableScrolling,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      intl,
    } = this.props;

    const handleSubmit = values => {
      const { age, firstName, lastName, favSports: rawSports, bio: rawBio, sex } = values;

      // Ensure that the optional bio is a string
      const bio = rawBio || '';
      const favSports = rawSports && rawSports.length ? rawSports.split(', ') : [];

      const profile = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        bio,
        publicData: {
          age,
          favSports,
          sex,
        },
      };
      const uploadedImage = this.props.image;

      const { fromDraft, type } = parse(this.props.location.search);

      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      onUpdateProfile(updatedValues).then(() => {
        if (this.props.location.search) {
          const redirectUrl =
            type && type === 'business-cards'
              ? `/organizator${fromDraft}/draft/photos`
              : `/l/event${fromDraft}/draft/photos`;
          this.props.history.push(redirectUrl);
        }
      });
    };

    const user = { ...ensureCurrentUser(currentUser) }; //, profileImage: ensureCurrentUser(currentUser).image };

    const {
      firstName,
      lastName,
      bio,
      publicData: { sex, age, favSports } = {},
    } = user.attributes.profile;

    const profileImageId = user.image ? user.image.id : null; // user.profileImage ? user.profileImage.id : null;
    //const profileImageId = user.image && user.image.id.uuid
    const profileImage = image || { imageId: profileImageId };
    const profileSettingsForm = user.id ? (
      <ProfileSettingsForm
        className={css.form}
        currentUser={{ ...currentUser }}
        initialValues={{
          firstName: firstName === 'Nowy' ? '' : firstName,
          lastName: lastName === 'Użytkownik' ? '' : lastName,
          bio,
          profileImage: user.image,
          sex,
          age,
          favSports: favSports && favSports.length ? favSports.join(', ') : '',
        }}
        profileImage={profileImage}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
        onManageDisableScrolling={onManageDisableScrolling}
      />
    ) : null;

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfileSettingsPage" />
            <UserNav selectedPageName="ProfileSettingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="ProfileSettingsPage.heading" />
                </h1>
                {user.id ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                  >
                    <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                  </NamedLink>
                ) : null}
              </div>
              {profileSettingsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

const { bool, func, object, shape, string } = PropTypes;

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ProfileSettingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ProfileSettingsPageComponent);

export default ProfileSettingsPage;
