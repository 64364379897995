import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
  PaginationLinks,
  SearchInput,
} from '../../../components';
import { TopbarContainer } from '../../../containers';
import { isScrollingDisabled } from '../../../ducks/UI.duck';
import { withViewport } from '../../../util/contextHelpers';
import { parse } from '../../../util/urlHelpers';
import { loadData } from './AcademyPage.duck';
import MainPost from './MainPost/MainPost';

import FooterV2 from '../../../components/Footer/FooterV2';
import css from './AcademyPage.css';

const format = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};
const Example = () => <span>test</span>;

const highlightObjects = {
  // 2: {
  //   type: 'highlight',
  //   component: Example
  // }
};

const highlightsCount = Object.keys(highlightObjects).length;
const perPage = 10 - highlightsCount;

function AcademyPageComponent({
  intl,
  scrollingDisabled,
  posts,
  isLoaded,
  location,
  history,
  viewport,
}) {
  const page = parse(location.search).page || 1;
  const query = parse(location.search).query;
  const allPosts = { items: [], ...posts };
  const firstArticle = allPosts.items[0];

  const renderedPosts = () => {
    const all = { ...allPosts };
    page === 1 && !query && viewport.width > 768 && all.items.shift();
    Object.keys(highlightObjects).forEach(idx =>
      all.items.splice(parseInt(idx), 0, highlightObjects[idx])
    );
    return all.items;
  };

  const totalPages = Math.ceil((posts.total - perPage) / (perPage - 1) + 1);

  const onSearch = searchValue => history.push(`/blog?query=${searchValue}`);

  return (
    <Page
      title="Blog sportowy Decathlon GO - porady, wskazówki, informacje ze świata sportu"
      description="Nasz sportowy blog dostarcza Ci najważniejszą dawkę informacji, porad, wskazówek dotyczących Twojego ulubionego sportu."
      schema={{
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        description:
          'Nasz sportowy blog dostarcza Ci najważniejszą dawkę informacji, porad, wskazówek dotyczących Twojego ulubionego sportu.',
        name: 'Blog sportowy Decathlon GO - porady, wskazówki, informacje ze świata sportu',
      }}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn className={css.root}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          {/* {(!query && page === 1) && (<div className={css.titleContainer}>
            <div className={css.mask} />
            <h1 className={css.pageTitle}>Blog Decathlon GO</h1>
            <p className={css.pageDesc}></p>
          </div>)} */}
          {isLoaded && (
            <>
              <div className={css.staticPageWrapper}>
                {
                  <>
                    {query && (
                      <NamedLink className={css.goBack} name="Academy">
                        Powrót na stronę główną
                      </NamedLink>
                    )}
                    <div className={css.queryHeader}>
                      <div className={css.querySection}>
                        <span className={css.line} />
                        <div className={css.column}>
                          <div className={css.queryLabel}>
                            {query ? 'Wyniki wyszukiwania' : 'Trenuj, baw się, rywalizuj!'}
                          </div>
                          <div className={css.query}>{query || 'Blog Decathlon GO'}</div>
                        </div>
                      </div>
                      <SearchInput placeholder="Szukaj na blogu" onSubmit={onSearch} />
                    </div>
                  </>
                }

                {page === 1 && !query && (
                  <MainPost
                    post={firstArticle}
                    intl={intl}
                    onSearch={onSearch}
                    displayMainPost={viewport.width > 768}
                  />
                )}
                <div className={css.articlesFourWrapper}>
                  {renderedPosts().map((post, index) => {
                    if (post.type === 'highlight') {
                      const Component = post.component;
                      return <Component key={index} />;
                    }
                    return (
                      <NamedLink
                        key={index}
                        name="PostPage"
                        params={{ id: post.fields.slug }}
                        className={css.articleLink}
                      >
                        <div className={css.articleWrapper}>
                          <div
                            className={css.articleImageContainer}
                            style={{ backgroundImage: `url(${post.fields.mainImage})` }}
                          />
                          <div className={css.articleContainer}>
                            <div>
                              <h4 className={css.articleTitle}>{post.fields.title}</h4>
                              <p className={css.articleText}>{post.fields.metaDescription}</p>
                            </div>
                            <div>
                              <span className={css.articleTag}>{post.fields.label}</span>
                              <p className={css.articleDate}>
                                {intl.formatDate(post.fields.publishingDate, format)},{' '}
                                {post.fields.minutesToRead} min czytania
                              </p>
                            </div>
                          </div>
                        </div>
                      </NamedLink>
                    );
                  })}
                </div>
                <div className={css.paginationWrapper}>
                  <PaginationLinks
                    className={css.pagination}
                    pageName="Academy"
                    pageSearchParams={{ page, query }}
                    pagination={{ page, totalPages }}
                  />
                </div>
              </div>
            </>
          )}
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <FooterV2 />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
}

const mapStateToProps = state => {
  const { posts, queryPostsError, isLoaded } = state.AcademyPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    posts,
    queryPostsError,
    isLoaded,
  };
};

const AcademyPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(AcademyPageComponent);

AcademyPage.loadData = (params, search) => {
  const page = (search && parse(search).page) || 1;
  const skip = page > 2 ? perPage + (page - 2) * (perPage - 1) : (page - 1) * perPage;
  const query = search && parse(search).query;
  const postsToRender = page === 1 && !query ? perPage : perPage - 1;
  return loadData(skip, postsToRender, query);
};

export default AcademyPage;
