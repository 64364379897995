import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';
import { ReactComponent as LeftArrowIcon } from '../../../../../assets/addEventForm/leftArrowIcon.svg';
import { getTransactionData } from './utils';

const Payment = ({ data, handleBack, handleClose, customStyle }) => {
  const { stripePaymentIntent, priceData, customerData } = getTransactionData(data);

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

  const seats =
    data.data && data.data.attributes.metadata.seats && data.data.attributes.metadata.seats.length
      ? data.data.attributes.metadata.seats
      : [];

  const options = {
    clientSecret: stripePaymentIntent.clientSecret,
  };

  return (
    <div style={customStyle ? customStyle : {}}>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            onClick={() => {
              handleBack();
            }}
            style={{
              display: 'flex',
              alignItems: 'center',
              border: 'none',
              background: 'none',
              fontSize: '20px',
              color: '#000',
              margin: '5px 0 auto',
              padding: '0 5px 0 0',
            }}
          >
            <LeftArrowIcon />
          </button>
          Płatność
        </div>
        <span
          onClick={() => {
            handleClose();
          }}
        >
          x
        </span>
      </h2>

      <Elements stripe={stripePromise} options={options}>
        <PaymentForm priceData={priceData} customerData={customerData} seats={seats} />
      </Elements>
    </div>
  );
};

export default Payment;
