import React from 'react';

// import css from './LowInformationPage.css';
import { LayoutWrapperTopbar } from '../../components';
import FooterV2 from '../../components/Footer/FooterV2';
import { TopbarContainer } from '../../containers';

const ScannerPrivacy = props => {
  return (
    <div style={{ position: 'relative' }}>
      <LayoutWrapperTopbar>
        <TopbarContainer currentPage="ScannerPrivacyPage" />
      </LayoutWrapperTopbar>
      <div data-custom-class="body" style={{ padding: '100px 5%' }}>
        <div>
          <strong>
            <span style={{ fontSize: 26 }}>
              <span data-custom-class="title">PRIVACY POLICY</span>
            </span>
          </strong>
        </div>
        <div>
          <br />
        </div>
        <div>
          <span style={{ color: 'rgb(127, 127, 127)' }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="subtitle">Last updated February 27, 2024</span>
              </span>
            </strong>
          </span>
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div>
          <br />
        </div>
        <div style={{ lineHeight: '1.5' }}>
          <span style={{ color: 'rgb(127, 127, 127)' }}>
            <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
              <span data-custom-class="body_text">
                This privacy notice for Decathlon Go ("<strong>we</strong>," "<strong>us</strong>,"
                or "<strong>our</strong>"
              </span>
              <span data-custom-class="body_text">
                ), describes how and why we might collect, store, use, and/or share ("
                <strong>process</strong>") your information when you use our services ("
                <strong>Services</strong>"), such as when you:
              </span>
            </span>
          </span>
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    Download and use our mobile application (Decathlon Go Scanner){' '}
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                              <span data-custom-class="body_text">
                                <span style={{ fontSize: 15 }}>
                                  <span style={{ color: 'rgb(89, 89, 89)' }}>,</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>{' '}
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15 }}>
                      <span style={{ color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15 }}>
                            <span style={{ color: 'rgb(89, 89, 89)' }}>
                              or any other application of ours that links to this privacy notice
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: '1.5' }} />
          <ul>
            <li style={{ lineHeight: '1.5' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                  <span data-custom-class="body_text">
                    Engage with us in other related ways, including any sales, marketing, or events
                  </span>
                </span>
              </span>
            </li>
          </ul>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span data-custom-class="body_text">
                  <strong>Questions or concerns?&nbsp;</strong>Reading this privacy notice will help
                  you understand your privacy rights and choices. If you do not agree with our
                  policies and practices, please do not use our Services. If you still have any
                  questions or concerns, please contact us at stanislaw.nagorski@decathlon.com.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <strong>
              <span style={{ fontSize: 15 }}>
                <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
              </span>
            </strong>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>
                    This summary provides key points from our privacy notice, but you can find out
                    more details about any of these topics by clicking the link following each key
                    point or by using our&nbsp;
                  </em>
                </strong>
              </span>
            </span>
            <a data-custom-class="link" href="#toc">
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                <span data-custom-class="body_text">
                  <strong>
                    <em>table of contents</em>
                  </strong>
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  <em>&nbsp;below to find the section you are looking for.</em>
                </strong>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What personal information do we process?</strong> When you visit, use, or
                navigate our Services, we may process personal information depending on how you
                interact with us and the Services, the choices you make, and the products and
                features you use. Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#personalinfo">
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                <span data-custom-class="body_text">personal information you disclose to us</span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Do we process any sensitive personal information?</strong> We do not process
                sensitive personal information.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Do we receive any information from third parties?</strong> We do not receive
                any information from third parties.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we process your information?</strong> We process your information to
                provide, improve, and administer our Services, communicate with you, for security
                and fraud prevention, and to comply with law. We may also process your information
                for other purposes with your consent. We process your information only when we have
                a valid legal reason to do so. Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#infouse">
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                <span data-custom-class="body_text">how we process your information</span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>
                  In what situations and with which parties do we share personal information?
                </strong>{' '}
                We may share information in specific situations and with specific third parties.
                Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#whoshare">
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                <span data-custom-class="body_text">
                  when and with whom we share your personal information
                </span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">.</span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do we keep your information safe?</strong> We have organizational and
                technical processes and procedures in place to protect your personal information.
                However, no electronic transmission over the internet or information storage
                technology can be guaranteed to be 100% secure, so we cannot promise or guarantee
                that hackers, cybercriminals, or other unauthorized third parties will not be able
                to defeat our security and improperly collect, access, steal, or modify your
                information. Learn more about&nbsp;
              </span>
            </span>{' '}
            <a data-custom-class="link" href="#infosafe">
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                <span data-custom-class="body_text">how we keep your information safe</span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>What are your rights?</strong> Depending on where you are located
                geographically, the applicable privacy law may mean you have certain rights
                regarding your personal information. Learn more about&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#privacyrights">
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                <span data-custom-class="body_text">your privacy rights</span>
              </span>
            </a>
            <span data-custom-class="body_text">.</span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>How do you exercise your rights?</strong> The easiest way to exercise your
                rights is by submitting a&nbsp;
              </span>
            </span>
            <a
              data-custom-class="link"
              href="https://app.termly.io/notify/f9e642a8-116e-40ea-bfe1-fb2d25f7c777"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                <span data-custom-class="body_text">data subject access request</span>
              </span>
            </a>{' '}
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                , or by contacting us. We will consider and act upon any request in accordance with
                applicable data protection laws.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                Want to learn more about what we do with any information we collect?&nbsp;
              </span>
            </span>
            <a data-custom-class="link" href="#toc">
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                <span data-custom-class="body_text">Review the privacy notice in full</span>
              </span>
            </a>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">.</span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div id="toc" style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(0, 0, 0)' }}>
                  <strong>
                    <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infocollect">
                <span style={{ color: 'rgb(0, 58, 250)' }}>1. WHAT INFORMATION DO WE COLLECT?</span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infouse">
                <span style={{ color: 'rgb(0, 58, 250)' }}>
                  2. HOW DO WE PROCESS YOUR INFORMATION?
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#legalbases">
                <span style={{ color: 'rgb(0, 58, 250)' }}>
                  3.{' '}
                  <span style={{ fontSize: 15 }}>
                    <span style={{ color: 'rgb(0, 58, 250)' }}>
                      WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
                    </span>
                  </span>
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(0, 58, 250)' }}>
                <a data-custom-class="link" href="#whoshare" style={{ color: 'rgb(0, 58, 250)' }}>
                  4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                </a>
              </span>{' '}
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#inforetain" style={{ color: 'rgb(0, 58, 250)' }}>
                <span style={{ color: 'rgb(0, 58, 250)' }}>
                  5. HOW LONG DO WE KEEP YOUR INFORMATION?
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infosafe">
                <span style={{ color: 'rgb(0, 58, 250)' }}>
                  6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                </span>
              </a>{' '}
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#infominors">
                <span style={{ color: 'rgb(0, 58, 250)' }}>
                  7. DO WE COLLECT INFORMATION FROM MINORS?
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span style={{ color: 'rgb(0, 58, 250)' }}>
                <a
                  data-custom-class="link"
                  href="#privacyrights"
                  style={{ color: 'rgb(0, 58, 250)' }}
                >
                  8. WHAT ARE YOUR PRIVACY RIGHTS?
                </a>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#DNT">
                <span style={{ color: 'rgb(0, 58, 250)' }}>
                  9. CONTROLS FOR DO-NOT-TRACK FEATURES
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }} />
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#policyupdates">
                <span style={{ color: 'rgb(0, 58, 250)' }}>
                  10. DO WE MAKE UPDATES TO THIS NOTICE?
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <a data-custom-class="link" href="#contact">
              <span style={{ color: 'rgb(0, 58, 250)', fontSize: 15 }}>
                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
              </span>
            </a>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <a data-custom-class="link" href="#request">
                <span style={{ color: 'rgb(0, 58, 250)' }}>
                  12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                </span>
              </a>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div id="infocollect" style={{ lineHeight: '1.5' }}>
            <span style={{ color: 'rgb(0, 0, 0)' }}>
              <span style={{ color: 'rgb(0, 0, 0)', fontSize: 15 }}>
                <span style={{ fontSize: 15, color: 'rgb(0, 0, 0)' }}>
                  <span style={{ fontSize: 15, color: 'rgb(0, 0, 0)' }}>
                    <span id="control" style={{ color: 'rgb(0, 0, 0)' }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          1. WHAT INFORMATION DO WE COLLECT?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div id="personalinfo" style={{ lineHeight: '1.5' }}>
            <span data-custom-class="heading_2" style={{ color: 'rgb(0, 0, 0)' }}>
              <span style={{ fontSize: 15 }}>
                <strong>Personal information you disclose to us</strong>
              </span>
            </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: '1.5' }}>
              <span style={{ color: 'rgb(127, 127, 127)' }}>
                <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>In Short:</em>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                        <span data-custom-class="body_text">
                          <strong>
                            <em>&nbsp;</em>
                          </strong>
                          <em>We collect personal information that you provide to us.</em>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
              <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                <span data-custom-class="body_text">
                  We collect personal information that you voluntarily provide to us when you
                </span>{' '}
                <span data-custom-class="body_text">
                  express an interest in obtaining information about us or our products and
                  Services, when you participate in activities on the Services, or otherwise when
                  you contact us.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }} />
          <div id="sensitiveinfo" style={{ lineHeight: '1.5' }}>
            <span style={{ fontSize: 15 }}>
              <span data-custom-class="body_text">
                <strong>Sensitive Information.</strong> We do not process sensitive information.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: '1.5' }}>
            <br />
          </div>
          <div style={{ lineHeight: '1.5' }} />
          <div style={{ lineHeight: '1.5' }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15 }}>
                <strong>Application Data.</strong> If you use our application(s), we also may
                collect the following information if you choose to provide us with access or
                permission:
              </span>
            </span>
            <div style={{ lineHeight: '1.5' }} />
            <ul>
              <li style={{ lineHeight: '1.5' }}>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    <em>Mobile Device Access.</em> We may request access or permission to certain
                    features from your mobile device, including your mobile device's camera, and
                    other features. If you wish to change our access or permissions, you may do so
                    in your device's settings.
                  </span>
                </span>
              </li>
            </ul>
            <div style={{ lineHeight: '1.5' }}>
              <div style={{ lineHeight: '1.5' }}>
                <div style={{ lineHeight: '1.5' }}>
                  <span style={{ fontSize: 15 }}>
                    <span data-custom-class="body_text">
                      This information is primarily needed to maintain the security and operation of
                      our application(s), for troubleshooting, and for our internal analytics and
                      reporting purposes.
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: '1.5' }}>
                  <br />
                </div>
                <div style={{ lineHeight: '1.5' }} />
                <div style={{ lineHeight: '1.5' }}>
                  <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        All personal information that you provide to us must be true, complete, and
                        accurate, and you must notify us of any changes to such personal
                        information.
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: '1.5' }}>
                  <br />
                </div>
                <div style={{ lineHeight: '1.5' }}>
                  <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}> </span>
                  </span>
                </div>
                <div id="infouse" style={{ lineHeight: '1.5' }}>
                  <span style={{ color: 'rgb(127, 127, 127)' }}>
                    <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                      <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                        <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                          <span id="control" style={{ color: 'rgb(0, 0, 0)' }}>
                            <strong>
                              <span data-custom-class="heading_1">
                                2. HOW DO WE PROCESS YOUR INFORMATION?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div>
                  <div style={{ lineHeight: '1.5' }}>
                    <br />
                  </div>
                  <div style={{ lineHeight: '1.5' }}>
                    <span style={{ color: 'rgb(127, 127, 127)' }}>
                      <span style={{ color: 'rgb(89, 89, 89)', fontSize: 15 }}>
                        <span data-custom-class="body_text">
                          <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                            <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                              <span data-custom-class="body_text">
                                <strong>
                                  <em>In Short:&nbsp;</em>
                                </strong>
                                <em>
                                  We process your information to provide, improve, and administer
                                  our Services, communicate with you, for security and fraud
                                  prevention, and to comply with law. We may also process your
                                  information for other purposes with your consent.
                                </em>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div style={{ lineHeight: '1.5' }}>
                  <br />
                </div>
                <div style={{ lineHeight: '1.5' }}>
                  <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                    <span style={{ fontSize: 15, color: 'rgb(89, 89, 89)' }}>
                      <span data-custom-class="body_text">
                        <strong>
                          We process your personal information for a variety of reasons, depending
                          on how you interact with our Services, including:
                        </strong>
                      </span>
                    </span>
                  </span>
                  <div style={{ lineHeight: '1.5' }}>
                    <div style={{ lineHeight: '1.5' }}>
                      <div style={{ lineHeight: '1.5' }}>
                        <div style={{ lineHeight: '1.5' }}>
                          <div style={{ lineHeight: '1.5' }}>
                            <div style={{ lineHeight: '1.5' }}>
                              <div style={{ lineHeight: '1.5' }}>
                                <div style={{ lineHeight: '1.5' }}>
                                  <p style={{ fontSize: 15, lineHeight: '1.5' }} />
                                  <p style={{ fontSize: 15, lineHeight: '1.5' }} />
                                  <p style={{ fontSize: 15, lineHeight: '1.5' }} />
                                  <p style={{ fontSize: 15, lineHeight: '1.5' }} />
                                  <div style={{ lineHeight: '1.5' }}>
                                    <div style={{ lineHeight: '1.5' }}>
                                      <div style={{ lineHeight: '1.5' }}>
                                        <div style={{ lineHeight: '1.5' }}>
                                          <div style={{ lineHeight: '1.5' }}>
                                            <div style={{ lineHeight: '1.5' }}>
                                              <div style={{ lineHeight: '1.5' }}>
                                                <div style={{ lineHeight: '1.5' }}>
                                                  <div style={{ lineHeight: '1.5' }}>
                                                    <div style={{ lineHeight: '1.5' }}>
                                                      <div style={{ lineHeight: '1.5' }}>
                                                        <div
                                                          style={{
                                                            lineHeight: '1.5',
                                                          }}
                                                        >
                                                          <div
                                                            style={{
                                                              lineHeight: '1.5',
                                                            }}
                                                          >
                                                            <div
                                                              style={{
                                                                lineHeight: '1.5',
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              />
                                                              <ul>
                                                                <li
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        To save or protect an
                                                                        individual's vital interest.
                                                                      </strong>{' '}
                                                                      We may process your
                                                                      information when necessary to
                                                                      save or protect an
                                                                      individual’s vital interest,
                                                                      such as to prevent harm.
                                                                    </span>
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              />
                                                              <div
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                id="legalbases"
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              >
                                                                <strong>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="heading_1">
                                                                      3. WHAT LEGAL BASES DO WE RELY
                                                                      ON TO PROCESS YOUR
                                                                      INFORMATION?
                                                                    </span>
                                                                  </span>
                                                                </strong>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              >
                                                                <em>
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        In Short:&nbsp;
                                                                      </strong>
                                                                      We only process your personal
                                                                      information when we believe it
                                                                      is necessary and we have a
                                                                      valid legal reason (i.e. ,
                                                                      legal basis) to do so under
                                                                      applicable law, like with your
                                                                      consent, to comply with laws,
                                                                      to provide you with services
                                                                      to enter into or fulfill our
                                                                      contractual obligations, to
                                                                      protect your rights, or to
                                                                      fulfill our legitimate
                                                                      business interests.
                                                                    </span>
                                                                  </span>
                                                                </em>{' '}
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  {' '}
                                                                </span>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              >
                                                                <br />
                                                              </div>
                                                              <div
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              >
                                                                <span
                                                                  style={{
                                                                    fontSize: 15,
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    The General Data Protection
                                                                    Regulation (GDPR) and UK GDPR
                                                                    require us to explain the valid
                                                                    legal bases we rely on in order
                                                                    to process your personal
                                                                    information. As such, we may
                                                                    rely on the following legal
                                                                    bases to process your personal
                                                                    information:
                                                                  </span>
                                                                </span>
                                                              </div>
                                                              <ul>
                                                                <li
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <strong>
                                                                        Consent.&nbsp;
                                                                      </strong>
                                                                      We may process your
                                                                      information if you have given
                                                                      us permission (i.e. , consent)
                                                                      to use your personal
                                                                      information for a specific
                                                                      purpose. You can withdraw your
                                                                      consent at any time. Learn
                                                                      more about&nbsp;
                                                                    </span>
                                                                  </span>{' '}
                                                                  <a
                                                                    data-custom-class="link"
                                                                    href="#withdrawconsent"
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color: 'rgb(0, 58, 250)',
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        withdrawing your consent
                                                                      </span>
                                                                    </span>
                                                                  </a>
                                                                  <span data-custom-class="body_text">
                                                                    .
                                                                  </span>
                                                                </li>
                                                              </ul>
                                                              <div
                                                                style={{
                                                                  lineHeight: '1.5',
                                                                }}
                                                              >
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                />
                                                                <ul>
                                                                  <li
                                                                    style={{
                                                                      lineHeight: '1.5',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <strong>
                                                                          Legal Obligations.
                                                                        </strong>{' '}
                                                                        We may process your
                                                                        information where we believe
                                                                        it is necessary for
                                                                        compliance with our legal
                                                                        obligations, such as to
                                                                        cooperate with a law
                                                                        enforcement body or
                                                                        regulatory agency, exercise
                                                                        or defend our legal rights,
                                                                        or disclose your information
                                                                        as evidence in litigation in
                                                                        which we are involved.
                                                                        <br />
                                                                      </span>
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                />
                                                                <ul>
                                                                  <li
                                                                    style={{
                                                                      lineHeight: '1.5',
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                        }}
                                                                      >
                                                                        <strong>
                                                                          Vital Interests.
                                                                        </strong>{' '}
                                                                        We may process your
                                                                        information where we believe
                                                                        it is necessary to protect
                                                                        your vital interests or the
                                                                        vital interests of a third
                                                                        party, such as situations
                                                                        involving potential threats
                                                                        to the safety of any person.
                                                                      </span>
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                />
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <span data-custom-class="body_text">
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      In legal terms, we are
                                                                      generally the "data
                                                                      controller" under European
                                                                      data protection laws of the
                                                                      personal information described
                                                                      in this privacy notice, since
                                                                      we determine the means and/or
                                                                      purposes of the data
                                                                      processing we perform. This
                                                                      privacy notice does not apply
                                                                      to the personal information we
                                                                      process as a "data processor"
                                                                      on behalf of our customers. In
                                                                      those situations, the customer
                                                                      that we provide services to
                                                                      and with whom we have entered
                                                                      into a data processing
                                                                      agreement is the "data
                                                                      controller" responsible for
                                                                      your personal information, and
                                                                      we merely process your
                                                                      information on their behalf in
                                                                      accordance with your
                                                                      instructions. If you want to
                                                                      know more about our customers'
                                                                      privacy practices, you should
                                                                      read their privacy policies
                                                                      and direct any questions you
                                                                      have to them.
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  id="whoshare"
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      color: 'rgb(127, 127, 127)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        color: 'rgb(89, 89, 89)',
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span
                                                                        style={{
                                                                          fontSize: 15,
                                                                          color: 'rgb(89, 89, 89)',
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            fontSize: 15,
                                                                            color:
                                                                              'rgb(89, 89, 89)',
                                                                          }}
                                                                        >
                                                                          <span
                                                                            id="control"
                                                                            style={{
                                                                              color: 'rgb(0, 0, 0)',
                                                                            }}
                                                                          >
                                                                            <strong>
                                                                              <span data-custom-class="heading_1">
                                                                                4. WHEN AND WITH
                                                                                WHOM DO WE SHARE
                                                                                YOUR PERSONAL
                                                                                INFORMATION?
                                                                              </span>
                                                                            </strong>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                      color: 'rgb(89, 89, 89)',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                        color: 'rgb(89, 89, 89)',
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          <em>In Short:</em>
                                                                        </strong>
                                                                        <em>
                                                                          &nbsp;We may share
                                                                          information in specific
                                                                          situations described in
                                                                          this section and/or with
                                                                          the following third
                                                                          parties.
                                                                        </em>
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                />
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <br />
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <span
                                                                    style={{
                                                                      fontSize: 15,
                                                                    }}
                                                                  >
                                                                    <span data-custom-class="body_text">
                                                                      We may need to share your
                                                                      personal information in the
                                                                      following situations:
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                                <ul>
                                                                  <li
                                                                    style={{
                                                                      lineHeight: '1.5',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <span data-custom-class="body_text">
                                                                        <strong>
                                                                          Business Transfers.
                                                                        </strong>{' '}
                                                                        We may share or transfer
                                                                        your information in
                                                                        connection with, or during
                                                                        negotiations of, any merger,
                                                                        sale of company assets,
                                                                        financing, or acquisition of
                                                                        all or a portion of our
                                                                        business to another company.
                                                                      </span>
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                />
                                                                <ul>
                                                                  <li
                                                                    style={{
                                                                      lineHeight: '1.5',
                                                                    }}
                                                                  >
                                                                    <span
                                                                      style={{
                                                                        fontSize: 15,
                                                                      }}
                                                                    >
                                                                      <strong>
                                                                        <span data-custom-class="body_text">
                                                                          When we use Google
                                                                          Analytics.
                                                                        </span>
                                                                      </strong>
                                                                      <span data-custom-class="body_text">
                                                                        &nbsp;We may share your
                                                                        information with Google
                                                                        Analytics to track and
                                                                        analyze the use of the
                                                                        Services. To opt out of
                                                                        being tracked by Google
                                                                        Analytics across the
                                                                        Services, visit{' '}
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              'rgb(0, 58, 250)',
                                                                          }}
                                                                        >
                                                                          <a
                                                                            data-custom-class="link"
                                                                            href="https://tools.google.com/dlpage/gaoptout"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                          >
                                                                            https://tools.google.com/dlpage/gaoptout
                                                                          </a>
                                                                        </span>
                                                                        . For more information on
                                                                        the privacy practices of
                                                                        Google, please visit the{' '}
                                                                        <span
                                                                          style={{
                                                                            color:
                                                                              'rgb(0, 58, 250)',
                                                                          }}
                                                                        >
                                                                          <a
                                                                            data-custom-class="link"
                                                                            href="https://policies.google.com/privacy"
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                          >
                                                                            Google Privacy &amp;
                                                                            Terms page
                                                                          </a>
                                                                        </span>
                                                                        .
                                                                      </span>
                                                                    </span>
                                                                  </li>
                                                                </ul>
                                                                <div
                                                                  style={{
                                                                    lineHeight: '1.5',
                                                                  }}
                                                                >
                                                                  <div
                                                                    style={{
                                                                      lineHeight: '1.5',
                                                                    }}
                                                                  >
                                                                    <div
                                                                      style={{
                                                                        lineHeight: '1.5',
                                                                      }}
                                                                    >
                                                                      <div
                                                                        style={{
                                                                          lineHeight: '1.5',
                                                                        }}
                                                                      >
                                                                        <div
                                                                          style={{
                                                                            lineHeight: '1.5',
                                                                          }}
                                                                        >
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            'rgb(89, 89, 89)',
                                                                                        }}
                                                                                      >
                                                                                        <span
                                                                                          style={{
                                                                                            fontSize: 15,
                                                                                          }}
                                                                                        >
                                                                                          <span
                                                                                            style={{
                                                                                              color:
                                                                                                'rgb(89, 89, 89)',
                                                                                            }}
                                                                                          >
                                                                                            {' '}
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="inforetain"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(127, 127, 127)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      id="control"
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 0, 0)',
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        <span data-custom-class="heading_1">
                                                                                          5. HOW
                                                                                          LONG DO WE
                                                                                          KEEP YOUR
                                                                                          INFORMATION?
                                                                                        </span>
                                                                                      </strong>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <strong>
                                                                                    <em>
                                                                                      In
                                                                                      Short:&nbsp;
                                                                                    </em>
                                                                                  </strong>
                                                                                  <em>
                                                                                    We keep your
                                                                                    information for
                                                                                    as long as
                                                                                    necessary to
                                                                                    fulfill the
                                                                                    purposes
                                                                                    outlined in this
                                                                                    privacy notice
                                                                                    unless otherwise
                                                                                    required by law.
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  We will only keep
                                                                                  your personal
                                                                                  information for as
                                                                                  long as it is
                                                                                  necessary for the
                                                                                  purposes set out
                                                                                  in this privacy
                                                                                  notice, unless a
                                                                                  longer retention
                                                                                  period is required
                                                                                  or permitted by
                                                                                  law (such as tax,
                                                                                  accounting, or
                                                                                  other legal
                                                                                  requirements).
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  When we have no
                                                                                  ongoing legitimate
                                                                                  business need to
                                                                                  process your
                                                                                  personal
                                                                                  information, we
                                                                                  will either delete
                                                                                  or anonymize such
                                                                                  information, or,
                                                                                  if this is not
                                                                                  possible (for
                                                                                  example, because
                                                                                  your personal
                                                                                  information has
                                                                                  been stored in
                                                                                  backup archives),
                                                                                  then we will
                                                                                  securely store
                                                                                  your personal
                                                                                  information and
                                                                                  isolate it from
                                                                                  any further
                                                                                  processing until
                                                                                  deletion is
                                                                                  possible.{' '}
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="infosafe"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(127, 127, 127)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      id="control"
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 0, 0)',
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        <span data-custom-class="heading_1">
                                                                                          6. HOW DO
                                                                                          WE KEEP
                                                                                          YOUR
                                                                                          INFORMATION
                                                                                          SAFE?
                                                                                        </span>
                                                                                      </strong>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <strong>
                                                                                    <em>
                                                                                      In
                                                                                      Short:&nbsp;
                                                                                    </em>
                                                                                  </strong>
                                                                                  <em>
                                                                                    We aim to
                                                                                    protect your
                                                                                    personal
                                                                                    information
                                                                                    through a system
                                                                                    of
                                                                                    organizational
                                                                                    and technical
                                                                                    security
                                                                                    measures.
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  We have
                                                                                  implemented
                                                                                  appropriate and
                                                                                  reasonable
                                                                                  technical and
                                                                                  organizational
                                                                                  security measures
                                                                                  designed to
                                                                                  protect the
                                                                                  security of any
                                                                                  personal
                                                                                  information we
                                                                                  process. However,
                                                                                  despite our
                                                                                  safeguards and
                                                                                  efforts to secure
                                                                                  your information,
                                                                                  no electronic
                                                                                  transmission over
                                                                                  the Internet or
                                                                                  information
                                                                                  storage technology
                                                                                  can be guaranteed
                                                                                  to be 100% secure,
                                                                                  so we cannot
                                                                                  promise or
                                                                                  guarantee that
                                                                                  hackers,
                                                                                  cybercriminals, or
                                                                                  other unauthorized
                                                                                  third parties will
                                                                                  not be able to
                                                                                  defeat our
                                                                                  security and
                                                                                  improperly
                                                                                  collect, access,
                                                                                  steal, or modify
                                                                                  your information.
                                                                                  Although we will
                                                                                  do our best to
                                                                                  protect your
                                                                                  personal
                                                                                  information,
                                                                                  transmission of
                                                                                  personal
                                                                                  information to and
                                                                                  from our Services
                                                                                  is at your own
                                                                                  risk. You should
                                                                                  only access the
                                                                                  Services within a
                                                                                  secure
                                                                                  environment.{' '}
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="infominors"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(127, 127, 127)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      id="control"
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 0, 0)',
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        <span data-custom-class="heading_1">
                                                                                          7. DO WE
                                                                                          COLLECT
                                                                                          INFORMATION
                                                                                          FROM
                                                                                          MINORS?
                                                                                        </span>
                                                                                      </strong>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <strong>
                                                                                    <em>
                                                                                      In Short:
                                                                                    </em>
                                                                                  </strong>
                                                                                  <em>
                                                                                    &nbsp;We do not
                                                                                    knowingly
                                                                                    collect data
                                                                                    from or market
                                                                                    to children
                                                                                    under 18 years
                                                                                    of age .
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  We do not
                                                                                  knowingly solicit
                                                                                  data from or
                                                                                  market to children
                                                                                  under 18 years of
                                                                                  age. By using the
                                                                                  Services, you
                                                                                  represent that you
                                                                                  are at least 18 or
                                                                                  that you are the
                                                                                  parent or guardian
                                                                                  of such a minor
                                                                                  and consent to
                                                                                  such minor
                                                                                  dependent’s use of
                                                                                  the Services. If
                                                                                  we learn that
                                                                                  personal
                                                                                  information from
                                                                                  users less than 18
                                                                                  years of age has
                                                                                  been collected, we
                                                                                  will deactivate
                                                                                  the account and
                                                                                  take reasonable
                                                                                  measures to
                                                                                  promptly delete
                                                                                  such data from our
                                                                                  records. If you
                                                                                  become aware of
                                                                                  any data we may
                                                                                  have collected
                                                                                  from children
                                                                                  under age 18,
                                                                                  please contact us
                                                                                  at{' '}
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      stanislaw.nagorski@decathlon.com
                                                                                    </span>
                                                                                  </span>{' '}
                                                                                  .
                                                                                </span>{' '}
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="privacyrights"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(127, 127, 127)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      id="control"
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 0, 0)',
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        <span data-custom-class="heading_1">
                                                                                          8. WHAT
                                                                                          ARE YOUR
                                                                                          PRIVACY
                                                                                          RIGHTS?
                                                                                        </span>
                                                                                      </strong>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <strong>
                                                                                    <em>
                                                                                      In Short:
                                                                                    </em>
                                                                                  </strong>
                                                                                  <em>
                                                                                    &nbsp; In some
                                                                                    regions, such as
                                                                                    the European
                                                                                    Economic Area
                                                                                    (EEA), United
                                                                                    Kingdom (UK),
                                                                                    and Switzerland
                                                                                    , you have
                                                                                    rights that
                                                                                    allow you
                                                                                    greater access
                                                                                    to and control
                                                                                    over your
                                                                                    personal
                                                                                    information.{' '}
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(89, 89, 89)',
                                                                                      }}
                                                                                    >
                                                                                      {' '}
                                                                                      &nbsp;
                                                                                    </span>
                                                                                    You may review,
                                                                                    change, or
                                                                                    terminate your
                                                                                    account at any
                                                                                    time.
                                                                                  </em>{' '}
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  In some regions
                                                                                  (like the EEA, UK,
                                                                                  and Switzerland ),
                                                                                  you have certain
                                                                                  rights under
                                                                                  applicable data
                                                                                  protection laws.
                                                                                  These may include
                                                                                  the right (i) to
                                                                                  request access and
                                                                                  obtain a copy of
                                                                                  your personal
                                                                                  information, (ii)
                                                                                  to request
                                                                                  rectification or
                                                                                  erasure; (iii) to
                                                                                  restrict the
                                                                                  processing of your
                                                                                  personal
                                                                                  information; (iv)
                                                                                  if applicable, to
                                                                                  data portability;
                                                                                  and (v) not to be
                                                                                  subject to
                                                                                  automated
                                                                                  decision-making.
                                                                                  In certain
                                                                                  circumstances, you
                                                                                  may also have the
                                                                                  right to object to
                                                                                  the processing of
                                                                                  your personal
                                                                                  information. You
                                                                                  can make such a
                                                                                  request by
                                                                                  contacting us by
                                                                                  using the contact
                                                                                  details provided
                                                                                  in the section "
                                                                                </span>
                                                                              </span>
                                                                            </span>{' '}
                                                                            <a
                                                                              data-custom-class="link"
                                                                              href="#contact"
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(0, 58, 250)',
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(0, 58, 250)',
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    HOW CAN YOU
                                                                                    CONTACT US ABOUT
                                                                                    THIS NOTICE?
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </a>{' '}
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  " below.
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  We will consider
                                                                                  and act upon any
                                                                                  request in
                                                                                  accordance with
                                                                                  applicable data
                                                                                  protection laws.
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              &nbsp;
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  If you are located
                                                                                  in the EEA or UK
                                                                                  and you believe we
                                                                                  are unlawfully
                                                                                  processing your
                                                                                  personal
                                                                                  information, you
                                                                                  also have the
                                                                                  right to complain
                                                                                  to your{' '}
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 58, 250)',
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              'rgb(0, 58, 250)',
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <a
                                                                                              data-custom-class="link"
                                                                                              href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                              rel="noopener noreferrer"
                                                                                              target="_blank"
                                                                                            >
                                                                                              <span
                                                                                                style={{
                                                                                                  fontSize: 15,
                                                                                                }}
                                                                                              >
                                                                                                Member
                                                                                                State
                                                                                                data
                                                                                                protection
                                                                                                authority
                                                                                              </span>
                                                                                            </a>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>{' '}
                                                                                  or&nbsp;
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                            <a
                                                                              data-custom-class="link"
                                                                              href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                                              rel="noopener noreferrer"
                                                                              target="_blank"
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(0, 58, 250)',
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(0, 58, 250)',
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    UK data
                                                                                    protection
                                                                                    authority
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </a>
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  .
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  If you are located
                                                                                  in Switzerland,
                                                                                  you may contact
                                                                                  the{' '}
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 58, 250)',
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        <span
                                                                                          style={{
                                                                                            color:
                                                                                              'rgb(0, 58, 250)',
                                                                                          }}
                                                                                        >
                                                                                          <span data-custom-class="body_text">
                                                                                            <span
                                                                                              style={{
                                                                                                color:
                                                                                                  'rgb(0, 58, 250)',
                                                                                                fontSize: 15,
                                                                                              }}
                                                                                            >
                                                                                              <a
                                                                                                data-custom-class="link"
                                                                                                href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                                rel="noopener noreferrer"
                                                                                                target="_blank"
                                                                                              >
                                                                                                Federal
                                                                                                Data
                                                                                                Protection
                                                                                                and
                                                                                                Information
                                                                                                Commissioner
                                                                                              </a>
                                                                                            </span>
                                                                                          </span>
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                  .
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="withdrawconsent"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <strong>
                                                                                    <u>
                                                                                      Withdrawing
                                                                                      your consent:
                                                                                    </u>
                                                                                  </strong>{' '}
                                                                                  If we are relying
                                                                                  on your consent to
                                                                                  process your
                                                                                  personal
                                                                                  information, you
                                                                                  have the right to
                                                                                  withdraw your
                                                                                  consent at any
                                                                                  time. You can
                                                                                  withdraw your
                                                                                  consent at any
                                                                                  time by contacting
                                                                                  us by using the
                                                                                  contact details
                                                                                  provided in the
                                                                                  section "
                                                                                </span>
                                                                              </span>
                                                                            </span>{' '}
                                                                            <a
                                                                              data-custom-class="link"
                                                                              href="#contact"
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(0, 58, 250)',
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(0, 58, 250)',
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    HOW CAN YOU
                                                                                    CONTACT US ABOUT
                                                                                    THIS NOTICE?
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </a>{' '}
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  " below .
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                However, please note
                                                                                that this will not
                                                                                affect the
                                                                                lawfulness of the
                                                                                processing before
                                                                                its withdrawal nor,
                                                                                will it affect the
                                                                                processing of your
                                                                                personal information
                                                                                conducted in
                                                                                reliance on lawful
                                                                                processing grounds
                                                                                other than consent.
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span data-custom-class="body_text">
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                If you have
                                                                                questions or
                                                                                comments about your
                                                                                privacy rights, you
                                                                                may email us at
                                                                                stanislaw.nagorski@decathlon.com
                                                                                .
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="DNT"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(127, 127, 127)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      id="control"
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 0, 0)',
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        <span data-custom-class="heading_1">
                                                                                          9.
                                                                                          CONTROLS
                                                                                          FOR
                                                                                          DO-NOT-TRACK
                                                                                          FEATURES
                                                                                        </span>
                                                                                      </strong>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Most web br
                                                                                  /owsers and some
                                                                                  mobile operating
                                                                                  systems and mobile
                                                                                  applications
                                                                                  include a
                                                                                  Do-Not-Track (
                                                                                  "DNT" ) feature or
                                                                                  setting you can
                                                                                  activate to signal
                                                                                  your privacy
                                                                                  preference not to
                                                                                  have data about
                                                                                  your online br
                                                                                  /owsing activities
                                                                                  monitored and
                                                                                  collected. At this
                                                                                  stage no uniform
                                                                                  technology
                                                                                  standard for
                                                                                  recognizing and
                                                                                  implementing DNT
                                                                                  signals has been
                                                                                  finalized . As
                                                                                  such, we do not
                                                                                  currently respond
                                                                                  to DNT br /owser
                                                                                  signals or any
                                                                                  other mechanism
                                                                                  that automatically
                                                                                  communicates your
                                                                                  choice not to be
                                                                                  tracked online. If
                                                                                  a standard for
                                                                                  online tracking is
                                                                                  adopted that we
                                                                                  must follow in the
                                                                                  future, we will
                                                                                  inform you about
                                                                                  that practice in a
                                                                                  revised version of
                                                                                  this privacy
                                                                                  notice.
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="policyupdates"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(127, 127, 127)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      id="control"
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 0, 0)',
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        <span data-custom-class="heading_1">
                                                                                          10. DO WE
                                                                                          MAKE
                                                                                          UPDATES TO
                                                                                          THIS
                                                                                          NOTICE?
                                                                                        </span>
                                                                                      </strong>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <em>
                                                                                    <strong>
                                                                                      In
                                                                                      Short:&nbsp;
                                                                                    </strong>
                                                                                    Yes, we will
                                                                                    update this
                                                                                    notice as
                                                                                    necessary to
                                                                                    stay compliant
                                                                                    with relevant
                                                                                    laws.
                                                                                  </em>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  We may update this
                                                                                  privacy notice
                                                                                  from time to time.
                                                                                  The updated
                                                                                  version will be
                                                                                  indicated by an
                                                                                  updated "Revised"
                                                                                  date and the
                                                                                  updated version
                                                                                  will be effective
                                                                                  as soon as it is
                                                                                  accessible. If we
                                                                                  make material
                                                                                  changes to this
                                                                                  privacy notice, we
                                                                                  may notify you
                                                                                  either by
                                                                                  prominently
                                                                                  posting a notice
                                                                                  of such changes or
                                                                                  by directly
                                                                                  sending you a
                                                                                  notification. We
                                                                                  encourage you to
                                                                                  review this
                                                                                  privacy notice
                                                                                  frequently to be
                                                                                  informed of how we
                                                                                  are protecting
                                                                                  your information.
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="contact"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(127, 127, 127)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      id="control"
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 0, 0)',
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        <span data-custom-class="heading_1">
                                                                                          11. HOW
                                                                                          CAN YOU
                                                                                          CONTACT US
                                                                                          ABOUT THIS
                                                                                          NOTICE?
                                                                                        </span>
                                                                                      </strong>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  If you have
                                                                                  questions or
                                                                                  comments about
                                                                                  this notice, you
                                                                                  may{' '}
                                                                                  <span
                                                                                    style={{
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span data-custom-class="body_text">
                                                                                      email us at
                                                                                      stanislaw.nagorski@decathlon.com
                                                                                      or&nbsp;
                                                                                    </span>
                                                                                  </span>{' '}
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        fontSize: 15,
                                                                                        color:
                                                                                          'rgb(89, 89, 89)',
                                                                                      }}
                                                                                    >
                                                                                      <span data-custom-class="body_text">
                                                                                        contact us
                                                                                        by post at:
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(89, 89, 89)',
                                                                                      }}
                                                                                    >
                                                                                      <span
                                                                                        style={{
                                                                                          color:
                                                                                            'rgb(89, 89, 89)',
                                                                                        }}
                                                                                      >
                                                                                        <span data-custom-class="body_text">
                                                                                          Decathlon
                                                                                          Go
                                                                                        </span>
                                                                                      </span>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>{' '}
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                Geodezyjna,
                                                                                Warszawa, Poland{' '}
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                Warszawa{' '}
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    , Mazowieckie
                                                                                    03-290
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                {' '}
                                                                                Poland{' '}
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(89, 89, 89)',
                                                                                      }}
                                                                                    >
                                                                                      {' '}
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>{' '}
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              data-custom-class="body_text"
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              If you are a resident
                                                                              in the European
                                                                              Economic Area or
                                                                              Switzerland , we are
                                                                              the "data controller"
                                                                              of your personal
                                                                              information. We have
                                                                              appointed{' '}
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Stanislaw Nagórski
                                                                                </span>
                                                                              </span>{' '}
                                                                              to be our
                                                                              representative in the
                                                                              EEA and Switzerland .
                                                                              You can contact them
                                                                              directly regarding our
                                                                              processing of your
                                                                              information
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  , by email at
                                                                                  stanislaw.nagorski@decathlon.com
                                                                                  ,
                                                                                </span>
                                                                              </span>{' '}
                                                                              or by post to:
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                ul. Geodezyjna 76{' '}
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    Warszawa{' '}
                                                                                  </span>
                                                                                </span>{' '}
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    &nbsp; 03-290{' '}
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          />
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span data-custom-class="body_text">
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    Poland
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>{' '}
                                                                            <span
                                                                              data-custom-class="body_text"
                                                                              style={{
                                                                                fontSize: 15,
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                {' '}
                                                                                <span data-custom-class="body_text">
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                    }}
                                                                                  >
                                                                                    {' '}
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            id="request"
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                color:
                                                                                  'rgb(127, 127, 127)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                  fontSize: 15,
                                                                                }}
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    fontSize: 15,
                                                                                    color:
                                                                                      'rgb(89, 89, 89)',
                                                                                  }}
                                                                                >
                                                                                  <span
                                                                                    style={{
                                                                                      fontSize: 15,
                                                                                      color:
                                                                                        'rgb(89, 89, 89)',
                                                                                    }}
                                                                                  >
                                                                                    <span
                                                                                      id="control"
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 0, 0)',
                                                                                      }}
                                                                                    >
                                                                                      <strong>
                                                                                        <span data-custom-class="heading_1">
                                                                                          12. HOW
                                                                                          CAN YOU
                                                                                          REVIEW,
                                                                                          UPDATE, OR
                                                                                          DELETE THE
                                                                                          DATA WE
                                                                                          COLLECT
                                                                                          FROM YOU?
                                                                                        </span>
                                                                                      </strong>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <br />
                                                                          </div>
                                                                          <div
                                                                            style={{
                                                                              lineHeight: '1.5',
                                                                            }}
                                                                          >
                                                                            <span
                                                                              style={{
                                                                                fontSize: 15,
                                                                                color:
                                                                                  'rgb(89, 89, 89)',
                                                                              }}
                                                                            >
                                                                              <span
                                                                                style={{
                                                                                  fontSize: 15,
                                                                                  color:
                                                                                    'rgb(89, 89, 89)',
                                                                                }}
                                                                              >
                                                                                <span data-custom-class="body_text">
                                                                                  Based on the
                                                                                  applicable laws of
                                                                                  your country, you
                                                                                  may have the right
                                                                                  to request access
                                                                                  to the personal
                                                                                  information we
                                                                                  collect from you,
                                                                                  change that
                                                                                  information, or
                                                                                  delete it. To
                                                                                  request to review,
                                                                                  update, or delete
                                                                                  your personal
                                                                                  information,
                                                                                  please fill out
                                                                                  and submit a&nbsp;
                                                                                </span>{' '}
                                                                                <span
                                                                                  style={{
                                                                                    color:
                                                                                      'rgb(0, 58, 250)',
                                                                                  }}
                                                                                >
                                                                                  <span data-custom-class="body_text">
                                                                                    <span
                                                                                      style={{
                                                                                        color:
                                                                                          'rgb(0, 58, 250)',
                                                                                        fontSize: 15,
                                                                                      }}
                                                                                    >
                                                                                      <a
                                                                                        data-custom-class="link"
                                                                                        href="https://app.termly.io/notify/f9e642a8-116e-40ea-bfe1-fb2d25f7c777"
                                                                                        rel="noopener noreferrer"
                                                                                        target="_blank"
                                                                                      >
                                                                                        data subject
                                                                                        access
                                                                                        request
                                                                                      </a>
                                                                                    </span>
                                                                                  </span>
                                                                                </span>{' '}
                                                                                <span data-custom-class="body_text">
                                                                                  .
                                                                                </span>
                                                                              </span>
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                        <div
                                                                          style={{
                                                                            color: '#595959',
                                                                            fontSize: 14,
                                                                            fontFamily: 'Arial',
                                                                            paddingTop: 16,
                                                                          }}
                                                                        >
                                                                          This privacy policy was
                                                                          created using Termly's{' '}
                                                                          <a
                                                                            style={{
                                                                              color:
                                                                                'rgb(48, 48, 241) !important',
                                                                            }}
                                                                            href="https://termly.io/products/privacy-policy-generator/"
                                                                          >
                                                                            Privacy Policy Generator
                                                                          </a>
                                                                          .
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterV2 />
    </div>
  );
};

export default ScannerPrivacy;
