import React from 'react';
import PropTypes from 'prop-types';

const DuplicateIcon = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 22 22" fill="none">
            <path d="M0.6875 9.16507V18.458C0.6875 19.5896 1.62181 20.5184 2.75275 20.5184H12.837C13.9679 20.5184 14.8967 19.5883 14.8967 18.458V9.16507C14.8967 8.03344 13.9673 7.09982 12.837 7.09982H2.75275C1.62181 7.09982 0.6875 8.03413 0.6875 9.16507ZM9.39538 1.48157C8.13725 1.48157 7.10738 2.51694 7.10738 3.77507V5.72344H12.837C14.7263 5.72344 16.2717 7.28063 16.2717 9.16507V14.9002H19.0231C20.2812 14.9002 21.3125 13.8648 21.3125 12.6067V3.77507C21.3125 2.51694 20.2812 1.48157 19.0231 1.48157H9.39538Z" fill="#0082C3" />
        </svg>
    );
};

DuplicateIcon.defaultProps = { className: null };

const { string } = PropTypes;

DuplicateIcon.propTypes = { className: string };

export default DuplicateIcon;
