import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconCalendar.css';

const IconCalendar = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="#9DAFC2" fillRule="nonzero" d="M16 1a1 1 0 0 1 1 1v1h2a3 3 0 0 1 3 3v14a3 3 0 0 1-3 3H5a3 3 0 0 1-3-3V6a3 3 0 0 1 3-3h2V2a1 1 0 0 1 2 0v1h6V2a1 1 0 0 1 1-1zm4 10H4v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9zM7 5H5a1 1 0 0 0-1 1v3h16V6a1 1 0 0 0-1-1h-2v1a1 1 0 1 1-2 0V5H9v1a1 1 0 1 1-2 0V5z"/>
    </svg>
  );
};

IconCalendar.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconCalendar.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconCalendar;
