import React, { Component } from 'react';
import { object, string, func } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldCheckboxIcon } from '../../components';
import config from '../../config';
import css from './ChooseSportsForm.css';

const { sportsConfig } = config;

const sports = Object.keys(sportsConfig)
  .map(sport => ({ key: sport, label: sportsConfig[sport].name, icon: sportsConfig[sport].icon }))
  .sort((a,b) => a.label.localeCompare(b.label));

class ChooseSportsFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            className,
            handleSubmit,
            intl,
            rootClassName,
            form,
            values,
            sports
          } = fieldRenderProps;

          const classes = classNames(rootClassName || css.root, className);
          const classesTextList = classNames(css.sportsTextList, {[`${css.hidden}`]: !values.favSports.length});
          
          const unCheckAll = () => {
            form.change(`favSports`, []);
          }

          return (
            <Form
              className={classes}
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <div className={css.sportsWrapper}>
                <h2 className={css.sportsHeading}>
                  <FormattedMessage id="ChooseSportsForm.sportsHeading" />
                </h2>
                <div className={classesTextList}>
                  <FormattedMessage id="ChooseSportsForm.selected" />: {values.favSports.join(', ')}
                  <span className={css.resetBtn} onClick={unCheckAll}>
                    <FormattedMessage id="ChooseSportsForm.reset" />
                  </span>
                </div>
                <div className={css.sportsList}>
                  {sports.map(sport => {
                    const { key, label, icon } = sport;
                    return <FieldCheckboxIcon 
                    key={key}
                    id={key}
                    name="favSports"
                    label={label}
                    value={label}
                    icon={icon}
                  />
                  })}
                </div>
              </div>
              
              <Button className={css.submitButton} type="submit">
                <FormattedMessage id="ChooseSportsForm.choose" /> 
                <span> ({values.favSports.length})</span>
              </Button>
            </Form>
          );
        }}
      />
    );
  }
}

ChooseSportsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  sports
};

ChooseSportsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  onSubmit: func.isRequired,
  initialValues: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ChooseSportsForm = compose(injectIntl)(ChooseSportsFormComponent);

ChooseSportsForm.displayName = 'ChooseSportsForm';

export default ChooseSportsForm;
