import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      {/* <p className={css.lastUpdated}>Ostatnia aktualizacja: 21 września 2019</p>

      <p>
        Pragniemy Państwa zapewnić, iż Decathlon sp. z o.o. (zwany później
        Administratorem) szanuje Internautów (zwanych później
        Użytkownikami), w tym osoby odwiedzające naszą witrynę internetową
        oraz przywiązuje bardzo dużą wagę do respektowania praw im
        przysługujących, ze szczególnym uwzględnieniem prawa do ochrony
        prywatności w Sieci. Dlatego też gromadzimy informacje pomagające
        nam lepiej zrozumieć Państwa potrzeby i dostosowywać naszą stronę
        do Państwa oczekiwań. W celu dokładnego wyjaśnienia stosowanych
        przez nas zasad przy gromadzeniu i przetwarzaniu informacji,
        zobowiązań Administratora w zakresie usług świadczonych za
        pośrednictwem Internetu oraz informacji prawnych na temat strony,
        którą właśnie Państwo odwiedzacie stworzyliśmy niniejszą Politykę
        Prywatności.
      </p>
      <p>
        Korzystając z tej strony akceptujecie Państwo zapisy niniejszej
        Polityki Prywatności.
      </p>

      <h2>DANE OSOBOWE</h2>
      <p>
        W związku z obowiązkiem wynikającym z Rozporządzenia Parlamentu
        Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
        sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
        osobowych i w sprawie swobodnego przepływu takich danych oraz
        uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
        danych) (Dz.Urz.UE.L Nr 119, str. 1) (dalej: RODO), informujemy,
        że:
      </p>

      <ol>
        <li>
          <strong>Administrator danych osobowych</strong>
          <p>
            Administratorem Państwa danych osobowych, czyli podmiotem
            decydującym o tym, w jakim celu oraz w jaki sposób będą
            przetwarzane Państwa dane jest Decathlon sp. z o. o. z
            siedzibą w Warszawie (kod pocztowy: 03–290 Warszawa), ul.
            Geodezyjna 76. W sprawach związanych z przetwarzaniem oraz
            ochroną swoich danych osobowych mogą Państwo skontaktować się
            z Inspektorem Ochrony Danych za pośrednictwem adresu e– mail
            iod@decathlon.com lub pisemnie na adres siedziby
            Administratora
          </p>
        </li>
        <li>
          <span>
            <strong>Cel i podstawy przetwarzania</strong>
            <br />
            Państwa dane osobowe przetwarzane będą w celu:
          </span>
          <ol>
            <li>
              prowadzenia działań marketingu bezpośredniego (w tym
              newsletter, kontakt dotyczący platformy Decathlon GO) co stanowi
              prawnie uzasadniony interes Administratora (podstawa prawna
              przetwarzania danych art. 6 ust. 1 lit f) RODO) – ze względu
              na przepisy szczególne działania marketingowe za
              pośrednictwem adresu mailowego lub numeru telefonu,
              prowadzone są w oparciu o odrębną zgodę na wykorzystanie
              odpowiedniego kanału komunikacji;
            </li>
            <li>
              obrony przed ewentualnymi roszczeniami prawnymi z tytułu
              naruszenia przepisów o ochronie danych osobowych na
              podstawie art. 6 ust. 1 lit. f) RODO.
            </li>
          </ol>
        </li>
        <li>
          <span>
            <strong>Odbiorcy danych</strong>
            <br />
            Państwa dane osobowe mogą zostać udostępnione:
          </span>
          <ol>
            <li>
              odpowiednim organom państwowym działającym na podstawie
              powszechnie obowiązujących przepisów prawa;
            </li>
            <li>
              organom państwa upoważnionym do dostępu do danych w zakresie
              i w celu określonym w przepisach szczególnych;
            </li>
            <li>
              innym podmiotom zewnętrznym świadczącym na rzecz
              Administratora usługi wspierające jego funkcjonowanie w
              zakresie świadczonych usług, tj. firmom marketingowym,
              firmom świądczącym usługi kontaktu bezpośredniego, dostawcom
              usług IT – przy czym takie podmioty przetwarzają dane na
              podstawie umowy powierzenia i wyłącznie zgodnie z naszymi
              jako Administratora poleceniami;
            </li>
            <li>
              dodatkowo dane osobowe będą przetwarzane przez upoważnionych
              w tym zakresie pracowników, współpracowników Administratora.
            </li>
          </ol>
        </li>
        <li>
          <span>
            <strong>Czas przetwarzania danych</strong>
            <br />
            Państwa dane przetwarzane będą:
          </span>
          <ol>
            <li>
              do czasu wycofania zgody na przekazywanie informacji
              marketingowych z wykorzystaniem odpowiedniego kanału
              komunikacji lub do momentu wniesienia skutecznego sprzeciwu
              wobec przetwarzania, w zależności od tego, które z tych
              zdarzeń nastąpi jako pierwsze;
            </li>
            <li>
              przez okres 12 miesięcy od momentu pozostawienia danych;
            </li>
            <li>
              po wycofaniu zgody lub wniesieniu sprzeciwu, przez okres do
              6 lat, w celu obrony przed ewentualnymi roszczeniami
              prawnymi.
            </li>
          </ol>
        </li>
        <li>
          <span>
            <strong>Przysługujące Państwu uprawnienia</strong>
            <br />W związku z przetwarzaniem Państwa danych osobowych
            posiadają Państwo następujące uprawnienia do:
          </span>
          <ol>
            <li>
              do czasu wycofania zdostępu do swoich danych osobowych;
            </li>
            <li>
              sprostowania swoich danych osobowych;
            </li>
            <li>usunięcia swoich danych osobowych;</li>
            <li>
              ograniczenia przetwarzania swoich danych osobowych;
            </li>
            <li>
              przenoszenia swoich danych osobowych;
            </li>
            <li>
              wniesienia sprzeciwu wobec przetwarzania swoich danych
              osobowych;
            </li>
            <li>
              <span>
                wniesienia skargi do organu nadzorczego, tj. Prezesa
                Urzędu Ochrony Danych Osobowych – więcej informacji
                dotyczących tego uprawnienia dostępne pod adresem:{' '}
                <a
                  href="https://uodo.gov.pl/pl/p/skargi"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://uodo.gov.pl/pl/p/skargi
                </a>
                .
              </span>
            </li>
          </ol>
        </li>
        <li>
          <strong>
            Przekazywanie danych osobowych do Państw Trzecich
          </strong>
          <p>
            Państwa dane mogą być przekazywane do państw trzecich w
            kontekście infrastruktury informatycznej, z której korzysta
            Administrator. W przypadkach wskazanego przekazania stosowane
            są odpowiednie i właściwe zabezpieczenia wymagane przez
            przepisy o ochronie danych osobowych oraz umożliwia się
            uzyskanie kopii danych przekazanych do państw trzecich.
          </p>
        </li>
        <li>
          <strong>Czy muszą Państwo podać nam swoje dane osobowe?</strong>
          <p>
            Podanie danych osobowych jest dobrowolne, jednak bez ich
            podania otrzymywanie informacji marketingowych w tym
            newsletteru i informacji ofertach dostępnych na stronie
            internetowej Decathlon GO oraz odpowiedzi na przedstawione pytania w
            ramach formularza kontaktowego nie będzie możliwe.
          </p>
        </li>
        <li>
          <strong>
            Zautomatyzowane przetwarzanie, w tym profilowanie
          </strong>
          <p>
            Państwa dane osobowe nie będą przetwarzane w sposób
            zautomatyzowany (w tym w formie profilowania), mogący
            wywoływać wobec Państwa skutki prawne lub w podobny sposób
            istotnie wpływać na Państwa sytuację.
          </p>
        </li>
      </ol>

      <h2>COOKIES</h2>
        <p>
          W związku z nowelizacją ustawy o prawie telekomunikacyjnym zostały
          wprowadzone nowe zasady dotyczące informowania Użytkowników, w
          jaki sposób na stronach internetowych stosowane są pliki cookie. W
          celu należytego zadbania o interesy Użytkowników oraz komfortu
          korzystania z serwisu internetowego, który odwołuje się do tego
          dokumentu, poniżej znajdziecie Państwo więcej informacji o plikach
          cookie.
        </p>
        <p>
          W ramach tej strony internetowej stosowane są następujące typy
          plików cookies:
        </p>
        <ol>
          <li>
            „niezbędne” pliki cookies, które umożliwiają korzystanie z usług
            dostępnych w ramach strony internetowej, np. uwierzytelniające
            pliki cookies wykorzystywane do usług wymagających
            uwierzytelniania w ramach strony internetowej, pliki cookies,
            które służą do zapewnienia bezpieczeństwa, np. wykorzystywane do
            wykrywania nadużyć w zakresie uwierzytelniania w ramach strony
            internetowej;
          </li>
          <li>
            „wydajnościowe” pliki cookies, które umożliwiają zbieranie
            informacji o sposobie korzystania ze stron wchodzących w skład
            strony internetowej;
          </li>
          <li>
            „funkcjonalne” pliki cookies, które umożliwiają „zapamiętanie”
            wybranych przez użytkownika ustawień strony internetowej i
            personalizację interfejsu, np. w zakresie wybranego języka lub
            regionu, rozmiaru czcionki, wyglądu strony internetowej itp.;
          </li>
          <li>
            „reklamowe” pliki cookies, które umożliwiają dostarczanie
            użytkownikom strony internetowej treści reklamowych bardziej
            dostosowanych do ich zainteresowań.
          </li>
        </ol>
        <p>Administrator wykorzystuje pliki typu cookies w celu:</p>
        <ol>
          <li>
            utrzymywanie sesji Użytkownika (po zalogowaniu), dzięki której
            Użytkownik nie musi na każdej podstronie Serwisu ponownie
            wpisywać loginu oraz hasła;
          </li>
          <li>
            dostosowanie serwisu do potrzeb Użytkownika;
          </li>
          <li>
            tworzenie statystyk oglądalności podstron Serwisu;
          </li>
          <li>
            opracowywania zagregowanych danych statystycznych, danych z
            pomiarów oraz danych na temat ogólnych trendów w celu
            wzmocnienia i optymalizacji działań marketingowych, promocyjnych
            i analitycznych.
          </li>
        </ol>
        <p>
          Administrator informuje, że Użytkownik może poprzez zmianę
          ustawień przeglądarki wyłączyć obsługę plików «cookies».
          Najczęściej odpowiednia opcja znajduje się w zakładce prywatność
          lub ustawienia danego programu. Poniżej Administrator opisuje, jak
          można zmienić ustawienia cookies w najpopularniejszych
          przeglądarkach internetowych:
        </p>
        <p>Internet Explorer:</p>
        <ol>
          <li>
            Otwórz program Internet Explorer, klikając przycisk Start, a
            następnie klikając polecenie Internet Explorer.
          </li>
          <li>
            Kliknij przycisk Narzędzia, a następnie kliknij polecenie Opcje
            internetowe.
          </li>
          <li>
            Kliknij kartę Prywatność, a następnie w obszarze Ustawienia
            przesuń suwak do najwyższego położenia, aby zablokować wszystkie
            pliki cookies, lub do najniższego położenia, aby zezwolić na
            wszystkie pliki cookies, a następnie kliknij przycisk OK.
          </li>
        </ol>
        <p>Chrome:</p>
        <ol>
          <li>Kliknij menu Chrome na pasku narzędzi przeglądarki.</li>
          <li>Wybierz Ustawienia.</li>
          <li>Kliknij Pokaż ustawienia zaawansowane.</li>
          <li>W sekcji „Prywatność” kliknij przycisk Ustawienia treści.</li>
          <li>
            W sekcji „Pliki cookies” możesz zmienić ustawienia plików
            cookies.
          </li>
        </ol>
        <p>Firefox:</p>
        <ol>
          <li>
            Na górze okna Firefoksa naciśnij przycisk Firefox, (w systemie
            Windows XP kliknij menu Narzędzia) i wybierz Opcje.
          </li>
          <li>Wybierz panel Prywatność.</li>
          <li>
            Z menu rozwijanego elementu Program Firefox: wybierz opcję:
            będzie używał ustawień historii użytkownika.
          </li>
          <li>
            Zaznacz Akceptuj ciasteczka, by włączyć, odznacz, by wyłączyć
            obsługę ciasteczek.
          </li>
        </ol>
        <p>Opera:</p>
        <ol>
          <li>
            Przejdź do zakładki Narzędzia w górnym menu Opery i z
            rozwijanego menu wybierz Preferencje.
          </li>
          <li>
            W okienku, które się pojawi przejdź do zakładki Zaawansowane, z
            menu po lewej stronie wybierz Ciasteczka i zmień ustawienia
            plików cookies.
          </li>
        </ol>
        <p>
          Administrator informuje, że w razie problemów ze zmianą ustawień
          plików cookies, najlepiej skorzystać z informacji znajdujących się
          na stronie internetowej producenta danego programu. Administrator
          informuje, że z momentem połączenia się przez Użytkownika z
          Serwisem, w logach systemowych Serwisu pojawia się informacja o
          numerze (w tym IP) i rodzaju urządzenia końcowego Użytkownika lub
          innej osoby fizycznej, z którego Użytkownik łączy się z Serwisem.
        </p>
        <p>
          Administrator informuje, że będzie przetwarzał, zgodnie z
          przepisami obowiązującego na terytorium Rzeczypospolitej Polskiej
          prawa, dane dotyczące numeru (w tym IP) i rodzaju urządzenia
          końcowego Użytkownika, a także czasu połączenia z Serwisem oraz
          inne dane eksploatacyjne dotyczące aktywności Użytkownika w
          Serwisie. Dane te przetwarzane są w celach technicznych.
          Administrator oświadcza, iż dołoży starań, aby zapewnić
          Użytkownikowi wysoki poziom bezpieczeństwa w zakresie korzystania
          z Serwisu, poprzez zastosowanie odpowiednich administracyjnych,
          technicznych i fizycznych zabezpieczeń danych przed ich
          przypadkowym lub bezprawnym zniszczeniem, utratą, nieupoważnionym
          ujawnieniem lub udostępnieniem. Niestety żaden sposób
          przekazywania danych za pośrednictwem Internetu nie jest w pełni
          bezpieczny. Wszelkie zdarzenia mające wpływ na bezpieczeństwo
          przekazu informacji, w tym np. dotyczące podejrzenia udostępnienia
          plików zawierających szkodliwe oprogramowanie należy zgłaszać na
          adres: <br />
          Decathlon sp. z o.o.
          <br />
          ul. Geodezyjna 76
          <br />
          03–290 Warszawa
          <br />
          tel. (22) 333 70 70
        </p>
        <p>
          Jakiekolwiek pytania dotyczące powyższej polityki prywatności
          prosimy kierować na adres:
          <br />
          Decathlon sp. z o.o.
          <br />
          ul. Geodezyjna 76
          <br />
          03–290 Warszawa
          <br />
          tel. (22) 333 70 70
        </p>
        <h2>SZCZEGÓŁOWY OPIS COOKIES</h2>
        <p>Szczegółowa lista ciasteczek, których używamy</p>
        <p>
          LINKI
          <br />
          Administrator proponuje na swojej stronie Serwisu korzystanie z
          łącz do swoich stron internetowych oraz do innych stron
          internetowych, które nie należą do Administratora. Łącza te
          zostały stworzone w porozumieniu ze stronami, których to dotyczy,
          ale których nie kontroluje w żaden sposób. Administrator nie
          ponosi w żaden sposób odpowiedzialności za treść tychże innych
          stron ani za sposób ich wykorzystywania przez użytkowników.
        </p>
        <p>
          DOSTĘPNOŚĆ
          <br />
          Administrator zobowiązuje się dokładać należytej staranności, aby
          zapewnić nieprzerwany dostęp dla swoich Użytkowników do strony
          internetowej.
        </p>
        <p>
          USTAWIENIA PRYWATNOŚCI
          <br />
          GOOGLE ANALYTICS
          <br />
          Ta strona internetowa korzysta z Google Analytics, usługi analizy
          oglądalności stron internetowych udostępnianej przez Google Inc.
          ("Google"). Google Analytics wykorzystuje tak zwane "pliki
          cookie", pliki tekstowe przechowywane na urządzeniach końcowych
          (komputerach, smartfonach, tabletach i innych). Umożliwia im
          analizę korzystania z witryny. Informacje generowane przez cookie
          na temat korzystania z tej witryny przez użytkowników są zazwyczaj
          przesyłane na najbliższy serwer Google i tam przechowywane. W
          związku z tym dane są przesyłane w zależności od lokalizacji
          użytkownika na serwerze w Europie lub w Stanach Zjednoczonych.
        </p>
        <p>
          Na tej stronie aktywowano anonimizację IP, dzięki czemu adres IP
          użytkowników Google w państwach członkowskich Unii Europejskiej
          lub w innych umawiających się krajach Porozumienia o Europejskim
          Obszarze Gospodarczym zostaje z góry skrócony. Tylko w wyjątkowych
          przypadkach pełny adres IP zostanie wysłany na serwer Google w USA
          i tam skrócony. W imieniu operatora tej witryny Google wykorzysta
          te informacje do oceny korzystania z witryny przez użytkowników,
          sporządzania raportów dotyczących aktywności na stronie oraz
          świadczenia innych usług związanych z aktywnością na stronie
          internetowej i korzystaniem z internetu u operatora strony. Adres
          IP przesłany w ramach Google Analytics przez przeglądarkę w formie
          opisanej powyżej nie zostanie scalony z innymi danymi
          dostarczonymi przez Google.
        </p>
        <p>
          Ponadto możesz zapobiec gromadzeniu przez Google danych
          generowanych przez plik cookie i związanych z korzystaniem z
          witryny (w tym adresu IP) oraz przetwarzaniem tych danych przez
          Google, pobierając wtyczkę do przeglądarki dostępną pod poniższym
          linkiem i zainstaluj:
          <br />
          <a
            href="http://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://tools.google.com/dlpage/gaoptout?hl=en
          </a>
          .
        </p>
        <p>
          Politykę prywatności Google można znaleźć tutaj:
          <br />
          <a
            href="https://www.google.com/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.google.com/intl/de/policies/privacy/
          </a>
          .
        </p>
        <p>
          Więcej informacji na temat prywatności Google Analytics można
          znaleźć na stronie:
          <br />
          <a
            href="https://support.google.com/analytics/answer/6004245"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://support.google.com/analytics/answer/6004245
          </a>
          .
        </p>
        <p>Google Analytics</p>
        <p>
          IamBot – FUNKCJA CZATU
          <br />
          Korzystamy z funkcji czatu IamBot na naszych stronach, aby
          zapewnić Ci dodatkowe opcje pomocy, gdy jest taka potrzeba. W ten
          sposób chcemy zwiększyć twoją satysfakcję z użytkowania strony.
        </p>
        <p>
          Dane dotyczące usługi IamBot są przechowywane na komputerze za
          pośrednictwem "lokalnej pamięci" lub plików cookie. Na serwerach
          IamBot historie rozmów są tymczasowo zapisywane i automatycznie
          usuwane po zakończeniu czatu.
        </p>
        <p>
          Ze względu na jakość naszych konsultacji możemy wymagać, aby treść
          komunikacji z konsultantem została przekazana innym konsultantom
          lub przejrzana przez przełożonych.
        </p>
        <p>
          YSANCE
          <br />
          Ta strona internetowa korzysta z usługi analityki internetowej
          dostarczanej przez ysance.com. Narzędzie do analizy pozwala nam
          poznać sposób korzystania z naszej strony internetowej i
          zainteresowanie odwiedzających naszymi produktami oraz
          zoptymalizować naszą stronę internetową. Na przykład, z wielu
          kategorii produktów, konkretna kategoria może zostać w prosty
          sposób zintegrowana z naszą witryną internetową i jest bardziej
          prawdopodobne, że zostanie znaleziona przez odwiedzającego.
        </p>
        <p>
          Uzyskane dane użytkownika są przesyłane na serwer ysance.com we
          Francji i tam przechowywane. Usługa ysance.com używa adresu IP,
          który jest przechowywany anonimowo (usunięcie ostatniego oktetu).
          Identyfikacja odwiedzających jest zasadniczo wykluczona.
        </p>
        <p>
          Narzędzie do analityki internetowej Ysance.com wykorzystuje pliki
          cookie. Możesz uniemożliwić ysance.com zbieranie informacji
          wygenerowanych przez plik cookie (w tym Twój anonimowy adres IP) z
          korzystania z witryny (w tym zanonimizowanego adresu IP), a tym
          samym zapobiec przetwarzaniu przez ysance.com tych informacji.
          Skonfiguruj swoją przeglądarkę, aby zapobiec używaniu plików
          cookie do usługi analizy ysance.com i usuwaniu zapisanych plików
          cookie na dysku twardym urządzenia.
        </p>
        <p>
          Polityka prywatności Ysance znajduje się tutaj:
          <br />
          <a
            href="http://www.ysance.com/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.ysance.com/privacy.html
          </a>
        </p>
        <p>
          BEAMPULSE
          <br />
          Ta strona internetowa korzysta z usługi analityki internetowej
          dostarczanej przez Beampulse, Dzięki analizie takich danych
          jesteśmy w stanie dostosować nasz serwis do zachowań użytkowników.
          Dane przesyłane na serwery Beampusle są w pełni zanonimizowane i
          nie jest możliwe na ich podstawie zidentyfikować konkretnego
          użytkownika.
        </p>
        <p>
          AB TASTY
          <br />
          Ta strona korzysta z narzędzia AB Tasty na potrzeby AB testów i
          ciągłego ulepszania strony. Wykorzystane pliki cookie pozwalają
          nam zmieniać stronę i analizować zmiany. Jeśli tego nie chcesz,
          możesz aktywować plik cookie dostępny za pośrednictwem tego linku{' '}
          <a
            href="https://www.decathlon.de/#abtastyoptout=1"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.decathlon.de/#abtastyoptout=1
          </a>
          , co spowoduje, że AB Tasty nie będzie działać. Zwracamy uwagę, że
          strona internetowa w tym przypadku nie może być już używana w
          całości.
        </p>
        <p>
          RTB HOUSE
          <br />
          Ta strona korzysta z narzędzia RTB HOUSE aby móc spersonalizować
          przekaz jaki do Ciebie kierujemy. Dzięki tej wiedzy, jesteśmy w
          stanie sugerować Ci produkty, które naprawdę Ciebie zainteresują,
          także wyświetlając je poza stroną Decathlon.pl
        </p>
        <p>
          Google Remarketing
          <br />
          Ta strona korzysta z narzędzia Google Remarketing aby móc
          spersonalizować przekaz jaki do Ciebie kierujemy, na stronach
          zewnętrznych.
        </p>
        <p>
          PERSONALIZACJA ZA POMOCĄ BitRec
          <br />
          Na naszej stronie internetowej korzystamy z usługi BitRec, która
          stara się sprostać Twoim osobistym potrzebom i preferencjom
          poprzez analizę danych naukowych. Pomaga nam to zrozumieć, które
          strony są dla Ciebie szczególnie atrakcyjne, które produkty
          najbardziej interesują naszych użytkowników i jakie indywidualne
          oferty powinniśmy skierować do użytkowników naszej witryny. Różne
          dane, takie jak Twój adres IP, kanał ruchu lub pogoda, są
          przechowywane w Twojej przeglądarce zaszyfrowane.
        </p>
        <p>
          AdForm
          <br />
          Udostępniamy dane marketingowe Tradelab, takie jak pliki cookie,
          identyfikator reklamy i adres IP. Tradelab to programowa platforma
          multimedialna dla reklamodawców i agencji. Link do polityki
          prywatniości AdForm (
          <a
            href="https://site.adform.com/uncategorized/product–and–services–privacy–policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://site.adform.com/uncategorized/product–and–services–privacy–policy/
          </a>
          )
        </p>
        <p>
          FACEBOOK CUSTOM AUDIENCE
          <br />
          Nasza strona internetowa wykorzystuje narzędzie komunikacji
          Facebook Custom Audience. Facebook generuje nieodwracalną i nie
          osobistą wartość hash (sumę kontrolną) z danych użytkowania, która
          jest używana do celów analitycznych i marketingowych, takich jak
          spersonalizowane nakładki reklamowe. Bardziej szczegółowe
          informacje na temat celu i zakresu związanego z gromadzeniem i
          przetwarzaniem danych przez Facebook oraz twoje ustawienia
          prywatności można znaleźć w polityce prywatności Facebooka:
          <br />
          <a
            href="https://www.facebook.com/ads/website_custom_audiences/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/website_custom_audiences/
          </a>
          <br />
          <a
            href="https://www.facebook.com/privacy/explanation"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/privacy/explanation
          </a>
          <br />
          Możesz odwołać swoją zgodę w dowolnym momencie i nie zgadzasz się
          z jej użyciem tutaj:
          <br />
          <a
            href="https://www.facebook.com/ads/website_custom_audiences/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/ads/website_custom_audiences/
          </a>
          .
        </p>
        <p>
          WTYCZKI SPOŁECZNOŚCIOWE
          <br />
          Na naszej stronie i blogu używamy wtyczek społecznościowych. Są to
          obecnie: Facebook, Google+, Twitter, Youtube i Instagram. Jeśli te
          wtyczki są aktywowane, przeglądarka nawiązuje bezpośrednie
          połączenie z serwerami odpowiedniej sieci społecznościowej, gdy
          tylko otworzysz stronę w naszej witrynie. Treść wtyczki jest
          przesyłana z sieci społecznościowej bezpośrednio do przeglądarki i
          włączana przez nią do witryny. Wtyczki można oczywiście
          dezaktywować jednym kliknięciem.
        </p>
        <p>
          Cel i zakres gromadzenia danych oraz dalsze przetwarzanie i
          wykorzystywanie danych przez sieci społecznościowe oraz związane z
          nimi prawa i opcje ustawień w celu ochrony prywatności użytkownika
          można znaleźć w polityce prywatności odpowiednich sieci lub stron
          internetowych. Linki można znaleźć poniżej. Jeśli nie chcesz, aby
          sieci społecznościowe zbierały dane o Tobie przez naszą stronę
          internetową, musisz wylogować się z tych sieci przed odwiedzenie
          naszej strony internetowej.
        </p>
        <p>
          Na naszych stronach internetowych używamy następujących wtyczek:
        </p>
        <p>
          FACEBOOK
          <br />
          Używamy wtyczek z portalu społecznościowego facebook.com,
          obsługiwanego przez Facebook Inc., 1601 S. California Ave, Palo
          Alto, CA 94304, USA ("Facebook"). Link do Polityki prywatności
          Facebooka można znaleźć tutaj: Polityka prywatności Facebooka.
          <br />
          <a
            href="https://www.facebook.com/about/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://www.facebook.com/about/privacy/
          </a>
        </p>
        <p>
          GOOGLE+
          <br />
          Używamy wtyczek z sieci społecznościowej Google+, która jest
          obsługiwana przez Google. Możesz znaleźć link do polityki
          prywatności Google tutaj: Informacje na temat ochrony prywatności
          od Google.
          <br />
          <a
            href="http://www.google.com/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.google.com/intl/de/policies/privacy/
          </a>
        </p>
        <p>
          TWITTER
          <br />
          Używamy wtyczek społecznościowych Twitter, które są obsługiwane
          przez Twitter Inc., 795 Folsom St., Suite 600, San Francisco, CA
          94107, USA ("Twitter"). Link do polityki prywatności Twittera
          znajdziesz tutaj: Polityka prywatności Twittera.
          <br />
          <a
            href="https://twitter.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://twitter.com/privacy
          </a>
        </p>
        <p>
          YOUTUBE
          <br />
          Używamy wtyczek z witryny Youtube obsługiwanej przez Google Inc.,
          1600 Amphitheatre Parkway, Mountain View, CA 94043, Stany
          Zjednoczone ("Youtube"). Link do polityki prywatności serwisu
          Youtube można znaleźć tutaj: Polityka prywatności serwisu Youtube.
          <br />
          <a
            href="http://www.google.com/intl/de/policies/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.google.com/intl/de/policies/privacy/
          </a>
        </p>
        <p>
          INSTAGRAM
          <br />
          Używamy wtyczek z sieci społecznościowej Instagram, obsługiwanej
          przez Instagram, LLC Instagram Inc., 1601 Willow Road, Menlo Park,
          CA 94025, USA ("Instagram"). Link do polityki prywatności
          Instagram można znaleźć tutaj:
          <br />
          <a
            href="http://instagram.com/about/legal/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://instagram.com/about/legal/privacy/
          </a>
        </p> */}
      <>
        <>
          <p
            dir="ltr"
            style={{ lineHeight: "1.295", marginTop: "12pt", marginBottom: "0pt" }}
          >
            <span
              style={{
                fontSize: "22pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#2e74b5",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Polityka Prywatności i Polityka Cookie Decathlon GO&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Informacje wstępne
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Poniżej znajdziesz politykę prywatności dla platformy internetowej
              Decathlon GO, znajdującej się pod adresem:
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Calibri,sans-serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              &nbsp;
            </span>
            <a href="https://go.decathlon.pl/" style={{ textDecoration: "none" }}>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: '"Source Sans Pro",sans-serif',
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                https://go.decathlon.pl/
              </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              .&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Administrator danych
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Administratorem, czyli podmiotem decydującym o celach i środkach
              przetwarzania Twoich danych osobowych jest Decathlon sp. z o.o. z siedzibą
              w Warszawie przy ul. Geodezyjnej 76, &nbsp;03-290 Warszawa (dalej: „
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Decathlon
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              ”).&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Decathlon przywiązuje szczególną uwagę do ochrony danych osobowych,
              dlatego wyznaczył inspektora ochrony danych, z którym możesz się
              skontaktować pod adresem poczty elektronicznej&nbsp;
            </span>
            <a href="mailto:iod@decathlon.com" style={{ textDecoration: "none" }}>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: '"Source Sans Pro",sans-serif',
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                iod@decathlon.com
              </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              . Decathlon zachęca do kontaktu z IOD w związku z wszelkimi wątpliwościami
              dotyczącymi przetwarzania Twoich danych osobowych.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Pozyskanie danych i cel ich przetwarzania
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Poniżej znajdziesz informacje o celach w jakich Twoje dane są
              przetwarzane, a także o okresie ich przechowywania i źródle
              pozyskania.&nbsp;
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Rejestracja i obsługa konta Decathlon GO
                </span>
              </p>
            </li>
          </ol>
          <div dir="ltr" style={{ marginLeft: "0pt" }} align="center">
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Cel przetwarzania i podstawa prawna
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Okres przechowywania danych
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku zbierania danych w sposób inny niż od Ciebie —
                        źródło ich pochodzenia i kategorie danych osobowych
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Przetwarzamy Twoje dane, abyś miał/a możliwość korzystania z
                        portalu, w tym założenia i korzystania z konta m.in.
                        publikowania ogłoszeń wydarzeń, zawierania umów dotyczących
                        usług dostępnych w ramach Decathlon GO.
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Jeśli jesteś organizatorem wydarzenia, przetwarzamy Twoje dane w
                        celu promocji wydarzeń w Internecie, zagwarantowania obsługi
                        technicznej sprzedaży, czy umożliwienia nam pośredniczenia w
                        sprzedaży Wydarzeń na Twoją rzecz.&nbsp;
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Jeśli jesteś klientem, przetwarzamy Twoje dane w celu
                        umożliwienia Tobie zakupu wydarzenia od organizatora na
                        stronie&nbsp;internetowej&nbsp;Decathlon GO oraz w celu
                        umożliwienia rejestracji na wydarzenie stronie internetowej
                        Decathlon GO (art. 6 ust. 1 lit. b RODO).
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku danych fakultatywnych podawanych w ramach profilu
                        (wizerunek, opis osoby, płeć, wiek, ulubione dyscypliny
                        sportowe) – podstawą przetwarzania jest Państwa zgoda (art. 6
                        ust. 1 lit. a RODO), którą można w każdej chwili wycofać bez
                        wpływu na przetwarzanie mające miejsce przed jej wycofaniem.
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Przetwarzamy Twoje dane również w celu spełnienia zobowiązań
                        prawnych, w celu realizacji obowiązku sprawozdawczości
                        finansowej (art. 6 ust. 1 lit. c RODO w zw. z m.in. art. 74 ust.
                        2 ustawy o rachunkowości oraz art. 32, art. 70 i art. 86 ustawy
                        z dnia 29.08.1997 Ordynacja podatkowa) ,&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        a także w celu realizacji naszego prawnie uzasadnionego interesu
                        (art. 6 ust. 1 lit. f RODO), &nbsp;tj.:&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        - prowadzenia działań marketingu bezpośredniego (w tym
                        newsletter)
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        &nbsp;- &nbsp;działania za pośrednictwem maila lub numeru
                        telefonu, prowadzone są na podstawie odrębnej zgody na
                        wykorzystanie odpowiedniego kanału komunikacji w zw. z art. 172
                        ustawy prawa telekomunikacyjnego oraz art. 10 ustawy o
                        świadczeniu usług drogą elektroniczną,&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        - umożliwienie Tobie otrzymywania spersonalizowanych propozycji
                        przyszłych wydarzeń na podstawie aktywności na portalu oraz
                        historie zakupów wydarzeń czy rejestracji na nie,
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        - umożliwienie umieszczania linków do mediów społecznościowych w
                        ramach wizytówki organizatora.
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku Twoich lub naszych roszczeń w związku z wykonaniem
                        usługi przez Decathlon – ustalenie, dochodzenia i obrona
                        roszczeń, co stanowi nasz prawnie uzasadniony interes (art. 6
                        ust.1 lit. f RODO).
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane przetwarzane będą do czasu:
                      </span>
                    </p>
                    <ul
                      style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
                    >
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Noto Sans Symbols",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            rozwiązania umowy o świadczenie usług drogą elektroniczną,
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Noto Sans Symbols",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            przedawnienia roszczeń z tytułu umowy świadczonej drogą
                            elektroniczną,
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Noto Sans Symbols",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            upływu okresu przewidzianego w portalu na złożenie i
                            rozpatrzenie reklamacji,
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Noto Sans Symbols",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            wygaśnięcia obowiązku przechowywania danych wynikającego z
                            przepisów prawa, w szczególności obowiązku przechowywania
                            dokumentów księgowych dotyczących umowy sprzedaży,
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Noto Sans Symbols",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            realizacji prawnie uzasadnionego interesu administratora lub
                            wniesienia uzasadnionego sprzeciwu, w sytuacji, gdy podstawą
                            przetwarzania danych osobowych jest prawnie uzasadniony
                            interes administratora,
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Noto Sans Symbols",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "8pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            przez okres ważności zgody, tj. do momentu jej wycofania lub
                            zrealizowania celu przez Decathlon.
                          </span>
                        </p>
                      </li>
                    </ul>
                    <br />
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Co do zasady dane pozyskujemy od Ciebie. Jeśli logujesz się
                        poprzez usługi tożsamości Apple, Facebooka lub Google
                        pozyskaliśmy je od Apple, Meta Platforms, lub Google w
                        następującym zakresie:&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Apple: imię, nazwisko, opcjonalnie adres e-mail (otwarty tekst
                        lub zamaskowany ciągiem cyfr);
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Facebook: imię nazwisko, zdjęcie profilowe, adres e-mail.
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Google: nazwa użytkownika, adres e-mail, ustawienia języka i
                        zdjęcie profilowe. &nbsp;
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={2}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Dołączenie do Grona Partnerów Decathlon GO
                </span>
              </p>
            </li>
          </ol>
          <div dir="ltr" style={{ marginLeft: "0pt" }} align="center">
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Cel przetwarzania i podstawa prawna
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Okres przechowywania danych
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku zbierania danych w sposób inny niż od Ciebie —
                        źródło ich pochodzenia i kategorie danych osobowych.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane osobowe przetwarzane będą w celu:&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        - komunikacji i udzielenia odpowiedzi na wiadomośc
                      </span>
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: '"Times New Roman",serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        i
                      </span>
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        &nbsp;dotyczącej dołączenia do grona Partnerów Decathlon GO na
                        podstawie art. 6 ust. 1 lit. f) RODO, tj. n
                      </span>
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: '"Times New Roman",serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        asz&nbsp;
                      </span>
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        prawnie uzasadniony interes;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        - w zależności od treści komunikacji, podjęcia działań na
                        &nbsp;żądanie przed zawarciem umowy, np. &nbsp;założenie konta,
                        dodawanie treningów online, zbierania zapisów (art. 6 ust. 1
                        lit. b); a w sytuacji jej zawarcia przez okres wykonania
                        zobowiązań prawnych, np. prowadzenia sprawozdawczości finansowej
                        (art. 6 ust. 1 lit. c RODO, w zakresie rozliczeń
                        sprawozdawczości finansowej: w zw. z m.in. art. 74 ust. 2 ustawy
                        o rachunkowości oraz art. 32, art. 70 i art. 86 ustawy z dnia
                        29.08.1997 Ordynacja podatkowa)
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane przetwarzamy także w celu podejmowania działań
                        marketingowych (art. 6 ust. 1 lit. f) RODO, tj. nasz prawnie
                        uzasadniony interes).
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku Twoich lub naszych roszczeń w związku z wykonaniem
                        usługi przez Decathlon – ustalenie, dochodzenia i obrona
                        roszczeń, co stanowi nasz prawnie uzasadniony interes (art. 6
                        ust.1 lit. f RODO)
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane przetwarzane będą do czasu:&nbsp;
                      </span>
                    </p>
                    <ul
                      style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
                    >
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            rozwiązania umowy o świadczenie usług drogą elektroniczną,
                            &nbsp;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Noto Sans Symbols",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            przedawnienia roszczeń z tytułu umowy świadczonej drogą
                            elektroniczną, &nbsp;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            upływu okresu przewidzianego w portalu na złożenie i
                            rozpatrzenie reklamacji,&nbsp;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            wygaśnięcia obowiązku przechowywania danych wynikającego z
                            przepisów prawa, w szczególności obowiązku przechowywania
                            dokumentów księgowych dotyczących umowy sprzedaży,&nbsp;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Noto Sans Symbols",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: "Calibri,sans-serif",
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            realizacji prawnie uzasadnionego interesu administratora lub
                            wniesienia uzasadnionego sprzeciwu, w sytuacji, gdy podstawą
                            przetwarzania danych osobowych jest prawnie uzasadniony
                            interes administratora,
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "8pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: '"Source Sans Pro",sans-serif',
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            przez okres ważności zgody, tj. do momentu jej wycofania lub
                            zrealizowania celu przez Decathlon.
                          </span>
                        </p>
                      </li>
                    </ul>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Co do zasady, przetwarzamy dane podawane przez Ciebie.
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={3}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Partnerzy lub ich Przedstawiciele
                </span>
              </p>
            </li>
          </ol>
          <div dir="ltr" style={{ marginLeft: "0pt" }} align="center">
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Cel przetwarzania i podstawa prawna
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Okres przechowywania danych
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku zbierania danych w sposób inny niż od Ciebie —
                        źródło ich pochodzenia i kategorie danych osobowych.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Przetwarzamy Twoje dane w celu wykonania zawartej z nami umowy
                        (w przypadku danych osobowych należących bezpośrednio do
                        Partnera podstawę przetwarzania stanowi art. 6 ust. 1 lit. b
                        RODO, a w przypadku danych osobowych reprezentantów Partnera
                        podstawę przetwarzania stanowi art. 6 ust. 1 lit. f RODO, tj. n
                      </span>
                      <span
                        style={{
                          fontSize: "12pt",
                          fontFamily: '"Times New Roman",serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        asz&nbsp;
                      </span>
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        prawnie uzasadniony interes) &nbsp;oraz w celu wykonania
                        zobowiązań prawnych, np. prowadzenia sprawozdawczości finansowej
                        (art. 6 ust. 1 lit. c RODO, w zakresie rozliczeń
                        sprawozdawczości finansowej: w zw. z m.in. art. 74 ust. 2 ustawy
                        o rachunkowości oraz art. 32, art. 70 i art. 86 ustawy z dnia
                        29.08.1997 Ordynacja podatkowa)
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku Twoich lub naszych roszczeń w związku z wykonaniem
                        usługi przez Decathlon – ustalenie, dochodzenia i obrona
                        roszczeń, co stanowi nasz prawnie uzasadniony interes (art. 6
                        ust.1 lit. f RODO).
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane osobowe będą przetwarzane do momentu wygaśnięcia lub
                        rozwiązania umowy, &nbsp;a po jej zakończeniu w terminach
                        określonych przez przepisy prawa, w tym o rachunkowości, a
                        następnie a następnie przez okres przedawnienia roszczeń
                        wynikających z umowy.
                      </span>
                    </p>
                    <br />
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Przetwarzamy dane podawane przez Ciebie.
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={4}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Reklamacje
                </span>
              </p>
            </li>
          </ol>
          <div dir="ltr" style={{ marginLeft: "0pt" }} align="center">
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Cel przetwarzania i podstawa prawna
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Okres przechowywania danych
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku zbierania danych w sposób inny niż od Ciebie —
                        źródło ich pochodzenia i kategorie danych osobowych.
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "0pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane osobowe przetwarzane będą w celu przyjęcia,
                        rozpatrzenia oraz realizacji złożonej przez Ciebie reklamacji
                        (art. 6 ust. 1 lit. b RODO) oraz w celu &nbsp;wykonania
                        zobowiązań prawnych, np. realizacji prowadzenia sprawozdawczości
                        finansowej (art. 6 ust. 1 lit. c RODO, w zakresie rozliczeń
                        sprawozdawczości finansowej: w zw. z m.in. art. 74 ust. 2 ustawy
                        o rachunkowości oraz art. 32, art. 70 i art. 86 ustawy z dnia
                        29.08.1997 Ordynacja podatkowa).&nbsp;
                      </span>
                    </p>
                    <br />
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku Twoich lub naszych roszczeń w związku z wykonaniem
                        usługi przez Decathlon – ustalenie, dochodzenia i obrona
                        roszczeń, co stanowi nasz prawnie uzasadniony interes (art. 6
                        ust.1 lit. f RODO).
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane osobowe są &nbsp;przetwarzane przez:&nbsp;
                      </span>
                    </p>
                    <ul
                      style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
                    >
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: '"Source Sans Pro",sans-serif',
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            czas trwania umowy;&nbsp;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: '"Source Sans Pro",sans-serif',
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            do czasu upływu rękojmi;&nbsp;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: '"Source Sans Pro",sans-serif',
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            do czasu rozliczenia reklamacji;&nbsp;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: '"Source Sans Pro",sans-serif',
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            przez okres wykonania zobowiązań ustawowych;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "0pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: '"Source Sans Pro",sans-serif',
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            przez okres dochodzenia, ustalenia i obrony roszczeń do
                            czasu ich przedawnienia;
                          </span>
                        </p>
                      </li>
                      <li
                        dir="ltr"
                        style={{
                          listStyleType: "disc",
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre",
                          marginLeft: "-18pt"
                        }}
                        aria-level={1}
                      >
                        <p
                          dir="ltr"
                          style={{
                            lineHeight: "1.3800000000000001",
                            marginTop: "0pt",
                            marginBottom: "8pt"
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              fontFamily: '"Source Sans Pro",sans-serif',
                              color: "#000000",
                              backgroundColor: "transparent",
                              fontWeight: 400,
                              fontStyle: "normal",
                              fontVariant: "normal",
                              textDecoration: "none",
                              verticalAlign: "baseline",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            realizacji prawnie uzasadnionego interesu administratora lub
                            wniesienia uzasadnionego sprzeciwu, w sytuacji, gdy podstawą
                            przetwarzania danych osobowych jest prawnie uzasadniony
                            interes administratora.
                          </span>
                        </p>
                      </li>
                    </ul>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Co do zasady, przetwarzamy dane podawane przez Ciebie
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={5}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Marketing elektroniczny&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <div dir="ltr" style={{ marginLeft: "0pt" }} align="center">
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Cel przetwarzania i podstawa prawna
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Okres przechowywania danych
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku zbierania danych w sposób inny niż od Ciebie —
                        źródło ich pochodzenia i kategorie danych osobowych
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Jeśli wyrazisz zgodę, przetwarzamy Twoje dane w celu wysyłania
                        kodów zniżkowych, ofert specjalnych i innych treści
                        marketingowych na podany przez Ciebie adres e-mail, co stanowi
                        nasz prawnie uzasadniony interes (art. 6 ust. 1 lit. f RODO).
                      </span>
                    </p>
                    <br />
                    <br />
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane osobowe będą przechowywane do czasu wycofania zgody
                        lub wniesienia sprzeciwu, tj. okazania nam w dowolny sposób, że
                        nie życzą sobie Państwo pozostawać z nami w kontakcie i
                        otrzymywać informacji o podejmowanych przez nas
                        działaniach.&nbsp;
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Co do zasady, przetwarzamy dane podawane przez Ciebie
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={6}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Korzystanie ze strony go.decathlon.pl&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <div dir="ltr" style={{ marginLeft: "0pt" }} align="center">
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Cel przetwarzania i podstawa prawna
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Okres przechowywania danych
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku zbierania danych w sposób inny niż od Ciebie —
                        źródło ich pochodzenia i kategorie danych osobowych
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Korzystamy z logów systemowych oraz plików cookie, w celu
                        umożliwienia działania podstawowych funkcji portalu, ale także z
                        innych rodzajów cookie, o czym więcej w dalszej części w
                        „Polityka cookie”.&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane osobowe będą przetwarzane w celu zapewnienia
                        podstawowych funkcji naszej strony internetowej. Jeżeli
                        wyraziłaś/eś zgodę, Twoje dane będą przetwarzane również w celu
                        dostarczenia Ci usług, ofert i komunikatów dopasowanych do
                        Twoich preferencji oraz w celu analizowania ruchu na stronie i
                        zapewnienia funkcji społecznościowych (w zależności od
                        zaznaczonych przez Ciebie preferencji).
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Dane osobowe powiązane z cookies niezbędnymi przetwarzane są na
                        podstawie naszego prawnie uzasadnionego interesu (art. 6 ust. 1
                        lit. f RODO). Dane osobowe powiązane z plikami statystycznymi i
                        marketingowymi przetwarzana są na podstawie Twojej zgody (art. 6
                        ust. 1 lit. a RODO).&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Zgoda na &nbsp; instalowanie plików cookies wynika z art. 173 i
                        174 Ustawy — prawo telekomunikacyjne.&nbsp;
                      </span>
                    </p>
                    <br />
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W takim przypadku dane będą przetwarzane przez okresy wskazane w
                        &nbsp;Polityce plików cookie (znajdującej się poniżej), do
                        momentu wycofania zgody lub do czasu wniesienia sprzeciwu wobec
                        przetwarzania danych.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Co do zasady, przetwarzamy dane podawane przez Ciebie
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={7}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Lokalizacja
                </span>
              </p>
            </li>
          </ol>
          <div dir="ltr" style={{ marginLeft: "0pt" }} align="center">
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Cel przetwarzania i podstawa prawna
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Okres przechowywania danych
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku zbierania danych w sposób inny niż od Ciebie —
                        źródło ich pochodzenia i kategorie danych osobowych
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Celem przetwarzania jest ustalenie Twojego położenia za pomocą
                        portalu Decathlon GO, dzięki czemu będziesz miał/a możliwość
                        odnalezienie odpowiedniego wydarzenia (art. 6 ust. 1 lit. a
                        RODO).&nbsp;
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Dane przetwarzamy przez czas udostępniania nam swojej
                        lokalizacji. Możesz w każdej chwili wyłączyć tę opcję.
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Przetwarzamy dane podawane przez Ciebie
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={8}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt",
                paddingLeft: "1.8500000000000014pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Kontakt bezpośredni
                </span>
              </p>
            </li>
          </ol>
          <div dir="ltr" style={{ marginLeft: "0pt" }} align="center">
            <table style={{ border: "none", borderCollapse: "collapse" }}>
              <tbody>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Cel przetwarzania i podstawa prawna
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Okres przechowywania danych
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "middle",
                      backgroundColor: "#f2f2f2",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "center",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 700,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        W przypadku zbierania danych w sposób inny niż od Ciebie –
                        źródło ich pochodzenia i kategorie danych osobowych
                      </span>
                    </p>
                  </td>
                </tr>
                <tr style={{ height: "11.5pt" }}>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Twoje dane będą przetwarzane w celu:&nbsp;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        - komunikacji i udzielenia odpowiedzi na wiadomości
                        korespondencję na podstawie art. 6 ust. 1 lit. f) RODO, tj.
                        prawnie uzasadniony interes realizowany przez Decathlon — w tej
                        sytuacji nasz prawnie uzasadniony interes polega na chęci
                        utrzymania kontaktu z Tobą, odpowiedzi na zadane pytania,
                        podtrzymania relacji;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        -w zależności od treści komunikacji, podjęcie działań na
                        &nbsp;żądanie przed zawarciem stosownej umowy – na podstawie
                        art. 6 ust. 1 lit. b) RODO, tj. niezbędność do podjęcia działań
                        przed zawarciem umowy;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        - w zależności od treści wiadomości, działania marketingowe
                        Decathlon na podstawie art. 6 ust. 1 lit. f) RODO, tj.
                        uzasadniony interes administratora;
                      </span>
                    </p>
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        -obrona i dochodzenie roszczeń, które mogą powstać w ramach
                        relacji między Tobą a Decathlon oraz inne cele, które są
                        niezbędne do realizacji prawnie uzasadnionych interesów
                        Decathlon na podstawie art. 6 ust. 1 lit. f) RODO, tj. prawnie
                        uzasadniony interes realizowany przez administratora;
                      </span>
                    </p>
                    <br />
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        textAlign: "justify",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Co do zasady, dane przetwarzane są przez okresy wskazane
                        przepisami prawa, a jeżeli co do określonych dokumentów nie są
                        one wskazane, przez czas kiedy ich przechowywanie mieści się w
                        ramach prawnie uzasadnionego celu Decathlon regulowanego czasem
                        możliwego dochodzenia roszczeń. Okres przechowywania danych
                        stanowiących treść korespondencji jest jednak uzależniony od
                        celu przetwarzania danych, którego dotyczy dana korespondencja
                        (jeżeli występują inne terminy usunięcia danych, to informacje
                        na ten temat odpowiedniej klauzuli informacyjnej).
                      </span>
                    </p>
                  </td>
                  <td
                    style={{
                      borderLeft: "solid #000000 0.5pt",
                      borderRight: "solid #000000 0.5pt",
                      borderBottom: "solid #000000 0.5pt",
                      borderTop: "solid #000000 0.5pt",
                      verticalAlign: "top",
                      backgroundColor: "#ffffff",
                      padding: "0pt 5.75pt 0pt 5.75pt",
                      overflow: "hidden",
                      overflowWrap: "break-word"
                    }}
                  >
                    <p
                      dir="ltr"
                      style={{
                        lineHeight: "1.3800000000000001",
                        marginTop: "0pt",
                        marginBottom: "10pt"
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          fontFamily: '"Source Sans Pro",sans-serif',
                          color: "#000000",
                          backgroundColor: "transparent",
                          fontWeight: 400,
                          fontStyle: "normal",
                          fontVariant: "normal",
                          textDecoration: "none",
                          verticalAlign: "baseline",
                          whiteSpace: "pre-wrap"
                        }}
                      >
                        Przetwarzamy dane podawane przez Ciebie
                      </span>
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                color: "rgb(74, 74, 74)"
              }}
            >
              Polityka cookies
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              dir="ltr"
              style={{
                listStyleType: "upper-roman",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3.25pt",
                paddingLeft: "3.3pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {" "}
                  Czym są pliki cookie?&nbsp;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Pliki cookie to niewielkie pliki tekstowe, które są zapisywane na
                  Twoim komputerze lub smartfonie podczas wyświetlania naszej strony
                  internetowej. Istnieją różne rodzaje plików cookie. My podzieliliśmy
                  je dla Ciebie na pięć grup:
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "6.549999999999997pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Niezbędne do korzystania z naszej strony internetowej;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "6.549999999999997pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Preferencyjne;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "6.549999999999997pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Statystyczne;&nbsp;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "6.549999999999997pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Marketingowe;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "6.699999999999996pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Niesklasyfikowane.
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={2}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Pliki cookie, które są niezbędne do korzystania z naszej strony
                  internetowej, służą m.in. do zapewnienia stabilności jej
                  funkcjonowania (mierzą ruch, zabezpieczając nas przed jej
                  przeciążeniem), zapamiętania wybranych przez Ciebie preferencji dot.
                  prywatności, wypełnienia udostępnionych przez nas formularzy on-line,
                  zapisania zawartości koszyka oraz monitorowania statusu zalogowania.
                  Te pliki cookie stosujemy domyślnie, czyli zapisujemy je na Twoim
                  komputerze lub &nbsp;smartfonie w momencie wejścia na naszą stronę
                  internetową (zgodnie z art. 173 ust. 3 ustawy prawo
                  telekomunikacyjne).
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Pozostałe pliki cookie wykorzystujemy jednie wówczas, gdy wyrazisz nam
                  na to zgodę. Przeczytasz o nich więcej w następnej części naszej
                  Polityki.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={2}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "upper-roman",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3.25pt",
                paddingLeft: "3.3pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {" "}
                  Z jakich plików cookie korzystamy?&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              <span className="Apple-tab-span" style={{ whiteSpace: "pre" }}>
                &nbsp; &nbsp;&nbsp;
              </span>
            </span>
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={4}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "10.5pt",
                fontFamily: "Roboto,sans-serif",
                color: "#444746",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt"
                }}
              >
                <span
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Roboto,sans-serif",
                    color: "#444746",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Więcej informacji nt. plików cookies znajdziesz w ustawieniach cookie.
                  Jeśli odwiedzasz stronę po raz pierwszy, wybierz przycisk
                  “Spersonalizuj”. Do ustawień możesz w każdej chwili wrócić klikając
                  niebieską ikonę przedstawiającą „spinacz”, a umieszczoną w lewym
                  dolnym rogu naszej strony. Wybierz wtedy przycisk “Zmień swoją zgodę”.
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Roboto,sans-serif",
                    color: "#444746",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Kiedy wybierzesz powyższe opcje będziesz mógł/a w każdej chwili
                  zapoznać się z kategoriami plików wykorzystywanych na stronie. Po
                  kliknięciu w kategorię wyświetlą Ci się dostawcy plików
                  wykorzystywanych na stronie. Kliknij w danego dostawcę, aby zapoznać
                  się ze szczegółami.
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "10.5pt",
                    fontFamily: "Roboto,sans-serif",
                    color: "#444746",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Jeśli chcesz się dowiedzieć więcej na temat dostawcy – kliknij
                  “Dowiedz się więcej na temat tego dostawcy”.
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={3}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "upper-roman",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3.25pt",
                paddingLeft: "3.3pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {" "}
                  Zgoda na instalację plików cookie
                </span>
              </p>
            </li>
          </ol>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Podczas Twojej wizyty na naszej stronie internetowej zostanie
                  wyświetlony baner informujący, że wykorzystuje ona pliki cookie. Jeśli
                  wybierzesz opcję „Zaakceptuj i zamknij", będzie to oznaczać, że
                  akceptujesz wszystkie pliki cookie, które są umieszczone na naszej
                  stronie internetowej oraz potwierdzasz, że zapoznałeś się z
                  informacjami na temat plików cookie oraz celów ich stosowania, a także
                  przypadków, w których dane zgromadzone z pomocą plików cookie są
                  przekazywane naszym partnerom. &nbsp;&nbsp;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.295",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "6pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Pamiętaj, że w przypadku niezbędnych plików cookie Twoja zgoda nie
                  jest wymagana, ponieważ pliki tego rodzaju zapewniają pełne i
                  nieprzerwane funkcjonowanie naszej strony. Te pliki cookie zwolnione
                  są z obowiązku uzyskania Twojej zgody zgodnie z art. 173 ust. 3 ustawy
                  prawo telekomunikacyjne.
                </span>
              </p>
            </li>
          </ol>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={4}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "upper-roman",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3.25pt",
                paddingLeft: "3.3pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {" "}
                  Brak zgody na instalację plików cookie
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              marginLeft: "14.2pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Jeśli nie chcesz, aby nasze pliki cookie były zapisywane na Twoim
              urządzeniu, możesz wybrać opcję „Odrzuć”. Kiedy wybierzesz tę opcję,
              odrzucisz wszystkie, prócz technicznie niezbędnych, plików cookie, które
              stosujemy na naszej stronie.&nbsp;
            </span>
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={5}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "upper-roman",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3.25pt",
                paddingLeft: "3.3pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  {" "}
                  Modyfikowanie ustawień plików cookie
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              marginLeft: "14.2pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Możesz szczegółowo zarządzać swoimi preferencjami dotyczącymi plików
              cookie, poprzez wybór pola "Spersonalizuj" na wyświetlonym Ci banerze
              cookie.&nbsp;
            </span>
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={6}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "upper-roman",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3.25pt",
                paddingLeft: "3.3pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Wycofanie zgody
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              marginLeft: "14.2pt",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "6pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Pamiętaj, że w każdym momencie możesz zmienić wyrażone przez siebie zgody
              klikając w
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(0, 0, 0)",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              {" "}
              niebieską ikonę przedstawiającą „spinacz”,
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              {" "}
              a umieszczoną w lewym dolnym rogu naszej strony.
            </span>
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={7}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "upper-roman",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "3.25pt",
                paddingLeft: "3.3pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{ lineHeight: "1.295", marginTop: "0pt", marginBottom: "8pt" }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Logi
                </span>
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "underline",
                    textDecorationSkipInk: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  &nbsp;
                </span>
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  serwera
                </span>
              </p>
            </li>
          </ol>
          <ul style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "10pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Korzystanie ze&nbsp;strony www wiąże się z&nbsp;przesyłaniem zapytań
                  do&nbsp;serwera, na&nbsp;którym przechowywana jest strona.
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "10pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Każde zapytanie skierowane do&nbsp;serwera zapisywane jest
                  w&nbsp;logach serwera. Logi obejmują m.in. adres IP&nbsp;użytkownika,
                  datę i&nbsp;czas serwera, informacje o&nbsp;przeglądarce internetowej
                  i&nbsp;systemie operacyjnym, z&nbsp;jakiego korzysta użytkownik.
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "10pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Logi zapisywane i&nbsp;przechowywane są na&nbsp;serwerze.
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "10pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Dane zapisane w&nbsp;logach serwera nie są kojarzone
                  z&nbsp;konkretnymi osobami korzystającymi ze&nbsp;Strony i&nbsp;nie są
                  wykorzystywane przez Decathlon w&nbsp;celu identyfikacji użytkownika.
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "10pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Logi serwera stanowią wyłącznie materiał pomocniczy służący
                  do&nbsp;administrowania stroną, a&nbsp;ich zawartość nie jest
                  ujawniana nikomu poza osobami upoważnionymi do&nbsp;administrowania
                  serwerem.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Odbiorcy danych
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              W związku z prowadzoną działalnością, Decathlon będzie ujawniać Twoje dane
              osobowe następującym podmiotom:
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Meta Platforms Ireland Ltd. („Facebook”) (z siedzibą w Irlandii),
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Google Cloud Poland Sp. z o.o („Google”),
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  organy państwowe oraz inne podmioty upoważnione do dostępu do danych w
                  zakresie i w celu określonym w przepisach prawa,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  banki, dostawcy usług płatniczych, w tym Stripe Payments Europe,
                  Limited (z siedzibą w Irlandii),
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  Sharetribe Oy ( z siedzibą w Finlandii),
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <ol
            style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}
            start={6}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  inne podmioty zewnętrzne świadczące na rzecz Decathlon usługi
                  wspierające jego funkcjonowanie w zakresie świadczonych usług IT,
                  firmom świadczącym usługi doradztwa w zakresie ochrony danych
                  osobowych, firmom świadczącym usługi marketingowe, reklamowe,
                  fotografom, innym podmiotom wspierającym nas w organizacji konkursu,
                  dostawcom poczty elektronicznej i systemów informatycznych, &nbsp;w
                  których przetwarzamy dane, firmom świadczącym usługi kontaktu
                  bezpośredniego &nbsp;– przy czym takie podmioty przetwarzają dane na
                  podstawie umowy powierzenia i wyłącznie zgodnie z naszymi jako
                  Decathlon poleceniami,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  W przypadku Klientów – Organizatorzy Wydarzeń, którzy otrzymują dane w
                  związku z zapisem Klienta na Wydarzenie,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  W przypadku Organizatorów Wydarzeń – Klienci oraz inne osoby
                  odwiedzające profil Organizatora,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro", sans-serif',
                    color: "rgb(0, 0, 0)",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  podmioty z grupy Decathlon.&nbsp;
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Uprawnienia w zakresie przetwarzanych danych
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Każdej osobie, której dane są przetwarzane przez Decathlon, przysługuje
              prawo do:
            </span>
          </p>
          <ol style={{ marginTop: 0, marginBottom: 0, paddingInlineStart: 48 }}>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  dostępu do swoich danych osobowych,&nbsp;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  sprostowania swoich danych osobowych,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  usunięcia swoich danych osobowych,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  ograniczenia przetwarzania swoich danych osobowych,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych
                  (zgodnie z art. 21 ust. 1 RODO, składając sprzeciw, należy wskazać
                  jego przyczyny związane z Państwa szczególną sytuacją),
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  przenoszenia swoich danych osobowych,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "lower-alpha",
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
                marginLeft: "-18pt"
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "10pt"
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: '"Source Sans Pro",sans-serif',
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap"
                  }}
                >
                  wycofania zgody bez wpływu na dokonane już przetwarzanie.
                </span>
              </p>
            </li>
          </ol>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Nadto przysługuje Ci prawo wniesienia skargi do organu nadzorczego, tj.
              Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                color: "rgb(74, 74, 74)"
              }}
            >
              Dobrowolność podania danych
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Podanie danych jest niezbędne do zawarcia umów i rozliczenia prowadzonej
              działalności oraz wywiązania się przez Decathlon z wymogów prawa. W
              pozostałym zakresie (w szczególności w celu przetwarzania przez Decathlon
              danych w celach marketingowych) podanie danych jest dobrowolne.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                color: "rgb(74, 74, 74)"
              }}
            >
              Przekazywanie danych do państw trzecich lub organizacji międzynarodowych
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Twoje dane są przekazywane poza Europejski Obszar Gospodarczy. W przypadku
              naszych dostawców rozwiązań informatycznych, mediów społecznościowych oraz
              plików cookies i innych technologii śledzących Twoje dane osobowe mogą być
              transferowane do USA. Przekazujemy dane osobowe do podmiotów z siedzibą w
              USA, które biorą udział w programie Data Privacy Framework. Więcej
              informacji znajdziesz na stronie:&nbsp;
            </span>
            <a
              href="https://www.dataprivacyframework.gov/s/"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: '"Source Sans Pro",sans-serif',
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                https://www.dataprivacyframework.gov/s/
              </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              . &nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              W pozostałych wypadkach zawieramy z naszymi dostawcami standardowe
              klauzule umowne. Więcej informacji, w tym kopie stosowanych przez nas
              standardowych klauzul umownych możesz uzyskać po kontakcie z naszym
              inspektorem danych osobowych:&nbsp;
            </span>
            <a href="mailto:iod@decathlon.com" style={{ textDecoration: "none" }}>
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: '"Source Sans Pro",sans-serif',
                  color: "#0000ff",
                  backgroundColor: "transparent",
                  fontWeight: 400,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap"
                }}
              >
                iod@decathlon.com
              </span>
            </a>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              .&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
                color: "rgb(74, 74, 74)"
              }}
            >
              Przetwarzanie danych osobowych w sposób zautomatyzowany
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              W przypadku wyrażenia zgody na pliki cookies wpływających na wyświetlanie
              reklam zgodnych z Twoimi zainteresowaniami dochodzi do profilowania,
              jednakże profilowanie to nie powoduje skutków prawych oraz nie wpływa na
              Ciebie w inny podobny sposób.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.7999999999999998",
              textAlign: "justify",
              marginTop: "36pt",
              marginBottom: "12pt"
            }}
          >
            <span
              style={{
                fontSize: 24,
                fontFamily: '"Source Sans Pro", sans-serif',
                color: "rgb(74, 74, 74)",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "small-caps",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Zmiany Polityki
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              Polityka prywatności może ulec zmianie, np. mogą zmienić się dane adresowe
              Decathlon, cele przetwarzania, czy sposób, w jakim możesz realizować swoje
              prawa w związku z przetwarzaniem. W przypadku istotnych zmian lub zmian
              mających wpływ na Twoje prawa będziemy Cię informować z odpowiednio
              większym wyprzedzeniem – w zależności od charakteru zmian.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt"
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: '"Source Sans Pro",sans-serif',
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap"
              }}
            >
              W przypadku zmian w plikach cookies i ich dostawców zmiany są
              odzwierciedlone w banerze cookies i Polityce cookies. Nowe pliki cookies
              nie zainstalują się zanim nie odwiedzisz ponownie strony.
            </span>
          </p>
        </>

      </>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
