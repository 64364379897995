import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName } from '../../util/routes';
import { types as sdkTypes } from '../../util/sdkLoader';
import { confirmP24Payment } from '../CheckoutPage/CheckoutPage.duck';
import { fetchTransactionOnly } from '../TransactionPage/TransactionPage.duck';

import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
} from '../../components';

import { TopbarContainer } from '../../containers';

import { parse } from '../../util/urlHelpers';

import { txIsAccepted } from '../../util/transaction';

import FooterV2 from '../../components/Footer/FooterV2';
import css from './P24StatusPage.css';

const { UUID } = sdkTypes;

class P24Status extends Component {
  componentDidMount() {
    const { redirect_status } = parse(this.props.location.search);
    if (redirect_status === 'failed') {
      return;
    }

    // Transactions in Sharetribe can be locked by other operation for a short period of time
    // However we don't get any info when it's released
    setTimeout(() => this.confirmTransaction(), 1500);
  }

  confirmTransaction = async () => {
    const { onConfirmP24Payment, onFetchTransaction, params, history } = this.props;
    const txId = new UUID(params.id);
    const transaction = await onFetchTransaction(txId, 'customer');

    const orderDetailsSuccessPath = pathByRouteName(
      'OrderDetailsSuccessPage',
      routeConfiguration(),
      { id: params.id }
    );

    if (txIsAccepted(transaction.data.data)) {
      history.push(orderDetailsSuccessPath);
      return;
    }

    try {
      await onConfirmP24Payment({
        transactionId: txId,
      });
      await onFetchTransaction(txId, 'customer');
      history.push(orderDetailsSuccessPath);
    } catch (error) {
      if (error.status === 409) {
        await onFetchTransaction(txId, 'customer');
        history.push(orderDetailsSuccessPath);
      } else {
        throw error;
      }
    }
  };

  succeededPaymentInfo = () => (
    <div className={css.paymentStatus}>
      <h2>Płatność przebiegła pomyślnie</h2>
      <div className={css.paymentStatusInfo}>
        Trwa potwierdzanie Twojej rezerwacji.{' '}
        <span className={css.bolded}>Nie wyłączaj strony</span>, za chwile przejdziesz do strony
        potwierdzającej rezerwację.
      </div>
      <div className={css.loader} />
    </div>
  );

  failedPaymentInfo = () => (
    <div className={css.paymentStatus}>
      <h2 className={css.failed}>Płatność zakończyła się niepowodzeniem</h2>
      <div className={css.paymentStatusInfo}>
        Środki nie zostały pobrane z Twojego konta. Przejdź do rozpoczętej transakcji, aby opłacić
        rezerwację ponownie.
      </div>
      <NamedLink
        name="OrderDetailsSuccessPage"
        params={{ id: this.props.params.id }}
        className={css.transactionBtn}
      >
        Przejdź do transakcji
      </NamedLink>
    </div>
  );

  render() {
    const { redirect_status } = parse(this.props.location.search);

    return (
      <Page title="Potwierdzenie płatności">
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {redirect_status === 'succeeded'
              ? this.succeededPaymentInfo()
              : this.failedPaymentInfo()}
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

export default compose(
  withRouter,
  connect(null, dispatch => ({
    dispatch,
    onConfirmP24Payment: params => dispatch(confirmP24Payment(params)),
    onFetchTransaction: (txId, txRole) => dispatch(fetchTransactionOnly(txId, txRole, true)),
  }))
)(P24Status);
