import React, { useState } from 'react';
import css from './styles.css';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { apiSdk } from '../../queries/api';
import { Avatar } from '../../components';

const ChatMessage = ({ text, date, isConsumer, userData }) => {
  return (
    <div className={css.message}>
      <div className={isConsumer ? css.messageLeft : css.messageRight}>
        <div className={css.messageText}>
          <Avatar user={userData} />
          {text}
        </div>
        <div className={css.messageDate}>{formatDate(date)}</div>
      </div>
    </div>
  );
};

const Chat = ({ messages }) => {
  return (
    <div className={css.chatContainer}>
      {messages.map(message => (
        <ChatMessage
          key={message.id}
          text={message.text}
          date={message.date}
          isConsumer={message.user === 'consumer'}
          userData={message.userData}
        />
      ))}
    </div>
  );
};

const MessagesView = ({ users, providerId, customerId, chatId, isMessageEditorOpen }) => {
  const [message, setMessage] = useState('');

  const providerName = users.find(u => u.id.uuid === providerId).attributes.profile.displayName;

  const queryClient = useQueryClient();

  const { data, isSuccess } = useQuery(['chat.messages', chatId], () =>
    apiSdk.chat.queryMessages(chatId, { include: ['sender'] })
  );

  const mutation = useMutation(apiSdk.chat.sendMessage, {
    onSuccess: data => {
      setMessage('');
      queryClient.invalidateQueries('chat.messages');
    },
  });

  const messages =
    isSuccess &&
    data.data.map(m => ({
      id: m.id.uuid,
      text: m.attributes.content,
      date: m.attributes.createdAt,
      user: m.relationships.sender.data.id.uuid === customerId ? 'consumer' : 'provider',
      userData: users.find(u => u.id.uuid === m.relationships.sender.data.id.uuid),
    }));

  const handleSendMessage = () => {
    mutation.mutate({ chatId, message });
  };

  return (
    <div className={css.messagesContainer}>
      {isMessageEditorOpen && (
        <div className={css.formContainer}>
          <textarea
            placeholder={`Napisz wiadomość do ${providerName}`}
            rows={5}
            style={{ border: 'solid 1px #919191', boxSizing: 'border-box' }}
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <button className={css.sendButton} onClick={handleSendMessage}>
            Wyślij
          </button>
        </div>
      )}
      {isSuccess && <Chat messages={messages} />}
    </div>
  );
};

export default MessagesView;

const formatDate = d => {
  const date = new Date(d);

  const optionsDate = { day: 'numeric', month: 'numeric' };
  const optionsTime = { hour: '2-digit', minute: '2-digit' };

  const formattedDate = date.toLocaleDateString('pl-PL', optionsDate);
  const formattedTime = date.toLocaleTimeString('pl-PL', optionsTime);

  return `${formattedDate} godz. ${formattedTime}`;
};
