import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Section from '../../../../components/Section/Section';
import SlideShow from '../../../../components/SlideShow/SlideShow';

import mecze from '../../images/mecze.png';
import zawody from '../../images/middle.png';
import zajecia_sportowe from '../../images/zajecia_sportowe.png';

import css from '../PopularActivities/PopularActivities.css';

const events = [
  {
    image: zajecia_sportowe,
    imageHeight: 500,
    label: 'Zajęcia sportowe i treningi',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?pub_event_type=activity%2Ctraining',
  },
  {
    image: zawody,
    imageHeight: 500,
    label: 'Zawody',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?pub_event_type=competition',
  },
  {
    image: mecze,
    imageHeight: 500,
    label: 'Mecze',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?pub_event_type=match',
  },
];

const PopularEvents = () => {
  return (
    <Section title="Popularne wydarzenia" isSliderContainer>
      <SlideShow maxVisibleItems={3}>
        {events.map(({ label, url, image, imageHeight }) => (
          <li key={label}>
            <a href={url} className={css.activityLink}>
              <div className={css.imageContainer} style={{ height: `${imageHeight}px` }}>
                <LazyLoadImage alt={label} src={image} height={imageHeight} width="100%" />
              </div>
              <p className={css.activityLabel}>{label}</p>
            </a>
          </li>
        ))}
      </SlideShow>
    </Section>
  );
};

export default PopularEvents;
