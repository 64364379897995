import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import css from './TermsOfService.css';


const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>

      <p>Ostatnia aktualizacja:  9 listopada 2023</p>
      <h2>Postanowienie og&oacute;lne</h2>
      <p>1. Decathlon GO jest serwisem internetowym, kt&oacute;rego właścicielem jest Decathlon sp&oacute;łka z ograniczoną odpowiedzialnością z siedzibą w Warszawie przy ul. Geodezyjnej 76, 03&ndash;290 Warszawa, wpisaną do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego pod numerem 0000007163, prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIV Wydział Gospodarczy Krajowego Rejestru Sądowego, NIP 9511855233, REGON 013102058, posiadającą kapitał zakładowy w kwocie 8 859 465,00 zł, będąca usługodawcą w rozumieniu ustawy o świadczeniu usług drogą elektroniczną (Dz.U. z 2019 r. poz. 123) (dalej Decathlon).</p>
      <p>2. Niniejszy Regulamin określa zasady świadczenia usług drogą elektroniczną przez Decathlon, w szczeg&oacute;lności warunki korzystania przez Użytkownik&oacute;w z serwisu Decathlon GO, jak r&oacute;wnież warunki zawierania i rozwiązywania um&oacute;w o świadczenie usług drogą elektroniczną przez Decathlon.</p>
      <p>3. Każdy Użytkownik zobowiązany jest do przestrzegania postanowień niniejszego Regulaminu od chwili podjęcia czynności zmierzających do skorzystania z Usług oferowanych przez Decathlon w ramach Decathlon GO.</p>
      <p>4. Prawa autorskie do Decathlon GO należą w całości do Decathlon.</p>
      <h2>Definicje</h2>
      <p>Używane w treści niniejszego Regulaminu pojęcia mają znaczenie nadane poniżej, chyba że z treści Regulaminu wyraźnie wynika inne znaczenie:</p>
      <p>1. Administrator albo Decathlon &ndash; sp&oacute;łka Decathlon sp&oacute;łka z ograniczoną odpowiedzialnością z siedzibą w Warszawie przy ul. Geodezyjnej 76, 03&ndash;290 Warszawa, wpisaną do rejestru przedsiębiorc&oacute;w Krajowego Rejestru Sądowego pod numerem 0000007163, prowadzonego przez Sąd Rejonowy dla m.st. Warszawy w Warszawie, XIV &nbsp;Wydział Gospodarczy Krajowego Rejestru Sądowego, NIP 9511855233, REGON 013102058, posiadającą kapitał zakładowy w kwocie 8 859 465,00 zł, będąca usługodawcą w rozumieniu ustawy o świadczeniu usług drogą elektroniczną (Dz.U. z 2019 r. poz. 123).</p>
      <p>2. Platforma albo Decathlon GO &ndash; prowadzony przez Administratora serwis internetowy, dostępny w domenie internetowej www.go.decathlon.pl, służący do publikowania Ogłoszeń Wydarzeń oraz zawierania um&oacute;w dotyczących usług dostępnych w ramach Decathlon GO.</p>
      <p>3. Użytkownik &ndash; osoba fizyczna, osoba prawna lub jednostka organizacyjna nieposiadająca osobowości prawnej, kt&oacute;ra zawarła umowę o świadczenie usług drogą elektroniczną przez Administratora w ramach Decathlon GO..</p>
      <p>4. Organizator lub Sprzedający &ndash; każda osoba fizyczna posiadająca pełną zdolność do czynności prawnych lub osoba prawna będąca organizatorem Wydarzenia.</p>
      <p>5. Wydarzenie &ndash; zorganizowane przez Organizatora przedsięwzięcie, w szczeg&oacute;lności wydarzenia, zajęcia sportowe bądź inne aktywności mające charakter sportowy. Wydarzenie może być bezpłatne lub odpłatne.</p>
      <p>6. Kupujący lub Klient &ndash; Użytkownik podejmujący działania zmierzające do nabycia lub nabywający dostęp do Wydarzenia w ramach Decathlon GO.</p>
      <p>7. Oferta &ndash; propozycja zawarcia umowy sprzedaży dostępu do Wydarzenia, na warunkach określonych przez Sprzedającego, zawierających w szczeg&oacute;lności cenę i opis, zakomunikowana za pomocą funkcjonalności dostępnych w ramach Decathlon GO.</p>
      <p>8. Ogłoszenie &ndash; informacja dostępna w serwisie Decathlon GO dotycząca Wydarzenia.</p>
      <p>9. Konto &ndash;profil Użytkownika na platformie Decathlon GO, identyfikowany za pomocą adresu e-mail, umożliwiający dokonywania określonych działań na Platformie.&nbsp;</p>
      <p>10. Rejestracja &ndash; procedura zakładania Konta.</p>
      <p>11. Regulamin &ndash; niniejszy Regulamin Decathlon GO określający zasady świadczenia usług drogą elektroniczną przez Decathlon.</p>
      <p>12. Opłata serwisowa &ndash; opłata związana z kosztami obsługi  transakcji dokonywanej  przez Użytkownika w celu zakupu Wydarzenia na stronie  <a href='https://go.decathlon.pl'>https://go.decathlon.pl/</a> , w wysokości określonej przy podsumowaniu zamówienia.</p>
      <h2>Zakres usług Decathlon GO</h2>
      <p>Decathlon Sp. Z o.o. (właściciel serwisu Decathlon GO) zobowiązuje się do świadczenia drogą elektroniczną: Usług dla Organizator&oacute;w oraz Usług dla Kupujących dostęp Wydarzenie. Korzystając z Platformy Użytkownik zawiera z Decathlon umowę o świadczenie usług drogą elektroniczną. Dokonując Rejestracji na Wydarzenie Użytkownik zawiera umowę o świadczenie usług drogą elektroniczną z Organizatorem Wydarzenia.</p>
      <p>1. Decathlon oferuje Organizatorom następujące usługi:</p>
      <p>1) udostępnienie Platformy wspierającej następujące procesy: możliwość zapisu na wydarzenie, promocję wydarzeń w Internecie przez Organizatora; obsługę techniczną sprzedaży;</p>
      <p>2) pośredniczenie w sprzedaży dostępu do Wydarzeń na rzecz Organizatora.</p>
      <p>2. Decathlon w ramach Platformy oferuje Kupującym Wydarzenie następujące usługi:</p>
      <p>1) możliwość zakupu dostępu do Wydarzenia Organizatora na stronie internetowej www.go.decathlon.pl,</p>
      <p>2) możliwość rejestracji na Wydarzenie Organizatora na stronie internetowej,</p>
      <p>3) możliwość otrzymywania spersonalizowanych propozycji przyszłych Wydarzeń, w oparciu o aktywność na Platformie</p>
      <p>&nbsp;Konto na platformie</p>
      <p>1. Osoby fizyczne, a także osoby prawne oraz jednostki organizacyjne nieposiadające osobowości prawnej, w celu Rejestracji, powinny wypełnić formularz rejestracyjny poprzez podanie: adresu e&ndash;mail (login) oraz hasła.</p>
      <p>2. Rejestracji osoby prawnej oraz jednostki organizacyjnej nieposiadającej osobowości prawnej, a także wszystkich dalszych czynności dotyczących tych podmiot&oacute;w na Platformie, może dokonać wyłącznie osoba, kt&oacute;ra jest umocowana do dokonywania w jej imieniu wszelkich czynności związanych z Rejestracją.</p>
      <p>3. Zawarcie umowy pomiędzy Użytkownikiem a Decathlon, kt&oacute;rej przedmiotem są usługi świadczone przez Decathlon w ramach Decathlon GO, na warunkach określonych w Regulaminie, następuje w chwili przyjęcia przez Użytkownika oferty Decathlon dotyczącej zakresu usług wskazanego w &sect; 3 niniejszego Regulaminu, dokonanej poprzez zaznaczenie pola akceptacji Regulaminu w formularzu rejestracji.</p>
      <p>4. W wyniku prawidłowej Rejestracji Decathlon tworzy dla Użytkownika Konto przypisane do loginu (adres email) podanego w formularzu rejestracyjnym. Użytkownik uzyskuje dostęp do Konta po podaniu na stronie internetowej Decathlon GO loginu i hasła (logowanie).</p>
      <p>5. W celu prowadzenia sprzedaży Organizator zobowiązany jest podać po dokonaniu rejestracji następujące dane: nazwę firmy, adres jego siedziby, telefon, numer NIP oraz numer rachunku bankowego, na kt&oacute;ry mają być przelewane środki za sprzedane Wydarzenia po zakończeniu Wydarzenia.</p>
      <p>6. Konto zawiera dane Użytkownika podane przez niego w formularzu rejestracyjnym. W przypadku p&oacute;źniejszej zmiany jakichkolwiek z tych danych Użytkownik powinien niezwłocznie zaktualizować je, korzystając z odpowiedniego formularza dostępnego na stronie internetowej Decathlon GO. Nie jest możliwe usunięcie przez Użytkownika jakichkolwiek danych w trakcie korzystania z usług Decathlon GO (w szczeg&oacute;lności od momentu uruchomienia strony Wydarzenia do zakończenia Wydarzenia i rozliczenia wszelkich płatności).</p>
      <p>7. Użytkownik może posiadać więcej niż jedno Konto, jednakże nie może ich wykorzystywać do dokonywania jakichkolwiek czynności stanowiących naruszenie postanowień niniejszego Regulaminu.</p>
      <p>8. Użytkownikowi nie wolno korzystać z Kont innych Użytkownik&oacute;w oraz udostępniać swojego Konta innym osobom, z wyjątkiem przypadk&oacute;w udostępnienia Konta osobom należycie umocowanym przez Użytkownika do działania w jego imieniu. Użytkownik powinien zachować w tajemnicy hasło do Konta. Konta są niezbywalne.</p>
      <p>9. Użytkownik otrzymuje możliwość organizacji Wydarzeń.</p>
      <p>10. Decathlon pobiera opłaty za usługi udostępnione na stronie Decathlon GO, z zastrzeżeniem usług bezpłatnych. Decathlon przed zawarciem umowy obejmującej odpłatne usługi poinformuje Użytkownika o wysokości opłaty. Użytkownik akceptując niniejszy Regulamin, wyraża zgodę na wszelkie zobowiązania o charakterze pieniężnym wynikające z faktu skorzystania z określonych usług udostępnionych w ramach Decathlon GO opisanych w Regulaminie.</p>
      <p>11. Decathlon będzie dążyć do utrzymania dostępności serwisu Decathlon GO bez przerw i zakł&oacute;ceń z uwzględnieniem zasad przewidzianych niniejszym Regulaminem.</p>
      <p>12. Decathlon nie jest stroną um&oacute;w zawieranych pomiędzy Użytkownikami (Organizatorem a Kupującym dostęp do Wydarzenia). Użytkownicy są wyłącznie odpowiedzialni za wykonanie um&oacute;w, kt&oacute;re zawarli ze sobą przy wykorzystaniu serwisu Decathlon GO.</p>
      <p>13. Decathlon nie ponosi odpowiedzialności za rzeczywistą tożsamość Użytkownika. Użytkownik jest zobowiązany do posługiwania się prawdziwymi danymi.&nbsp;</p>
      <p>14. Użytkownik jest zobowiązany:</p>
      <p>1) do dostarczenia zgodnych z prawdą i nie wprowadzających w błąd informacji w trakcie Rejestracji i korzystania z Decathlon GO oraz w komunikacji z innymi Użytkownikami.</p>
      <p>2) do przestrzegania obowiązujących przepis&oacute;w prawa oraz poszanowania wszystkich praw os&oacute;b trzecich, w szczeg&oacute;lności, użytkownik nie może używać obraźliwych lub wprowadzających w błąd treści, niezależnie od tego do kogo są one kierowane, umieszczać na Platformie jakichkolwiek materiał&oacute;w pornograficznych lub treści, kt&oacute;re naruszają obowiązujące przepisy prawa, przesyłać niezam&oacute;wionej informacji handlowej do Użytkownik&oacute;w.</p>
      <p>15. Użytkownik nie jest uprawniony do:</p>
      <p>3) wykonywania wszelkich działań, kt&oacute;re mogą wpływać na funkcjonowanie infrastruktury Decathlon GO, zwłaszcza działań, kt&oacute;re mogą spowodować zakł&oacute;cenie działania wspomnianej infrastruktury;</p>
      <p>4) wykorzystywania jakichkolwiek treści stanowiących własność Decathlon w celach innych niż korzystanie z Usług oferowany w ramach Decathlon GO,</p>
      <p>5) wykorzystywania jakichkolwiek treści innych Użytkownik&oacute;w bez ich wyraźnej zgody.</p>
      <p>16. Zakazane jest dostarczanie przez Użytkownika treści o charakterze bezprawnym. W razie otrzymania przez Decathlon urzędowego zawiadomienia lub wiarygodnej wiadomości o bezprawnym charakterze przechowywanych danych dostarczonych przez Użytkownika, Decathlon może poinformować o tym Użytkownika i zablokować dostęp do tych danych. Decathlon nie będzie ponosić odpowiedzialności względem Użytkownika za szkodę powstałą w wyniku uniemożliwienia dostępu do danych o treści bezprawnej.</p>
      <p>17. Użytkownik może rozwiązać umowę z Decathlon (dotyczącą określonego Konta) bez zachowania wypowiedzenia wysyłając zgłoszenie na adres go.kontakt@decathlon.com.</p>
      <p>18. Z ważnych przyczyn, w szczeg&oacute;lności gdy Użytkownik, po uprzednim wezwaniu przez Decathlon nie spełnia swoich zobowiązań umownych wobec Decathlon umowa może zostać rozwiązana przez Decathlon za wypowiedzeniem, z zachowaniem siedmiodniowego terminu wypowiedzenia.</p>
      <p>19. Decathlon ma prawo do rozwiązania umowy z Użytkownikiem ze skutkiem natychmiastowym lub do zablokowania Konta w przypadku naruszenia istotnych postanowień niniejszego Regulaminu.</p>
      <h2>Dodawanie wydarzeń</h2>
      <p>1. Możliwość dodawania Wydarzeń posiada każdy Użytkownik.</p>
      <p>2. W procesie dodawania Wydarzenia Użytkownik podaje niezbędne informacje, kt&oacute;re po zapisaniu będą widoczne w wersji publicznej serwisu Decathlon GO.</p>
      <p>3. Dodawanie Wydarzeń nie może naruszać postanowień niniejszego Regulaminu.</p>
      <p>5. Administrator ma prawo usunąć zamieszczone przez Użytkownika Wydarzenia w przypadku, gdy wspomniane materiały zostały zdublowane. O tym, kt&oacute;re Wydarzenie zostanie usunięte decyduje data dodania lub kompletność informacji o wydarzeniu.</p>
      <p>6. Jeśli przez kolejne 3 miesiące w opublikowanym przez Organizatora wydarzeniu nie dokonano ani 1 (jednego) zam&oacute;wienia , Decathlon ma prawo usunąć wydarzenie ze strony Decathlon GO.</p>
      <p>Zakup wydarzenia</p>
      <ol>
        <li>
          <p>&nbsp;Użytkownik zainteresowany Wydarzeniem oferowanym przez Organizatora składa zam&oacute;wienie mające charakter oświadczenia o przyjęciu oferty poprzez dokonanie rezerwacji na wydarzenie.</p>
        </li>
        <li>
          <p>W przypadku konieczności uzyskania szczeg&oacute;łowych informacji na potrzeby organizacji Wydarzenia, Organizator może udostępnić Uczestnikowi do uzupełnienia dodatkowe formularze.&nbsp;</p>
        </li>
        <li>
          <p>Jeżeli zam&oacute;wienie, złożone przez Klienta po skorzystaniu z narzędzia Wyślij zgłoszenie, zostanie przyjęte przez Sprzedającego, strony zawierają umowę na warunkach określonych w ofercie.</p>
        </li>
        <li>
          <p>&nbsp;Zapewnienie spełnienia szczeg&oacute;lnych wymagań przewidzianych przepisami prawa, np. zachowania formy pisemnej bądź innej formy szczeg&oacute;lnej dla ważności umowy, należy wyłącznie do stron umowy (Organizatora i Kupującego Wydarzenie).</p>
        </li>
        <li>
          <p>Potwierdzeniem nabycia odpłatnego bądź bezpłatnego dostępu Wydarzenia będzie informacja wysłana na adres e&ndash;mail, kt&oacute;ry został wskazany podczas rejestracji, jak r&oacute;wnież informacja na Koncie Użytkownika.</p>
        </li>
      </ol>
      <h2>Płatności</h2>
      <p>1. Cena Wydarzenia ustalana jest przez Organizatora.</p>
      <p>2. Decathlon GO umożliwia dokonanie płatności za pośrednictwem serwisu Stripe (zgodnie z regulaminem tego&nbsp;<a href="https://stripe.com/en-pl/privacy">https://stripe.com/en-pl/privacy</a>&nbsp; serwisu).</p>
      <p>3. W przypadku Wydarzeń płatnych, Decathlon pobiera opłatę serwisową, która wyrażona jest w odpowiedniej kwocie całkowitej ceny zakupu biletu za wydarzenie lub opłaty prowadzonej przez Organizatora. O wysokości opłaty serwisowej Klient zostaje poinformowany przed umożliwieniem mu opłacenia zakupu.</p>
      <p>4. Decathlon, za pośrednictwem Stripe przelewa otrzymaną sumę środk&oacute;w za sprzedaż Wydarzeń Organizatorowi pomniejszoną o opłaty należne Decathlon, w ciągu 7 dni po zakończeniu Wydarzenia. Środki są przelewane na rachunek bankowy Organizatora znajdujący się w Polsce (siedziba lub oddział w Polsce) na numer rachunku bankowego w formacie NRB/IBAN.</p>
      <h2>Faktura</h2>
      <p>1. Decathlon, na żądanie Organizatora, po zakończeniu Wydarzenia wystawia Organizatorowi fakturę VAT na kwotę prowizji za świadczone Usługi.</p>
      <p>2. Wygenerowana faktura VAT w postaci pliku PDF zostanie przesłana w formie elektronicznej do Organizatora na wskazany przy rejestracji adres e&ndash;mail.</p>
      <h2>Odwołanie, zmiana czasu, miejsca i programu wydarzenia</h2>
      <p>1. W przypadku odwołania Wydarzenia, środki wpłacone przez Kupującego są mu zwracane w terminie . W przypadku zmiany daty lub miejsca odbycia się Wydarzenia, o ile Kupujący zażąda zwrotu środk&oacute;w..</p>
      <p>2. W przypadku odwołania wydarzenia przez Organizatora, Decathlon przelewa otrzymaną sumę środk&oacute;w za sprzedaż Wydarzeń Organizatorowi pomniejszoną o prowizję należną Decathlon, w ciągu 7 dni po odwołaniu Wydarzenia.</p>
      <h2>Własność intelektualna</h2>
      <p>1. Wszelkie materiały, w tym elementy grafiki, układ i kompozycja tych element&oacute;w (tzw. layout), znaki towarowe oraz inne informacje, dostępne na stronach oraz podstronach Platformy stanowią przedmiot praw Decathlon lub Użytkownik&oacute;w. Wskazane elementy mogą stanowić przedmiot autorskich praw majątkowych, praw własności przemysłowej, w tym praw z rejestracji znak&oacute;w towarowych oraz praw do baz danych i jako takie korzystają z ustawowej ochrony prawnej. Pobieranie lub wykorzystywanie w jakimkolwiek zakresie dostępnych w ramach Platformy materiał&oacute;w wymaga każdorazowo zgody uprawnionego i nie może naruszać postanowień Regulaminu oraz powszechnie obowiązującego prawa, jak r&oacute;wnież nie może naruszać praw Decathlon lub Użytkownik&oacute;w. Decathlon nie ponosi odpowiedzialności za materiały umieszczane na Platformie przez Użytkownik&oacute;w.</p>
      <p>2. Każdy z Użytkownik&oacute;w przyjmuje do wiadomości, że zamieszczone przez niego treści, w tym zdjęcia, inne materiały stanowiące utwory lub przedmiot innych praw wyłącznych Użytkownika mogą być wykorzystywane przez Administratora w celu wykonania zawartej z Administratorem umowy o świadczenie usług drogą elektroniczną przez czas nieoznaczony na podstawie niewyłącznej, nieodpłatnej licencji na wykorzystywanie takich materiał&oacute;w przez Administratora uprawniającej do:</p>
      <p>1) w zakresie utrwalania i zwielokrotniania materiał&oacute;w &ndash; wytwarzanie określoną techniką egzemplarzy materiał&oacute;w, w tym techniką drukarską, reprograficzną, zapisu magnetycznego oraz techniką cyfrową;</p>
      <p>2) w zakresie obrotu oryginałem albo egzemplarzami, na kt&oacute;rych materiały utrwalono &ndash; wprowadzanie do obrotu, użyczenie lub najem oryginału albo egzemplarzy;</p>
      <p>3) w zakresie rozpowszechniania materiał&oacute;w w spos&oacute;b inny niż określony w pkt. 2 powyżej &ndash; publiczne wykonanie, wystawienie, wyświetlenie, odtworzenie oraz nadawanie i reemitowanie, a także publiczne udostępnianie materiał&oacute;w w taki spos&oacute;b, aby każdy m&oacute;gł mieć do nich dostęp w miejscu i w czasie przez siebie wybranym.</p>
      <p>3. Poprzez umieszczenie materiał&oacute;w w serwisie Decathlon GO, Użytkownik nieodpłatnie przenosi na Administratora prawo zezwalania na wykonywanie zależnego prawa autorskiego do materiał&oacute;w umieszczonych w serwisie Decathlon GO przez Użytkownika.</p>
      <p>4. Użytkownik zamieszczający zdjęcia lub inne materiały przyjmuje do wiadomości, że ich rozmiar może być zmieniony celem dostosowania do wymagań Platformy.</p>
      <h2>Odpowiedzialność</h2>
      <p>1. Odpowiedzialność za błędne wpisanie przez Użytkownika danych na Platformie, w szczeg&oacute;lności danych adresowych, ponosi Użytkownik. W przypadku, gdyby Użytkownik zauważył błąd w podanych przez siebie danych po potwierdzeniu zam&oacute;wienia, powinien skontaktować się z Decathlon GO.</p>
      <p>2. Całkowita odpowiedzialność dotycząca Wydarzenia leży wyłącznie po stronie Organizatora.</p>
      <p>3. Informacje na temat Wydarzeń, ilości dostępnych miejsc, ceny Wydarzenia i formy płatności są określane przez Organizatora. Decathlon nie ma wpływu na jakość tych informacji.</p>
      <p>4. Nazwę, adres oraz inne dane identyfikujące Organizatora, Kupujący Wydarzenie może uzyskać na Platformie.</p>
      <p>5. Decathlon nie ponosi odpowiedzialności za zobowiązania Organizatora w stosunku do Kupujących Wydarzenie.</p>
      <p>6. Administrator nie odpowiada za informacje opublikowane na Platformie przez Organizatora.</p>
      <h2>Kontakt i postępowanie reklamacyjne</h2>
      <p>1. Użytkownik może kontaktować się z Administratorem w sprawie usług świadczonych drogą elektroniczną na podstawie niniejszego Regulaminu w formie:</p>
      <p>1) pisemnej na adres: Decathlon sp. z o.o., ul. Geodezyjnej 76, 03&ndash;290 Warszawa,</p>
      <p>2) elektronicznej przy użyciu danych znajdujących się w zakładce Kontakt na stronie gł&oacute;wnej serwisu Decathlon GO.</p>
      <p>2. Administrator może kontaktować się z Użytkownikiem w formie:</p>
      <p>1) pisemnej na wskazany przez Użytkownika adres korespondencyjny</p>
      <p>2) elektronicznej na wskazany przez Użytkownika adres e&ndash;mail,</p>
      <p>3) telefonicznej na wskazany przez Użytkownika numer telefonu.</p>
      <p>3. W przypadku, gdy zdaniem Użytkownika usługi świadczone na podstawie Regulaminu nie są realizowane przez Administratora lub są realizowane niezgodnie z postanowieniami Regulaminu, może on złożyć zastrzeżenia w spos&oacute;b określony w Regulaminie (Reklamacja).</p>
      <p>4. Reklamację można złożyć w formie elektronicznej za pomocą formularza kontaktowego lub pisemnej. Reklamacja powinna zawierać co najmniej nazwę, pod jaką Użytkownik występuje, opis zgłaszanych zastrzeżeń oraz wskazanie proponowanego sposobu rozstrzygnięcia Reklamacji.</p>
      <p>5. Administrator rozpoznaje Reklamację w terminie 14 dni od daty jej otrzymania. W przypadku, gdy Reklamacja nie zawiera informacji koniecznych do jej rozpatrzenia, Administrator zwr&oacute;ci się do składającego Reklamację o jej uzupełnienie w niezbędnym zakresie, a termin 14 dni biegnie w&oacute;wczas od dnia doręczenia uzupełnionej Reklamacji.</p>
      <p>6. Odpowiedź na Reklamację wysyłana jest wyłącznie na adres e&ndash;mail przypisany do Konta danego Użytkownika. W szczeg&oacute;lnie uzasadnionych przypadkach Administrator może wysłać odpowiedź na inny, wskazany przez składającego Reklamację, adres e&ndash;mail, kt&oacute;ry nie jest przypisany do Konta Użytkownika, lub pisemnie na adres wskazany w Reklamacji.</p>
      <p>7. Użytkownik ma prawo odwołać się od decyzji podjętej przez Administratora w ramach Reklamacji (Odwołanie).</p>
      <h2>Zmiany regulaminu</h2>
      <p>1. Administrator może w przypadku zmiany przepis&oacute;w prawa lub w celu usprawnienia działania serwisu Decathlon GO, a także w celu poprawy ochrony prywatności Użytkownik&oacute;w i zapobiegania nadużyciom, zmienić Regulamin lub uruchomić nową wersję usług świadczonych w ramach Decathlon GO.</p>
      <p>2. Użytkownik zostanie powiadomiony o fakcie i treści zmian lub nowej wersji usług świadczonych w ramach Decathlon GO.</p>
      <p>3. Zmiana Regulaminu wchodzi w życie nie wcześniej niż w terminie 10 dni od dnia poinformowania o zmianie Regulaminu.</p>
      <p>4. Użytkownik może złożyć oświadczenie o akceptacji lub braku akceptacji nowego Regulaminu i rozwiązać umowę zgodnie z &sect; 4 ust. 17 Regulaminu.</p>



    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
