import React, { useState, useEffect } from "react";
import FormItem from "../../../components/FormItem";
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { StrictModeDroppable } from './DroppableStrictMode';
import { useFormContext } from "../../context";

import css from './styles.css';

const validateFiles = (files) => {
    return (
        files.length > 0 &&
        Array.from(files).every(
            file => file.size < 5 * 1024 * 1024 && ['image/jpeg', 'image/png', 'image/gif'].includes(file.type)
        )
    );
};

const EventPictures = ({ register, setValue, errors }) => {
    const { formData } = useFormContext();
    const [files, setFiles] = useState(typeof formData.generalInfo.files === 'string' ? JSON.parse(formData.generalInfo.files) : formData.generalInfo.files || []);


    useEffect(() => {
        setFiles(typeof formData.generalInfo.files === 'string' ? JSON.parse(formData.generalInfo.files) : formData.generalInfo.files || []);
    }, [formData])

    useEffect(() => {
        setValue('files', files);
    }, [files])


    const handleFilesChange = (event) => {
        const newFiles = event.target.files;

        if (validateFiles(newFiles)) {
            const filesArray = Array.from(newFiles).map((file, index) => ({
                id: `item-${file.name}`,
                content: URL.createObjectURL(file),
                name: file.name,
                type: file.type,
                file: file
            }));
            setFiles(prev => {
                return !!prev.length ? [...prev, ...filesArray] : filesArray
            });

        } else {
            setFiles([]);
        }
    };

    const onDragEnd = (result) => {
        if (!result.destination) return;
        const items = Array.from((typeof files === 'string' ? JSON.parse(files) : files));
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        setFiles(items);
    };

    const removeFile = (e, id) => {
        e.preventDefault();
        setFiles((typeof files === 'string' ? JSON.parse(files) : files).filter(file => (typeof file.id === 'string' ? file.id : file.id.uuid) !== id));
    };

    return (
        <FormItem label={'Zdjęcia wydarzenia'}>
            <input
                style={{ paddingTop: '8px', fontWeight: '400', border: 'none' }}
                type="file"
                multiple
                onChange={handleFilesChange}
                accept=".jpg, .jpeg, .png"
            />
            <p style={{ fontSize: '14px' }} >.JPG or .PNG. Max 5 MB</p>
            {errors.files && (
                <p style={{ color: '#f00' }} role="alert">
                    {errors.files.message}
                </p>
            )}

            <input
                type="hidden"
                {...register("files", {
                    validate: (value) => {
                        const filesArray = (value);
                        return filesArray.length > 0 || "Co najmniej jeden obrazek jest wymagany.";
                    }
                })}
            />

            <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="droppable-images" direction="both">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(2, 1fr)',
                                gap: '10px'
                            }}
                        >
                            {(typeof files === 'string' ? JSON.parse(files) : files).map((file, index) => (
                                <>

                                    <Draggable key={typeof file.id === 'string' ? file.id : file.id.uuid} draggableId={typeof file.id === 'string' ? file.id : file.id.uuid} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={{
                                                    position: 'relative',
                                                    cursor: 'move',
                                                    ...provided.draggableProps.style
                                                }}
                                                key={typeof file.id === 'string' ? file.id : file.id.uuid}
                                            >
                                                <img src={file.content || file.attributes.variants['scaled-large'].url} alt={`Preview ${index + 1}`} className={css.eventPicturesImage} />
                                                <button onClick={(e) => removeFile(e, typeof file.id === 'string' ? file.id : file.id.uuid)} className={css.eventPicturesButton}>X</button>
                                            </div>
                                        )}
                                    </Draggable>
                                </>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </StrictModeDroppable>
            </DragDropContext>
        </FormItem>
    );
};

export default EventPictures;
