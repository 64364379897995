import en from 'react-intl/locale-data/en';
import { addMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { parse } from '../../../util/urlHelpers';

// ================ Action types ================ //

export const FETCH_CONSOLE_LISTINGS_REQUEST = 'app/ConsoleListingPage/FETCH_CONSOLE_LISTINGS_REQUEST';
export const FETCH_CONSOLE_LISTINGS_SUCCESS = 'app/ConsoleListingPage/FETCH_CONSOLE_LISTINGS_SUCCESS';
export const FETCH_CONSOLE_LISTINGS_ERROR = 'app/ConsoleListingPage/FETCH_CONSOLE_LISTINGS_ERROR';

export const UPDATE_USER_REQUEST = 'app/ConsoleListingPage/UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'app/ConsoleListingPage/UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR = 'app/ConsoleListingPage/UPDATE_USER_ERROR';

// ================ Reducer ================ //

const initialState = {
  fetchInProgress: false,
  fetchListingListError: null,
  pagination: null,
  listingList: [],
  updateUserInProgress: false,
  updateUserError: null,
};

export default function consoleUserReducer(state = initialState, action = {}) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONSOLE_LISTINGS_REQUEST:
      return { ...state, fetchInProgress: true, fetchListingListError: null };
    case FETCH_CONSOLE_LISTINGS_SUCCESS: {
      return {
        ...state,
        fetchInProgress: false,
        listingList: payload.data.data,
        pagination: payload.data.meta,
      };
    }
    case FETCH_CONSOLE_LISTINGS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchListingListError: payload };
      case UPDATE_USER_REQUEST:
        return { ...state, updateUserInProgress: true, updateUserError: null };
      case UPDATE_USER_SUCCESS: {
        return {
          ...state,
          updateUserInProgress: false,
          updateUserError: payload.data.data,
        };
      }
      case UPDATE_USER_ERROR:
        console.error(payload); // eslint-disable-line
        return { ...state, updateUserInProgress: false, updateUserError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

const fetchListingListRequest = () => ({ type: FETCH_CONSOLE_LISTINGS_REQUEST });

const fetchListingListSuccess = response => ({
  type: FETCH_CONSOLE_LISTINGS_SUCCESS,
  payload: response,
});

const fetchListingListError = e => ({
  type: FETCH_CONSOLE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

const updateUserRequest = () => ({ type: UPDATE_USER_REQUEST });

const updateUserSuccess = response => ({
  type: UPDATE_USER_SUCCESS,
  payload: response,
});

const updateUserError = e => ({
  type: UPDATE_USER_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

const CONSOLE_PAGE_SIZE = 20;

export const loadData = (params ,search) => (dispatch, getState, sdk) => {
  const { page = 1, keywords, entityId, authorId } = parse(search);
  const paginationParams = {
    page,
    per_page: CONSOLE_PAGE_SIZE,
  };

  const authorIdMaybe = authorId ? { authorId } : {};

  const queryParams = {
    ...paginationParams,
    keywords,
    include: ['images', 'author'],
    sort: '-createdAt',
    state: 'all',
    hidden: 'all',
    ...authorIdMaybe
  };

  dispatch(fetchListingListRequest());

  if (entityId) {
    return sdk.goSdk.listings
      .show({ id: entityId })
      .then(response => {
        const res = {
          ...response,
          data: {
            ...response.data,
            data: [response.data.data],
          },
        }
        dispatch(addMarketplaceEntities(res));
        dispatch(fetchListingListSuccess(res));
        return res;
      })
      .catch(e => {
        dispatch(fetchListingListError(e));
        throw e;
      });
  }

  return sdk.goSdk.listings
    .query(queryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchListingListSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(fetchListingListError(e));
      throw e;
    });
};

export const updateListing = data => {
  return (dispatch, getState, sdk) => {
    return sdk.goSdk.listings
      .update(data)
      .then(response => {
        dispatch(addMarketplaceEntities(response));
        return response;
      })
      .catch(e => {
        dispatch(updateUserError(e));
        throw e;
      });
  };
};
