import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { debounce } from '../../libs';
import IconChevronLeft from '../IconChevronLeft/IconChevronLeft';
import IconChevronRight from '../IconChevronRight/IconChevronRight';

import css from './SlideShow.css';

function SlideShow({ maxVisibleItems = 4, children }) {
  const sliderRef = useRef(null);

  const [sliderState, setSliderState] = useState({
    scrollLeft: 0,
    width: 0,
    scrollWidth: 0,
    paddingInline: 0,
    productWidth: 0,
    showControls: false,
  });

  useEffect(() => {
    const slider = sliderRef.current;

    if (slider) {
      const updateDimensions = () => {
        const padding = parseFloat(getComputedStyle(slider).paddingInline);
        const gap = parseFloat(getComputedStyle(slider).gap);
        const productWidth = slider.firstChild.clientWidth;
        const visibleProducts = Math.round(
          (slider.clientWidth - padding * 2 + gap) / (productWidth + gap)
        );

        setSliderState({
          scrollLeft: slider.scrollLeft,
          width: slider.clientWidth - padding * 2,
          scrollWidth: slider.scrollWidth,
          paddingInline: padding,
          productWidth: slider.firstChild.clientWidth,
          showControls: children.length > visibleProducts && visibleProducts > 1,
        });
      };

      updateDimensions();

      const handleScroll = () => {
        setSliderState(prevState => ({
          ...prevState,
          scrollLeft: slider.scrollLeft,
        }));
      };

      slider.addEventListener('scrollend', handleScroll);
      window.addEventListener('resize', updateDimensions);

      return () => {
        slider.removeEventListener('scrollend', handleScroll);
        window.removeEventListener('resize', updateDimensions);
      };
    }
  }, []);

  const debouncedHandleLeftClick = debounce(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: sliderState.scrollLeft - sliderState.width,
        behavior: 'smooth',
      });
    }
  }, 200);

  const debouncedHandleRightClick = debounce(() => {
    if (sliderRef.current) {
      sliderRef.current.scrollTo({
        left: sliderState.scrollLeft + sliderState.width,
        behavior: 'smooth',
      });
    }
  }, 200);

  const { scrollLeft, width, scrollWidth, paddingInline, showControls } = sliderState;

  return (
    <>
      <ul
        className={css.slider}
        style={{ '--itemsCount': children.length, '--maxVisibleItems': maxVisibleItems }}
        ref={sliderRef}
      >
        {children}
      </ul>

      <div className={css.controls} style={{ display: showControls ? 'flex' : 'none' }}>
        <button
          aria-label="strzałka w lewo"
          title="przewiń polecane wydarzenia w lewo"
          onClick={debouncedHandleLeftClick}
          disabled={scrollLeft === 0}
        >
          <IconChevronLeft aria-hidden="true" focusable="false" />
        </button>
        <button
          aria-label="strzałka w prawo"
          title="przewiń polecane wydarzenia w prawo"
          onClick={debouncedHandleRightClick}
          disabled={scrollLeft >= scrollWidth - width - 2 * paddingInline}
        >
          <IconChevronRight aria-hidden="true" focusable="false" />
        </button>
      </div>
    </>
  );
}

const { node, number } = PropTypes;

SlideShow.propTypes = {
  children: node,
  maxVisibleItems: number,
};

export default SlideShow;
