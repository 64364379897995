import React from 'react';
import PropTypes from 'prop-types';

const IconChevronBigLeft = props => {
  const { className, ...rest } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="#1F1F1F" fillRule="evenodd" d="M9.876 12.431L16.613 5 17.778 6.284 9.876 15 1.975 6.284 3.14 5z" transform="rotate(90 10.315 10.438)"/>
    </svg>
  );
};

const { string } = PropTypes;

IconChevronBigLeft.defaultProps = {
  className: null,
};

IconChevronBigLeft.propTypes = {
  className: string,
};

export default IconChevronBigLeft;