import React from 'react';
import PropTypes from 'prop-types';

const IconAddBig = props => {
  const { className } = props;
  return (   
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="#607FF2" d="M12 4c.552 0 1 .448 1 1v6h6c.552 0 1 .448 1 1s-.448 1-1 1h-6v6c0 .552-.448 1-1 1s-1-.448-1-1v-6H5c-.552 0-1-.448-1-1s.448-1 1-1h6V5c0-.552.448-1 1-1z"/>
    </svg>
  );
};

IconAddBig.defaultProps = { className: null };

const { string } = PropTypes;

IconAddBig.propTypes = {
  className: string,
};

export default IconAddBig;
