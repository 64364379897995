import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import classNames from 'classnames';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Logo,
  NamedLink,
  Page,
} from '../../../components';
import config from '../../../config';
import { isScrollingDisabled } from '../../../ducks/UI.duck';
import { withViewport } from '../../../util/contextHelpers';
import YoutubePlayer from '../../ListingPage/Youtube';
import ReferencingPostsSection from '../components/ReferencingPostsSection';
import Chevron from './chevron.svg';
import { loadData } from './PostPage.duck';
import RentAd from './RentAd';
import SharingButtons from './SharingButtons';

import FooterV2 from '../../../components/Footer/FooterV2';
import css from './PostPage.css';

function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

const format = { year: 'numeric', month: 'long', day: 'numeric' };

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

const formatDate = publishingDate => {
  if (!publishingDate) return null;
  const date = new Date(publishingDate);
  return `${('0' + date.getDate()).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(
    -2
  )}.${date.getFullYear()}`;
};

export class ProfilePageComponent extends Component {
  render() {
    const {
      assets,
      inlineReferences,
      internalLinks,
      label,
      mainImage,
      metaTitle,
      metaDescription,
      minutesToRead,
      publishingDate,
      referencingPosts = [],
      slug,
      title,
      text,
    } = this.props.post;

    const options = {
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
          return (
            <a href={node.data.uri} target="_blank">
              {children}
            </a>
          );
        },
        [INLINES.ENTRY_HYPERLINK]: (node, children) => {
          // If you are using contenful.js sdk, the referenced entry is resolved
          // automatically and is available at `node.data.target`.
          const referencedEntry =
            inlineReferences.length > 0
              ? inlineReferences.filter(iR => iR.sys.id === node.data.target.sys.id)[0]
              : null;

          if (!referencedEntry) return null;

          if (referencedEntry.sys.contentType.sys.id === 'internalPage')
            return <Link to={`/${referencedEntry.fields.slug}`}>{children}</Link>;

          return <Link to={`/blog/${referencedEntry.fields.slug}`}>{children}</Link>;
        },
        [BLOCKS.EMBEDDED_ASSET]: node => {
          const img = assets.filter(iR => iR.sys.id === node.data.target.sys.id)[0];
          return <img src={img.fields.file.url} alt={img.fields.title} />;
        },
        [BLOCKS.EMBEDDED_ENTRY]: node => {
          const referencedVideo =
            inlineReferences.length > 0
              ? inlineReferences.filter(iR => iR.sys.id === node.data.target.sys.id)[0]
              : null;
          if (!referencedVideo || referencedVideo.sys.contentType.sys.id !== 'youtubeVideo')
            return null;

          const youtubeId = youtube_parser(referencedVideo.fields.url);
          if (!youtubeId) return null;

          return (
            <div className={css.youtubeVideo}>
              <YoutubePlayer
                youtubeId={youtubeId}
                autoplay={0}
                containerClassName={css.videoContainer}
              />
            </div>
          );
        },
      },
    };

    const products =
      internalLinks && internalLinks.ecommerce
        ? config.sportsConfig[internalLinks.ecommerce].externalProducts
        : [
            ...config.sportsConfig['yoga'].externalProducts,
            ...config.sportsConfig['running'].externalProducts,
          ];

    const { intl, scrollingDisabled, viewport } = this.props;
    const isMobileShow = viewport.width < MAX_MOBILE_SCREEN_WIDTH ? 1 : null;
    const isTabletShow =
      viewport.width < MAX_TABLET_SCREEN_WIDTH && viewport.width >= MAX_MOBILE_SCREEN_WIDTH
        ? 3
        : null;
    const slidesToShow = isMobileShow || isTabletShow || 1;

    const sharedUrl = `${config.canonicalRootURL}/blog/${slug}`;
    const author = { fields: {}, ...this.props.post.author };
    const facebookImages = mainImage
      ? { facebookImages: [{ url: mainImage, width: 1200, height: 630 }] }
      : {};

    return (
      <Page
        title={`${metaTitle} | Decathlon GO`}
        description={metaDescription}
        {...facebookImages}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          author: {
            '@type': 'Person',
            name: author.fields.name,
          },
          publisher: {
            '@type': 'Organization',
            name: 'Decathlon Go',
          },
          image: mainImage,
          description: metaDescription,
          name: metaTitle,
          headline: metaTitle,
          mainEntityOfPage: sharedUrl,
        }}
      >
        <LayoutSingleColumn className={classNames(css.fixedBottom, css.withBackground)}>
          <LayoutWrapperTopbar>
            {/* <TopbarContainer className={css.blogPostTopbar} /> */}
            <div className={css.staticTopbar}>
              <NamedLink
                className={css.logoLink}
                name="LandingPage"
                title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
              >
                <Logo
                  format="mobile"
                  className={css.logo}
                  alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
                />
              </NamedLink>
            </div>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.mobileMainHeader}>
              <NamedLink className={css.backButtonMobile} name="Academy">
                <img src={Chevron} alt="Powrót" className={css.chevron} />
                Wróć do bloga
              </NamedLink>
              <div className={css.mobileAuthor}>
                <img className={css.authorImage} src={author.fields.mainImage} alt={title} />
                <span className={css.userName}>{author.fields.name},</span>
                <span className={css.userDate}>{formatDate(publishingDate)}</span>
              </div>
              <h1 className={css.mainTitle}>{title}</h1>
            </div>
            <section className={css.mainBanner}>
              <div className={css.contentWrapper}>
                <div className={css.whiteWrapper}>
                  <NamedLink className={classNames(css.back, css.backButtons)} name="Academy">
                    <img src={Chevron} alt="Powrót" className={css.chevron} />
                    Powrót
                  </NamedLink>
                  <h1 className={css.mainTitle}>{title}</h1>
                  <div className={css.mainBtnContainer}>
                    <span>{label}</span>
                  </div>
                  <div className={css.mainUserInfo}>
                    <div className={css.author}>
                      <img className={css.authorImage} src={author.fields.mainImage} alt={title} />
                      <span className={css.mainUserName}>{author.fields.name}</span>
                      <span className={classNames(css.dot, css.dotMobile)} />
                    </div>
                    <div className={css.mobileAdditionalInfo}>
                      <span>{formatDate(publishingDate)}</span>
                      <span className={classNames(css.dot)} />
                      <span>{minutesToRead} min czytania</span>
                    </div>
                  </div>
                </div>
                <div className={classNames(css.withBackground, css.shareSection)}>
                  <span className={css.line} />
                  <span className={css.shareText}>Udostępnij</span>
                  <SharingButtons sharedUrl={sharedUrl} title={title} />
                </div>
              </div>
              <div
                className={css.mainImageWrapper}
                style={{ backgroundImage: `url(${mainImage})` }}
              />
            </section>
            <div className={css.staticPageWrapper}>
              <div className={css.articleWrapper}>
                <div>
                  <section className={classNames(css.mainArticle)}>
                    {text && documentToReactComponents(text, options)}
                  </section>

                  <section className={classNames(css.mainArticle, css.authorSection)}>
                    <img
                      alt="Autor"
                      className={css.summaryAuthorImage}
                      src={author.fields.mainImage}
                    />
                    <div className={css.summaryAuthorInfo}>
                      <div className={css.summaryAuthorWrapper}>
                        <span className={css.summaryUserName}>{author.fields.name}</span>
                        <span className={css.summaryContactInfo}>
                          <span>{author.fields.email}</span>
                        </span>
                      </div>
                      <div className={css.summaryAuthorDescription}>
                        {author.fields.description &&
                          documentToReactComponents(author.fields.description)}
                      </div>
                    </div>
                  </section>
                </div>

                <div className={css.articleSidebar}>
                  <section className={css.mobileShareSection}>
                    <div className={css.sharingHeader}>Udostępnij</div>
                    <SharingButtons sharedUrl={sharedUrl} title={title} />
                    <span className={css.horizontalLine} />
                  </section>

                  <div className={css.mobileWrap}>
                    <RentAd category={label} />
                    {/* <div className={css.actions}>
                  <a href={label === 'Tenis stołowy' ? "https://rent.decathlon.pl/s?address=Polska&bounds=52.75693675%2C21.54764708%2C51.7481481%2C20.36112364&keywords=robot&mapSearch=true&utm_source=go.decathlon.pl&utm_medium=referral&utm_campaign=blog_post" : "https://rent.decathlon.pl/s?address=Polska&bounds=54.9054761%2C24.1458931%2C49.002025%2C14.1228641&pub_category=bikes&utm_source=go.decathlon.pl&utm_medium=referral&utm_campaign=blog_post"} target="_blank" rel="noopener" className={css.trainAtHome}>
                    <img src={label === 'Tenis stołowy' ? 'https://kgcodego.b-cdn.net/blog/tenis-robot-desktop.jpg' : 'https://kgcodego.b-cdn.net/blog/rent-bikes-banner.jpg'} alt="Wypożycz rower w Decathlon Rent"/>
                  </a>
                </div> */}

                    {/* <section className={css.postProducts}>
                  <div className={css.mainNavTitle}>
                    <span className={css.line} />
                    Może Ci się przydać!
                  </div>
                  <SectionExternalProducts
                    category="Blog Post"
                    products={products}
                    titleVisible={false}
                    sport={"fitness"}
                    urlCampaign="blog_post"
                    slidesToShow={slidesToShow}
                    sectionClassName={css.externalProducts}
                  />
                </section> */}
                  </div>
                  <section>
                    <ReferencingPostsSection title="Zobacz także" posts={referencingPosts} />
                  </section>
                </div>
              </div>
              {/* <FixedBottom /> */}
            </div>
          </LayoutWrapperMain>

          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const mapStateToProps = state => {
  const { post, queryPostError } = state.PostPage;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    post,
    queryPostError,
  };
};

const ProfilePage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps),
  injectIntl
)(ProfilePageComponent);

ProfilePage.loadData = params => {
  return loadData(params.id);
};

export default ProfilePage;
