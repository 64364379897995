import { createGoSDK } from '@dktunited/sporteo-sdk';
import axios from 'axios';

export const sdkGo = createGoSDK(`${process.env.REACT_APP_API_URL}/api`, {
  axios,
  noAuth: typeof window === 'undefined',
  useUnsafeRefreshToken: false,
});

export const apiSdk = {
  users: {
    currentUser: async () => {
      const res = await sdkGo.currentUser.show();
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    currentUserUpdate: async newData => {
      const res = await sdkGo.currentUser.updateProfile(newData);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    show: async userId => {
      const res = await sdkGo.users.show({ id: userId });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
  },
  listings: {
    query: async params => {
      const res = await sdkGo.listings.query(params);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    show: async (listingId, params) => {
      const res = await sdkGo.listings.show({ id: listingId, ...params });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    create: async params => {
      const res = await sdkGo.listings.create(params);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    update: async newData => {
      const res = await sdkGo.listings.update(newData);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
  },
  images: {
    create: async file => {
      const res = await sdkGo.images.upload({ file });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
  },
  transactions: {
    create: async newData => {
      const res = await sdkGo.transactions.create(newData);
      if (res.status === 201) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    show: async (transactionId, params) => {
      const res = await sdkGo.transactions.show({ id: transactionId, ...params });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    query: async params => {
      const res = await sdkGo.transactions.query(params);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    cancel: async transactionId => {
      const res = await sdkGo.transactions.update({ id: transactionId, cancel: {} });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    syncPayment: async transactionId => {
      const res = await sdkGo.transactions.update({ id: transactionId, syncPayment: {} });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
  },
  chat: {
    show: async chatId => {
      const res = await sdkGo.chats.show({ id: chatId });
      if (res.status === 201) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    create: async listingId => {
      const res = await sdkGo.chats.create({ listingId });
      if (res.status === 201) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    queryChats: async params => {
      const res = await sdkGo.chats.query(params);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    sendMessage: async ({ chatId, message }) => {
      const res = await sdkGo.messages.create({ chatId: chatId, content: message });
      if (res.status === 201) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    queryMessages: async (chatId, params) => {
      const res = await sdkGo.messages.query({ chatId: chatId, ...params });
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    queryAllMessages: async params => {
      const res = await sdkGo.messages.query(params);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
  },
  review: {
    create: async newData => {
      const res = await sdkGo.reviews.create(newData);
      if (res.status === 201) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
    query: async params => {
      const res = await sdkGo.reviews.query(params);
      if (res.status === 200) {
        return res.data;
      } else {
        throw new Error('error', res.error);
      }
    },
  },
};
