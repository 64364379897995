import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  default as facebookImage,
  default as twitterImage,
} from '../../assets/sporteoFacebook.png';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import css from './HomeTrainingPage.css';
import HowItWorksSection from './HowItWorks/HowItWorksSection';
import NextEventSection from './NextEvent/NextEventSection';
import OurTrainersSection from './OurTrainers/OurTrainersSection';
import TrainingsOverviewSection from './Overview/TrainingsOverviewSection';
import ScheduleSection from './Schedule/ScheduleSection';
import { searchListings } from './SportLocationPage.duck';
import TrainerOfTheMonthSection from './TrainerOfTheMonth/TrainerOfTheMonthSection';
import Webinars from './Webinars/Webinars';
import training1 from './images/hantel.png';
import training3 from './images/live.png';
import training2 from './images/trener.png';
import MauriczImg from './mauricz.jpg';
import MizeraImg from './mizera.jpg';
import WierzbickaImg from './wierzbicka.jpg';

import FooterV2 from '../../components/Footer/FooterV2';
import { manageDisableScrolling } from '../../ducks/UI.duck';

const trainers = [
  {
    name: 'Ola Żelazo',
    description:
      'Trenerka fitness, dyplomowana nauczycielką jogi, autorka książki “Bądź fit” oraz YouTuberką, z którą ćwiczą tysiące dziewczyn w każdym wieku.',
    image: 'https://kgcodego.b-cdn.net/landings/zelazo.jpg',
  },
  {
    name: 'Natalia Gacka',
    description:
      'Mistrzyni Świata juniorek fitness sylwetkowego. W swoich autorskich planach treningowych wykorzystuje wiedzę i doświadczenie zdobyte podczas wielu treningów i udziału w zawodach fitness.',
    image: 'https://kgcodego.b-cdn.net/landings/gacka.jpg',
  },
  {
    name: 'Paweł Korzeniowski',
    description:
      'Wybitny polski pływak, wielokrotny rekordzista i mistrz Polski, mistrz i dwukrotny wicemistrz świata, wielokrotny mistrz Europy i olimpijczyk.',
    image: 'https://kgcodego.b-cdn.net/landings/korzen.jpg',
  },
  {
    name: 'Kasia Bigos',
    description:
      'Instruktorka fitness i pole dance, mistrzynia Polski i Europy Pole Sport w kategorii duety oraz autorka programu treningowego Fireworkout.',
    image: 'https://kgcodego.b-cdn.net/landings/bigos.jpg',
  },
  {
    name: 'Bronisław Olenkowicz',
    description:
      'Uczestnik CrossFit Games, 3-krotny mistrz Polski Kettlebell Hardstyle, autor programowów treningowych STRONG FOR LIFE.',
    image: 'https://kgcodego.b-cdn.net/landings/olenkowicz.jpg',
  },
  {
    name: 'Kids&Sport',
    description:
      'Grupa trenerów, która prowadzi zajęcia sportowe dla dzieci w przedszkolach i szkołach podstawowych, wspólnie z Decathlon szkoli  nauczycieli w ramach akcji Zdrowo i Sportowo.',
    image: 'https://kgcodego.b-cdn.net/landings/kidssports.jpg',
  },
  {
    name: 'Łukasz Grabowski',
    description:
      "Od ponad 8 lat zbiera doświadczenie jako licencjonowany trener Zumba Fitness, znany jako Zumba® fitness Lu, tancerz salsy, bachaty, kizomby oraz zouk'a.",
    image: 'https://kgcodego.b-cdn.net/landings/grabowski.jpg',
  },
  {
    name: 'Krzysztof "FERICZE" Ferenc',
    description:
      'Prowadzący i trener programu telewizyjnego TTV Kanapowcy, ambasador marki OLIMP SPORT NUTRITION, trener personalny i motywator tysięcy osób',
    image: 'https://kgcodego.b-cdn.net/landings/fericze.jpg',
  },
  {
    name: 'Kamil Jasiński',
    description:
      'Trener personalny z wieloletnim doświadczeniem z Krakowa, ex-gimnastyk, właściciel Centrum Treningu Personalnego i Fizjoterapii FitMixer. Multimedalista Mistrzostw Polski w Gimnastyce i Aerobiku Sportowym, Mistrz Europy i Świata w Showdance.',
    image: 'https://kgcodego.b-cdn.net/landings/jasinski.jpg',
  },
  {
    name: 'Qczaj',
    description:
      'Daniel Józef Kuczaj to trener personalny, którego pokochały Polki. Dobre poczucie humoru i porządna dawka motywacji to coś na co zawsze można liczyć na jego profilach w social mediach.',
    image: 'https://kgcodego.b-cdn.net/landings/qczaj.jpg',
  },
];
export class HomeTrainingPageComponent extends React.Component {
  render() {
    const {
      history,
      intl,
      location,
      scrollingDisabled,
      onManageDisableScrolling,
      pageListings,
    } = this.props;
    const siteTitle = 'Decathlon GO';
    const schemaTitle = 'Trenuj w domu z Decathlon';
    const schemaDescription =
      'Pandemia koronawirusa nie musi oznaczać braku aktywności. Wręcz przeciwnie. Spędzając czas w domu zadbaj o swoją odporność i dołącz do wspłnych treningów.';
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const now = new Date().getTime();
    const nextListing = pageListings
      .filter(l => l.attributes.publicData.date > now)
      .sort(l => l.attributes.publicData.date - now)[0];

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.header}>
              <h1 className={css.mainTitle}>
                <span className={css.mainTitleBlue}>DECATHLON PORUSZA POLSKĘ!</span>
              </h1>
              <div className={css.mainDescription}>
                <span className={css.tag}>
                  Zaproś profesjonalnych trenerów do swojego domu i wytrwaj w noworocznych
                  postanowieniach!
                </span>
                <br />
                <span>
                  Dołącz do naszej akcji, zapisz się na treningi LIVE i&nbsp;nie daj wybić się z
                  rytmu!
                </span>
              </div>
            </div>
            <div className={css.offersWrapper}>
              <div className={css.offers}>
                <div className={css.offer}>
                  <div className={css.offerImage}>
                    <img alt="Darmowe treningi" src={training1} />
                  </div>
                  <div className={css.offerTitle}>Darmowe treningi</div>
                </div>
                <div className={css.divider} />
                <div className={css.offer}>
                  <div className={css.offerImage}>
                    <img alt="Profesjonalni trenerzy" src={training2} />
                  </div>
                  <div className={css.offerTitle}>Profesjonalni trenerzy</div>
                </div>
                <div className={css.divider} />
                <div className={css.offer}>
                  <div className={css.offerImage}>
                    <img alt="Treningi LIVE" src={training3} />
                  </div>
                  <div className={css.offerTitle}>Treningi LIVE</div>
                </div>
              </div>
            </div>
            <div className={css.webinars}>
              <div className={css.webinarsTop}>
                <div className={css.webinarsHeader}>
                  <h2>Decathlon, na zdrowie!</h2>
                  <p>
                    Dieta jest nieodłącznym towarzyszem każdego sportowca i osób, które chcą zdrowo
                    żyć. Co jeść i jak jeść ? Dowiedz się tego od naszych ekspertów. Zapisz się na
                    webinar z najlepszymi dietetykami już teraz!
                  </p>
                </div>
                <div className={css.dieticians}>
                  <div className={css.dietician}>
                    <img src={MauriczImg} />
                    <h3>Jakub Mauricz</h3>
                  </div>
                  <div className={classNames(css.dietician, css.reverse)}>
                    <img src={WierzbickaImg} />
                    <h3>Iwona Wierzbicka</h3>
                  </div>
                  <div className={css.dietician}>
                    <img src={MizeraImg} />
                    <h3>Justyna Mizera</h3>
                  </div>
                </div>
              </div>
              <div className={css.webinarsBottom}>
                <h2>Najbliższe webinary</h2>
                <Webinars data={pageListings} />
              </div>
            </div>
            <NextEventSection data={nextListing} intl={intl} />
            <HowItWorksSection />
            <ScheduleSection data={pageListings} />
            <TrainingsOverviewSection data={pageListings} />
            <TrainerOfTheMonthSection />
            <OurTrainersSection trainers={trainers} />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const { bool, func, number, object, shape } = PropTypes;

HomeTrainingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentPageResultIds } = state.SportLocationPage;
  const pageListings = getListingsById(state, currentPageResultIds);

  return {
    pageListings,
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});
// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const HomeTrainingPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(HomeTrainingPageComponent);

HomeTrainingPage.loadData = (params, search) => {
  const queryParams = {
    ...params.searchParams,
  };

  //const { origin, pub_date = `${lastMidnight.getTime()},`, ...rest } = queryParams;
  const { origin, ...rest } = queryParams;
  const originMaybe = config.sortSearchByDistance && origin ? { origin } : {};

  return searchListings({
    ...rest,
    ...originMaybe,
    meta_landing: ['has_all:homeTraining'],
    sort: '-pub_date',
    include: ['author', 'images'],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData', 'metadata'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
    'limit.images': 1,
  });
};

export default HomeTrainingPage;
