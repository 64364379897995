import React, { Component } from 'react';
import { array, func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import { ValidationError, FieldCheckboxGreen } from '../../components';

import css from './FieldCheckboxList.css';

const GroupOfFieldCheckboxes = props => {
  const { id, className, name, options } = props;
  return (
    <fieldset className={className}>
      <ul className={css.list}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          return (
            <li key={fieldId} className={css.item}>
              <FieldCheckboxGreen id={fieldId} name={name} label={option.label} value={option.key} />
            </li>
          );
        })}
      </ul>
    </fieldset>
  );
};

class FieldCheckboxListComponent extends Component {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      customErrorText,
      id,
      label,
      input,
      meta,
      options,
      rootClassName,
      ...rest
    } = this.props;
    /* eslint-enable no-unused-vars */

    if (label && !id) {
      throw new Error('id required when a label is given');
    }
    const { name } = input;

    const { valid, invalid, touched, error } = meta;

    const errorText = customErrorText || error;

    // Error message and input error styles are only shown if the
    // field has been touched and the validation has failed.
    const hasError = !!customErrorText || !!(touched && invalid && error);

    const fieldMeta = { touched: hasError, error: errorText };
    const inputProps = { id, ...input, ...rest };

    return (
      <div className={rootClassName}>
        {label ? <label htmlFor={name}>{label}</label> : null}
        <GroupOfFieldCheckboxes
          className={css.fieldGroup}
          name={name}
          id={id}
          options={options}
          {...inputProps}
        />
        <ValidationError fieldMeta={fieldMeta} />
      </div>
    );
  }
}

FieldCheckboxListComponent.defaultProps = {
  customErrorText: null,
  id: null,
  label: null,
  rootClassName: null
};

FieldCheckboxListComponent.propTypes = {
  customErrorText: string,
  id: string,
  label: string,
  rootClassName: string,

  // Generated by final-form's Field component
  input: shape({
    onChange: func.isRequired,
  }).isRequired,
  meta: object.isRequired,
  options: array.isRequired
};

class FieldCheckboxList extends Component {
  componentWillUnmount() {
    // Unmounting happens too late if it is done inside Field component
    // (Then Form has already registered its (new) fields and
    // changing the value without corresponding field is prohibited in Final Form
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldCheckboxListComponent} {...this.props} />;
  }
}

export default FieldCheckboxList;
