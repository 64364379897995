import React, { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import FormItem from '../../components/FormItem';
import { useFormContext } from '../context';
import CustomCheckbox from '../../components/CustomCheckbox';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import css from './styles.css';
import { updateEvent } from '../api';
import RequestStatusModal from '../RequestStatusModal';

const Requirements = () => {
  const { updateFormData, isLastStep, formData, listingId, type } = useFormContext();
  const [updateSucces, setUpdateSucces] = React.useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
    watch,
  } = useForm({
    values: formData.requirements,
  });

  const watchedFemaleCheckbox = watch('forWho.female');
  const watchedMaleCheckbox = watch('forWho.male');
  const watchedChildrenCheckbox = watch('forWho.children');

  const watchedNoviceCheckbox = watch('level.novice');
  const watchedBegginerCheckbox = watch('level.beginner');
  const watchedIntermediateCheckbox = watch('level.intermediate');
  const watchedAdvancedCheckbox = watch('level.advanced');
  const watchedProfessionalCheckbox = watch('level.professional');

  const listingTitle = formData.generalInfo.eventTitle;

  const submittedRef = useRef(false);

  useEffect(() => {
    if (!watchedFemaleCheckbox && !watchedMaleCheckbox && !watchedChildrenCheckbox) {
      setError('forWho', {
        type: 'manual',
        message: 'Wybierz przynajmniej jedną opcję',
      });
    } else {
      clearErrors('forWho');
    }
  }, [watchedFemaleCheckbox, watchedMaleCheckbox, watchedChildrenCheckbox, submittedRef.current]);

  useEffect(() => {
    if (
      !watchedNoviceCheckbox &&
      !watchedBegginerCheckbox &&
      !watchedIntermediateCheckbox &&
      !watchedAdvancedCheckbox &&
      !watchedProfessionalCheckbox
    ) {
      setError('level', {
        type: 'manual',
        message: 'Wybierz przynajmniej jedną opcję',
      });
    } else {
      clearErrors('level');
    }
  }, [
    watchedNoviceCheckbox,
    watchedBegginerCheckbox,
    watchedIntermediateCheckbox,
    watchedAdvancedCheckbox,
    watchedProfessionalCheckbox,
    submittedRef.current,
  ]);

  const onSubmit = async data => {
    submittedRef.current = true;
    if (!errors.forWho && !errors.level) {
      updateFormData(data, 'requirements');
    }
    const updateStatus = await updateEvent({
      id: listingId,
      publicData: {
        gender: Object.entries(data.forWho)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key),
        levels: Object.entries(data.level)
          .filter(([key, value]) => value === true)
          .map(([key, value]) => key),
      },
    });
    updateStatus === 'success' ? setUpdateSucces('success') : setUpdateSucces('error');
  };

  const submitButtonLabel = useMemo(
    () => (isLastStep ? 'Opublikuj' : type === 'edit' ? 'Zapisz' : 'Dalej'),
    [isLastStep, type]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.container}>
      {type === 'edit' && updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => setUpdateSucces(null)}
          listingId={listingId}
          slug={listingTitle}
        />
      )}

      <h1 className={css.header}>
        <LeftArrowIcon />
        Wymagania
      </h1>
      <FormItem label={'Dla kogo skierowany jest event?'}>
        <CustomCheckbox label={'Kobieta'} control={control} name="forWho.female" />
        <CustomCheckbox label={'Mężczyzna'} control={control} name="forWho.male" />
        <CustomCheckbox label={'Dziecko'} control={control} name="forWho.children" />
      </FormItem>
      {errors.forWho && submittedRef.current && (
        <p style={{ color: '#f00', margin: 0 }}>{errors.forWho.message}</p>
      )}

      <FormItem label={'Wybierz poziom zaawansowania?'}>
        <CustomCheckbox label={'Nowicjusz'} control={control} name="level.novice" />
        <CustomCheckbox label={'Początkujący'} control={control} name="level.beginner" />
        <CustomCheckbox label={'Średniozaawansowany'} control={control} name="level.intermediate" />
        <CustomCheckbox label={'Zaawansowany'} control={control} name="level.advanced" />
        <CustomCheckbox label={'Profesjonalista'} control={control} name="level.professional" />
      </FormItem>
      {errors.level && submittedRef.current && (
        <p style={{ color: '#f00', margin: 0 }}>{errors.level.message}</p>
      )}

      <div className={css.buttonContainer} onClick={() => (submittedRef.current = true)}>
        <button type="submit" className={css.submitClasses}>
          {submitButtonLabel}
        </button>
      </div>
    </form>
  );
};

export default Requirements;
