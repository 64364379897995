import React, { useEffect, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../assets/addEventForm/leftArrowIcon.svg';
import { CustomCheckbox } from '../components/CustomCheckbox';
import css from './style.css';

import AddChildForm from './AddChildForm';
import EditAdultForm from './EditAdultForm';

const ForWhoForm = ({
  participantsData,
  setParticipantsData,
  isForChildren,
  userData,
  setUserData,
  isAllBooked,
  setForwardButtonDisabled,
  handleClose,
}) => {
  const isNoUser = !userData.firstName || !userData.lastName;

  const [isAdultChecked, setIsAdultChecked] = useState(true);

  const [editAdult, setEditAdult] = useState(isNoUser);
  const [editChild, setEditChild] = useState(false);
  const [editedChildIndex, setEditedChildIndex] = useState(null);
  const [childrenData, setChildrenData] = useState(
    participantsData.filter(participant => participant.age && Number(participant.age) < 20)
  );

  setForwardButtonDisabled(editAdult || editChild);

  const handleEditChild = childData => {
    if (editedChildIndex === null) {
      setChildrenData(prev => [...prev, childData]);
      setEditChild(false);
      setForwardButtonDisabled(false);
    } else {
      setChildrenData(prev =>
        prev.map((child, index) => {
          if (index === editedChildIndex) {
            return childData;
          }
          return child;
        })
      );
      setEditChild(false);
      setForwardButtonDisabled(false);
    }
  };

  const handleCheckChild = index => {
    setChildrenData(prev =>
      prev.map((child, i) => {
        if (i === index) {
          return { ...child, isChecked: !child.isChecked };
        }
        return child;
      })
    );
  };

  useEffect(() => {
    setParticipantsData(
      isAdultChecked
        ? [...childrenData.filter(child => child.isChecked), { ...userData, isChecked: true }]
        : childrenData.filter(child => child.isChecked)
    );
  }, [childrenData, userData, isAdultChecked]);

  return (
    <div className={css.formContainer}>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            onClick={handleClose}
            style={{
              display: 'flex',
              alignItems: 'center',
              border: 'none',
              background: 'none',
              fontSize: '20px',
              color: '#000',
              margin: '5px 0 auto',
              padding: '0 5px 0 0',
            }}
          >
            <LeftArrowIcon />
          </button>
          Kto bierze udział w wydarzeniu
        </div>
        <span onClick={handleClose}>x</span>
      </h2>
      <h3>Twoje dane:</h3>
      {isForChildren && (
        <CustomCheckbox
          value={isAdultChecked}
          onChange={() => setIsAdultChecked(prev => !prev)}
          label={'Ja - osoba dorosła'}
        />
      )}
      <div className={css.personalInfo}>
        <p>{`${userData.firstName} ${userData.lastName}`}</p>
        <button onClick={() => setEditAdult(true)} type="button">
          Edytuj
        </button>
      </div>
      {editAdult && (
        <EditAdultForm setEditAdult={setEditAdult} userData={userData} setUserData={setUserData} />
      )}
      {isForChildren && (
        <p
          className={css.personalInfo}
          onClick={() => {
            if (childrenData.length < 5) {
              setEditChild(true);
              setForwardButtonDisabled(true);
            }
          }}
        >
          Dodaj dziecko{' '}
          <button
            className={css.addChild}
            style={{ textDecoration: 'none', fontSize: '24px' }}
            type="button"
          >
            +
          </button>
        </p>
      )}{' '}
      {childrenData.length >= 5 && <p style={{ color: 'red' }}>Można zapisać do pięciu dzieci</p>}
      {childrenData.map((child, index) => {
        return (
          <div className={css.personalInfo} key={index}>
            <div style={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
              <CustomCheckbox
                value={child.isChecked}
                onChange={() => handleCheckChild(index)}
                label={'Dziecko'}
              />
              <div>
                <p>{`${child.firstName} ${child.lastName}`}</p>
                <p>{`Wiek: ${child.age}`}</p>
              </div>
            </div>
            <button
              onClick={() => {
                setEditChild(true);
                setEditedChildIndex(index);
                setForwardButtonDisabled(true);
              }}
              type="button"
            >
              Edytuj
            </button>
          </div>
        );
      })}
      {editChild && (
        <AddChildForm
          handleAddChild={handleEditChild}
          setIsChildChecked={setEditChild}
          editedChildData={editedChildIndex !== null ? childrenData[editedChildIndex] : null}
        />
      )}
      {isAllBooked && (
        <p style={{ color: 'red' }}>Brak wystarczającej liczby miejsc na to wydarzenie</p>
      )}
    </div>
  );
};

export default React.memo(ForWhoForm);
