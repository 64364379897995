import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from 'react-slick';
import Cookies from 'js-cookie';
import classNames from 'classnames';
import { IconArrowRight, IconChevronBigLeft, IconChevronBigRight } from '../../components';
import { recommendationApi } from '../../components/SectionExternalProducts/api';
import css from './TransactionPage.css';

const PrevArrow = props => {
  const { className, onClick } = props;

  return (
    <div className={classNames(className, css.carousel, css.left)} onClick={onClick}>
      <IconChevronBigLeft />
    </div>
  );
};

const NextArrow = props => {
  const { className, onClick } = props;

  return (
    <div className={classNames(className, css.carousel, css.right)} onClick={onClick}>
      <IconChevronBigRight />
    </div>
  );
};

const RecommendedProducts = ({ listingId }) => {
  const [recoData, setRecoData] = useState([]);
  const settings = {
    dots: false,
    infinite: true,
    // slidesToShow: 4,
    // slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    arrows: true,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: recoData && recoData.length > 4 ? true : false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    (async () => {
      try {
        const uuid = Cookies.get('_snrs_uuid');
        const resData = await recommendationApi.getCatalogItemsData(listingId);
        if (resData && resData.category2) {
          const res = await recommendationApi.getProducts('pI2jSob5PusQ', uuid, resData.category2);
          const data = res.products.data;
          console.log('res', res);
          setRecoData(data);
        }
      } catch (error) {
        console.error('Błąd:', error);
      }
    })();
  }, []);

  return recoData && !!recoData.length ? (
    <div className={css.section}>
      <h3 style={{ fontSize: '28px', margin: 0 }}>Przygotuj się do sportowego wydarzenia</h3>
      <p style={{ fontSize: '16px', margin: '8px 0 20px 0' }}>
        Produkty zaprojektowanym z myślą o Twoim komforcie i wydajności.
      </p>
      {
        <Slider {...settings} className={css.slider}>
          {recoData &&
            recoData.map(product => (
              <a href={product.link} className={css.productLink} key={product.link} target="_blank">
                <div className={css.imageContainer}>
                  <LazyLoadImage alt={product.title} src={product.imageLink} threshold={100} />
                  <p className={css.imageContainerPrice}>Już od: {product.price} zł</p>
                </div>

                <p className={css.brand}>
                  {product.title} <IconArrowRight />
                </p>
              </a>
            ))}
        </Slider>
      }
    </div>
  ) : null;
};

export default RecommendedProducts;
