import { sdkGo } from '../../util/api';

const uploadImages = async images => {
  const uploadPromises = images.map(async image => {
    const res = await sdkGo.images.upload({ file: image.file });
    return res.data.data.id.uuid;
  });

  return await Promise.all(uploadPromises);
};

export const uploadFile = async file => {
  const res = await sdkGo.files.upload({ file });
  return res.data.id.uuid;
};

export const addEvent = async eventData => {
  try {
    const uploadedImageIds = await uploadImages(
      typeof eventData.images === 'string' ? JSON.parse(eventData.images) : eventData.images
    );
    eventData.images = uploadedImageIds;
    const res = await sdkGo.listings.create({
      ...eventData,
    });

    const newEventId = await res.data.data.id.uuid;
    if (eventData.publicData.selectedDate) {
      await addEventDate({
        listingId: newEventId,
        date: new Date(eventData.publicData.selectedDate),
        participantsLimit: 15000, //To Do
        duration: 180, //Number(duration),
      });
    }
    window.location.href = `/l/event/${newEventId}/draft/1`;
  } catch (error) {
    console.error('Error creating event:', error);
  }
};

export const updateEvent = async (eventData, filesIds) => {
  try {
    if (eventData.images) {
      const filesToUpload = (typeof eventData.images === 'string'
        ? JSON.parse(eventData.images)
        : eventData.images
      ).filter(file => !file.id.uuid);
      const uploadedImageIds = filesToUpload.length ? await uploadImages(filesToUpload) : [];
      eventData.images = [...filesIds, ...uploadedImageIds];
    }
    sdkGo.listings.update(eventData);

    return 'success';
  } catch (error) {
    console.error('Error updating event:', error);
    return 'error';
  }
};

export const addEventDate = async date => {
  try {
    const res = await sdkGo.listingDates.create(date);
    return 'success';
  } catch (error) {
    console.error('Error updating event:', error);
    return 'error';
  }
};

export const updateEventDate = async date => {
  try {
    const res = await sdkGo.listingDates.update(date);
    return 'success';
  } catch (error) {
    console.error('Error updating event:', error);
    return 'error';
  }
};
