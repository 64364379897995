import React from 'react';
import css from './style.css'

const Switcher = ({ selected, toggleSelected, options }) => {

    return (
        <div className={css.container}
            onClick={toggleSelected}>
            <div
                className={css.switcher}
                style={selected === options[0] ? ({ left: '0' }) : ({ left: '50%' })}
            >
            </div>
            <div className={css.option}>
                {options[0]}
            </div>
            <div className={css.option}>
                {options[1]}
            </div>
        </div>
    );
};

export default Switcher;
