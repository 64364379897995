import PropTypes from 'prop-types';
import React from 'react';
import css from './Section.css';

function Section({ children, title, subtitle, paragraph, isSliderContainer = false, ...props }) {
  return (
    <section className={isSliderContainer ? css.sliderContainer : css.container} {...props}>
      <div className={css.header}>
        {title && <h2 className={css.title}>{title}</h2>}
        {subtitle && <h3 className={css.subtitle}>{subtitle}</h3>}
        {paragraph && <p className={css.description}>{paragraph}</p>}
      </div>

      {children}
    </section>
  );
}

const { string, node } = PropTypes;

Section.propTypes = {
  children: node.isRequired,
  title: string,
  subtitle: string,
  paragraph: string,
};

export default Section;
