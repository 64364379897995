import React from 'react';
import FormItem from '../../../components/FormItem';
import DateTimeInput from '../../../components/DateTimeInput';

const EventDate = ({ register, errors, control }) => (
  <FormItem label={'Termin wydarzenia'}>
    <div style={{ border: 'solid 1px #949494', padding: '0 10px', width: '100%' }}>
      <DateTimeInput register={register} control={control} />
      {errors.selectedDate && errors.selectedDate.type === 'required' && (
        <p style={{ color: '#f00' }} role="alert">
          Data wydarzenia jest wymagana
        </p>
      )}
    </div>
  </FormItem>
);

export default EventDate;
// import React from "react";
// import FormItem from "../../../components/FormItem";
// import DateTimeInput from "../../../components/DateTimeInput";

// const EventDate = ({ register, errors, control }) => <FormItem label={'Termin wydarzenia'}>
//     <DateTimeInput register={register} control={control} />
//     {errors.selectedDate && errors.selectedDate.type === "required" && (
//         <p style={{ color: '#f00' }} role="alert">Data wydarzenia jest wymagana</p>
//     )}
// </FormItem>

// export default EventDate;
