import React from 'react'
import classNames from 'classnames';
import css from './SearchFiltersMobile.css';


const InfoModal = () => {

    return <div className={classNames(css.infoModal)}>
        <p className={classNames(css.infoModalText)}>
            Wyniki wyszukiwania pokazują wszystkie oferty, które odpowiadają Twoim kryteriom. Możesz zawęzić wyświetlone wyniki, używając dostępnych filtrów. Wyniki wyszukiwania domyślnie są uporządkowane chronologicznie, od wydarzeń dodanych najwcześniejszych do najstarszych.
        </p>
    </div>
}

export default InfoModal