import React from 'react';
import css from './styles.css';

const SelectStep = ({ steps, setFormStep, formData, step, type }) => {
  const isEvent = !!formData.isEvent.isEvent;
  const isGeneralInformationComplete = isEvent
    ? !!formData.generalInfo &&
      !!formData.generalInfo.eventType &&
      !!formData.generalInfo.eventTitle &&
      !!formData.generalInfo.sport &&
      !!formData.generalInfo.description
    : !!formData.generalInfo &&
      //   !!formData.generalInfo.eventType &&
      !!formData.generalInfo.eventTitle &&
      !!formData.generalInfo.sport &&
      !!formData.generalInfo.description;

  const isRequirementsComplete =
    !!formData.requirements &&
    !!Object.entries(formData.requirements.forWho)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key).length &&
    !!Object.entries(formData.requirements.level)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key).length;

  const isRegulationsComplete = !!formData.regulations && !!formData.regulations.cancel;

  const isLocationComplete = !!formData.location && !!formData.location.location;

  const isEventDatesComplete = !!formData.eventDates && !!formData.eventDates.termins.length;

  const isSectorsPriceCompleted =
    !!formData.sectorsPrice && !!Object.values(formData.sectorsPrice).length;

  const isVipPlasesCompleted = isSectorsPriceCompleted; // !!formData.VIPSeats && !!Object.keys(formData.VIPSeats).length;

  const completeConditionsEvent = {
    0: isGeneralInformationComplete || type === 'edit',
    1: isRequirementsComplete || type === 'edit',
    2: isRegulationsComplete || type === 'edit',
    3: isLocationComplete || type === 'edit',
    4: isEventDatesComplete || type === 'edit',
    5: isEventDatesComplete || type === 'edit',
  };

  const completeConditionsTicketing = {
    0: isGeneralInformationComplete || type === 'edit',
    1: isSectorsPriceCompleted || type === 'edit',
    2: isVipPlasesCompleted || type === 'edit',
    // 1: isRequirementsComplete || type === 'edit',
    3: isRegulationsComplete || type === 'edit',
    // 3: isLocationComplete || type === 'edit',
    // 4: isEventDatesComplete || type === 'edit',
    // 5: isEventDatesComplete || type === 'edit',
  };

  const completeConditions = isEvent ? completeConditionsEvent : completeConditionsTicketing;

  return (
    <div>
      <ul className={css.selectStepContainer}>
        {Object.values(steps).map(({ id, name }) => (
          <li
            style={!completeConditions[id] && Number(step) !== id ? { color: 'gray' } : {}}
            className={css.selectStepItem}
            key={id}
            onClick={() => (completeConditions[id] ? setFormStep(id) : null)}
          >
            {!!id && <span className={css.selectStepArrow}>{'>'}</span>}
            {name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectStep;
