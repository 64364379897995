import pick from 'lodash/pick';
import config from '../../config';
import { generateQrCode, initiatePrivileged, transitionPrivileged } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import {
  TRANSITION_REQUEST_PAYMENT,
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  TRANSITION_CONFIRM_PAYMENT,
  TRANSITION_REQUEST_PUSH_PAYMENT,
  TRANSITION_CONFIRM_PUSH_PAYMENT,
  isPrivileged,
} from '../../util/transaction';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { setTransationMetaData } from '../../util/api'
const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const generateMultiple7DigitCodes = (numberOfCodes) => {
  const codes = new Set();
  while (codes.size < numberOfCodes) {
    const code = Math.floor(1000000 + Math.random() * 9000000).toString();
    codes.add(code);
  }
  return [...codes];
}


// ================ Action types ================ //

export const SET_INITAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/ListingPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/ListingPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/ListingPage/SPECULATE_TRANSACTION_ERROR';

// ================ Reducer ================ //

const initialState = {
  listing: null,
  bookingData: null,
  bookingDates: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
      };
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
  };



  const { paymentMethod, qrConfirmation, ...rest } = orderParams;

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params: rest,
    }
    : {
      processAlias: qrConfirmation ? config.paidBookingProcessAlias : config.paidBookingProcessAliasWithoutQr,
      transition: paymentMethod === 'p24' ? TRANSITION_REQUEST_PUSH_PAYMENT : TRANSITION_REQUEST_PAYMENT,
      params: { ...rest, paymentMethodTypes: [paymentMethod === 'p24' ? 'p24' : 'card'] },
    };

  //old
  // const bodyParams = transactionId
  //   ? {
  //     id: transactionId,
  //     transition: TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  //     params: rest,
  //   }
  //   : {
  //     processAlias: config.paidBookingProcessAlias,
  //     transition: paymentMethod === 'p24' ? TRANSITION_REQUEST_PUSH_PAYMENT : TRANSITION_REQUEST_PAYMENT,
  //     params: { ...rest, paymentMethodTypes: [paymentMethod === 'p24' ? 'p24' : 'card'] },
  //   };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };


  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];

    order.attributes.payinTotal = new Money(order.attributes.payinTotal.amount, order.attributes.payinTotal.currency)
    order.attributes.payoutTotal = new Money(order.attributes.payoutTotal.amount, order.attributes.payoutTotal.currency)

    const seats = bodyParams.params.seats//order.booking.attributes.seats
    const seatsWithDiscount = bodyParams.params.seatsWithDiscount

    const ticketsNumbers = generateMultiple7DigitCodes(seats)
    const ticketsNumbersWithDiscount = generateMultiple7DigitCodes(seatsWithDiscount)
    // const date = new Date(order.booking.attributes.start)

    qrConfirmation && generateQrCode(order.id.uuid, order.id.uuid, [...ticketsNumbers, ...ticketsNumbersWithDiscount]).then((response) => {
      const tickets = ticketsNumbers.reduce((acc, ticket) => {
        return ([
          ...acc,
          {
            ticketNumber: ticket,
            checked: false,
            withDiscount: false,
            qrCodeUrl: response[ticket],
            // date
          }
        ])
      }, [])

      const ticketsWithDiscount = ticketsNumbersWithDiscount.reduce((acc, ticket) => {
        return ([
          ...acc,
          {
            ticketNumber: ticket,
            checked: false,
            withDiscount: true,
            qrCodeUrl: response[ticket],
            // date
          }
        ])
      }, [])

      if (bodyParams.params.additionalFormData) {
        return setTransationMetaData(order.id.uuid, {
          ...bodyParams.params.additionalFormData, ticketsNumbers: [...tickets, ...ticketsWithDiscount]
        })

      } else {
        return setTransationMetaData(order.id.uuid, {
          ticketsNumbers: [...tickets, ...ticketsWithDiscount]
        })
      }
    })


    if (!qrConfirmation && bodyParams.params.additionalFormData) {
      setTransationMetaData(order.id.uuid, {
        ...bodyParams.params.additionalFormData
      })

    }

    // setTransationMetaData(order.id.uuid, bodyParams.params.additionalFormData)
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  // const createOrder = transactionId ? sdk.transactions.transition : sdk.transactions.initiate;

  // return createOrder(bodyParams, queryParams)
  //   .then(response => {
  //     const entities = denormalisedResponseEntities(response);
  //     const order = entities[0];

  //     setTransationMetaData(order.id.uuid, bodyParams.params.additionalFormData)

  //     dispatch(initiateOrderSuccess(order));
  //     dispatch(fetchCurrentUserHasOrdersSuccess(true));
  //     return order;
  //   })
  //   .catch(e => {
  //     dispatch(initiateOrderError(storableError(e)));
  //     const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
  //     log.error(e, 'initiate-order-failed', {
  //       ...transactionIdMaybe,
  //       listingId: orderParams.listingId.uuid,
  //       bookingStart: orderParams.bookingStart,
  //       bookingEnd: orderParams.bookingEnd,
  //     });
  //     throw e;
  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      bookingStart: orderParams.bookingStart,
      bookingEnd: orderParams.bookingEnd,
    });
    throw e;
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(res => res.json())
      .then(res => handleSucces(res))
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transition(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: false, bookingData, bodyParams, queryParams })
      .then(res => res.json())
      .then(handleSucces)
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiate(bodyParams, queryParams)
      .then(handleSucces)
      .catch(handleError);
  }
};

export const confirmPayment = orderParams => (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PAYMENT,
    params: {},
  };

  return sdk.transactions
    .transition(bodyParams)
    .then(response => {
      const order = response.data.data;
      dispatch(confirmPaymentSuccess(order.id));
      return order;
    })
    .catch(e => {
      dispatch(confirmPaymentError(storableError(e)));
      const transactionIdMaybe = orderParams.transactionId
        ? { transactionId: orderParams.transactionId.uuid }
        : {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
      });
      throw e;
    });
};

export const confirmP24Payment = orderParams => async (dispatch, getState, sdk) => {
  dispatch(confirmPaymentRequest());

  const bodyParams = {
    id: orderParams.transactionId,
    transition: TRANSITION_CONFIRM_PUSH_PAYMENT,
    params: {},
  };

  try {
    const response = await sdk.transactions.transition(bodyParams, { expand: true, include: 'listing,booking' });

    const order = response.data.data;
    // dispatch(addMarketplaceEntities(response))
    dispatch(confirmPaymentSuccess(order.id));
    return order;
  } catch (e) {
    dispatch(confirmPaymentError(storableError(e)));
    const transactionIdMaybe = orderParams.transactionId
      ? { transactionId: orderParams.transactionId.uuid }
      : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
    });
    throw e;
  }
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then((response) => {
        const messageId = response.data.data.id;
        setTransationMetaData(orderId, { [messageId.uuid]: false })
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate the speculative transaction with the given booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */

export const speculateTransaction = (orderParams, transactionId) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const transition = isTransition
    ? TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY
    : TRANSITION_REQUEST_PAYMENT;
  const isPrivilegedTransition = isPrivileged(transition);

  const bookingData = {
    startDate: orderParams.bookingStart,
    endDate: orderParams.bookingEnd,
  };

  const params = {
    ...orderParams,
    cardToken: 'CheckoutPage_speculative_card_token',
  };

  const bodyParams = isTransition
    ? {
      id: transactionId,
      transition,
      params,
    }
    : {
      processAlias: config.paidBookingProcessAlias,
      transition,
      params,
    };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };
  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];

    tx.attributes.payinTotal = new Money(tx.attributes.payinTotal.amount, tx.attributes.payinTotal.currency)
    tx.attributes.payoutTotal = new Money(tx.attributes.payoutTotal.amount, tx.attributes.payoutTotal.currency)

    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    const { listingId, bookingStart, bookingEnd } = params;
    log.error(e, 'speculate-transaction-failed', {
      listingId: listingId.uuid,
      bookingStart,
      bookingEnd,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };

  if (isTransition && isPrivilegedTransition) {
    // transition privileged
    return transitionPrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(res => res.json())
      .then(handleSuccess)
      .catch(handleError);
  } else if (isTransition) {
    // transition non-privileged
    return sdk.transactions
      .transitionSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  } else if (isPrivilegedTransition) {
    // initiate privileged
    return initiatePrivileged({ isSpeculative: true, bookingData, bodyParams, queryParams })
      .then(res => res.json())
      .then(res => handleSuccess(res))
      .catch(handleError);
  } else {
    // initiate non-privileged
    return sdk.transactions
      .initiateSpeculative(bodyParams, queryParams)
      .then(handleSuccess)
      .catch(handleError);
  }
};

