import React from 'react';
import { FormattedMessage } from 'react-intl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_EVENT_PARTICIPATION, propTypes } from '../../util/types';

import css from './BookingBreakdown.css';

const LineItemEventParticipationMaybe = props => {
  const { transaction, intl } = props;

  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === LINE_ITEM_EVENT_PARTICIPATION && !item.reversal
  );

  const { quantity } = unitPurchase || {};

  if (!quantity) return null;

  return (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        Udział x {quantity.toFixed()}
      </span>
      <span className={css.itemValue}>
        {formatMoney(intl, unitPurchase.unitPrice)} x {quantity.toFixed()}
      </span>
    </div>
  );
};

LineItemEventParticipationMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.bookingUnitType.isRequired,
};

export default LineItemEventParticipationMaybe;
