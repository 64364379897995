import React from "react";
const SingleFieldType = ({
    picture,
    title,
    description,
    onClick,
    selectedTypeOfQuestion,
    fieldType,
}) => {
    return (
        <div
            onClick={onClick}
            // className={
            //     selectedTypeOfQuestion === fieldType
            //         ? 'css.containerselected'
            //         : 'flex justify-between items-center gap-4 hover:cursor-pointer border-b py-4'
            // }

            style={
                selectedTypeOfQuestion === fieldType
                    ? {}
                    : {
                        display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '16px', cursor: 'pointer', borderBottom: '1px solid #ccc', padding: '16px 0'
                    }
            }
        >
            <img style={{ maxWidth: '30%' }} src={picture} />
            <div >
                <h3 >{title}</h3>
                <p style={{ fontSize: '12px' }} >{description}</p>
            </div>
        </div>
    );
};

export default SingleFieldType;