import React from 'react';
import css from "./styles.css";


const FormItem = ({ label, children, classes = '' }) => {
    return (
        <label
            className={`${css.formItemContainer} ${css[classes]}`}>
            {label}
            {children}
        </label>
    );

}

export default FormItem;
