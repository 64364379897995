import React from 'react';
import Section from '../../../../components/Section/Section';

import css from './Reasons.css';

const reasons = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.0136 5.76786C14.4117 2.6403 9.6745 2.64379 7.07732 5.77832L7.0741 5.78221C5.20839 8.00329 5.20838 11.2963 7.07408 13.5174L7.08304 13.5281L12.0041 19.6125L16.9163 13.6286C16.917 13.6278 16.9176 13.627 16.9182 13.6262C18.7926 11.3035 18.7963 8.09522 17.0136 5.76786ZM5.92389 4.81935C9.12417 0.959309 14.9779 0.959955 18.1773 4.82129L18.186 4.83175L18.1943 4.84252C20.4027 7.71353 20.4042 11.6976 18.0833 14.5711L18.0795 14.5757L11.9955 21.9871L5.92093 14.4767C3.59178 11.6985 3.59276 7.59643 5.92389 4.81935ZM8.4498 9.6998C8.4498 7.72049 9.95153 6.0498 11.9998 6.0498C14.0642 6.0498 15.5498 7.8376 15.5498 9.6998C15.5498 11.614 13.914 13.2498 11.9998 13.2498C10.1032 13.2498 8.4498 11.7314 8.4498 9.6998ZM11.9998 7.5498C10.8481 7.5498 9.9498 8.47912 9.9498 9.6998C9.9498 10.8682 10.8965 11.7498 11.9998 11.7498C13.0856 11.7498 14.0498 10.7856 14.0498 9.6998C14.0498 8.56201 13.1354 7.5498 11.9998 7.5498Z"
          fill="#101010"
        />
      </svg>
    ),
    title: 'Znajdź zajęcia w okolicy',
    description: 'Znajdź i zarezerwuj zajęcia sportowe w swojej okolicy szybko i wygodnie.',
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9998 4.5498C7.88528 4.5498 4.5498 7.88528 4.5498 11.9998C4.5498 16.1143 7.88528 19.4498 11.9998 19.4498C16.1143 19.4498 19.4498 16.1143 19.4498 11.9998C19.4498 7.88528 16.1143 4.5498 11.9998 4.5498ZM3.0498 11.9998C3.0498 7.05686 7.05686 3.0498 11.9998 3.0498C16.9428 3.0498 20.9498 7.05686 20.9498 11.9998C20.9498 16.9428 16.9428 20.9498 11.9998 20.9498C7.05686 20.9498 3.0498 16.9428 3.0498 11.9998Z"
          fill="#101010"
        />
        <path
          d="M13.4998 10.7998C13.4998 9.9998 14.1998 9.2998 14.9998 9.2998C15.7998 9.2998 16.4998 9.9998 16.4998 10.7998"
          fill="#101010"
        />
        <path
          d="M8.39976 13.3998C8.39976 15.3998 9.99976 16.9998 11.9998 16.9998C13.9998 16.9998 15.5998 15.3998 15.5998 13.3998"
          fill="#101010"
        />
        <path
          d="M7.49976 10.7998C7.49976 9.9998 8.19976 9.2998 8.99976 9.2998C9.79976 9.2998 10.4998 9.9998 10.4998 10.7998"
          fill="#101010"
        />
      </svg>
    ),
    title: 'Odkryj różnorodność dyscyplin',
    description: 'Spróbuj popularnych i niszowych sportów, dostępnych dla każdego poziomu',
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.5C8.54421 3.5 5.75 6.29421 5.75 9.75C5.75 13.2058 8.54421 16 12 16C15.4558 16 18.25 13.2058 18.25 9.75C18.25 6.29421 15.4558 3.5 12 3.5ZM4.25 9.75C4.25 5.46579 7.71579 2 12 2C16.2842 2 19.75 5.46579 19.75 9.75C19.75 12.6734 18.1363 15.2156 15.75 16.5358V21.9778L12 20.4778L8.25 21.9778V16.5358C5.8637 15.2156 4.25 12.6734 4.25 9.75ZM9.75 17.1691V19.7622L12 18.8622L14.25 19.7622V17.1691C13.5381 17.3843 12.7827 17.5 12 17.5C11.2173 17.5 10.4619 17.3843 9.75 17.1691Z"
          fill="#101010"
        />
        <path
          d="M12 11.44L14.16 13L13.35 10.46L15.5 8.89L12.83 8.88L12 6.34L11.17 8.88L8.5 8.89L10.65 10.46L9.84 13L12 11.44Z"
          fill="#101010"
        />
      </svg>
    ),
    title: 'Trenuj z profesjonalistami',
    description: 'Podnoś swoje umiejętności pod okiem doświadczonych trenerów',
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.42 5.63973C4.42 3.88551 5.83579 2.46973 7.59 2.46973C9.34421 2.46973 10.76 3.88551 10.76 5.63973C10.76 7.39394 9.34421 8.80973 7.59 8.80973C5.83579 8.80973 4.42 7.39394 4.42 5.63973ZM7.59 3.96973C6.66421 3.96973 5.92 4.71394 5.92 5.63973C5.92 6.56551 6.66421 7.30973 7.59 7.30973C8.51579 7.30973 9.26 6.56551 9.26 5.63973C9.26 4.71394 8.51579 3.96973 7.59 3.96973ZM11.13 7.46973C11.13 5.32865 12.8527 3.58973 15 3.58973C17.1358 3.58973 18.87 5.32708 18.87 7.46973C18.87 9.61238 17.1358 11.3497 15 11.3497C12.8642 11.3497 11.13 9.61238 11.13 7.46973ZM15 5.08973C13.6873 5.08973 12.63 6.15081 12.63 7.46973C12.63 8.78708 13.6958 9.84973 15 9.84973C16.3042 9.84973 17.37 8.78708 17.37 7.46973C17.37 6.15238 16.3042 5.08973 15 5.08973ZM2 17.2197C2 12.9441 4.31673 9.72973 7.59 9.72973C9.04058 9.72973 10.3386 10.3823 11.3141 11.4954L10.1859 12.484C9.46135 11.6572 8.55942 11.2297 7.59 11.2297C5.52327 11.2297 3.5 13.3354 3.5 17.2197H2ZM15 13.5697C12.1535 13.5697 9.5 16.4451 9.5 21.5297H8C8 16.0544 10.9465 12.0697 15 12.0697C19.0535 12.0697 22 16.0544 22 21.5297H20.5C20.5 16.4451 17.8465 13.5697 15 13.5697Z"
          fill="#101010"
        />
      </svg>
    ),
    title: 'Dołącz do społeczności',
    description: 'Spotykaj nowych ludzi na lokalnych wydarzeniach sportowych',
  },
];

const DecathlonGoReasons = () => {
  return (
    <Section title="Dlaczego Decathlon GO?">
      <div className={css.container}>
        {reasons.map(({ icon, title, description }) => (
          <div className={css.wrapper} key={title}>
            <div>{icon}</div>
            <h3 className={css.title}>{title}</h3>
            <p className={css.description}>{description}</p>
          </div>
        ))}
      </div>
    </Section>
  );
};

export default DecathlonGoReasons;
