import React, { useState } from 'react';

import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools";


const CodeEditor = ({ code, id, keyName, onUpdateEntity, fieldType, dataType, setFieldOpened }) => {
  const coder = JSON.stringify(code, null, 2);
  const [value, setValue] = useState(coder);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function onChange(newValue) {
    setValue(newValue);
  }

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setError(null);
      await onUpdateEntity({ id, [dataType]: { [keyName]: JSON.parse(value) } });
      setIsSubmitting(false);
      setFieldOpened(null)
    } catch (e) {
      setError(e.message);
      setIsSubmitting(false);
    }

  }

  return (
    <div>
      <AceEditor
        maxLines={Infinity}
        value={value}
        mode="javascript"
        theme="monokai"
        fontSize="16px"
        highlightActiveLine={true}
        onChange={onChange}
        setOptions={{
          enableLiveAutocompletion: true,
          showLineNumbers: true,
          tabSize: 2,
          useWorker: false,
        }}
      />
      {error && <div>{error}</div>}
      <button onClick={handleSubmit} disabled={isSubmitting}>{isSubmitting ? '...' : 'Zatwierdź'}</button>
      <button onClick={() => setFieldOpened(null)}>Anuluj</button>
    </div>
  )
}

export default CodeEditor;