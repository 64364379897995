import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';

export const useReplacePathParam = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const replacePathParams = newParams => {
    console.log('useReplacePathParam', newParams);

    const updatedParams = { ...params, ...newParams };
    const newPath = `/s/wydarzenia-sportowe/${updatedParams.sport}/${updatedParams.city}`;

    const searchParams = location.search;

    history.push({ pathname: newPath, search: searchParams });
  };

  return replacePathParams;
};
