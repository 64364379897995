import React from "react";
import FormItem from "../../../components/FormItem";
import { sportsConfig } from "../../../../sportsConfig";
import css from './styles.css'

const sports = Object.keys(sportsConfig)
    .map(sport => ({ key: sport, label: sportsConfig[sport].name }))
    .sort((a, b) => a.label.localeCompare(b.label))

const EventSport = ({ register, errors }) => {

    return <FormItem label={'Sport'}>
        <select defaultValue={""} className={`${css.eventTypeContainer} ${css.select}`} {...register("sport", { required: true })} >
            <option value={""} disabled >wybierz dyscyplinę</option>
            {sports.map((sport, index) => <option value={sport.key} key={index}>
                {sport.label}
            </option>)}
        </select>
        {errors.sport && errors.sport.type === "required" && (
            <p style={{ color: '#f00' }} role="alert">Dyscyplina sportu jest wymagana</p>
        )}
    </FormItem>
}

export default EventSport;

