import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useMutation } from 'react-query';
import Section from '../../components/Section/Section';
import SlideShow from '../../components/SlideShow/SlideShow';
import { apiSdk } from '../../queries/api';

import img1 from '../LandingPage/images/img1.png';
import img2 from '../LandingPage/images/img2.png';
import img3 from '../LandingPage/images/img3.png';

import css from './styles.css';

const activities = [
  {
    image: img1,
    label: 'Darmowe wydarzenia',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?min_price=0&max_price=0',
  },
  {
    image: img2,
    label: 'Wydarzenia dla dzieci',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?pub_gender=children',
  },
  {
    image: img3,
    label: 'Zajęcia dla dorosłych',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?pub_gender=female%2Cmale',
  },
];

const useReview = ({ transactionId }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [content, setContent] = useState('');
  const [successInfo, setSuccessInfo] = useState(false);

  const mutation = useMutation(
    () =>
      apiSdk.review.create({
        transactionId,
        rating,
        content,
      }),
    {
      onSuccess: data => {
        setContent('');
        setRating(0);
        setHover(0);
        setSuccessInfo('Twoja opinia została dodana');
      },
    }
  );

  const handleSend = () => {
    mutation.mutate();
  };

  return {
    rating,
    setRating,
    hover,
    setHover,
    content,
    setContent,
    successInfo,
    handleSend,
  };
};

const Review = ({ transactionId }) => {
  const {
    rating,
    setRating,
    hover,
    setHover,
    content,
    setContent,
    successInfo,
    handleSend,
  } = useReview({ transactionId });

  return (
    <div>
      {successInfo ? (
        <p>{successInfo}</p>
      ) : (
        <>
          <div style={{ margin: '20px auto', display: 'flex', alignItems: 'center' }}>
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={index <= (hover || rating) ? css.on : css.off}
                  onClick={() => setRating(index)}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(rating)}
                  style={{ background: 'none', border: 'none', cursor: 'pointer' }}
                >
                  <span className={css.star}>&#9733;</span>
                </button>
              );
            })}
            <span>{`${rating} / 5`}</span>
          </div>
          <div
            style={{
              margin: '20px auto',
              display: 'flex',
              flexDirection: 'column',
              padding: '0 10px',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <p>Jak było?</p>
            <textarea
              rows={5}
              style={{ border: '1px solid #8996A2', padding: '10px', boxSizing: 'border-box' }}
              placeholder="Podziel się z nami wrażeniami"
              value={content}
              onChange={e => setContent(e.target.value)}
            />
            <button
              disabled={!rating}
              className={!rating ? css.disabled : css.sendButton}
              onClick={handleSend}
            >
              Wyślij
            </button>
          </div>
        </>
      )}
      <div style={{ background: '#E1E3F5', padding: '30px 0', marginTop: '20px' }}>
        <Section
          title="Szukasz nowych wyzwań ?"
          subtitle="Sprawdź jakie jeszcze wydarzenia czekają na Ciebie na naszej platformie"
          isSliderContainer
        >
          <SlideShow>
            {activities.map(product => (
              <a href={product.url} className={css.activityLink} key={product.label}>
                <div className={css.imageContainer}>
                  <LazyLoadImage alt={product.label} src={product.image} threshold={100} />
                </div>
                <p className={css.activityLabel}>{product.label}</p>
              </a>
            ))}
          </SlideShow>
        </Section>
      </div>
    </div>
  );
};

export default Review;
