import React, { useState } from 'react';
import PropTypes from 'prop-types';
import css from './ToggleField.css';

const ToggleField = ({ label, value, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    onSave(inputValue);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setInputValue(value);
    setIsEditing(false);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  return (
    <>
      <h3>{label}</h3>
      {isEditing ? (
        <div>
          <input
            type="text"
            value={inputValue}
            onChange={handleChange}
            className={css.inputField}
          />
          <button onClick={handleSaveClick} className={css.saveButton}>
            Save
          </button>
          <button onClick={handleCancelClick} className={css.cancelButton}>
            Cancel
          </button>
        </div>
      ) : (
        <div className={css.description}>
          <span>{value}</span>
          <button onClick={handleEditClick} className={css.editButton}>
            Edit
          </button>
        </div>
      )}
    </>
  );
};

ToggleField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default ToggleField;
