import React, { Component } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import SectionAdditionalInfo from './SectionAdditionalInfo';
import IconLoc from './IconLoc';
import IconInfo from './IconInfo';
import { propTypes } from '../../util/types';
import { obfuscatedCoordinates } from '../../util/maps';
import { Map } from '../../components';
import config from '../../config';

import css from './ListingPage.css';

class SectionMapMaybe extends Component {
  constructor(props) {
    super(props);
    this.state = { isStatic: true };
  }

  render() {
    const { className, rootClassName, geolocation, header, publicData, listingId, sectionClassName } = this.props;

    if (!geolocation) {
      return null;
    }

    const address = publicData && publicData.location ? publicData.location.address : '';
    const classes = classNames(rootClassName || css.sectionMap, className);
    const cacheKey = listingId ? `${listingId.uuid}_${geolocation.lat}_${geolocation.lng}` : null;

    const mapProps = config.maps.fuzzy.enabled
      ? { obfuscatedCenter: obfuscatedCoordinates(geolocation, cacheKey) }
      : { address, center: geolocation };
    const map = <Map {...mapProps} useStaticMap={this.state.isStatic} />;

    return (
      <div className={classNames(css.section, sectionClassName)}>
        {header && <h2 className={css.header2}>{header}</h2>}
        {this.state.isStatic ? (
          <button
            className={css.map}
            onClick={() => this.setState({ isStatic: false })}
          >
            {map}
          </button>
        ) : (
          <div className={css.map}>{map}</div>
        )}
        <div className={css.locationInfoRow}>
          <IconLoc className={css.mapIcon} />
          <span className={classNames(css.description, css.location)}>
            {publicData.location.address}
          </span>
        </div>
        {
          publicData.location.building &&
            <div className={classNames(css.description, css.locationInfoRow)}>
              <IconInfo className={css.mapIcon} />
              <span className={css.additionalInfoLabel}>Dodatkowe informacje:</span>
              <span className={css.additionalInfo}>
                {publicData.location.building}
              </span>
            </div>
        }
        <SectionAdditionalInfo
          phone={publicData.phone}
          website={publicData.website}
          facebookUrl={publicData.facebook_url}
          instagramUrl={publicData.instagram_url}
        />
      </div>
    );
  }
}

SectionMapMaybe.defaultProps = {
  rootClassName: null,
  className: null,
  geolocation: null,
  listingId: null,
};

SectionMapMaybe.propTypes = {
  rootClassName: string,
  className: string,
  geolocation: propTypes.latlng,
  listingId: propTypes.uuid,
};

export default SectionMapMaybe;
