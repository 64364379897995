import React from 'react';
import { Link } from 'react-router-dom';
import Section from '../../../../components/Section/Section';

import css from './CityEvents.css';

const cities = [
  {
    label: 'Warszawa',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/warszawa?bounds=52.47094281%2C21.30950476%2C51.96614075%2C20.69015052',
  },
  {
    label: 'Katowice',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/katowice?bounds=50.2973176471578%2C19.12291896395016%2C50.13002717297118%2C18.89146640564267',
  },
  {
    label: 'Kraków',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/kraków?bounds=50.12612589673038%2C20.2174975543244%2C49.96740536805499%2C19.79224845563986',
  },
  {
    label: 'Gdańsk',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/gdańsk?bounds=54.66865618%2C18.870883%2C54.18934769%2C18.2515287',
  },
  {
    label: 'Wrocław',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/wrocław?bounds=51.21147357174232%2C17.17634783168821%2C51.04268220041629%2C16.8073800205415',
  },
  {
    label: 'Poznań',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/poznań?bounds=52.50914101692316%2C17.07165926547468%2C52.29191614333686%2C16.73167242406996',
  },
  {
    label: 'Szczecin',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/szczecin?bounds=53.53768233438447%2C14.80541658603947%2C53.32170957608885%2C14.44382391446629',
  },
  {
    label: 'Lublin',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/lublin?bounds=51.29655950092251%2C22.67354259315681%2C51.13980958326064%2C22.45378596172716',
  },
  {
    label: 'Łódź',
    url:
      '/s/wydarzenia-sportowe/wszystkie-sporty/lodz?bounds=51.85991913827916%2C19.6399429163139%2C51.68614418574892%2C19.32086175064714',
  },
];

const CityEvents = () => {
  return (
    <Section title="Wydarzenia w Twoim mieście">
      <ul className={css.linksWrapper}>
        {cities.map(({ label, url }) => (
          <Link to={url} className={css.linkButton} key={label}>
            <span>{label}</span>
          </Link>
        ))}
      </ul>
    </Section>
  );
};

export default CityEvents;
