import React from 'react';
import PropTypes from 'prop-types';

const IconDownload = (props) => {
  const { className } = props;
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_4)">
        <path
          d="M11.6666 15H3.33332C2.44302 15 1.60595 14.6533 0.976328 14.0236C0.346732 13.3941 0 12.557 0 11.6666V10.8333C0 10.373 0.373077 9.99991 0.833338 9.99991C1.2936 9.99991 1.66668 10.373 1.66668 10.8333V11.6666C1.66668 12.1118 1.84006 12.5303 2.1548 12.8451C2.46962 13.1599 2.88814 13.3333 3.33332 13.3333H11.6666C12.1118 13.3333 12.5303 13.1599 12.8451 12.8451C13.1599 12.5303 13.3333 12.1118 13.3333 11.6666V10.8333C13.3333 10.373 13.7064 9.99991 14.1666 9.99991C14.6269 9.99991 15 10.373 15 10.8333V11.6666C15 12.557 14.6533 13.394 14.0236 14.0236C13.394 14.6533 12.557 15 11.6666 15ZM7.49998 11.6666C7.38471 11.6666 7.27496 11.6432 7.17512 11.6009C7.08196 11.5616 6.99443 11.5044 6.91784 11.4296C6.91781 11.4296 6.91781 11.4295 6.91778 11.4295C6.91723 11.429 6.91667 11.4285 6.91612 11.4279C6.91598 11.4278 6.9158 11.4276 6.91566 11.4274C6.91519 11.427 6.91478 11.4266 6.91435 11.4262C6.91405 11.4259 6.91379 11.4256 6.9135 11.4253C6.91321 11.425 6.91283 11.4247 6.91257 11.4244C6.91199 11.4238 6.91135 11.4232 6.91077 11.4226L3.57741 8.08923C3.25199 7.76381 3.25199 7.23616 3.57741 6.91071C3.90284 6.58528 4.43052 6.58525 4.75594 6.91071L6.66668 8.82144V0.833338C6.66665 0.373077 7.03972 0 7.49998 0C7.96025 0 8.33335 0.373077 8.33335 0.833338V8.82141L10.2441 6.91071C10.5695 6.58528 11.0972 6.58528 11.4226 6.91071C11.748 7.23613 11.748 7.76381 11.4226 8.08923L8.08923 11.4226C8.08865 11.4231 8.08801 11.4238 8.08743 11.4244C8.08711 11.4247 8.08676 11.425 8.0865 11.4253C8.08621 11.4256 8.08594 11.4258 8.08565 11.4261C8.08525 11.4266 8.08478 11.427 8.08437 11.4274C8.08423 11.4275 8.08402 11.4277 8.08388 11.4279C8.08335 11.4284 8.0828 11.429 8.08225 11.4295C8.08222 11.4295 8.08222 11.4295 8.08219 11.4295C8.07302 11.4385 8.06373 11.4472 8.05424 11.4556C7.98447 11.5178 7.90674 11.5664 7.82456 11.601C7.82427 11.6011 7.82404 11.6012 7.82375 11.6014C7.82343 11.6015 7.82317 11.6016 7.82285 11.6017C7.72352 11.6436 7.61448 11.6666 7.49998 11.6666Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4">
          <rect width="15" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

IconDownload.defaultProps = { className: null };

const { string } = PropTypes;

IconDownload.propTypes = {
  className: string,
};

export default IconDownload;
