import React, { useState } from 'react';
import { compose } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import { NamedLink } from '../../../components';
import config from '../../../config';
import { withViewport } from '../../../util/contextHelpers';
import { createSlug } from '../../../util/urlHelpers';

import Next from './Next';
import Prev from './Prev';

import clockImg from './clock.svg';
import css from './ScheduleSection.css';

const { sportsConfig } = config;

function ScheduleSection({ data, viewport }) {
  const [selectedDate, setSelectedDate] = useState(moment());
  const [mobileSelectedDay, setMobileSelectedDate] = useState(moment());

  const firstDayOfWeek = selectedDate.clone().weekday(0);
  const lastDayOfWeek = selectedDate.clone().weekday(6);

  const formattedMonday = firstDayOfWeek.format('D.MM.YYYY');
  const formattedSunday = lastDayOfWeek.format('D.MM.YYYY');
  const selectedDateRange = `${formattedMonday} - ${formattedSunday}`;

  const isMobile = viewport.width <= 1024

  const getTime = date => {
    const hour = moment(date).hour()
    const minutes = moment(date).minutes() < 10 ? `0${moment(date).minutes()}`:moment(date).minutes()
    return `${hour}:${minutes}`
  }
  
  const getListingsFromDay = day => {
    const filteredData = data.filter(item =>
      moment(item.attributes.publicData.date).isSame(moment(day), 'day')
    )
    return filteredData
  }

  const indexes = [ 0, 1, 2, 3, 4, 5, 6]
  const selectedRangeTimestamps = indexes.map(index => moment(firstDayOfWeek).clone().add(index, 'days').unix() * 1000)

  const onPrev = () => {
    const newStartDay = firstDayOfWeek.clone().add(-7, 'days')
    setSelectedDate(newStartDay)
  }

  const onNext = () => {
    const newStartDay = lastDayOfWeek.clone().add(1, 'days')
    setSelectedDate(newStartDay)
  }

  const onMobilePrev = () => {
    const newStartDay = mobileSelectedDay.clone().add(-1, 'days')
    setMobileSelectedDate(newStartDay)
  }

  const onMobileNext = () => {
    const newStartDay = mobileSelectedDay.clone().add(1, 'days')
    setMobileSelectedDate(newStartDay)
  }
  
  return (
    <section className={css.section}>
      <h2 className={css.title}>Harmonogram treningów</h2>
      {
        isMobile ? (
          <>
            <div className={css.mobileDateSelection}>
              <span>{mobileSelectedDay.format('D.MM.YYYY')}</span>
              <div className={css.arrowWrapper}>
                <a onClick={onMobilePrev} className={css.mobilePrev}><Prev color="#fff" /></a>
                <a onClick={onMobileNext} className={css.mobileNext}><Next color="#fff" /></a>
              </div>
            </div>
            <div className={css.mobileScheduleWrapper}>
              <div className={css.day}>
                <span className={css.dayTitle}>{mobileSelectedDay.format('dddd')}</span>
                <div className={css.eventsWrapper}>
                  {getListingsFromDay(mobileSelectedDay).length > 0 ? getListingsFromDay(mobileSelectedDay).map(event =>
                    <NamedLink name="ListingPage" params={{ id: event.id.uuid, slug: createSlug(event.attributes.title) }}>
                      <Event
                        key={event.id.uuid}
                        title={event.attributes.title}
                        time={getTime(event.attributes.publicData.date)}
                        type={event.attributes.publicData.sport} />
                    </NamedLink>
                  ) : <NoEventPlaceholder />}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={css.dateSelection}>
              <a onClick={onPrev} className={css.prev}><Prev /></a>
              <span className={css.selectedRange}>{selectedDateRange}</span>
              <a onClick={onNext} className={css.next}><Next /></a>
            </div>
            <div className={css.scheduleWrapper}>
            {
              selectedRangeTimestamps.map(day => {
                return (
                  <div className={css.day}>
                    <span className={css.dayTitle}>
                      {moment(day).format('dddd')}
                      <span>{moment(day).format('D.MM.YYYY')}</span>
                    </span>
                    <div className={css.eventsWrapper}>
                      {getListingsFromDay(day).map(event =>
                        <NamedLink name="ListingPage" params={{ id: event.id.uuid, slug: createSlug(event.attributes.title)}}>
                          <Event
                            key={event.id.uuid}
                            title={event.attributes.title}
                            time={getTime(event.attributes.publicData.date)}
                            type={event.attributes.publicData.sport}
                            forKids={event.attributes.publicData && event.attributes.publicData.max_age <= 18}
                            webinar={event.attributes.metadata && event.attributes.metadata.webinar}
                          />                     
                        </NamedLink>
                      )}
                    </div>
                  </div>
                )}
              )}
            </div>
          </>
          )
      }
    </section>
  )
}

export default compose(withViewport)(ScheduleSection)

const Event = ({ title, time, type, forKids, webinar }) => (
  <div className={css.eventWrapper}>
    <div className={classNames(css.event, { [css.noRadius]: forKids })}>
      <div className={css.eventIcon} style={{ backgroundColor: sportsConfig[type].color }}>
        <img src={sportsConfig[type].icon} />
      </div>
      <span className={css.eventTitle}>{title}</span>
      <span className={css.eventTime}><img src={clockImg} />{time}</span>
    </div>
    {forKids && <div className={css.forKids}>Dla dzieci</div>}
    {webinar && <div className={css.webinar}>Webinar</div>}
  </div>
)

const NoEventPlaceholder = () => <div className={css.placeholder}>Brak wydarzeń</div>