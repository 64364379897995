import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ReactComponent as CheckIcon } from '../../assets/transactionPage/check.svg';

export const config = (processState, isConsumer, listingData, customerName, handleCancel, css) => {
  switch (processState) {
    case 'accepted':
      return isConsumer
        ? {
            header: (
              <p className={css.contentText}>
                {/* <CheckIcon /> */}
                Jesteś w grze! Dokonano zapisu na
                <Link
                  className={css.link}
                  to={`/l/${listingData.id.uuid}`}
                >{` ${listingData.attributes.title}`}</Link>
              </p>
            ),
            location: true,
            haveQuestionsButton: true,
            activeBanner: false,
            recommendations: true,
            cancelEventButton: false,
          }
        : {
            header: (
              <p>
                Zgłoszenie od {customerName} na{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                jest kompletne.
              </p>
            ),
            location: '',
            haveQuestionsButton: '',
            activeBanner: '',
            recommendations: '',
            cancelEventButton: (
              <button onClick={handleCancel} className={css.cancelButton}>
                Anuluj zgłoszenie
              </button>
            ),
          };
    case 'sent':
      return isConsumer
        ? {
            header: (
              <p className={css.contentText}>
                {/* <CheckIcon /> */}
                Jesteś w grze! Dokonano zapisu na
                <Link
                  className={css.link}
                  to={`/l/${listingData.id.uuid}`}
                >{` ${listingData.attributes.title}`}</Link>
              </p>
            ),
            location: true,
            haveQuestionsButton: true,
            activeBanner: false,
            recommendations: true,
            cancelEventButton: false,
          }
        : {
            header: (
              <p>
                Zgłoszenie od {customerName} na{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                jest kompletne.
              </p>
            ),
            location: '',
            haveQuestionsButton: '',
            activeBanner: '',
            recommendations: '',
            cancelEventButton: (
              <button onClick={handleCancel} className={css.cancelButton}>
                Anuluj zgłoszenie
              </button>
            ),
          };
    case 'pending-payment':
      return isConsumer
        ? {
            header: <p>Nie potwierdzono jeszcze płatności</p>,
            location: '',
            haveQuestionsButton: '',
            activeBanner: false,
            recommendations: true,
            cancelEventButton: false,
          }
        : {
            header: (
              <p>
                Płatność za{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                nie została jeszcze potwierdzona
              </p>
            ),
            location: '',
            haveQuestionsButton: '',
            activeBanner: '',
            recommendations: '',
            cancelEventButton: '',
          };
    case 'payment-expired':
      return isConsumer
        ? {
            header: <p>Płatność nie została potwierdzona na czas</p>,
            location: false,
            haveQuestionsButton: false,
            activeBanner: false,
            recommendations: false,
            cancelEventButton: false,
          }
        : {
            header: (
              <p>
                Płatność za{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                nie została potwierdzona na czas
              </p>
            ),
            location: '',
            haveQuestionsButton: '',
            activeBanner: '',
            recommendations: '',
            cancelEventButton: '',
          };
    case 'cancelled':
      return isConsumer
        ? {
            header: (
              <p>
                {customerName}, Twoje zgłoszenie na{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                zostało anulowane.
              </p>
            ),
            location: false,
            haveQuestionsButton: false,
            activeBanner: false,
            recommendations: false,
            cancelEventButton: false,
          }
        : {
            header: (
              <p>
                Zgłoszenie {customerName} na{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                zostało anulowane.
              </p>
            ),
            location: '',
            haveQuestionsButton: '',
            activeBanner: '',
            recommendations: '',
            cancelEventButton: '',
          };
    case 'reviewed':
      return isConsumer
        ? {
            header: (
              <p>
                {customerName}, wydarzenie{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                dobiegło końca.
              </p>
            ),
            location: false,
            haveQuestionsButton: false,
            activeBanner: false,
            recommendations: false,
            cancelEventButton: false,
          }
        : {
            header: (
              <p>
                Zgłoszenie od {customerName} na{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                jest kompletne.
              </p>
            ),
            location: '',
            haveQuestionsButton: '',
            activeBanner: '',
            recommendations: '',
            cancelEventButton: '',
          };
    case 'delivered':
      return isConsumer
        ? {
            header: (
              <p>
                Oceń wydarzenie{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>
              </p>
            ),
            location: false,
            haveQuestionsButton: false,
            activeBanner: false,
            recommendations: false,
            cancelEventButton: false,
            review: true,
          }
        : {
            header: (
              <p>
                Zgłoszenie od {customerName} na{' '}
                <Link to={`/l/${listingData.id.uuid}`}>{` ${listingData.attributes.title}`}</Link>{' '}
                jest kompletne.
              </p>
            ),
            location: '',
            haveQuestionsButton: '',
            activeBanner: '',
            recommendations: '',
            cancelEventButton: '',
            review: true,
          };
  }
};
