import React, { useState } from 'react';
import Buttons from '../Buttons/Buttons';
import css from './CheckboxField.css';
import { createFormElement } from '../utils';

const CheckboxField = ({
  setSelectedTypeOfQuestion,
  setIsOpenAddQuestion,
  setStep,
  setFormElements,
  elementData,
}) => {
  const [title, setTitle] = useState((elementData && elementData.title) || '');

  const [option, setoption] = useState('');

  const [invalidValueTitle, setInvalidValueTitle] = useState(null);
  const [invalidValueOption, setInvalidValueOption] = useState(null);

  const [options, setoptions] = useState(
    (elementData && elementData.options) || []
  );
  const [isValidateError, setIsValidateError] = useState(false);

  const handleTitleonChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setInvalidValueTitle('pole wymagane');
      setTitle(e.target.value);
    } else if (e.target.value.length > 50) {
      setInvalidValueTitle('maksymalna długość to 50 znaków');
    } else {
      setTitle(e.target.value);
    }
  };

  const handleOptionChange = (e) => {
    if (e.target.value.length > 50) {
      setInvalidValueOption('maksymalna długość to 50 znaków');
    } else {
      setoption(e.target.value);
    }
  };

  const handleAddOption = (option) => {
    if (option.trim().length === 0) {
      setInvalidValueOption('pole wymagane');
    } else if (options.length > 19) {
      setInvalidValueOption('maksymalnie 20 opcji');
    } else {
      setoptions((options) => [...options, option]);
      setoption('');
      setInvalidValueOption(null);
    }
  };

  const handleDeleteOption = (option) => {
    setoptions((options) => options.filter((o) => o !== option));
  };

  const hadnleCreateSelectElement = () => {

    const newelementData = {
      title,
      options,
      selectedTypeOfQuestion: 'checkboxField',
    }

    const element = createFormElement(newelementData)

    const elementToSave = {
      id: title,
      elementData: newelementData,
      element
    };

    if (title && options.length) {
      setIsValidateError(false);
      setFormElements((prev) => {
        if (elementData && elementData.title) {
          let elementToUpdate = prev.findIndex(
            (el) => el.id === elementData.title
          );
          const copy = prev;
          copy[elementToUpdate] = elementToSave;
          return copy;
        } else {
          return [...prev, elementToSave];
        }
      });
      setIsOpenAddQuestion(false);
      setSelectedTypeOfQuestion(null);
      setStep(null);
    } else {
      setIsValidateError(true);
    }
  };

  return (
    <>
      <h2>Dostosuj pole wielokrotnego wyboru</h2>
      <label>
        Nadaj tytuł (przykład: Dyscypliny sportu)
        <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
        <input type="text" value={title} onChange={handleTitleonChange}></input>
        {invalidValueTitle && (
          <span className={css.invalidmessage}>{invalidValueTitle}</span>
        )}
      </label>

      <ul>
        {!!options.length && 'Dodane opcje:'}
        {options.map((option) => {
          return (
            <li className={css.option} key={option}>
              {option}
              <button
                className={css.deletebutton}
                onClick={() => handleDeleteOption(option)}
              >
                usuń
              </button>
            </li>
          );
        })}
      </ul>
      <label>
        Dodaj odpowiedzi:
        <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
        <div className={css.optioninput}>
          <input
            type="text"
            value={option}
            onChange={handleOptionChange}
          ></input>{' '}
          <button
            className={css.addbutton}
            onClick={() => handleAddOption(option)}
          >
            dodaj
          </button>
        </div>
        {invalidValueOption && (
          <span className={css.invalidmessage}>{invalidValueOption}</span>
        )}
      </label>
      {isValidateError && (
        <p style={{ color: 'red' }}>Uzupełnij wymagane pola</p>
      )}
      <Buttons
        setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
        setIsOpenAddQuestion={setIsOpenAddQuestion}
        setStep={setStep}
        hadnleCreateSelectElement={hadnleCreateSelectElement}
        isEdited={!!elementData}
      />
    </>
  );
};

export default CheckboxField;
