import React from 'react';
import { useParams } from 'react-router-dom';
import SelectStep from '../components/SelectStep';
import { useFormContext } from './context';
import css from './styles.css';

function FormManager() {
  const { step } = useParams();
  const { moveToStep, steps, formData, type } = useFormContext();
  const stepComponent = steps[step].component;

  return (
    <div className={css.formContainer}>
      <SelectStep
        steps={steps}
        setFormStep={moveToStep}
        formData={formData}
        step={step}
        type={type}
      />
      {stepComponent ? stepComponent : <div>Invalid step</div>}
    </div>
  );
}

export default FormManager;
