import React from 'react';
import PropTypes from 'prop-types';

const IconStar = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
    >
      <path
        fill="#FDC62E"
        fillRule="evenodd"
        d="M4.123 3.673L5.552.78a.5.5 0 01.896 0l1.43 2.894 3.194.467a.5.5 0 01.277.853L9.037 7.245l.546 3.18a.5.5 0 01-.726.528L6 9.45l-2.857 1.503a.5.5 0 01-.726-.528l.546-3.18L.65 4.993a.5.5 0 01.277-.853l3.195-.467z"
      />
    </svg>
  );
};

IconStar.defaultProps = { className: null };

const { string } = PropTypes;

IconStar.propTypes = {
  className: string,
};

export default IconStar;