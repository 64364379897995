export const getTransactionData = data => {
  const unitPrice = data.data.attributes.lineItems.find(item => item.code === 'booking')
    ? data.data.attributes.lineItems.find(item => item.code === 'booking').unitPrice.amount
    : 0;
  const unitPriceQuantity = data.data.attributes.lineItems.find(item => item.code === 'booking')
    ? data.data.attributes.lineItems.find(item => item.code === 'booking').quantity
    : 0;
  const reducedUnitPrice = data.data.attributes.lineItems.find(
    item => item.code === 'booking-reduced'
  )
    ? data.data.attributes.lineItems.find(item => item.code === 'booking-reduced').unitPrice.amount
    : 0;
  const reducePriceQuantity = data.data.attributes.lineItems.find(
    item => item.code === 'booking-reduced'
  )
    ? data.data.attributes.lineItems.find(item => item.code === 'booking-reduced').quantity
    : 0;
  const provission = data.data.attributes.lineItems.find(item => item.code === 'service-provision')
    ? data.data.attributes.lineItems.find(item => item.code === 'service-provision').unitPrice
        .amount
    : 0;
  const provissionQuantity = data.data.attributes.lineItems.find(
    item => item.code === 'service-provision'
  )
    ? data.data.attributes.lineItems.find(item => item.code === 'service-provision').quantity
    : 0;

  const priceData = {
    unitPrice,
    unitPriceQuantity,
    reducedUnitPrice,
    reducePriceQuantity,
    provission,
    provissionQuantity,
    transactionId: data.data.id.uuid,
  };

  return {
    priceData,
    customerData: data.data.attributes.customerData,
    stripePaymentIntent: data.data.attributes.stripePaymentIntent,
  };
};
