import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconChevronDown.css';

const IconChevronDown = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg className={classes} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path fill="#2A374A" d="M17.293 8.293a1 1 0 011.414 1.414l-6 6a1 1 0 01-1.414 0l-6-6a1 1 0 011.414-1.414L12 13.586l5.293-5.293z"/>
    </svg>
  );
};

IconChevronDown.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconChevronDown.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconChevronDown;
