export function findKeyByName(config, name) {
    for (const key in config) {
        if (removePolishCharacters(config[key].name.toLowerCase()) === removePolishCharacters(name.toLowerCase())) {
            return key;
        }
    }
    return null;
}

export function removePolishCharacters(text) {
    const polishToAscii = {
        'ą': 'a', 'ć': 'c', 'ę': 'e', 'ł': 'l', 'ń': 'n', 'ó': 'o', 'ś': 's', 'ź': 'z', 'ż': 'z',
        'Ą': 'A', 'Ć': 'C', 'Ę': 'E', 'Ł': 'L', 'Ń': 'N', 'Ó': 'O', 'Ś': 'S', 'Ź': 'Z', 'Ż': 'Z'
    };

    return text.split('').map(char => polishToAscii[char] || char).join('');
}