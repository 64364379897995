import React, { useState, useEffect } from 'react';
import css from './EditListingAvailabilityPanel.css';
import CloseIcon from '../../containers/OrganizerPage/CloseIcon';
import DateInput from '../FieldDateInput/DateInput';
import FieldDateInput from '../FieldDateInput/FieldDateInput';
import DateTimeInput from '../../forms/EditListingAvailabilityForm/EditDateTimeField';
import { required } from '../../util/validators';
import IconInfo from '../IconInfo/IconInfo';
import IconInfoSecondary from '../IconInfo/IconInfoSecondary';

function generateDates(startDate, frequency, endDate = null) {
    const result = [];
    const today = new Date(startDate);
    const sixMonthsLater = new Date(new Date().setMonth(today.getMonth() + 6));
    const endCondition = endDate ? new Date(endDate) : sixMonthsLater;

    let currentDate = new Date(startDate);

    if (!!frequency) while (currentDate <= endCondition) {
        result.push(new Date(currentDate));

        switch (frequency) {
            case 'day':
                currentDate.setDate(currentDate.getDate() + 1);
                break;
            case 'week':
                currentDate.setDate(currentDate.getDate() + 7);
                break;
            case '2weeks':
                currentDate.setDate(currentDate.getDate() + 14);
                break;
            case '3weeks':
                currentDate.setDate(currentDate.getDate() + 21);
                break;
            case 'month':
                currentDate.setMonth(currentDate.getMonth() + 1);
                break;
            default:
                throw new Error('Nieprawidłowa częstotliwość');
        }
    }

    return result.map(date => date.toLocaleString("pl-PL", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    }).replace(" ", "T"));
}



const TerminItem = ({ date, limit, setTermins, duration, itemId, availability }) => {

    const [localLimit, setLocalLimit] = useState(limit)
    const [localDuration, setLocalDuration] = useState(duration)
    const [isRequiredAddNewTerminInfo, setIsRequiredAddNewTerminInfo] = useState(false)
    const handleRemove = (e) => {

        Object.values(availability.calendar)[0].exceptions
            .filter(item => {
                return (new Date(item.availabilityException.attributes.start)).getTime() === (new Date(date)).getTime()
            })
            .forEach((item) => {
                availability.onDeleteAvailabilityException({
                    currentException: item,
                    id: item.availabilityException.id.uuid
                })
            })
        setTermins(prev => prev.filter(({ id }) => id !== itemId))
    }


    const handleUpdate = (e) => {
        setLocalLimit(e.currentTarget.value)
    }

    useEffect(() => {
        setTermins(prev => prev.map((termin) => {

            if (termin.id === itemId) {
                return ({ ...termin, limit: localLimit, duration: localDuration })
            } else {
                return termin
            }
        }
        ));
        (localLimit !== limit || localDuration !== duration) && Object.values(availability.calendar)[0].exceptions
            .filter(item => {
                return (new Date(item.availabilityException.attributes.start)).getTime() === (new Date(date)).getTime()
            })
            .forEach((item) => {
                item && availability.onDeleteAvailabilityException({
                    currentException: item,
                    id: item.availabilityException.id.uuid
                })
            })

    }, [localLimit, localDuration])

    const isMobileView = window.matchMedia('(max-width: 768px)').matches;

    const today = new Date()

    const formatedDate = date.toLocaleString("pl-PL", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    })

    return <div className={css.terminItem}>
        {isMobileView ? (
            <div style={{
                position: 'relative',
                borderRadius: '20px', margin: '10px 0', padding: '10px',
                background: '#FFF', boxShadow: '0px 4px 30px 0px rgba(163, 163, 163, 0.25'
            }}>
                <div style={{
                    display: 'flex', justifyContent: 'space-between'
                }}>
                    <div>
                        <p style={{ marginRight: '30px' }}>{formatedDate.replace(" ", "T").split('T')[0]}</p>
                        <p>godz. {formatedDate.replace(" ", "T").split('T')[1]}</p>
                    </div>
                    {new Date(date) >= today && <span onClick={handleRemove} className={css.closeButton}><CloseIcon /> Usuń</span>}
                </div>
                <p style={{ width: '100%', textAlign: 'center' }}>Limit uczestników: <input style={{ maxWidth: '80px', marginLeft: '10px' }} type='number' min={1} value={localLimit ? localLimit : 'bez limitu'} onChange={handleUpdate} /></p>
                <p
                    style={{ width: '100%', textAlign: 'center' }}
                    onMouseOver={() => setIsRequiredAddNewTerminInfo(true)}
                    onMouseLeave={() => setIsRequiredAddNewTerminInfo(false)}
                >
                    Czas trwania: <input style={{ maxWidth: '80px', marginLeft: '10px' }} type='number' min={5} value={localDuration} disabled />
                    {/* <span
                        style={{ marginLeft: '10px' }}
                        onMouseOver={() => setIsRequiredAddNewTerminInfo(true)}
                        onMouseLeave={() => setIsRequiredAddNewTerminInfo(false)}
                    >
                        <IconInfoSecondary />
                    </span> */}
                </p>
                {isRequiredAddNewTerminInfo && <p style={{ position: 'absolute', top: '20px', left: '50px', fontSize: '14px', lineHeight: '1.1', background: '#FFF', padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 30px 0px rgba(163, 163, 163, 0.25)' }}>Aby wydłużyć czas trwania wydarzenia, dodaj nowy termin</p>}


            </div>
        ) : (
            <>
                <div style={{ position: 'relative' }}>
                    <span style={{ marginRight: '30px' }}>{formatedDate.replace(" ", "T").split('T').join(' godz. ')}</span>
                    {/* <span>Limit uczestników: {limit ? limit : 'bez limitu'}</span> */}
                    <span style={{ display: 'flex', alignItems: 'center' }}>Limit uczestników: <input style={{ maxWidth: '80px', marginLeft: '10px' }} type='number' min={1} value={localLimit ? localLimit : 'bez limitu'} onChange={handleUpdate} /></span>
                    <span
                        style={{ display: 'flex', alignItems: 'center' }}
                        onMouseOver={() => setIsRequiredAddNewTerminInfo(true)}
                        onMouseLeave={() => setIsRequiredAddNewTerminInfo(false)}
                    >
                        Czas trwania: <input style={{ maxWidth: '80px', marginLeft: '10px' }} type='number' min={5} value={localDuration} disabled /></span>
                    {/* <span
                        style={{ marginLeft: '10px' }}
                        onMouseOver={() => setIsRequiredAddNewTerminInfo(true)}
                        onMouseLeave={() => setIsRequiredAddNewTerminInfo(false)}
                    >
                        <IconInfoSecondary />
                    </span> */}

                    {isRequiredAddNewTerminInfo && <span style={{ position: 'absolute', top: '20px', left: '50px', fontSize: '14px', lineHeight: '1.1', background: '#FFF', padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 30px 0px rgba(163, 163, 163, 0.25)' }}>Aby wydłużyć czas trwania wydarzenia, dodaj nowy termin</span>}


                </div>
                {new Date(date) >= today && <span onClick={handleRemove} className={css.closeButton}><CloseIcon /> Usuń</span>}
            </>
        )}
    </div>
}


const AddTerminForm = ({ termins, setTermins, availability }) => {
    const [datesLimit, setDatesLimit] = useState(150)
    const [isAddTerminModalOpen, setIsAddTerminModalOpen] = useState(false)
    // const [termins, setTermins] = useState([])
    const [validationError, setValidationError] = useState(false)
    const [date, setDate] = useState()
    const [endDate, setEndDate] = useState()
    const [limit, setLimit] = useState('')
    const [period, setPeriod] = useState(0)
    const [terminAdded, setTerminAdded] = useState(false)
    const [isLimitReached, setIsLimitReached] = useState(false)
    const [terminExist, setTerminExist] = useState(false)
    const [duration, setDuration] = useState("")
    const [ValidationDurationError, setValidationDurationError] = useState(false)
    const [isInfoVisible, setIsInfoVisible] = useState(false)
    // const [isRequiredAddNewTerminInfo, setIsRequiredAddNewTerminInfo] = useState(false)

    const requiredMsg = required('To pole jest wymagane');



    const handleSubmit = (e) => {

        Object.values(availability.calendar)[0].exceptions.forEach((item) => {
            availability.onDeleteAvailabilityException({
                currentException: item,
                id: item.availabilityException.id.uuid
            })
        })

        e.preventDefault()
        if (!date || date.getMinutes() % 5 !== 0) {
            setValidationError(true)
            return
        }
        if (!duration || Number(duration) % 5 !== 0) {
            setValidationDurationError(true)
            return
        }
        const formatedDate = (new Date(date)).toLocaleString("pl-PL", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        }).replace(" ", "T")

        let isTerminExist = false

        termins.forEach((termin) => {
            const terminDate = new Date(termin.date)
            const endTerminDate = new Date(termin.date)
            endTerminDate.setMinutes(terminDate.getMinutes() + Number(termin.duration))

            if ((new Date(date)).getTime() >= terminDate.getTime() && (new Date(date)).getTime() < (new Date(endTerminDate)).getTime()) {
                setTerminExist(true)
                isTerminExist = true
                return
            }
        })

        if (!(termins.length > 150 || !date || isTerminExist)) {

            setIsAddTerminModalOpen(false)

            if (period) {
                const dates = generateDates(date, period, endDate)
                setTermins(prev => [...prev,
                ...dates.map(date => {
                    return { date, limit, period, duration, id: `${date}_${limit}_${period}` }
                })
                ])
            } else {
                setTermins(prev => [...prev, { date: formatedDate, limit, duration, period, id: `${formatedDate}_${limit}_${period}` }])
            }
            setTerminAdded(true)
            setDate()
            setLimit('')
            setPeriod(0)
            setDuration("")
            setTerminExist(false)
            setValidationError(false)
            setValidationDurationError(false)
        }


    }

    useEffect(() => {
        setDatesLimit(150 - termins.length)

        if (termins.length > 150) {
            setTermins(prev => prev.sort((a, b) => new Date(a.date) - new Date(b.date)).slice(0, 150))
        }

    }, [termins.length])

    return <>

        <div style={{ position: 'relative' }}>
            <p>Dostępny limit terminów: {datesLimit}
                {/* <span
                    style={{ marginLeft: '10px' }}
                    onMouseOver={() => setIsRequiredAddNewTerminInfo(true)}
                    onMouseLeave={() => setIsRequiredAddNewTerminInfo(false)}
                >
                    <IconInfoSecondary />
                </span> */}
            </p>
            {/* {isRequiredAddNewTerminInfo && <p style={{ position: 'absolute', top: '20px', left: '50px', fontSize: '14px', lineHeight: '1.1', background: '#FFF', padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 30px 0px rgba(163, 163, 163, 0.25)' }}>Aby wydłużyć czas trwania wydarzenia, dodaj nowy termin</p>} */}

            <button
                disabled={!datesLimit ? true : false}
                onClick={() => setIsAddTerminModalOpen(true)}
                className={css.addTermin}
                style={!datesLimit ? { background: '#B2B2B2', border: '#B2B2B2' } : {}}
                onMouseOver={() => setIsLimitReached(true)}
                onMouseLeave={() => setIsLimitReached(false)}
            >
                dodaj termin
            </button>
            {isLimitReached && !datesLimit && <p>Limit posiadanych dat w wydarzeniu został przekroczony, stwórz nowe wydarzenie z kolejnymi terminami</p>}
        </div>

        {
            (isAddTerminModalOpen || terminAdded) && <form className={css.dateForm}>
                {!terminAdded ? <>
                    <div className={css.formContainer}>
                        {/* <label className={css.item}> */}
                        {/* Wybierz datę */}
                        {/* <input value={date} onChange={(e) => setDate(e.currentTarget.value)} min={new Date().toISOString().slice(0, 16)} type='datetime-local'></input>
                            {validationError && <p style={{ fontSize: '12px', color: 'red', margin: 0 }}>Pole wymagane</p>} */}
                        {terminExist && <p style={{ color: 'red' }}>Ten termin jest zajęty, wybierz inny.</p>}


                        <DateTimeInput
                            id="dateAndTime"
                            name="dateAndTime"
                            label="Wybierz datę"
                            date={date}
                            handleChange={setDate}
                            validate={requiredMsg}
                        />
                        {validationError && <p style={{ color: 'red' }}>Data wydarzenia jest wymagana.</p>}
                        <label className={css.item} style={{ position: 'relative' }}>
                            Podaj czas trwania wydarzenia (w minutach, co 5 min)
                            <span
                                style={{ marginLeft: '10px' }}
                                onMouseOver={() => setIsInfoVisible(true)}
                                onMouseLeave={() => setIsInfoVisible(false)}
                            >
                                <IconInfoSecondary />
                            </span>
                            {isInfoVisible && <p style={{ position: 'absolute', top: '20px', left: '50px', fontSize: '14px', lineHeight: '1.1', background: '#FFF', padding: '10px', borderRadius: '10px', boxShadow: '0px 4px 30px 0px rgba(163, 163, 163, 0.25)' }}>Jest to czas, w którym uczestnicy po rozpoczęciu wydarzenia będą mogli się zapisywać na Decathlon GO </p>}
                            <input value={duration} onChange={(e) => setDuration(e.currentTarget.value.toString())} min={1} type='number' max={10000} />
                        </label>
                        {ValidationDurationError && <p style={{ color: 'red' }}>Czas trwania  wydarzenia jest wymagany (musi być wielokrotnością 5 min).</p>}

                        <label className={css.item}>
                            Podaj limit uczestników (opcjonalnie)
                            <input value={limit} min={1} onChange={(e) => setLimit(e.currentTarget.value)} type='number' max={10000} />
                        </label>
                        <select style={{ marginTop: '25px', marginBottom: '25px' }} value={period} onChange={e => setPeriod(e.currentTarget.value)}>
                            <option value={0}>Wydarzenie nie powtarza się</option>
                            <option value={'day'}>Codziennie</option>
                            <option value={'week'}>Co tydzień</option>
                            <option value={'2weeks'}>Co 2 tygodnie</option>
                            <option value={'3weeks'}>Co 3 tygodnie</option>
                            <option value={'month'}>Co miesiąc</option>
                        </select>

                        {!!period && <DateTimeInput
                            id="endDateAndTime"
                            name="endDateAndTime"
                            label="Do kiedy"
                            date={endDate}
                            handleChange={setEndDate}
                        // validate={requiredMsg}
                        />}
                    </div>
                    <div className={css.buttonsContainer}>
                        <button className={css.cancel} onClick={() => {
                            setIsAddTerminModalOpen(false)
                            setTerminExist(false)
                            setValidationError(false)
                        }} >Anuluj</button>
                        <button className={css.save} style={{ background: `${!(termins.length > 150 || !date || !duration || duration % 5 !== 0) && '#0082c3'}` }} onClick={handleSubmit}>Zapisz</button>
                    </div></> : <div className={css.buttonsContainer} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                    <p >Termin dodano</p>
                    <button
                        className={css.cancel}
                        onClick={() => {
                            setIsAddTerminModalOpen(false)
                            setTerminAdded(false)
                        }} >Zamknij</button>

                </div>}
            </form>
        }
        <div className={css.terminsContainer}>
            {termins.sort((a, b) => new Date(a.date) - new Date(b.date)).map(({ date, limit, id, duration }) => <TerminItem key={id} date={date} itemId={id} limit={limit} duration={duration} setTermins={setTermins} availability={availability} />)}
        </div>
    </>
}

export default AddTerminForm