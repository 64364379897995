import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';
import config from '../../config';

import css from './EditListingDescriptionForm.css';

const { sportsConfig } = config;

const sports = Object.keys(sportsConfig)
  .map(sport => ({ key: sport, label: sportsConfig[sport].name }))
  .sort((a,b) => a.label.localeCompare(b.label))

const SportSelectField = props => {
  const { name, id, intl } = props;
  const sportLabel = intl.formatMessage({
    id: 'SportSelectField.sportLabel',
  });
  const sportPlaceholder = intl.formatMessage({
    id: 'SportSelectField.sportPlaceholder',
  });
  const sportRequired = required(
    intl.formatMessage({
      id: 'SportSelectField.sportRequired',
    })
  );
  
  return sports ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={sportLabel}
      validate={sportRequired}
    >
      <option disabled value="">
        {sportPlaceholder}
      </option>
      {sports.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default SportSelectField;
