import React, { useState, useEffect } from 'react';
import DateTimeInput from './DateTimeInput';
import { ReactComponent as IconInfoSecondary } from '../../../assets/addEventForm/info.svg';

import css from './styles.css';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { sdkGo } from '../../../queries/api';
import RequestStatusModal from '../RequestStatusModal';
import { updateEventDate } from '../api';

function getCurrentTime(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();

  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  return hours + ':' + minutes;
}

function generateDates(startDate, frequency, endDate = null) {
  const result = [];
  const today = new Date(startDate);
  const sixMonthsLater = new Date(new Date().setMonth(today.getMonth() + 6));
  const endCondition = endDate ? new Date(endDate) : sixMonthsLater;

  let currentDate = new Date(startDate);

  if (!!frequency)
    while (currentDate <= endCondition) {
      result.push(new Date(currentDate));

      switch (frequency) {
        case 'day':
          currentDate.setDate(currentDate.getDate() + 1);
          break;
        case 'week':
          currentDate.setDate(currentDate.getDate() + 7);
          break;
        case '2weeks':
          currentDate.setDate(currentDate.getDate() + 14);
          break;
        case '3weeks':
          currentDate.setDate(currentDate.getDate() + 21);
          break;
        case 'month':
          currentDate.setMonth(currentDate.getMonth() + 1);
          break;
        default:
          throw new Error('Nieprawidłowa częstotliwość');
      }
    }

  return result;
}

const TerminItem = ({ date, limit, setTermins, duration, itemId, dateId }) => {
  const [localLimit, setLocalLimit] = useState(limit);
  const [isRequiredAddNewTerminInfo, setIsRequiredAddNewTerminInfo] = useState(false);
  const [updateSucces, setUpdateSucces] = useState(null);
  const [updateLimitSucces, setUpdateLimitSucces] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const handleRemove = e => {
    if (dateId) {
      setIsButtonDisabled(true);
      sdkGo.listingDates
        .delete({
          id: dateId,
        })
        .then(res => {
          setUpdateSucces('success');
        })
        .catch(error => {
          setUpdateSucces('error');
        });
    } else {
      setUpdateSucces('error');
      // setTermins(prev => prev.filter(({ id }) => id !== itemId));
    }
  };

  const handleUpdate = e => {
    setLocalLimit(e.currentTarget.value);
    updateEventDate({
      id: dateId,
      participantsLimit: Number(e.currentTarget.value),
    })
      .then(res => {
        setUpdateLimitSucces('success');
      })
      .catch(error => {
        setUpdateLimitSucces('error');
      });
  };

  useEffect(() => {
    setTermins(prev =>
      prev.map(termin => {
        if (termin.id === itemId) {
          return { ...termin, limit: localLimit, duration: duration };
        } else {
          return termin;
        }
      })
    );
  }, [localLimit, duration]);

  const today = new Date();

  const formatedDate = new Date(date).toLocaleDateString();

  return (
    <div className={'css.terminItem'}>
      {updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => {
            setUpdateSucces(null);
            setIsButtonDisabled(false);
            setTermins(prev => prev.filter(({ id }) => id !== itemId));
          }}
          infoSuccess={'Termin został usunięty'}
        />
      )}
      {updateLimitSucces && (
        <RequestStatusModal
          status={updateLimitSucces}
          resetStatus={() => {
            setUpdateLimitSucces(null);
          }}
          infoSuccess={'Zaktualizowano limit uczestników'}
        />
      )}
      <div className={css.dateItemContainer}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', fontWeight: 600 }}>
            <p style={{ marginRight: '8px' }}>{formatedDate}</p>
            <p>godz. {getCurrentTime(new Date(date))}</p>
          </div>
          {new Date(date) >= today && (
            <button disabled={isButtonDisabled} onClick={handleRemove} className={css.deleteButton}>
              {' '}
              Usuń
            </button>
          )}
        </div>
        <p style={{ width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center' }}>
          Limit uczestników:
          {localLimit !== 50000 && localLimit !== null ? (
            <input
              style={{ maxWidth: '80px', marginLeft: '10px', border: 'none', padding: 0 }}
              type="number"
              min={1}
              value={localLimit}
              onChange={handleUpdate}
            />
          ) : (
            <input
              style={{ maxWidth: '100px', marginLeft: '10px', border: 'none', padding: 0 }}
              type="text"
              value={'bez limitu'}
              disabled
              onChange={handleUpdate}
            />
          )}
        </p>
        <p
          style={{ width: '100%', textAlign: 'left', display: 'flex', alignItems: 'center' }}
          onMouseOver={() => setIsRequiredAddNewTerminInfo(true)}
          onMouseLeave={() => setIsRequiredAddNewTerminInfo(false)}
        >
          Czas trwania:{' '}
          <input
            style={{ maxWidth: '80px', marginLeft: '10px', border: 'none', padding: 0 }}
            type="number"
            min={5}
            value={duration}
            disabled
          />
        </p>
        {isRequiredAddNewTerminInfo && (
          <p
            style={{
              position: 'absolute',
              top: '20px',
              left: '50px',
              fontSize: '14px',
              lineHeight: '1.1',
              background: '#FFF',
              padding: '10px',
              borderRadius: '10px',
              boxShadow: '0px 4px 30px 0px rgba(163, 163, 163, 0.25)',
            }}
          >
            Aby wydłużyć czas trwania wydarzenia, dodaj nowy termin
          </p>
        )}
      </div>
    </div>
  );
};

const AddTerminForm = ({ termins, setTermins, register, control }) => {
  const [datesLimit, setDatesLimit] = useState(150);
  const [isAddTerminModalOpen, setIsAddTerminModalOpen] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const [date, setDate] = useState();
  const [endDate, setEndDate] = useState();
  const [limit, setLimit] = useState('');
  const [period, setPeriod] = useState(0);
  const [terminAdded, setTerminAdded] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [terminExist, setTerminExist] = useState(false);
  const [duration, setDuration] = useState('');
  const [ValidationDurationError, setValidationDurationError] = useState(false);
  const [isInfoVisible, setIsInfoVisible] = useState(false);

  const requiredMsg = 'To pole jest wymagane';

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const duplicate = query.get('duplicate');

  const handleSubmit = e => {
    e.preventDefault();
    if (!date || date.getMinutes() % 5 !== 0) {
      setValidationError(true);
      return;
    }
    if (!duration || Number(duration) % 5 !== 0) {
      setValidationDurationError(true);
      return;
    }
    const formatedDate = new Date(date);

    let isTerminExist = false;

    termins.forEach(termin => {
      const terminDate = new Date(termin.date);
      const endTerminDate = new Date(termin.date);
      endTerminDate.setMinutes(terminDate.getMinutes() + Number(termin.duration));

      if (
        new Date(date).getTime() >= terminDate.getTime() &&
        new Date(date).getTime() < new Date(endTerminDate).getTime()
      ) {
        setTerminExist(true);
        isTerminExist = true;
        return;
      }
    });

    if (!(termins.length > 150 || !date || isTerminExist)) {
      setIsAddTerminModalOpen(false);

      if (period) {
        const dates = generateDates(date, period, endDate);
        setTermins(prev => [
          ...prev,
          ...dates.map(date => {
            return { date, limit, period, duration, id: `${date}_${limit}_${period}` };
          }),
        ]);
      } else {
        setTermins(prev => [
          ...prev,
          { date: formatedDate, limit, duration, period, id: `${formatedDate}_${limit}_${period}` },
        ]);
      }
      setTerminAdded(true);
      setDate();
      setLimit('');
      setPeriod(0);
      setDuration('');
      setTerminExist(false);
      setValidationError(false);
      setValidationDurationError(false);
    }
  };

  useEffect(() => {
    setDatesLimit(150 - termins.length);

    if (termins.length > 150) {
      setTermins(prev => prev.sort((a, b) => new Date(a.date) - new Date(b.date)).slice(0, 150));
    }
  }, [termins.length]);

  return (
    <>
      <div style={{ position: 'relative' }}>
        {!!duplicate && (
          <div>
            <p>
              <strong>KOPIA WYDARZENIA </strong>Aby je opublikować na stronie:
            </p>
            {/* <p>1. Dodaj regulamin wydarzenia</p> */}
            <p>1. Dodaj daty wydarzenia</p>
            {/* <p>2. Zweryfikuj poprawność pozostałych informacji dotyczących wydarzenia</p> */}
            <p>2. Naciśnij “opublikuj wydarzenie"</p>
          </div>
        )}
        <p>Dostępny limit terminów: {datesLimit}</p>

        <button
          disabled={!datesLimit ? true : false}
          onClick={() => setIsAddTerminModalOpen(true)}
          className={css.addTerminButton}
          style={!datesLimit ? { background: '#B2B2B2', border: '#B2B2B2' } : {}}
          onMouseOver={() => setIsLimitReached(true)}
          onMouseLeave={() => setIsLimitReached(false)}
          type="button"
        >
          Dodaj termin
        </button>
        {isLimitReached && !datesLimit && (
          <p>
            Limit posiadanych dat w wydarzeniu został przekroczony, stwórz nowe wydarzenie z
            kolejnymi terminami
          </p>
        )}
      </div>

      {(isAddTerminModalOpen || terminAdded) && (
        <>
          <div className={css.curtain}></div>
          <div className={css.addTerminModalContainer}>
            {!terminAdded ? (
              <>
                <div style={{ padding: '32px 16px', width: '100%' }}>
                  <h2 className={css.addTerminModalContainerHeader}>
                    Dodaj termin
                    <button
                      style={{ fontWeight: '400' }}
                      onClick={() => {
                        setIsAddTerminModalOpen(false);
                        setTerminExist(false);
                        setValidationError(false);
                      }}
                    >
                      x
                    </button>
                  </h2>

                  {terminExist && (
                    <p style={{ color: 'red' }}>Ten termin jest zajęty, wybierz inny.</p>
                  )}

                  <DateTimeInput
                    id="dateAndTime"
                    name="dateAndTime"
                    label="Termin wydarzenia"
                    date={date}
                    handleChange={setDate}
                    validate={requiredMsg}
                  />
                  {validationError && (
                    <p style={{ color: 'red' }}>Data wydarzenia jest wymagana.</p>
                  )}
                  <label className={css.addTerminModalContainerLabel}>
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      Podaj czas trwania wydarzenia (w minutach, co 5 min)
                      <span
                        style={{ marginLeft: '8px', width: 'fit-content' }}
                        // className='ml-2 cursor-pointer w-fit'
                        onMouseOver={() => setIsInfoVisible(true)}
                        onMouseLeave={() => setIsInfoVisible(false)}
                      >
                        <IconInfoSecondary />
                      </span>
                    </span>
                    {isInfoVisible && (
                      <p className={css.addTerminModalContainerInfo}>
                        Jest to czas, w którym uczestnicy po rozpoczęciu wydarzenia będą mogli się
                        zapisywać na Decathlon GO
                      </p>
                    )}
                    <input
                      className={css.addTerminModalContainerInput}
                      value={duration}
                      onChange={e => setDuration(e.currentTarget.value.toString())}
                      min={1}
                      type="number"
                      max={minutesUntilMidnight(date)}
                    />
                  </label>
                  {ValidationDurationError && (
                    <p style={{ color: 'red' }}>
                      Czas trwania wydarzenia jest wymagany (musi być wielokrotnością 5 min).
                    </p>
                  )}

                  <label className={css.addTerminModalContainerLabel}>
                    Podaj limit uczestników (opcjonalnie)
                    <input
                      className={css.addTerminModalContainerInput}
                      value={limit}
                      min={1}
                      onChange={e => setLimit(e.currentTarget.value)}
                      type="number"
                      max={10000}
                    />
                  </label>
                  <select
                    className={css.addTerminModalContainerSelect}
                    value={period}
                    onChange={e => setPeriod(e.currentTarget.value)}
                  >
                    <option value={0}>Wydarzenie nie powtarza się</option>
                    <option value={'day'}>Codziennie</option>
                    <option value={'week'}>Co tydzień</option>
                    <option value={'2weeks'}>Co 2 tygodnie</option>
                    <option value={'3weeks'}>Co 3 tygodnie</option>
                    <option value={'month'}>Co miesiąc</option>
                  </select>

                  {!!period && (
                    <DateTimeInput
                      id="endDateAndTime"
                      name="endDateAndTime"
                      label="Do kiedy"
                      date={endDate}
                      handleChange={setEndDate}
                      register={register}
                      control={control}
                      // validate={requiredMsg}
                    />
                  )}
                </div>
                <div className={'css.buttonsContainer'}>
                  <button
                    className={css.addTerminModalContainerAcceptButton}
                    style={{
                      background: `${!(
                        termins.length > 150 ||
                        !date ||
                        !duration ||
                        duration % 5 !== 0
                      ) && '#3643BA'}`,
                    }}
                    onClick={handleSubmit}
                  >
                    Zatwierdź
                  </button>
                </div>
              </>
            ) : (
              <div
                style={{
                  padding: '40px',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                }}
              >
                <p>Termin dodano</p>
                <button
                  className={css.addTerminModalContainerCancelButton}
                  onClick={() => {
                    setIsAddTerminModalOpen(false);
                    setTerminAdded(false);
                  }}
                >
                  Zamknij
                </button>
              </div>
            )}
          </div>
        </>
      )}
      <div className={'css.terminsContainer'}>
        {termins
          .sort((a, b) => new Date(a.date) - new Date(b.date))
          .map(({ date, limit, id, duration, dateId }) => (
            <TerminItem
              key={id}
              date={date}
              itemId={id}
              limit={limit}
              duration={duration}
              setTermins={setTermins}
              dateId={dateId}
              // listingId={listingId}
              // listingTitle={listingTitle}
            />
          ))}
      </div>
    </>
  );
};

export default AddTerminForm;

function minutesUntilMidnight(date) {
  console.log('date', date);
  const now = new Date(date);
  const midnight = new Date(now);
  midnight.setHours(24, 0, 0, 0);

  const millisecondsUntilMidnight = midnight - now;
  const minutesUntilMidnight = Math.floor(millisecondsUntilMidnight / 1000 / 60);

  return minutesUntilMidnight;
}
