import css from './CalculatorPage.css';

const result = {
  default: {
    css: css.default,
    header: '',
    text: ''
  },
  gyno: {
    css: css.correct,
    header: 'Sylwetka gynoidalna, typ "gruszka"',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  andro: {
    css: css.correct,
    header: 'Sylwetka androidalna, typ "jabłko"',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
}

export default function whrHelper(whr, sex) {

  if (!whr || !sex) return result.default;

  if(sex === "male") {
    if(whr > 1) return result.andro;
    return result.gyno;
  }

  if(sex === "female") {
    if(whr > 0.8) return result.andro;
    return result.gyno;
  }
}

