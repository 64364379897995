import React, { useState } from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';

import { NamedLink } from '../../components';
import rfmHelper from './rfmHelper';

import css from './CalculatorPage.css';

const RFM = () => {
  const [rfm, setRfm] = useState(null);
  const [age, setAge] = useState(null);
  const [sex, setSex] = useState(null);

  return (
    <div className={css.calculator}>
      <Formik
        initialValues={{ age: '', sex: null, height: '', circuit: '' }}
        onSubmit={(values) => {
          const rfm = 64 - (20 * (values.height / values.circuit)) + 12 * (values.sex === 'male' ? 0 : 1);
          setRfm(rfm);
          setAge(values.age);
          setSex(values.sex);
        }}
      >
        {({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={css.label} htmlFor="sex">Płeć</div>
            <div className={css.radioWrapper}>
              <span className={css.radioContainer}>
                <input
                  className={css.radioInput}
                  type="radio"
                  id="female"
                  name="sex"
                  value="female"
                  onChange={handleChange}
                />
                <label htmlFor="female" className={css.radioLabel}>Kobieta</label>
              </span>
              <span className={css.radioContainer}>
                <input
                  className={css.radioInput}
                  type="radio"
                  id="male"
                  name="sex"
                  value="male"
                  onChange={handleChange}
                />
                <label htmlFor="male" className={css.radioLabel}>Mężczyzna</label>
              </span>
            </div>

            <label className={css.label} htmlFor="age">Wiek</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="age"
                name="age"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.age}
              />
              <span className={css.inputIcon}>LAT</span>
            </div>

            <label className={css.label} htmlFor="height">Wzrost</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="height"
                name="height"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.height}
              />
              <span className={css.inputIcon}>CM</span>
            </div>
            
            <label className={css.label} htmlFor="circuit">Obwód talii</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="circuit"
                name="circuit"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.circuit}
              />
              <span className={css.inputIcon}>CM</span>
            </div>

            <div className={css.btnContainer}>
              <button className={css.submitCalcBtn} type="submit" disabled={!values.age || !values.sex || !values.height || !values.circuit}>
                Oblicz
              </button>
            </div>
          </form>
        )}
      </Formik>
      <div className={css.resultsContainer}>
        <div className={css.calcResults}>
          <h2 className={css.paragraphHeader}>Twój wskaźnik RFM wynosi</h2>
          <span className={classNames(css.resultNumber, rfmHelper(rfm, age, sex).css)}>{Math.round(rfm * 10) / 10 || '-'}</span>
          <span className={classNames(css.advice, css.adviceHeader)}>Wynik:</span>
          <p className={classNames(css.resultHeader, rfmHelper(rfm, age, sex).css)}>{rfmHelper(rfm, age, sex).header}</p>
        </div>
        <div className={css.otherCalculators}>
          <span>Sprawdź inne kalkulatory: </span>
          <NamedLink name="BMICalculator">
            BMI
          </NamedLink>
          <NamedLink name="WHRCalculator">
            WHR
          </NamedLink>
          <NamedLink name="BMRCalculator">
            BMR
          </NamedLink>
        </div>
      </div>
    </div>
  )
}

export default RFM;
