import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowIcon } from '../../../../assets/searchPage/arrow.svg';
import { CustomCheckbox } from '../../../../forms/components/CustomCheckbox';
import { sportsConfig } from '../../../../sportsConfig';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useReplacePathParam } from '../../hooks/useReplacePathParam';
import { useUpdateQueryParams } from '../../hooks/useUpdateQueryParams';
import { findKeyByName, removePolishCharacters } from '../../utils';
import css from './styles.css';

const Dropdown = ({ options, title, queryParamName, searchParamName, params }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(
    (params[searchParamName] && decodeURIComponent(params[searchParamName]).split(',')) ||
      (params[queryParamName] &&
        params[queryParamName] !== 'wszystkie-sporty' &&
        params[queryParamName].split('_').map(sport => findKeyByName(sportsConfig, sport))) ||
      []
  );

  useEffect(() => {
    if (params[searchParamName] && decodeURIComponent(params[searchParamName]).split(',')) {
      setSelectedOptions(
        params[searchParamName] && decodeURIComponent(params[searchParamName]).split(',')
      );
    } else {
      setSelectedOptions([]);
    }
  }, [params[searchParamName] && decodeURIComponent(params[searchParamName])]);

  // const [searchValue, setSearchValue] = useState('');

  const updateQueryParams = useUpdateQueryParams();
  const replacePathParam = useReplacePathParam();

  const { dropdownRef } = useClickOutside(isOpen, setIsOpen);

  const toggleDropdown = () => {
    setIsOpen(prev => !prev);
  };

  const handleSave = () => {
    toggleDropdown();
    if (searchParamName) {
      updateQueryParams(searchParamName, selectedOptions);
    }
    if (queryParamName) {
      if (queryParamName === 'sport') {
        const newValue = selectedOptions
          .map(option => sportsConfig[option].name.toLowerCase().replaceAll(' ', '-'))
          .join('_');
        replacePathParam(
          queryParamName,
          newValue ? removePolishCharacters(newValue) : 'wszystkie-sporty'
        );
      } else {
        replacePathParam(queryParamName, selectedOptions.join('_'));
      }
    }
  };

  const handleOptionChange = option => {
    setSelectedOptions(prevSelected =>
      prevSelected.includes(option)
        ? prevSelected.filter(item => item !== option)
        : [...prevSelected, option]
    );
  };

  return (
    <div className={css.dropdown} ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={classNames(css.dropdownToggle, isOpen ? css.active : null)}
      >
        {title}
        {isOpen ? (
          <ArrowIcon style={{ marginLeft: '10px' }} />
        ) : (
          <ArrowIcon style={{ transform: 'rotate(180deg)', marginLeft: '10px' }} />
        )}
      </button>
      {isOpen && (
        <div className={css.dropdownMenu}>
          {/* <input
            type="text"
            value={searchValue}
            onChange={e => setSearchValue(e.currentTarget.value)}
            placeholder="Wpisz wyszukiwaną wartość..."
            style={{
              padding: '5px',
              border: 'solid 1px #919191',
              borderRadius: '4px',
              fontSize: '14px',
            }}
          /> */}
          <div style={{ overflowY: 'auto', marginBottom: '40px' }}>
            {options
              // .filter(({ label }) => label.toLowerCase().includes(searchValue))
              .sort((a, b) => a.label.localeCompare(b.label))
              .map((option, index) => (
                <CustomCheckbox
                  key={index}
                  value={selectedOptions.includes(option.value)}
                  onChange={() => handleOptionChange(option.value)}
                  label={`${option.label} ${!!option.count ? `(${option.count})` : ''}`}
                />
              ))}
          </div>
          <div className={css.dropdownFooter}>
            <button
              onClick={() => {
                setSelectedOptions([]);
                // setSearchValue('');
              }}
              className={css.clearButton}
            >
              WYCZYŚĆ
            </button>
            <button onClick={handleSave} className={css.saveButton}>
              ZAPISZ
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
