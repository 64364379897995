import React, { useState, useEffect, useMemo } from "react"
import { recommendationApi } from "./api"

export const useGetRecommendedProducts = (uuid, listingId) => {

    const [products, setProducts] = useState([])
    const [correlationId, setCorrelationId] = useState(null)


    useEffect(() => {

        const getProductsInfo = async () => {
            const { category2, gender, kategoriawiekowa, natureofproduct } = await recommendationApi.getCatalogItemsData(listingId) || {}

            const { products, correlationId } = await recommendationApi.getProducts('9Qn75IAjOiUc', uuid, category2, gender, kategoriawiekowa, natureofproduct)
            setProducts(products || [])
            setCorrelationId(correlationId)

        }

        getProductsInfo()

    }, [])

    return { products, correlationId }

}


export const useIsInViewport = (ref) => {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = (typeof window !== 'undefined') ? new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting),
    ) : null

    useEffect(() => {
        ref.current && observer && observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, [ref, observer]);
    return isIntersecting;
}