import React, { useState } from 'react';
import { compose } from 'redux';
import { object, string, bool, number, func, shape } from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import omit from 'lodash/omit';

import InfoModal from './Modal';

import {
  BookingDateRangeFilter,
  EventDateRangeFilter,
  SelectSingleFilter,
  SelectMultipleFilter,
  PriceFilter,
  KeywordFilter,
  IconInfo,
} from '../../components';
import routeConfiguration from '../../routeConfiguration';
import { parseDateFromISO8601, stringifyDateToISO8601 } from '../../util/dates';
import { createResourceLocatorString } from '../../util/routes';
import { propTypes } from '../../util/types';
import css from './SearchFilters.css';

// Dropdown container can have a positional offset (in pixels)
const FILTER_DROPDOWN_OFFSET = -14;
const RADIX = 10;

// resolve initial value for a single value filter
const initialValue = (queryParams, paramName) => {
  return queryParams[paramName];
};

// resolve initial values for a multi value filter
const initialValues = (queryParams, paramName) => {
  const param = queryParams[paramName];

  return !!param ? param.substring(param.indexOf(':')).split(',') : [];
};

const initialPriceRangeValue = (queryParams, paramName) => {
  const price = queryParams[paramName];
  const valuesFromParams = !!price
    ? price.split(',').map((v) => Number.parseInt(v, RADIX))
    : [];

  return !!price && valuesFromParams.length === 2
    ? {
      minPrice: valuesFromParams[0],
      maxPrice: valuesFromParams[1],
    }
    : null;
};

// const initialDateRangeValue = (queryParams, paramName) => {
//   const dates = queryParams[paramName];
//   const rawValuesFromParams = !!dates ? dates.split(',') : [];
//   const valuesFromParams = rawValuesFromParams.map(v => parseDateFromISO8601(v));
//   const initialValues =
//     !!dates && valuesFromParams.length === 2
//       ? {
//           dates: { startDate: valuesFromParams[0], endDate: valuesFromParams[1] },
//         }
//       : { dates: null };

//   return initialValues;
// };

const initialDateRangeValue = (queryParams, paramName) => {
  const dates = queryParams.pub_date;
  const rawValuesFromParams = !!dates ? dates.toString().split(',') : [];
  const valuesFromParams = rawValuesFromParams.map((v) => {
    const date = new Date(v * 1);
    return parseDateFromISO8601(date).toString();
  });
  const initialValues =
    !!dates && valuesFromParams.length === 2
      ? {
        dates: {
          startDate: valuesFromParams[0],
          endDate: valuesFromParams[1],
        },
      }
      : { dates: null };

  return initialValues;
};

const SearchFiltersComponent = (props) => {
  const {
    rootClassName,
    className,
    urlQueryParams,
    landingData,
    listingsAreLoaded,
    resultsCount,
    searchInProgress,
    categoryFilter,
    sportFilter,
    sportsFilter,
    levelsFilter,
    genderFilter,
    priceFilter,
    dateRangeFilter,
    eventTypeFilter,
    businessTypeFilter,
    keywordFilter,
    sportSupportFilter,
    isSearchFiltersPanelOpen,
    toggleSearchFiltersPanel,
    searchFiltersPanelSelectedCount,
    history,
    intl,
  } = props;

  const [isHovered, setIsHovered] = useState(false)

  const hasNoResult = listingsAreLoaded && resultsCount === 0;
  const classes = classNames(
    rootClassName || css.root,
    { [css.longInfo]: hasNoResult },
    className
  );

  const businessCardsView = urlQueryParams.pub_type === 'business_card';

  const landingDataName = landingData || 'SearchPage';

  const categoryLabel = intl.formatMessage({
    id: 'SearchFilters.categoryLabel',
  });

  const levelsLabel = intl.formatMessage({
    id: 'SearchFilters.levelsLabel',
  });

  const gendersLabel = intl.formatMessage({
    id: 'SearchFilters.genderLabel',
  });


  const sportLabel = intl.formatMessage({
    id: 'SearchFilters.sportLabel',
  });

  const keywordLabel = intl.formatMessage({
    id: 'SearchFilters.keywordLabel',
  });

  const eventTypeLabel = intl.formatMessage({
    id: 'SearchFilters.eventTypeLabel',
  });

  const initialSport = sportFilter
    ? initialValues(urlQueryParams, sportFilter.paramName)
    : null;

  const initialSports = sportsFilter
    ? initialValues(urlQueryParams, sportsFilter.paramName)
    : null;

  const initialLevels = levelsFilter
    ? initialValues(urlQueryParams, levelsFilter.paramName)
    : null;

  const initialGender = genderFilter
    ? initialValues(urlQueryParams, genderFilter.paramName)
    : null;


  const initialCategory = categoryFilter
    ? initialValue(urlQueryParams, categoryFilter.paramName)
    : null;

  const initialPriceRange = priceFilter
    ? initialPriceRangeValue(urlQueryParams, priceFilter.paramName)
    : null;

  const initialDateRange = dateRangeFilter
    ? initialDateRangeValue(urlQueryParams, dateRangeFilter.pub_date)
    : null;

  const initialKeyword = keywordFilter
    ? initialValue(urlQueryParams, keywordFilter.paramName)
    : null;

  const initialEventTypes = eventTypeFilter
    ? initialValues(urlQueryParams, eventTypeFilter.paramName)
    : null;

  const initialBusinessTypes = businessTypeFilter
    ? initialValues(urlQueryParams, businessTypeFilter.paramName)
    : null;

  const initialSportSupport = sportSupportFilter
    ? initialValues(urlQueryParams, sportSupportFilter.paramName)
    : null;

  const handleSelectOptions = (urlParam, options) => {
    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: options.join(',') }
        : omit(urlQueryParams, urlParam);
    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  };

  const handleSelectAnyOptions = (urlParam, options) => {
    const queryParams =
      options && options.length > 0
        ? { ...urlQueryParams, [urlParam]: `has_any:${options.join(',')}` }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  };

  const handleSelectOption = (urlParam, option) => {
    // query parameters after selecting the option
    // if no option is passed, clear the selection for the filter
    const queryParams = option
      ? { ...urlQueryParams, [urlParam]: option }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  };

  const handlePrice = (urlParam, range) => {
    const { minPrice, maxPrice } = range || {};
    const queryParams =
      minPrice != null && maxPrice != null
        ? { ...urlQueryParams, [urlParam]: `${minPrice},${maxPrice}` }
        : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  };

  const handleDateRange = (urlParam, dateRange) => {
    const hasDates = dateRange && dateRange.dates;
    const { startDate, endDate } = hasDates ? dateRange.dates : {};

    const start = startDate ? stringifyDateToISO8601(startDate) : null;
    const end = endDate ? stringifyDateToISO8601(endDate) : null;

    const queryParams =
      start != null && end != null
        ? {
          ...urlQueryParams,
          pub_date: `${new Date(start).setHours(0, 0, 0, 0)},${new Date(
            end
          ).setHours(23, 59, 59, 0)}`,
        }
        : omit(urlQueryParams, 'pub_date');
    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  };

  const handleKeyword = (urlParam, values) => {
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );
  };

  const handleTypeChange = (type) => {
    // const urlParam = sportSupportFilter.paramName;

    // const { pub_sport, pub_sports, pub_event_type, pub_business_type, pub_date, price, ...rest } = urlQueryParams;
    // const queryParams = !initialSportSupport.length
    // ? { ...urlQueryParams, [urlParam]: true }
    // : omit(urlQueryParams, urlParam);
    const typeSpecificParams =
      type === 'event'
        ? omit(urlQueryParams, ['pub_sports', 'pub_business_type'])
        : omit(urlQueryParams, [
          'pub_event_type',
          'pub_sport',
          'pub_date',
          // 'pub_gender',
          'price',
        ]);
    const queryParams = { ...typeSpecificParams, pub_type: type };
    history.push(
      createResourceLocatorString(
        landingDataName,
        routeConfiguration(),
        {},
        queryParams
      )
    );

    // e.target.blur();
  };

  // const categoryFilterElement = categoryFilter ? (
  const categoryFilterElement = false ? (
    <SelectSingleFilter
      urlParam={categoryFilter.paramName}
      label={categoryLabel}
      onSelect={handleSelectOption}
      showAsPopup
      options={categoryFilter.options}
      initialValue={initialCategory}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const sportsFilterElement =
    sportFilter && !businessCardsView ? (
      <SelectMultipleFilter
        id={'SearchFilters.sportFilter'}
        name="sport"
        urlParam={sportFilter.paramName}
        label={sportLabel}
        onSubmit={handleSelectOptions}
        showAsPopup
        options={sportFilter.options}
        initialValues={initialSport}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

  const sportsBusinessType =
    sportsFilter && businessCardsView ? (
      <SelectMultipleFilter
        id="SearchFilters.sportsBusinessFilter"
        name="sports"
        urlParam={sportsFilter.paramName}
        label="Sport"
        onSubmit={handleSelectAnyOptions}
        showAsPopup
        options={sportsFilter.options}
        initialValues={initialSports}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

  const levelsFilterElement = levelsFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.levelsFilter'}
      name="levels"
      urlParam={levelsFilter.paramName}
      label={levelsLabel}
      onSubmit={handleSelectOptions}
      showAsPopup
      options={levelsFilter.options}
      initialValues={initialLevels}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const genderFilterElement = genderFilter ? (
    <SelectMultipleFilter
      id={'SearchFilters.genderFilter'}
      name="gender"
      urlParam={genderFilter.paramName}
      label={gendersLabel}
      onSubmit={handleSelectOptions}
      showAsPopup
      options={genderFilter.options}
      initialValues={initialGender}
      contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
    />
  ) : null;

  const priceFilterElement =
    priceFilter && !businessCardsView ? (
      <PriceFilter
        id="SearchFilters.priceFilter"
        urlParam={priceFilter.paramName}
        onSubmit={handlePrice}
        showAsPopup
        {...priceFilter.config}
        initialValues={initialPriceRange}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

  const dateRangeFilterElement =
    false &&
      dateRangeFilter &&
      dateRangeFilter.config.active &&
      !businessCardsView ? (
      <EventDateRangeFilter
        id="SearchFilters.dateRangeFilter"
        urlParam={dateRangeFilter.paramName}
        onSubmit={handleDateRange}
        showAsPopup
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        initialValues={initialDateRange}
      />
    ) : null;

  const keywordFilterElement =
    keywordFilter && keywordFilter.config.active ? (
      <KeywordFilter
        id={'SearchFilters.keywordFilter'}
        name="keyword"
        urlParam={keywordFilter.paramName}
        label={keywordLabel}
        onSubmit={handleKeyword}
        showAsPopup
        initialValues={initialKeyword}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

  const eventType =
    eventTypeFilter && !businessCardsView ? (
      <SelectMultipleFilter
        id="SearchFilters.eventTypeFilter"
        name="event_type"
        urlParam={eventTypeFilter.paramName}
        label={eventTypeLabel}
        onSubmit={handleSelectOptions}
        showAsPopup
        options={eventTypeFilter.options}
        initialValues={initialEventTypes}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

  const businessType =
    businessTypeFilter && businessCardsView ? (
      <SelectMultipleFilter
        id="SearchFilters.businessTypeFilter"
        name="business_type"
        urlParam={businessTypeFilter.paramName}
        label="Rodzaj działalności"
        onSubmit={handleSelectAnyOptions}
        showAsPopup
        options={businessTypeFilter.options}
        initialValues={initialBusinessTypes}
        contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
      />
    ) : null;

  const sportSupportType = sportSupportFilter ? (
    <>
      <button
        className={classNames(css.label, {
          [css.labelSelected]: !businessCardsView,
        })}
        onClick={() => handleTypeChange('event')}
      >
        Wydarzenia
      </button>
      <button
        className={classNames(css.label, {
          [css.labelSelected]: businessCardsView,
        })}
        onClick={() => handleTypeChange('business_card')}
      >
        Organizatorzy
      </button>

      <span onMouseOver={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} className={classNames(css.searchFilterProductsOrder)}>
        Kolejność produktów{' '}
        <IconInfo className={classNames(css.searchFilterInfoIcon)} />
      </span>
      {isHovered && <InfoModal />}
    </>
  ) : null;

  const toggleSearchFiltersPanelButtonClasses =
    isSearchFiltersPanelOpen || searchFiltersPanelSelectedCount > 0
      ? css.searchFiltersPanelOpen
      : css.searchFiltersPanelClosed;
  const toggleSearchFiltersPanelButton = toggleSearchFiltersPanel ? (
    <button
      className={toggleSearchFiltersPanelButtonClasses}
      onClick={() => {
        toggleSearchFiltersPanel(!isSearchFiltersPanelOpen);
      }}
    >
      <FormattedMessage
        id="SearchFilters.moreFiltersButton"
        values={{ count: searchFiltersPanelSelectedCount }}
      />
    </button>
  ) : null;

  return (
    <div className={classes}>
      <div className={css.filters}>
        <div className={css.topFilters}>{sportSupportType}</div>
        <div className={css.bottomFilters}>
          {/* {categoryFilterElement} */}
          {sportsFilterElement}
          {sportsBusinessType}
          {eventType}
          {businessType}
          {/* {levelsFilterElement} */}
          {genderFilterElement}
          {priceFilterElement}
          {dateRangeFilterElement}
          {/* {keywordFilterElement} */}

          {toggleSearchFiltersPanelButton}
        </div>
      </div>

      {listingsAreLoaded && resultsCount > 0 ? (
        <div className={css.searchResultSummary}>
          <span className={css.resultsFound}>
            {businessCardsView ? (
              <FormattedMessage
                id="SearchFilters.foundResultsBusinessCard"
                values={{ count: resultsCount }}
              />
            ) : (
              <FormattedMessage
                id="SearchFilters.foundResults"
                values={{ count: resultsCount }}
              />
            )}
          </span>
        </div>
      ) : null}

      {hasNoResult ? (
        <div className={css.noSearchResults}>
          {businessCardsView ? (
            <FormattedMessage id="SearchFilters.noResultsBusinesses" />
          ) : (
            <FormattedMessage id="SearchFilters.noResults" />
          )}
        </div>
      ) : null}

      {searchInProgress ? (
        <div className={css.loadingResults}>
          <FormattedMessage id="SearchFilters.loadingResults" />
        </div>
      ) : null}
    </div>
  );
};

SearchFiltersComponent.defaultProps = {
  rootClassName: null,
  className: null,
  landingData: null,
  resultsCount: null,
  searchingInProgress: false,
  categoryFilter: null,
  amenitiesFilter: null,
  eventTypeFilter: null,
  priceFilter: null,
  sportSupportFilter: null,
  dateRangeFilter: null,
  isSearchFiltersPanelOpen: false,
  toggleSearchFiltersPanel: null,
  searchFiltersPanelSelectedCount: 0,
};

SearchFiltersComponent.propTypes = {
  rootClassName: string,
  className: string,
  landingData: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchingInProgress: bool,
  onManageDisableScrolling: func.isRequired,
  categoriesFilter: propTypes.filterConfig,
  amenitiesFilter: propTypes.filterConfig,
  eventTypeFilter: propTypes.filterConfig,
  priceFilter: propTypes.filterConfig,
  sportSupportFilter: propTypes.filterConfig,
  dateRangeFilter: propTypes.filterConfig,
  isSearchFiltersPanelOpen: bool,
  toggleSearchFiltersPanel: func,
  searchFiltersPanelSelectedCount: number,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SearchFilters = compose(withRouter, injectIntl)(SearchFiltersComponent);

export default SearchFilters;
