import React, { useEffect, useState, useMemo, useRef } from 'react';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { array, bool, number, string } from 'prop-types';
import { IconChevronBigLeft, IconChevronBigRight } from '../../components';
import Slider from 'react-slick';
import css from './SectionExternalProducts.css';
import Cookies from 'js-cookie';
import { useGetRecommendedProducts, useIsInViewport } from './hooks';
// import { products } from './mock';
import DotFill from '../IconDots/DotFIll';
import DotEmpty from '../IconDots/DotEmpty';
import { recommendationApi } from './api';

const PrevArrow = props => {
  const { className, onClick, currentPage } = props;

  return (
    currentPage !== 0 && (
      <div className={classNames(className, css.carousel, css.left)} onClick={onClick}>
        <IconChevronBigLeft />
      </div>
    )
  );
};

const NextArrow = props => {
  const { className, onClick, pagesCount, currentPage } = props;

  return (
    currentPage < pagesCount - 1 && (
      <div className={classNames(className, css.carousel, css.right)} onClick={onClick}>
        <IconChevronBigRight />
      </div>
    )
  );
};

const SectionExternalProducts = props => {
  const {
    category,
    description,
    // products,
    sectionClassName,
    slidesToShow,
    sport,
    titleClassName,
    titleVisible,
    urlCampaign,
    listingId,
  } = props;

  const uuid = Cookies.get('_snrs_uuid');

  const { products, correlationId } = useGetRecommendedProducts(uuid, listingId.uuid);

  const [currentPage, setCurrentPage] = useState(0);
  const [pagesCount, setPagesCount] = useState(null);

  useEffect(() => {
    setPagesCount(Math.ceil(products.data ? products.data.length / slidesToShow : 0));
  }, [products]);

  const ref = useRef(null);

  const isInViewport = useIsInViewport(ref);

  useEffect(() => {
    if (isInViewport) {
      const items = [...products.data]
        .splice(currentPage * slidesToShow, slidesToShow)
        .map(({ itemId }) => itemId);
      recommendationApi.sendViewEvent(correlationId, uuid, items);
    }
  }, [isInViewport, currentPage]);

  const sendEventOnClick = itemId => {
    recommendationApi.sendClickEvent(correlationId, uuid, itemId);
  };

  if (!pagesCount) return null;

  const section = sectionClassName || css.section;
  const title = titleClassName || css.sectionTitle;

  const settings = {
    dots: true,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToShow,
    nextArrow: <NextArrow currentPage={currentPage} pagesCount={pagesCount} />,
    prevArrow: <PrevArrow currentPage={currentPage} />,
    afterChange: current => setCurrentPage(current / slidesToShow),
    customPaging: index => {
      return currentPage === index ? (
        <div>
          <DotFill className={css.dot} />
        </div>
      ) : (
        <div>
          <DotEmpty className={css.dot} />
        </div>
      );
    },
  };

  return (
    <div className={section} style={{ paddingBottom: '30px', maxWidth: '80vw' }} ref={ref}>
      {titleVisible && <h2 className={title}>Może Ci się przydać</h2>}
      {description && (
        <div className={css.sectionDesc}>
          Potrzebujesz odzieży, sprzętu lub akcesoriów, które pozwolą Ci uprawiać swój ulubiony
          sport?
        </div>
      )}
      <Slider {...settings} className={css.slider}>
        {products.data.map(product => {
          return (
            product.itemId &&
            product.title &&
            product.imageLink &&
            product.brand &&
            product.price && (
              <a
                href={product.link}
                className={css.productLink}
                key={product.url}
                target="_blank"
                onClick={() => sendEventOnClick(product.itemId)}
              >
                <div className={css.imageContainer}>
                  <LazyLoadImage alt={product.title} src={product.imageLink} threshold={100} />
                </div>
                <p className={css.brand}>{product.brand}</p>
                <div style={{ width: '100%' }}>
                  <p className={css.productName}>{product.title}</p>
                </div>

                {!!Number(product.discount) ? (
                  <div className={css.priceContainer}>
                    <p className={css.price}>
                      <span className={css.promoprice}>{product.salePrice.value} zł</span>
                      <p className={css.dicountcontainer}>
                        <span className={css.oldprice}>{product.price.value} zł</span>
                        <span className={css.discount}>
                          {Math.round(
                            Number(product.salePrice.value) - Number(product.price.value)
                          )}{' '}
                          zł
                        </span>
                      </p>
                    </p>
                    <p className={css.lowestprice}>
                      Najnizsza cena <span>{product.price.value} zł</span>
                    </p>
                  </div>
                ) : (
                  <p className={css.normalprice}>{product.price.value} zł</p>
                )}
              </a>
            )
          );
        })}
      </Slider>
    </div>
  );
};

SectionExternalProducts.defaultProps = {
  category: null,
  description: false,
  titleVisible: true,
  // products: [],
  slidesToShow: 1,
  sport: null,
  urlCampaign: null,
};

SectionExternalProducts.propTypes = {
  category: string,
  description: bool,
  title: bool,
  // products: array,
  slidesToShow: number,
  sport: string,
  urlCampaign: string,
};

export default SectionExternalProducts;
