import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';
import classNames from 'classnames';
import { isScrollingDisabled } from '../../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { loadData, updateListing, downloadReviews } from './ConsoleReviewPage.duck';
import { PaginationLinks, IconSpinner, Page, NamedLink } from '../../../components';
import { parse, stringify } from '../../../util/urlHelpers';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { TopbarContainer } from '../..';

import css from './ConsoleReviewPage.css';
import SearchField from '../utils/SearchField/SearchField';
import ExtendedDataComponent from '../utils/ExtendedData/ExtendedData';
import TopNavigation from '../utils/TopNavigation/TopNavigation';
import ToggleEditField from '../utils/ToggleField/ToggleField';

const ConsoleReviewPageComponent = props => {
  const {
    reviews,
    fetchInProgress,
    scrollingDisabled,
    pagination,
    intl,
    location: { search, pathname },
    history,
    onDownloadReviews,
    isAdmin
  } = props;
  const [fieldOpened, setFieldOpened] = useState(null);
  const [entityOpened, setEntityOpened] = useState(null);

  const handleDownload = () => {
    onDownloadReviews({})
  }

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ConsoleReviewPage"
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  if (!isAdmin) {
    return (
      <Page title="Console" scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
      </Page>
    );
  }

  return (
    <Page title="Console" scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        className={css.topbar}
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        mobileClassName={css.mobileTopbar}
      />
      <div className={css.container}>
        <TopNavigation currentPage="ConsoleReviewPage" />
        {/* <div>
          <SearchField paramName="keywords" />
        </div> */}
        <div className={css.header}>
          <h1>Recenzje</h1>
          <span>{pagination && pagination.totalItems}</span>
          <button onClick={handleDownload} className={css.downloadBtn}>Pobierz</button>
        </div>
        {fetchInProgress ? (
          <div className={css.listItemsLoading}>
            <IconSpinner />
          </div>
        ) : (
          <ul>
            {reviews.map(l => (
              <div
                key={l.id.uuid}
                className={classNames(
                  css.listingWrapper,
                  {
                    [css.entityCollapsed]: entityOpened && entityOpened !== l.id.uuid,
                    [css.entityExpanded]: entityOpened === l.id.uuid,
                  }
                )}
              >
                {console.log(l)}
                <div
                  className={classNames(css.listingRow, { [css.deleted]: l.attributes.deleted })}
                  onClick={() => setEntityOpened(entityOpened === l.id.uuid ? null : l.id.uuid)}
                >
                  <div>
                    {l.attributes.rating}
                  </div>
                  <div className={css.titleWrapper}>
                    <h2 className={css.title}>
                      {`${l.author.attributes.profile.displayName || 'Usunięty użytkownik'}`}
                      <span className={css.text}>ocenił</span>
                      {`${l.listing.attributes.title}` || 'Usunięte wydarzenie'}
                    </h2>
                  </div>
                  <div />
                  <div className={css.createdAt}>
                    {intl.formatDate(l.attributes.createdAt, { day: 'numeric', month: 'numeric', year: 'numeric' })}
                  </div>
                </div>
                {entityOpened === l.id.uuid && (
                  <div className={css.more}>
                    <div className={css.additionalInfo}>
                      <div />
                      <div className={css.additionalContent}>
                        <div>Opinia: {l.attributes.content}</div>
                        <div>
                          Uczestnik:
                          <NamedLink
                            name="ConsoleUserPage"
                            to={{
                              search: `?entityId=${l.author.id.uuid}`,
                            }}
                            className={css.entityLink}
                          >
                            {l.author.attributes.profile.displayName}
                          </NamedLink>
                        </div>
                        <div>
                          Wydarzenie:
                          <NamedLink
                            name="ConsoleListingPage"
                            to={{
                              search: `?entityId=${l.listing.id.uuid}`,
                            }}
                            className={css.entityLink}
                          >
                            {l.listing.attributes.title}
                          </NamedLink>
                        </div>
                        <div>
                          Organizator:
                          <NamedLink
                            name="ConsoleUserPage"
                            to={{
                              search: `?entityId=${l.subject.id.uuid}`,
                            }}
                            className={css.entityLink}
                          >
                            {l.subject.attributes.profile.displayName}
                          </NamedLink>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </ul>
        )}
        {paginationLinks}
      </div>
    </Page>
  );
};

ConsoleReviewPageComponent.defaultProps = {
  fetchInProgress: false,
  fetchUserListError: null,
  pagination: null,
  scrollingDisabled: false,
};

ConsoleReviewPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchUserListError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  listings: arrayOf(propTypes.listing).isRequired,
  intl: intlShape.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
  onUpdateListing: func.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchReviewListError, pagination, reviewList } =
    state.ConsoleReviewPage;
  const isAdmin = state.user.currentUser && state.user.currentUser.attributes.isAdmin;
  return {
    fetchInProgress,
    fetchReviewListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    reviews: getMarketplaceEntities(state, reviewList),
    isAdmin,
  };
};

const mapDispatchToProps = dispatch => ({
  onDownloadReviews: params => dispatch(downloadReviews(params)),
});

const ConsoleReviewPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleReviewPageComponent);

ConsoleReviewPage.loadData = loadData;

export default ConsoleReviewPage;
