import React from 'react';
import css from '../price.css';

const Input = ({ label, value, onChange, type, placeholder }) => {
  return (
    <label className={css.formLabel}>
      {label}
      <input
        min={0}
        defaultValue={0}
        type={type}
        className={css.formInput}
        value={value}
        onChange={e => onChange(e)}
        placeholder={placeholder}
      />
    </label>
  );
};

export default Input;
