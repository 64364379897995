import React from 'react';
import PropTypes from 'prop-types';

const DotFill = props => {
    const { className } = props;
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <circle cx="6" cy="6" r="6" fill="#007DBC" />
        </svg>

    );
};

DotFill.defaultProps = { className: null };

const { string } = PropTypes;

DotFill.propTypes = {
    className: string,
};

export default DotFill;