import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserCard, Modal, NamedLink } from '../../components';
import { EnquiryForm } from '../../forms';

import css from './ListingPage.css';

const SectionHostMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isEnquiryModalOpen,
    onCloseEnquiryModal,
    sendEnquiryError,
    sendEnquiryInProgress,
    onSubmitEnquiry,
    currentUser,
    onManageDisableScrolling,
  } = props;

  if (!listing.author) {
    return null;
  }

  return (
    <div id="host" className={css.sectionHost}>
      <h2 className={css.header2}>
        Opiekun
      </h2>
      <UserCard user={listing.author} currentUser={currentUser} onContactUser={onContactUser} />
      <Modal
        id="ListingPage.enquiry"
        contentClassName={css.enquiryModalContent}
        isOpen={isEnquiryModalOpen}
        onClose={onCloseEnquiryModal}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <EnquiryForm
          className={css.enquiryForm}
          submitButtonWrapperClassName={css.enquirySubmitButtonWrapper}
          listingTitle={title}
          authorDisplayName={authorDisplayName}
          sendEnquiryError={sendEnquiryError}
          onSubmit={onSubmitEnquiry}
          inProgress={sendEnquiryInProgress}
        />
      </Modal>

      {/* <div className={css.linkToOther}>
        <p>To nie do końca jest wydarzenie, którego szukasz?</p>
        <p>
          <NamedLink
            name="SearchPage"
            to={{
              search:
                `address=Polska&bounds=55.98904748%2C24.05383751%2C47.89042442%2C14.14416954&pub_sport=${listing.attributes.publicData.sport}`,
            }}
            className={css.heroButton}
          >
          Sprawdź nasze inne wydarzenia
          </NamedLink>
        </p>
      </div> */}
    </div>
  );
};

export default SectionHostMaybe;
