import React from 'react';
import PropTypes from 'prop-types';

const IconErrorSend = (props) => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
            <path d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60Z" fill="#FF4C38" />
        </svg>
    );
};

IconErrorSend.defaultProps = { className: null };

const { string } = PropTypes;

IconErrorSend.propTypes = {
    className: string,
};

export default IconErrorSend;
