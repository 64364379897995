import React, { Component } from 'react';
import {
  bool,
  func,
  instanceOf,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { pathByRouteName, findRouteByRouteName } from '../../util/routes';
import { propTypes, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import {
  ensureListing,
  ensureCurrentUser,
  ensureUser,
  ensureTransaction,
  ensureBooking,
} from '../../util/data';
import { dateFromLocalToAPI, minutesBetween } from '../../util/dates';
import { createSlug } from '../../util/urlHelpers';
import {
  isTransactionInitiateAmountTooLowError,
  isTransactionInitiateListingNotFoundError,
  isTransactionInitiateMissingStripeAccountError,
  isTransactionInitiateBookingTimeNotAvailableError,
  isTransactionChargeDisabledError,
  isTransactionZeroPaymentError,
  transactionInitiateOrderStripeErrors,
} from '../../util/errors';
import { formatMoney } from '../../util/currency';
import {
  TRANSITION_ENQUIRE,
  txIsPaymentPending,
  txIsPaymentExpired,
} from '../../util/transaction';
import {
  AvatarMedium,
  BookingBreakdown,
  Logo,
  NamedLink,
  NamedRedirect,
  Page,
  ResponsiveImage,
  Form,
} from '../../components';
import { StripePaymentForm } from '../../forms';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  handleCardPayment,
  retrievePaymentIntent,
} from '../../ducks/stripe.duck.js';
import { PrimaryButton } from '../../components';
import AdditionalUserForm from './AdditionalUserForm';

import {
  initiateOrder,
  setInitialValues,
  speculateTransaction,
  confirmPayment,
  sendMessage,
} from './FreeCheckoutPage.duck';
import { storeData, storedData, clearData } from './CheckoutPageSessionHelpers';
import css from './CheckoutPage.css';

// import { getLoyalityConsent, setLoyalityConsents } from '../../util/api';

const STORAGE_KEY = 'CheckoutPage';

// Stripe PaymentIntent statuses, where user actions are already completed
// https://stripe.com/docs/payments/payment-intents/status
const STRIPE_PI_USER_ACTIONS_DONE_STATUSES = [
  'processing',
  'requires_capture',
  'succeeded',
];

const initializeOrderPage = (initialValues, routes, dispatch) => {
  const OrderPage = findRouteByRouteName('OrderDetailsPage', routes);

  // Transaction is already created, but if the initial message
  // sending failed, we tell it to the OrderDetailsPage.
  dispatch(OrderPage.setInitialValues(initialValues));
};

const checkIsPaymentExpired = (existingTransaction) => {
  return txIsPaymentExpired(existingTransaction)
    ? true
    : txIsPaymentPending(existingTransaction)
      ? minutesBetween(
        existingTransaction.attributes.lastTransitionedAt,
        new Date()
      ) >= 15
      : false;
};

export class FreeCheckoutPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageData: {},
      dataLoaded: false,
      submitting: false,
      // isLoyalityActive: false,
      // isLoyalityChecked: null,
      // accesToken: null
    };
    this.stripe = null;

    this.loadInitialData = this.loadInitialData.bind(this);
    this.handlePaymentIntent = this.handlePaymentIntent.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    // const cookies = Object.fromEntries(document.cookie.split(';').map(cookie => {
    //   const [name, value] = cookie.trim().split('=');
    //   return [name, decodeURIComponent(value)];
    // }));
    const currentListing = ensureListing(this.state.pageData.listing);
    if (currentListing.attributes.publicData.additionalForm && currentListing.attributes.publicData.additionalForm.length) return
    if (this.props.speculatedTransaction && !prevProps.speculatedTransaction) {
      this.handleSubmit();
    }
  }

  componentWillMount() {
    if (typeof window !== 'undefined' && window) {
      this.loadInitialData();
    }
  }

  /**
   * Load initial data for the page
   *
   * Since the data for the checkout is not passed in the URL (there
   * might be lots of options in the future), we must pass in the data
   * some other way. Currently the ListingPage sets the initial data
   * for the CheckoutPage's Redux store.
   *
   * For some cases (e.g. a refresh in the CheckoutPage), the Redux
   * store is empty. To handle that case, we store the received data
   * to window.sessionStorage and read it from there if no props from
   * the store exist.
   *
   * This function also sets of fetching the speculative transaction
   * based on this initial data.
   */
  loadInitialData() {
    const {
      bookingData,
      bookingDates,
      listing,
      transaction,
      fetchSpeculatedTransaction,
      history,
    } = this.props;
    // Browser's back navigation should not rewrite data in session store.
    // Action is 'POP' on both history.back() and page refresh cases.
    // Action is 'PUSH' when user has directed through a link
    // Action is 'REPLACE' when user has directed through login/signup process
    const hasNavigatedThroughLink =
      history.action === 'PUSH' || history.action === 'REPLACE';

    const hasDataInProps =
      !!(bookingData && bookingDates && listing) && hasNavigatedThroughLink;
    if (hasDataInProps) {
      // Store data only if data is passed through props and user has navigated through a link.
      storeData(bookingData, bookingDates, listing, transaction, STORAGE_KEY);
    }

    // NOTE: stored data can be empty if user has already successfully completed transaction.
    const pageData = hasDataInProps
      ? { bookingData, bookingDates, listing, transaction }
      : storedData(STORAGE_KEY);

    // Check if a booking is already created according to stored data.
    const tx = pageData ? pageData.transaction : null;
    const isBookingCreated = tx && tx.booking && tx.booking.id;

    const shouldFetchSpeculatedTransaction =
      pageData &&
      pageData.listing &&
      pageData.listing.id &&
      pageData.bookingData &&
      pageData.bookingDates &&
      pageData.bookingDates.bookingStart &&
      pageData.bookingDates.bookingEnd &&
      !isBookingCreated;

    if (shouldFetchSpeculatedTransaction) {
      const listingId = pageData.listing.id;
      const { bookingStart, bookingEnd } = pageData.bookingDates;

      // Convert picked date to date that will be converted on the API as
      // a noon of correct year-month-date combo in UTC
      const bookingStartForAPI = dateFromLocalToAPI(bookingStart);
      const bookingEndForAPI = dateFromLocalToAPI(bookingEnd);

      // Fetch speculated transaction for showing price in booking breakdown
      // NOTE: if unit type is line-item/units, quantity needs to be added.
      // The way to pass it to checkout page is through pageData.bookingData
      fetchSpeculatedTransaction({
        listingId,
        bookingStart: bookingStartForAPI,
        bookingEnd: bookingEndForAPI,
      });
    }

    this.setState({ pageData: pageData || {}, dataLoaded: true });
  }

  handlePaymentIntent(handlePaymentParams, additionalFormvalues) {
    const { onInitiateOrder, onSendMessage } = this.props;
    const { pageData, speculatedTransaction, message } = handlePaymentParams;
    const storedTx = ensureTransaction(pageData.transaction);

    // Step 1: initiate order by requesting payment from Marketplace API
    const fnRequestPayment = (fnParams) => {
      // fnParams should be { listingId, bookingStart, bookingEnd }
      const hasPaymentIntents =
        storedTx.attributes.protectedData &&
        storedTx.attributes.protectedData.stripePaymentIntents;

      // If paymentIntent exists, order has been initiated previously.
      return hasPaymentIntents
        ? Promise.resolve(storedTx)
        : onInitiateOrder(fnParams, storedTx.id);
    };

    // Step 2: send initial message
    const fnSendMessage = (fnParams) => {
      return onSendMessage({ ...fnParams, message });
    };

    // Here we create promise calls in sequence
    // This is pretty much the same as:
    // fnRequestPayment({...initialParams})
    //   .then(result => fnHandleCardPayment({...result}))
    //   .then(result => fnConfirmPayment({...result}))
    const applyAsync = (acc, val) => acc.then(val);
    const composeAsync = (...funcs) => (x) =>
      funcs.reduce(applyAsync, Promise.resolve(x));
    const handlePaymentIntentCreation = composeAsync(
      fnRequestPayment,
      fnSendMessage
    );
    // Create order aka transaction
    // NOTE: if unit type is line-item/units, quantity needs to be added.
    // The way to pass it to checkout page is through pageData.bookingData
    const tx = speculatedTransaction ? speculatedTransaction : storedTx;

    const orderParams = {
      listingId: pageData.listing.id,
      qrConfirmation: pageData.listing.attributes.publicData.qr_confirmation,
      bookingStart: tx.booking.attributes.start,
      bookingEnd: tx.booking.attributes.end,
      additionalFormData: additionalFormvalues,
    };
    return handlePaymentIntentCreation(orderParams);
  }

  handleSubmit(additionalFormvalues) {
    const currentListing = ensureListing(this.state.pageData.listing);
    // if (currentListing.attributes.publicData.additionalForm) return
    // const isRewardActive = currentListing.attributes.publicData.reward_active || false
    if (this.state.submitting) {
      return;
    }
    this.setState({ submitting: true });

    const {
      history,
      speculatedTransaction,
      currentUser,
      paymentIntent,
      dispatch,
    } = this.props;

    // if (this.state.isLoyalityChecked && !this.state.isLoyalityActive) {
    //   setLoyalityConsents(this.state.accesToken);
    // }

    const requestPaymentParams = {
      pageData: this.state.pageData,
      speculatedTransaction,
      paymentIntent,
    };

    this.handlePaymentIntent(requestPaymentParams, additionalFormvalues)
      .then((res) => {
        const { orderId, messageSuccess } = res;
        this.setState({ submitting: false });

        const routes = routeConfiguration();
        const initialMessageFailedToTransaction = messageSuccess
          ? null
          : orderId;
        const orderDetailsPath = pathByRouteName(
          'OrderDetailsSuccessPage',
          routes,
          { id: orderId.uuid }
        );

        initializeOrderPage(
          { initialMessageFailedToTransaction },
          routes,
          dispatch
        );
        clearData(STORAGE_KEY);

        //fb event
        window.fbq('trackSingle', '383270155928431', 'Purchase', {
          value: 0.0,
          currency: 'PLN',
        });
        this.props.listing.id.uuid === '5fa51a30-20a7-4580-9bcd-be71f2be3e89' &&
          window.fbq(
            'trackSingleCustom',
            '1638637219771900',
            'DecaGo_Dodanie_potwierdzenie zgłoszenia'
          );
        history.push(orderDetailsPath);
      })
      .catch((err) => {
        console.error(err);
        this.setState({ submitting: false });
      });
  }

  //   onStripeInitialized(stripe) {
  //     this.stripe = stripe;

  //     const { paymentIntent, onRetrievePaymentIntent } = this.props;
  //     const tx = this.state.pageData ? this.state.pageData.transaction : null;

  //     // We need to get up to date PI, if booking is created but payment is not expired.
  //     const shouldFetchPaymentIntent =
  //       this.stripe &&
  //       !paymentIntent &&
  //       tx &&
  //       tx.id &&
  //       tx.booking &&
  //       tx.booking.id &&
  //       txIsPaymentPending(tx) &&
  //       !checkIsPaymentExpired(tx);

  //     if (shouldFetchPaymentIntent) {
  //       const { stripePaymentIntentClientSecret } =
  //         tx.attributes.protectedData && tx.attributes.protectedData.stripePaymentIntents
  //           ? tx.attributes.protectedData.stripePaymentIntents.default
  //           : {};

  //       // Fetch up to date PaymentIntent from Stripe
  //       onRetrievePaymentIntent({ stripe, stripePaymentIntentClientSecret });
  //     }
  //   }

  render() {
    const {
      scrollingDisabled,
      speculateTransactionInProgress,
      speculateTransactionError,
      speculatedTransaction: speculatedTransactionMaybe,
      initiateOrderError,
      confirmPaymentError,
      intl,
      params,
      currentUser,
      handleCardPaymentError,
      paymentIntent,
      retrievePaymentIntentError,
    } = this.props;

    // Since the listing data is already given from the ListingPage
    // and stored to handle refreshes, it might not have the possible
    // deleted or closed information in it. If the transaction
    // initiate or the speculative initiate fail due to the listing
    // being deleted or closec, we should dig the information from the
    // errors and not the listing data.
    const listingNotFound =
      isTransactionInitiateListingNotFoundError(speculateTransactionError) ||
      isTransactionInitiateListingNotFoundError(initiateOrderError);

    const isLoading = !this.state.dataLoaded || speculateTransactionInProgress;

    const { listing, bookingDates, transaction } = this.state.pageData;
    const existingTransaction = ensureTransaction(transaction);
    const speculatedTransaction = ensureTransaction(
      speculatedTransactionMaybe,
      {},
      null
    );
    const currentListing = ensureListing(listing);
    const currentAuthor = ensureUser(currentListing.author);

    const isOwnListing =
      currentUser &&
      currentUser.id &&
      currentAuthor &&
      currentAuthor.id &&
      currentAuthor.id.uuid === currentUser.id.uuid;

    const hasListingAndAuthor = !!(currentListing.id && currentAuthor.id);
    const hasBookingDates = !!(
      bookingDates &&
      bookingDates.bookingStart &&
      bookingDates.bookingEnd
    );
    const hasRequiredData = hasListingAndAuthor && hasBookingDates;
    const canShowPage = hasRequiredData && !isOwnListing;
    const shouldRedirect = !isLoading && !canShowPage;
    // Redirect back to ListingPage if data is missing.
    // Redirection must happen before any data format error is thrown (e.g. wrong currency)
    if (shouldRedirect) {
      // eslint-disable-next-line no-console
      console.error(
        'Missing or invalid data for checkout, redirecting back to listing page.',
        {
          transaction: speculatedTransaction,
          bookingDates,
          listing,
        }
      );
      return <NamedRedirect name="ListingPage" params={params} />;
    }

    // Show breakdown only when speculated transaction and booking are loaded
    // (i.e. have an id)
    const tx = existingTransaction.booking
      ? existingTransaction
      : speculatedTransaction;
    const txBooking = ensureBooking(tx.booking);
    const breakdown =
      tx.id && txBooking.id ? (
        <BookingBreakdown
          className={css.bookingBreakdown}
          userRole="customer"
          unitType={config.bookingUnitType}
          transaction={{ ...tx, listing: this.props.listing }}
          booking={txBooking}
        />
      ) : null;

    const isPaymentExpired = checkIsPaymentExpired(existingTransaction);

    // Allow showing page when currentUser is still being downloaded,
    // but show payment form only when user info is loaded.

    const listingTitle = currentListing.attributes.title;
    const title = intl.formatMessage(
      { id: 'CheckoutPage.title' },
      { listingTitle }
    );

    const firstImage =
      currentListing.images && currentListing.images.length > 0
        ? currentListing.images[0]
        : null;

    const listingLink = (
      <NamedLink
        name="ListingPage"
        params={{ id: currentListing.id.uuid, slug: createSlug(listingTitle) }}
      >
        <FormattedMessage id="CheckoutPage.errorlistingLinkText" />
      </NamedLink>
    );

    const isAmountTooLowError = isTransactionInitiateAmountTooLowError(
      initiateOrderError
    );
    const isChargeDisabledError = isTransactionChargeDisabledError(
      initiateOrderError
    );
    const isBookingTimeNotAvailableError = isTransactionInitiateBookingTimeNotAvailableError(
      initiateOrderError
    );
    const stripeErrors = transactionInitiateOrderStripeErrors(
      initiateOrderError
    );

    let initiateOrderErrorMessage = null;
    let listingNotFoundErrorMessage = null;

    if (listingNotFound) {
      listingNotFoundErrorMessage = (
        <p className={css.notFoundError}>
          <FormattedMessage id="CheckoutPage.listingNotFoundError" />
        </p>
      );
    } else if (isAmountTooLowError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (isBookingTimeNotAvailableError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isChargeDisabledError) {
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.chargeDisabledMessage" />
        </p>
      );
    } else if (stripeErrors && stripeErrors.length > 0) {
      // NOTE: Error messages from Stripes are not part of translations.
      // By default they are in English.
      const stripeErrorsAsString = stripeErrors.join(', ');
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderStripeError"
            values={{ stripeErrors: stripeErrorsAsString }}
          />
        </p>
      );
    } else if (initiateOrderError) {
      // Generic initiate order error
      initiateOrderErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage
            id="CheckoutPage.initiateOrderError"
            values={{ listingLink }}
          />
        </p>
      );
    }

    const speculateTransactionErrorMessage = speculateTransactionError ? (
      <p className={css.speculateError}>
        <FormattedMessage id="CheckoutPage.speculateTransactionError" />
      </p>
    ) : null;
    let speculateErrorMessage = null;

    if (
      isTransactionInitiateMissingStripeAccountError(speculateTransactionError)
    ) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.providerStripeAccountMissingError" />
        </p>
      );
    } else if (
      isTransactionInitiateBookingTimeNotAvailableError(
        speculateTransactionError
      )
    ) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.bookingTimeNotAvailableMessage" />
        </p>
      );
    } else if (isTransactionZeroPaymentError(speculateTransactionError)) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.initiateOrderAmountTooLow" />
        </p>
      );
    } else if (speculateTransactionError) {
      speculateErrorMessage = (
        <p className={css.orderError}>
          <FormattedMessage id="CheckoutPage.speculateFailedMessage" />
        </p>
      );
    }

    const topbar = (
      <div className={css.topbar}>
        <NamedLink className={css.home} name="LandingPage">
          {/* <Logo
            className={css.logoMobile}
            title={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="mobile"
          /> */}
          <Logo
            className={css.logoDesktop}
            alt={intl.formatMessage({ id: 'CheckoutPage.goToLandingPage' })}
            format="desktop"
          />
        </NamedLink>
      </div>
    );

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'CheckoutPage.perNight'
      : isDaily
        ? 'CheckoutPage.perDay'
        : 'CheckoutPage.perUnit';

    const price = currentListing.attributes.price;
    const formattedPrice = formatMoney(intl, price);
    const detailsSubTitle = `${formattedPrice}`;

    const showInitialMessageInput = !(
      existingTransaction &&
      existingTransaction.attributes.lastTransition === TRANSITION_ENQUIRE
    );

    const pageProps = { title, scrollingDisabled };

    // if (isLoading) {
    //   return (
    //     <Page {...pageProps}>
    //       {topbar}
    //       <div className={css.loading}>
    //         <FormattedMessage id="CheckoutPage.loadingData" />
    //       </div>
    //     </Page>
    //   );
    // }

    // Get first and last name of the current user and use it in the StripePaymentForm to autofill the name field
    const userName =
      currentUser && currentUser.attributes
        ? `${currentUser.attributes.profile.firstName} ${currentUser.attributes.profile.lastName}`
        : null;

    // If paymentIntent status is not waiting user action,
    // handleCardPayment has been called previously.
    const hasPaymentIntentUserActionsDone =
      paymentIntent &&
      STRIPE_PI_USER_ACTIONS_DONE_STATUSES.includes(paymentIntent.status);

    // If your marketplace works mostly in one country you can use initial values to select country automatically
    // e.g. {country: 'FI'}

    const initalValuesForStripePayment = { name: userName };

    return (this.props.speculatedTransaction) && currentListing.attributes.publicData.additionalForm && !!currentListing.attributes.publicData.additionalForm.length ? <Page {...pageProps}>
      {topbar}
      <div className={css.contentContainer}>
        <div className={css.aspectWrapper}>
          <ResponsiveImage
            rootClassName={css.rootForImage}
            alt={listingTitle}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
          />
        </div>
        <div className={classNames(css.avatarWrapper, css.avatarMobile)}>
          <AvatarMedium user={currentAuthor} disableProfileLink />
        </div>
        <div className={css.bookListingContainer}>
          <div className={css.heading}>
            <h1 className={css.title}>{title}</h1>
            <div className={css.author}>
              <FormattedMessage
                id="CheckoutPage.hostedBy"
                values={{
                  name: currentAuthor.attributes.profile.displayName,
                }}
              />
            </div>
            {listing.attributes.publicData.term_of_use && <div className={css.author} style={{ fontWeight: 'bold' }}>
              <FormattedMessage
                id="CheckoutPage.termsOfUse"
              />
            </div>}
          </div>

          {transaction && transaction.listing && !transaction.listing.attributes.publicData.price_hide && <div className={css.priceBreakdownContainer}>
            <h3 className={css.priceBreakdownTitle}>
              <FormattedMessage id="CheckoutPage.priceBreakdownTitle" />
            </h3>
            {speculateTransactionErrorMessage}
            {breakdown}
          </div>}

          <section className={css.paymentContainer}>
            {initiateOrderErrorMessage}
            {listingNotFoundErrorMessage}
            {speculateErrorMessage}
            {retrievePaymentIntentError ? (
              <p className={css.orderError}>
                <FormattedMessage
                  id="CheckoutPage.retrievingStripePaymentIntentFailed"
                  values={{ listingLink }}
                />
              </p>
            ) : null}

            {currentListing.attributes.publicData.additionalForm ? (
              <AdditionalUserForm
                handleSubmitRegisterForm={this.handleSubmit}
                additionalForm={
                  currentListing.attributes.publicData.additionalForm
                }
                cta="Potwierdź zgłoszenie"
              />
            ) : (
              <PrimaryButton
                onClick={() => this.handleSubmit(null)}>
                Potwierdź zgłoszenie
              </PrimaryButton>
            )}
          </section>
        </div>

        <div className={css.detailsContainerDesktop}>
          <div className={css.detailsAspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              alt={listingTitle}
              image={firstImage}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          <div className={css.avatarWrapper}>
            <AvatarMedium user={currentAuthor} disableProfileLink />
          </div>
          <div className={css.detailsHeadings}>
            <h2 className={css.detailsTitle}>{listingTitle}</h2>
          </div>
          {transaction && transaction.listing && !transaction.listing.attributes.publicData.price_hide && <h3 className={css.bookingBreakdownTitle}>
            <FormattedMessage id="CheckoutPage.priceBreakdownTitle" />
          </h3>}
          {transaction && transaction.listing && !transaction.listing.attributes.publicData.price_hide && speculateTransactionErrorMessage}
          {transaction && transaction.listing && !transaction.listing.attributes.publicData.price_hide && breakdown}
        </div>
      </div>
    </Page> : null
  }
}

FreeCheckoutPageComponent.defaultProps = {
  initiateOrderError: null,
  confirmPaymentError: null,
  listing: null,
  bookingData: {},
  bookingDates: null,
  speculateTransactionError: null,
  speculatedTransaction: null,
  transaction: null,
  currentUser: null,
  paymentIntent: null,
};

FreeCheckoutPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  listing: propTypes.listing,
  bookingData: object,
  bookingDates: shape({
    bookingStart: instanceOf(Date).isRequired,
    bookingEnd: instanceOf(Date).isRequired,
  }),
  fetchSpeculatedTransaction: func.isRequired,
  speculateTransactionInProgress: bool.isRequired,
  speculateTransactionError: propTypes.error,
  speculatedTransaction: propTypes.transaction,
  transaction: propTypes.transaction,
  currentUser: propTypes.currentUser,
  params: shape({
    id: string,
    slug: string,
  }).isRequired,
  onInitiateOrder: func.isRequired,
  onHandleCardPayment: func.isRequired,
  onRetrievePaymentIntent: func.isRequired,
  initiateOrderError: propTypes.error,
  confirmPaymentError: propTypes.error,
  // handleCardPaymentError comes from Stripe so that's why we can't expect it to be in a specific form
  handleCardPaymentError: oneOfType([propTypes.error, object]),
  paymentIntent: object,

  // from connect
  dispatch: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  const {
    listing,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    initiateOrderError,
    confirmPaymentError,
  } = state.CheckoutPage;
  const { currentUser } = state.user;
  const {
    handleCardPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
  } = state.stripe;
  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
    bookingData,
    bookingDates,
    speculateTransactionInProgress,
    speculateTransactionError,
    speculatedTransaction,
    transaction,
    listing,
    initiateOrderError,
    handleCardPaymentError,
    confirmPaymentError,
    paymentIntent,
    retrievePaymentIntentError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  onInitiateOrder: (params, transactionId) =>
    dispatch(initiateOrder(params, transactionId)),
  fetchSpeculatedTransaction: (params) =>
    dispatch(speculateTransaction(params)),
  onRetrievePaymentIntent: (params) => dispatch(retrievePaymentIntent(params)),
  onHandleCardPayment: (params) => dispatch(handleCardPayment(params)),
  onConfirmPayment: (params) => dispatch(confirmPayment(params)),
  onSendMessage: (params) => dispatch(sendMessage(params)),
});

const FreeCheckoutPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(FreeCheckoutPageComponent);

FreeCheckoutPage.setInitialValues = (initialValues) =>
  setInitialValues(initialValues);

FreeCheckoutPage.displayName = 'CheckoutPage';

export default FreeCheckoutPage;
