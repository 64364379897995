// ================ Action types ================ //

export const OPEN_MISSING_INFO_MODAL = 'app/MissingInformationModal/OPEN_MISSING_INFO_MODAL';
export const CLOSE_MISSING_INFO_MODAL = 'app/MissingInformationModal/CLOSE_MISSING_INFO_MODAL';

// ================ Reducer ================ //

const initialState = {
  isOpen: false,
  content: null,
  data: null
};

const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const STRIPE_ACCOUNT = 'STRIPE_ACCOUNT';

const missingInformationReducer = (state = initialState, action = {}) => {
  const { type, content, data } = action;

  switch (type) {
    case OPEN_MISSING_INFO_MODAL:
      return {
        isOpen: true,
        content,
        data
      };

    case CLOSE_MISSING_INFO_MODAL:
      return {
        isOpen: false,
        content: null,
        data: null
      };

    default:
      return state;
  }
};

export default missingInformationReducer;

// ================ Action creators ================ //

export const openMissingInfoModal = (content, data) => ({
  type: OPEN_MISSING_INFO_MODAL,
  content,
  data
});

export const closeMissingInfoModal = () => ({
  type: CLOSE_MISSING_INFO_MODAL,
});
