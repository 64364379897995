import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconLoc = props => {
  const { className } = props;

  return (
    <svg className={className} enableBackground="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="m256 0c-102.245 0-185.427 83.182-185.427 185.426 0 126.888 165.939 313.167 173.004 321.035 6.636 7.391 18.222 7.378 24.846 0 7.065-7.868 173.004-194.147 173.004-321.035-.002-102.244-83.183-185.426-185.427-185.426zm0 278.719c-51.442 0-93.292-41.851-93.292-93.293s41.851-93.292 93.292-93.292 93.291 41.851 93.291 93.293-41.85 93.292-93.291 93.292z"/></svg>
  );
};

const { string } = PropTypes;

IconLoc.defaultProps = {
  className: null,
  rootClassName: null,
};

IconLoc.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconLoc;
        