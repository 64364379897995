import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { createSlug, stringify } from '../../util/urlHelpers';
import { NamedLink } from '../../components';

import css from './TransactionPanel.css';

// import bannerSmallPath from '../../assets/03-2024-320.png';
// import bannerBigPath from '../../assets/03-2024-1024.png';

export const HEADING_ENQUIRED = 'enquired';
export const HEADING_PAYMENT_PENDING = 'pending-payment';
export const HEADING_PAYMENT_EXPIRED = 'payment-expired';
export const HEADING_REQUESTED = 'requested';
export const HEADING_ACCEPTED = 'accepted';
export const HEADING_DECLINED = 'declined';
export const HEADING_CANCELED = 'canceled';
export const HEADING_DELIVERED = 'deliveded';

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkDeviceType = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth >= 1200) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    };

    // Check device type on initial render
    checkDeviceType();

    // Re-check device type on window resize
    window.addEventListener('resize', checkDeviceType);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', checkDeviceType);
  }, []);

  return isMobile;
};

const createListingLink = (listingId, label, listingDeleted, searchParams = {}, className = '') => {
  if (!listingDeleted) {
    const params = { id: listingId, slug: createSlug(label) };
    const to = { search: stringify(searchParams) };
    return (
      <NamedLink className={className} name="ListingPage" params={params} to={to}>
        {label}
      </NamedLink>
    );
  } else {
    return <FormattedMessage id="TransactionPanel.deletedListingOrderTitle" />;
  }
};

const ListingDeletedInfoMaybe = props => {
  return props.listingDeleted ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.messageDeletedListing" />
    </p>
  ) : null;
};

const HeadingCustomer = props => {
  const { className, id, values, listingDeleted } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const HeadingCustomerWithSubtitle = props => {
  const { className, id, values, subtitleId, subtitleValues, children, listingDeleted } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
        <FormattedMessage id={subtitleId} values={subtitleValues} />
      </h1>
      {children}
      <ListingDeletedInfoMaybe listingDeleted={listingDeleted} />
    </React.Fragment>
  );
};

const CustomerBannedInfoMaybe = props => {
  return props.isCustomerBanned ? (
    <p className={css.transactionInfoMessage}>
      <FormattedMessage id="TransactionPanel.customerBannedStatus" />
    </p>
  ) : null;
};

const HeadingProvider = props => {
  const { className, id, values, isCustomerBanned, children } = props;
  return (
    <React.Fragment>
      <h1 className={className}>
        <span className={css.mainTitle}>
          <FormattedMessage id={id} values={values} />
        </span>
      </h1>
      {children}
      <CustomerBannedInfoMaybe isCustomerBanned={isCustomerBanned} />
    </React.Fragment>
  );
};

// Functional component as a helper to choose and show Order or Sale heading info:
// title, subtitle, and message
const PanelHeading = props => {
  const {
    className,
    rootClassName,
    panelHeadingState,
    customerName,
    isFreeProcess,
    providerName,
    listingId,
    listingTitle,
    listingDeleted,
    isCustomerBanned,
    trainingUrl,
    sendMessageForm,
    location,
  } = props;

  const [isMessageFormVisible, setIsMessageFormVisible] = useState(false);
  // const [isBannerVisible, setIsBannerVisible] = useState(false);

  // useEffect(() => {
  //   const id = setTimeout(() => {
  //     setIsBannerVisible(true);
  //   }, 1000);
  //   return () => {
  //     clearTimeout(id);
  //   };
  // }, []);

  const isMobile = useIsMobile();

  const isCustomer = props.transactionRole === 'customer';

  const defaultRootClassName = isCustomer ? css.headingOrder : css.headingSale;
  const titleClasses = classNames(rootClassName || defaultRootClassName, className);
  const listingLink = createListingLink(listingId, listingTitle, listingDeleted);

  switch (panelHeadingState) {
    case HEADING_ENQUIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderEnquiredTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleEnquiredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_PAYMENT_PENDING:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentPendingTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentPendingTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        >
          <p className={css.transactionInfoMessage}>
            <FormattedMessage
              id="TransactionPanel.salePaymentPendingInfo"
              values={{ customerName }}
            />
          </p>
        </HeadingProvider>
      );
    case HEADING_PAYMENT_EXPIRED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderPaymentExpiredTitle"
          values={{ listingLink }}
          listingDeleted={listingDeleted}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.salePaymentExpiredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_REQUESTED:
      if (isFreeProcess) {
        return isCustomer ? (
          <HeadingCustomerWithSubtitle
            className={titleClasses}
            id="TransactionPanel.orderFreeRequested"
            values={{ customerName }}
            subtitleId="TransactionPanel.orderFreeRequestedSubtitle"
            subtitleValues={{ listingLink }}
          >
            {trainingUrl && (
              <p className={css.transactionInfoMessage}>
                Jak dołączyć?{' '}
                <span className={css.bold}>
                  Kliknij w{' '}
                  <a href={trainingUrl} target="_blank" className={css.trainingUrl}>
                    link
                  </a>
                </span>
                <br /> Link do wydarzenia jest również dostępny w zakładce Twoje wiadomości w prawym
                górnym rogu ekranu oraz w mailu.
              </p>
            )}
          </HeadingCustomerWithSubtitle>
        ) : (
          <HeadingProvider
            className={titleClasses}
            id="TransactionPanel.saleFreeRequested"
            values={{ customerName, listingLink }}
          />
        );
      }
      return isCustomer ? (
        <HeadingCustomerWithSubtitle
          className={titleClasses}
          id="TransactionPanel.orderPreauthorizedTitle"
          values={{ customerName }}
          subtitleId="TransactionPanel.orderPreauthorizedSubtitle"
          subtitleValues={{ listingLink }}
        >
          {!listingDeleted ? (
            <p className={css.transactionInfoMessage}>
              <FormattedMessage
                id="TransactionPanel.orderPreauthorizedInfo"
                values={{ providerName }}
              />
            </p>
          ) : null}
        </HeadingCustomerWithSubtitle>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleRequestedTitle"
          values={{ customerName, listingLink }}
        >
          {!isCustomerBanned ? (
            <p className={titleClasses}>
              <FormattedMessage id="TransactionPanel.saleRequestedInfo" values={{ customerName }} />
            </p>
          ) : null}
        </HeadingProvider>
      );
    case HEADING_ACCEPTED:
      return isCustomer ? (
        // <HeadingCustomerWithSubtitle
        //   className={titleClasses}
        //   id="TransactionPanel.orderPreauthorizedTitle"
        //   values={{ customerName }}
        //   subtitleId="TransactionPanel.orderAcceptedSubtitle"
        //   subtitleValues={{ listingLink }}
        // />
        <div className={css.heading}>
          <h2>Jesteś w grze! Zapisałeś się na {listingLink}</h2>
          <p style={{ marginBottom: 0 }}>Lokalizacja: {location}</p>
          <p style={{ marginTop: 0 }}>
            Masz pytania?{' '}
            <button className={css.sendMessageButton} onClick={() => setIsMessageFormVisible(true)}>
              Napisz do organizatora
            </button>
          </p>
          {isMessageFormVisible && sendMessageForm}
          {/* {isBannerVisible && <a href='https://www.decathlon.pl/butiki/okazje-na-dlugi-weekend?utm_source=web&utm_medium=Decathlon_GO&utm_campaign=kids_day_24_go' target='_blank'>
            <div style={{ position: 'fixed', zIndex: 9999, top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>

              <div
                onClick={() => setIsBannerVisible(false)}
                style={{ position: 'absolute', top: 0, right: 0, transform: 'translate(50%,-50%)', borderRadius: '50%', background: 'black', color: 'white', padding: '5px 10px' }}>
                x
              </div>

              {isMobile ? <a href='https://www.decathlon.pl/butiki/okazje-na-dlugi-weekend?utm_source=web&utm_medium=Decathlon_GO&utm_campaign=kids_day_24_go'><img style={{ maxHeight: '95vh' }} src={bannerSmallPath} /></a> : <img style={{ maxHeight: '95vh' }} src={bannerBigPath} />}

            </div>
          </a>} */}
          {/* //https://apps.apple.com/app/id1168607403?mt=8 */}
        </div>
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleAcceptedTitle"
          values={{ customerName, listingLink }}
        />
      );
    case HEADING_DECLINED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDeclinedTitle"
          values={{ customerName, listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDeclinedTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    case HEADING_CANCELED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderCancelledTitle"
          values={{ customerName, listingLink }}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleCancelledTitle"
          values={{ customerName, listingLink }}
        />
      );
    case HEADING_DELIVERED:
      return isCustomer ? (
        <HeadingCustomer
          className={titleClasses}
          id="TransactionPanel.orderDeliveredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      ) : (
        <HeadingProvider
          className={titleClasses}
          id="TransactionPanel.saleDeliveredTitle"
          values={{ customerName, listingLink }}
          isCustomerBanned={isCustomerBanned}
        />
      );
    default:
      console.log('Unknown state given to panel heading.');
      return null;
  }
};

export default PanelHeading;
