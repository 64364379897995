import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useFormContext } from '../context';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import AddTerminForm from './AddTerminForm';

import css from './styles.css';
import { addEventDate } from '../api';
import RequestStatusModal from '../RequestStatusModal';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';
import { sdkGo } from '../../../queries/api';

const EventDates = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const duplicate = query.get('duplicate');
  const history = useHistory();
  const { updateFormData, isLastStep, formData, listingId, type } = useFormContext();
  const [updateSucces, setUpdateSucces] = React.useState(null);

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    values: formData.eventDates,
  });

  const submittedRef = useRef(false);
  const listingTitle = formData.generalInfo.eventTitle;

  const [termins, setTermins] = useState(formData.eventDates.termins || []);

  const onSubmit = async data => {
    const datesToAdd = data.termins.filter(
      ({ id }) => !formData.eventDates.termins.find(({ id: id2 }) => id === id2)
    );

    updateFormData(data, 'eventDates');

    const promises = datesToAdd.map(({ date, duration, limit }) =>
      addEventDate({
        listingId,
        date: new Date(date),
        participantsLimit: Number(limit) === 0 ? null : Number(limit),
        duration: Number(duration),
      }).then(updateStatus => {
        updateStatus === 'success' ? setUpdateSucces('success') : setUpdateSucces('error');
      })
    );

    await Promise.all(promises);

    if (duplicate) await sdkGo.listings.update({ id: listingId, state: 'published' });

    window.location.reload();
  };

  useEffect(() => {
    setTermins(formData.eventDates.termins);
  }, [formData.eventDates.termins]);

  useEffect(() => {
    setValue('termins', termins);
    if (!termins.length) {
      setError('dates', {
        type: 'manual',
        message: 'Wybierz przynajmniej jedną datę',
      });
    } else {
      clearErrors('dates');
    }
  }, [termins]);

  const submitButtonLabel = useMemo(
    () => (isLastStep ? 'Opublikuj' : type === 'edit' ? 'Zapisz' : 'Dalej'),
    [isLastStep, type]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={css.container}>
      {type === 'edit' && updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => setUpdateSucces(null)}
          listingId={listingId}
          slug={listingTitle}
        />
      )}

      <h1 className={css.header}>
        <LeftArrowIcon />
        Dostępność
      </h1>

      <AddTerminForm
        termins={termins}
        setTermins={setTermins}
        register={register}
        control={control}
      />
      {errors.dates && submittedRef.current && (
        <p style={{ color: '#f00', margin: 0 }}>{errors.dates.message}</p>
      )}

      {
        <div className={css.buttonContainer} onClick={() => (submittedRef.current = true)}>
          <button type="submit" className={css.submitClasses}>
            {!duplicate ? submitButtonLabel : 'Opublikuj'}
          </button>
        </div>
      }
      {/* {!!duplicate && <div className={css.buttonContainer} >
            <button 
            onClick={async () => {
                const res = await sdkGo.listings.update({ id: listingId, state: 'published' })
                res.status = 200 ? setUpdateSucces('success') : setUpdateSucces('error')
                setTimeout(() => {
                    setUpdateSucces(null)
                    history.push(`/inbox/sales`)
                }, 2000)
            }}
             type="submit" disabled={!termins.length} className={css.submitClasses} >Opublikuj</button>
        </div>} */}
    </form>
  );
};

export default EventDates;
