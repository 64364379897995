import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { ReactComponent as ArrowIcon } from '../../../../assets/searchPage/arrow.svg';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useUpdateQueryParams } from '../../hooks/useUpdateQueryParams';
import css from './styles.css';

const PriceDropdown = ({ params }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [values, setValues] = useState(
    (params.min_price &&
      params.max_price && [Number(params.min_price), Number(params.max_price)]) || [0, 1000]
  );

  const updateQueryParams = useUpdateQueryParams();
  const { dropdownRef } = useClickOutside(isOpen, setIsOpen);

  useEffect(() => {
    if (params.min_price && params.max_price) {
      setValues([Number(params.min_price), Number(params.max_price)]);
    } else {
      setValues([0, 1000]);
    }
  }, [params.min_price, params.max_price]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (index, event) => {
    const value = parseInt(event.target.value.replace('zł', '').trim(), 10);
    if (!isNaN(value)) {
      const newValues = [...values];
      newValues[index] = value;
      setValues(newValues);
    } else {
      const newValues = [...values];
      newValues[index] = 0;
      setValues(newValues);
    }
  };

  const handleSave = () => {
    updateQueryParams('min_price', values[0]);
    updateQueryParams('max_price', values[1]);
    toggleDropdown();
  };

  const MIN = 0;
  const MAX = 1000;
  const STEP = 1;

  return (
    <div className={css.priceDropdown} ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className={classNames(css.dropdownToggle, isOpen ? css.active : null)}
      >
        Cena{' '}
        {isOpen ? (
          <ArrowIcon style={{ marginLeft: '10px' }} />
        ) : (
          <ArrowIcon style={{ transform: 'rotate(180deg)', marginLeft: '10px' }} />
        )}
      </button>
      {isOpen && (
        <div className={css.dropdownMenu}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <Range
              values={values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={values => {
                setValues(values);
              }}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '90%',
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '2px',
                      width: '100%',
                      borderRadius: '4px',
                      background: getTrackBackground({
                        values,
                        colors: ['#ccc', '#3643BA', '#ccc'],
                        min: MIN,
                        max: MAX,
                      }),
                      alignSelf: 'center',
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ props, isDragged }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '28px',
                    width: '28px',
                    borderRadius: '50%',
                    backgroundColor: '#E1E3F5',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    boxShadow: '0px 2px 6px #AAA',
                  }}
                >
                  <div
                    style={{
                      height: '28px',
                      width: '28px',
                      borderRadius: '50%',
                      backgroundColor: isDragged ? '#3643BA' : '#E1E3F5',
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className={css.priceInputs}>
            <div className={css.priceInput}>
              <label htmlFor="minInput">Min</label>
              <input
                id="minInput"
                type="text"
                value={`${values[0]} zł`}
                max={MAX}
                onChange={e => handleInputChange(0, e)}
                placeholder=" "
              />
            </div>
            <div className={css.priceInput}>
              <label htmlFor="maxInput">Maks</label>
              <input
                id="maxInput"
                type="text"
                value={`${values[1]} zł`}
                onChange={e => handleInputChange(1, e)}
              />
            </div>
          </div>
          <div className={css.dropdownFooter}>
            <button
              onClick={() => {
                setValues([MIN, MAX]);
              }}
              className={css.clearButton}
            >
              WYCZYŚĆ
            </button>
            <button onClick={handleSave} className={css.saveButton}>
              ZAPISZ
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceDropdown;
