import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 18" fill="none">
            <g clip-path="url(#clip0_774_271)">
                <path d="M1.70631 17.8872C1.265 17.9129 0.830993 17.7662 0.4958 17.4781C-0.165267 16.8131 -0.165267 15.739 0.4958 15.074L14.971 0.59879C15.6586 -0.0445905 16.7375 -0.00882538 17.3808 0.678742C17.9626 1.30051 17.9965 2.25617 17.4602 2.91758L2.89976 17.4781C2.56889 17.762 2.14184 17.9085 1.70631 17.8872Z" fill="#C30000" />
                <path d="M16.1645 17.8873C15.7172 17.8853 15.2885 17.7078 14.971 17.3928L0.495723 2.91753C-0.116721 2.20234 -0.0334556 1.12601 0.681736 0.513511C1.32006 -0.0331292 2.26147 -0.0331292 2.89974 0.513511L17.4602 14.9888C18.1476 15.6323 18.1832 16.7113 17.5396 17.3987C17.514 17.426 17.4876 17.4525 17.4602 17.4781C17.1037 17.7881 16.6345 17.9363 16.1645 17.8873Z" fill="#C30000" />
            </g>
            <defs>
                <clipPath id="clip0_774_271">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

CloseIcon.defaultProps = { className: null };

const { string } = PropTypes;

CloseIcon.propTypes = { className: string };

export default CloseIcon;
