import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldCheckboxList, FieldCheckboxListWithDesc, FieldTextInput, IconBike, IconEvent, IconExercise, IconInstructor, IconRelax } from '../../components';
import config from '../../config';

import css from './EditOrganizerDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const businessTypes = [
  { key: 'coach', label: 'Trener', desc: 'trener fitness, badmintona, sportów zespołowych etc.', checkboxComponent: props => <IconExercise {...props} /> },
  { key: 'club', label: 'Klub', desc: 'klub fitness, siłownia, szkoła tańca, studio jogi, klub tenisowy etc.', checkboxComponent: props => <IconBike {...props}/> },
  { key: 'organizer', label: 'Organizator eventów', desc: 'organizator turniejów, obozów, wyjazdów, biegów etc.', checkboxComponent: props => <IconEvent {...props}/> },
  { key: 'practicioner', label: 'Wellness', desc: 'dietetyk, fizjoterapeuta, masażysta etc.', checkboxComponent: props => <IconRelax {...props}/>},
  { key: 'teacher', label: 'Szkoleniowiec', desc: 'szkoleniowiec, trener, edukator etc.', checkboxComponent: props => <IconInstructor {...props}/> }
]

const { sportsConfig } = config;

const sports = Object.keys(sportsConfig)
  .map(sport => ({ key: sport, label: sportsConfig[sport].name, icon: sportsConfig[sport].icon}))
  .sort((a,b) => a.label.localeCompare(b.label))

const EditListingDescriptionFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        className,
        disabled,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        isPublished,
        updated,
        updateInProgress,
        fetchErrors,
      } = fieldRenderProps;


      const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
      const errorMessageUpdateListing = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
        </p>
      ) : null;

      // This error happens only on first tab (of EditListingWizard)
      const errorMessageCreateListingDraft = createListingDraftError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
        </p>
      ) : null;

      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
        </p>
      ) : null;

      const classes = classNames(css.root, className);
      const submitReady = updated && pristine;
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessageCreateListingDraft}
          {errorMessageUpdateListing}
          {errorMessageShowListing}

          <FieldTextInput
            id="title"
            name="title"
            disabled={isPublished}
            className={css.field}
            type="text"
            label="Nazwa"
            placeholder="Podaj nazwę wydarzenia"
            maxLength={TITLE_MAX_LENGTH}
            validate={composeValidators(required("Musisz podać nazwę wydarzenia"), maxLength("Za długa nazwa wydarzenia - maksymalnie 60 znaków.", 60))}
            autoFocus
          />

          <FieldTextInput
            id="description"
            name="description"
            className={css.field}
            type="textarea"
            label="Opis organizatora"
            placeholder="Podaj opis organizatora"
            validate={composeValidators(required("Przynajmniej krótki opis organizatora jest wymagany"))}
          />

          <FieldTextInput
            id="service_description"
            name="service_description"
            className={css.field}
            type="textarea"
            label="Opis oferty"
            placeholder="Przedstaw swoją ofertę"
            validate={composeValidators(required("Przynajmniej krótki opis usług jest wymagany"))}
          />

          <FieldTextInput
            id="phone"
            name="phone"
            className={css.field}
            type="tel"
            label="Telefon kontaktowy"
            placeholder="Podaj telefon"
          />

          <FieldTextInput
            id="facebook_url"
            name="facebook_url"
            className={css.field}
            type="url"
            label="Facebook"
            placeholder="Podaj adres profilu na facebooku"
          />

          <FieldTextInput
            id="instagram_url"
            name="instagram_url"
            className={css.field}
            type="url"
            label="Instagram"
            placeholder="Podaj adres profilu na instagramie"
          />

          <FieldTextInput
            id="website"
            name="website"
            className={css.field}
            type="url"
            label="Strona internetowa"
            placeholder="Podaj swoją stronę internetową"
          />
          
          <FieldCheckboxListWithDesc
            rootClassName={css.fieldMargin}
            id="business_type"
            name="business_type"
            label="Rodzaj działalności"
            options={businessTypes}
          />
          
          <FieldCheckboxList
            rootClassName={css.fieldMargin}
            id="sports"
            name="sports"
            label="Sport"
            options={sports}
          />

          <Button
            className={css.submitButton}
            type="submit"
            inProgress={submitInProgress}
            disabled={submitDisabled}
            ready={submitReady}
          >
            {saveActionMsg}
          </Button>
        </Form>
      );
    }}
  />
);

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
