import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';

// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/OrganizerActivitiesSection/SET_INITIAL_STATE';

export const QUERY_ORGANIZER_ACTIVITIES_REQUEST = 'app/OrganizerActivitiesSection/QUERY_ORGANIZER_ACTIVITIES_REQUEST';
export const QUERY_ORGANIZER_ACTIVITIES_SUCCESS = 'app/OrganizerActivitiesSection/QUERY_ORGANIZER_ACTIVITIES_SUCCESS';
export const QUERY_ORGANIZER_ACTIVITIES_ERROR = 'app/OrganizerActivitiesSection/QUERY_ORGANIZER_ACTIVITIES_ERROR';

// ================ Reducer ================ //

const initialState = {
  organizerId: null,
  organizerActivitiesRefs: [],
  queryOrganizerActivitiesError: null,
  isLoading: false
};

export default function organizerActivitiesSectionReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case QUERY_ORGANIZER_ACTIVITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        // Empty listings only when user id changes
        organizerActivitiesRefs: payload.userId === state.userId ? state.organizerActivitiesRefs : [],

        queryOrganizerActivitiesError: null,
      };
    case QUERY_ORGANIZER_ACTIVITIES_SUCCESS:
      return { ...state, organizerActivitiesRefs: payload.organizerActivitiesRefs, isLoading: false };
    case QUERY_ORGANIZER_ACTIVITIES_ERROR:
      return { ...state, organizerActivitiesRefs: [], queryOrganizerActivitiesError: payload, isLoading: false };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryOrganizerActivitiesRequest = organizerId => ({
  type: QUERY_ORGANIZER_ACTIVITIES_REQUEST,
  payload: { organizerId },
});

export const queryOrganizerActivitiesSuccess = organizerActivitiesRefs => ({
  type: QUERY_ORGANIZER_ACTIVITIES_SUCCESS,
  payload: { organizerActivitiesRefs },
});

export const queryOrganizerActivitiesError = e => ({
  type: QUERY_ORGANIZER_ACTIVITIES_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryOrganizerActivities = organizerId => (dispatch, getState, sdk) => {
  dispatch(queryOrganizerActivitiesRequest());
  let lastMidnight = new Date();
  lastMidnight.setHours(0, 0, 0, 0);

  return sdk.listings
    .query({
      authorId: organizerId,
      pub_date: `${lastMidnight.getTime()},`,
      pub_type: 'event',
      include: ['author', 'images'],
      include: ['author'],
      sort: '-pub_date',
      // 'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      // 'fields.listing': ['metadata'],
    })
    .then(response => {
      const organizerActivitiesRefs = response.data.data.map(({ id, type }) => ({ id, type }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryOrganizerActivitiesSuccess(organizerActivitiesRefs));
      return response;
    })
    .catch(e => dispatch(queryOrganizerActivitiesError(storableError(e))));
};
