import React from 'react';
import { Link } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import css from './SportLocationPage.css';

const SectionSports = props => {
  const { city, city2, intl, sports } = props;

  const activityTitle = intl.formatMessage(
    { id: 'SportLocationPage.otherActivity' },
    { city2 }
  );

  if (!sports) return null;

  return (
    <div className={css.section}>
      <h2 className={css.sectionTitle}>{activityTitle}</h2>
      <div className={css.sectionDesc}>Interesujesz się innymi sportami? Sprawdź co jeszcze dla Ciebie mamy w Twoim mieście.</div>
      <div className={css.tilesContainer}>
        {
          sports.map((sport, i) => {
            const { name, searchUrl, src, srcSet } = sport;
            return (
              <Link key={name} to={searchUrl}>
                <div className={css.tileContainer}>
                  <LazyLoadImage
                    alt={name}
                    src={src}
                    srcSet={srcSet}
                    threshold={100}
                    visibleByDefault={i === 0}
                  />
                  <div className={css.imgMask}/>
                  <div className={css.tileHeading}><p>{name}</p> <p>{city}</p></div>
                </div>
              </Link>
            )
          })
        }
      </div>
    </div>
  );
};

export default SectionSports;
