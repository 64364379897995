/* eslint-disable  */
import cx from 'classnames';
import { array, arrayOf, bool, func, oneOf, shape, string } from 'prop-types';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FacebookIcon, FacebookShareButton } from 'react-share';
import { compose } from 'redux';
import { IconChevronRight } from '../../components';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { types as sdkTypes } from '../../util/sdkLoader';
import { propTypes } from '../../util/types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';

import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
  Page,
} from '../../components';
import { NotFoundPage, TopbarContainer } from '../../containers';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { withViewport } from '../../util/contextHelpers';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import { createResourceLocatorString } from '../../util/routes';

import FooterV2 from '../../components/Footer/FooterV2.js';
import { openMissingInfoModal } from '../../components/ModalMissingInformation/MissingModalInformation.duck';
import Activities from './Activities';
import css from './ListingPage.css';
import { loadData, sendEnquiry, setInitialValues } from './ListingPage.duck';
import SectionDescriptionMaybe from './SectionDescriptionMaybe';
import SectionHeading from './SectionHeading';
import SectionHostMaybe from './SectionHostMaybe';
import SectionImages from './SectionImages';
import SectionMapMaybe from './SectionMapMaybe';
import SectionOfferDescriptionMaybe from './SectionOfferDescriptionMaybe';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID } = sdkTypes;

export class ListingPageComponent extends Component {
  constructor(props) {
    super(props);
    const { enquiryModalOpenForListingId, params } = props;
    this.state = {
      pageClassNames: [],
      imageCarouselOpen: false,
      enquiryModalOpen: enquiryModalOpenForListingId === params.id,
      promoModalOpen: false,
      selectedImageIndex: 0,
      selectedTab: 'profile',
    };

    this.onContactUser = this.onContactUser.bind(this);
    this.onSubmitEnquiry = this.onSubmitEnquiry.bind(this);
  }

  onContactUser() {
    const {
      currentUser,
      history,
      callSetInitialValues,
      params,
      location,
      onOpenMissingNameModal,
    } = this.props;

    if (!currentUser) {
      const state = {
        from: `${location.pathname}${location.search}${location.hash}`,
      };

      // We need to log in before showing the modal, but first we need to ensure
      // that modal does open when user is redirected back to this listingpage
      callSetInitialValues(setInitialValues, {
        enquiryModalOpenForListingId: params.id,
      });

      // signup and return back to listingPage.
      history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}), state);
    } else {
      if (
        currentUser.attributes.profile.firstName === 'Nowy' ||
        currentUser.attributes.profile.lastName === 'Użytkownik'
      ) {
        onOpenMissingNameModal();
      } else {
        this.setState({ enquiryModalOpen: true });
      }
    }
  }

  onSubmitEnquiry(values) {
    const { history, params, onSendEnquiry } = this.props;
    const routes = routeConfiguration();
    const listingId = new UUID(params.id);
    const { message } = values;

    onSendEnquiry(listingId, message.trim())
      .then(txId => {
        this.setState({ enquiryModalOpen: false });
        ReactGA.event({
          category: 'Message',
          action: 'Send',
          label: 'via enquiry',
        });
        // Redirect to OrderDetailsPage
        history.push(
          createResourceLocatorString('OrderDetailsPage', routes, { id: txId.uuid }, {})
        );
      })
      .catch(() => {
        // Ignore, error handling in duck file
      });
  }

  handleClickTab = selectedTab => {
    this.setState({ selectedTab });
  };

  render() {
    const {
      isAuthenticated,
      currentUser,
      getListing,
      getOwnListing,
      intl,
      onManageDisableScrolling,
      params: rawParams,
      scrollingDisabled,
      showListingError,
      sendEnquiryInProgress,
      sendEnquiryError,
      history,
    } = this.props;

    const listingId = new UUID(rawParams.id);
    const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
    const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
    const currentListing =
      isPendingApprovalVariant || isDraftVariant
        ? ensureOwnListing(getOwnListing(listingId))
        : ensureListing(getListing(listingId));

    const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');

    const listingType = isDraftVariant
      ? LISTING_PAGE_PARAM_TYPE_DRAFT
      : LISTING_PAGE_PARAM_TYPE_EDIT;
    const listingTab = isDraftVariant ? 'photos' : 'description';

    const {
      description = '',
      geolocation = null,
      title = '',
      publicData,
    } = currentListing.attributes;

    const richTitle = richText(title, {
      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
      longWordClass: css.longWord,
    });

    if (showListingError && showListingError.status === 404) {
      // 404 listing not found

      return <NotFoundPage />;
    } else if (showListingError) {
      // Other error in fetching listing

      const errorTitle = intl.formatMessage({
        id: 'ListingPage.errorLoadingListingTitle',
      });

      return (
        <Page title={errorTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>
              <TopbarContainer />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.errorText}>
                <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <FooterV2 />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    } else if (!currentListing.id) {
      // Still loading the listing

      const loadingTitle = intl.formatMessage({
        id: 'ListingPage.loadingListingTitle',
      });

      return (
        <Page title={loadingTitle} scrollingDisabled={scrollingDisabled}>
          <LayoutSingleColumn className={css.pageRoot}>
            <LayoutWrapperTopbar>
              <TopbarContainer />
            </LayoutWrapperTopbar>
            <LayoutWrapperMain>
              <p className={css.loadingText}>
                <FormattedMessage id="ListingPage.loadingListingMessage" />
              </p>
            </LayoutWrapperMain>
            <LayoutWrapperFooter>
              <FooterV2 />
            </LayoutWrapperFooter>
          </LayoutSingleColumn>
        </Page>
      );
    }

    const handleViewPhotosClick = (e, selectedImageIndex = 0) => {
      // Stop event from bubbling up to prevent image click handler
      // trying to open the carousel as well.
      e.stopPropagation();
      this.setState({
        selectedImageIndex: selectedImageIndex,
        imageCarouselOpen: true,
      });
    };
    const authorAvailable = currentListing && currentListing.author;
    const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
    const isOwnListing =
      userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

    const currentAuthor = authorAvailable ? currentListing.author : null;
    const ensuredAuthor = ensureUser(currentAuthor);

    // When user is banned or deleted the listing is also deleted.
    // Because listing can be never showed with banned or deleted user we don't have to provide
    // banned or deleted display names for the function
    const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

    const handleBack = () => {
      if (history.length > 2) {
        history.goBack();
      } else {
        history.push(
          `/s?address=Polska&bounds=55.98904748%2C24.05383751%2C47.89042442%2C14.14416954&pub_sport=${currentListing.attributes.publicData.sport}`
        );
      }
    };

    const listingImages = (listing, variantName) =>
      (listing.images || [])
        .map(image => {
          const variants = image.attributes.variants;
          const variant = variants ? variants[variantName] : null;

          // deprecated
          // for backwards combatility only
          const sizes = image.attributes.sizes;
          const size = sizes ? sizes.find(i => i.name === variantName) : null;

          return variant || size;
        })
        .filter(variant => variant != null);

    const facebookImages = listingImages(currentListing, 'facebook');
    const twitterImages = listingImages(currentListing, 'twitter');
    const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'ListingPage.schemaTitle' }, { title, siteTitle });
    const seoDescription = publicData.service_description.slice(0, 200) + '...';

    const productsList =
      publicData &&
      publicData.sports &&
      publicData.sports.reduce((acc, curr) => {
        if (!config.sportsConfig[curr].externalProducts) return acc;
        return [...acc, ...config.sportsConfig[curr].externalProducts];
      }, []);

    const products =
      productsList && productsList.length
        ? productsList
        : [
            ...config.sportsConfig['yoga'].externalProducts,
            ...config.sportsConfig['running'].externalProducts,
          ];

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        author={authorDisplayName}
        contentType="website"
        description={seoDescription}
        facebookImages={facebookImages}
        twitterImages={twitterImages}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'ItemPage',
          description: seoDescription,
          name: schemaTitle,
          image: schemaImages,
          location: {
            '@type': 'Place',
            address: publicData.location.address,
          },
        }}
      >
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div>
              <div className={css.topButtonsContainer}>
                <button className={css.backButton} onClick={handleBack}>
                  <IconChevronRight className={css.chevronLeft} />
                  Powrót
                </button>
                {isOwnListing && (
                  <NamedLink
                    className={css.topEditListingLink}
                    name="EditOrganizerPage"
                    params={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                  >
                    Edytuj
                  </NamedLink>
                )}
                <FacebookShareButton
                  url={`${config.canonicalRootURL}${this.props.match.url}`}
                  className={css.shareButton}
                  beforeOnClick={() =>
                    ReactGA.event({
                      category: 'Social',
                      action: 'Clicked Share',
                      label: schemaTitle,
                    })
                  }
                >
                  <span>Udostępnij</span>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
              </div>
              <div className={css.wrapper}>
                <div>
                  <SectionImages
                    title={title}
                    listing={currentListing}
                    isOwnListing={isOwnListing}
                    editParams={{
                      id: listingId.uuid,
                      slug: listingSlug,
                      type: listingType,
                      tab: listingTab,
                    }}
                    imageCarouselOpen={this.state.imageCarouselOpen}
                    onImageCarouselClose={() => this.setState({ imageCarouselOpen: false })}
                    handleViewPhotosClick={handleViewPhotosClick}
                    onManageDisableScrolling={onManageDisableScrolling}
                    selectedImageIndex={this.state.selectedImageIndex}
                  />
                  <div className={cx(css.navTabs, css.section)}>
                    <ul>
                      <li
                        className={cx({
                          [css.active]: this.state.selectedTab === 'profile',
                        })}
                      >
                        <span onClick={() => this.handleClickTab('profile')}>Profil</span>
                      </li>
                      <li
                        className={cx({
                          [css.active]: this.state.selectedTab === 'activities',
                        })}
                      >
                        <span onClick={() => this.handleClickTab('activities')}>Oferta</span>
                      </li>
                    </ul>
                  </div>
                  {this.state.selectedTab === 'activities' ? (
                    <div>
                      <Activities organizerId={ensuredAuthor.id.uuid} />
                    </div>
                  ) : (
                    <div>
                      <div className={css.mainContent}>
                        <div>
                          <SectionHeading
                            richTitle={richTitle}
                            title={title}
                            sports={publicData.sports}
                          />
                        </div>
                        <SectionDescriptionMaybe description={description} />
                        <SectionMapMaybe
                          geolocation={geolocation}
                          header="Lokalizacja"
                          publicData={publicData}
                          listingId={currentListing.id}
                          sectionClassName={cx(css.desktopHidden, css.withBorder)}
                        />
                        <section className={cx(css.desktopHidden, css.mainNav)}>
                          <div className={css.mainNavTitle}>Może Ci się przydać!</div>
                          {/* <SectionExternalProducts
                            category="Organizator Page"
                            products={products}
                            titleVisible={false}
                            isResponsive={false}
                            sport={'fitness'}
                            urlCampaign="organizer_page"
                            slidesToShow={1}
                            sectionClassName={css.externalProducts}
                          /> */}
                        </section>
                        <SectionOfferDescriptionMaybe
                          serviceDescription={publicData.service_description}
                        />

                        <SectionHostMaybe
                          title={title}
                          listing={currentListing}
                          authorDisplayName={authorDisplayName}
                          onContactUser={this.onContactUser}
                          isEnquiryModalOpen={isAuthenticated && this.state.enquiryModalOpen}
                          onCloseEnquiryModal={() => this.setState({ enquiryModalOpen: false })}
                          sendEnquiryError={sendEnquiryError}
                          sendEnquiryInProgress={sendEnquiryInProgress}
                          onSubmitEnquiry={this.onSubmitEnquiry}
                          currentUser={currentUser}
                          onManageDisableScrolling={onManageDisableScrolling}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <SectionMapMaybe
                    geolocation={geolocation}
                    publicData={publicData}
                    listingId={currentListing.id}
                  />
                  <section className={css.mainNav}>
                    <div className={css.mainNavTitle}>Może Ci się przydać!</div>
                    {/* <SectionExternalProducts
                      category="Organizator Page"
                      products={products}
                      titleVisible={false}
                      sport={'fitness'}
                      urlCampaign="organizer_page"
                      isResponsive={false}
                      slidesToShow={1}
                      sectionClassName={css.externalProducts}
                    /> */}
                  </section>
                </div>
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ListingPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  enquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  timeSlots: null,
  fetchTimeSlotsError: null,
  sendEnquiryError: null,
};

ListingPageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  enquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  sendEnquiryInProgress: bool.isRequired,
  sendEnquiryError: propTypes.error,
  onSendEnquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,

  categoriesConfig: array,
  amenitiesConfig: array,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
    enquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    enquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    sendEnquiryInProgress,
    sendEnquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values) => dispatch(setInitialValues(values)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onOpenMissingNameModal: () => dispatch(openMissingInfoModal('NAME_MISSING')),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  withViewport,
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ListingPageComponent);

ListingPage.setInitialValues = initialValues => setInitialValues(initialValues);
ListingPage.loadData = loadData;

export default ListingPage;
