import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { ReactComponent as CalendarIcon } from '../../../../assets/listingPage/calendar.svg';
import { ReactComponent as PinIcon } from '../../../../assets/listingPage/localization.svg';
import AddressFromCoordinates from '../../../../components/Map/AddressFromCoordinates';
import { sportsConfig } from '../../../../sportsConfig';

import css from './styles.css';

const EventCard = ({ eventData, withoutPicture = false }) => {
  const eventDate = new Date(eventData.eventDate.attributes.date);
  // eventDate.setMinutes(eventDate.getMinutes() + eventData.eventDate.attributes.duration);
  const commission =
    eventData.id === '8b69026f-5854-41d0-af3e-c7a4c3507579' ||
    eventData.id === 'b94ed811-53bc-4846-823e-77b6c68340cd'
      ? 3.5
      : eventData.commission;

  const reducedPrice =
    !!eventData.reducedPrice && !!eventData.reducedPrice.amount
      ? eventData.reducedPrice.amount / 100
      : null;
  const price = !!eventData.price && !!eventData.price.amount ? eventData.price.amount / 100 : null;
  const rawEventPrice = reducedPrice ? reducedPrice : price;
  const isPaymentOnline = !!eventData.paymentEnabled;
  const eventPrice = isPaymentOnline
    ? rawEventPrice * (1 + commission / 100) +
      (eventData.id === '8b69026f-5854-41d0-af3e-c7a4c3507579' ||
      eventData.id === 'b94ed811-53bc-4846-823e-77b6c68340cd'
        ? 0
        : 2)
    : rawEventPrice;
  const priceHide = eventData.publicData.price_hide;

  return (
    <Link
      to={`/l/${eventData.title
        .split(' ')
        .join('-')
        .split('/')
        .join('-')}/${eventData.id}`}
      className={css.eventCardContainer}
      key={eventData.id}
    >
      {!withoutPicture && (
        <>
          <div className={css.sportLabel}>{sportsConfig[eventData.publicData.sport].name}</div>
          <div className={css.imageContainer}>
            <LazyLoadImage
              alt={eventData.title}
              src={eventData.image.attributes.variants['square-small2x'].url}
              threshold={100}
            />
          </div>
        </>
      )}
      <p className={css.title}>{eventData.title}</p>
      <p className={css.info}>
        <span>
          <CalendarIcon style={{ marginRight: '5px' }} />
          {formatDate(eventDate)}
        </span>
        {eventData.isMultiDates && <strong>różne terminy</strong>}
      </p>
      {typeof window !== 'undefined' && (
        <p className={css.info}>
          <span>
            <PinIcon style={{ marginRight: '5px' }} />
            <AddressFromCoordinates
              lat={eventData.geolocation.lat}
              lng={eventData.geolocation.lng}
            />
          </span>
        </p>
      )}
      {!priceHide && (
        <p className={css.price}>
          {!!eventPrice ? `Od ${eventPrice.toFixed(2)} zł za osobę` : 'Darmowe'}
        </p>
      )}
    </Link>
  );
};

export default EventCard;

const formatDate = date => {
  const now = new Date();
  const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const startOfTargetDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const diffDays = Math.floor((startOfTargetDay - startOfToday) / (24 * 60 * 60 * 1000));

  if (diffDays === 0) {
    return `dzisiaj, ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else if (diffDays === 1) {
    return `jutro, ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else if (diffDays === 2) {
    return `pojutrze, ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else if (diffDays > 2 && diffDays <= 6) {
    return `za ${diffDays} dni, ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else if (diffDays === 7) {
    return `za tydzień, ${date.getHours()}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  } else {
    const months = [
      'stycznia',
      'lutego',
      'marca',
      'kwietnia',
      'maja',
      'czerwca',
      'lipca',
      'sierpnia',
      'września',
      'października',
      'listopada',
      'grudnia',
    ];

    const day = date.getDate();
    const month = months[date.getMonth()];
    const hours = date.getHours();
    const minutes = date
      .getMinutes()
      .toString()
      .padStart(2, '0');

    return `${day} ${month}, ${hours}:${minutes}`;
  }
};
