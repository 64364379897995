import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import css from './FieldCheckboxIcon.css';

const FieldCheckboxIconComponent = props => {
  const { rootClassName, className, id, icon, label, ...rest } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <img src={icon} className={css.icon} />
        <span className={css.text}>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxIconComponent.defaultProps = {
  className: null,
  rootClassName: null,
  label: null,
  icon: null
};

FieldCheckboxIconComponent.propTypes = {
  className: string,
  rootClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,
  icon: string,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxIconComponent;
