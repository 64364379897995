import React, { forwardRef, useEffect } from 'react';
import css from './styles.css';

const SidePanelContainer = forwardRef(({ children }, ref) => {
  useEffect(() => {
    document.documentElement.classList.add('no-scroll');

    return () => document.documentElement.classList.remove('no-scroll');
  });
  return (
    <>
      <div className={css.curtain}></div>
      <div className={css.showAll} ref={ref}>
        {children}
      </div>
    </>
  );
});

export default SidePanelContainer;
