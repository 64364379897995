import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FormItem from '../components/FormItem';
import css from './style.css';
import { useMutation, useQueryClient } from 'react-query';
import { apiSdk } from '../../queries/api';


const EditAdultForm = ({ setEditAdult, userData, setUserData }) => {

    const queryClient = useQueryClient();

    const mutation = useMutation(apiSdk.users.currentUserUpdate, {
        onSuccess: (data) => {
            setUserData({
                firstName: data.data.attributes.profile.firstName,
                lastName: data.data.attributes.profile.lastName
            })
            queryClient.invalidateQueries('user.current');
            setEditAdult(false)
        }
    })

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    const onSubmit = (data) => {
        mutation.mutate(data)
    };

    useEffect(() => {
        setValue('firstName', userData.firstName);
        setValue('lastName', userData.lastName);
    }, [userData, setValue])

    return <form onSubmit={handleSubmit(onSubmit)}>
        <FormItem label={'Imię'} >
            <input className={css.eventTypeContainer} id="firstName" {...register('firstName', { required: true })} />
            {errors.firstName && <span style={{ color: 'red' }}>To pole jest wymagane</span>}
        </FormItem>

        <FormItem label={'Nazwisko'} >
            <input className={css.eventTypeContainer} id="lastName" {...register('lastName', { required: true })} />
            {errors.lastName && <span style={{ color: 'red' }}>To pole jest wymagane</span>}
        </FormItem>


        <div className={css.buttons}>
            <button className={css.cancelButton} onClick={() => setEditAdult(false)} type="button">ANULUJ</button>
            <button className={css.confirmButton} type="submit">ZAPISZ</button>
        </div>
    </form>
}

export default EditAdultForm;