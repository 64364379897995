import React from 'react';
import classNames from 'classnames';

import heroImg from './hero.svg';
import arrowImg from './arrow.svg';
import checkImg from './check.svg';
import mailImg from './mail.svg';
import workoutImg from './workout.svg';
import css from './HowItWorks.css';

function HowItWorksSection() {
  return (
    <section className={css.section}>
      <div className={css.content}>
        <h2 className={css.title}>Jak to działa</h2>
        <div className={css.stepsWrapper}>
          <div className={css.step}>
            <div className={css.iconWrapper}>
              <img src={checkImg} />
            </div>
            <span className={css.desc} style={{ bottom: '-40px'}}>Zapisz się na trening</span>
          </div>

          <div className={css.arrowWrapper} style={{ backgroundImage: `url(${arrowImg})`}} />
          
          <div className={css.step}>
            <div className={css.iconWrapper}>
              <img src={mailImg} />
            </div>
            <span className={css.desc}>Otrzymasz na maila zaproszenie wraz z linkiem do treningu</span>
          </div>

          <div className={css.arrowWrapper} style={{ backgroundImage: `url(${arrowImg})`}} />

          <div className={css.step}>
            <div className={css.iconWrapper}>
              <img src={workoutImg} />
            </div>
            <span className={css.desc}>Połącz się za pomocą linku i trenuj z nami</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorksSection