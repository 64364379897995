import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import emailImg from './images/email.png';
import email2xImg from './images/email@2x.png';
import profileImg from './images/profile.png';
import profile2xImg from './images/profile@2x.png';
import startImg from './images/start.png';
import start2xImg from './images/start@2x.png';

import css from './AuthenticationPage.css';

const stages = {
  1: {
    img: emailImg,
    imgBig: email2xImg,
    header: 'Potwierdź adres email',
    desc: 'Witamy w Decathlon GO! Potwierdź swój adres email, klikając w wiadomość od nas, aby użytkownicy (uczestnicy i organizatorzy) mogli się z Tobą kontaktować.',
    btnText: 'Przejdź dalej'
  },
  2: {
    img: profileImg,
    imgBig: profile2xImg,
    header: 'Uzupełnij profil',
    desc: 'Uzupełniony profil będzie niezbędnych do organizacji oraz zapisywania się na wydarzenia, a także kontaktu z innymi użytkownikami. Pozwól innym się poznać.',
    btnText: 'Przejdź dalej'
  },
  3: {
    img: startImg,
    imgBig: start2xImg,
    header: 'Znajdź wydarzenie i zapisz się',
    desc: 'Jesteś już tylko o krok od dołączenia do społeczności Decathlon GO. Życzymy owocnego odkrywania tego co dla Ciebie przygotowaliśmy.',
    btnText: 'Zacznij korzystać'
  }
}

class Onboarding extends React.Component {
  state = {
    stage: 1
  }

  handleClick = () => {
    const { stage } = this.state;
    const { push } = this.props.history;

    if (stage !== 3) {
      this.setState({ stage: stage + 1})
    } else {
      push('/');
    }
  }
  
  render() {
    const { stage } = this.state;

    return (
      <div className={classNames(css.content, css.onboardingContent)}>
        <div className={css.onboardingWrapper}>
          <h1>Witamy w Decathlon GO!</h1>
          <img src={stages[stage].img} srcSet={`${stages[stage].img}, ${stages[stage].imgBig} 2x`} className={css.onboardingImg} />
          <h2 className={css.onnoardingHeader}>{stages[stage].header}</h2>
          <p className={css.onboardingDesc}>{stages[stage].desc}</p>
          <button onClick={this.handleClick} className={css.seeMoreBtn}>{stages[stage].btnText}</button>
          <div className={css.dotsWrapper}>
            <div className={classNames(css.dot, { [css.filledDot]: stage === 1 })} onClick={()=>this.setState({stage: 1})}/>
            <div className={classNames(css.dot, { [css.filledDot]: stage === 2 })} onClick={()=>this.setState({stage: 2})}/>
            <div className={classNames(css.dot, { [css.filledDot]: stage === 3 })} onClick={()=>this.setState({stage: 3})}/>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Onboarding);