import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import mainPageRun from '../../images/partner.png';

import css from './BecomePartner.css';

const BecomePartner = () => {
  return (
    <section className={css.eventPromoSection}>
      <div className={css.eventWrapper}>
        <LazyLoadImage
          className={css.eventPromoSectionImage}
          alt="Zostań partnerem"
          src={mainPageRun}
          height={470}
          width="100%"
        />
        <div className={css.eventPromoSectionContent}>
          <h2 className={css.headerContent}>Zostań naszym partnerem</h2>
          <p className={css.content}>
            Dołącz do naszej społeczności i organizuj wydarzenia na Decathlon GO bez żadnych umów
            oraz opłat. Zyskaj łatwe w obsłudze narzędzie, dzięki któremu zbierzesz zapisy na swój
            event oraz pozyskasz nowych uczestników.
          </p>
          <a href="/l/event-new" className={css.eventPromoSectionButton}>
            Dodaj wydarzenie
          </a>
        </div>
      </div>
    </section>
  );
};

export default BecomePartner;
