import React from "react";
import css from './InboxPage.css';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Avatar, NamedLink, UserDisplayName } from "../../components";
import {
    txIsRequested,
} from '../../util/transaction';
import { formatDate } from "./utils";
import BookingInfoMaybe from "./BookingInfoMaybe";
import { intlShape } from 'react-intl';

const { oneOf } = PropTypes;

const InboxItem = props => {

    const { unitType, type, tx, intl, stateData } = props;
    const { customer, provider, listing } = tx;
    const isOrder = type === 'order';

    const otherUser = isOrder ? provider : customer;
    const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
    const isOtherUserBanned = otherUser.attributes.banned;

    const isSaleNotification = !isOrder && txIsRequested(tx) && tx.booking.attributes.state !== 'accepted';
    const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
    const lastTransitionedAt = formatDate(intl, new Date(tx.attributes.lastTransitionAt));

    const linkClasses = classNames(css.itemLink, {
        [css.bannedUserLink]: isOtherUserBanned,
    });

    return (
        <div className={css.item}>
            <div className={css.itemAvatar}>
                <Avatar user={otherUser} />
            </div>
            <NamedLink
                className={linkClasses}
                name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                params={{ id: tx.id.uuid }}
            >
                <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
                <div className={css.itemInfo}>
                    <div className={classNames(css.itemUsername, stateData.nameClassName)}>
                        {otherUserDisplayName} | {tx.listing.attributes.title}
                    </div>
                    <BookingInfoMaybe
                        bookingClassName={stateData.bookingClassName}
                        intl={intl}
                        isOrder={isOrder}
                        tx={tx}
                        unitType={unitType}
                    />
                </div>
                <div className={css.itemState}>
                    <div className={classNames(css.stateName, stateData.stateClassName)}>
                        {stateData.state}
                    </div>
                    <div
                        className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
                        title={lastTransitionedAt.long}
                    >
                        {lastTransitionedAt.short}
                    </div>
                </div>
            </NamedLink>
        </div>
    );
};

InboxItem.propTypes = {
    unitType: propTypes.bookingUnitType.isRequired,
    type: oneOf(['order', 'sale']).isRequired,
    tx: propTypes.transaction.isRequired,
    intl: intlShape.isRequired,
};

export default InboxItem