import React, { useState } from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';

import { NamedLink } from '../../components';
import whrHelper from './whrHelper';

import css from './CalculatorPage.css';

const WHR = () => {
  const [whr, setWhr] = useState(null);
  const [sex, setSex] = useState(null);

  return (
    <div className={css.calculator}>
      <Formik
        initialValues={{ sex: null, circuit1: '', circuit2: '' }}
        onSubmit={(values) => {
          const whr = values.circuit1 / values.circuit2;
          setWhr(whr);
          setSex(values.sex)
        }}
      >
        {({
          values,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className={css.label} htmlFor="sex">Płeć</div>
            <div className={css.radioWrapper}>
              <span className={css.radioContainer}>
                <input
                  className={css.radioInput}
                  type="radio"
                  id="female"
                  name="sex"
                  value="female"
                  onChange={handleChange}
                />
                <label htmlFor="female" className={css.radioLabel}>Kobieta</label>
              </span>
              <span className={css.radioContainer}>
                <input
                  className={css.radioInput}
                  type="radio"
                  id="male"
                  name="sex"
                  value="male"
                  onChange={handleChange}
                />
                <label htmlFor="male" className={css.radioLabel}>Mężczyzna</label>
              </span>
            </div>

            <label className={css.label} htmlFor="circuit1">Obwód talii</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="circuit1"
                name="circuit1"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.circuit1}
              />
              <span className={css.inputIcon}>CM</span>
            </div>
            
            <label className={css.label} htmlFor="circuit2">Obwód bioder</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="circuit2"
                name="circuit2"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.circuit2}
              />
              <span className={css.inputIcon}>CM</span>
            </div>
            
            <div className={css.btnContainer}>
              <button className={css.submitCalcBtn} type="submit" disabled={!values.sex || !values.circuit1 || !values.circuit2}>
                Oblicz
              </button>
            </div>
          </form>
        )}
      </Formik>
      <div className={css.resultsContainer}>
        <div className={css.calcResults}>
          <h2 className={css.paragraphHeader}>Twój wskaźnik WHR wynosi</h2>
          <span className={classNames(css.resultNumber, whrHelper(whr, sex).css)}>{Math.round(whr * 100) / 100 || '-'}</span>
          <span className={classNames(css.advice, css.adviceHeader)}>Wynik:</span>
          <p className={classNames(css.resultHeader, whrHelper(whr, sex).css)}>{whrHelper(whr, sex).header}</p>
        </div>
        <div className={css.otherCalculators}>
          <span>Sprawdź inne kallulatory: </span>
          <NamedLink name="RFMCalculator">
            RFM
          </NamedLink>
          <NamedLink name="BMICalculator">
            BMI
          </NamedLink>
          <NamedLink name="BMRCalculator">
            BMR
          </NamedLink>
        </div>
      </div>
    </div>
  )
}

export default WHR;