import GeneralIcon from './components/icons/general.svg';
import BasketballIcon from './components/icons/basketball.svg';
import VolleyballIcon from './components/icons/volleyball.svg';
import FitnessIcon from './components/icons/fitness.svg';
import FishingIcon from './components/icons/fishing.svg';
import RunningIcon from './components/icons/running.svg';
import TennisIcon from './components/icons/tennis.svg';
import CyclingIcon from './components/icons/cycling.svg';
import MartialartsIcon from './components/icons/martialarts.svg';
import SailingIcon from './components/icons/sailing.svg';
import WindsurfingIcon from './components/icons/windsurfing.svg';
import FootballIcon from './components/icons/football.svg';
import YogaIcon from './components/icons/yoga.svg';
import ClimbingIcon from './components/icons/climbing.svg';
import SwimmingIcon from './components/icons/swimming.svg';
import DivingIcon from './components/icons/diving.svg';
import HorseRidingIcon from './components/icons/horseriding.svg';
import GolfIcon from './components/icons/golf.svg';
import TrekkingIcon from './components/icons/trekking.svg';
import BadmintonIcon from './components/icons/badminton.svg';
import TableTennisIcon from './components/icons/tabletennis.svg';
import NordicWalkingIcon from './components/icons/nordicwalking.svg';
import StrengthSportsIcon from './components/icons/strengthsports.svg';
import ArcheryIcon from './components/icons/archery.svg';
import SquashIcon from './components/icons/squash.svg';
import HockeyIcon from './components/icons/hockey.svg';
import BaseballIcon from './components/icons/baseball.svg';
import BoxingIcon from './components/icons/boxing.svg';
import AmericanFootballIcon from './components/icons/americanfootball.svg';
import RugbyIcon from './components/icons/rugby.svg';
import LongboardIcon from './components/icons/longboard.svg';
import PaintballIcon from './components/icons/paintball.svg';
import KorfballIcon from './components/icons/korfball.svg';
import LacrosseIcon from './components/icons/lacrosse.svg';
import ShootingIcon from './components/icons/shooting.svg';
import FencingIcon from './components/icons/fencing.svg';
import AcrobaticsIcon from './components/icons/acrobatics.svg';
import CanoeingIcon from './components/icons/canoeing.svg';
import RowingIcon from './components/icons/rowing.svg';

export const sportsConfig = {
  americanfootball: {
    icon: AmericanFootballIcon,
    color: '#D32832',
    name: 'Futbol amerykański',
  },
  baseball: {
    icon: BaseballIcon,
    color: '#d32832',
    name: 'Baseball',
  },
  basketball: {
    icon: BasketballIcon,
    color: '#DB5D2F',
    name: 'Koszykówka',
    externalProducts: [
      {
        name: 'Tarmak',
        desc: 'Dwustronne spodenki sh500r',
        price: '69,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/short.jpg',
        url: 'https://www.decathlon.pl/dwustronne-spodenki-sh500r-id_8540471.html',
      },
      {
        name: 'Tarmak',
        desc: 'Piłka bt100 rozm. 7',
        price: '49,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/bt100.jpg',
        url: 'https://www.decathlon.pl/pika-bt100-rozm7-pomara-id_8495726.html',
      },
      {
        name: 'Tarmak',
        desc: 'Nakolannik',
        price: '39,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/knee1.jpg',
        url: 'https://www.decathlon.pl/nakolannik-id_8394665.html',
      },
      {
        name: 'Tarmak',
        desc: 'Buty SHIELD 300',
        price: '119,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/but300.jpg',
        url: 'https://www.decathlon.pl/buty-shield-300-czar-bia-czerw-id_8497273.html',
      },
    ],
  },
  // boxing: {
  //   icon: BoxingIcon,
  //   color: '#d32832',
  //   name: 'Boks',
  //   externalProducts: [
  //     { name: 'DOMYOS', desc: 'Stanik fitness cardio Domyos 540', price: '69,99', imgUrl: 'https://contents.mediadecathlon.com/p2023629/k$8a91181962a75cc1b1ddd4d923ace9ff/sq/stanik-fitness-cardio-domyos-540.jpg?format=auto&f=1296x1296', url: 'https://www.decathlon.pl/p/stanik-fitness-cardio-domyos-540/_/R-p-332206?mc=8643072&c=Purpurowy' },
  //     { name: 'Nyamba', desc: 'Taśma elastyczna fitness Nyamba', price: '19,99', imgUrl: 'https://contents.mediadecathlon.com/p1788545/k$a5528cda21757a089a766115ea9f3184/sq/tasma-elastyczna-fitness-nyamba-3-sztuki-5-6-7-kg.jpg?format=auto&f=1296x1296', url: 'https://www.decathlon.pl/p/tasma-elastyczna-fitness-nyamba-3-sztuki-5-6-7-kg/_/R-p-305336?mc=8528803' },
  //     { name: 'DOMYOS', desc: 'Legginsy fitness Domyos', price: '99,99', imgUrl: 'https://contents.mediadecathlon.com/p2023437/k$ea3440911eb3acd88dd2255f4e7a363e/sq/legginsy-fitness-domyos-fti-500a.jpg?format=auto&f=1296x1296', url: 'https://www.decathlon.pl/p/legginsy-fitness-domyos-fti-500a/_/R-p-306496?mc=8651834&c=Purpurowy' },
  //     { name: 'Corength', desc: 'Zestaw hantli do treningu siłowego 20 kg', price: '249,99', imgUrl: 'https://contents.mediadecathlon.com/p1183101/k$28bf0f41482fecbf3eb6a50bb405059a/sq/zestaw-hantli-do-treningu-silowego-20-kg.jpg?format=auto&f=1296x1296', url: 'https://www.decathlon.pl/p/zestaw-hantli-do-treningu-silowego-20-kg/_/R-p-7449' },
  //   ]
  // },
  cycling: {
    icon: CyclingIcon,
    color: '#EFC70B',
    name: 'Kolarstwo',
  },
  enduro: {
    icon: CyclingIcon,
    color: '#EFC70B',
    name: 'Enduro',
  },
  fishing: {
    icon: FishingIcon,
    color: '#3d4129',
    name: 'Wędkarstwo',
  },
  martialarts: {
    icon: MartialartsIcon,
    color: '#99020b',
    name: 'Sztuki walki',
    externalProducts: [
      {
        name: 'OUTSHOCK',
        desc: 'STRÓJ DO JUDO 200 DLA DZIECKA',
        price: '39,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/sztuki_walki_judo_junior.jpg',
        url: 'https://www.decathlon.pl/stroj-do-judo-200-id_8365192.html',
      },
      {
        name: 'OUTSHOCK',
        desc: 'KARATEGA DO KARATE 200 DLA DZIECKA',
        price: '39,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/sztuki_walki_karate_junior.jpg',
        url: 'https://www.decathlon.pl/karatega-do-karate-200-id_8365189.html',
      },
      {
        name: 'OUTSHOCK',
        desc: 'PAS PRZESZYWANY SZTUKI WALKI 2.80M',
        price: '19,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/sztuki_walki_pas.jpg',
        url: 'https://www.decathlon.pl/pas-przeszywany-280m-id_8388781.html',
      },
      {
        name: 'OUTSHOCK',
        desc: 'STRÓJ DO JUDO, AIKIDO 100',
        price: '99,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/sztuki_walki_judo100.jpg',
        url: 'https://www.decathlon.pl/stroj-do-judo-aikido-100-id_8546429.html',
      },
      {
        name: 'OUTSHOCK',
        desc: 'KIMONO DO KARATE 100',
        price: '99,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/sztuki_walki_karate100.jpg',
        url: 'https://www.decathlon.pl/kimono-do-karate-100-id_8546400.html',
      },
    ],
  },
  running: {
    icon: RunningIcon,
    color: '#c5e822',
    name: 'Bieganie',
    externalProducts: [
      {
        name: 'Kalenji',
        desc: 'Czapka z daszkiem do biegania',
        price: '29,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1815926/k$cb3aac2aaff99426503eeccd5b3ceddf/sq/czapka-z-daszkiem-do-biegania-kalenji.jpg?format=auto&f=864x864',
        url: 'https://www.decathlon.pl/p/czapka-z-daszkiem-do-biegania/_/R-p-300422',
      },
      {
        name: 'Kiprun',
        desc: 'SKARPETKI DO BIEGANIA RUN500 MID X2',
        price: '19,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1743521/k$e45b128b6fcc41c79c39c6ddea83a3ee/sq/skarpetki-do-biegania-run500-mid-x2.jpg?format=auto&f=864x864',
        url: 'https://www.decathlon.pl/p/skarpetki-do-biegania-comfort-mid-x2-czarne/_/R-p-165396',
      },
      {
        name: 'Kalenji',
        desc: 'OPASKA DO BIEGANIA WIELOFUNKCYJNA',
        price: '14,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1997341/k$e2dcf1d1237c4b245680d2f98826c10b/sq/opaska-do-biegania-wielofunkcyjna.jpg?format=auto&f=864x864',
        url: 'https://www.decathlon.pl/p/wielofunkcyjna-opaska-do-biegania/_/R-p-2523',
      },
      {
        name: 'Kalenji',
        desc: 'Pas na telefon do biegania',
        price: '19,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p2155466/k$731cc93416c5ee4e649890ef866f6bb4/sq/pas-na-telefon-do-biegania-kalenji.jpg?format=auto&f=864x864',
        url: 'https://www.decathlon.pl/p/pas-na-telefon-do-biegania-kalenji/_/R-p-302460',
      },
    ],
  },
  // sailing: {
  //   icon: SailingIcon,
  //   color: '#2a93d5',
  //   name: 'Żeglarstwo'
  // },
  squash: {
    icon: SquashIcon,
    color: '#e5f000',
    name: 'Squash',
    externalProducts: [
      {
        name: 'OPFEEL',
        desc: 'RAKIETA DO SQUASHA SR 100',
        price: '39,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/squash_rakieta.jpg',
        url: 'https://www.decathlon.pl/rakieta-sr-100-id_8370043.html',
      },
      {
        name: 'OPFEEL',
        desc: 'PIŁKA DO SQUASHA SB560 CZERWONA KROPKA X2',
        price: '14,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/squash_pilkaczerwona.jpg',
        url: 'https://www.decathlon.pl/pika-sb560-czerwona-kropka-x2-id_8503655.html',
      },
      {
        name: 'OPFEEL',
        desc: 'PIŁKA DO SQUASHA SB990 2 ŻÓŁTE KROPKI X1',
        price: '9,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/squash_pilkazolta.jpg',
        url: 'https://www.decathlon.pl/pika-sb990-2-ote-kropki-x1-id_8353480.html',
      },
      {
        name: 'OPFEEL',
        desc: 'ZESTAW DO SQUASHA SR130',
        price: '129,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/squash_zestawrakiety.jpg',
        url: 'https://www.decathlon.pl/zestaw-sr-130-2019-id_8384014.html',
      },
    ],
  },
  tennis: {
    icon: TennisIcon,
    color: '#e5f000',
    name: 'Tenis ziemny',
  },
  volleyball: {
    icon: VolleyballIcon,
    color: '#174578',
    name: 'Siatkówka',
    externalProducts: [
      {
        name: 'Allsix',
        desc: 'Nakolanniki VKP100',
        price: '29,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/nakolanniki.jpg',
        url: 'https://www.decathlon.pl/nakolanniki-vkp100-czarne-id_8364273.html',
      },
      {
        name: 'Allsix',
        desc: 'Rękaw V500',
        price: '39,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rekawki.jpg',
        url: 'https://www.decathlon.pl/rkawki-vap500-czarne-id_8561779.html',
      },
      {
        name: 'Mikasa',
        desc: 'Piłka siatkowa Mikasa V200W',
        price: '259,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/pilkav200.jpg',
        url: 'https://www.decathlon.pl/pika-siatkowa-v200w-id_8584155.html',
      },
      {
        name: 'Allsix',
        desc: 'Buty V100 AD',
        price: '99,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/but.jpg',
        url: 'https://www.decathlon.pl/buty-v100-ad-mskie-id_8408005.html',
      },
    ],
  },
  // windsurfing: {
  //   icon: WindsurfingIcon,
  //   color: '#2caeff',
  //   name: 'Windsurfing'
  // },
  handball: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Piłka ręczna',
  },
  fitness: {
    icon: FitnessIcon,
    color: '#721e6e',
    name: 'Fitness',
    externalProducts: [
      {
        name: 'DOMYOS',
        desc: 'Stanik fitness cardio Domyos 540',
        price: '69,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p2023629/k$8a91181962a75cc1b1ddd4d923ace9ff/sq/stanik-fitness-cardio-domyos-540.jpg?format=auto&f=1296x1296',
        url:
          'https://www.decathlon.pl/p/stanik-fitness-cardio-domyos-540/_/R-p-332206?mc=8643072&c=Purpurowy',
      },
      {
        name: 'Nyamba',
        desc: 'Taśma elastyczna fitness Nyamba',
        price: '19,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1788545/k$a5528cda21757a089a766115ea9f3184/sq/tasma-elastyczna-fitness-nyamba-3-sztuki-5-6-7-kg.jpg?format=auto&f=1296x1296',
        url:
          'https://www.decathlon.pl/p/tasma-elastyczna-fitness-nyamba-3-sztuki-5-6-7-kg/_/R-p-305336?mc=8528803',
      },
      {
        name: 'DOMYOS',
        desc: 'Legginsy fitness Domyos',
        price: '99,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p2023437/k$ea3440911eb3acd88dd2255f4e7a363e/sq/legginsy-fitness-domyos-fti-500a.jpg?format=auto&f=1296x1296',
        url:
          'https://www.decathlon.pl/p/legginsy-fitness-domyos-fti-500a/_/R-p-306496?mc=8651834&c=Purpurowy',
      },
      {
        name: 'Corength',
        desc: 'Zestaw hantli do treningu siłowego 20 kg',
        price: '249,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1183101/k$28bf0f41482fecbf3eb6a50bb405059a/sq/zestaw-hantli-do-treningu-silowego-20-kg.jpg?format=auto&f=1296x1296',
        url: 'https://www.decathlon.pl/p/zestaw-hantli-do-treningu-silowego-20-kg/_/R-p-7449',
      },
    ],
  },
  swimming: {
    icon: SwimmingIcon,
    color: '#6bd2dc',
    name: 'Pływanie',
    externalProducts: [
      {
        name: 'NABAIJI',
        desc: 'OKULARKI PŁYWACKIE 100 XBASE L',
        price: '11,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_okularki.jpg',
        url: 'https://www.decathlon.pl/okularki-pywackie-xbase-black-senior-id_5998369.html',
      },
      {
        name: 'NABAIJI',
        desc: 'KLAPKI BASENOWE SLAP 100 BASIC MĘSKIE',
        price: '19,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_klapki.jpg',
        url: 'https://www.decathlon.pl/klapki-slap-100-basic-id_8388647.html',
      },
      {
        name: 'NABAIJI',
        desc: 'STRÓJ JEDNOCZĘŚCIOWY PŁYWACKI HEVA+ DAMSKI',
        price: '34,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_stroj.jpg',
        url: 'https://www.decathlon.pl/stroj-1cz-heva-id_8484202.html',
      },
      {
        name: 'NABAIJI',
        desc: 'KRÓTKIE PŁETWY PŁYWACKIE SILIFINS 500',
        price: '59,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_pletwy.jpg',
        url: 'https://www.decathlon.pl/krotkie-petwy-silifins-500-id_8403756.html',
      },
      {
        name: 'NABAIJI',
        desc: 'ZESTAW PŁYWACKI LEONY+ DLA DZIECI: STRÓJ OKULARKI CZEPEK RĘCZNIK TORBA',
        price: '59,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_zestaw.jpg',
        url: 'https://www.decathlon.pl/zestaw-leony-jr-id_8335083.html',
      },
      {
        name: 'NABAIJI',
        desc: 'RĘCZNIK Z MIKROFIBRY L 80 X 130 CM',
        price: '29,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_recznik.jpg',
        url: 'https://www.decathlon.pl/rcznik-mikrofibra-l-80x130-cm-id_8510068.html',
      },
    ],
  },
  badminton: {
    icon: BadmintonIcon,
    color: '#e5f000',
    name: 'Badminton',
    externalProducts: [
      {
        name: 'Perfly',
        desc: 'Rakiety do badmintona BR100 starter',
        price: '54,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rakietystarter.jpg',
        url: 'https://www.decathlon.pl/rakieta-br100-starter-id_8490832.html',
      },
      {
        name: 'Perfly',
        desc: 'Rakieta do badmintona BR160 solid',
        price: '54,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rakietaperfly.jpg',
        url: 'https://www.decathlon.pl/rakieta-br160-solid-czar-ziel-id_8490847.html',
      },
      {
        name: 'Yonex',
        desc: 'Naciąg do badmintona bg80',
        price: '24,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/naciagyonex.jpg',
        url: 'https://www.decathlon.pl/nacig-bg-80-niebieski-id_8539583.html',
      },
      {
        name: 'Perfly',
        desc: 'Lotki do badmintona z piór FSC 990',
        price: '99,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/lotkiperfly.jpg',
        url: 'https://www.decathlon.pl/lotki-fsc990-77-x12-id_8490818.html',
      },
    ],
  },
  tabletennis: {
    icon: TableTennisIcon,
    color: '#e5f000',
    name: 'Tenis stołowy',
    externalProducts: [
      {
        name: 'TIBHAR',
        desc: 'Piłki TIBHAR',
        price: '14,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/pileczki_tibhar.jpg',
        url: 'https://www.decathlon.pl/piki-syntt-ng-4-x3-biae-id_8406134.html',
      },
      {
        name: 'PONGORI',
        desc: 'Rakietka TTR100 PONGORI',
        price: '29,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rakietka100.jpg',
        url: 'https://www.decathlon.pl/rakietka-ttr-100-3-allround-id_8546785.html',
      },
      {
        name: 'PONGORI',
        desc: 'Zestaw 2 rakietek',
        price: '59,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/zestaw_rakietki.jpg',
        url: 'https://www.decathlon.pl/2-rakietki-ttr100-3-i-3-piki-id_8528613.html',
      },
      {
        name: 'STIGA',
        desc: 'Rakietka STIGA Flexure 5',
        price: '229,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rakietka_stiga.jpg',
        url: 'https://www.decathlon.pl/rakietka-flexure-5-id_8379896.html',
      },
    ],
  },
  climbing: {
    icon: ClimbingIcon,
    color: '#3c3c3b',
    name: 'Wspinaczka',
    externalProducts: [
      {
        name: 'SIMOND',
        desc: 'BUTY WSPINACZKOWE ROCK+',
        price: '199,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/wspinaczka_buty.jpg',
        url: 'https://www.decathlon.pl/buty-wspinaczkowe-rock-szare-id_8495164.html ',
      },
      {
        name: 'SIMOND',
        desc: 'WORECZEK NA MAGNEZJĘ L',
        price: '39,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/wspinaczka_woreczek.jpg',
        url: 'https://www.decathlon.pl/woreczek-na-magnezj-l-niebies-id_8495280.html',
      },
      {
        name: 'SIMOND',
        desc: 'MAGNEZJA W KULKACH',
        price: '14,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/wspinaczka_magnezja_kulka.jpg',
        url: 'https://www.decathlon.pl/magnezja-w-kulkach--id_8387375.html',
      },
      {
        name: 'SIMOND',
        desc: 'LINA WSPINACZKOWA INDOOR 10 MM X 25 M',
        price: '169,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/wspinaczka_lina.jpg',
        url: 'https://www.decathlon.pl/lina-indoor-10-mm-x-25-m-nieb-id_8542905.html',
      },
      {
        name: 'SIMOND',
        desc: 'UPRZĄŻ WSPINACZKOWA EASY DLA DZIECI',
        price: '129,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/wspinaczka_uprzaz.jpg',
        url: 'https://www.decathlon.pl/uprz-easy-dla-dzieci-id_8359208.html',
      },
      {
        name: 'SIMOND',
        desc: 'PLECAK ALPINISTYCZNY ALPINISM 22 L',
        price: '99,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/wspinaczka_plecak.jpg',
        url: 'https://www.decathlon.pl/plecak-alpinism-22-l-czarny-id_8495212.html',
      },
      {
        name: 'SIMOND',
        desc: 'KARABINEK WSPINACZKOWY 3000',
        price: '34,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/wspinaczka_karabinek.jpg',
        url: 'https://www.decathlon.pl/karabinek-wspinaczkowy-3000--id_8248866.html',
      },
    ],
  },
  football: {
    icon: FootballIcon,
    color: '#abc748',
    name: 'Piłka nożna',
    externalProducts: [
      {
        name: 'Kipsta',
        desc: 'Buty do piłki nożnej Kipsta Viralto IVr',
        price: '369,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p2380224/k$29edc1504d354ef27a9372a3d941c157/1296x1296/KIPSTA%20VIRALTO%20IV%20CUIR%20EVO%20FG%20NOIR.jpg?format=auto',
        url:
          'https://www.decathlon.pl/p/buty-do-pilki-noznej-kipsta-viralto-iv-premium-skorzane-fg/_/R-p-325215?mc=8787420&c=Szary',
      },
      {
        name: 'Kipsta',
        desc: 'Piłka Ligue 1',
        price: '369,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p2302028/k$85adc97c9d520533a7ff003c78db26d4/1296x1296/KIPSTA%20DOTATION%20OMB%20LIGUE%201%20T5%20AH22.jpg?format=auto',
        url:
          'https://www.decathlon.pl/p/pilka-do-pilki-noznej-kipsta-ligue-1-uber-eats-officiel-match-ball-2022/_/R-p-338852?mc=8750136&c=Bia%25C5%2582y',
      },
      {
        name: 'Kipsta',
        desc: 'Buty CRL Pixel Game',
        price: '399,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p2454414/k$3f53e28c9b5f7a92d16739440bf71ff1/1296x1296/KIPSTA%20CLR%20FG%20PIXEL%20GAME.jpg?format=auto',
        url:
          'https://www.decathlon.pl/p/buty-do-pilki-noznej-kipsta-clr-pixel-game-fg/_/R-p-341445?mc=8750990&c=Wielokolorowy',
      },
      {
        name: 'Kipsta',
        desc: 'Koszulka Viralto II',
        price: '119,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p2379734/k$a269154bbd7fa1eb7a44b26bdf97e058/1296x1296/KIPSTA%20MAILLOT%20VIRALTO%20II%20%20MIXED%20BLEU%20NOIR%20BLAN.jpg?format=auto',
        url:
          'https://www.decathlon.pl/p/koszulka-do-pilki-noznej-kipsta-viralto-ii/_/R-p-339577?mc=8756304',
      },
    ],
  },
  kayaking: {
    icon: CanoeingIcon,
    color: '#2CAEFF',
    name: 'Kajakarstwo',
  },
  strength_sports: {
    icon: StrengthSportsIcon,
    color: '#a5329f',
    name: 'Sporty siłowe',
    externalProducts: [
      {
        name: 'DOMYOS',
        desc: 'TOP FITNESS KARDIO',
        price: '69,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_top.jpg',
        url: 'https://www.decathlon.pl/krotki-top-fct-900--id_8402633.html',
      },
      {
        name: 'DOMYOS',
        desc: 'LEGGINSY FITNESS KARDIO',
        price: '49,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_legginsy.jpg',
        url: 'https://www.decathlon.pl/legginsy-fti-100-czarne-id_8554673.html',
      },
      {
        name: 'DOMYOS',
        desc: 'MATA TONEMAT',
        price: '29,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_mata.jpg',
        url: 'https://www.decathlon.pl/tonemat-s-id_8573748.html',
      },
      {
        name: 'DOMYOS',
        desc: 'TAŚMA ELASTIBAND',
        price: '24,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_tasma.jpg',
        url: 'https://www.decathlon.pl/tama-elastiband-100-light-id_8527896.html',
      },
      {
        name: 'DOMYOS',
        desc: 'KOSZULKA FITNESS CARDIO',
        price: '29,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_koszulka.jpg',
        url: 'https://www.decathlon.pl/koszulka-fts-120-id_8549874.html',
      },
      {
        name: 'DOMYOS',
        desc: 'SPODENKI FITNESS KARDIO',
        price: '24,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_spodenki.jpg',
        url: 'https://www.decathlon.pl/spodenki-fst-120-id_8510183.html',
      },
      {
        name: 'NYAMBA',
        desc: 'OBCIĄŻENIA NA NADGARSTKI I KOSTKI',
        price: '34,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_obciazniki.jpg',
        url:
          'https://www.decathlon.pl/obcienie-na-nadgarstki-i-kostki-gymweight-2-x-05-kg-id_8358970.html',
      },
      {
        name: 'DOMYOS',
        desc: 'UCHWYTY PUSH UP BARS',
        price: '19,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_pushups.jpg',
        url: 'https://www.decathlon.pl/uchwyty-push-up-bars-id_8375469.html',
      },
      {
        name: 'NYAMBA',
        desc: 'ZESTAW MINITAŚM X 3',
        price: '19,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_zestaw_tasm.jpg',
        url: 'https://www.decathlon.pl/mini-bandy-tamy-do-pilatesu-x3-id_8528803.html',
      },
      {
        name: 'DOMYOS',
        desc: 'MATA YOGA 4MM',
        price: '39,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/f_mata4mm.jpg',
        url: 'https://www.decathlon.pl/mata-yoga-4mm-id_8506526.html',
      },
      {
        name: 'DOMYOS',
        desc: 'Koszulka do fitnessu',
        price: '16,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/mytop.jpg',
        url: 'https://www.decathlon.pl/top-do-fitnessu-damski-id_8125767.html',
      },
      {
        name: 'DOMYOS',
        desc: 'Skakanka',
        price: '19,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/skakanka.jpg',
        url: 'https://www.decathlon.pl/skakanka-fitness-500-id_8556907.html',
      },
      {
        name: 'DOMYOS',
        desc: 'Buty do fitnessu',
        price: '49,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/butdomyos.jpg',
        url: 'https://www.decathlon.pl/buty-100--id_8379422.html',
      },
      {
        name: 'DOMYOS',
        desc: 'Legginsy do fitnessu',
        price: '79,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/legginsy500.jpg',
        url: 'https://www.decathlon.pl/legginsy-fti-500a-id_8547528.html',
      },
    ],
  },
  roller_blading: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Rolki',
  },
  golf: {
    icon: GolfIcon,
    color: '#88ae2c',
    name: 'Golf',
  },
  horse_riding: {
    icon: HorseRidingIcon,
    color: '#6e4d2f',
    name: 'Jeździectwo',
  },
  hockey: {
    icon: HockeyIcon,
    color: '#2caeff',
    name: 'Hokej',
  },
  // skating: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Łyżwiarstwo'
  // },
  nordicwalking: {
    icon: NordicWalkingIcon,
    color: '#c5e822',
    name: 'Nordic walking',
  },
  yoga: {
    icon: YogaIcon,
    color: '#a5b5d5',
    name: 'Joga',
    externalProducts: [
      {
        name: 'Kimjaly',
        desc: 'Mata do jogi Kimjaly Light 5 mm',
        price: '99,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1928990/k$4683d31f21656dde92d9c40841421793/sq/mata-do-jogi-kimjaly-light-5-mm.jpg?format=auto&f=1296x1296',
        url:
          'https://www.decathlon.pl/p/mata-do-jogi-club/_/R-p-15228?mc=8640176&c=R%2525C3%2525B3%2525C5%2525BCowy',
      },
      {
        name: 'Kimjaly',
        desc: 'Klocek do jogi Kimjaly piankowy',
        price: '24,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1938876/k$38ff7e0a9a96355d2d305b448bed0e9d/sq/klocek-do-jogi-kimjaly-piankowy.jpg?format=auto&f=1296x1296',
        url: 'https://www.decathlon.pl/p/kostka-do-jogi/_/R-p-14222?mc=8611100&c=R%C3%B3%C5%BCowy',
      },
      {
        name: 'Kimjaly',
        desc: 'Pasek do jogi Kimjaly',
        price: '24,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1938921/k$451e285b38a30cbcc766cc4e060e8955/sq/pasek-do-jogi-kimjaly.jpg?format=auto&f=1296x1296',
        url:
          'https://www.decathlon.pl/p/pasek-do-jogi-kimjaly/_/R-p-183211?mc=8611075&c=R%C3%B3%C5%BCowy',
      },
      {
        name: 'Kimjaly',
        desc: 'Legginsy do jogi damskie Kimjaly',
        price: '79,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1786456/k$c5530688cb0962b82dd4b7951a92282f/sq/legginsy-do-jogi-damskie-kimjaly.jpg?format=auto&f=1296x1296',
        url:
          'https://www.decathlon.pl/p/legginsy-do-jogi-damskie-kimjaly/_/R-p-308792?mc=8560839&c=Czarny',
      },
      {
        name: 'Kimjaly',
        desc: 'Bluza do jogi damska Kimjaly',
        price: '79,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1585542/k$f217221213d95a43332c0561d5b2873b/sq/bluza-do-jogi-damska-kimjaly.jpg?format=auto&f=1296x1296',
        url:
          'https://www.decathlon.pl/p/bluza-do-jogi-damska-kimjaly/_/R-p-300500?mc=8525360&c=R%C3%B3%C5%BCowy',
      },
    ],
  },
  triathlon: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Triathlon',
  },
  // diving: {
  //   icon: DivingIcon,
  //   color: '#6bd2dc',
  //   name: 'Nurkowanie',
  //   externalProducts: [
  //     { name: 'MARES', desc: 'PŁETWY NURKOWE AVANTI SUPERCHANNEL', price: '159,99', imgUrl: 'https://kgcodego.b-cdn.net/products/nurkowanie_pletwy.jpg', url: 'https://www.decathlon.pl/petwy-avanti-superchannel-id_8030754.html' },
  //     { name: 'CRESSI', desc: 'FAJKA CORSICA DO ŁOWIECTWA I FREEDIVINGU', price: '49,99', imgUrl: 'https://kgcodego.b-cdn.net/products/nurkowanie_fajka.jpg', url: 'https://www.decathlon.pl/fajka-corsica-id_8091545.html' },
  //     { name: 'SUBEA', desc: 'MASKA NURKOWA SCD 500 MONO JEDNOSZYBOWA', price: '89,99', imgUrl: 'https://kgcodego.b-cdn.net/products/nurkowanie_maska.jpg', url: 'https://www.decathlon.pl/maska-scd-500-mono-id_8587044.html' },
  //     { name: 'MARES', desc: 'ZEGAREK-KOMPUTER NURKOWY SMART', price: '1099,00', imgUrl: 'https://kgcodego.b-cdn.net/products/nurkowanie_zegarek.jpg', url: 'https://www.decathlon.pl/zegarek-komputer-smart-id_8360611.html' },
  //     { name: 'SUBEA', desc: 'JACKET NURKOWY TYPU SKRZYDŁO SCD 500B', price: '999,99', imgUrl: 'https://kgcodego.b-cdn.net/products/nurkowanie_kamizelka.jpg', url: 'https://www.decathlon.pl/jacket-scd-500b-skrzydo-id_8550807.html' },
  //     { name: 'SUBEA', desc: 'KOMBINEZON NURKOWY OVERALL SCD 100 Z NEOPRENU', price: '399,99', imgUrl: 'https://kgcodego.b-cdn.net/products/nurkowanie_pianka_pelna.jpg', url: 'https://www.decathlon.pl/kombinezon-scd-100-55-mm-id_8354115.html' },
  //     { name: 'SUBEA', desc: 'KRÓTKA PIANKA NURKOWA SCD 500 3MM DAMSKA', price: '289,99', imgUrl: 'https://kgcodego.b-cdn.net/products/nurkowanie_pianka_krotka.jpg', url: 'https://www.decathlon.pl/krotka-pianka-scd-500-3-mm-id_8491781.html' },
  //   ]
  // },
  // skiing: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Narciarstwo'
  // },
  // snowboard: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Snowboard'
  // },
  // surfing: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Surfing'
  // },
  // archery: {
  //   icon: ArcheryIcon,
  //   color: '#d32832',
  //   name: 'Łucznictwo'
  // },
  dart: {
    icon: ShootingIcon,
    color: '#93a3ae',
    name: 'Dart',
  },
  tourism: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Turystyka',
    externalProducts: [
      {
        name: 'QUECHUA',
        desc: 'KOSZULKA TURYSTYCZNA KRÓTKI RĘKAW MH100 MĘSKA',
        price: '19,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/turystyka_koszulkameska.jpg',
        url: 'https://www.decathlon.pl/koszulka-turystyczna-techfresh-50-mska--id_8316244.html',
      },
      {
        name: 'QUECHUA',
        desc: 'SKARPETY TURYSTYCZNE NH100 MID X 2 PARY',
        price: '9,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/turystyka_skarpety.jpg',
        url: 'https://www.decathlon.pl/skarpety-turystyczne-arpenaz-50-mid-id_8329439.html',
      },
      {
        name: 'QUECHUA',
        desc: 'POLAR TURYSTYCZNY MH100 MĘSKI',
        price: '29,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/turystyka_polar.jpg',
        url: 'https://www.decathlon.pl/polar-mh100-mski-id_8397388.html',
      },
      {
        name: 'QUECHUA',
        desc: 'KOSZULKA TURYSTYCZNA Z KRÓTKIM RĘKAWEM - MH100 - DAMSKA',
        price: '19,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/turystyka_koszulka1.jpg',
        url: 'https://www.decathlon.pl/koszulka-mh100-damska-id_8502219.html',
      },
    ],
  },
  sport_festival: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Sport Festival',
  },
  // backpacking: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Backpacking',
  //   externalProducts: [
  //     { name: 'QUECHUA', desc: 'KOSZULKA TURYSTYCZNA KRÓTKI RĘKAW MH100 MĘSKA', price: '19,99', imgUrl: 'https://kgcodego.b-cdn.net/products/turystyka_koszulkameska.jpg', url: 'https://www.decathlon.pl/koszulka-turystyczna-techfresh-50-mska--id_8316244.html' },
  //     { name: 'QUECHUA', desc: 'SKARPETY TURYSTYCZNE NH100 MID X 2 PARY', price: '9,99', imgUrl: 'https://kgcodego.b-cdn.net/products/turystyka_skarpety.jpg', url: 'https://www.decathlon.pl/skarpety-turystyczne-arpenaz-50-mid-id_8329439.html' },
  //     { name: 'QUECHUA', desc: 'POLAR TURYSTYCZNY MH100 MĘSKI', price: '29,99', imgUrl: 'https://kgcodego.b-cdn.net/products/turystyka_polar.jpg', url: 'https://www.decathlon.pl/polar-mh100-mski-id_8397388.html' },
  //     { name: 'QUECHUA', desc: 'KOSZULKA TURYSTYCZNA Z KRÓTKIM RĘKAWEM - MH100 - DAMSKA', price: '19,99', imgUrl: 'https://kgcodego.b-cdn.net/products/turystyka_koszulka1.jpg', url: 'https://www.decathlon.pl/koszulka-mh100-damska-id_8502219.html' }
  //   ]
  // },
  dancing: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Taniec',
  },
  // aquafitness: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Aquafitness',
  //   externalProducts: [
  //     { name: 'NABAIJI', desc: 'OKULARKI PŁYWACKIE 100 XBASE L', price: '11,99', imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_okularki.jpg', url: 'https://www.decathlon.pl/okularki-pywackie-xbase-black-senior-id_5998369.html' },
  //     { name: 'NABAIJI', desc: 'KLAPKI BASENOWE SLAP 100 BASIC MĘSKIE', price: '19,99', imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_klapki.jpg', url: 'https://www.decathlon.pl/klapki-slap-100-basic-id_8388647.html' },
  //     { name: 'NABAIJI', desc: 'STRÓJ JEDNOCZĘŚCIOWY PŁYWACKI HEVA+ DAMSKI', price: '34,99', imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_stroj.jpg', url: 'https://www.decathlon.pl/stroj-1cz-heva-id_8484202.html' },
  //     { name: 'NABAIJI', desc: 'KRÓTKIE PŁETWY PŁYWACKIE SILIFINS 500', price: '59,99', imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_pletwy.jpg', url: 'https://www.decathlon.pl/krotkie-petwy-silifins-500-id_8403756.html' },
  //     { name: 'NABAIJI', desc: 'ZESTAW PŁYWACKI LEONY+ DLA DZIECI: STRÓJ OKULARKI CZEPEK RĘCZNIK TORBA', price: '59,99', imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_zestaw.jpg', url: 'https://www.decathlon.pl/zestaw-leony-jr-id_8335083.html' },
  //     { name: 'NABAIJI', desc: 'RĘCZNIK Z MIKROFIBRY L 80 X 130 CM', price: '29,99', imgUrl: 'https://kgcodego.b-cdn.net/products/plywanie_recznik.jpg', url: 'https://www.decathlon.pl/rcznik-mikrofibra-l-80x130-cm-id_8510068.html' }
  //   ]
  // },
  rugby: {
    icon: RugbyIcon,
    color: '#88AE2C',
    name: 'Rugby',
    externalProducts: [
      {
        name: 'OFFLOAD',
        desc: 'PIŁKA DO RUGBY R300 ROZMIAR 5',
        price: '39,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rugby_pilka.jpg',
        url: 'https://www.decathlon.pl/pika-r300-rozmiar-5-id_8407951.html',
      },
      {
        name: 'OFFLOAD',
        desc: 'KASK DO RUGBY 500 DLA DZIECI',
        price: '84,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rugby_kask.jpg',
        url: 'https://www.decathlon.pl/kask-r500-jr-nieb-biae-id_8522570.html',
      },
      {
        name: 'OFFLOAD',
        desc: 'PODSPODENKI OCHRONNE DO RUGBY R500',
        price: '109,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rugby_spodenki.jpg',
        url: 'https://www.decathlon.pl/podspodenki-ochronne-500-czar-id_8550433.html',
      },
      {
        name: 'OFFLOAD',
        desc: 'OCHRANIACZ TORSU DO RUGBY R100',
        price: '129,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rugby_koszulkaochronna.jpg',
        url: 'https://www.decathlon.pl/ochraniacz-torsu-r100-czar-o-id_8550430.html',
      },
      {
        name: 'OFFLOAD',
        desc: 'BUTY DO RUGBY NA GRZĄSKĄ NAWIERZCHNIĘ',
        price: '169,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rugby_buty.jpg',
        url: 'https://www.decathlon.pl/density-r900-sg-8-czarne-id_8496923.html',
      },
      {
        name: 'OFFLOAD',
        desc: 'KOSZULKA DO RUGBY R500 MĘSKA',
        price: '89,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rugby_koszulka.jpg',
        url: 'https://www.decathlon.pl/koszulka-r500-czerwona-id_8558282.html',
      },
      {
        name: 'OFFLOAD',
        desc: 'LEGGINSY DO RUGBY R500 DLA DZIECI',
        price: '54,99',
        imgUrl: 'https://kgcodego.b-cdn.net/products/rugby_legginsy.jpg',
        url: 'https://www.decathlon.pl/legginsy-r500-jr-nieb-id_8560030.html',
      },
    ],
  },
  // longboard: {
  //   icon: LongboardIcon,
  //   color: '#8B7058',
  //   name: 'Longboard'
  // },
  // paintball: {
  //   icon: PaintballIcon,
  //   color: '#D32832',
  //   name: 'Paintball'
  // },
  // korfball: {
  //   icon: KorfballIcon,
  //   color: '#ABC748',
  //   name: 'Korfball'
  // },
  // lacrosse: {
  //   icon: LacrosseIcon,
  //   color: '#ABC748',
  //   name: 'Lacrosse'
  // },
  // shooting: {
  //   icon: ShootingIcon,
  //   color: '#D32832',
  //   name: 'Strzelectwo'
  // },
  // fencing: {
  //   icon: FencingIcon,
  //   color: '#D32832',
  //   name: 'Szermierka'
  // },
  acrobatics: {
    icon: AcrobaticsIcon,
    color: '#721E6E',
    name: 'Akrobatyka',
  },
  // rowing: {
  //   icon: RowingIcon,
  //   color: '#2CAEFF',
  //   name: 'Wioślarstwo',
  //   externalProducts: [
  //     { name: 'KALENJI', desc: 'PAS KARDIO DO BIEGANIA DUAL ANT+ / BLUETOOTH SMART', price: '149,99', imgUrl: 'https://kgcodego.b-cdn.net/products/wiosla_pas.jpg', url: ' https://www.decathlon.pl/pas-kard-ant-bluetooth-smart-id_8334795.html' },
  //     { name: 'KALENJI', desc: 'PULSOMETR DO BIEGANIA ONRHYTHM 500', price: '149,99', imgUrl: 'https://kgcodego.b-cdn.net/products/wiosla_zegarek.jpg', url: 'https://www.decathlon.pl/pulsometr-onrhythm-500-id_8389271.html' },
  //     { name: 'DOMYOS', desc: 'SPODENKI FITNESS KARDIO FST 500 MĘSKIE', price: '59,99', imgUrl: 'https://kgcodego.b-cdn.net/products/wiosla_spodenki.jpg', url: 'https://www.decathlon.pl/spodenki-fst-500-id_8510213.html' },
  //     { name: 'DOMYOS', desc: 'KOSZULKA FITNESS KRÓTKI RĘKAW FTS500 MĘSKA', price: '59,99', imgUrl: 'https://kgcodego.b-cdn.net/products/wiosla_koszulka.jpg', url: 'https://www.decathlon.pl/koszulka-fts-500--id_8561089.html' }
  //   ]
  // },
  // pickleball: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Pickleball'
  // },
  // parkour: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Parkour'
  // },
  // racketlon: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Racketlon'
  // },
  frisbee: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Frisbee',
  },
  // fitnesswalking: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Fitness walking',
  //   externalProducts: [
  //     { name: 'Kalenji', desc: 'Czapka z daszkiem do biegania', price: '29,99', imgUrl: 'https://contents.mediadecathlon.com/p1815926/k$cb3aac2aaff99426503eeccd5b3ceddf/sq/czapka-z-daszkiem-do-biegania-kalenji.jpg?format=auto&f=864x864', url: 'https://www.decathlon.pl/p/czapka-z-daszkiem-do-biegania/_/R-p-300422' },
  //     { name: 'Kiprun', desc: 'SKARPETKI DO BIEGANIA RUN500 MID X2', price: '19,99', imgUrl: 'https://contents.mediadecathlon.com/p1743521/k$e45b128b6fcc41c79c39c6ddea83a3ee/sq/skarpetki-do-biegania-run500-mid-x2.jpg?format=auto&f=864x864', url: 'https://www.decathlon.pl/p/skarpetki-do-biegania-comfort-mid-x2-czarne/_/R-p-165396' },
  //     { name: 'Kalenji', desc: 'OPASKA DO BIEGANIA WIELOFUNKCYJNA', price: '14,99', imgUrl: 'https://contents.mediadecathlon.com/p1997341/k$e2dcf1d1237c4b245680d2f98826c10b/sq/opaska-do-biegania-wielofunkcyjna.jpg?format=auto&f=864x864', url: 'https://www.decathlon.pl/p/wielofunkcyjna-opaska-do-biegania/_/R-p-2523' },
  //     { name: 'Kalenji', desc: 'Pas na telefon do biegania', price: '19,99', imgUrl: 'https://contents.mediadecathlon.com/p2155466/k$731cc93416c5ee4e649890ef866f6bb4/sq/pas-na-telefon-do-biegania-kalenji.jpg?format=auto&f=864x864', url: 'https://www.decathlon.pl/p/pas-na-telefon-do-biegania-kalenji/_/R-p-302460' }
  //   ]
  // },
  athletics: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Lekkoatletyka',
    externalProducts: [
      {
        name: 'Kalenji',
        desc: 'Czapka z daszkiem do biegania',
        price: '29,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1815926/k$cb3aac2aaff99426503eeccd5b3ceddf/sq/czapka-z-daszkiem-do-biegania-kalenji.jpg?format=auto&f=864x864',
        url: 'https://www.decathlon.pl/p/czapka-z-daszkiem-do-biegania/_/R-p-300422',
      },
      {
        name: 'Kiprun',
        desc: 'SKARPETKI DO BIEGANIA RUN500 MID X2',
        price: '19,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1743521/k$e45b128b6fcc41c79c39c6ddea83a3ee/sq/skarpetki-do-biegania-run500-mid-x2.jpg?format=auto&f=864x864',
        url: 'https://www.decathlon.pl/p/skarpetki-do-biegania-comfort-mid-x2-czarne/_/R-p-165396',
      },
      {
        name: 'Kalenji',
        desc: 'OPASKA DO BIEGANIA WIELOFUNKCYJNA',
        price: '14,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p1997341/k$e2dcf1d1237c4b245680d2f98826c10b/sq/opaska-do-biegania-wielofunkcyjna.jpg?format=auto&f=864x864',
        url: 'https://www.decathlon.pl/p/wielofunkcyjna-opaska-do-biegania/_/R-p-2523',
      },
      {
        name: 'Kalenji',
        desc: 'Pas na telefon do biegania',
        price: '19,99',
        imgUrl:
          'https://contents.mediadecathlon.com/p2155466/k$731cc93416c5ee4e649890ef866f6bb4/sq/pas-na-telefon-do-biegania-kalenji.jpg?format=auto&f=864x864',
        url: 'https://www.decathlon.pl/p/pas-na-telefon-do-biegania-kalenji/_/R-p-302460',
      },
    ],
  },
  ocr: {
    icon: RunningIcon,
    color: '#c5e822',
    name: 'Bieganie przeszkodowe',
    // externalProducts: [
    //   { name: 'Kalenji', desc: 'Czapka z daszkiem do biegania', price: '29,99', imgUrl: 'https://contents.mediadecathlon.com/p1815926/k$cb3aac2aaff99426503eeccd5b3ceddf/sq/czapka-z-daszkiem-do-biegania-kalenji.jpg?format=auto&f=864x864', url: 'https://www.decathlon.pl/p/czapka-z-daszkiem-do-biegania/_/R-p-300422' },
    //   { name: 'Kiprun', desc: 'SKARPETKI DO BIEGANIA RUN500 MID X2', price: '19,99', imgUrl: 'https://contents.mediadecathlon.com/p1743521/k$e45b128b6fcc41c79c39c6ddea83a3ee/sq/skarpetki-do-biegania-run500-mid-x2.jpg?format=auto&f=864x864', url: 'https://www.decathlon.pl/p/skarpetki-do-biegania-comfort-mid-x2-czarne/_/R-p-165396' },
    //   { name: 'Kalenji', desc: 'OPASKA DO BIEGANIA WIELOFUNKCYJNA', price: '14,99', imgUrl: 'https://contents.mediadecathlon.com/p1997341/k$e2dcf1d1237c4b245680d2f98826c10b/sq/opaska-do-biegania-wielofunkcyjna.jpg?format=auto&f=864x864', url: 'https://www.decathlon.pl/p/wielofunkcyjna-opaska-do-biegania/_/R-p-2523' },
    //   { name: 'Kalenji', desc: 'Pas na telefon do biegania', price: '19,99', imgUrl: 'https://contents.mediadecathlon.com/p2155466/k$731cc93416c5ee4e649890ef866f6bb4/sq/pas-na-telefon-do-biegania-kalenji.jpg?format=auto&f=864x864', url: 'https://www.decathlon.pl/p/pas-na-telefon-do-biegania-kalenji/_/R-p-302460' }
    // ]
  },
  physiotherapy: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Fizjoterapia',
  },
  // wakeboard: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Wakeboard'
  // },
  dietetics: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Dietetyka',
  },
  ocr: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'OCR',
  },
  sport_festival: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Sport Festival',
  },
  other: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Inne',
  },
  bts: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'BTS',
  },
  // cleaning: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Sprzątanie świata',
  // },
  skateboard: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Deskorolka',
  },
  water_sports: {
    icon: WindsurfingIcon,
    color: '#4a90e2',
    name: 'Sporty wodne',
  },
  padel: {
    icon: TennisIcon,
    color: '#4a90e2',
    name: 'Padel',
  },
  winter_sports: {
    icon: GeneralIcon,
    color: '#4a90e2',
    name: 'Sporty zimowe',
  },
  // kaber: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'KaBeR',
  // },
  // canicross: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Canicross',
  // },
  // bikejoring: {
  //   icon: GeneralIcon,
  //   color: '#4a90e2',
  //   name: 'Bikejoring',
  // },
  mobility: {
    icon: CyclingIcon,
    color: '#4a90e2',
    name: 'Mobility',
  },
  roadCycling: {
    icon: CyclingIcon,
    color: '#4a90e2',
    name: 'Rower szosowy',
  },
};

export const recommendationConfig = {
  americanfootball: {
    category2: 'futbol amerykański',
    natureofproduct: [
      'piłka do futbolu amerykańskiego',
      'rękawice do futbolu amerykańskiego',
      'koszulka do futbolu amerykańskiego',
    ],
  },
  baseball: {
    category2: 'baseball',
    natureofproduct: [
      'baza baseballowa',
      'kij baseballowy',
      'piłka baseballowa',
      'rękawica baseballowa',
      'zestaw do baseballu',
    ],
  },
  basketball: {
    category2: 'koszykowka',
    natureofproduct: ['kosz do koszykówki', 'piłka do koszykówki'],
  },
  cycling: {
    category2: 'rowery',
    natureofproduct: ['bielizna rowerowa', 'kask rowerowy', 'oświetlenie rowerowe'],
  },
  roadCycling: {
    category2: 'rowery',
    natureofproduct: ['bielizna rowerowa', 'kask rowerowy', 'oświetlenie rowerowe'],
  },
  fishing: {
    category2: 'wędkarstwo',
    natureofproduct: [],
  },
  martialarts: {
    category2: 'sporty walki',
    natureofproduct: ['buty', 'bandaże bokserskie', 'szorty', 'skakanka', 'ochraniacz zębów'],
  },
  running: {
    category2: 'bieganie',
    natureofproduct: [
      'bluza',
      'czołówka',
      'koszulka długi rękaw',
      'koszulka z długim rękawem',
      'legginsy do biegania',
      'lampka piersiowa',
      'monitor aktywności fizycznej',
      'spodnie',
    ],
  },
  squash: {
    category2: 'squash',
    natureofproduct: ['piłka do squasha', 'plecak', 'rakieta do squasha', 'skarpetki', 'szorty'],
  },
  tennis: {
    category2: 'tenis',
    natureofproduct: [
      'koszulka',
      'koszulka z długim rękawem',
      'legginsy',
      'piłka',
      'szorty',
      'skarpetki',
    ],
  },
  volleyball: {
    category2: 'siatkówka halowa',
    natureofproduct: [
      'koszulka',
      'legginsy',
      'ochraniacz kolana',
      'piłka do siatkówki',
      'skarpetki',
      'spodnie',
      'szorty',
      'zestaw ochraniaczy',
    ],
  },
  handball: {
    category2: 'piłka ręczna',
    natureofproduct: [
      'buty',
      'koszulka z krótkim rękawem',
      'piłka do piłki ręcznej',
      'skarpetki',
      'spodnie',
      'szorty',
    ],
  },
  fitness: {
    category2: 'fitness, siłownia',
    natureofproduct: [
      'bluza',
      'buty',
      'koszulka',
      'koszulka bez rękawów',
      'koszulka crop top',
      'legginsy',
      'skarpetki',
      'spodnie',
      'szorty',
    ],
  },
  swimming: {
    category2: 'pływanie',
    natureofproduct: [
      'czepek kąpielowy',
      'majtki od stroju pływackiego',
      'strój pływacki',
      'strój pływacki jednoczęściowy',
    ],
  },
  badminton: {
    category2: 'badminton',
    natureofproduct: ['buty', 'koszulka', 'lotka plastikowa', 'ręcznik', 'szorty', 'sukienka'],
  },
  tabletennis: {
    category2: 'tenis stołowy',
    natureofproduct: ['buty', 'rakieta', 'koszulka', 'szorty'],
  },
  climbing: {
    category2: 'wspinaczka, alpinizm',
    natureofproduct: ['bluza', 'buty do wspinaczki', 'buty', 'koszulka', 'spodnie'],
  },
  football: {
    category2: 'piłka nożna',
    natureofproduct: [
      'bluza',
      'buty piłkarskie',
      'koszulka z długim rękawem',
      'koszulka z krótkim rękawem',
      'odzież termoaktywna',
      'piłka do piłki nożnej',
      'skarpetki',
      'spodnie',
      'dresy',
      'szorty',
    ],
  },
  kayaking: {
    category2: 'kajakarstwo',
    natureofproduct: ['kajak', 'pianka ochronna', 'pianka neoprenowa'],
  },
  strength_sports: {
    category2: 'siłownia',
    natureofproduct: [
      'bluza',
      'buty',
      'koszulka',
      'koszulka bez rękawów',
      'koszulka crop top',
      'legginsy',
      'skarpetki',
      'spodnie',
      'szorty',
    ],
  },
  roller_blading: {
    category2: 'rolki i wrotki',
    natureofproduct: [
      'kask',
      'ochraniacz nadgarstka',
      'plecak',
      'rolki',
      'skarpetki',
      'zestaw ochraniaczy',
    ],
  },
  golf: {
    category2: 'golf',
    natureofproduct: [
      'bluza',
      'buty do golfa',
      'polo z krótkim rękawem',
      'ręcznik',
      'skarpetki',
      'spodnie',
    ],
  },
  horse_riding: {
    category2: 'jeździectwo',
    natureofproduct: [
      'bluza',
      'bryczesy',
      'czaprak',
      'czapsy',
      'kamizelka',
      'kask',
      'koszula z długim rękawem',
      'legginsy',
      'ochraniacz na plecy',
      'ochraniacz na łopatki',
      'polar',
    ],
  },
  hockey: {
    category2: 'hokej',
    natureofproduct: ['łyżwy hokejowe', 'kask', 'kij', 'krążek', 'ręcznik', 'ochraniacz zębów'],
  },
  nordicwalking: {
    category2: 'nordic walking',
    natureofproduct: ['kijek do chodzenia', 'paski do kijków', 'pokrowiec na kije'],
  },
  yoga: {
    category2: 'joga',
    natureofproduct: [
      'bluza',
      'klocek do jogi',
      'koszulka',
      'legginsy',
      'mata do jogi',
      'poduszka',
      'skarpetki',
      'spodnie',
      'szorty',
    ],
  },
  triathlon: {
    category2: 'triathlon',
    natureofproduct: ['pianka neoprenowa', 'lemondka'],
  },
  tourism: {
    category2: 'turystyka, trekking',
    natureofproduct: [
      'czołówka',
      'zegarek',
      'plecak',
      'rakieta',
      'bluza',
      'spodnie',
      'latarka',
      'buty',
    ],
  },
  dancing: {
    category2: 'taniec, gimnastyka',
    natureofproduct: ['bluza', 'getry', 'koszulka', 'legginsy', 'szorty', 'torba'],
  },
  rugby: {
    category2: 'rugby',
    natureofproduct: [
      'kurtka',
      'legginsy',
      'bielizna ochronna',
      'buty do rugby',
      'kask do rugby',
      'piłka do rugby',
      'szorty',
      'skarpetki',
    ],
  },
  acrobatics: {
    category2: 'fitness, siłownia',
    natureofproduct: [
      'bluza',
      'koszulka',
      'koszulka bez rękawów',
      'legginsy',
      'skarpetki',
      'spodnie',
      'szorty',
    ],
  },
  frisbee: {
    category2: 'fitness, siłownia',
    natureofproduct: [
      'bluza',
      'koszulka',
      'koszulka bez rękawów',
      'koszulka crop top',
      'legginsy',
      'skarpetki',
      'spodnie',
      'szorty',
    ],
  },
  athletics: {
    category2: 'fitness, siłownia',
    natureofproduct: [
      'bluza',
      'buty',
      'koszulka',
      'koszulka bez rękawów',
      'koszulka crop top',
      'legginsy',
      'skarpetki',
      'spodnie',
      'szorty',
    ],
  },
  physiotherapy: {
    category2: 'zdrowie i regeneracja sportowca',
    natureofproduct: [
      'kijek do masażu',
      'opaska kompresyjna',
      'pistolet do masażu',
      'roller do masażu',
      'poduszka do masażu',
      'taśma kinezjologiczna',
      'waga',
    ],
  },
  dietetics: {
    category2: 'zdrowie i regeneracja sportowca',
    natureofproduct: [
      'kijek do masażu',
      'opaska kompresyjna',
      'pistolet do masażu',
      'roller do masażu',
      'poduszka do masażu',
      'taśma kinezjologiczna',
      'waga',
    ],
  },
  other: {
    category2: 'fitness, siłownia',
    natureofproduct: [
      'bluza',
      'koszulka',
      'koszulka bez rękawów',
      'legginsy',
      'skarpetki',
      'spodnie',
      'szorty',
    ],
  },
  skateboard: {
    category2: 'deskorolki, longboardy',
    natureofproduct: ['buty', 'czapka z daszkiem', 'kask', 'rękawice', 'zestaw ochraniaczy'],
  },
  water_sports: {
    category2: 'pływanie',
    natureofproduct: [
      'pianka neoprenowa',
      'okulary do pływania',
      'buty',
      'bokserki pływackie',
      'strój pływacki',
      'rękawice neoprenowe',
    ],
  },
  padel: {
    category2: 'padel',
    natureofproduct: [
      'rakieta do padla',
      'pokrowiec do przechowywania',
      'torba',
      'szorty',
      'koszulka',
    ],
  },
  winter_sports: {
    category2: 'narciarstwo',
    natureofproduct: [
      'buty',
      'bielizna ochronna',
      'kurtka pikowana',
      'kurtka puchowa',
      'legginsy termoaktywne',
      'odzież termoaktywna',
      'polar',
      'skarpetki',
    ],
  },
};

export const sportsDictionary = {
  'futbol amerykanski': 'Futbol amerykański',
  baseball: 'Baseball',
  koszykowka: 'Koszykówka',
  kolarstwo: 'Kolarstwo',
  wedkarstwo: 'Wędkarstwo',
  'sztuki walki': 'Sztuki walki',
  bieganie: 'Bieganie',
  squash: 'Squash',
  'tenis ziemny': 'Tenis ziemny',
  siatkowka: 'Siatkówka',
  'pilka reczna': 'Piłka ręczna',
  fitness: 'Fitness',
  plywanie: 'Pływanie',
  badminton: 'Badminton',
  'tenis stolowy': 'Tenis stołowy',
  wspinaczka: 'Wspinaczka',
  'pilka nozna': 'Piłka nożna',
  kajakarstwo: 'Kajakarstwo',
  'sporty silowe': 'Sporty siłowe',
  rolki: 'Rolki',
  golf: 'Golf',
  jezdziectwo: 'Jeździectwo',
  hokej: 'Hokej',
  'nordic walking': 'Nordic walking',
  joga: 'Joga',
  triathlon: 'Triathlon',
  dart: 'Dart',
  turystyka: 'Turystyka',
  'sport festival': 'Sport Festival',
  taniec: 'Taniec',
  rugby: 'Rugby',
  akrobatyka: 'Akrobatyka',
  frisbee: 'Frisbee',
  lekkoatletyka: 'Lekkoatletyka',
  'bieganie przeszkodowe': 'Bieganie przeszkodowe',
  fizjoterapia: 'Fizjoterapia',
  dietetyka: 'Dietetyka',
  'sprzatanie swiata': 'Sprzątanie świata',
  deskorolka: 'Deskorolka',
  'sporty wodne': 'Sporty wodne',
  padel: 'Padel',
  'sporty zimowe': 'Sporty zimowe',
  kabr: 'KaBeR',
  canicross: 'Canicross',
  bikejoring: 'Bikejoring',
  mobility: 'Mobility',
  'rower szosowy': 'Rower szosowy',
  enduro: 'Enduro',
};
