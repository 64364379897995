import css from './CalculatorPage.css';

const result = {
  default: {
    css: css.default,
    header: '',
    text: ''
  },
  underfat: {
    css: css.underfat,
    header: 'Niedowaga',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  healthy: {
    css: css.healthy,
    header: 'Zdrowy poziom tkanki tłuszczowej',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  overfat: {
    css: css.overfat,
    header: 'Nadmierne otłuszczenie',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  },
  obese: {
    css: css.obese,
    header: 'Otyłość',
    text: 'Jesteś na skraju wygłodzenia. Skonsultuj się z lekarzem, gdyż ten stan jest niebezpieczny dla zdrowia'
  }
}

export default function rfmHelper(rfm, age, sex) {
  if (!rfm || !age || !sex) return result.default;

  if (sex === 'female') {
    if (age <= 39) {
      if (rfm < 21) return result.underfat;
      if (rfm >= 21 && rfm < 33) return result.healthy;
      if (rfm >= 33 && rfm < 39)return result.overfat;
      if (rfm >= 39) return result.obese;
    }
    if (age > 39 && age <= 59) {
      if (rfm < 23) return result.underfat;
      if (rfm >= 23 && rfm < 35) return result.healthy;
      if (rfm >= 35 && rfm < 40)return result.overfat;
      if (rfm >= 40) return result.obese;
    }
    if (age > 59) {
      if (rfm < 24) return result.underfat;
      if (rfm >= 24 && rfm < 36) return result.healthy;
      if (rfm >= 36 && rfm < 42)return result.overfat;
      if (rfm >= 42) return result.obese;
    }
  }

  if(sex === 'male') {
    if (age <= 39) {
      if (rfm < 8) return result.underfat;
      if (rfm >= 8 && rfm < 19) return result.healthy;
      if (rfm >= 19 && rfm < 25)return result.overfat;
      if (rfm >= 25) return result.obese;
    }
    if (age > 39 && age <= 59) {
      if (rfm < 11) return result.underfat;
      if (rfm >= 11 && rfm < 22) return result.healthy;
      if (rfm >= 22 && rfm < 28)return result.overfat;
      if (rfm >= 28) return result.obese;
    }
    if (age > 59) {
      if (rfm < 13) return result.underfat;
      if (rfm >= 13 && rfm < 25) return result.healthy;
      if (rfm >= 25 && rfm < 30)return result.overfat;
      if (rfm >= 30) return result.obese;
    }
  }
}

