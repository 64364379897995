import React, { useState, useEffect } from "react";
import BackIcon from '../ManageListingsPage/BackIcon';
import MessageItem from './MessageItem'
import css from './InboxPage.css';
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
import { useQuery } from "react-query";
import { apiSdk } from "../../queries/api";
import { PaginationLinks } from "../../components";

const MessagesView = ({ currentUser }) => {
    const history = useHistory()

    const { search } = useLocation()

    const searchParams = new URLSearchParams(search)
    const page = Number(searchParams.get('page')) || 1
    const [totalPages, setTotalPages] = useState(1)

    const per_page = 10


    const [filteredMessages, setFilteredMessages] = useState([])

    const [selectValue, setSelectValue] = useState('all')

    const { data: messages, isSuccess: isSuccessMessages } = useQuery(
        ['messages.query', , page, per_page],
        () => apiSdk.chat.queryAllMessages({
            userId: 'me',
            sort: '-lastPostedAt',
            include: ['sender', 'chat.transaction', 'chat.listing'],
            page,
            per_page
        }),
        {
            enabled: !!currentUser && !!currentUser.data.id.uuid,
            keepPreviousData: false,
            staleTime: 0,
        }
    );

    const handleSelectChange = (event) => {
        let isRead = null
        switch (event.currentTarget.value) {
            case 'all':
                isRead = 'all'
                break;
            case 'read':
                isRead = true
                break
            case 'unread':
                isRead = false
                break
        }
        setSelectValue(event.currentTarget.value)
        setFilteredMessages(prev => {
            // let result = []
            // prev.forEach(items => {
            //     const result2 = isRead === 'all' ? items : items.filter(item => {
            //         console.log('item', item)
            //         return item.isRead === isRead
            //     })
            //     result = [...result, result2]
            // })
            // return result
        })

    }

    useEffect(() => {
        if (isSuccessMessages) {
            setTotalPages(messages.meta.totalPages)
        }
    }, [isSuccessMessages])

    useEffect(() => {
        const messagesWithExtraData = isSuccessMessages && messages.data.map(message => {
            const senderData = messages.included.filter(item => item.type === 'user' && item.id.uuid === message.relationships.sender.data.id.uuid)[0]
            const chatId = message.relationships.chat.data.id.uuid
            const chatData = messages.included.filter(item => item.type === 'chat' && item.id.uuid === chatId)[0]
            const transactionId = chatData.relationships.transaction.data ? chatData.relationships.transaction.data.id.uuid : ''
            const transactionData = messages.included.filter(item => item.type === 'transaction' && item.id.uuid === transactionId)[0]
            const listingId = chatData.relationships.listing.data.id.uuid
            const listingData = messages.included.filter(item => item.type === 'listing' && item.id.uuid === listingId)[0]

            return ({
                ...message,
                senderData,
                transactionData,
                listingData,
                chatData,
                chatId
            })
        })
        if (messages) setFilteredMessages(messagesWithExtraData)
    }, [isSuccessMessages])


    const pagination = {
        page,
        totalPages,
    }


    const pagingLinks =
        isSuccessMessages && messages.data.length > 0 && pagination && pagination.totalPages > 1 ? (
            <PaginationLinks
                className={css.pagination}
                pageName="InboxPage"
                pagePathParams={{ tab: 'messages' }}
                pagination={pagination}
            />
        ) : null;

    return <div>
        {/* <div className={css.form}>
            <label className={css.filter}>
                Sortuj wiadomości
                <select value={selectValue} onChange={handleSelectChange}>
                    <option value={'all'}>Wszystkie</option>
                    <option value={'unread'} >Nieodczytane</option>
                    <option value={'read'}>Odczytane</option>
                </select>
            </label>
        </div> */}
        {isSuccessMessages && currentUser && filteredMessages.flat()
            .sort((a, b) => b.createdAt - a.createdAt)
            .map(item => <MessageItem
                currentUser={currentUser.data}
                message={item}
            />)}
        {pagingLinks}
    </div>

}

export default MessagesView