import React from 'react';
import PropTypes from 'prop-types';

const IconSignup = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2A374A"
        fillRule="nonzero"
        stroke="none"
        d="M8 9.333a3.333 3.333 0 0 1 3.333 3.334V14A.667.667 0 0 1 10 14v-1.333a2 2 0 0 0-2-2H3.333a2 2 0 0 0-2 2V14A.667.667 0 1 1 0 14v-1.333a3.333 3.333 0 0 1 3.333-3.334H8zm5.333-4.666c.369 0 .667.298.667.666v1.334h1.333a.667.667 0 0 1 0 1.333H14v1.333a.667.667 0 0 1-1.333 0V8h-1.334a.667.667 0 1 1 0-1.333h1.334V5.333c0-.368.298-.666.666-.666zM5.667 1.333a3.333 3.333 0 1 1 0 6.667 3.333 3.333 0 0 1 0-6.667zm0 1.334a2 2 0 1 0 0 4 2 2 0 0 0 0-4z"
      />
    </svg>
  );
};

IconSignup.defaultProps = { className: null };

const { string } = PropTypes;

IconSignup.propTypes = {
  className: string,
};

export default IconSignup;
