import React from 'react';
import { sports } from './sportsData';
import css from './SportLocationPage.css';
import { types as sdkTypes } from '../../util/sdkLoader';
import HeaderImg from './header.png';
const { LatLng, LatLngBounds } = sdkTypes;

function computeSportTiles(sportsArray, city) {
  const tiles = sportsArray.map((sport) => {
    return {
      name: sports[sport].name,
      src: sports[sport].src,
      srcSet: sports[sport].srcSet,
      searchUrl: sports[sport].searchUrl + city,
    };
  });
  return tiles;
}

export const sportLocationLandings = [
  {
    url: '/wydarzenia/siatkowka-warszawa',
    name: 'VolleyballWarsawLanding',
    title: 'Treningi siatkówki Warszawa – dla dzieci i dorosłych',
    header: 'Siatkówka w Warszawie – gdzie obejrzeć mecz i grać amatorsko?',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Trening siatkówki w Warszawie to sportowa przygoda! Graj w drużynie, bierz udział w zaawansowanych zajęciach i znajdź trening z Decathlon Go.',
    lead:
      'Siatkówka to wyjątkowo emocjonujący rodzaj aktywności! Z Decathlon Go znajdziesz najlepsze dla siebie miejsce do trenowania oraz śledzenia sportowych emocji z trybun. Zajęcia indywidualne, gra drużynowa czy towarzyska – Ty wybierasz!',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/volleyball.jpg',
    sports: computeSportTiles(
      ['basketball', 'football', 'fitness', 'running', 'tabletennis'],
      'warszawa'
    ),
    articles: [
      {
        title: 'Treningi siatkówki Warszawa – od amatorskiej gry do profesjonalnego klubu',
        text: [
          'Rywalizacja, przekraczanie własnych granic i rozwój kondycji sprawiają, że siatkówka to popularny sport zarówno wśród dorosłych, jak i dzieci. W zależności od poziomu twoich umiejętności możesz zapisać się na trening na poziomie podstawowym, średniozaawansowanym lub zaawansowanym dla dorosłych. Jeśli chcesz, możesz grać w drużynie lub rozpocząć od zajęć indywidualnych z trenerem. W każdej chwili możesz zmienić rodzaj wybranej przez ciebie aktywności lub częstotliwość treningów – nie jesteś związany umową członkostwa. Zapłacisz jedynie za te zajęcia, w których weźmiesz udział.Siatkówka to doskonała okazja do pracy nad ogólną wytrzymałością i kondycją organizmu. Podczas gry rozwiniesz koordynację, refleks i umiejętność pracy zespołowej. Po długim dniu pracy spędzonym za biurkiem taka forma aktywności bardzo dobrze się sprawdzi i pomoże w utrzymaniu sprawności. ',
          <span >
            <a style={{ fontSize: '16px' }} href="https://go.decathlon.pl/b/zajecia-siatkowki/">Zajęcia z siatkówki</a>,
          </span>,
          ' są bardzo popularne, nie tylko w Warszawie. Znajdź formę i rodzaj treningu, który najbardziej będzie ci odpowiadać. Możesz skorzystać z rad doświadczonych trenerów, którzy wskażą najlepsze ścieżki rozwoju.',
        ],
      },
      {
        title: 'Zajęcia z siatkówki dla najmłodszych – jak znaleźć najlepsze miejsce?',
        text: [
          'Sport w życiu dzieci i młodzieży odgrywa szczególną rolę. Nie tylko wspomaga prawidłowy rozwój, ale również wspiera w wypracowaniu niezbędnych cech charakteru. Już od najmłodszych lat warto zachęcać pociechę do pozostawania w ciągłym ruchu! Na Decathlon Go znajdziesz wiele propozycji zajęć z siatkówki organizowanych na terenie Warszawy oraz innych miast. Zapoznaj się z harmonogramem ćwiczeń i wybierz zajęcia prowadzone przez profesjonalnych trenerów i miłośników siatkówki. W łatwy i szybki sposób, bez zbędnych formalności, zapiszesz swoje dziecko na wybrany trening. Odbywają się w różnych dzielnicach, dzięki czemu dopasowanie napiętego grafiku będzie łatwiejsze. Zajęcia z siatkówki będą również bardzo dobrym rozwiązaniem dla młodzieży. Nastolatkowie lubią odkrywać swoje pasje, zawierać nowe znajomości i rywalizować. To wszystko zapewni im gra w siatkówkę, która również pozytywnie wpłynie na ogólny rozwój młodych ludzi. Swoją pasję możesz rozwijać na wiele sposobów! Z Decathlon Go znajdziesz miejsca na terenie Warszawy i innych miast, gdzie będziesz mógł wybrać się na mecz. Przeżyj sportowe emocje wspólnie z bliską ci osobą i bądź świadkiem niezwykłych akcji na boisku! Mecze odbywają się głównie na halach, a listę zaplanowanych wydarzeń sportowych znajduje się na naszej platformie. By zapisać się na wybrany mecz, należy opłacić bilet za pośrednictwem strony. Warto jednak na bieżąco śledzić Decathlon Go, ponieważ dużo wydarzeń jest bezpłatnych.',
        ],
      },
    ],
    searchParams: {
      sport: 'volleyball',
      bounds: new LatLngBounds(
        new LatLng(52.30094281, 21.10950476),
        new LatLng(52.10614075, 20.89015052)
      ),
    },
  },
  {
    url: '/wydarzenia/koszykowka-warszawa',
    name: 'BasketballWarsawLanding',
    title: 'Trening koszykówki Warszawa – gdzie trenować?',
    header:
      'Treningi koszykówki Warszawa ',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Szukasz treningu koszykówki w Warszawie? Znajdź najlepszego trenera i rozpocznij zajęcia jeszcze dziś!',
    lead:
      'Chcesz poćwiczyć refleks, lubisz rywalizację i bycie w ciągłym ruchu? Koszykówka to wyjątkowy rodzaj sportu, który wspiera kondycję całego organizmu. Z Decathlon Go znajdziesz najlepsze miejsce na trening i przeżycie sportowych emocji z trybun!',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/basketball.jpg',
    sports: computeSportTiles(
      ['volleyball', 'football', 'fitness', 'running', 'tabletennis'],
      'warszawa'
    ),
    articles: [
      {
        title: 'Znajdź idealne miejsce na trening dla koszykarzy w Warszawie.',
        text: [
'Koszykówka to coraz popularniejszy sport, który rodzi mnóstwo emocji! To świetna propozycja nie tylko dla dorosłych, ale też dzieci i młodzieży. Możesz trenować na dowolnym poziomie i ciągle doskonalić swoje umiejętności koszykarskie. Skorzystaj z organizowanych zajęć i zdobądź kilka punktów dla swojej drużyny! Przygodę z koszykówką możesz rozpocząć w każdym momencie. Ten rodzaj dyscypliny rozwija koordynację, kondycję oraz wytrzymałość całego organizmu. Podczas gry pracują najbardziej nie tylko mięśnie ud i pośladków, ale również ramion i pleców. Na Decathlon Go znajdziesz całą listę dostępnych treningów – na terenie Warszawy i innych miast. Koszykówka to sport całoroczny, który wymaga zaangażowania. Gra czy zajęcia mogą odbywać się w sezonie letnim na terenie boiska na świeżym boisku. W okresie zimowym jest możliwość treningu na wynajętej przez klienta sali na terenie Warszawy, czy obiekcie, na którym odbywają się zajęcia zorganizowane. Możesz skorzystać z doświadczenia trenera koszykówki i szkolić się pod jego okiem.',
        ],
      },
      {
        title: 'Indywidualne i grupowe treningi z koszykówki',
        text: [
'Koszykówka to nie tylko nauka rzutów piłki do kosza. Zawodników łączy niesamowita więź, tworzą społeczność i grają jako jedna drużyna, by razem osiągać zamierzone cele. Jeśli jednak nie zamierzasz dołączać do klubu sportowego czy zapisać się do drużyny, możesz skorzystać z indywidualnych zajęć z koszykówki. Trener sportowy krok po kroku wskaże ci, w jaki sposób możesz doskonalić swoją technikę. To dobra propozycja zarówno dla początkujących, jak i zaawansowanych graczy, którzy chcą być w jak najlepszej formie. Podczas treningu możesz korzystać z dostępnego sprzętu sportowego. W łatwy sposób poznasz również rekomendacje dotyczące wyboru trenera czy klubu sportowego, które pochodzą bezpośrednio od uczniów. Na Decathlon Go znajdziesz informacje na temat naborów na profesjonalne zajęcia grupowe prowadzone przez wykwalifikowaną kadrę. Na zajęcia warto zabrać ze sobą wygodny strój do ćwiczeń oraz buty do koszykówki, usztywniające kostki, by zmniejszyć ryzyko kontuzji podczas gry. ',
        ],
      },
      {
        title: 'Bądź świadkiem sportowych emocji!',
        text: [
'Poczuj wyjątkową atmosferę podczas meczu koszykówki i przeżyj niezapomniane sportowe emocje! Na Decathlon Go znajdziesz informacje o zbliżających się rozgrywkach i meczach między drużynami na światowym poziomie. Ta wyjątkowa gra zespołowa potrafi trzymać w napięciu do ostatniego rzutu piłki do kosza!Podczas meczów będziesz miał okazję poznać trenerów czy pasjonatów gry, od lat związanych ze światem koszykówki. Możesz przyjść sam lub zabrać bliską osobę. Bilety na mecz możesz kupić przez Decathlon Go – to wygodne rozwiązanie, bez zbędnych formalności. ',
        ],
      },
    ],
    searchParams: {
      sport: 'basketball',
      bounds: new LatLngBounds(
        new LatLng(52.30094281, 21.10950476),
        new LatLng(52.10614075, 20.89015052)
      ),
    },
  },
  {
    url: '/wydarzenia/fitness-warszawa',
    name: 'FitnessWarsawLanding',
    title: 'Fitness Warszawa – trenuj w klubie fitness i na siłowni',
    header: 'Fitness Warszawa',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Odkryj świat fitnessu w Warszawie. Dołącz do naszego klubu i trenuj na siłowni z szerokim wyborem zajęć. Znajdź swoją aktywność fizyczną!',
    lead:
      'Ćwiczenia fitness to nie tylko świetny sposób na prowadzenie zdrowego trybu życia i poznawanie nowych osób, ale również zróżnicowana forma treningów. Na Decathlon Go znajdziesz najlepszy dla siebie rodzaj zajęć – od crossfit i HIIT po basen i pilates.',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/fitness.jpg',
    sports: computeSportTiles(
      ['basketball', 'football', 'volleyball', 'running', 'tabletennis'],
      'warszawa'
    ),
    articles: [
      {
        title: 'Wybierz najlepszy dla siebie zajęcia fitness',
        text: [
'Regularne treningi to klucz do sukcesu i sposób na osiągnięcie zamierzonych celów. Warto przeplatać różne rodzaje aktywności. W ten sposób nie tylko unikniemy monotonii, ale również damy organizmowi zróżnicowany rodzaj wysiłku. Ćwiczenia fitness to popularny rodzaj aktywności, zwłaszcza wśród kobiet. Treningi możesz dopasować do swoich potrzeb oraz tego, w jaki sposób pragniesz kształtować sylwetkę. Możesz zdecydować się na intensywne zajęcia cardio z elementami treningu siłowego czy crossfit. Popularne są również zajęcia z jogi i pilatesu, które nie tylko obniżają poziom stresu, ale wpływają na poprawę elastyczności i jędrności całego ciała. Ciekawym rozwiązaniem jest również dance cardio, czyli ćwiczenia na bazie kroków z tańca, które poprawiają kondycję oraz wytrzymałość. Aktywność fizyczna powinna sprawiać radość i przynosić wiele satysfakcji, nawet jeśli od czasu do czasu nie będziemy mieli ochoty na trening. Ważne, by dobrać odpowiednio ćwiczenia i program treningowy, co pozwoli na uzyskanie jak najlepszych rezultatów. ',
        ],
      },
      {
        title: 'Zajęcia fitness w Warszawie – gdzie szukać? ',
        text: [
'Na Decathlon Go znajdziesz szeroki wybór zajęć fitness – od treningów aerobowych, po treningi funkcjonalne z wykorzystaniem nowoczesnego sprzętu. W zależności od wybranego rodzaju aktywności, zajęcia odbywają się na sali lub siłowni. Wybierz zajęcia fitness w Warszawie dostosowane do twoich potrzeb i oczekiwań. Być może zależy ci na większym spalaniu tkanki tłuszczowej lub budowaniu masy mięśniowej. Warto pamiętać, że podczas wykonywania cardio czy ćwiczeń ze sprzętem siłowym prawidłowa technika ma szczególne znaczenie i często decyduje o osiągniętych rezultatach. Jeśli chcesz poznać właściwe sposoby na wykonywanie ćwiczeń, możesz zapisać się na zajęcia grupowe prowadzone przez instruktorów lub indywidualne z trenerem. Niezależnie od tego czy ćwiczysz na maszynach, czy do treningu potrzebujesz jedynie maty do ćwiczeń – najważniejsza jest przyjemność, jaką daje sam ruch. Wybierz najlepszy dla siebie rodzaj aktywności i zacznij przekraczać granice własnych możliwości! ',
        ],
      },
      {
        title: 'Dołącz do klubu fitness i pozostań aktywny! ',
        text: [
'Trening fitness nie tylko modeluje sylwetkę i pozwala spalić kalorie, ale również obniża poziom stresu, daje wyrzut endorfin i poprawia nastrój. Ćwiczenia to dobry sposób, by zrelaksować się po długim dniu spędzonym za biurkiem i poprawić jakość snu oraz poziom koncentracji w ciągu dnia. Z Decathlon Go możesz poznawać różne rodzaje aktywności! Warszawskie zajęcia grupowe fitness wprowadzą Cię w świat sportu i aktywności fizycznej. Mogą również pomóc w zrzuceniu zbędnych kilogramów czy utrzymaniu aktualnej sylwetki. Zapłacisz jedynie za te zajęcia, w których weźmiesz udział, dlatego możesz korzystać ze zróżnicowanych treningów i to bez umowy członkostwa, czy zbędnych formalności. ',
        ],
      },
    ],
    searchParams: {
      sport: 'fitness',
      bounds: new LatLngBounds(
        new LatLng(52.30094281, 21.10950476),
        new LatLng(52.10614075, 20.89015052)
      ),
    },
  },
  {
    url: '/wydarzenia/pilka-nozna-warszawa',
    name: 'FootballWarsawLanding',
    title: 'Trening piłki nożnej w Warszawie',
    header: 'Treningi piłki nożnej w Warszawie',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Zapraszamy na trening piłki nożnej w Warszawie. Wybierz zajęcia z wykwalifikowanym trenerem i popraw swoją grę już teraz! ⚽',
    lead:
      'Piłka nożna to niezwykle popularny rodzaj aktywności. Trenowana przez dorosłych, dzieci i młodzież dostarcza sportowych emocji, zdrową rywalizację i radość z ruchu. Z Decathlon Go znajdziesz najlepsze dla siebie miejsce na trening i przeżyjesz niezapomniany czas na boisku!',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/football.jpg',
    sports: computeSportTiles(
      ['basketball', 'volleyball', 'fitness', 'running', 'tabletennis'],
      'warszawa'
    ),
    articles: [
      {
        title: 'Piłka nożna Warszawa – jak znaleźć najlepsze miejsce do trenowania?',
        text: [
          'Regularny trening daje wiele korzyści. Nie musisz należeć do drużyny piłkarskiej czy klubu sportowego, by doskonalić swoje umiejętności na boisku! Niezależnie od tego czy trenujesz od najmłodszych lat, czy dopiero rozpoczynasz swoją przygodę z tym sportem, możesz trenować piłkę nożną w każdym momencie! Z Decathlon Go znajdziesz najlepsze dla siebie miejsce do treningów piłkarskich. Wybór jest naprawdę duży! Zajęcia odbywają się w różnych dzielnicach Warszawy, dzięki czemu wybór najdogodniejszej lokalizacji nie będzie problemem. Treningi są na różnym poziomie zaawansowania, a trenerzy piłki nożnej podzielą się swoim doświadczeniem oraz wprowadzą się w świat zasad tej gry. Piłka nożna to dyscyplina dla ambitnych osób, ale również takich, którzy chcą poprawić kondycję i ogólną wytrzymałość organizmu. Jeśli chcesz, możesz zapisać się do drużyny piłkarskiej prowadzonej przez trenerów czy pasjonatów piłki nożnej. Dzięki rekomendacji, które pochodzą bezpośrednio od uczniów, wybór drużyny dla dzieci i młodzieży będzie ułatwiony.',
        ],
      },
      {
        title: 'Sportowe zajęcia piłki nożnej z trenerem w Warszawie',
        text: [
'Choć piłka nożna to sport drużynowy, indywidualne zajęcia z trenerem mogą pomóc w osiągnięciu lepszych wyników. W każdym wieku piłka nożna to pewne wyzwanie dla organizmu. Podczas gry intensywnie pracują mięśnie całego ciała, w tym pośladków i ud. Refleks, koordynacja oraz umiejętność prowadzenia piłki mają szczególne znaczenie nie tylko podczas meczów. Zajęcia poprowadzone przez profesjonalistów pozwolą ci i twojej drużynie razem osiągnąć zamierzone cele. Na Decathlon Go sprawdzisz listę dostępnych treningów na poziomie podstawowym i zaawansowanym, w tym zajęcia piłki nożnej Warszawa dla dzieci. W zależności od rodzaju wybranej przez ciebie aktywności, podczas ćwiczeń będziesz mógł korzystać z dostępnego sprzętu. Wystarczy więc wygodny strój do ćwiczeń i odpowiedni rodzaj obuwia. Jeśli zajęcia będą odbywać się na hali, przydadzą się sportowe buty, jeśli na boisku, niezbędne będą korki. ',   
        ],
      },
       {
        title: 'Gra w piłkę nożną – aktywność i sposób na wolny czas ',
        text: [
'Gra w piłkę nożną szybko rozprzestrzeniła się na całym świecie i należy do najbardziej popularnych dyscyplin. Nic więc dziwnego, że o grze marzą nie tylko chłopcy i dziewczynki, ale również dorośli. Ten rodzaj aktywności pozwala na obniżenie poziomu stresu, wyrzut endorfin, poprawę nastroju i wzrost ogólnej wydolności organizmu. Co ma znaczenie zwłaszcza przy prowadzeniu głównie siedzącego trybu życia. Na regularne treningi możesz chodzić razem z przyjaciółmi, wspólnie przeżywać sportowe emocje i razem osiągać zamierzone cele! Bez obaw, zapłacisz jedynie za te treningi, w których weźmiesz udział. Brak zbędnych formalności i umów członkowskich sprawia, że możesz po prostu cieszyć się wybranym rodzajem aktywności i skupić się na osiąganiu zamierzonych celów! ',
      ],
      },
    ],
    searchParams: {
      sport: 'football',
      bounds: new LatLngBounds(
        new LatLng(52.30094281, 21.10950476),
        new LatLng(52.10614075, 20.89015052)
      ),
    },
  },
  {
    url: '/wydarzenia/bieganie-warszawa',
    name: 'RunningWarsawLanding',
    title: 'Treningi biegowe w Warszawie. Zacznij biegać!',
    header: 'Grupy biegowe Warszawa',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Chcesz biegać? W Warszawie nie brakuje profesjonalnych treningów biegowych. Zapisz się na bezpłatne zajęcia biegowe i dołącz do klubu biegowego.',
    lead:
      'Aktywny tryb życia to jeden z przepisów na sukces. Buty do biegania i sportowy strój – tylko tyle dzieli cię od rozpoczęcia przygody ze światem biegania z Decathlon Go! Znajdź najlepszy dla siebie rodzaj treningu i przygotuj się na sportowe emocje.',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/running.jpg',
    sports: computeSportTiles(
      ['basketball', 'volleyball', 'fitness', 'football', 'tabletennis'],
      'warszawa'
    ),
    articles: [
      {
        title: 'Treningi biegowe w Warszawie ',
        text: [
          'Bieganie to wyjątkowy rodzaj aktywności. Nie tylko pozwala na spalenie wysokiej liczby kalorii, ale również poprawia ogólną kondycję i wydolność organizmu. Wyrzut endorfin, który towarzyszy tej aktywności sprawia, że obniża się poziom stresu i poprawia się nastrój. Z Decathlon Go możesz dołączyć do świata biegania i pod okiem doświadczonych trenerów przekraczać granice własnych możliwości. Możesz trenować indywidualnie lub dołączyć do klubu biegowego. Niezależnie od tego, czy właśnie przygotowujesz się do maratonu, biegu na 10 km, czy po prostu chcesz podnieść swoją sprawność – regularne treningi pomogą w osiągnięciu założonego celu. Czasem trudno znaleźć motywację do pójścia na indywidualny trening. Dlatego sprawdzą się zajęcia biegowe, podczas których możesz poznać innych miłośników tej aktywności, którzy już rozpoczęli swoją przygodę z bieganiem i nie zamierzają z niej zrezygnować! ',
        ],
      },
      {
        title: 'Jak znaleźć miejsce do trenowania dla zaawansowanych i początkujących biegaczy?',
        text: [
          'Jeśli rozważasz rozpoczęcie wspólnych treningów z innymi biegaczami, możesz dołączyć do grupy i wziąć udział w organizowanych aktywnościach. Wysiłek fizyczny to świetny sposób, by rozładować stres i przy okazji poznać nowych ludzi. Stałe treningi wpływają na sprawność ogólną i pozwolą ci na łatwiejsze osiąganie celów w innych dziedzinach życia. Na Decathlon Go znajdziesz między innymi grupy biegowe w Warszawie, zawody biegowe (na przykład zbiórki na rzecz fundacji niosących pomoc), czy kluby sportowe, które oferują profesjonalne treningi na terenie stadionu. Znajdziesz zajęcia dla zaawansowanych i początkujących biegaczy na terenie całego kraju. Wystarczy zarezerwować na Decathlon Go przykładowo udział w biegu treningowym. Organizator otrzyma powiadomienie o twoim zapisie, a ty bez zbędnych formalności i umów członkowskich będziesz mógł zacząć przygodę z bieganiem lub trenować do pobicia własnych rekordów. Możesz zapoznać się z harmonogramem zajęć dla biegaczy o różnym poziomie zaawansowania. Cyklicznie odbywają się bezpłatne treningi pod okiem fachowych trenerów oraz inne inicjatywy, nie tylko dla zaawansowanych biegaczy, ale i członków klubu. ',
        ],
      },
            {
        title: 'Dołącz do bezpłatnych zajęć biegowych',
        text: [
'Dołącz do osób, które wspólnie trenują i prowadzą aktywny tryb życia. Niezależnie od tego, czy głównie korzystasz z bieżni, biegasz w terenie, czy chcesz wystartować w klubowych zawodach – dzięki urozmaiconym treningom jesteś w stanie uzyskać jeszcze lepsze efekty! Na Decathlon Go znajdziesz wiele propozycji zajęć, które pozwolą ci się wybiegać! Od sprintu, biegu z przeszkodami czy na trudnym terenie po biegi długodystansowe. Zajęcia są bezpłatne i pozwolą na rozwój, a także zawarcie nowych znajomości. Spotkania biegowe odbywają się na terenie różnych dzielnic w Warszawie, dlatego znalezienie dogodnej lokalizacji nie powinno być problemem. Możesz przyjść na trening z przyjaciółką, partnerem, czy swoim dzieckiem (pod warunkiem ukończenia 12 roku życia).',     
        ],
      },
    ],
    searchParams: {
      sport: 'running',
      bounds: new LatLngBounds(
        new LatLng(52.30094281, 21.10950476),
        new LatLng(52.10614075, 20.89015052)
      ),
    },
  },
  {
    url: '/wydarzenia/tenis-stolowy-warszawa',
    name: 'TableTennisWarsawLanding',
    title: 'Tenis stołowy Warszawa – gdzie wybrać się na trening?',
    header: 'Tenis stołowy Warszawa',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Gdzie znajdziesz najlepsze miejsca do treningu tenisa stołowego w Warszawie? Sprawdź naszą listę i rozpocznij swoją przygodę z tenisem!',
    lead:
      'Tenis stołowy potrafi dostarczyć wielu sportowych emocji! Od treningów indywidualnych po turnieje między klubami sportowymi. Z Decathlon Go znajdziesz najlepsze dla siebie miejsce na trening i rozwijanie sportowej pasji!',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/tabletennis.jpg',
    sports: computeSportTiles(
      ['basketball', 'football', 'volleyball', 'running', 'fitness'],
      'warszawa'
    ),
    articles: [
      {
        title: 'Jak znaleźć miejsce na trening w Warszawie?',
        text: [
          'Gra w tenisa stołowego to świetna propozycja dla dzieci i dorosłych, którzy lubią rywalizację, dynamikę gry oraz pokonywanie własnych granic. Ten rodzaj aktywności to coś więcej niż samo odbijanie piłeczki rakietką. Profesjonalni gracze na zawodach i rozgrywkach ligowych prezentują niezwykle wysoki poziom gry, co wymaga od nich sporej dyscypliny i samozaparcia. Ten rodzaj sportu pozwala na ćwiczenie refleksu i szybkości reakcji, koordynacji całego ciała, a oprócz tego daje mnóstwo satysfakcji! Przygodę z tenisem stołowym można rozpocząć w każdym wieku. To od ciebie zależy, czy będziesz chciał trenować pod okiem profesjonalnego trenera, uczęszczać na zajęcia indywidualne i grupowe, czy po prostu mile spędzić czas na niezobowiązującej grze towarzyskiej. Na Decathlon Go znajdziesz listę dostępnych treningów z gry w tenisa stołowego. Zawodnicy mogą zgłosić się na każdym poziomie zaawansowania. Jeśli chcesz podnieść poziom swojej gry i doskonalić technikę, zajęcia pod okiem trenera tenisa stołowego mogą okazać się dobrym rozwiązaniem.',
        ],
      },
      {
        title: 'Zajęcia z tenisa stołowego dla początkujących i zaawansowanych graczy',
        text: [
         'By rozpocząć wymarzony trening, nie potrzebujesz specjalistycznego sprzętu. Wystarczy sala ze stołem do tenisa i wygodny strój do ćwiczeń. Możesz wynająć obiekt lub wziąć udział w indywidualnych, bądź grupowych zajęciach sportowych. Gra w tenisa stołowego pozwala na ciągły rozwój. Najlepsi zawodnicy mają szansę wzięcia udziału w mistrzostwach Polski oraz organizowanych rozgrywkach ligowych. Istnieje możliwość, by dołączyć do klubu sportowego, czy sekcji tenisa stołowego, co jest szczególnie popularne wśród młodzieży.',
        ],
      },
      {
        title: 'Tenis stołowy w Warszawie – zajęcia indywidualne z trenerem',
        text: [
         'Tenis stołowy stał się popularny już w XVII w. w Chinach i od tego czasu to jedna z najpopularniejszych dyscyplin sportowych, która przyciąga coraz więcej osób. Ten rodzaj sportu może być uprawiany w wielu kategoriach wiekowych, z czego często korzystają uczniowie podczas zajęć z wychowania fizycznego w szkole. Jeśli potrzebujesz odrobiny urozmaicenia, chcesz spróbować czegoś nowego lub doskonalić technikę gry, zajęcia z trenerem mogą okazać się dobrym rozwiązaniem. Często wystarczy tylko kilka godzin ćwiczeń pod okiem profesjonalisty, by znacząco podnieść swoje umiejętności i poziom gry. Może się to przełożyć nie tylko na wygrywanie kolejnych setów, ale również satysfakcję z gry. Na Decathlon Go znajdziesz szerokie wybór treningów z tenisa stołowego. Zapłacisz jedynie za te zajęcia, w których weźmiesz udział. Zapoznaj się z dostępnym na stronie harmonogramem i znajdź najlepszy dla siebie rodzaj ćwiczeń! Bez zbędnych formalności, czy umów członkostwa – odkrywaj swoje nowe sportowe pasje i czerp przyjemność z aktywności fizycznej!',
        ],
      },
    ],
    searchParams: {
      sport: 'tabletennis',
      bounds: new LatLngBounds(
        new LatLng(52.30094281, 21.10950476),
        new LatLng(52.10614075, 20.89015052)
      ),
    },
  },
  {
    url: '/wydarzenia/badminton-warszawa',
    name: 'BadmintonWarsawLanding',
    title: 'Badminton Warszawa – nauka, treningi i zajęcia na korcie',
    header: 'Badminton Warszawa',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Odkryj najlepsze miejsca do nauki badmintona w Warszawie! Spróbuj swoich umiejętności w wygranym klubie – spotkaj się ze znajomymi i trenerami.',
    lead:
      'Badminton to dyscyplina nie tylko dla dorosłych, ale również dzieci i młodzieży. Znajdź idealne miejsce do trenowania z Decathlon Go i spędź wyjątkowe chwile na korcie!',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/badminton.jpg',
    sports: computeSportTiles(
      ['basketball', 'football', 'tabletennis', 'running', 'fitness'],
      'warszawa'
    ),
    articles: [
      {
        title: 'Trening badmintona w Warszawie – jak znaleźć najlepsze miejsce na ćwiczenia?',
        text: [
          'Gra w badmintona to świetny sposób na połączenie aktywności fizycznej z dobrą zabawą! Na kortach do badmintona w Warszawie możesz wziąć udział w zajęciach sportowych, czy indywidualnych treningach pod okiem profesjonalnych trenerów. Ten rodzaj dyscypliny pozwoli nie tylko na wzrost kondycji, koordynacji i wytrzymałości całego organizmu, ale również na umiejętność szybkiego reagowania, wyczucia przeciwnika i zdrowej rywalizacji. Dzięki Decathlon Go bez problemu znajdziesz miejsca, w których będziesz mógł realizować swoją pasję do badmintona. Możesz zapisać się do akademii badmintona, w której nauka gry odbywa się na każdym poziomie zaawansowania. Skorzystaj z profesjonalnego sprzętu i doświadczenia trenerów. Zajęcia odbywają się w różnych dzielnicach Warszawy, na przykład na Ochocie czy Pradze, dzięki czemu możesz wybrać dogodną dla siebie lokalizację.Wystarczy zapisać się na wybrany trening, by móc spędzić aktywny czas. Zajęcia odbywają się cyklicznie w zamkniętych obiektach, halach. Gracze mają szansę doskonalić swoją technikę, brać udział w turniejach i odkrywać swój potencjał. Podczas treningów możesz zarówno korzystać ze swojego sprzętu, jak i organizatorów zajęć.',
        ],
      },
      {
        title: 'Gra w badmintona dla najmłodszych',
        text: [
          'Ten rodzaj sportu jest popularny wśród dzieci i młodzieży. Gra w badmintona nie tylko pozytywnie wpływa na prawidłowy rozwój najmłodszych miłośników sportu, ale również pomaga im w pokonywaniu własnych granic, umożliwia zawieranie nowych znajomości i kształtuje charakter. Uprawianie sportu wymaga samodyscypliny i zaangażowania. Warto pozwalać pociechom na eksperymentowanie i próbowanie nowych dyscyplin sportu. Na Decathlon Go znajdziesz harmonogramy i szczegóły dotyczące organizacji zajęć. W ramach akademii badmintona czy klubu sportowego możesz wysłać pociechę na obóz sportowy, czy turniej. Wystarczy wygodny strój do ćwiczeń, rakieta i lotka do badmintona, by twoje dziecko mogło wkroczyć do fascynującego świata gry! ',
        ],
      },
       {
        title: 'Trenuj indywidualnie, w klubie badmintona lub z partnerem!',
        text: [
'Spróbuj różnych rodzajów aktywności i wybierz najlepszy dla siebie! Każdy sposób na trening jest dobry, najważniejsze jednak, by przybliżał cię do upragnionych efektów. Dlatego od czasu do czasu możesz zmieniać formę treningu i przykładowo wybrać się na zorganizowane zajęcia w klubie sportowym pod okiem wykwalifikowanej kadry. To doskonała okazja, by doskonalić swoją technikę, a także ruszać się i spalać kalorie. Jeśli nigdy nie grałeś w badmintona, być może warto sprawdzić czy ten rodzaj aktywności jest dla ciebie? Bez obaw, zapłacisz jedynie za te treningi, w których weźmiesz udział. Bez umów członkowskich i zbędnych formalności, dlatego odkrywanie swoich pasji i poszerzanie sportowych horyzontów jeszcze nigdy nie było tak proste!',
        ],
      },
    ],
    searchParams: {
      sport: 'badminton',
      bounds: new LatLngBounds(
        new LatLng(52.30094281, 21.10950476),
        new LatLng(52.10614075, 20.89015052)
      ),
    },
  },
  {
    url: '/wydarzenia/bieganie-wroclaw',
    name: 'RunningWroclawLanding',
    title:
      'Trening biegowy Wrocław. Biegaj z grupą pod okiem trenera!',
    header:
      'Grupy biegowe Wrocław ',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Dołącz do grupy biegowej w samym sercu Wrocławia i trenuj pod okiem doświadczonego trenera. Znajdź motywację i wsparcie w bieganiu!',
    lead:
      'Bieganie to prawdziwa przygoda! Nowych ścieżek i tras nigdy nie zabraknie, zwłaszcza jeśli wybierzesz się na trening z grupą biegową. Z Decathlon Go znajdziesz najlepsze miejsce do wspólnych treningów i popracujesz nad swoją kondycją i wydolnością organizmu.',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/running.jpg',
    sports: computeSportTiles(
      ['running', 'volleyball', 'fitness', 'football', 'tabletennis'],
      'warszawa'
    ),
    articles: [
      {
        title:
          'Trening biegowy w grupie we Wrocławiu',
        text: [
          'Grupy biegowe wśród miłośników aktywności fizycznej cieszą się coraz większą popularnością. Biegacze z wieloletnim doświadczeniem i początkujący amatorzy sportu mogą pracować nad lepszą formą i kondycją organizmu, a przy okazji poznać innych miłośników biegania. Niezależnie od tego czy przygotowujesz się do mistrzostw, czy chcesz pobić swoją życiówkę, czy wprowadzić odrobinę ruchu do siedzącego trybu życia – bieganie w grupie może okazać się świetnym rozwiązaniem. Grupy biegowe we Wrocławiu oferują zróżnicowane zajęcia dostosowane do poziomu zaawansowania i wieku uczestników. Treningi odbywają się pod okiem doświadczonych trenerów biegania, a sami biegacze tworzą wyjątkową społeczność, do której możesz dołączyć. Na Decathlon Go znajdziesz informacje o treningach biegowych we Wrocławiu. Możesz zacząć biegać w każdym momencie, warto jednak przed rozpoczęciem aktywności przemyśleć swoje cele i efekty, które będziesz chciał osiągnąć. Duża część sukcesu będzie zależeć nie tylko od samodyscypliny i zaangażowania, ale również diety oraz odpowiednio ułożonego planu treningowego. Grupowe treningi biegowe we Wrocławiu mogą pomóc w osiągnięciu zamierzonych celów. ',        
        ],
      },
      {
        title:
          'Bieganie we Wrocławie – trenuj pod okiem trenera! ',
        text: [
          'Bieganie to pasja, która bardzo często staje się jednym ze sposobów na życie. Ten rodzaj aktywności nie wymaga specjalistycznego sprzętu – wystarczą odpowiednio dopasowane buty do biegania oraz wygodny strój do ćwiczeń. Biegać możemy niemal w każdym miejscu, a liczba osób praktykujących ten sport cały czas rośnie, możliwe dlatego, że ten rodzaj aktywności spala mnóstwo kalorii. Możesz wybrać się na indywidualny trening lub skorzystać z zajęć grupowych. Te ostatnie są popularne zwłaszcza w większych miastach, gdzie można spotkać wielu miłośników biegania. Niezależnie od tego, jaki rodzaj aktywności wybierasz na co dzień, zajęcia biegowe mogą pomóc we wzroście kondycji i wydolności całego organizmu.',
        ],
      },
         {
        title:
          'Wspólny bieg i zawody – przeżyj przygodę z grupą biegową Wrocław ',
        text: [
  'Wrocławskie grupy biegowe to nie tylko dobra okazja na urozmaicenie swoich codziennych treningów, ale również poznanie nowych znajomych. Wymiana doświadczeń przyniesie dodatkowe korzyści oraz pozwoli na ulepszenie swoich treningów lub doda motywacji do kontynuowania ćwiczeń. Cykliczne treningi są prowadzone przez doświadczonych biegaczy, którzy dostosowują poziom zajęć do możliwości grupy. W ramach klubów sportowych, czy zajęć z trenerem biegowym, możesz skorzystać również z organizowanych obozów biegowych oraz imprez biegowych. Najczęściej odbywają się podczas cieplejszych pór roku, kiedy sezon na bieganie trwa w pełni. To dobra okazja do przygotowania motorycznego, poznania prawidłowej techniki biegu (n stawiania stopy na podłożu), czy dostosowania planów treningowych do własnych potrzeb i możliwości ciała.',      
        ],
      },
    ],
    searchParams: {
      sport: 'running',
      bounds: new LatLngBounds(
        new LatLng(51.2114736, 17.1763478),
        new LatLng(51.04268219999999, 16.80738)
      ),
    },
  },
  {
    url: '/wspieraj-sport',
    name: 'SportSupportLanding',
    title: '#wspierajsport razem z nami!',
    header: '#wspierajsport razem z nami i zdobądź bon o wartości 20zł!',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Jak możesz pomóc? Kup trening w okazyjnej cenie i wykorzystaj go do końca 2020 roku. Taka forma “vouchera” będzie nieocenionym wsparciem dla trenerów i siłowni, którzy walczą o być albo nie być na rynku.',
    lead: (
      <span>
        <span style={{ fontWeight: 700 }}>NAGRADZAMY AKTYWNYCH!</span> <br />{' '}
        Zdobądź kupon 20zł na zakupy w Decathlon! Zarezerwuj w maju aktywności
        na kwotę min. 40zł i zgarnij bon 20zł na zakupy w Decathlon.
      </span>
    ),
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/sport-supports.jpg',
    sports: [],
    articles: [
      {
        title: 'Nagradzamy aktywność sportową bonami Decathlon!',
        text: [
          'Jeśli w maju dokonasz rezerwacji o łącznej wartości min. 40 zł, na wydarzenia objęte akcją #wspierajsport, otrzymasz od DecathlonGO bon na 20 zł do zrealizowania w sklepach Decathlon i na decathlon.pl. Jedyne czego potrzebujecie to karta lojalnościowa Decathlon, której numer należy podać przy dokonywaniu rezerwacji!',
          '1. Załóż konto na DecathlonGO i aktywuj adres email poprzez link aktywacyjny, który otrzymasz na podanego przy rejestracji maila',
          '2. Dodaj krótki opis swojej osoby, zaznacz interesujące Cię sporty i prześlij swoje sportowe zdjęcie profilowe, tak żeby inni mogli Cię lepiej poznać na platformie.',
          '3. Na tej stronie (https://go.decathlon.pl/wspieraj-sport) znajdź interesujący Cię trening',
          '4. Zapłać online za wejściówki na wybrane przez Ciebie zajęcia, tak żeby kwota opłaconych wejściówek w maju wynosiła min. 40 zł i podaj przy każdym zakupie numer swojej karty Decathlon.',
          '5. Trenuj z nami przez cały miesiąc w ramach treningów, za które zapłaciłeś i ciesz się z bonu na kwotę 20 zł do wykorzystania na decathlon.pl i w naszych sklepach stacjonarnych.',
          'Pod koniec każdego tygodnia będziemy przygotowywać listę osób, które wydały na DecathlonGO min. 40 zł w maju. Każda z tych osób otrzyma od nas informację na maila potwierdzającą spełnienie warunków akcji a następnie otrzyma od nas bon w formie cyfrowej. Bon będzie ważny przez cały rok i będzie można go przeznaczyć na dowolny produkt z naszego sklepu. Wspólnie #wspieramysport i spędzamy aktywnie maj ćwicząc z DecathlonGO!',
        ],
      },
      {
        title: 'Co zyskujesz jako organizator?',
        text: [
          'Wiemy, że nie jest lekko, dlatego chcemy wesprzeć, już teraz, zarówno trenerów, jak i siłownie w jak najszybszym powrocie do aktywności biznesowej. Jak to zrobimy?',
          'Po pierwsze, od każdego, kto będzie potrzebował takiego wsparcia, na czas akcji będziemy pobierać tylko 3% prowizji na pokrycie kosztów transakcji w systemie płatności. Oczywiście obecność na naszej platformie pozostaje bez zmian - jest i będzie darmowa. Dzięki temu bez żadnych nakładów finansowych pozwoli to odbudować frekwencję i zainteresowanie usługami.',
          'Po drugie, wesprzemy Wasze biznesy marketingowo, korzystając nie tylko z naszych własnych kanałów komunikacji, ale również skorzystamy z siły dotarcia Decathlonu. Będzie to niezwykle ważny aspekt, bo w takiej kombinacji docieramy do milionów sportowców miesięcznie.',
          'Po trzecie, Twoja oferta będzie bardzo atrakcyjna dla potencjalnego klienta, ponieważ za wydanie min. 40 złotych w maju otrzymuje bon o wartościu 20zł do Decathlon. Chyba warto spróbować i wrzucić swój trening na platformę, co?',
          'Tyle możemy dla Was zrobić i mamy nadzieję, że chociaż tak przyczynimy się do odbudowania Waszego biznesu. Wszyscy razem gramy do jednej bramki, dlatego tak ważne jest, aby się wzajemnie wspierać. Jeżeli jesteś zainteresowany naszą propozycją, daj znać.',
        ],
      },
    ],
    searchParams: {
      // sport: 'running',
      meta_sport_support: true,
      bounds: new LatLngBounds(
        new LatLng(55.18904748, 23.85383751),
        new LatLng(48.49042442, 14.44416954)
      ),
    },
    showBenefits: true,
  },
  {
    url: '/cwicze-w-domu',
    name: 'TrainAtHomeLanding',
    title: 'Wyzwanie #cwiczewdomu! - postaw na aktywność w domowym zaciszu',
    header: 'Trenuj w domu z Decathlon!',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Nie daj się marazmowi i aktywnie wykorzystaj chwile spędzone w domu. Zadbaj o swoje samopoczucie, biorąc udział w różnorodnych treningach ogólnorozwojowych.',
    lead:
      'Ponad 150 treningów online ze znanymi trenerami z wielu dyscyplin. Pływanie na sucho, tłuszczożerny HIIT, a może trening motoryczny dla biegacza? Ćwicz, co chcesz kiedy chcesz i gdzie chcesz zupełnie bezpłatnie z Decathlon Go!',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/cwicze-w-domu2.jpg',
    sports: [],
    articles: [
      {
        title: 'Dlaczego warto ćwiczyć w domu?',
        text: [
          'Ruch to zdrowie, a zdrowie to dobre samopoczucie, które dzisiaj jest nam szczególnie potrzebne. Siłownie są zamknięte, wychodzenie z domu ograniczone, ale to nie jest przeszkodą do tego, aby w domowym zaciszu wykonać trening. W Internecie jest wiele gotowych zestawów, pytanie tylko - co wybrać?',
          'Ciekawym rozwiązaniem jest skorzystanie z treningu on-line. Co więcej, najlepiej chyba skorzystać z opcji treningu z Decathlon, bo dzięki temu będziesz czuć się prawie tak, jak podczas normalnej sesji z trenerem personalnym. Dzisiejsza technologia umożliwia taką formę ćwiczeń, a my jako Decathlon GO idziemy z duchem czasu.',
          'Każdego dnia na naszej platformie znajdziesz trening live. Będą to różni trenerzy, a każdy z nich przygotuje dla Was dedykowany zestaw ćwiczeń. Na nudę na pewni nie będziesz narzekać, a dobry humor zostanie z Tobą na długo!',
        ],
      },
      {
        title: 'Co ćwiczyć w domu?',
        text: [
          'Nie możesz doczekać się wspólnego treningu on-line albo chcesz się do niego lepiej przygotować? Jest wiele ćwiczeń, które możesz wykonać w domu. Poniżej znajdziesz przykładową listę: Mountain climber (podpór przodem, na przemian przyciągaj prawe/lewe kolano do klatki piersiowej), Przysiady (Air Squat, czyli przysiady bez obciążenia), Podpór przodem, na przemian podnosisz prawą nogę i lewą rękę, potem lewą nogę i prawą rękę.',
          'Każde ćwiczenie wykonujesz po 10 powtórzeń lub 20-30sek, potem przerwa lub spokojniejsze ćwiczenie, np. trucht w miejscu 20-50sek (w zależności od Twojego stopnia zaawansowania, a jeśli taki trening robisz pierwszy raz, wydłuż przerwy). Proste?',
          'Wyzwanie #cwiczewdomu ma wiele twarzy. Najważniejsze jest to, aby cały czas pozostać w ruchu. Postaw na aktywność w domu i razem z nami ćwicz z trenerami!',
        ],
      },
    ],
    searchParams: {
      // sport: 'running',
      sort: 'pub_date',
      meta_train_at_home: true,
      bounds: new LatLngBounds(
        new LatLng(55.18904748, 23.85383751),
        new LatLng(48.49042442, 14.44416954)
      ),
      allEvents: true,
    },
    hideMap: true,
    allEvents: true,
  },
  {
    url: '/konferencja-reps-polska',
    name: 'RepsConferenceLanding',
    title: 'REPs Polska - konferencja live',
    header: 'REPs Polska - konferencja live',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Zapisz się na rozpoczynającą się 20 maja pięciodniową ogólnokrajową konferencję Fitness, Wellness i Treningu Personalnego live online, podczas której będzie można uczestniczyć w 35h wykładów, zajęć praktycznych i warsztatów!',
    lead:
      'REPs Polska przy wsparciu szkół I trenerów posiadających ich akredytację, organizuje wydarzenie online jakiego jeszcze nie było! Już  20 maja rozpocznie się pięciodniowa ogólnokrajowa konferencja Fitness, Wellness i Treningu Personalnego live online, podczas której będzie można uczestniczyć w 35h wykładów, zajęć praktycznych i warsztatów!',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/reps_konf.jpg',
    sports: [],
    landingClassName: css.reps,
    articles: [
      {
        title: 'Tematy konferencja REPs Polska',
        text: [
          'REPs Polska jest organizacją będącą członkiem ICREPs - Międzynarodowej Federacji Zrzeszającej Kraje Członkowskie posiadające System Rejestracji REPs, która reprezentuje wybrane Placówki Szkoleniowe oraz ich Szkoleniowców, jak również indywidualnych Instruktorów Fitness, Instruktorów Siłowni i Trenerów Personalnych, którzy otrzymali dyplom ukończenia kursu w jednej z akredytowanych szkół.',
          'To wydarzenie zostało przygotowane w taki sposób, aby odpowiadać potrzebom zarówno profesjonalistów, jak i amatorów, oraz osobom mającym zarówno 20, 40, czy 60 lat. Tematy  poruszane na konferencji, zostały wyselekcjonowane w taki sposób, aby każdy znalazł w niej temat, który go zainteresuje:',
          '-> oparte na najbardziej aktualnych badaniach, sposoby na poprawę siły, szybkości, balansu, mobilności i gibkości',
          '-> dowiedzieć się jak skutecznie i bez zbędnego wysiłku doprowadzić swoje ciało do dobrej formy i wyglądu',
          '-> zwiększyć swoją wiedzę na temat dietetyki i suplementacji',
          '-> pracować nad sprawnością swojego umysłu, nauczyć się motywować i poznać metody na polepszenie swoich relacji z otoczeniem',
          '-> poznać metody o tym, jak zarabiać w branży fitness i jak czerpać z niej korzyści',
        ],
      },
      {
        title: 'Konferencja REPs',
        text: [
          'Podcza konferencji przewidzianych jest 35 godzinnych spotkań, które będą skierowane do szerokiego grona odbiorców, osób które na co dzień “żyją” w szeroko pojętej branży fitness, jak i tych stawiających w niej pierwsze kroki, a nawet osób, które chcą poprawić budowę swojego ciała.',
          'Dołącz do uczestników tego wyjątkowego wydarzenia, jeżeli jesteś:',
          '-> trenerem personalnym, który chcą uzupełnić swój warsztat i nauczyć się sprzedawać swoje treningi',
          '-> managerem klubu, który chcą zaczerpnąć inspiracji do rozszerzenia klubowego grafiku zajęć',
          '-> osobą, która chciałaby w przyszłości zostać trenerami i instruktorami',
          '-> miłośnikiem fitness, w tym Jogi, Pilates, Streching, Ballance, który chce wchłonąć nowe sposoby ćwiczeń i poznać nowe formy zajęć',
          '-> domatorem i okazjonalnym bywalcem klubów, który dopiero marzą o zdrowym i pięknym ciele',
          'Podczas każdych zajęć będziesz mógł ćwiczyć lub jak wolisz tylko popatrzeć, posłuchać i spróbować. Wybór zostanie pozostawiony Tobie',
        ],
      },
    ],
    searchParams: {
      // sport: 'running',
      // meta_train_at_home: true,
      meta_landing: 'has_all:reps',
      bounds: new LatLngBounds(
        new LatLng(55.18904748, 23.85383751),
        new LatLng(48.49042442, 14.44416954)
      ),
    },
    hideMap: true,
    repsBenefits: true,
  },
  {
    url: '/treningi-online/joga',
    name: 'TrainOnlineYogaLanding',
    title: 'Praktyka jogi online',
    header: 'JOGA W DOMU, JOGA Z RODZINĄ',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Zapraszamy na praktykę jogi w domowym zaciszu razem z partnerami Decathlon GO! Ogromna liczby treningów do wyboru.',
    lead:
      'W myśl tegorocznego hasła Międzynarodowego Dnia Jogi zapraszamy Cię do wspólnej praktyki online. Usiądź na macie, złap/weź głęboki oddech i odkryj w sobie to, co nieznane. Praktykuj w domu, praktykuj z rodziną, praktykuj z nami. Namaste!',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/yoga-online.jpg',
    sports: [],
    articles: [
      {
        title: 'Dlaczego joga?',
        text: [
          'Joga to system indyjskiej filozofii mający na uwadze relacje zachodzące między ciałem a duchem. Dzięki specjalnemu treningowi ciała do którego zalicza się również ascezę oraz medytację prowadzącą do dyscypliny duchowe, a także kierując się w życiu najwyższymi zasadami etycznymi praktykujący jogę mogą dostąpić szansy poznania natury rzeczywistości.',
        ],
      },
      {
        title: 'Joga w domu',
        text: [
          'Już teraz przed Tobą szansa na własne studio jogi w domowym zaciszu. Jedyne czego potrzebujesz to wygodny strój, mata do ćwiczeń i chęć oraz zapał, aby w niesamowitych asanach zrelaksować się po ciężkim dniu. My zapewniami raem z naszymi partnerami ogrom filmów na których o dowolnej porze znajdziecie niesamowite sekwencje asan do domowej praktyki jogi.',
        ],
      },
    ],
    searchParams: {
      // sport: 'running',
      meta_landing: 'has_all:jogaonline',
      bounds: new LatLngBounds(
        new LatLng(55.18904748, 23.85383751),
        new LatLng(48.49042442, 14.44416954)
      ),
      allEvents: true,
    },
    hideMap: true,
    allEvents: true,
  },
  {
    url: '/treningi-online/dzienzdrowia',
    name: 'HealthDayLanding',
    title: 'Dzień Zdrowia - poruszamy razem dzieciaki',
    header: 'Dzień Zdrowia - poruszamy razem dzieciaki',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Co trzecie dziecko w Polsce ma problem z nadwagą. A aktywność może temu zaradzić! W ramach naszej akcji z programem “Zdrowo i Sportowo” poruszliśmy już prawie PÓŁ MILIONA dzieci! I nie przestajemy. Przygotowaliśmy dla Was serię 35 eventów, które odbędą się w 12 sklepach Decathlon w takich miastach jak Warszawa, Poznań, Katowice i Gdańsk z Rumią. W wydarzeniach możesz wziąć udział na żywo w naszych sklepach, ale również tutaj - online. Zapraszamy!',
    lead:
      'Co trzecie dziecko w Polsce ma problem z nadwagą. A aktywność może temu zaradzić! W ramach naszej akcji z programem “Zdrowo i Sportowo” poruszliśmy już prawie PÓŁ MILIONA dzieci! I nie przestajemy. Przygotowaliśmy dla Was serię 35 eventów, które odbędą się w 12 sklepach Decathlon w takich miastach jak Warszawa, Poznań, Katowice i Gdańsk z Rumią. W wydarzeniach możesz wziąć udział na żywo w naszych sklepach, ale również tutaj - online. Zapraszamy!',
    mainImgSrc: HeaderImg,
    sports: [],
    articles: [
      {
        title: 'Dla rodziców',
        text: [
          'Sprawdź dedykowane rodzicom artykuły na Naszym blogu:',
          <p>
            <a
              href="https://blog.decathlon.pl/sport/dla-rodzicow/"
              target="_blank"
            >
              https://blog.decathlon.pl/sport/dla-rodzicow/
            </a>
          </p>,
        ],
      },
      {
        title: 'Zdrowo i sportowo',
        text: [
          'Sprawdź materiały dla rodziców oraz szczegóły akcji zdrowo i sportowo: ',
          <p>
            <a href="https://zdrowoisportowo.edu.pl/" target="_blank">
              https://zdrowoisportowo.edu.pl/
            </a>
          </p>,
        ],
      },
    ],
    searchParams: {
      // sport: 'running',
      meta_landing: 'has_all:healthday',
      bounds: new LatLngBounds(
        new LatLng(55.18904748, 23.85383751),
        new LatLng(48.49042442, 14.44416954)
      ),
      allEvents: true,
    },
    hideMap: true,
    allEvents: true,
  },
  {
    url: '/treningi-online/poruszamydzieciakiwwakacje',
    name: 'HealtholidayLanding',
    title: 'Poruszamy dzieciaki w wakacje',
    header: 'Poruszamy dzieciaki w wakacje',
    city: 'Poznań',
    city2: 'Poznaniu',
    description:
      'Co trzecie dziecko w Polsce ma problem z nadwagą. A aktywność może temu zaradzić! Już w te wakacje możesz wziąć udział w bezpłatnych spotkaniach sportowych w sklepach Decathlon w Poznaniu. Razem z programem “Zdrowo i Sportowo” zadbamy o to, by Twoje dziecko odkryło swój ulubiony sport. W wydarzeniach możesz wziąć udział na żywo w sklepach Decathlon, ale również tutaj - online. Zapraszamy!!',
    lead:
      'Co trzecie dziecko w Polsce ma problem z nadwagą. A aktywność może temu zaradzić! Już w te wakacje możesz wziąć udział w bezpłatnych spotkaniach sportowych w sklepach Decathlon w Poznaniu. Razem z programem “Zdrowo i Sportowo” zadbamy o to, by Twoje dziecko odkryło swój ulubiony sport. W wydarzeniach możesz wziąć udział na żywo w sklepach Decathlon, ale również tutaj - online. Zapraszamy!!',
    mainImgSrc: HeaderImg,
    sports: [],
    articles: [
      {
        title: 'Dla rodziców',
        text: [
          'Sprawdź dedykowane rodzicom artykuły na Naszym blogu:',
          <p>
            <a
              href="https://blog.decathlon.pl/sport/dla-rodzicow/"
              target="_blank"
            >
              https://blog.decathlon.pl/sport/dla-rodzicow/
            </a>
          </p>,
        ],
      },
      {
        title: 'Zdrowo i sportowo',
        text: [
          'Sprawdź materiały dla rodziców oraz szczegóły akcji zdrowo i sportowo: ',
          <p>
            <a href="https://zdrowoisportowo.edu.pl/" target="_blank">
              https://zdrowoisportowo.edu.pl/
            </a>
          </p>,
        ],
      },
    ],
    searchParams: {
      // sport: 'running',
      meta_landing: 'has_all:healthHoliday',
      bounds: new LatLngBounds(
        new LatLng(55.18904748, 23.85383751),
        new LatLng(48.49042442, 14.44416954)
      ),
      allEvents: true,
    },
    hideMap: true,
    allEvents: true,
  },

  {
    url: '/treningi-online/wakacjezdecathlongo',
    name: 'HolidayWithDecathlonGoLanding',
    title: 'Poruszamy dzieciaki w wakacje',
    header: 'Poruszamy dzieciaki w wakacje ZAJĘCIA ONLINE',
    city: 'Poznań',
    city2: 'Poznaniu',
    description:
      'Co trzecie dziecko w Polsce ma problem z nadwagą. A aktywność może temu zaradzić! Razem z programem “Zdrowo i Sportowo” zadbamy o to, by poruszać dzieciaki w te wakacje. Weź udział w 10. bezpłatnych zajęciach online i zadbaj o aktywność fizyczną Twojej rodziny. Zapraszamy!!!',
    lead:
      'Co trzecie dziecko w Polsce ma problem z nadwagą. A aktywność może temu zaradzić! Razem z programem “Zdrowo i Sportowo” zadbamy o to, by poruszać dzieciaki w te wakacje. Weź udział w 10. bezpłatnych zajęciach online i zadbaj o aktywność fizyczną Twojej rodziny. Zapraszamy!!!',
    mainImgSrc: HeaderImg,
    sports: [],
    articles: [
      {
        title: 'Dla rodziców',
        text: [
          'Sprawdź dedykowane rodzicom artykuły na Naszym blogu:',
          <p>
            <a
              href="https://blog.decathlon.pl/sport/dla-rodzicow/"
              target="_blank"
            >
              https://blog.decathlon.pl/sport/dla-rodzicow/
            </a>
          </p>,
        ],
      },
      {
        title: 'Zdrowo i sportowo',
        text: [
          'Sprawdź materiały dla rodziców oraz szczegóły akcji zdrowo i sportowo: ',
          <p>
            <a href="https://zdrowoisportowo.edu.pl/" target="_blank">
              https://zdrowoisportowo.edu.pl/
            </a>
          </p>,
        ],
      },
    ],
    searchParams: {
      // sport: 'running',
      meta_landing: 'has_all:healthHolidayWithGo',
      bounds: new LatLngBounds(
        new LatLng(55.18904748, 23.85383751),
        new LatLng(48.49042442, 14.44416954)
      ),
      allEvents: true,
    },
    hideMap: true,
    allEvents: true,
  },

  {
    url: '/treningi-online/tasmy-gumy-oporowe',
    name: 'TrainOnlineElasticBandLanding',
    title: 'Treningi online z gumami i taśmami oporowymi',
    header: 'Treningi online z gumami i taśmami oporowymi',
    city: 'Warszawa',
    city2: 'Warszawie',
    description:
      'Przedstawiamy przykładowe treningi wzmacniające z taśmami i gumami oporowymi dzięki, którym wzmocnicie całe ciało.',
    lead:
      'Trening z gumami oporowymi łączy w sobie elementy ćwiczeń z obciążeniem, a także zwinnościowych i rozciągania. Wszystko zależy od techniki ćwiczeń oraz rodzaju użytych gum lub taśm. Bo te, żeby efekty treningu były widoczne, też trzeba dobrać we właściwy sposób.',
    mainImgSrc: 'https://kgcodego.b-cdn.net/landings/mini-bands.jpg',
    sports: [],
    articles: [
      {
        title: 'Gumy, taśmy, ekspandery: czym się różnią?',
        text: [
          'Pod nazwą guma do ćwiczeń kryć się mogą różne akcesoria, choć tak naprawdę istnieje tu ścisła terminologia, którą warto znać. Warto więc uporządkować sobie na początek wiedzę teoretyczną, bo wcale nie jest ona złożona, a może się przydać przy doborze sprzętu do zaplanowanych ćwiczeń.',
          <>
            <h3>Ekspandery</h3>
            <p>
              Ekspander jest z trzech wymienionych akcesoriów najłatwiejszy do
              odróżnienia. Może być wykonany z gumowych „linek” albo sprężyn,
              natomiast cechą charakterystyczną są uchwyty. To właśnie one
              ograniczają zastosowanie tego akcesorium, co w połączeniu z wyższą
              z reguły ceną sprawia, że to gumy i taśmy są popularniejsze.
            </p>
          </>,
          <>
            <h3>Taśma oporowa</h3>
            <p>
              Taśma oporowa może mieć różny kolor, różną grubość (a co za tym
              idzie, także oferować różny poziom trudności treningu), różną
              długość. Zawsze jest to jednak tylko „pas” z gumy. Taśma nigdy nie
              tworzy zamkniętej pętli.
            </p>
          </>,
          <>
            <h3>Guma oporowa</h3>
            <p>
              Guma oporowa to pojęcie najbardziej pojemne, ale ściśle rzecz
              ujmując, tą nazwą powinny być określane tylko minibandy i
              powerbandy. To akcesoria tworzące zamkniętą pętlę. Wykonane są z
              materiału podobnego do taśm, jednak najczęściej są od nich
              grubsze, ale węższe. Świetnie sprawdzają się na przykład jako
              element progresji choćby w ćwiczeniach na drążku.
            </p>
          </>,
        ],
      },
      {
        title: 'Gumy oporowe: która będzie najlepsza?',
        text: [
          'Praktycznie wszystkie gumy i taśmy oporowe są dziś wykonane z lateksu. To materiał, który praktycznie nie ma słabych punktów, więc ryzyko pęknięcia jest znikome. W najniższym segmencie cenowym zdarzają się jeszcze taśmy lateksowe z domieszką naturalnej gumy, ale ich jakość jest słabsza, a charakterystyka pracy niezbyt korzystna.',
          'Duże znaczenie będzie miało to, jaki opór stawia guma. Zależy to przede wszystkim od dwóch czynników: szerokości taśmy oraz jej grubości. Wąskie minibandy mają 13 mm szerokości, ale są dość grube i zapewniają opór na poziomie 9-10 kilogramów szerokie na ponad 80 mm taśmy oporowe mogą „dokładać” nawet do 70 kilogramów. Najczęściej jednak gumy i taśmy są „kodowane” kolorami, przy czym każdy producent może tu stosować swój własny klucz – nie ma uniwersalnej reguły, która mówi na przykład, że taśma fioletowa jest mocniejsza od niebieskiej.',
        ],
      },
      {
        title: 'Po co używać gum oporowych?',
        text: [
          'Gumy oporowe znakomicie sprawdzają się w domowym treningu. Mogą stanowić budżetowy zamiennik wyciągu czy niewielkich ciężarków, natomiast zdecydowanie najbardziej przydatne są w początkowych etapach treningu, kiedy używane obciążenia są jeszcze dość skromne. Co prawda, chwytając gumę nieco inaczej lub po prostu kupując grubszą, można w pewnym zakresie progresować, ale mimo wszystko – to rozwiązanie albo dla początkujących, albo dla zaawansowanych, którzy z różnych powodów nie mogą realizować planu treningowego na siłowni.',
          'Odpowiednio użyte gumy oporowe (szczególnie popularne w tym zastosowaniu są minibandy) mogą pomagać na przykład w podciąganiu na drążku. Klasyczna taśma o raczej dużej grubości poważnie utrudnia klasyczne pompki lub domowe ćwiczenia na biceps.',
          'Mówiąc ogólnie: gumy oporowe pozwalają urozmaicić ćwiczenia i dostosować je do poziomu wytrenowania. W warunkach domowych w dodatku pozwolą przećwiczyć mięśnie, które w inny sposób trudno jest pobudzić do wydajnego działania.',
        ],
      },
      {
        title: 'Jaki trening można poprawić dzięki użyciu taśm i gum?',
        text: [
          'Jedną z najwspanialszych zalet taśm i gum oporowych jest to, że wykorzystane właściwie, są w stanie urozmaicić prawie każdy trening. Zasad jest stosunkowo niewiele: należy wykorzystywać taśmy w sposób bezpieczny, czyli minimalizować ryzyko pęknięcia lub ześlizgnięcia się taśmy. Konieczne jest dobranie odpowiedniego typu i oporu taśmy zarówno do wykonywanych ćwiczeń, jak i poziomu wytrenowania. Ćwicząc z gumami oporowymi, należy dbać o prawidłową technikę: większe obciążenie w połączeniu z niedokładnym wykonywaniem ćwiczeń zwiększa ryzyko kontuzji.',
          <>
            <h3>Gumy oporowe w ćwiczeniach ogólnorozwojowych</h3>
            <p>
              To podstawowe i najpowszechniejsze zastosowanie gum oporowych.
              Pozwalają one nie tylko progresować w treningu ogólnorozwojowym,
              ale też – prawidłowo użyte – wymuszają dopracowanie techniczne
              wykonywanych ćwiczeń. Gumy i taśmy oporowe można wykorzystać na
              przykład w ćwiczeniu przysiadów, pompek, a także wielu ćwiczeń
              obręczy barkowej.
            </p>
          </>,
          <>
            <h3>Taśmy oporowe w treningu siłowym</h3>
            <p>
              Taśmy i gumy oporowe mogą początkującym zastąpić hantle w wielu
              typowych ćwiczeniach, szczególnie obejmujących górną połowę ciała.
              Na wyższych stopniach zaawansowanie gumy wykorzystuje się albo do
              dopracowania techniki i jako element stopniowej progresji, albo
              jako alternatywę dla treningu na siłowni, kiedy z różnych powodów
              trzeba ćwiczyć w domu. Warto też dodać, że wiele osób ułatwia
              sobie dzięki gumom pierwsze serie podciągnięć na drążku.
            </p>
          </>,
          <>
            <h3>Gumy i taśmy oporowe w treningu sztuk walki</h3>
            <p>
              Odrobina inwencji pozwala wykorzystać taśmy i gumy w treningach
              sztuk walki: zwiększają one szybkość, pozwalają pracować nad
              techniką, ale też kształtują mięśnie i stanowią dodatkowe
              utrudnienie w bardziej złożonych figurach treningowych. Warto
              pamiętać, że tempo ruchów wykonywanych w takim treningu wymaga,
              aby guma była bardzo odporna na rozdarcia, a przy tym musi stawiać
              spory opór.
            </p>
          </>,
          <>
            <h3>Gumy w treningu biegowym i kolarskim</h3>
            <p>
              Także biegacze i kolarze mogą poprawić swoje umiejętności dzięki
              gumom. Pomocny może okazać się zakup specjalnych gum dla piłkarzy,
              które stabilniej trzymają się na nogach i pozwalają przetrenować
              siłę biegową. Nawet jednak klasyczne taśmy pozwalają wprowadzić do
              planu treningowego kilka dodatkowych ćwiczeń na mięśnie nóg – samą
              bazą tlenową nie da się przecież wygrać zawodów, potrzeba jeszcze
              sporo siły.
            </p>
          </>,
        ],
      },
    ],
    searchParams: {
      // sport: 'running',
      meta_landing: 'has_all:elasticband',
      bounds: new LatLngBounds(
        new LatLng(55.18904748, 23.85383751),
        new LatLng(48.49042442, 14.44416954)
      ),
      allEvents: true,
    },
    hideMap: true,
    allEvents: true,
  },
];
