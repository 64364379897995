import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import config from '../../config';
import { getAuth } from '../../util/api';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';

import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedRedirect,
  Page,
} from '../../components';
import { TopbarContainer } from '../../containers';
import {
  authenticationInProgress,
  login,
  // signupWithIdp,
  loginSuccess,
} from '../../ducks/Auth.duck';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';

import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import FooterV2 from '../../components/Footer/FooterV2';
import css from './AuthenticationPage.css';

export const AuthenticationPageComponent = props => {
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search);

  const code = searchParams.get('code');

  useEffect(() => {
    if (code) submitLogin({ code });
  }, []);

  const {
    authInProgress,
    currentUser,
    intl,
    isAuthenticated,
    location,
    loginError,
    scrollingDisabled,
    signupError,
    submitLogin,
    submitSignup,
    confirmError,
    submitSingupWithIdp,
    submitSignupSuccess,
    tab,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onManageDisableScrolling,
  } = props;
  const isConfirm = tab === 'confirm';
  const isLogin = tab === 'login';
  const locationFrom = location.state && location.state.from ? location.state.from : null;
  // const authinfoFrom =
  //   this.state.authInfo && this.state.authInfo.from
  //     ? this.state.authInfo.from
  //     : null;
  const locationFromLocalStorage =
    (typeof window !== 'undefined' && localStorage.getItem('redirectUrl')) || '/';
  const from = locationFromLocalStorage; //locationFrom
  if (locationFrom && typeof window !== 'undefined')
    localStorage.setItem('redirectUrl', locationFrom);
  // localStorage.removeItem('redirectUrl');
  // ? locationFrom
  // : authinfoFrom
  //   ? authinfoFrom
  //   : null;
  const user = ensureCurrentUser(currentUser);

  console.log('history', history);

  // Already authenticated, redirect away from auth page
  // if (isAuthenticated && from && !from.includes('login') && typeof window !== 'undefined') {
  //   localStorage.removeItem('redirectUrl');
  //   return <Redirect to={from} />;
  // } else if (isAuthenticated && location.pathname.includes('logged-in')) {
  //   return <NamedRedirect name="LandingPage" />;
  // }
  if (history.action === 'POP' && isLogin) {
    return <NamedRedirect name="LandingPage" />;
  }

  if (isAuthenticated && isConfirm && from && typeof window !== 'undefined') {
    localStorage.removeItem('redirectUrl');
    return <Redirect to={from} />;
  } else if (isAuthenticated && isConfirm) {
    return <NamedRedirect name="LandingPage" />;
  }

  const loginErrorMessage = (
    <div className={css.error}>
      <FormattedMessage id="AuthenticationPage.loginFailed" />
    </div>
  );

  // eslint-disable-next-line no-confusing-arrow
  const errorMessage = (error, message) => (error ? message : null);
  const loginOrSignupError =
    loginError && loginError.status === 409 ? errorMessage(loginError, loginErrorMessage) : null;

  // Tabs for SignupForm and LoginForm
  const authenticationForms = async () => {
    await getAuth(submitSignupSuccess);
  };

  const formContent =
    !isConfirm && !isAuthenticated && !authInProgress && history.action !== 'POP'
      ? authenticationForms(submitSignupSuccess)
      : null;

  const siteTitle = config.siteTitle;
  const schemaTitle = isLogin
    ? intl.formatMessage({ id: 'AuthenticationPage.schemaTitleLogin' }, { siteTitle })
    : intl.formatMessage({ id: 'AuthenticationPage.schemaTitleSignup' }, { siteTitle });

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
      }}
      robots="noindex, follow"
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.layoutWrapperMain}>
          <div className={css.root} onClick={formContent}></div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <FooterV2 />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

AuthenticationPageComponent.defaultProps = {
  currentUser: null,
  loginError: null,
  signupError: null,
  tab: 'signup',
  sendVerificationEmailError: null,
};

const { bool, func, object, oneOf, shape } = PropTypes;

AuthenticationPageComponent.propTypes = {
  authInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,
  loginError: propTypes.error,
  scrollingDisabled: bool.isRequired,
  signupError: propTypes.error,
  submitLogin: func.isRequired,
  submitSignup: func.isRequired,
  tab: oneOf(['login', 'signup']),

  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withRouter
  location: shape({ state: object }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated, loginError, signupError } = state.Auth;
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  return {
    authInProgress: authenticationInProgress(state),
    currentUser,
    isAuthenticated,
    loginError,
    scrollingDisabled: isScrollingDisabled(state),
    signupError,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
  };
};

const mapDispatchToProps = dispatch => ({
  submitLogin: ({ code }) => dispatch(login(code)),
  // submitSignup: (params) => dispatch(signup(params)),
  // submitSingupWithIdp: (params) => dispatch(signupWithIdp(params)),
  submitSignupSuccess: params => dispatch(loginSuccess()),
  // onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const AuthenticationPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(AuthenticationPageComponent);

export default AuthenticationPage;
