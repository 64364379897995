import React from 'react';
import PropTypes from 'prop-types';

const IconArrowRight = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2A374A"
        d="M8.471 2.862l4.667 4.667.013.013.046.053-.059-.066c.029.029.055.06.077.092l.031.05c.009.014.016.029.023.043.041.086.064.184.064.286 0 .069-.01.135-.03.198-.003.013-.008.026-.013.039-.007.018-.014.035-.023.052-.005.012-.01.023-.017.034-.01.02-.022.038-.035.056l-.02.028c-.014.018-.029.035-.044.051l-.013.013-4.667 4.667c-.26.26-.682.26-.942 0-.26-.26-.26-.682 0-.943l3.528-3.528H3.333c-.368 0-.666-.299-.666-.667 0-.368.298-.667.666-.667h7.724L7.529 3.805c-.26-.26-.26-.683 0-.943.26-.26.682-.26.942 0z"
      />
    </svg>
  );
};

IconArrowRight.defaultProps = { className: null };

const { string } = PropTypes;

IconArrowRight.propTypes = {
  className: string,
};

export default IconArrowRight;