import React from "react";
import css from './BookingWithoutPayment.css';


const TimeSlotsModal = ({ timeSlots, setDate, setIsOtherSlotsModalOpen }) => {
    return <>
        <div className={css.modalBackground} onClick={() => setIsOtherSlotsModalOpen(false)} />
        <div className={css.timeslotsmodalcontainer}>
            <p className={css.timeslotmodalheader}>Kliknij, aby wybrać termin <span className={css.timeslotmodalclose} onClick={() => setIsOtherSlotsModalOpen(false)}>x</span></p>
            <ul className={css.timeslotsmodalitemscontainer}>
                {timeSlots.filter(timeSlot => {
                    return timeSlot && timeSlot.attributes && (new Date()).getTime() < (new Date(timeSlot.attributes.end)).getTime()
                }).map((timeSlot, index) => {
                    return <li className={css.timeslotsmodalitem} key={index} onClick={() => {
                        setDate(new Date(timeSlot.attributes.start))
                        setIsOtherSlotsModalOpen(false)
                    }}>
                        <p style={{ margin: 0 }}>{new Intl.DateTimeFormat('pl-PL', {
                            timeStyle: "short",
                            dateStyle: "medium",
                        }).format(new Date(timeSlot.attributes.start)).split(',').join(' godz. ')}
                        </p>
                        <p style={{ margin: '8px 0 0 0' }}>Liczba dostępnych miejsc: {timeSlot.attributes.seats}</p>
                    </li>
                })}
            </ul>
        </div>
        {/* </div> */}
    </>
}

export default TimeSlotsModal