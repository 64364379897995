import React from 'react';
import css from './EditListingAdditionalFieldChoose.css';

import selectPath from '../../../src/assets/select.jpeg';
import checkboxPath from '../../../src/assets/checkbox.jpeg';
import teaxtareaPath from '../../../src/assets/textarea.jpeg';


const SingleFieldType = ({
  picture,
  title,
  description,
  onClick,
  selectedTypeOfQuestion,
  fieldType,
}) => {
  return (
    <div
      onClick={onClick}
      className={
        selectedTypeOfQuestion === fieldType
          ? css.containerselected
          : css.container
      }
    >
      <div className={css.picturecontainer}>
        <img className={css.picture} src={picture} />
      </div>
      <div className={css.text}>
        <h3 className={css.title}>{title}</h3>
        <p className={css.description}>{description}</p>
      </div>
    </div>
  );
};

const FieldsChoose = ({
  closeAddQuestionForm,
  selectedTypeOfQuestion,
  setSelectedTypeOfQuestion,
  setStep,
}) => {
  return (
    <>
      <h2>Wybierz typ pola</h2>
      <SingleFieldType
        onClick={() => {
          setSelectedTypeOfQuestion('checkboxField');
          setStep('editField');
        }}
        selectedTypeOfQuestion={selectedTypeOfQuestion}
        picture={checkboxPath}
        title={'Pole wielokrotnego wyboru'}
        description={
          <span>
            Uczestnik może wybrać{' '}
            <span style={{ textDecoration: 'underline' }}>1 lub więcej</span>{' '}
            odpowiedzi
          </span>
        }
        fieldType={'checkboxField'}
      />
      <SingleFieldType
        onClick={() => {
          setSelectedTypeOfQuestion('selectField');
          setStep('editField');
        }}
        selectedTypeOfQuestion={selectedTypeOfQuestion}
        picture={selectPath}
        title={'Pole jednokrotnego wyboru'}
        description={
          <span>
            Uczestnik może wybrać{' '}
            <span style={{ textDecoration: 'underline' }}>tylko 1</span>{' '}
            odpowiedź
          </span>
        }
        fieldType={'selectField'}
      />
      <SingleFieldType
        onClick={() => {
          setSelectedTypeOfQuestion('textareaField');
          setStep('editField');
        }}
        selectedTypeOfQuestion={selectedTypeOfQuestion}
        picture={teaxtareaPath}
        title={'Pole tekstowe'}
        description={'Uczestnik wpisuje odpowiedź na pytanie'}
        fieldType={'textareaField'}
      />
      <div className={css.buttonscontainer}>
        <button className={css.cancel} onClick={closeAddQuestionForm}>
          Anuluj
        </button>
      </div>
    </>
  );
};

export default FieldsChoose;
