import React from 'react';
import classNames from 'classnames';
import css from './FieldSelect.css';

const FieldSelectDisplayed = ({ title, description, options, register, isRequired }) => {

    const selectClasses = classNames(css.select);

    const selectProps = { className: selectClasses };

    const classes = classNames(css.root);
    return (
        <div className={classes}>
            <label htmlFor={title}>
                {title}
                {isRequired && <sup style={{ color: 'red' }}>*</sup>}
            </label>
            <select style={{ color: '#4a4a4a' }} {...selectProps} {...register(title.split('.').join('_'), {
                required: isRequired,
                validate: (value) => !isRequired || value !== 'wybierz'
            })}>
                <option disabled value="">
                    {description}
                </option>
                {['wybierz', ...options].map((c) => (
                    <option key={c} value={c}>
                        {c}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default FieldSelectDisplayed