import React from 'react';
import { ReactComponent as CheckIcon } from '../../../../assets/listingPage/checkCircle.svg';

import css from '../styles.css';

const levels = data =>
  data.data.attributes.publicData.levels
    ? data.data.attributes.publicData.levels
        .map(item => {
          switch (item) {
            case 'novice':
              return 'Nowicjusz';
            case 'beginner':
              return 'Początkujący';
            case 'intermediate':
              return 'Średniozaawansowany';
            case 'advanced':
              return 'Zaawansowany';
            case 'professional':
              return 'Profesjonalista';
          }
        })
        .join(', ')
    : '';

const ImportantInfo = ({ data }) => {
  const termsFileUrl =
    data.included.filter(item => item.type === 'file').length &&
    data.included.filter(item => item.type === 'file')[0].attributes.url;

  return (
    <section className={css.important}>
      <h2>Ważne informacje</h2>

      <div className={css.contentContainer}>
        <div>
          <h3>Wymagania dla gości</h3>
          <p>
            <CheckIcon style={{ marginRight: '10px' }} />
            Poziomy zaawansowania uczestników: {levels(data)}
          </p>
        </div>

        <div>
          <h3>Zasady i regulamin</h3>
          <p>{data.data.attributes.publicData.returns_policy}</p>
          {!!termsFileUrl && (
            <p>
              <a href={termsFileUrl} target="_blank">
                Zobacz regulamin wydarzenia
              </a>
            </p>
          )}
        </div>
      </div>
    </section>
  );
};

export default ImportantInfo;
