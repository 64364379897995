import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useGoogleMaps } from '../../util/mapContext';

const containerStyle = {
    width: '100%',
    height: '400px',
    zIndex: 1,
};

const MapWithMarker = ({ lat, lng }) => {

    const { isLoaded, loadError } = useGoogleMaps();

    if (loadError) {
        return <div>Błąd podczas ładowania mapy</div>;
    }

    if (!isLoaded) {
        return <div>Wczytywanie mapy...</div>;
    }

    const center = {
        lat: lat,
        lng: lng,
    };

    return (
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
            <Marker position={center} />
        </GoogleMap>
    );
};

export default MapWithMarker;
