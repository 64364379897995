import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';
import config from '../../config';

import css from './EditListingDescriptionForm.css';

const eventTypes = config.custom.eventTypes;

const EventTypeSelectField = props => {
  const { name, id, intl } = props;
  const typeLabel = intl.formatMessage({
    id: 'EventTypeSelectField.typeLabel',
  });
  const typePlaceholder = intl.formatMessage({
    id: 'EventTypeSelectField.typePlaceholder',
  });
  const typeRequired = required(
    intl.formatMessage({
      id: 'EventTypeSelectField.typeRequired',
    })
  );

  return eventTypes ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={typeLabel}
      validate={typeRequired}
    >
      <option disabled value="">
        {typePlaceholder}
      </option>
      {eventTypes.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default EventTypeSelectField;
