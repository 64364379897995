import React, { useEffect, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingAvailabilityForm } from '../../forms';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { onetimeEventAvailabilityPlan, permanentEventAvailabilityPlan, regularEventAvailabilityPlan } from './availabilityPlans';

import css from './EditListingAvailabilityPanel.css';
import AddTerminForm from './AddTerminForm';

const EditListingAvailabilityPanel = props => {
  const {
    className, rootClassName, listing, availability, onSubmit, onChange, submitButtonText, panelUpdated, updateInProgress, errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  // const availabilityPlan = currentListing.attributes.availabilityPlan;
  const publicData = currentListing.attributes.publicData;
  // const chooseAvailabilityPlan = (availabilityType, { seats, entries, dateAndTime }) => {
  //   if (availabilityType === 'onetime') return onetimeEventAvailabilityPlan(dateAndTime, seats);
  //   // permanent event type doesnt have participants limit - stop selling tickets is amde via closing event
  //   // reopening allows to sell again
  //   if (availabilityType === 'permanent') return permanentEventAvailabilityPlan();
  //   return regularEventAvailabilityPlan(entries)
  // }

  const [termins, setTermins] = useState(publicData.termins ? publicData.termins : [])


  // const seats = availabilityPlan && availabilityPlan.entries.reduce((acc, cur) => {
  //   return { ...acc, [cur.dayOfWeek]: cur.seats }
  // }, {});
  // const dayOfWeek = availabilityPlan && availabilityPlan.entries.map(day => day.dayOfWeek);

  // const initDaysOfWeek = { dayOfWeek, seats };

  useEffect(() => {
    availability.onFetchAvailabilityExceptions({
      listingId: currentListing.id.uuid,
      start: new Date(),
      end: new Date((new Date()).setMonth((new Date()).getMonth() + 3))
    })
  }, [])

  return (
    <div className={css.editListingContainer} >
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAvailabilityPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
          />
        ) : (
          <FormattedMessage id="EditListingAvailabilityPanel.createListingTitle" />
        )}
      </h1>

      {/* <AddTerminForm /> */}

      {new Date(currentListing.attributes.createdAt) < new Date(2024, 0, 19) && currentListing.attributes.publicData.availabilityType === 'onetime' ? <p style={{ transform: 'translateY(-50vh)' }}>
        Brak możliwości edycji terminów wydarzenia.
      </p> : <EditListingAvailabilityForm
        className={css.form}
        listingId={currentListing.id}
        initialValues={{
          // availabilityType: publicData.availabilityType || 'onetime',
          // participants_limit: publicData.participants_limit,
          // daysOfWeek: initDaysOfWeek,
          // dateAndTime: publicData.date
          termins: publicData.termins

        }}
        termins={termins}
        setTermins={setTermins}
        availability={availability}
        availabilityPlan={{
          type: "availability-plan/time",
          timezone: "Europe/Warsaw",
          entries: [
            { dayOfWeek: 'mon', seats: 0, startTime: '01:00', endTime: '23:55' },
            { dayOfWeek: 'tue', seats: 0, startTime: '01:00', endTime: '23:55' },
            { dayOfWeek: 'wed', seats: 0, startTime: '01:00', endTime: '23:55' },
            { dayOfWeek: 'thu', seats: 0, startTime: '01:00', endTime: '23:55' },
            { dayOfWeek: 'fri', seats: 0, startTime: '01:00', endTime: '23:55' },
            { dayOfWeek: 'sat', seats: 0, startTime: '01:00', endTime: '23:55' },
            { dayOfWeek: 'sun', seats: 0, startTime: '01:00', endTime: '23:55' }
          ]
        }}
        onSubmit={() => {
          // Object.values(availability.calendar)[0].exceptions.forEach((item) => {
          //   availability.onDeleteAvailabilityException({
          //     currentException: item,
          //     id: item.availabilityException.id.uuid
          //   })
          // })
          // Object.values(availability.calendar).flat()
          // const daysOfWeekSubmit = daysOfWeek && daysOfWeek.seats && Object.keys(daysOfWeek.seats).map(day => (
          //   {dayOfWeek: day, seats: Number(daysOfWeek.seats[day]) }
          // ));
          termins.forEach(termin => {
            let terminDate = new Date(termin.date);
            terminDate.setMinutes(terminDate.getMinutes() + Number(termin.duration) - (termin.duration == 5 ? 0 : 5))
            availability.onCreateAvailabilityException({
              listingId: currentListing.id.uuid,
              start: new Date(termin.date),
              end: terminDate,
              seats: termin.limit || 10000
            })
          });

          // const selectedAvailabilityPlan = chooseAvailabilityPlan(availabilityType, {entries: daysOfWeekSubmit, seats: participants_limit, dateAndTime });
          // We save the default availability plan
          // I.e. this listing is available every night.
          // Exceptions are handled with live edit through a calendar,
          // which is visible on this panel.
          // const eventDate = availabilityType === 'onetime' ? new Date(dateAndTime).getTime() : 2524608000000;
          const data = {
            publicData: {
              termins,
              // participants_limit: availabilityType === 'onetime' ? participants_limit : availability === 'regular' ? selectedAvailabilityPlan : null,
              date: (new Date(termins[termins.length - 1].date)).getTime()
            },
            availabilityPlan: {
              type: "availability-plan/time",
              timezone: "Europe/Warsaw",
              entries: [
                { dayOfWeek: 'mon', seats: 0, startTime: '01:00', endTime: '23:55' },
                { dayOfWeek: 'tue', seats: 0, startTime: '01:00', endTime: '23:55' },
                { dayOfWeek: 'wed', seats: 0, startTime: '01:00', endTime: '23:55' },
                { dayOfWeek: 'thu', seats: 0, startTime: '01:00', endTime: '23:55' },
                { dayOfWeek: 'fri', seats: 0, startTime: '01:00', endTime: '23:55' },
                { dayOfWeek: 'sat', seats: 0, startTime: '01:00', endTime: '23:55' },
                { dayOfWeek: 'sun', seats: 0, startTime: '01:00', endTime: '23:55' }
              ]
            }
          }
          onSubmit(data)
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
      />}
    </div>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  availability: shape({
    calendar: object.isRequired,
    onFetchAvailabilityExceptions: func.isRequired,
    onCreateAvailabilityException: func.isRequired,
    onDeleteAvailabilityException: func.isRequired,
  }).isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
