import React from 'react';
import {
  AboutPage,
  AuthenticationPage,
  CheckoutPage,
  FreeCheckoutPage,
  ContactDetailsPage,
  EditListingPage,
  EditOrganizerPage,
  EmailVerificationPage,
  HowToPage,
  InboxPage,
  LandingPage,
  ListingPage,
  ManageListingsPage,
  ManageBusinessCardPage,
  NotFoundPage,
  OnboardingPage,
  OrganizerPage,
  P24StatusPage,
  PartnersPage,
  PasswordChangePage,
  ConsentsChangePage,
  PasswordRecoveryPage,
  PasswordResetPage,
  PayoutPreferencesPage,
  PaymentMethodsPage,
  PrivacyPolicyPage,
  LowInformationPage,
  ScannerPrivacyPage,
  ProfilePage,
  ProfileSettingsPage,
  SearchPage,
  StripePayoutPage,
  StyleguidePage,
  TermsOfServicePage,
  TransactionPage,
  HomeTrainingPage,
  ConsoleListingPage,
  ConsoleUserPage,
  ConsoleReviewPage,
  ConsoleTransactionPage,
} from './containers';

import { academyRoutes } from './containers/Academy/academyRoutes';
import { sportLocationRoutes } from './containers/SportLocationPage/sportLocationRoutes';
import { calculatorRoutes } from './containers/CalculatorPage/calculatorRoutes';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
import { Redirect } from 'react-router-dom';

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'ConsentsChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  // 'PayoutPreferencesPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/logged-in',
      name: 'ConfirmPage',
      component: props => <AuthenticationPage {...props} tab="confirm" />,
    },
    {
      path: '/',
      name: 'LandingPage',
      component: props => <LandingPage {...props} />,
    },
    {
      path: '/o-nas',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/jak-dodac-perfekcyjne-wydarzenie-sportowe',
      name: 'HowToPage',
      component: HowToPage,
    },
    {
      path: '/how-to-add-perfect-event',
      name: 'HowToPage',
      component: HowToPage,
    },
    {
      path: '/dla-partnerow',
      name: 'PartnersPage',
      component: PartnersPage,
    },
    {
      path: '/partners',
      name: 'PartnersPage',
      component: PartnersPage,
    },
    {
      path: '/trenuj-w-domu',
      name: 'HomeTrainingPage',
      component: props => <HomeTrainingPage {...props} />,
      loadData: HomeTrainingPage.loadData,
    },
    // {
    //   path: '/s',
    //   name: 'SearchPage',
    //   component: (props) => <SearchPage {...props} />,
    //   loadData: SearchPage.loadData,
    // },
    {
      path: '/s/wydarzenia-sportowe/:sport/:city',
      name: 'SearchPage',
      component: () => <SearchPage />,
      // loadData: SearchPage.loadData,
    },
    // {
    //   path: '/s/filters',
    //   name: 'SearchFiltersPage',
    //   component: (props) => <SearchPage {...props} tab="filters" />,
    //   loadData: SearchPage.loadData,
    // },
    // {
    //   path: '/s/listings',
    //   name: 'SearchListingsPage',
    //   component: (props) => <SearchPage {...props} tab="listings" />,
    //   loadData: SearchPage.loadData,
    // },
    // {
    //   path: '/s/map',
    //   name: 'SearchMapPage',
    //   component: (props) => <SearchPage {...props} tab="map" />,
    //   loadData: SearchPage.loadData,
    // },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:id',
      name: 'ListingPageShort',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: props => <CheckoutPage {...props} />,
      setInitialValues: CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/checkout-free',
      name: 'FreeCheckoutPage',
      auth: true,
      component: props => <FreeCheckoutPage {...props} />,
      setInitialValues: FreeCheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    // Organizer routes
    {
      path: '/organizator',
      name: 'OrganizerBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/organizator/:slug/:id',
      name: 'OrganizerPage',
      component: props => <OrganizerPage {...props} />,
      loadData: OrganizerPage.loadData,
    },
    {
      path: '/organizator/:slug/:id/:type/:tab',
      name: 'EditOrganizerPage',
      auth: true,
      component: props => <EditOrganizerPage {...props} />,
      loadData: EditOrganizerPage.loadData,
    },
    // End of organizer routes
    {
      path: '/l/event-new',
      name: 'NewListingPage',
      auth: true,
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{
            slug: draftSlug,
            id: draftId,
            type: 'new',
            step: '0',
          }}
        />
      ),
    },
    {
      path: '/l/event/:id/:type/:step',
      name: 'EditListingPage',
      auth: true,
      component: props => <EditListingPage {...props} />,
      loadData: EditListingPage.loadData,
    },
    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: props => <ListingPage {...props} />,
      loadData: ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/profil/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: props => <ProfilePage {...props} />,
      loadData: ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ProfileSettingsPage {...props} />,
    },

    // {
    //   path: '/login',
    //   name: 'LoginPage',
    //   component: (props) => <AuthenticationPage {...props} tab="login" />,
    // },
    {
      path: '/signup',
      name: 'SignupPage',
      component: () => props => <AuthenticationPage {...props} tab="signup" />,
    },
    {
      path: '/login',
      name: 'LoginPage',
      component: props => <AuthenticationPage {...props} tab="login" />,
    },

    // {
    //   path: '/testlogin',
    //   name: 'TestLoginPage',
    //   component: (props) => <AuthenticationPage {...props} tab="logintest" />,
    // },
    {
      path: '/signup-success',
      name: 'OnboardingPage',
      component: props => <OnboardingPage {...props} />,
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: props => <PasswordRecoveryPage {...props} />,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <InboxPage {...props} />,
      loadData: InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      // loadData: (params) =>
      //   TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      // setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/message/:id/details',
      name: 'MessageDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} />,
      // loadData: (params) =>
      //   TransactionPage.loadData({ ...params, }),
      // setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/l/:chatId/:listingId/',
      name: 'ListingMessageDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ListingPage {...props} />,
      // loadData: (params) =>
      //   TransactionPage.loadData({ ...params, }),
      // setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/details-success',
      name: 'OrderDetailsSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="customer" />,
      // loadData: (params) =>
      //   TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      // setInitialValues: TransactionPage.setInitialValues,
    },
    {
      path: '/order/:id/push-payment-status',
      name: 'P24StatusPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <P24StatusPage {...props} transactionRole="customer" />,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <TransactionPage {...props} transactionRole="provider" />,
      // loadData: (params) =>
      //   TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/inbox/sales',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageListingsPage {...props} />,
      // loadData: ManageListingsPage.loadData,
    },
    {
      path: '/business-cards',
      name: 'ManageBusinessCardPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ManageBusinessCardPage {...props} />,
      // loadData: ManageListingsPage.loadData,
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <ContactDetailsPage {...props} />,
      loadData: ContactDetailsPage.loadData,
    },
    // {
    //   path: '/account/change-password',
    //   name: 'PasswordChangePage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: (props) => <PasswordChangePage {...props} />,
    // },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <PaymentMethodsPage {...props} />,
      loadData: PaymentMethodsPage.loadData,
    },
    // {
    //   path: '/account/payments',
    //   name: 'PayoutPreferencesPage',
    //   auth: true,
    //   authPage: 'LoginPage',
    //   component: props => <PayoutPreferencesPage {...props} />,
    //   loadData: PayoutPreferencesPage.loadData,
    // },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <StripePayoutPage {...props} />,
      loadData: StripePayoutPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: props => <TermsOfServicePage {...props} />,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicyPage',
      component: props => <PrivacyPolicyPage {...props} />,
    },
    {
      path: '/informacje-prawne',
      name: 'LowInformationPage',
      component: props => <LowInformationPage {...props} />,
    },
    {
      path: '/scanner-privacy',
      name: 'ScannerPrivacyPage',
      component: props => <ScannerPrivacyPage {...props} />,
    },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: props => <StyleguidePage {...props} />,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: props => <StyleguidePage raw {...props} />,
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: props => <PasswordResetPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <EmailVerificationPage {...props} />,
      loadData: EmailVerificationPage.loadData,
    },
    // {
    //   path: '/confirm',
    //   name: 'ConfirmPage',
    //   component: props => <AuthenticationPage {...props} tab="confirm" />,
    //   // component: AuthenticationPage,
    //   // extraProps: { tab: 'confirm' },
    // },
    {
      path: '/console/listings',
      name: 'ConsoleListingPage',
      component: props => <ConsoleListingPage />,
      loadData: ConsoleListingPage.loadData,
    },
    {
      path: '/console/users',
      name: 'ConsoleUserPage',
      component: props => <ConsoleUserPage />,
      loadData: ConsoleUserPage.loadData,
    },
    {
      path: '/console/reviews',
      name: 'ConsoleReviewPage',
      component: props => <ConsoleReviewPage />,
      loadData: ConsoleReviewPage.loadData,
    },
    {
      path: '/console/transactions',
      name: 'ConsoleTransactionPage',
      component: props => <ConsoleTransactionPage />,
      loadData: ConsoleTransactionPage.loadData,
    },
    //belowe there are Academy routes
    ...academyRoutes,
    ...sportLocationRoutes,
    ...calculatorRoutes,
  ];
};

export default routeConfiguration;
