import React from 'react';
import PropTypes from 'prop-types';

const IconLogin = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2A374A"
        fillRule="nonzero"
        stroke="none"
        d="M12.667 1.333a2 2 0 0 1 2 2v9.334a2 2 0 0 1-2 2H10a.667.667 0 1 1 0-1.334h2.667a.667.667 0 0 0 .666-.666V3.333a.667.667 0 0 0-.666-.666H10a.667.667 0 1 1 0-1.334h2.667zM7.138 4.195l3.333 3.334.054.06.026.035a.697.697 0 0 1 .086.179.664.664 0 0 1-.086.573l-.026.036a.685.685 0 0 1-.037.042l-.017.017-3.333 3.334a.667.667 0 0 1-.943-.943L8.39 8.666H2a.667.667 0 0 1 0-1.333h6.39L6.195 5.138a.667.667 0 0 1 .943-.943z"
      />
    </svg>
  );
};

IconLogin.defaultProps = { className: null };

const { string } = PropTypes;

IconLogin.propTypes = {
  className: string,
};

export default IconLogin;
