import React, { Component } from 'react';
import { bool, func, node, number, object, string } from 'prop-types';
import classNames from 'classnames';
import { injectIntl, intlShape } from 'react-intl';
import { OutsideClickHandler, FieldDateRangeController, IconCalendar } from '../../components';
import css from './FieldDatePopup.css';

const KEY_CODE_ESCAPE = 27;

class FieldDatePopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };

    this.filter = null;
    this.filterContent = null;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  handleSubmit() {
    this.setState({ isOpen: false });
  }

  handleClear() {
    const { input } = this.props;
    input.onChange('');
  }

  handleCancel() {
    const { input, initialValues } = this.props;
    input.onChange(initialValues)
  }

  handleChange(values) {
    const { input } = this.props;
    input.onChange(values)
  }

  handleBlur() {
    this.setState({ isOpen: false });
  }

  handleKeyDown(e) {
    // Gather all escape presses to close menu
    if (e.keyCode === KEY_CODE_ESCAPE) {
      this.toggleOpen(false);
    }
  }

  toggleOpen(enforcedState) {
    if (enforcedState) {
      this.setState({ isOpen: enforcedState });
    } else {
      this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }
  }

  render() {
    const {
      className,
      contentPlacementOffset,
      id,
      initialValues,
      keepDirtyOnReinitialize,
      labelClassName,
      inputFieldClass,
      placeholder,
      popupClassName,
      rootClassName,
      input,
      meta,
      intl,
      iconClassName
    } = this.props;
    const { name } = input;
    const classes = classNames(rootClassName || css.root, className);
    const labelClasses = classNames(css.label, labelClassName);
    const inputClasses = classNames(css.inputField, inputFieldClass);
    const popupClasses = classNames(css.popup, { [css.isOpen]: this.state.isOpen });
    const popupSizeClasses = popupClassName || css.popupSize;
    const iconClass = classNames(iconClassName || css.icon);
    const clear = intl.formatMessage({ id: 'FilterForm.clear' });
    const cancel = intl.formatMessage({ id: 'FilterForm.cancel' });
    const submit = intl.formatMessage({ id: 'FilterForm.submit' });

    const format = {
      month: 'long',
      day: 'numeric',
    };

    const inputValue = input.value ? `${intl.formatDate(input.value.startDate, format)} - ${intl.formatDate(input.value.endDate, format)}` : '';
    return (
      <OutsideClickHandler onOutsideClick={this.handleBlur} className={css.mainContainer}>
        <div
          className={classes}
          onKeyDown={this.handleKeyDown}
          ref={node => {
            this.filter = node;
          }}
          onClick={() => this.toggleOpen()}  
        >
          <input
            className={inputClasses}
            type="text"
            placeholder={placeholder}
            name={name}
            autoComplete="off"
            value={inputValue}
            disabled      
          />
          <div className={iconClass}>
            <IconCalendar className={css.iconCalendar} />
          </div>
          
          <div
            id={id}
            className={popupClasses}
            ref={node => {
              this.filterContent = node;
            }}
          > 
            {this.state.isOpen ? (
              <div>
                <FieldDateRangeController
                  name="dates"
                  // controllerRef={node => {
                  //   this.popupControllerRef = node;
                  // }}
                  onChange={input.onChange}
                />
                <div className={css.buttonsWrapper}>
                  <button className={css.clearButton} type="button" onClick={this.handleClear}>
                    {clear}
                  </button>
                  {/* <button className={css.cancelButton} type="button" onClick={this.handleCancel}>
                    {cancel}
                  </button> */}
                  <button className={css.submitButton} type="button" onClick={this.toggleOpen}>
                    {submit}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </OutsideClickHandler>
    );
  }
}

FieldDatePopup.defaultProps = {
  rootClassName: null,
  className: null,
  popupClassName: null,
  id: null,
  initialValues: null,
  keepDirtyOnReinitialize: false,
  contentPlacementOffset: 0,
  liveEdit: false,
  label: null,
};

FieldDatePopup.propTypes = {
  rootClassName: string,
  className: string,
  popupClassName: string,
  id: string,
  initialValues: object,
  keepDirtyOnReinitialize: bool,
  contentPlacementOffset: number,
  label: string,

  // form injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(FieldDatePopup);

