import React, { Component } from 'react';
import { string, arrayOf, func } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import classNames from 'classnames';
import moment from 'moment';
import { required, bookingDateRequired, composeValidators } from '../../util/validators';
import { propTypes } from '../../util/types';
import { Form, FieldDateInput } from '../../components';

import css from './BookingDateForm.css';

export class BookingDateFormComponent extends Component {
  render() {
    const { id, rootClassName, className, setDate, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <FinalForm
        {...rest}
        render={fieldRenderProps => {
          const {
            datePlaceholder,
            handleSubmit,
            intl,
            timeSlots,
            fetchTimeSlotsError,
          } = fieldRenderProps;

          const bookingLabel = 'Termin';
          const requiredMessage = intl.formatMessage({ id: 'BookingDatesForm.requiredDate' });
          const dateErrorMessage = 'Data jest nieprawidłowa';
          const timeSlotsError = fetchTimeSlotsError ? (
            <p className={css.timeSlotsError}>
              <FormattedMessage id="BookingDatesForm.timeSlotsError" />
            </p>
          ) : null;

          const dateFormatOptions = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          };

          const now = moment();
          const today = now.startOf('day').toDate();
          const datePlaceholderText =
            datePlaceholder || intl.formatDate(today, dateFormatOptions);

          return (
            <Form onSubmit={handleSubmit} className={classes} id={id}>
              {timeSlotsError}
              <Field
                name="date"
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;
                  const changeDate = value => {
                    onChange(value);
                    setDate(value.date);
                  }
                  const dateInput = { ...restInput, onChange: changeDate };
                  return (
                    <FieldDateInput
                      className={css.bookingDate}
                      name="date"
                      id="date"
                      label={bookingLabel}
                      placeholderText={datePlaceholderText}
                      format={null}
                      timeSlots={timeSlots}
                      validate={composeValidators(
                        required(requiredMessage),
                        bookingDateRequired(dateErrorMessage)
                      )}
                      {...dateInput}
                    />
                  )
                }}
              />
            </Form>
          );
        }}
      />
    );
  }
}

BookingDateFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  datePlaceholder: null,
  id: null,
  timeSlots: null
};

BookingDateFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  id: string,
  setDate: func,
  timeSlots: arrayOf(propTypes.timeSlot),
  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  datePlaceholder: string
};

const BookingDateForm = compose(injectIntl)(BookingDateFormComponent);
BookingDateForm.displayName = 'BookingDateForm';

export default BookingDateForm;