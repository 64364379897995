import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import css from './FieldCheckboxCustom.css';

const FieldCheckboxCustomComponent = props => {
  const { rootClassName, className, id, label, type, ...rest } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: type,
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <span>{label}</span>
      </label>
    </span>
  );
};

FieldCheckboxCustomComponent.defaultProps = {
  className: null,
  rootClassName: null,
  label: null,
  type: "checkbox"
};

FieldCheckboxCustomComponent.propTypes = {
  className: string,
  rootClassName: string,
  type: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxCustomComponent;