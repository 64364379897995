import React from 'react';
import {
  ExternalLink,
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import { twitterPageURL } from '../../util/urlHelpers';

import FooterV2 from '../../components/Footer/FooterV2';
import css from './AboutPage.css';
import image from './background.png';

const AboutPage = () => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  // prettier-ignore
  return (
    <StaticPage
      title="O nas"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Wszystko o Decathlon GO',
        name: 'O nas',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}>Sport w Twojej okolicy dzięki Decathlon GO.</h1>
          <img className={css.coverImage} src={image} alt="Społeczność Decathlon GO." />

          <div className={css.contentWrapper}>
            <div className={css.contentSide}>
              <p></p>
            </div>

            <div className={css.contentMain}>
              <h2>
                Każdego tygodnia organizowane są setki wydarzeń sportowych w całej Polsce, w których uczestniczy tysiące osób. Wspólne treningi, wyprawy, zawody, turnieje, rozgrywki łączą ludzi o różnych stopniach zaawansowania. Decathlon GO ma na celu uczynienie tego procesu jeszcze prostszym i bardziej dostępnym.
              </h2>

              <p>
                Jesteśmy ekipą sportowych pasjonatów, którzy nie boją się wyzwań i nowych projektów. Tworzymy multisportową platformę dla każdego.
                Niezależnie czy dopiero chcesz zacząć, ćwiczysz regularnie czy jesteś profesjonalistą, na go.decathlon.pl znajdziesz coś dla siebie.
                Nie wiesz jakie wydarzenia są organizowane w Twoim mieście?
                A może jedziesz na wyjazd służbowy lub urlop i chcesz wziąć udział w zajęciach sportowych?
                Chcesz spróbować nowej aktywności?
                Dołącz do społeczności Decathlon GO! Wszystkie wydarzenia w Polsce w jednym miejscu.
              </p>

              <h3 className={css.subtitle}>Organizujesz wydarzenia sportowe?</h3>

              <p>
                Dołącz do naszej multisportowej platformy i organizuj, promuj, zarabiaj organizując wydarzenia sportowe.
                Dzięki Decathlon GO w łatwy i darmowy sposób możesz dodać swoje wydarzenie i trafić do tysięcy potencjalnych uczestników. Możesz skorzystać z wielu narzędzi promocji, a także przyjmować płatności online.
              </p>

              <h3 id="contact" className={css.subtitle}>
                Masz pytania lub wątpliwości? Zapraszamy do kontaktu.
              </h3>
              <p>
                Jeśli nie jesteś pewien, czy Twoja oferta jest odpowiednie, aby znaleźć się na platformie Decathlon GO, jeżeli chcesz promować swoje wydarzenie na naszej platformie lub chcesz pobierać płatności online napisz do nas.
              </p>
              <p>
                Zapraszamy do kontaktu pod adresem {' '}
                <ExternalLink href="mailto:go.kontakt@decathlon.com">go.kontakt@decathlon.com</ExternalLink>
              </p>
              {/* <p>
                You can also checkout our{' '}
                <ExternalLink href={siteFacebookPage}>Facebook</ExternalLink> and{' '}
                <ExternalLink href={siteTwitterPage}>Twitter</ExternalLink>.
              </p> */}
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
        <FooterV2 />
      </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
