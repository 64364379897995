import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, bool, shape, string, func } from 'prop-types';
import classNames from 'classnames';
import { isScrollingDisabled } from '../../../ducks/UI.duck';
import { getMarketplaceEntities } from '../../../ducks/marketplaceData.duck';
import { loadData, updateListing } from './ConsoleListingPage.duck';
import { PaginationLinks, IconSpinner, Page, NamedLink } from '../../../components';
import { parse, stringify } from '../../../util/urlHelpers';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { TopbarContainer } from '../..';

import css from './ConsoleListingPage.css';
import SearchField from '../utils/SearchField/SearchField';
import ExtendedDataComponent from '../utils/ExtendedData/ExtendedData';
import TopNavigation from '../utils/TopNavigation/TopNavigation';
import ToggleEditField from '../utils/ToggleField/ToggleField';

const ConsoleListingPageComponent = props => {
  const {
    listings,
    fetchInProgress,
    scrollingDisabled,
    pagination,
    intl,
    location: { search, pathname },
    history,
    onUpdateListing,
    isAdmin
  } = props;
  const { entityId, authorId } = parse(search);

  const [fieldOpened, setFieldOpened] = useState(null);
  const [entityOpened, setEntityOpened] = useState(entityId || null);

  useEffect(() => {
    if (!entityId) {
      setEntityOpened(null);
    }
  }, [entityId]);

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="ConsoleListingPage"
        pageSearchParams={parse(search)}
        pagination={pagination}
      />
    ) : null;

  const handleStateChange = (listingId, newState) => {
    onUpdateListing({ id: listingId, state: newState });
  };

  const handleSave = (listingId, field, newValue) => {
    onUpdateListing({ id: listingId, [field]: newValue });
  };

  if (!isAdmin) {
    return (
      <Page title="Console" scrollingDisabled={scrollingDisabled}>
        <TopbarContainer
          className={css.topbar}
          mobileRootClassName={css.mobileTopbar}
          desktopClassName={css.desktopTopbar}
          mobileClassName={css.mobileTopbar}
        />
      </Page>
    );
  }

  return (
    <Page title="Console" scrollingDisabled={scrollingDisabled}>
      <TopbarContainer
        className={css.topbar}
        mobileRootClassName={css.mobileTopbar}
        desktopClassName={css.desktopTopbar}
        mobileClassName={css.mobileTopbar}
      />
      <div className={css.container}>
        <TopNavigation currentPage="ConsoleListingPage" />
        <div>
          <SearchField paramName="keywords" />
        </div>
        <div className={css.header}>
          {
            (!entityId && !authorId) ?
              <h1 className={css.header1}>Wydarzenia</h1>
              :
              <NamedLink name="ConsoleListingPage" className={css.header1}>Wydarzenia  {`>`}</NamedLink>
          }
          {(entityId || authorId) && <h2>{entityId || authorId}</h2>}
          <span>{pagination && pagination.totalItems}</span>
        </div>
        {fetchInProgress ? (
          <div className={css.listItemsLoading}>
            <IconSpinner />
          </div>
        ) : (
          <ul>
            {listings.map(l => (
              <div
                key={l.id.uuid}
                className={classNames(
                  css.listingWrapper,
                  {
                    [css.entityCollapsed]: entityOpened && entityOpened !== l.id.uuid,
                    [css.entityExpanded]: entityOpened === l.id.uuid,
                  }
                )}
              >
                <div
                  className={classNames(css.listingRow, { [css.deleted]: l.attributes.deleted })}
                  onClick={() => setEntityOpened(entityOpened === l.id.uuid ? null : l.id.uuid)}
                >
                  <div>
                    {l.images && l.images[0] ? (
                      <img src={l.images[0].attributes.variants['square-small'].url} />
                    ) : (
                      <div className={css.imagePlaceholder} />
                    )}
                  </div>
                  <div className={css.titleWrapper}>
                    <h2 className={css.title}>{l.attributes.title}</h2>
                    {(l.attributes.state === 'draft' || l.attributes.state === 'closed') && (
                      <h3>{l.attributes.state}</h3>
                    )}
                  </div>
                  {l.attributes.price ? (
                    <div className={css.price}>
                      {`${l.attributes.price.amount / 100} ${l.attributes.price.currency}`}
                    </div>
                  ) : (
                    <div className={css.price}>Darmowe</div>
                  )}
                  <div className={css.createdAt}>
                    {intl.formatDate(l.attributes.createdAt, { day: 'numeric', month: 'numeric', year: 'numeric' })}
                  </div>
                </div>
                {entityOpened === l.id.uuid && (
                  <div>
                    <div className={css.additionalInfo}>
                      <div>
                        <div className={css.stateButtons}>
                          {l.attributes.state === 'published' && (
                            <button
                              onClick={() => handleStateChange(l.id.uuid, 'closed')}
                              className={classNames(css.actionButton, css.closeButton)}
                            >
                              Close Listing
                            </button>
                          )}
                          {l.attributes.state === 'closed' && (
                            <button
                              onClick={() => handleStateChange(l.id.uuid, 'published')}
                              className={classNames(css.actionButton, css.openButton)}
                            >
                              Open Listing
                            </button>
                          )}
                          {l.attributes.state === 'draft' && (
                            <button
                              onClick={() => handleStateChange(l.id.uuid, 'published')}
                              className={classNames(css.actionButton, css.publishButton)}
                            >
                              Publish Listing
                            </button>
                          )}
                        </div>
                        <div className={css.entityLinks}>
                          <NamedLink name="ConsoleTransactionPage" className={css.entityLink} to={{ search: `listingId=${l.id.uuid}` }}>
                            Transakcje
                          </NamedLink>
                        </div>
                      </div>
                      <div className={css.additionalInfo}>
                        <ToggleEditField
                          label="Nazwa"
                          value={l.attributes.title}
                          onSave={(newValue) => handleSave(l.id.uuid, 'title', newValue)}
                        />
                        <ToggleEditField
                          label="Opis"
                          value={l.attributes.description}
                          onSave={(newValue) => handleSave(l.id.uuid, 'description', newValue)}
                        />
                        <h3>Id</h3>
                        <NamedLink name="ListingPageCanonical" params={{ id: l.id.uuid }} className={css.entityLink}>
                          {l.id.uuid}
                        </NamedLink>
                        <h3>Organizator</h3>
                        {
                          l.author &&
                          <NamedLink
                            name="ConsoleUserPage"
                            to={{
                              search: `?entityId=${l.author.id.uuid}`,
                            }}
                            className={css.entityLink}
                          >
                            {l.author.attributes.profile.displayName || l.author.attributes.email}
                          </NamedLink>
                        }
                      </div>
                    </div>
                    <ExtendedDataComponent
                      data={l.attributes.publicData}
                      dataType="publicData"
                      onUpdate={onUpdateListing}
                      fieldOpened={fieldOpened}
                      setFieldOpened={setFieldOpened}
                      entityId={l.id.uuid}
                    />
                    <ExtendedDataComponent
                      data={l.attributes.privateData}
                      dataType="privateData"
                      onUpdate={onUpdateListing}
                      fieldOpened={fieldOpened}
                      setFieldOpened={setFieldOpened}
                      entityId={l.id.uuid}
                    />
                    <ExtendedDataComponent
                      data={l.attributes.metadata}
                      dataType="metadata"
                      onUpdate={onUpdateListing}
                      fieldOpened={fieldOpened}
                      setFieldOpened={setFieldOpened}
                      entityId={l.id.uuid}
                    />
                  </div>
                )}
              </div>
            ))}
          </ul>
        )}
        {paginationLinks}
      </div>
    </Page>
  );
};

ConsoleListingPageComponent.defaultProps = {
  fetchInProgress: false,
  fetchUserListError: null,
  pagination: null,
  scrollingDisabled: false,
};

ConsoleListingPageComponent.propTypes = {
  fetchInProgress: bool.isRequired,
  fetchUserListError: propTypes.error,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  listings: arrayOf(propTypes.listing).isRequired,
  intl: intlShape.isRequired,
  history: shape({ push: func.isRequired }).isRequired,
  location: shape({ search: string, pathname: string }).isRequired,
  onUpdateListing: func.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchUserListError, pagination, listingList, updateUserInProgress } =
    state.ConsoleListingPage;
    const isAdmin = state.user.currentUser && state.user.currentUser.attributes.isAdmin;
  return {
    fetchInProgress,
    fetchUserListError,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    listings: getMarketplaceEntities(state, listingList),
    updateUserInProgress,
    isAdmin
  };
};

const mapDispatchToProps = dispatch => ({
  onUpdateListing: params => dispatch(updateListing(params)),
});

const ConsoleListingPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ConsoleListingPageComponent);

ConsoleListingPage.loadData = loadData;

export default ConsoleListingPage;
