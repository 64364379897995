import React from 'react';
import PropTypes from 'prop-types';

const IconPartnership = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2A374A"
        fillRule="nonzero"
        stroke="none"
        d="M13.333 1.333a2 2 0 0 1 2 2v4a7.333 7.333 0 0 1-14.666 0v-4a2 2 0 0 1 2-2h10.666zm0 1.334H2.667A.667.667 0 0 0 2 3.333v4a6 6 0 1 0 12 0v-4a.667.667 0 0 0-.667-.666zm-2.195 3.528c.26.26.26.683 0 .943L8.471 9.805a.667.667 0 0 1-.942 0L4.862 7.138a.667.667 0 1 1 .943-.943L8 8.391l2.195-2.196c.26-.26.683-.26.943 0z"
      />
    </svg>
  );
};

IconPartnership.defaultProps = { className: null };

const { string } = PropTypes;

IconPartnership.propTypes = {
  className: string,
};

export default IconPartnership;
