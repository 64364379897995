import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Section from '../../../../components/Section/Section';
import SlideShow from '../../../../components/SlideShow/SlideShow';

import img1 from '../../images/img1.png';
import img2 from '../../images/img2.png';
import img3 from '../../images/img3.png';

import css from '../PopularActivities/PopularActivities.css';

const events = [
  {
    image: img1,
    imageHeight: 250,
    label: 'Darmowe wydarzenia',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?min_price=0&max_price=0',
  },
  {
    image: img2,
    imageHeight: 250,
    label: 'Wydarzenia dla dzieci',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?pub_gender=children',
  },
  {
    image: img3,
    imageHeight: 250,
    label: 'Zajęcia dla dorosłych',
    url: '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska?pub_gender=female%2Cmale',
  },
];

const DiscoverEvents = () => {
  return (
    <Section title="Odkryj wydarzenia" isSliderContainer>
      <SlideShow maxVisibleItems={3}>
        {events.map(({ label, url, image, imageHeight }) => (
          <li key={label}>
            <a href={url} className={css.activityLink}>
              <div className={css.imageContainer} style={{ height: `${imageHeight}px` }}>
                <LazyLoadImage alt={label} src={image} height={imageHeight} width="100%" />
              </div>
              <p className={css.activityLabel}>{label}</p>
            </a>
          </li>
        ))}
      </SlideShow>
    </Section>
  );
};

export default DiscoverEvents;
