import React, { useEffect, useState } from 'react';
import { bool, func, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingAdditionalForm } from '../../forms';
import FieldsChoose from './EditListingAdditionalFieldChoose';
import TextareaField from './TextAreaField/TextAreaField';

import css from './EditListingAdditionalPanel.css';
import SelectField from './SelectField/SelectField';
import CheckboxField from './CheckboxField/CheckboxField';
import { createFormElement } from '../EditListingAdditionalFormPanel/utils'

const EditListingAdditionalPanel = (props) => {
  const {
    className,
    rootClassName,
    listing,
    availability,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const isPublished =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const publicData = currentListing.attributes.publicData;


  const [isOpenAddQuestion, setIsOpenAddQuestion] = useState(false);
  const [selectedTypeOfQuestion, setSelectedTypeOfQuestion] = useState(null);
  const [step, setStep] = useState(null); //editField
  const [formElements, setFormElements] = useState(publicData.additionalForm && publicData.additionalForm.length ? publicData.additionalForm.map(item => {
    return {
      id: item.title,
      elementData: item,
      element: createFormElement(item)
    }
  }) : []);
  const [elementData, setElementData] = useState(null);

  const handleAddQuestionClick = () => {
    setIsOpenAddQuestion((prev) => !prev);
  };

  const closeAddQuestionForm = () => {
    setIsOpenAddQuestion(false);
  };

  const handleEditQuestion = (elementData, e) => {
    e.preventDefault();
    setStep('editField');
    setElementData(elementData);
    setSelectedTypeOfQuestion(elementData.selectedTypeOfQuestion);
  };

  useEffect(() => {
    setElementData(null);
  }, [isOpenAddQuestion]);


  return (
    <div className={classes}>
      <h1 className={css.title}>
        {isPublished ? (
          <FormattedMessage
            id="EditListingAdditionalPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} /> }}
          />
        ) : (
          <FormattedMessage id="EditListingAdditionalPanel.createListingTitle" />
        )}
      </h1>
      <FormattedMessage id="EditListingAdditionalPanel.createListingSubtitle1" />
      {!isOpenAddQuestion && (
        <p className={css.subtitle} onClick={handleAddQuestionClick}>
          <FormattedMessage id="EditListingAdditionalPanel.createListingSubtitle" />
        </p>
      )}
      {isOpenAddQuestion && step !== 'editField' && (
        <FieldsChoose
          closeAddQuestionForm={closeAddQuestionForm}
          selectedTypeOfQuestion={selectedTypeOfQuestion}
          setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
          setStep={setStep}
        />
      )}
      {step === 'editField' && selectedTypeOfQuestion === 'textareaField' && (
        <TextareaField
          setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
          setIsOpenAddQuestion={setIsOpenAddQuestion}
          setStep={setStep}
          setFormElements={setFormElements}
          elementData={elementData}
        />
      )}
      {step === 'editField' && selectedTypeOfQuestion === 'selectField' && (
        <SelectField
          setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
          setIsOpenAddQuestion={setIsOpenAddQuestion}
          setStep={setStep}
          setFormElements={setFormElements}
          elementData={elementData}
        />
      )}
      {step === 'editField' && selectedTypeOfQuestion === 'checkboxField' && (
        <CheckboxField
          setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
          setIsOpenAddQuestion={setIsOpenAddQuestion}
          setStep={setStep}
          setFormElements={setFormElements}
          elementData={elementData}
        />
      )}
      <EditListingAdditionalForm
        formElements={formElements}
        handleEditQuestion={handleEditQuestion}
        setFormElements={setFormElements}
        className={css.form}
        listingId={currentListing.id}
        initialValues={{

        }}
        onSubmit={(values) => {
          const data = {
            publicData: {
              ...values
            },
          };
          onSubmit(data);
        }}
        onChange={onChange}
        saveActionMsg={submitButtonText}
        updated={panelUpdated}
        updateError={errors.updateListingError}
        updateInProgress={updateInProgress}
      />
    </div>
  );
};

EditListingAdditionalPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingAdditionalPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  availability: shape({
    calendar: object.isRequired,
    onFetchAvailabilityExceptions: func.isRequired,
    onCreateAvailabilityException: func.isRequired,
    onDeleteAvailabilityException: func.isRequired,
  }).isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAdditionalPanel;
