import React, { useEffect, useState } from 'react';
import { queryOwnListing } from './ManageListingsPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { stringify } from 'querystring';

import { formatMoney } from '../../util/currency';
import { useHistory } from 'react-router-dom';

import css from './ManageCustomerPage.css';

import { ResponsiveImage, IconDownload, Avatar, NamedLink, IconClose } from '../../components';
import {
  getCustomersData,
  getEventDetailsData,
  getEventDetailsDataPdf,
} from '../../../src/util/api';
import { txState } from '../InboxPage/InboxPage';
import { formatDate } from '../InboxPage/utils';
import BackIcon from './BackIcon';
import MesssageIcon from '../../components/messageIcon/MessageIcon';
import SendMessageModal from './SendMessageModal';
import ShowUsersIcon from '../OrganizerPage/ShowUsersIcon';
import CloseIcon from '../OrganizerPage/CloseIcon';
import { useQuery } from 'react-query';
import { apiSdk } from '../../queries/api';

const ManageDatesPageComponent = ({ listingId, intl }) => {
  let history = useHistory();

  const [filteredDate, setFilteredDate] = useState('');
  const [termins, setTermins] = useState([]);

  const { data: selectedListing, isSuccess: isSuccessListing } = useQuery('listing.show', () =>
    apiSdk.listings.show(listingId, {
      include: ['images', 'dates'],
    })
  );

  // useEffect(() => {
  //     onGetOwnListing({ listingId })
  // }, [isSuccessListing])

  useEffect(() => {
    (isSuccessListing || selectedListing) &&
      setTermins(
        selectedListing ? selectedListing.included.filter(item => item.type === 'listing-date') : []
      );
  }, [isSuccessListing, selectedListing]);

  const daysDictionary = {
    '1': 'Poniedziałek',
    '2': 'Wtorek',
    '3': 'Środa',
    '4': 'Czwartek',
    '5': 'Piątek',
    '6': 'Sobota',
    '0': 'Niedziela',
  };

  return (
    <div>
      <span>
        <BackIcon />
        <button className={css.back} onClick={history.goBack}>
          Powrót
        </button>
      </span>

      <div>
        <div className={css.form}>
          <div className={css.labelscontainer}>
            <label className={css.label}>
              Wyszukaj wydarzenie
              <input
                style={{ border: 'solid 1px #919191', padding: '5px 10px' }}
                value={filteredDate}
                onChange={e => setFilteredDate(e.currentTarget.value)}
                type="text"
              />
            </label>
          </div>
        </div>
        <ul>
          {termins &&
            termins
              .filter(date => {
                return date.attributes.date.includes(filteredDate);
              })
              .map(date => (
                <li className={css.dateContainer} key={date.id.uuid}>
                  <div>
                    <p>{`${new Date(date.attributes.date).toLocaleDateString()}, ${new Date(
                      date.attributes.date
                    ).toLocaleTimeString()}`}</p>
                    <p className={css.dayOfWeek}>
                      {daysDictionary[new Date(date.attributes.date).getDay().toLocaleString()]}
                    </p>
                  </div>
                  <NamedLink
                    name={'ManageListingsPage'}
                    params={{ listingId: listingId }}
                    to={{
                      search: stringify({ listingId: listingId, termin: date.id.uuid }),
                    }}
                    title={intl.formatMessage({ id: 'ManageListingCard.seeConsumers' })}
                  >
                    <FormattedMessage id="ManageListingCard.seeConsumers" />
                    <ShowUsersIcon className={css.icon} />
                  </NamedLink>
                </li>
              ))}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { selectedListing, queryInProgress } = state.ManageListingsPage;

  return {
    selectedListing,
    queryInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onGetOwnListing: queryParams => dispatch(queryOwnListing(queryParams)),
});

const ManageDatesPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ManageDatesPageComponent);

export default ManageDatesPage;
