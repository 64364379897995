import React from 'react';
import css from './HowToPage.css';

export default function Step({ step }) {
  const { color, img, header, text } = step;

  return (
    <div className={css.stepWrapper}>
      <img src={img} alt={header}/>
      <div>
        <h3 style={{color}}>{header}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
}