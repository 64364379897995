import { apiBaseUrl } from './api';

const fnGetFileNameFromContentDispostionHeader = function (header) {
  const contentDispostion = header.split(';');
  const fileNameToken = `filename=`;

  let fileName = 'customers.csv';
  for (let thisValue of contentDispostion) {
    if (thisValue.trim().indexOf(fileNameToken) === 0) {
      fileName = decodeURIComponent(
        thisValue.trim().replace(fileNameToken, '').replaceAll('"', '')
      );
      break;
    }
  }

  return fileName;
};

export const postCSV = (path, body, fileType) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  return fetch(url, options)
    .then(async (res) => ({
      filename: fnGetFileNameFromContentDispostionHeader(
        res.headers.get('content-disposition')
      ),
      blob: await res.blob(),
    }))
    .then((resObj) => {
      const newBlob = new Blob([resObj.blob], {
        type: fileType === 'csv' ? 'text/csv' : 'application/pdf',
      });

      const objUrl = window.URL.createObjectURL(newBlob);

      let link = document.createElement('a');
      link.href = objUrl;
      link.download = resObj.filename;
      link.click();

      // For Firefox it is necessary to delay revoking the ObjectURL.
      setTimeout(() => {
        window.URL.revokeObjectURL(objUrl);
      }, 250);
    })
    .catch((error) => {
      console.log('DOWNLOAD ERROR', error);
    });
};
