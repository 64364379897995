import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  InlineTextButton,
  Logo,
  Menu,
  MenuContent,
  MenuItem,
  MenuLabel,
  NamedLink,
} from '../../components';
import { openMissingInfoModal } from '../../components/ModalMissingInformation/MissingModalInformation.duck';
import SimpleSearchForm from '../../containers/LandingPage/SimpleSearchForm';
import useRemoveSearchParam from '../../containers/SearchPage/hooks/useRemoveSearchParam';
import { useReplacePathParam } from '../../containers/SearchPage/hooks/useReplacePathParam';
import { useUpdateQueryParams } from '../../containers/SearchPage/hooks/useUpdateQueryParams';
import { removePolishCharacters } from '../../containers/SearchPage/utils';
import { TopbarSearchForm } from '../../forms';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { sportsConfig } from '../../sportsConfig';
import { propTypes } from '../../util/types';
import css from './TopbarDesktop.css';

const TopbarDesktop = props => {
  const {
    atHomePage,
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    notificationCount,
    intl,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
  } = props;
  const authClassName = isAuthenticated ? css.auth : '';
  const classes = classNames(rootClassName || css.root, className, authClassName, {
    [css.homepage]: atHomePage,
  });

  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      form="TopbarSearchFormDesktop"
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot =
    notificationCount > 0 ? <div className={css.notificationDot}>!</div> : null;

  const inboxLink = isAuthenticated ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
      params={{ tab: 'messages' }}
    >
      <span className={css.iconInbox}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.29555 5.55005L11.9974 11.0753L19.6156 5.55005H4.29555ZM20.5498 6.72544L12.0022 12.9248L3.5498 6.86113V18.2C3.5498 18.3296 3.58972 18.3793 3.60513 18.3947C3.62055 18.4101 3.67024 18.45 3.7998 18.45H20.1998C20.325 18.45 20.3992 18.4115 20.4461 18.3708C20.4879 18.3346 20.5261 18.2804 20.5498 18.2084V6.72544ZM2.54447 4.54472C2.87906 4.21013 3.32937 4.05005 3.7998 4.05005H20.2998C20.7702 4.05005 21.2206 4.21013 21.5551 4.54472C21.8897 4.8793 22.0498 5.32961 22.0498 5.80005V18.3743L22.0352 18.4471C21.8722 19.2624 21.1921 19.95 20.1998 19.95H3.7998C3.32937 19.95 2.87906 19.79 2.54447 19.4554C2.20989 19.1208 2.0498 18.6705 2.0498 18.2V5.80005C2.0498 5.32961 2.20989 4.8793 2.54447 4.54472Z"
            fill="#101010"
          />
        </svg>
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const calendarLink = isAuthenticated ? (
    <div>
      <NamedLink
        className={css.inboxLink}
        name="InboxPage"
        // params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        params={{ tab: 'orders' }}
        key="Calendar"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.1498 4.45V3H9.6498V4.45H14.3498V3H15.8498V4.45H22.0498V18.2621L22.0396 18.3233C21.9677 18.7549 21.7786 19.1657 21.449 19.4733C21.113 19.787 20.6761 19.95 20.1998 19.95H3.7998C3.32937 19.95 2.87906 19.7899 2.54447 19.4553C2.20989 19.1207 2.0498 18.6704 2.0498 18.2V4.45H8.1498ZM8.1498 5.95H3.5498V18.2C3.5498 18.3296 3.58972 18.3793 3.60513 18.3947C3.62055 18.4101 3.67024 18.45 3.7998 18.45H20.1998C20.3235 18.45 20.3866 18.413 20.4256 18.3767C20.4661 18.3389 20.5189 18.2639 20.5498 18.1286V5.95H15.8498V7.5H14.3498V5.95H9.6498V7.5H8.1498V5.95ZM6.2498 11.5V10H7.7498V11.5H6.2498ZM11.2498 11.5V10H12.7498V11.5H11.2498ZM16.2498 11.5V10H17.7498V11.5H16.2498ZM6.2498 15.5V14H7.7498V15.5H6.2498ZM11.2498 15.5V14H12.7498V15.5H11.2498ZM16.2498 15.5V14H17.7498V15.5H16.2498Z"
            fill="#101010"
          />
        </svg>
      </NamedLink>
    </div>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const handleClickInfo = e => {
    e.stopPropagation();
    props.openMissingInfoModal('EMAIL_VERIFICATION');
  };

  const profileMenu = isAuthenticated ? (
    <Menu>
      <MenuLabel isOpenClassName={css.profileMenuIsOpen}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={css.userIcon}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.33317 5.25016C6.33317 3.25262 8.00229 1.5835 9.99984 1.5835C11.9974 1.5835 13.6665 3.25262 13.6665 5.25016C13.6665 7.24771 11.9974 8.91683 9.99984 8.91683C8.00229 8.91683 6.33317 7.24771 6.33317 5.25016ZM9.99984 3.0835C8.83072 3.0835 7.83317 4.08104 7.83317 5.25016C7.83317 6.41928 8.83072 7.41683 9.99984 7.41683C11.169 7.41683 12.1665 6.41928 12.1665 5.25016C12.1665 4.08104 11.169 3.0835 9.99984 3.0835ZM13.5331 12.7807C12.6035 11.5289 11.3638 10.8821 10.0191 10.9166C7.34908 10.985 4.9165 13.6245 4.9165 18.3335H3.4165C3.4165 13.2092 6.1506 9.51528 9.98061 9.41708C11.8859 9.36822 13.5628 10.3048 14.7374 11.8863C15.9052 13.4588 16.5832 15.6672 16.5832 18.2502H15.0832C15.0832 15.9164 14.4695 14.0415 13.5331 12.7807Z"
            fill="#101010"
          />
        </svg>
        {/* {currentUser && !currentUser.attributes.emailVerified && (
          <div className={css.profileAvatarWarning} onClick={handleClickInfo}>
            !
          </div>
        )} */}
        {/* {currentUser && <div className={css.profileAvatarOnline}></div>} */}
      </MenuLabel>
      <MenuContent className={css.profileMenuContent}>
        <MenuItem key="ManageListingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourListingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <a
            href="https://membership.decathlon.com/pl-PL/home/"
            target="_blank"
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.yourRewardsLink" />
          </a>
        </MenuItem>
        <MenuItem key="ProfileSettingsPage">
          <NamedLink
            className={classNames(css.profileSettingsLink, currentPageClass('ProfileSettingsPage'))}
            name="ProfileSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
          </NamedLink>
        </MenuItem>
        <MenuItem key="AccountSettingsPage">
          <NamedLink
            className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
            name="AccountSettingsPage"
          >
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
          </NamedLink>
        </MenuItem>

        <MenuItem key="logout">
          <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
            <span className={css.menuItemBorder} />
            <FormattedMessage id="TopbarDesktop.logout" />
          </InlineTextButton>
        </MenuItem>
      </MenuContent>
    </Menu>
  ) : null;

  // const loginLink = isAuthenticated ? null : (
  //   <a onClick={() => getAuth()} className={css.navItemLink}>
  //     <FormattedMessage id="TopbarDesktop.login" />
  //   </a>
  // );

  // const signupLink = isAuthenticated ? null : (
  //   <NamedLink name="SignupPage" className={css.navItemLink}>
  //     <FormattedMessage id="TopbarDesktop.signup" />
  //   </NamedLink>
  // );

  const loginLink = isAuthenticated ? null : (
    <NamedLink name="LoginPage" className={css.navItemLink}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.33317 5.25016C6.33317 3.25262 8.00229 1.5835 9.99984 1.5835C11.9974 1.5835 13.6665 3.25262 13.6665 5.25016C13.6665 7.24771 11.9974 8.91683 9.99984 8.91683C8.00229 8.91683 6.33317 7.24771 6.33317 5.25016ZM9.99984 3.0835C8.83072 3.0835 7.83317 4.08104 7.83317 5.25016C7.83317 6.41928 8.83072 7.41683 9.99984 7.41683C11.169 7.41683 12.1665 6.41928 12.1665 5.25016C12.1665 4.08104 11.169 3.0835 9.99984 3.0835ZM13.5331 12.7807C12.6035 11.5289 11.3638 10.8821 10.0191 10.9166C7.34908 10.985 4.9165 13.6245 4.9165 18.3335H3.4165C3.4165 13.2092 6.1506 9.51528 9.98061 9.41708C11.8859 9.36822 13.5628 10.3048 14.7374 11.8863C15.9052 13.4588 16.5832 15.6672 16.5832 18.2502H15.0832C15.0832 15.9164 14.4695 14.0415 13.5331 12.7807Z"
          fill="#101010"
        />
      </svg>
    </NamedLink>
  );

  const location = useLocation();
  const { sport, city } = useParams();
  const updateQueryParams = useUpdateQueryParams();
  const replacePathParam = useReplacePathParam();
  const removeSearchParam = useRemoveSearchParam();
  const [bounds, setBounds] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSearchSubmit = data => {
    setIsSubmitted(true);
    const { startDate, endDate, sport } = data;
    const { selectedPlace } = data.location || {};
    const regex = /\d/;
    const city =
      selectedPlace &&
      Object.keys(selectedPlace).length > 0 &&
      !regex.test(selectedPlace.address.split(',')[0])
        ? selectedPlace.address.split(',')[0]
        : !!data.location && !!data.location.search
        ? data.location.search.split(',')[0]
        : null;
    const bounds =
      selectedPlace &&
      `${selectedPlace.bounds.ne.lat},${selectedPlace.bounds.ne.lng},${selectedPlace.bounds.sw.lat},${selectedPlace.bounds.sw.lng}`;

    if (startDate) {
      setStartDate(new Date(startDate).toISOString());
    } else {
      setStartDate(null);
    }
    if (endDate) {
      setEndDate(new Date(endDate).toISOString());
    } else {
      setEndDate(null);
    }
    if (bounds && city) {
      setBounds(bounds);
    } else if (!city) {
      setBounds('55.98904748, 24.05383751, 47.89042442, 14.14416954');
    }

    replacePathParam({
      sport:
        sport && !!sport.length
          ? removePolishCharacters(sportsConfig[sport].name.toLowerCase())
          : 'wszystkie-sporty',
      city: city ? removePolishCharacters(city.toLowerCase()) : 'cala-polska',
    });
  };

  useEffect(() => {
    if (isSubmitted && bounds) updateQueryParams('bounds', bounds);
  }, [bounds]);
  useEffect(() => {
    if (isSubmitted && startDate) {
      updateQueryParams('start', startDate);
    } else if (isSubmitted && !startDate) {
      removeSearchParam(['start']);
    }
  }, [startDate]);
  useEffect(() => {
    if (isSubmitted && endDate) {
      updateQueryParams('end', endDate);
    } else if (isSubmitted && !endDate) {
      removeSearchParam(['end']);
    }
  }, [endDate]);

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search);
  //   const bounds = searchParams.get('bounds');
  //   if (bounds) updateQueryParams('bounds', bounds);
  //   if (city || sport)
  //     replacePathParam({
  //       city: city || 'cala-poslka',
  //       sport: sport || 'wszystkie-sporty',
  //     });
  // }, []);

  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {/* {search} */}
      <ul
        className={classNames(css.navContrainer, css.mobileHidden)}
        style={{ justifyContent: 'center', gap: '24px', margin: '0' }}
      >
        {!location.pathname.includes('/s/') ? (
          <>
            <li>
              <Link
                className={css.navItemLink}
                to={
                  '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska/?pub_event_type=activity%2Ctraining'
                }
              >
                Zajęcia i treningi
              </Link>
            </li>
            <li>
              <Link
                className={css.navItemLink}
                to={'/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska/?pub_event_type=match'}
              >
                Mecze
              </Link>
            </li>
            <li>
              <Link
                className={css.navItemLink}
                to={
                  '/s/wydarzenia-sportowe/wszystkie-sporty/cala-polska/?pub_event_type=competition'
                }
              >
                Zawody
              </Link>
            </li>
          </>
        ) : (
          <SimpleSearchForm
            form="TopbarSearchFormDesktop"
            onSubmit={handleSearchSubmit}
            initialValues={{
              eventTypes: 'all',
              sport: [],
            }}
          />
        )}
      </ul>
      <div
        className={css.navContrainer}
        style={{ justifyContent: 'flex-end', paddingLeft: '32px', flex: '0' }}
      >
        <NamedLink className={classNames(css.buttonNavbar, css.mobileHidden)} name="NewListingPage">
          <FormattedMessage id="TopbarDesktop.createListing" />
        </NamedLink>
        {/* <NamedLink className={css.navItemLink} name="">
          <FormattedMessage id="TopbarDesktop.disciplines" />
        </NamedLink> */}
        {/* <a className={css.navItemLink} href="https://partner.decathlon.pl">
          <FormattedMessage id="TopbarDesktop.partnership" />
        </a> */}
        {/* <a
          href="https://decathlon.pl?utm_source=decathlon_go&utm_medium=referral&utm_campaign=top_menu"
          className={classNames(css.navItemLink, css.externalLink)}
          target="_blank"
        >
          <IconShoppingBag className={css.iconBag} />
          <FormattedMessage id="TopbarDesktop.shop" />
        </a> */}
        {calendarLink}
        {inboxLink}
        {profileMenu}
        {loginLink}
      </div>
    </nav>
  );
};

const { bool, func, object, number, string } = PropTypes;

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default connect(null, { openMissingInfoModal })(TopbarDesktop);
