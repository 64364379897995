import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const IconInfoSecondary = (props) => {
    const { className } = props;

    return (
        // <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        //     <path d="M11.2903 6.45161C11.2903 5.73998 10.7116 5.16129 10 5.16129C9.28837 5.16129 8.70968 5.73998 8.70968 6.45161C8.70968 7.16324 9.28837 7.74194 10 7.74194C10.7116 7.74194 11.2903 7.16324 11.2903 6.45161ZM9.35484 6.45161C9.35484 6.0958 9.64418 5.80645 10 5.80645C10.3558 5.80645 10.6452 6.0958 10.6452 6.45161C10.6452 6.80743 10.3558 7.09677 10 7.09677C9.64418 7.09677 9.35484 6.80743 9.35484 6.45161ZM8.3871 12.9032V14.8387H11.6129V12.9032H10.9677V8.3871H8.3871V10.3226H9.03226V12.9032H8.3871ZM9.03226 9.67742V9.03226H10.3226V13.5484H10.9677V14.1935H9.03226V13.5484H9.67742V9.67742H9.03226ZM10 20C15.5138 20 20 15.5138 20 10C20 4.4862 15.5138 0 10 0C4.4862 0 0 4.4862 0 10C0 15.5138 4.4862 20 10 20ZM10 0.645161C15.1585 0.645161 19.3548 4.84154 19.3548 10C19.3548 15.1585 15.1585 19.3548 10 19.3548C4.84154 19.3548 0.645161 15.1585 0.645161 10C0.645161 4.84154 4.84154 0.645161 10 0.645161ZM5.69352 3.96028L5.31864 3.43514C6.69229 2.45385 8.31102 1.93548 10 1.93548C14.4468 1.93548 18.0645 5.55318 18.0645 10C18.0645 11.689 17.5462 13.3077 16.5649 14.6814L16.0397 14.3065C16.9423 13.0429 17.4194 11.5535 17.4194 10C17.4194 5.90899 14.091 2.58065 10 2.58065C8.44648 2.58065 6.95706 3.05774 5.69352 3.96028ZM1.93548 10C1.93548 8.31102 2.45416 6.69229 3.43545 5.31864L3.96028 5.69352C3.05774 6.95706 2.58065 8.44616 2.58065 10C2.58065 14.091 5.90899 17.4194 10 17.4194C11.5535 17.4194 13.0429 16.9423 14.3065 16.0397L14.6814 16.5649C13.3077 17.5462 11.689 18.0645 10 18.0645C5.55318 18.0645 1.93548 14.4468 1.93548 10Z" fill="#8996A2" />
        // </svg>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9998 3.5498C7.333 3.5498 3.5498 7.333 3.5498 11.9998C3.5498 16.6666 7.333 20.4498 11.9998 20.4498C16.6666 20.4498 20.4498 16.6666 20.4498 11.9998C20.4498 7.333 16.6666 3.5498 11.9998 3.5498ZM2.0498 11.9998C2.0498 6.50457 6.50457 2.0498 11.9998 2.0498C17.495 2.0498 21.9498 6.50457 21.9498 11.9998C21.9498 17.495 17.495 21.9498 11.9998 21.9498C6.50457 21.9498 2.0498 17.495 2.0498 11.9998ZM8.65201 9.94228C8.78095 8.26604 10.1935 6.8498 11.9998 6.8498C13.814 6.8498 15.3498 8.38559 15.3498 10.1998C15.3498 11.0217 14.9537 11.9066 14.3301 12.5301C14.0407 12.8196 13.6998 13.0303 13.4394 13.1912C13.4074 13.211 13.3766 13.23 13.3473 13.2483C13.0535 13.4319 12.8434 13.579 12.676 13.7799L12.6542 13.8061L12.6301 13.8301C12.4302 14.0301 12.3498 14.295 12.3498 14.6998H10.8498C10.8498 14.1127 10.9662 13.3896 11.5452 12.7941C11.8729 12.4098 12.2527 12.1636 12.5523 11.9763C12.5825 11.9574 12.6117 11.9393 12.6399 11.9217C12.9223 11.7457 13.1104 11.6285 13.2695 11.4695C13.646 11.093 13.8498 10.5779 13.8498 10.1998C13.8498 9.21402 12.9856 8.3498 11.9998 8.3498C11.0155 8.3498 10.2336 9.1188 10.1498 10.0311V10.3989L8.6498 10.3998V9.971L8.65201 9.94228Z" fill="#101010" />
            <path d="M11.5998 17.4998C12.0969 17.4998 12.4998 17.0969 12.4998 16.5998C12.4998 16.1027 12.0969 15.6998 11.5998 15.6998C11.1028 15.6998 10.6998 16.1027 10.6998 16.5998C10.6998 17.0969 11.1028 17.4998 11.5998 17.4998Z" fill="#101010" />
        </svg>


    );
};

const { string } = PropTypes;

IconInfoSecondary.defaultProps = {
    className: null,
    // rootClassName: null,
};

IconInfoSecondary.propTypes = {
    className: string,
    // rootClassName: string,
};

export default IconInfoSecondary;
