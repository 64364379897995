import React from 'react';
import {
  FieldCheckbox,
  FieldRadioButton,
  FieldSelect,
  FieldTextInput
} from '../../components';
import { object } from 'prop-types';
import * as validators from '../../util/validators';
import css from './StripePaymentForm.css';

const AdditionalFields = props => {
  const { additionalFields, intl } = props;

  if (!additionalFields || !additionalFields.fields || !additionalFields.fields.length) return null;
  
  const additionalFieldsHeading = intl.formatMessage({
    id: 'StripePaymentForm.additionalFields',
  });
  const requiredMessage = intl.formatMessage({
    id: 'StripePaymentForm.validateRequired',
  });
  const checkboxValue = intl.formatMessage({
    id: 'StripePaymentForm.checkboxValue',
  });
  const requiredField = intl.formatMessage({
    id: 'StripePaymentForm.requiredField',
  });
  const optionalField = intl.formatMessage({
    id: 'StripePaymentForm.optionalField',
  });
  const selectPlaceholder = intl.formatMessage({ id: 'StripePaymentForm.selectPlaceholder' });
  const requiredValidation = validators.required(requiredMessage);
  const { composeValidators,  nonEmptyArray } = validators;

  return (
    <>
      <h3 className={css.messageHeading}>{additionalFieldsHeading}</h3>

      {additionalFields.fields.map((field, index) => {
        const { label, name, options, placeholder, required, type } = field;
        const validation = required ? requiredValidation : null;
        const optionalLabel = `${label} • ${required ? requiredField : optionalField}`;

        if (type === 'select') return (
          <FieldSelect
            className={css.message}
            id={name}
            key={name}
            label={optionalLabel}
            name={`add.${name}`}
            validate={validation}
          >
            <option disabled={required} value="">
              {selectPlaceholder}
            </option>
            {options.map((option, i) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </FieldSelect>
        );

        if (type === 'checkbox') return (
          <div key={index}>
            <p className={css.label}>{optionalLabel}</p>
            <FieldCheckbox
              id={name}
              name={`add.${name}`}
              label={placeholder}
              value={checkboxValue}
              validate={required ? composeValidators(nonEmptyArray(requiredMessage)) : null}
            />
          </div>
        );

        if (type === 'radio') return (
          <div key={index}>
            <p className={css.label}>{optionalLabel}</p>
            <div className={css.radioContainer}>
              {
                options.map((option, i) => (
                  <FieldRadioButton
                    id={option}
                    key={i}
                    label={option}
                    name={`add.${name}`}
                    validate={validation}
                    value={option}
                  />
                ))
              }
            </div>
          </div>
        );

        return (
          <FieldTextInput
            className={css.message}
            id={name}
            key={name}
            label={optionalLabel}
            name={`add.${name}`}
            placeholder={placeholder}
            type="text"
            validate={validation}
          />
        )
      })}
    </>
  );
};

AdditionalFields.defaultProps = {
  additionalFields: null
};

AdditionalFields.propTypes = {
  additionalFields: object,
  intl: object.isRequired
};

export default AdditionalFields;