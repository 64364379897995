import { useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useRemoveSearchParams = () => {
  const location = useLocation();
  const history = useHistory();

  const removeParams = useCallback(
    paramsToRemove => {
      const searchParams = new URLSearchParams(location.search);

      paramsToRemove.forEach(param => {
        if (searchParams.has(param)) {
          searchParams.delete(param);
        }
      });

      const newSearch = searchParams.toString();
      const newLocation = `${location.pathname}${newSearch ? `?${newSearch}` : ''}`;
      history.replace(newLocation);
    },
    [location, history]
  );

  return removeParams;
};

export default useRemoveSearchParams;
