// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/AcademyPage/SET_INITIAL_STATE';

export const QUERY_POSTS_REQUEST = 'app/AcademyPage/QUERY_POSTS_REQUEST';
export const QUERY_POSTS_LOADING = 'app/AcademyPage/QUERY_POSTS_LOADING';
export const QUERY_POSTS_SUCCESS = 'app/AcademyPage/QUERY_POSTS_SUCCESS';
export const QUERY_POSTS_ERROR = 'app/AcademyPage/QUERY_POSTS_ERROR';

// ================ Reducer ================ //

const initialState = {
  posts: [],
  queryPostsError: null,
  isLoaded: false
};

export default function postsPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };

    case QUERY_POSTS_REQUEST:
      return { ...state, queryPostsError: null, isLoaded: true};
    case QUERY_POSTS_LOADING:
      return { ...state, isLoaded: false}
    case QUERY_POSTS_SUCCESS:
      return { ...state, posts: payload, isLoaded: true };
    case QUERY_POSTS_ERROR:
      return { ...state, posts: [], queryPostError: payload, isLoaded: true };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const queryPostsRequest = () => ({
  type: QUERY_POSTS_REQUEST,
});

export const queryPostsLoading = () => ({
  type: QUERY_POSTS_LOADING,
});

export const queryPostsSuccess = posts => ({
  type: QUERY_POSTS_SUCCESS,
  payload: posts,
});

export const queryPostsError = e => ({
  type: QUERY_POSTS_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const queryPosts = (skip, limit, query) => (dispatch, getState, sdk) => {
  const contentfulUrl = `https://cdn.contentful.com/spaces/jrml4xfrfnmx/environments/master/entries?access_token=Clc-Qo9iyiXSMpr5BEOtuFEL_lBw9jIKubvwz2EoxoA&content_type=blogPost&order=-fields.publishingDate&include=0&skip=${skip}&limit=${limit}&${query ? `query=${query}` : ''}`;
  dispatch(queryPostsLoading())

  if(typeof window !== 'undefined' && window.fetch) {
    return fetch(contentfulUrl) 
      .then(response => {
        return response.json();
      })
      .then(response => {
        dispatch(queryPostsSuccess(response));
      })
      .catch(e => dispatch(queryPostsError(e)));
  } else {
    return sdk.injectedNodeAxios.get(contentfulUrl)
      .then(response => {
          return response.data;
      })
      .then(response => {
        dispatch(queryPostsSuccess(response));
      })
      .catch(e => dispatch(queryPostsError(e)));
  }
};

export const loadData = (skip, limit, query) => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  // dispatch(setInitialState());

  return Promise.all([
    dispatch(queryPosts(skip, limit, query)),
  ]);
};