import React, { useState, useEffect } from 'react';

import { useMutation, useQuery } from 'react-query';

import { apiSdk } from '../../../queries/api.js';

import MessagesView from '../../TransactionPage/MessagesView.js';

const MessageForm = ({
  listingId,
  isMessageFormOpen,
  setIsMessageFormOpen,
  isMy,
  provider,
  messageChatId,
}) => {
  const [chatId, setChatId] = useState(messageChatId);
  const [isChatCreated, setIsChatCreated] = useState(false);

  const params = isMy
    ? {
        listingId,
        type: 'listing-customer',
        providerId: 'me',
        include: ['customer', 'provider'],
      }
    : {
        listingId,
        type: 'listing-customer',
        customerId: 'me',
        include: ['customer', 'provider'],
      };

  const { data: chat, isSuccess: isSuccessChat } = useQuery(
    ['chat.query', listingId, isChatCreated],
    () => apiSdk.chat.queryChats(params)
  );

  const users = isSuccessChat ? chat.included.filter(item => item.type === 'user') : [];
  const providerId =
    isSuccessChat &&
    chatId &&
    chat.data.length &&
    chat.data.filter(({ id }) => id.uuid === chatId) &&
    chat.data.filter(({ id }) => id.uuid === chatId)[0].relationships.provider.data.id.uuid;
  const customerId =
    isSuccessChat &&
    chatId &&
    chat.data.length &&
    chat.data.filter(({ id }) => id.uuid === chatId) &&
    chat.data.filter(({ id }) => id.uuid === chatId)[0].relationships.customer.data.id.uuid;

  const mutationCreateChat = useMutation(() => apiSdk.chat.create(listingId), {
    onSuccess: data => {
      setChatId(data.data.id.uuid);
      setIsChatCreated(true);
    },
  });

  useEffect(() => {
    if (isMessageFormOpen && !chatId) {
      mutationCreateChat.mutate(listingId);
    }
  }, [isMessageFormOpen, chatId]);

  return (
    chatId &&
    providerId &&
    customerId && (
      <div style={{ marginTop: '30px' }}>
        <MessagesView
          users={users}
          providerId={providerId}
          customerId={customerId}
          chatId={chatId}
          isMessageEditorOpen={isMessageFormOpen}
        />
      </div>
    )
  );
};

export default MessageForm;
