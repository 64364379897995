import React, { useState } from 'react';
import css from './TextAreaField.css';
import Buttons from '../Buttons/Buttons';

import { createFormElement } from '../utils';

const TextAreaField = ({
  setSelectedTypeOfQuestion,
  setIsOpenAddQuestion,
  setStep,
  setFormElements,
  elementData,
}) => {
  const [title, setTitle] = useState((elementData && elementData.title) || '');
  const [description, setDescription] = useState(
    (elementData && elementData.description) || ''
  );
  const [invalidValueTitle, setInvalidValueTitle] = useState(null);
  const [invalidValueTitleDescription, setInvalidValueDescription] = useState(
    null
  );
  const [isValidateError, setIsValidateError] = useState(false);

  const handleTitleonChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setTitle(e.target.value);
      setInvalidValueTitle('pole wymagane');
    } else if (e.target.value.length > 50) {
      setInvalidValueTitle('maksymalna długość to 50 znaków');
    } else {
      setTitle(e.target.value);
    }
  };

  const handleDescriptionChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setDescription(e.target.value);
      setInvalidValueDescription('pole wymagane');
    } else if (e.target.value.length > 50) {
      setInvalidValueDescription('maksymalna długość to 50 znaków');
    } else {
      setDescription(e.target.value);
    }
  };

  const hadnleCreateElement = () => {

    const newelementData = {
      title,
      description,
      selectedTypeOfQuestion: 'textareaField',
    }

    const element = createFormElement(newelementData)

    const elementToSave = {
      id: title,
      elementData: newelementData,
      element
    };

    if (title && description) {
      setIsValidateError(false);
      setFormElements((prev) => {
        if (elementData && elementData.title) {
          let elementToUpdate = prev.findIndex(
            (el) => el.id === elementData.title
          );
          const copy = prev;
          copy[elementToUpdate] = elementToSave;
          return copy;
        } else {
          return [...prev, elementToSave];
        }
      });
      setIsOpenAddQuestion(false);
      setSelectedTypeOfQuestion(null);
      setStep(null);
    } else {
      setIsValidateError(true);
    }
  };

  return (
    <>
      <h2>Dostosuj pole tekstowe</h2>
      <label>
        Nadaj tytuł (przykład: Nazwa drużyny)
        <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
        <input type="text" value={title} onChange={handleTitleonChange}></input>
        {invalidValueTitle && (
          <span className={css.invalidmessage}>{invalidValueTitle}</span>
        )}
      </label>
      <label>
        Podaj opis (przykład: Podaj nazwę Swojej drużyny)
        <span style={{ color: 'red', verticalAlign: 'super' }}>*</span>
        <input
          type="text"
          value={description}
          onChange={handleDescriptionChange}
        ></input>
        {invalidValueTitleDescription && (
          <span className={css.invalidmessage}>
            {invalidValueTitleDescription}
          </span>
        )}
      </label>
      {isValidateError && (
        <p style={{ color: 'red' }}>Uzupełnij wymagane pola</p>
      )}
      <Buttons
        setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
        setIsOpenAddQuestion={setIsOpenAddQuestion}
        setStep={setStep}
        hadnleCreateSelectElement={hadnleCreateElement}
        isEdited={!!elementData}
      />
    </>
  );
};

export default TextAreaField;
