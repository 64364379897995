import React from 'react';
import EventTitle from './components/EventTitle';
import EventPrice from './components/EventPrice';
import EventType from './components/EventType';
import EventSport from './components/EventSport';
import EventDescription from './components/EventDescription';
import EventPictures from './components/EventPictures';
import EventDate from './components/EventDate';
import EventArea from './components/EventArea';

export const getComponents = (eventType, register, errors, control, setValue, watch) =>
  eventType === 'Wydarzenie'
    ? [
        <EventTitle register={register} errors={errors} />,
        <EventPrice
          register={register}
          errors={errors}
          setValue={setValue}
          watch={watch}
          control={control}
        />,
        <EventType register={register} errors={errors} />,
        <EventSport register={register} errors={errors} />,
        <EventDescription register={register} errors={errors} />,
        <EventPictures register={register} errors={errors} setValue={setValue} />,
      ]
    : [
        <EventTitle register={register} errors={errors} />,
        <EventDate register={register} errors={errors} control={control} />,
        <EventSport register={register} errors={errors} />,
        <EventDescription register={register} errors={errors} />,
        <EventArea register={register} errors={errors} />,
        <EventPictures register={register} errors={errors} setValue={setValue} />,
      ];

// import React from "react";
// import EventTitle from "./components/EventTitle";
// import EventPrice from "./components/EventPrice";
// import EventType from "./components/EventType";
// import EventSport from "./components/EventSport";
// import EventDescription from "./components/EventDescription";
// import EventPictures from "./components/EventPictures";
// import EventDate from "./components/EventDate";
// import EventArea from "./components/EventArea";

// export const getComponents = (eventType, register, errors, control, setValue, watch) => eventType === 'Wydarzenie' ? (
//     [
//         <EventTitle register={register} errors={errors} />,
//         <EventPrice register={register} errors={errors} setValue={setValue} watch={watch} control={control} />,
//         <EventType register={register} errors={errors} />,
//         <EventSport register={register} errors={errors} />,
//         <EventDescription register={register} errors={errors} />,
//         <EventPictures register={register} errors={errors} setValue={setValue} />
//     ]
// ) : (
//     [
//         <div>Wkrótce dostępne :)</div>
//         // <EventTitle register={register} errors={errors} />,
//         // <EventDate register={register} errors={errors} control={control} />,
//         // <EventSport register={register} errors={errors} />,
//         // <EventDescription register={register} errors={errors} />,
//         // <EventArea register={register} errors={errors} />,
//         // <EventPictures register={register} errors={errors} setValue={setValue} />
//     ]
// )
