import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFormContext } from '../context';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import SectorsPriceModal from './SectorsPriceModal';
import Input from './components/Input';
import css from './price.css';
import { updateEvent } from '../api';
import RequestStatusModal from '../RequestStatusModal';
import { getCategories } from '../../../util/api';

const PriceForm = () => {
  const { updateFormData, isLastStep, formData, listingId, type } = useFormContext();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    clearErrors,
  } = useForm();

  const [categories, setCategories] = useState([]);
  const [pricing, setPricing] = useState(formData.sectorsPrice.data || []);
  const [isStadiumMapOpen, setIsStadiumMapOpen] = useState(false);
  const [isAllSectorsSelected, setIsAllSectorsSelected] = useState(false);

  const handlePriceChange = (e, category, priceType) => {
    e.persist();
    setPricing(prev => {
      return [
        ...prev.filter(item => item.category !== category.key),
        {
          category: category.key,
          ticketTypes: [
            {
              ticketType: priceType,
              price: Number(e.target.value),
              label: priceType === 'standard' ? 'Bilet normalny' : 'Bilet ulgowy',
            },
            {
              ticketType: priceType === 'standard' ? 'reduced' : 'standard',
              price: prev
                .filter(item => item.category === category.key)[0]
                .ticketTypes.filter(
                  ({ ticketType }) =>
                    ticketType === (priceType === 'standard' ? 'reduced' : 'standard')
                )[0].price,
              label: priceType === 'standard' ? 'Bilet ulgowy' : 'Bilet normalny',
            },
          ],
        },
      ];
    });
  };

  const listingTitle = formData.generalInfo.eventTitle;

  const [updateSucces, setUpdateSucces] = React.useState(null);

  const onSubmit = async () => {
    if (!isAllSectorsSelected) {
      setError('sectorsPrice', {
        type: 'manual',
        message: 'Wszystkie kategorie muszą mieć ustawioną cenę',
      });
      return;
    }
    updateFormData({ data: pricing }, 'sectorsPrice');
    const updateStatus = await updateEvent({
      id: listingId,
      publicData: {
        sectorsPrice: pricing,
      },
    });
    updateStatus === 'success' ? setUpdateSucces('success') : setUpdateSucces('error');
  };

  const submitButtonLabel = useMemo(
    () => (isLastStep ? 'Opublikuj' : type === 'edit' ? 'Zapisz' : 'Dalej'),
    [isLastStep, type]
  );

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const data = await getCategories(formData.generalInfo.stadium.split('_')[0]);
        setCategories(data);
        if (!formData.sectorsPrice.data)
          setPricing(
            data.map(category => ({
              category: category.key,
              ticketTypes: [
                { ticketType: 'standard', price: 0, label: 'Bilet normalny' },
                { ticketType: 'reduced', price: 0, label: 'Bilet ulgowy' },
              ],
            }))
          );
      } catch (error) {
        console.error(error.message);
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    if (pricing.length) {
      const isAllSectorsSelected = pricing.every(item =>
        item.ticketTypes
          .filter(({ ticketType }) => ticketType === 'standard')
          .every(({ price }) => Number(price) > 0)
      );
      setIsAllSectorsSelected(isAllSectorsSelected);

      if (isAllSectorsSelected) {
        clearErrors('sectorsPrice');
      }
    }
  }, [pricing]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {type === 'edit' && updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => setUpdateSucces(null)}
          listingId={listingId}
          slug={listingTitle}
        />
      )}
      <div className={css.container}>
        <h1 className={css.header}>
          <LeftArrowIcon />
          Wybór ceny
        </h1>

        <button
          className={css.map_submitButton}
          type="button"
          onClick={() => setIsStadiumMapOpen(prev => !prev)}
        >
          Pokaż plan obiektu
        </button>

        {isStadiumMapOpen && (
          <SectorsPriceModal
            pricing={pricing}
            handleCloseStadiumMap={() => setIsStadiumMapOpen(false)}
          />
        )}
        {!!categories.length && listingId !== '00000000-0000-0000-0000-000000000000' && (
          <ul style={{ listStyle: 'none', width: 'fit-content' }}>
            {categories.map(category => (
              <li key={category.key} className={css.category}>
                <p className={css.category_paragraph}>
                  <div
                    className={css.category_color}
                    style={{
                      background: category.color,
                    }}
                  ></div>
                  {category.label}
                </p>
                {!!pricing.length && (
                  <div className={css.category_buttons}>
                    <Input
                      label="Bilet normalny"
                      value={calculatePrice(pricing, category, 'standard')}
                      onChange={e => handlePriceChange(e, category, 'standard')}
                      type="number"
                    />
                    <Input
                      label="Bilet ulgowy"
                      value={calculatePrice(pricing, category, 'reduced')}
                      onChange={e => handlePriceChange(e, category, 'reduced')}
                      type="number"
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div>{errors.sectorsPrice && <p className={css.error}>{errors.sectorsPrice.message}</p>}</div>
      <div className={css.buttonContainer}>
        <button type="submit" className={css.map_submitButton}>
          {submitButtonLabel}
        </button>
      </div>
    </form>
  );
};

export default React.memo(PriceForm);

const calculatePrice = (pricing, category, priceType) => {
  return pricing
    .filter(item => item.category === category.key)[0]
    .ticketTypes.filter(({ ticketType }) => ticketType === priceType)[0].price;
};
