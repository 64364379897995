import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { compose } from 'redux';
import { ReactComponent as PinIcon } from '../../assets/searchPage/pin.svg';
import { LayoutWrapperFooter, Page } from '../../components';
import FooterV2 from '../../components/Footer/FooterV2';
import MapSearchPage from '../../components/Map/MapSearchPage';
import { TopbarContainer } from '../../containers';
import { useIsMobile } from '../../util/hooks/useIsMobile';
import { createSearchResultSchema } from '../SportLocationPage/SportLocationPage.helpers';
import css from './SearchPage.css';
import ButtonFilter from './components/ButttonFilter/ButtonFilter';
import Dropdown from './components/DropdownFilter/DropdownFilter';
import EventCard from './components/EventCard/EventCard';
import Header from './components/Header/Header';
import PriceDropdown from './components/PriceFilter/PriceFilter';
import { filtersData } from './constants';
import { useGetData } from './hooks/useGetData';
import { useUrlParams } from './hooks/useUrlParams';

const SearchPage = ({ intl }) => {
  const params = useUrlParams();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [isMapOpen, setIsMapOpen] = useState(false);

  const {
    isSuccess,
    eventsData,
    hasMore,
    isFetching,
    loadMore,
    totalItems,
    rawListings,
  } = useGetData();
  const searchResultSchema =
    isSuccess && createSearchResultSchema(rawListings.data, history.location.pathnane, intl);

  const toggleMap = () => {
    setIsMapOpen(prev => !prev);
  };

  useEffect(() => {
    if (isMapOpen && window.innerWidth < 960) {
      document.documentElement.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
    }

    return () => {
      document.documentElement.classList.remove('no-scroll');
    };
  }, [isMapOpen]);

  return (
    <Page
      scrollingDisabled={false}
      description={searchResultSchema ? searchResultSchema.description : ''}
      title={searchResultSchema ? searchResultSchema.title : ''}
      schema={searchResultSchema ? searchResultSchema.schema : {}}
    >
      <TopbarContainer
        className={css.topbar}
        currentPage="SearchPage"
        currentSearchParams={'urlQueryParams'}
      />
      {!isFetching && (
        <Header city={params.city} eventsAmount={totalItems} setIsMapOpen={setIsMapOpen} />
      )}
      <div style={{ position: 'relative' }}>
        <div className={css.mobileOverflow}>
          <div className={css.filtersContainer}>
            <button onClick={toggleMap} className={css.showMap}>
              <PinIcon />
            </button>

            {filtersData.map(filterData => (
              <Dropdown {...filterData} params={params} />
            ))}
            <PriceDropdown params={params} />
            <span className={css.divider}></span>

            <ButtonFilter
              content={'Darmowe wydarzenia'}
              type={'free'}
              params={[
                { type: 'min_price', value: 0 },
                { type: 'max_price', value: 0 },
              ]}
              defaultChecked={params.min_price === '0' && params.max_price === '0'}
              unCheckParams={params.min_price === '0' && params.max_price === '0' ? true : false}
            />

            <ButtonFilter
              content={'Wydarzenia dla dzieci'}
              type={'children'}
              params={[
                {
                  type: 'pub_gender',
                  value:
                    params.pub_gender && decodeURIComponent(params.pub_gender) === 'female,male'
                      ? encodeURIComponent(['children', 'female', 'male'].join(','))
                      : 'children',
                },
              ]}
              unCheckParams={
                params.pub_gender &&
                decodeURIComponent(params.pub_gender) === 'children,female,male'
                  ? encodeURIComponent(['female', 'male'].join(','))
                  : null
              }
              defaultChecked={params.pub_gender && params.pub_gender.includes('children')}
            />
            <ButtonFilter
              content={'Wydarzenia dla dorosłych'}
              type={'adults'}
              params={[
                {
                  type: 'pub_gender',
                  value:
                    params.pub_gender && params.pub_gender.includes('children')
                      ? encodeURIComponent(['children', 'female', 'male'].join(','))
                      : encodeURIComponent(['female', 'male'].join(',')),
                },
              ]}
              unCheckParams={
                params.pub_gender &&
                decodeURIComponent(params.pub_gender) === 'children,female,male'
                  ? 'children'
                  : null
              }
              defaultChecked={
                params.pub_gender && decodeURIComponent(params.pub_gender) === 'female,male'
              }
            />
          </div>
        </div>
      </div>
      {/* {totalItems === 0 && <NearestEvents />} */}
      <div className={css.content}>
        <div className={isMapOpen ? css.eventCardsContainerSplit : css.eventCardsContainer}>
          {eventsData ? (
            eventsData.map(eventData => <EventCard key={eventData.id} eventData={eventData} />)
          ) : (
            <p>Wczytywanie wydarzeń...</p>
          )}
        </div>
        {isMapOpen && (
          <div className={css.mapContainer}>
            <MapSearchPage
              markers={eventsData.map(item => ({
                id: item.id,
                position: item.geolocation,
                label: '',
                info: <EventCard eventData={item} withoutPicture={true} />,
              }))}
              initialBounds={
                params.bounds
                  ? {
                      northEast: {
                        lat: params.bounds.split(',')[0],
                        lng: params.bounds.split(',')[1],
                      },

                      southWest: {
                        lat: params.bounds.split(',')[2],
                        lng: params.bounds.split(',')[3],
                      },
                    }
                  : {
                      northEast: {
                        lat: 55.722939178840285,
                        lng: 23.888423016126506,
                      },

                      southWest: {
                        lat: 47.92610378536748,
                        lng: 14.703852703626508,
                      },
                    }
              }
            />
          </div>
        )}
        {isMobile && isMapOpen && (
          <div className={css.mobileCloseMapButtonContainer}>
            <button onClick={() => setIsMapOpen(false)}>Wróć do listy</button>
          </div>
        )}
      </div>

      {hasMore && (
        <button onClick={loadMore} className={css.showMore}>
          Pokaż więcej
        </button>
      )}
      <LayoutWrapperFooter>
        <FooterV2 />
      </LayoutWrapperFooter>
    </Page>
  );
};

export default compose(injectIntl)(SearchPage);
