import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as LeftArrowIcon } from '../../../assets/addEventForm/leftArrowIcon.svg';
import { useForm } from 'react-hook-form';
import { useFormContext } from '../context';
import FieldsChoose from './components/FieldsChoose';

import CheckboxField from './components/CheckboxField/CheckboxField';
import SelectField from './components/SelectField/SelectField';
import TextAreaField from './components/TextAreaField/TextAreaField';
import QuestionsList from './components/QuestionsList';
import { createFormElement } from './components/utils';

import css from './styles.css';
import { updateEvent } from '../api';
import RequestStatusModal from '../RequestStatusModal';

const AdditionalForm = () => {
  const { updateFormData, isLastStep, formData, listingId, type } = useFormContext();
  const [updateSucces, setUpdateSucces] = React.useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
  } = useForm({
    values: formData.additiionalForm,
  });

  const listingTitle = formData.generalInfo.eventTitle;

  const [isOpenAddQuestion, setIsOpenAddQuestion] = useState(false);
  const [selectedTypeOfQuestion, setSelectedTypeOfQuestion] = useState(null);
  const [step, setStep] = useState(null); //editField
  // const [formElements, setFormElements] = useState([]); //dorobic jak ponizej wczytywanie wartosci poczatkowych
  const [formElements, setFormElements] = useState(
    formData.additiionalForm &&
      formData.additiionalForm.data &&
      formData.additiionalForm.data.length
      ? formData.additiionalForm.data.map(item => {
          return {
            id: item.title,
            elementData: item,
            element: createFormElement(item),
          };
        })
      : []
  );
  const [elementData, setElementData] = useState(null);

  const handleEditQuestion = (elementData, e) => {
    e.preventDefault();
    setStep('editField');
    setElementData(elementData);
    setSelectedTypeOfQuestion(elementData.selectedTypeOfQuestion);
  };

  useEffect(() => {
    setValue(
      'data',
      formElements.map(item => item.elementData)
    );
  }, [formElements]);

  const onSubmit = async data => {
    // Update the form data and go to the next step
    console.log(data);
    updateFormData(data, 'additiionalForm');

    const updateStatus = await updateEvent({
      id: listingId,
      publicData: {
        additionalForm: data.data,
      },
      state: 'published',
    });
    updateStatus === 'success' ? setUpdateSucces('success') : setUpdateSucces('error');

    if (type !== 'edit') {
      window.location.href = `/l/${listingTitle}/${listingId}`;
    }
  };

  const submitButtonLabel = useMemo(
    () => (type === 'edit' ? 'Zapisz' : isLastStep ? 'Opublikuj' : 'Dalej'),
    [isLastStep, type]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {type === 'edit' && updateSucces && (
        <RequestStatusModal
          status={updateSucces}
          resetStatus={() => setUpdateSucces(null)}
          listingId={listingId}
          slug={listingTitle}
        />
      )}
      <div className={css.container}>
        <h1 className={css.header}>
          <LeftArrowIcon />
          Formularz pytań dodatkowych
        </h1>
        <p>Zadaj uczestnikom wydarzenia dodatkowe pytania (opcjonalne)</p>

        <FieldsChoose
          selectedTypeOfQuestion={selectedTypeOfQuestion}
          setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
          setStep={setStep}
          isOpenAddQuestion={isOpenAddQuestion}
          setIsOpenAddQuestion={setIsOpenAddQuestion}
        />

        <QuestionsList
          formElements={formElements}
          setFormElements={setFormElements}
          handleEditQuestion={handleEditQuestion}
        />

        {step === 'editField' && selectedTypeOfQuestion === 'textareaField' && (
          <TextAreaField
            setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
            setIsOpenAddQuestion={setIsOpenAddQuestion}
            setStep={setStep}
            setFormElements={setFormElements}
            elementData={elementData}
          />
        )}

        {step === 'editField' && selectedTypeOfQuestion === 'selectField' && (
          <SelectField
            setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
            setIsOpenAddQuestion={setIsOpenAddQuestion}
            setStep={setStep}
            setFormElements={setFormElements}
            elementData={elementData}
          />
        )}
        {step === 'editField' && selectedTypeOfQuestion === 'checkboxField' && (
          <CheckboxField
            setSelectedTypeOfQuestion={setSelectedTypeOfQuestion}
            setIsOpenAddQuestion={setIsOpenAddQuestion}
            setStep={setStep}
            setFormElements={setFormElements}
            elementData={elementData}
          />
        )}
      </div>
      <div className={css.buttonContainer}>
        <button type="submit" className={css.submitClasses}>
          {submitButtonLabel}
        </button>
      </div>
    </form>
  );
};

export default AdditionalForm;
