import React from 'react';
import { node, string } from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';
import css from './FieldCheckboxIconWithCheck.css';

const IconCheckbox = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path className={css.box} fill="none" fillRule="nonzero" stroke="#6D8098" strokeWidth="2" d="M15.833 2.833H4.167a1.5 1.5 0 0 0-1.5 1.5V16a1.5 1.5 0 0 0 1.5 1.5h11.666a1.5 1.5 0 0 0 1.5-1.5V4.333a1.5 1.5 0 0 0-1.5-1.5zm0-.333c1.013 0 1.834.82 1.834 1.833V16c0 1.013-.821 1.833-1.834 1.833H4.167A1.833 1.833 0 0 1 2.333 16V4.333c0-1.012.821-1.833 1.834-1.833h11.666z"/>
      <path className={css.checked} fill="#2A374A" fillRule="nonzero" d="M13.333 1.833a.833.833 0 0 1 0 1.667H4.167a.833.833 0 0 0-.834.833V16c0 .46.373.833.834.833h11.666c.46 0 .834-.373.834-.833v-5.833a.833.833 0 0 1 1.666 0V16a2.5 2.5 0 0 1-2.5 2.5H4.167a2.5 2.5 0 0 1-2.5-2.5V4.333a2.5 2.5 0 0 1 2.5-2.5h9.166zm5.59 1.078a.833.833 0 0 1 0 1.178l-8.334 8.334a.833.833 0 0 1-1.178 0l-2.5-2.5a.833.833 0 1 1 1.178-1.179L10 10.654l7.744-7.743a.833.833 0 0 1 1.179 0z"/>
    </svg>
  );
};


IconCheckbox.defaultProps = { className: null };

IconCheckbox.propTypes = { className: string };


const FieldCheckboxIconWithCheckComponent = props => {
  const { rootClassName, className, id, icon, label, svgClassName, ...rest } = props;

  const classes = classNames(rootClassName || css.root, className);
  const checkboxProps = {
    id,
    className: css.input,
    component: 'input',
    type: 'checkbox',
    ...rest,
  };

  return (
    <span className={classes}>
      <Field {...checkboxProps} />
      <label htmlFor={id} className={css.label}>
        <span className={css.textWrapper}>
          <img src={icon} className={css.icon} />
          <span className={css.text}>{label}</span>
        </span>
        <span className={css.checkboxWrapper}>
          <IconCheckbox className={svgClassName} />
        </span>
      </label>
    </span>
  );
};

FieldCheckboxIconWithCheckComponent.defaultProps = {
  className: null,
  rootClassName: null,
  label: null,
  icon: null,
  svgClassName: null,
};

FieldCheckboxIconWithCheckComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,
  label: node,
  icon: string,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default FieldCheckboxIconWithCheckComponent;
