import React from 'react';
import FieldTextInput from '../FieldTextInput/FieldTextInput';
import FieldCheckboxGroup from '../FieldCheckboxGroup/FieldCheckboxGroup';
import { FieldSelect } from '..';
import FieldSelectDisplayed from '../FieldSelect/FieldSelectDisplayed';
import FieldCheckboxGroupDisplayed from '../FieldCheckboxGroup/FieldCheckboxGroupDisplayed';


export const createFormElement = (elementData) => {

    const {
        title,
        description,
        options,
        selectedTypeOfQuestion
    } = elementData

    switch (selectedTypeOfQuestion) {
        case 'textareaField':
            return <FieldTextInput
                id={title}
                name={title}
                type="textarea"
                label={title}
                placeholder={description}
            />
        case 'checkboxField':
            return <FieldCheckboxGroup
                id={title}
                name={description}
                label={title}
                options={options.map((option) => {
                    return {
                        key: option,
                        label: option,
                    };
                })}
            />
        case 'selectField':
            return <FieldSelect
                name={title}
                id={title}
                label={title}
            >
                <option disabled value="">
                    {description}
                </option>
                {options.map((c) => (
                    <option key={c} value={c}>
                        {c}
                    </option>
                ))}
            </FieldSelect>
        default:
            return null
    }
}

export const createShowedFormElement = (elementData, register, errors) => {

    const {
        title,
        description,
        options,
        selectedTypeOfQuestion
    } = elementData


    switch (selectedTypeOfQuestion) {
        case 'textareaField':
            return <>
                <div style={{ background: '#eee', padding: '10px', margin: '20px 0', borderRadius: '8px', border: `${!!errors[title] ? 'solid 1px red' : 'solid 1px #eee'}` }}>
                    <p>{title}
                        {!!elementData.isRequired && <sup style={{ color: 'red' }}>*</sup>}
                    </p>
                    <textarea placeholder={description} {...register(title.split('.').join('_'), { required: !!elementData.isRequired })} rows={2} maxLength={100} />
                </div>
                {!!errors[title] && <p style={{ color: 'red' }}>Pole jest wymagane</p>}
            </>

        case 'checkboxField':
            return <>
                <div style={{ background: '#eee', padding: '10px', margin: '20px 0', borderRadius: '8px', border: `${!!errors[title] ? 'solid 1px red' : 'solid 1px #eee'}` }}>
                    <FieldCheckboxGroupDisplayed register={register} title={title} isRequired={!!elementData.isRequired} description={description} options={options} />
                </div>
                {!!errors[title] && <p style={{ color: 'red' }}>Pole jest wymagane</p>}
            </>
        case 'selectField':
            return <>
                <div style={{ background: '#eee', padding: '10px', margin: '20px 0', borderRadius: '8px', border: `${!!errors[title] ? 'solid 1px red' : 'solid 1px #eee'}` }}>
                    <FieldSelectDisplayed register={register} isRequired={!!elementData.isRequired} title={title} description={description} options={options} />
                </div>
                {!!errors[title] && <p style={{ color: 'red' }}>Pole jest wymagane</p>}
            </>
        default:
            return null
    }
}