import React, { useEffect } from 'react';
import { useSwitcher } from '../../../components/Switcher/useSwitcher';
import FormItem from '../../../components/FormItem';
import Switcher from '../../../components/Switcher/Switcher';
import { useFormContext } from '../../context';

import css from './styles.css';
import CustomCheckbox from '../../../components/CustomCheckbox';
import { useQuery } from 'react-query';
import { apiSdk } from '../../../../queries/api';

const EventPrice = ({ register, errors, setValue, watch, control }) => {
  const { formData } = useFormContext();

  const { data: fetchedUSerData, isSuccess } = useQuery('user.current', () =>
    apiSdk.users.currentUser()
  );

  const priceTypeSwitcher = useSwitcher(
    ['Darmowe', 'Płatne'],
    !formData.generalInfo.price ? 'Darmowe' : 'Płatne'
  );

  useEffect(() => {
    if (priceTypeSwitcher.selected === 'Darmowe') {
      setValue('price', null);
      setValue('discountPrice', null);
    }
  }, [priceTypeSwitcher.selected]);

  return (
    <>
      <FormItem label={'Cena za wydarzenie'}>
        <div className={css.eventPriceSwitcher}>
          <Switcher {...priceTypeSwitcher} />
        </div>
        {priceTypeSwitcher.selected === 'Płatne' && (
          <div className={css.eventPriceContainer}>
            <input
              type="number"
              placeholder="Bilet normalny"
              className={css.eventPriceInput}
              {...register('price', { required: true })}
            />
            <input
              type="number"
              max={Number(watch('price'))}
              placeholder="Bilet ulgowy (opcjonalnie)"
              className={css.eventPriceInput}
              {...register('discountPrice', { required: false })}
            />
          </div>
        )}
        {errors.price && errors.price.type === 'required' && (
          <p style={{ color: '#f00' }} role="alert">
            Cena wydarzenia jest wymagana
          </p>
        )}
      </FormItem>
      {isSuccess &&
        fetchedUSerData.data.attributes.hasStripeAccount &&
        fetchedUSerData.data.attributes.profile.metadata.set_payments_enabled && (
          <FormItem label={''}>
            <CustomCheckbox
              label={'Włącz płatności online'}
              control={control}
              name={'paymentEnabled'}
            />
          </FormItem>
        )}
    </>
  );
};

export default EventPrice;
