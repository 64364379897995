import React from 'react';
import DatePicker, { registerLocale, } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import "react-datepicker/dist/react-datepicker.css";
import './DateTimeInput.css';

registerLocale('pl', pl)

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <input
        onClick={onClick}
        value={value}
        readOnly
        ref={ref}
        style={{ border: 'none !important' }}
    />
));

const sixMonthsFromNow = new Date();
sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);


const DateTimeInput = props => {
    const { label } = props;


    return (
        <div style={{ maxWidth: '100%', display: "flex", flexDirection: 'column' }}>
            <label style={{ fontSize: '14px' }} >{label}</label>
            <div style={{ border: 'solid 1px #949494' }}>
                <DatePicker
                    selected={props.date}
                    onChange={(date) => props.handleChange(date)}
                    locale="pl"
                    showTimeSelect
                    timeCaption="Czas"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="d MMMM yyyy, HH:mm"
                    minDate={new Date()}
                    shouldCloseOnSelect={true}
                    maxDate={sixMonthsFromNow}
                    customInput={<CustomInput />}
                />
            </div>
        </div>
    );
};

export default DateTimeInput;
