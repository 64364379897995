import React, { useState, useRef, useEffect } from 'react';
import { useJsApiLoader, Autocomplete } from '@react-google-maps/api';
import { Controller } from 'react-hook-form';
import { useGoogleMaps } from '../../../util/mapContext';


const LocationInput = ({ apiKey, control, name, initialCoordinates }) => {

    const { isLoaded, loadError } = useGoogleMaps();


    const [inputValue, setInputValue] = useState("");
    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (initialCoordinates && isLoaded) {
            const geocoder = new window.google.maps.Geocoder();
            const latLng = new window.google.maps.LatLng(initialCoordinates.lat, initialCoordinates.lng);

            geocoder.geocode({ location: latLng }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    setInputValue(results[0].formatted_address);
                }
            });
        }
    }, [initialCoordinates, isLoaded]);

    const handleLoad = (autocomplete) => {
        autocompleteRef.current = autocomplete;
    };

    const handleChange = (onChange) => {
        if (autocompleteRef.current) {
            const place = autocompleteRef.current.getPlace();
            if (place.geometry) {
                const lat = place.geometry.location.lat();
                const lng = place.geometry.location.lng();
                onChange({ lat, lng });
                setInputValue(place.formatted_address);
            }
        }
    };

    if (!isLoaded) {
        return <div>ładowanie...</div>;
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                    <Autocomplete onLoad={handleLoad} onPlaceChanged={() => handleChange(onChange)}>
                        <input
                            style={{ border: '1px solid #949494', padding: '12px', width: '100%' }}
                            type="text"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                            placeholder="Wpisz adres"
                        />
                    </Autocomplete>
                    {error && <span style={{ color: 'red' }}>To pole jest wymagane</span>}
                </div>
            )}
        />
    );
};

export default LocationInput;
