import { tr } from 'date-fns/locale';
import { first } from 'lodash';

const sharetribeSdk = require('sharetribe-flex-sdk');


const sdk = sharetribeSdk.createInstance({
    clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

export const showCurrentUserData = async () => {
    return sdk.currentUser.show().then(res => {
        if (res.status === 200) {
            return ({
                email: res.data.data.attributes.email,
                firstName: res.data.data.attributes.profile.firstName,
                lastName: res.data.data.attributes.profile.lastName,
            })
        } else {
            throw new Error('Error fetching current user data');
        }
    }).catch(e => {
        if (e.status === 403) {
            console.log('res.status', window.location);
            // window.location.href = '/login';
        }
    });
}

export const initiateTransactionForSTSEvent = async ({ listingId, bookingStart, bookingEnd }) => {
    return sdk.transactions.initiate({
        processAlias: "free-sts-booking/release-1",
        transition: "transition/request",
        params: {
            listingId,
            bookingStart: new Date(bookingStart),
            bookingEnd: new Date(bookingEnd),
            seats: 1,
            // cardToken: "tok_mastercard"
        }
    }, {
        expand: true
    }).then(res => {
        if (res.status === 200) {
            return 'Transaction initiated successfully'
        } else {
            throw new Error('Error fetching current user data');
        }
    }).catch(e => {
        if (e.status === 403) {
            console.log('res.status', window.location);
            window.location.href = '/login';
        }
    });
}
