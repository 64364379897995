import desktop1 from './images/desktop1.png';
import desktop1_2x from './images/desktop1@2x.png';
import desktop2 from './images/desktop2.png';
import desktop2_2x from './images/desktop2@2x.png';
import desktop3 from './images/desktop3.png';
import desktop3_2x from './images/desktop3@2x.png';
import desktop4 from './images/desktop4.png';
import desktop4_2x from './images/desktop4@2x.png';
import desktop5 from './images/desktop5.png';
import desktop5_2x from './images/desktop5@2x.png';
import Name from './images/name.svg';
import Sport from './images/sport.svg';
import Age from './images/age.svg';
import Deadline from './images/deadline.svg';
import Level from './images/level.svg';
import LevelAdditional from './images/level_additional.svg';
import Localization from './images/localization.svg';
import Photos from './images/photos.svg';
import Participants from './images/participants.svg';
import Price from './images/price.svg';
import Rules from './images/rules.svg';
import Remarks from './images/remarks.svg';
import Recommendations from './images/recommendations.svg';
import LocalizationRemarks from './images/localization_remarks.svg';
import Desc from './images/desc.svg';
import Website from './images/website.svg';

export default [
  {
    header: 'Krok 1 z 5',
    id: 'step1',
    mainImg: desktop1,
    mainImgBig: desktop1_2x,
    steps: [
      { header: 'Nazwa wydarzenia', color: '#541bbe', text: 'Jedna z najważniejszych informacji. Dobra nazwa wydarzenia to klucz zainteresowania użytkowników Decathlon GO.', img: Name },
      { header: 'Sport', color: '#3c6f9c', text: 'Określ sport, którego dotyczy wydarzenie. Jeżeli Twoje wydarzenie dotyczy sportu, którego nie ma na liście skontaktuj się z nami.', img: Sport },
      { header: 'Opis oferty', color: '#dd6892', text: 'Opisz wydarzenie w możliwie szczegółowy sposób. Pomoże to potencjalnym uczestnikom zapoznać się z Twoją propozycją oraz będzie miało pozytywny wpływ na widopczność wydarzenia w internecie.', img: Desc },
      { header: 'Termin i czas trwania', color: '#543864', text: 'Wybierając z kalendarza, podaj dzień i godzinę rozpoczęcia wydarzenia. Sugerujemy również podanie orientacyjnego czasu trwania np. 2 godziny, cały dzień, czy tydzień.', img: Deadline },
      { header: 'Limit uczestników', color: '#ff6464', text: 'Określ liczbę uczestników dla których jest wydarzenie. Możesz pozostawić pole puste jeżeli nie przewidujesz limitu uczestników.', img: Participants },
      { header: 'Strona internetowa', color: '#347474', text: 'Jeżeli posiadasz osobną stronę internetową wydarzenia możesz podać ją tutaj. To pole jest opcjonalne.', img: Website },
      { header: 'Cena', color: '#ec9b3b', text: 'Wprowadź cenę w PLN za udział w wydarzeniu. Jeżeli oferta jest darmowa wprowadź liczbę 0. Nie pozostawiaj pola pustego.', img: Price },
      { header: 'Rejestracja online', color: '#ec9b3b', text: 'Zaznacz checkbox jeżeli chcesz zarządzać zgłoszeniami poprzez Decathlon GO. Będziesz dostawać zgłoszenia na platformie oraz powiadomienia mailowe. Akceptować lub odrzucać zgłoszenia będziesz poprzez platformę.', img: Recommendations }
    ]
  },
  {
    header: 'Krok 2 z 5',
    id: 'step2',
    mainImg: desktop2,
    mainImgBig: desktop2_2x,
    steps: [
      { header: 'Poziom', color: '#541bbe', text: 'Określ do jakich uczestników skierowane jest Twoje wydarzenie. Masz do wyboru 5 poziomów zaawansowania. Odwiedzający Decathlon GO mają możliwość wyszukiwania wydarzeń po oczekiwanym poziomie uczestników.', img: Level },
      { header: 'Dodatkowy opis poziomu', color: '#3c6f9c', text: 'Opcjonalnie możesz uściślić oczekiwania wobec zaawansowania uczestników, gdy wymagane są szczególne umiejętności np. znajomość przepisów umożliwijąca sędziowanie.', img: LevelAdditional },
      { header: 'Wiek', color: '#dd6892', text: 'Określ wiek uczestników wydarzenia. Możesz nie wypełniać żadnego pola, jeżeli wydarzenie skierowane jest do uczestników bez względu na wiek. Możesz określić tylko dolną lub tylko górną granicę wieku. W uwagach dodatkowych możesz wyszczególnić inne wymagania dotyczące wieku np. wymagana zgoda opiekunów na uczestnictwo osób niepełnoletnich.', img: Age }
    ]
  },
  {
    header: 'Krok 3 z 5',
    id: 'step3',
    mainImg: desktop3,
    mainImgBig: desktop3_2x,
    steps: [
      { header: 'Zasady wydarzenia', color: '#3c6f9c', text: 'Przedstaw uczestnikom zasady wydarzenia. Zamieść tutaj regulamin oraz wszystko to co pozwoli uczestnikom dowiedzieć się o sposobie realizacji wydarzenia.', img: Rules },
      { header: 'Zalecenia dla uczestników', color: '#dd6892', text: 'Możesz tutaj określić zalecenia i rekomendacje dla uczestników Twojego wydarzenia np. zapewniasz sprzęt lub uczestnik we własnym zakresie musi zorganizować sprzęt.', img: Recommendations },
      { header: 'Uwagi dodatkowe', color: '#541bbe', text: 'Opcjonalnie możesz umieścić tutaj wszelkie inne i dodatkowe informacje, które powinni poznać uczestnicy. Może to być np. konieczność podania numeru licencji zawodniczej w zgłoszeniu, rozmiaru koszulki jeżeli zapewniasz taką uczestnikom, ewentualne dodatkowe opłaty, czy możliwość wypożyczenia sprzętu na miejscu.', img: Remarks }
    ]
  },
  {
    header: 'Krok 4 z 5',
    id: 'step4',
    mainImg: desktop4,
    mainImgBig: desktop4_2x,
    steps: [
      { header: 'Lokalizacja', color: '#541bbe', text: 'Wpisz lub wyszukaj lokalizację wydarzenia z pomocą Google. Będzie to informacja dla uczestników, a także pozwoli precyzyjnie wskazać miejsce na mapie z wynikami wyszukiwania.', img: Localization },
      { header: 'Uwagi dodatkowe', color: '#3c6f9c', text: 'Możesz opcjonalnie podać informacje dotyczące lokalizacji np. bardziej dokładny opis miejsca wydarzenia, czy wskazówki dojazdu. Pomoże to uczestnikom sprawnie i bez problemów dostać się na wydarzenie.', img: LocalizationRemarks }
    ]
  },
  {
    header: 'Krok 5 z 5',
    id: 'step5',
    mainImg: desktop5,
    mainImgBig: desktop5_2x,
    steps: [
      { header: 'Zdjęcia', color: '#541bbe', text: 'Atrakcyjne zdjęcie jest niezwykle istotne, gdyż wyświetlane jest w wynikach wyszukiwania, a dla rekomendowanych wydarzeń także na stronie głównej. Pierwsze zdjęcie jest najważniejsze, dlatego znajdź coś atrakcyjnego w dobrej rozdzielczości, najlepiej ze swoim logo jeżeli takie posiadasz.', img: Photos },
      { header: 'Opublikuj', color: '#44b480', text: 'Gdy Twoje wydarzenie jest już kompletne, pozostało tylko je opublikować. Będzie ono wtedy dostępne w wynikach wyszukiwania dla wszystkich użytkowników Decathlon GO.', img: Website }
    ]
  },
];