import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IconChevronDown, ListingCard } from '../../components';
import css from './SearchResultsPanel.css';

const SearchResultsPanelExpand = props => {
  const { className, rootClassName, listings, listingClassName } = props;
  const classes = classNames(rootClassName || css.root, className);
  const listingClasses = classNames(listingClassName || css.listingCards);
  const listingCardClasses = listingClassName ? css.listingCardBlock : css.listingCard;

  // Panel width relative to the viewport
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');

  var d = new Date();
  d.setUTCHours(0,0,0,0);

  return (
    <div className={classes}>
      <div className={listingClasses}>
        {listings.map(l => (
          <ListingCard
            className={listingCardClasses}
            key={l.id.uuid}
            listing={l}
            renderSizes={cardRenderSizes}
          />
        ))}
      </div>
    </div>
  );
};

SearchResultsPanelExpand.defaultProps = {
  className: null,
  isListingOpen: false,
  listings: [],
  listingClassName: null,
  rootClassName: null,
  search: null,
  togglePanel: null,
};

const { array, bool, func, number, object, string } = PropTypes;

SearchResultsPanelExpand.propTypes = {
  className: string,
  isListingOpen: bool,
  listings: array,
  listingClassName: string,
  maxItemsToShow: number.isRequired,
  rootClassName: string,
  search: object,
  togglePanel: func
};

export default SearchResultsPanelExpand;
