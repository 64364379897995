import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { propTypes } from '../../util/types';
import { parse } from '../../util/urlHelpers';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import debounce from 'lodash.debounce';
import { withRouter } from 'react-router-dom';
import {
  ManageListingCard,
  PaginationLinks,
} from '../../components';

import {
  closeListing,
  openListing,
  getOwnListingsById,
  queryOwnListings,
  setSelectedEventType
} from './ManageListingsPage.duck';
import css from './ManageListingsPage.css';
import ManageCustomersPage from './ManageCustomersPage';
import ManageDatesPage from './ManageDatesPage';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 42 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 5;

export class ManageListingsPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { listingMenuOpen: null, searchValue: '', inputValue: '', rerender: false };
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.debouncedSearch = debounce(this.handleInputChange, 300); // Opóźnienie wynosi 300 ms

  }

  onToggleMenu(listing) {
    this.setState(prev => ({ ...prev, listingMenuOpen: listing }));
  }


  componentDidMount() {
    const currentUrl = window.location.href;
    const params = new URL(currentUrl).search;
    const queryParams = parse(params);
    const page = queryParams.page || 1;

    this.props.onGetOwnListings({
      // ...queryParams,
      page,
      per_page: RESULT_PAGE_SIZE,
      include: ['images', 'dates'],
      // 'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
      // 'limit.images': 1,
      authorId: this.props.authorId,
      state: this.props.selectedEventType,//this.state.selectedOption,
      keywords: this.state.searchValue
    })
  }

  componentDidUpdate(prevState) {
    const currentUrl = window.location.href;

    const params = new URL(currentUrl).search;
    const queryParams = parse(params);
    const page = queryParams.page || 1;

    if (!queryParams.listingId && prevState.queryParams && (prevState.queryParams.keywords !== this.state.searchValue || prevState.queryParams.state !== this.props.selectedEventType)) {
      this.props.onGetOwnListings({
        // ...queryParams,
        page,
        perPage: RESULT_PAGE_SIZE,
        include: ['images', 'dates'],
        // 'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        // 'limit.images': 1,
        authorId: this.props.authorId,
        state: this.props.selectedEventType,//this.state.selectedOption,
        keywords: this.state.searchValue
      })
    }

  }

  componentWillUnmount() {
    this.debouncedSearch.cancel();
  }

  handleInputChange = (value) => {
    this.setState(prev => ({ ...prev, searchValue: value }))
  };

  handleChange = (e) => {
    const inputValue = e.currentTarget.value
    this.setState(prev => ({ ...prev, inputValue: inputValue }))
    this.debouncedSearch(inputValue);
  }

  handleSelectChange = (event) => {
    // this.setState({ selectedOption: event.currentTarget.value });
    this.props.onChangeSelectedEventType(event.currentTarget.value)
    this.props.history.push('/inbox/sales')
  }

  render() {
    const {
      closingListing,
      closingListingError,
      listings,
      onCloseListing,
      onOpenListing,
      openingListing,
      openingListingError,
      pagination,
      queryInProgress,
      queryParams,
      intl,
    } = this.props;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const listingsAreLoaded = !queryInProgress && hasPaginationInfo;


    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id="ManageListingsPage.queryError" />
      </h2>
    );

    const noResults =
      listingsAreLoaded && pagination.totalItems === 0 ? (
        <h1 className={css.title}>
          <FormattedMessage id="ManageListingsPage.noResults" />
        </h1>
      ) : null;


    const listingId = new URLSearchParams(this.props.history.location.search).get('listingId')

    const page = queryParams ? queryParams.page : 1;
    const paginationLinks =
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ManageListingsPage"
          pageSearchParams={{ page }}
          pagination={pagination}
        />
      ) : null;

    const listingMenuOpen = this.state.listingMenuOpen;
    const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    const openingErrorListingId = !!openingListingError && openingListingError.listingId;


    const panelWidth = 62.5;
    // Render hints for responsive image
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');


    return (

      <div className={css.listingPanel}>
        {/* {heading} */}
        {listingId ? <div>
          {this.props.history.location.hash === '#dates' ? <ManageDatesPage listingId={listingId} /> : <ManageCustomersPage listingId={listingId} />}
        </div> :
          <>
            <div className={css.filterscontainer} >
              <label className={css.search}>
                Wyszukaj wydarzenie
                <input type='text' placeholder='Wpisz conajmniej trzy litery...' value={this.state.inputValue} onChange={this.handleChange} />
              </label>
              <label className={css.filter}>
                Sortuj wydarzenia
                <select value={this.props.selectedEventType} onChange={this.handleSelectChange}>
                  <option value={'all'}>Wszystkie</option>
                  <option value={'published'} >Opublikowane</option>
                  <option value={'draft'}>Wersja robocza</option>
                  <option value={'closed'}>Zakończone</option>
                </select>
              </label>
            </div>
            <div className={css.listingCards}>
              {/* {listings.filter(l => l.attributes.publicData.type === 'event').map(l => ( */}
              {listings.map(l => (
                <ManageListingCard
                  className={css.listingCard}
                  key={l.id.uuid}
                  listing={l}
                  isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                  actionsInProgressListingId={openingListing || closingListing}
                  onToggleMenu={this.onToggleMenu}
                  onCloseListing={onCloseListing}
                  onOpenListing={onOpenListing}
                  hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                  hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                  renderSizes={renderSizes}
                />
              ))}
            </div>
            {paginationLinks}
          </>
        }
      </div>
    );
  }
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    selectedEventType,
  } = state.ManageListingsPage;

  const listings = getOwnListingsById(state, currentPageResultIds);
  return {
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    selectedEventType
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onGetOwnListings: (queryParams) => dispatch(queryOwnListings(queryParams)),
  onChangeSelectedEventType: (eventType) => dispatch(setSelectedEventType(eventType))
});

const ManageListingsPage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ManageListingsPageComponent);


export default ManageListingsPage;
