import React from 'react';
import PropTypes from 'prop-types';

const DotEmpty = props => {
    const { className } = props;
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <circle cx="6" cy="6" r="6" fill="#D9DDE1" />
        </svg>

    );
};

DotEmpty.defaultProps = { className: null };

const { string } = PropTypes;

DotEmpty.propTypes = {
    className: string,
};

export default DotEmpty;