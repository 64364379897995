export const formatDate = (date) => {
    return date.toLocaleDateString('pl-PL', {
        weekday: 'short',
        day: '2-digit',
        month: 'short'
    }).replace('.', '');
};

export const formatTime = (date) => {
    return date.toLocaleTimeString('pl-PL', {
        hour: '2-digit',
        minute: '2-digit'
    });
};

export const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000);
};