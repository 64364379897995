import React from 'react';
import PropTypes from 'prop-types';

const CheckIcon = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="11" viewBox="0 0 15 11" fill="none">
            <path d="M14.6514 0.451458C14.1873 -0.0132627 13.4337 -0.0129697 12.969 0.451458L5.39677 8.02396L2.03125 4.65847C1.56653 4.19375 0.813231 4.19375 0.34851 4.65847C-0.116211 5.12319 -0.116211 5.87649 0.34851 6.34121L4.55523 10.5479C4.78744 10.7801 5.09193 10.8965 5.39645 10.8965C5.70097 10.8965 6.00576 10.7804 6.23797 10.5479L14.6514 2.13417C15.1161 1.66978 15.1161 0.916149 14.6514 0.451458Z" fill="#00CB51" />
        </svg>
    );
};

CheckIcon.defaultProps = { className: null };

const { string } = PropTypes;

CheckIcon.propTypes = { className: string };

export default CheckIcon;
