import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import config from '../../config';

import css from './SearchMapPriceLabel.css';

class SearchMapPriceLabel extends Component {
  shouldComponentUpdate(nextProps) {
    const currentListing = ensureListing(this.props.listing);
    const nextListing = ensureListing(nextProps.listing);
    const isSameListing = currentListing.id.uuid === nextListing.id.uuid;
    const hasSamePrice = currentListing.attributes.price === nextListing.attributes.price;
    const hasSameActiveStatus = this.props.isActive === nextProps.isActive;
    const hasSameRefreshToken =
      this.props.mapComponentRefreshToken === nextProps.mapComponentRefreshToken;

    return !(isSameListing && hasSamePrice && hasSameActiveStatus && hasSameRefreshToken);
  }

  render() {
    const { className, rootClassName, intl, listing, onListingClicked, isActive } = this.props;
    const currentListing = ensureListing(listing);
    // const { price } = currentListing.attributes;

    const price = { currency: 'PLN'}
    
    // Create formatted price if currency is known or alternatively show just the unknown currency.
    const formattedPrice = '23 PLN'
      // price && price.currency === config.currency ? formatMoney(intl, price) : price.currency;

    const classes = classNames(rootClassName || css.root, className);
    const priceLabelClasses = classNames(css.priceLabel, { [css.priceLabelActive]: isActive });
    const caretClasses = classNames(css.caret, { [css.caretActive]: isActive });

    // return (
    //   <div className={classes}>
    //     {
    //       currentListing.publicData && config.sportsConfig[currentListing.publicData.sport] ? <img src={config.sportsConfig[currentListing.publicData.sport].icon} /> : null
    //     }
    //   </div>
    // );

    return (
      <button className={classes} onClick={() => onListingClicked(currentListing)}>
        {/* <div className={css.caretShadow} /> */}
        <div className={priceLabelClasses}>
        {
          currentListing.attributes.publicData && config.sportsConfig[currentListing.attributes.publicData.sport] ? 
            <img src={config.sportsConfig[currentListing.attributes.publicData.sport].icon} /> :
            <div className={css.pin}></div>
        }
        </div>
        {/* <div className={caretClasses} /> */}
      </button>
    );
  }
}

SearchMapPriceLabel.defaultProps = {
  className: null,
  rootClassName: null,
};

const { func, string } = PropTypes;

SearchMapPriceLabel.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
  onListingClicked: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default injectIntl(SearchMapPriceLabel);
