import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl } from 'react-intl';
import { Reorder, useMotionValue } from 'framer-motion/dist/framer-motion';

import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  Button,
} from '../../components';
import css from './EditListingAdditionalForm.css';
import arrayMutators from 'final-form-arrays';

const SingleFormElement = ({
  value,
  index,
  handleEditQuestion,
  handleRemoveElement,
  hadleIsRequiredField
}) => {
  const y = useMotionValue(0);

  const { id, element, elementData } = value;

  return (
    <Reorder.Item className={css.formelement} style={{ y }} value={value}>
      <span className={css.formelementcounter}>{index + 1}.</span>{' '}
      <div className={css.formelementcontainer}>
        <div style={{ width: '100%' }}>{element}</div>
        <div className={css.formelementactions}>
          <label className={css.formelementactionslabel}>
            <input
              type="checkbox"
              className={css.formelementactionsinput}
              onChange={(e) => hadleIsRequiredField(e, id)}
              checked={elementData.isRequired}
            />
            Wymagane
          </label>
          <button
            onClick={(e) => handleEditQuestion(elementData, e)}
            className={css.formelementactionsbutton}
          >
            Edytuj
          </button>
          <button
            onClick={() => handleRemoveElement(id)}
            className={css.formelementactionsbutton}
          >
            Usuń
          </button>
        </div>
      </div>
    </Reorder.Item>
  );
};

export const EditListingAdditionalFormComponent = (props) => {
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={(fieldRenderProps) => {
        const {
          formElements,
          handleEditQuestion,
          setFormElements,
          className,
          rootClassName,
          disabled,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateError,
          updateInProgress,
          availability,
          availabilityPlan,
          listingId,
          values,
          form,
        } = fieldRenderProps;

        const handleRemoveElement = (elemnentId) => {
          setFormElements((prev) => prev.filter(({ id }) => id !== elemnentId));
        };

        const hadleIsRequiredField = (e, elemnentId) => {
          const isChecked = e.target ? e.target.checked : false
          setFormElements(prev => {
            const requiredElement = prev.find(({ id }) => id === elemnentId)
            requiredElement.elementData.isRequired = isChecked
            return [...prev.filter(({ id }) => id !== elemnentId), requiredElement]
          })
        }

        const classes = classNames(rootClassName || css.root, className);
        const submitReady = updated && pristine;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <Reorder.Group
              axis="y"
              values={formElements}
              onReorder={setFormElements}
            >
              {formElements.map((value, index) => (
                <SingleFormElement
                  value={value}
                  index={index}
                  handleEditQuestion={handleEditQuestion}
                  handleRemoveElement={handleRemoveElement}
                  hadleIsRequiredField={hadleIsRequiredField}
                  key={value.id}
                />
              ))}
            </Reorder.Group>

            <Button
              className={css.submitButton}
              type="submit"
              onClick={() => form.change('additionalForm', formElements.map(item => item.elementData))}
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingAdditionalFormComponent.defaultProps = {
  updateError: null,
};

EditListingAdditionalFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  updated: bool.isRequired,
  updateError: propTypes.error,
  updateInProgress: bool.isRequired,
  availability: object.isRequired,
};

export default compose(injectIntl)(EditListingAdditionalFormComponent);
