import React from 'react';
import PropTypes from 'prop-types';

const IconLevel = props => {
  const { className } = props;
  return (   
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
        <path fill="#009BE7" fillRule="nonzero" stroke="none" d="M9 1.5a.5.5 0 0 1 .5.5v8a.5.5 0 1 1-1 0V2a.5.5 0 0 1 .5-.5zm-3 3a.5.5 0 0 1 .5.5v5a.5.5 0 1 1-1 0V5a.5.5 0 0 1 .5-.5zm-3 3a.5.5 0 0 1 .5.5v2a.5.5 0 1 1-1 0V8a.5.5 0 0 1 .5-.5z"/>
    </svg>
  );
};

IconLevel.defaultProps = { className: null };

const { string } = PropTypes;

IconLevel.propTypes = {
  className: string,
};

export default IconLevel;
