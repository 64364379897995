import React from 'react';
import SportLocationPage from './SportLocationPage';
import { sportLocationLandings } from './sportLocationLandings'; 

export const sportLocationRoutes = sportLocationLandings.map(landing => 
  ({
    path: landing.url,
    name: landing.name,
    component: () => <SportLocationPage landingData={landing} />,
    loadData: (params, search) => SportLocationPage.loadData({ searchParams: landing.searchParams, ...params}, search),
  }));