import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = props => {
  const { className } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 16" fill="none">
      <g clip-path="url(#clip0_774_266)">
        <path d="M9.88086 2.68736L1.07744 11.4914C1.03315 11.5358 1.00117 11.5919 0.985939 11.6522L0.0101637 15.5688C-0.0190245 15.6867 0.0156207 15.812 0.101662 15.8981C0.166765 15.9632 0.255472 15.9992 0.346209 15.9992C0.374001 15.9992 0.402428 15.9958 0.430094 15.9888L4.34665 15.0129C4.40769 14.9977 4.46315 14.9658 4.50744 14.9216L13.3116 6.11812L9.88086 2.68736Z" fill="#0082C3" />
        <path d="M15.4926 1.48697L14.5126 0.507003C13.8577 -0.147956 12.7162 -0.147321 12.062 0.507003L10.8616 1.7074L14.2922 5.13803L15.4926 3.93764C15.8198 3.6106 16 3.17532 16 2.71236C16 2.24941 15.8198 1.81413 15.4926 1.48697Z" fill="#0082C3" />
      </g>
      <defs>
        <clipPath id="clip0_774_266">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

EditIcon.defaultProps = { className: null };

const { string } = PropTypes;

EditIcon.propTypes = { className: string };

export default EditIcon;
