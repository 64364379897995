import React from 'react';
import PropTypes from 'prop-types';

const IconPlus = props => {
  const { className } = props;
  return (   
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill="#2A374A"
        fillRule="nonzero"
        stroke="none"
        d="M8 2.667c.368 0 .667.298.667.666v4h4a.667.667 0 1 1 0 1.334h-4v4a.667.667 0 1 1-1.334 0v-4h-4a.667.667 0 0 1 0-1.334h4v-4c0-.368.299-.666.667-.666z"
      />
    </svg>
  );
};

IconPlus.defaultProps = { className: null };

const { string } = PropTypes;

IconPlus.propTypes = {
  className: string,
};

export default IconPlus;
