import React from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  NamedLink,
} from '../../components';
import config from '../../config';
import { StaticPage, TopbarContainer } from '../../containers';
import { parse, twitterPageURL } from '../../util/urlHelpers';

import css from './PartnersPage.css';
import Boost from './images/boost-reservation.svg';
import Deposit from './images/deposit.svg';
import Event from './images/event.svg';
import Registration from './images/free-registration.svg';
import Money from './images/money-back.svg';
import Profile from './images/profile.svg';
import Promote from './images/promote.svg';
import Share from './images/share.svg';
import Support from './images/support.svg';

import Arrow from './images/arrow.svg';

import FooterV2 from '../../components/Footer/FooterV2';
import { competitions } from './competitions';
import { sportsContent } from './sportsContent';

const PartnersPage = props => {
  const { siteTwitterHandle, siteFacebookPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);
  const { sport = 'general', type = 'general' } = parse(props.location.search);

  const promoSport = sportsContent[sport] || sportsContent['general'];
  const promoCompetition = competitions[type] || competitions['general'];

  return (
    <StaticPage
      title="Zostań organizatorem wydarzeń sportowych"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'Zostań organizatorem i promuj swoje wydarzenia sportowe | Decathlon GO',
        name: 'Zostań organizatorem wydarzeń sportowych',
      }}
    >
      <LayoutSingleColumn className={css[promoSport.bgClassName]}>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain className={css.staticPageWrapper}>
          <div className={css.headerSection}>
            <h1>
              Dodaj {promoCompetition.name}{' '}
              <span className={css.sportName}>{promoSport.pSportsName}</span> i&nbsp;zyskaj nowych
              uczestników
            </h1>
            <p>
              Organizowanie {promoCompetition.headerSection} {promoSport.pSportsName} nigdy nie było
              tak proste. Promuj wydarzenia, zarządzaj zgłoszeniami i miej pewność, że pasjonaci{' '}
              {promoSport.pSportName} pojawią się u Ciebie gotowi do gry lub treningu.
            </p>
            {/* <NamedLink className={css.seeMoreBtn} name="SignupPage">Dodaj {promoCompetition.name}</NamedLink> */}
          </div>
          <div className={css.benefitsSection}>
            <h2>Sprawdź co zyskujesz</h2>
            <div className={css.benefitTilesWrapper}>
              <div className={css.benefitTile}>
                <img src={Registration} />
                <h3>Darmowa rejestracja</h3>
                <p>
                  Zakładasz darmowe konto. Płacisz prowizję tylko wtedy, jeżeli ktoś dokona płatnej
                  rezerwacji. Zarabiamy, jeżeli Ty zarabiasz.
                </p>
              </div>
              <div className={css.benefitTile}>
                <img src={Deposit} />
                <h3>Pewność i bezpieczeństwo</h3>
                <p>
                  Nie martw się już o anulowanie zgłoszenia w ostatniej chwili, chronimy cię, jeśli
                  uczestnik się nie pojawi.
                </p>
              </div>
              <div className={css.benefitTile}>
                <img src={Promote} />
                <h3>Promocja własnych {promoCompetition.benefitsSection}</h3>
                <p>
                  Zwiększ widoczność swojej firmy i wydarzeń. Dotrzyj do tysięcy użytkowników,
                  szukających sportowych wydarzeń i zwiększ swoje przychody dzięki Decathlon GO.
                </p>
              </div>
              <div className={css.benefitTile}>
                <img src={Money} />
                <h3>Łatwe zarządzanie</h3>
                <p>
                  Wszystko w jednym miejscu. Zarządzaj zgłoszeniami i płatnościami z poziomu swojego
                  konta.
                </p>
              </div>
              <div className={css.benefitTile}>
                <img src={Boost} />
                <h3>Buduj zaufanie</h3>
                <p>
                  Otrzymuj recenzje po wydarzeniu. Oceny wystawione przez uczestników znacząco
                  zwiększają zainteresowanie ofertą.
                </p>
              </div>
              <div className={css.benefitTile}>
                <img src={Support} />
                <h3>Zapewniamy wsparcie</h3>
                <p>
                  Masz pytania? My mamy odpowiedzi. Dajemy wsparcie merytoryczne i techniczne, jeśli
                  czegoś potrzebujesz, skontaktuj się z nami.
                </p>
              </div>
            </div>
          </div>
          <div className={css.soSimpleSection}>
            <h2>Zobacz jakie to proste!</h2>
            <div className={css.stepsWrapper}>
              <div className={css.step}>
                <img src={Profile} />
                <h3>Stwórz profil</h3>
                <p>Załóż darmowe konto</p>
              </div>
              <img className={css.arrow} src={Arrow} />
              <div className={css.step}>
                <img src={Event} />
                <h3>Dodaj {promoCompetition.name}</h3>
                <p>Dodaj {promoCompetition.name} w Decathlon GO z pomocą dedykowanego samouczka</p>
              </div>
              <img className={css.arrow} src={Arrow} />
              <div className={css.step}>
                <img src={Share} />
                <h3>Udostępnij</h3>
                <p>Udostępnij {promoCompetition.name} i zaproś pierwszych uczestników.</p>
              </div>
            </div>
          </div>
          <div className={css.promoSection}>
            <div>
              <img src={promoSport.imgSrc} srcSet={promoSport.imgSrcRetina} />
              <div className={css.promoContent}>
                <h2>Promuj {promoCompetition.promoSection}</h2>
                <p>
                  Zwiększ liczbę odbiorców Twoich wydarzeń dzięki Decathlon GO. Proponujemy różne
                  możliwości promowania wydarzeń. Sprawdź i wybierz najlepszą dla Ciebie opcję.
                </p>
              </div>
            </div>
          </div>
          <div className={css.joinSection}>
            <h2>Dołącz do Decathlon GO</h2>
            <p>
              Budujemy społeczność aktywnych fizycznie. Proponujemy wydarzenia sportowe niezależnie
              od stopnia zaawansowania, dla tych, co lubią sportowe aktywności i wyzwania lub chcą
              zacząć sportową przygodę.
            </p>
            <NamedLink className={css.seeMoreBtn} name="LoginPage">
              Dołącz
            </NamedLink>
          </div>
          {/* <div className={css.faqSection}>
            <div>
              <h2>Najczęściej zadawane pytania</h2>
              <Questions />
            </div>
          </div> */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <FooterV2 />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default PartnersPage;
