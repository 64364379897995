import React from "react";
import FormItem from "../../../components/FormItem";
import css from './styles.css';

const EventDescription = ({ register, errors }) => <FormItem label={' Opis oferty'} >
    <textarea placeholder="Dodaj krótki opis oferty" rows={3} className={css.eventTypeContainer} {...register("description", { required: true })} />
    {errors.description && errors.description.type === "required" && (
        <p style={{ color: '#f00' }} role="alert">Opis wydarzenia jest wymagany</p>
    )}
</FormItem>


export default EventDescription;

