import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-warszawa',
    predictionPlace: {
      address: 'Warszawa, Polska',
      bounds: new LatLngBounds(new LatLng(52.47094281, 21.30950476), new LatLng(51.96614075, 20.69015052)),
    },
  },
  {
    id: 'default-gdansk',
    predictionPlace: {
      address: 'Gdańsk, Polska',
      bounds: new LatLngBounds(new LatLng(54.66865618, 18.870883), new LatLng(54.18934769, 18.2515287)),
    },
  },
  // {
  //   id: 'default-lodz',
  //   predictionPlace: {
  //     address: 'Łódź, Polska',
  //     bounds: new LatLngBounds(new LatLng(51.89058047, 19.60394022), new LatLng(51.63559642, 19.29426309)),
  //   },
  // },
  {
    id: 'default-katowice',
    predictionPlace: {
      address: 'Katowice, Polska',
      bounds: new LatLngBounds(new LatLng(50.49238032, 19.28938102), new LatLng(49.92196728, 18.54780387)),
    },
  },
  {
    id: 'default-poznan',
    predictionPlace: {
      address: 'Poznań, Polska',
      bounds: new LatLngBounds(new LatLng(52.63514387, 17.21612005), new LatLng(52.1322209, 16.59676581)),
    },
  },
  {
    id: 'default-krakow',
    predictionPlace: {
      address: 'Kraków, Polska',
      bounds: new LatLngBounds(new LatLng(50.12612589673038, 20.2174975543244), new LatLng(49.96740536805499, 19.79224845563986)),
    },
  },
  {
    id: 'default-bydgoszcz',
    predictionPlace: {
      address: 'Bydgoszcz, Polska',
      bounds: new LatLngBounds(new LatLng(53.20947772325057, 18.20249421862841), new LatLng(53.04189655667319, 17.87387056363903)),
    },
  },
  {
    id: 'default-wroclaw',
    predictionPlace: {
      address: 'Wroclaw, Polska',
      bounds: new LatLngBounds(new LatLng(51.21147357174232, 17.17634783168821), new LatLng(51.04268220041629, 16.8073800205415)),
    },
  },
];
