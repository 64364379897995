import React from 'react';
import {
  FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, EmailShareButton, EmailIcon, LinkedinShareButton, LinkedinIcon
} from 'react-share';
import ReactGA from 'react-ga';

import css from './PostPage.css';

export default function SharingButtons({ sharedUrl, title }) {

  return (
    <div className={css.sharingButtons}>
      <FacebookShareButton 
        url={sharedUrl}
        className={css.shareButton}
        beforeOnClick={()=>ReactGA.event({
          category: 'Social',
          action: 'Clicked Share Article on Facebook',
          label: title
        })}>
        <FacebookIcon size={36} round={true}/>
      </FacebookShareButton>
      <TwitterShareButton 
        url={sharedUrl}
        className={css.shareButton}
        beforeOnClick={()=>ReactGA.event({
          category: 'Social',
          action: 'Clicked Share Article on Twitter',
          label: title
        })}>
        <TwitterIcon
          size={36}
          round={true}
        />
      </TwitterShareButton>
      <LinkedinShareButton 
        url={sharedUrl}
        className={css.shareButton}
        beforeOnClick={()=>ReactGA.event({
          category: 'Social',
          action: 'Clicked Share Article on LinkedIn',
          label: title
        })}>
        <LinkedinIcon size={36} round={true}/>
      </LinkedinShareButton>
      <EmailShareButton 
        url={sharedUrl}
        className={css.shareButton}
        beforeOnClick={()=>ReactGA.event({
          category: 'Social',
          action: 'Clicked Share Article via Email',
          label: title
        })}>
        <EmailIcon size={36} round={true}/>
      </EmailShareButton>
    </div>
  );
}