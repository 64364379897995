import React, { useState, useCallback, useEffect, useRef } from 'react';
import { GoogleMap, InfoWindow } from '@react-google-maps/api';
import { useGoogleMaps } from '../../util/mapContext';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useUpdateQueryParams } from '../../containers/SearchPage/hooks/useUpdateQueryParams';
import { debounce } from 'lodash';

const containerStyle = {
    width: '100%',
    height: '100%'
};

const GoogleSearchMap = ({ markers, initialBounds }) => {

    const [map, setMap] = useState(null);
    const [bounds, setBounds] = useState(initialBounds);
    const [selectedMarker, setSelectedMarker] = useState(null);
    const [clusterMarkers, setClusterMarkers] = useState([]);

    const markerClustererRef = useRef(null);
    const updateQueryParams = useUpdateQueryParams()

    const onLoad = useCallback((map) => {
        setMap(map);
        if (initialBounds) {
            const { northEast, southWest } = initialBounds;
            const bounds = new window.google.maps.LatLngBounds(
                new window.google.maps.LatLng(southWest.lat, southWest.lng),
                new window.google.maps.LatLng(northEast.lat, northEast.lng)
            );
            map.fitBounds(bounds);
        }
    }, [initialBounds]);

    const onUnmount = useCallback((map) => {
        setMap(null);
    }, []);

    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const handleClusterClick = (markers) => {
        setClusterMarkers(markers);
    };

    const { isLoaded, loadError } = useGoogleMaps();

    useEffect(() => {
        if (map && isLoaded) {
            markerClustererRef.current = new MarkerClusterer({
                map,
                markers: [],
                onClusterClick: (event, cluster, map) => {
                    handleClusterClick(cluster.markers);
                }
            });
            markers.forEach(marker => {
                const markerInstance = new window.google.maps.Marker({
                    position: marker.position,
                    label: marker.label,
                    info: marker.info,
                    icon: {
                        url: createHTMLMarker(`
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path d="M14.0993 6.2998C14.9277 6.2998 15.5993 5.62823 15.5993 4.7998C15.5993 3.97138 14.9277 3.2998 14.0993 3.2998C13.2708 3.2998 12.5993 3.97138 12.5993 4.7998C12.5993 5.62823 13.2708 6.2998 14.0993 6.2998Z" fill="white"/>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.5395 7.01959L13.5598 7.00153L16.5471 10.3622L18.927 10.4503L18.8715 11.9493L15.8515 11.8374L13.5317 9.22763L12.5796 12.5966L16.2423 15.3175L16.3491 21.0859L14.8494 21.1137L14.7562 16.0821L11.5916 13.7312C11.3221 13.5716 11.1711 13.3314 11.1031 13.0933C11.0479 12.9001 11.0488 12.7034 11.0492 12.6172C11.0492 12.6108 11.0493 12.605 11.0493 12.5998V12.4959L12.2436 8.26985L9.64928 9.30758V12.1998H8.14928V8.29203L12.0976 6.71269C12.5968 6.48031 13.1103 6.61896 13.4413 6.91985C13.4756 6.95108 13.5084 6.98439 13.5395 7.01959ZM9.80489 16.3822L10.497 14.5365L11.9015 15.0631L10.7937 18.0174L5.40625 17.344L5.5923 15.8556L9.80489 16.3822Z" fill="white"/>
                            </svg>
                            `),
                        scaledSize: new window.google.maps.Size(48, 48),
                        anchor: new window.google.maps.Point(24, 24),
                    }
                });
                markerInstance.addListener('click', () => handleMarkerClick(marker));

                markerClustererRef.current.addMarker(markerInstance);
            });

        }
    }, [map, isLoaded]);

    const onBoundsChanged = useCallback(
        debounce(() => {
            if (map) {
                const bounds = map.getBounds();
                const ne = bounds.getNorthEast();
                const sw = bounds.getSouthWest();
                setBounds({
                    northEast: { lat: ne.lat(), lng: ne.lng() },
                    southWest: { lat: sw.lat(), lng: sw.lng() }
                });
                updateQueryParams('bounds', `${ne.lat()},${ne.lng()},${sw.lat()},${sw.lng()}`);
            }
        }, 1000),
        [map, updateQueryParams]
    );

    useEffect(() => {
        return () => {
            onBoundsChanged.cancel();
        };
    }, [onBoundsChanged]);


    // useEffect(() => {
    //     if (map && initialBounds) {
    //         const { northEast, southWest } = initialBounds;
    //         const bounds = new window.google.maps.LatLngBounds(
    //             new window.google.maps.LatLng(southWest.lat, southWest.lng),
    //             new window.google.maps.LatLng(northEast.lat, northEast.lng)
    //         );
    //         map.fitBounds(bounds);
    //     }
    // }, [map, initialBounds]);

    if (loadError) {
        return <div>Błąd podczas ładowania mapy</div>;
    }

    if (!isLoaded) {
        return <div>Wczytywanie mapy...</div>;
    }

    return (
        <GoogleMap
            mapContainerStyle={containerStyle}
            // zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
            onBoundsChanged={onBoundsChanged}
        >
            {selectedMarker && (
                <InfoWindow
                    position={selectedMarker.position}
                    onCloseClick={() => setSelectedMarker(null)}
                >
                    <div style={{ maxWidth: '300px', maxHeight: '200px' }}>
                        {selectedMarker.info}
                    </div>
                </InfoWindow>
            )}

            {clusterMarkers.length > 0 && (
                <InfoWindow
                    position={clusterMarkers[0].position}
                    onCloseClick={() => setClusterMarkers([])}
                >
                    <SimpleSlider markers={clusterMarkers} />
                </InfoWindow>
            )}
        </GoogleMap>
    );
};


const SimpleSlider = ({ markers }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % markers.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + markers.length) % markers.length);
    };

    return (
        <div style={{ maxWidth: '400px', position: 'relative', overflow: 'hidden' }}>
            <div
                style={{
                    display: 'flex',
                    transition: 'transform 0.5s ease-in-out',
                    transform: `translateX(-${currentIndex * 100}%)`
                }}
            >
                {markers.map((marker, index) => (
                    <div
                        key={index}
                        style={{
                            minWidth: '100%',
                            boxSizing: 'border-box',
                            padding: '10px 30px'
                        }}
                    >
                        {marker.info}
                    </div>
                ))}
            </div>
            <button
                onClick={prevSlide}
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '0',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer'
                }}
            >
                {'<'}
            </button>
            <button
                onClick={nextSlide}
                style={{
                    position: 'absolute',
                    top: '50%',
                    right: '0',
                    transform: 'translateY(-50%)',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    color: 'white',
                    border: 'none',
                    cursor: 'pointer'
                }}
            >
                {'>'}
            </button>
            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                {currentIndex + 1} / {markers.length}
            </div>
        </div>
    );
};


export default GoogleSearchMap;

const createHTMLMarker = (content) => {
    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" style="z-index: 1000;">
        <foreignObject width="100%" height="100%">
          <div xmlns="http://www.w3.org/1999/xhtml" style="display: flex; align-items: center; justify-content: center; width: 100%; height: 100%; border-radius: 50%; background: #3643BA; z-index: 1000;">
            ${content}
          </div>
        </foreignObject>
      </svg>`;
    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svg)}`;
};


