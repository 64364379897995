import React from 'react';
import PropTypes from 'prop-types';

const MesssageIcon = props => {
    const { className } = props;
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
            <path d="M14.2551 11.9131L12.4126 13.7619C11.6654 14.5117 10.3507 14.5279 9.5874 13.7619L7.74477 11.9131L1.12689 18.5525C1.37323 18.6664 1.64484 18.7344 1.93359 18.7344H20.0664C20.3552 18.7344 20.6267 18.6665 20.8729 18.5525L14.2551 11.9131Z" fill="#0082C3" />
            <path d="M20.0664 3.26562H1.93362C1.64487 3.26562 1.37327 3.3336 1.12701 3.44751L8.19868 10.5426C8.19915 10.5431 8.19971 10.5432 8.20019 10.5437C8.20066 10.5441 8.20074 10.5448 8.20074 10.5448L10.5001 12.8518C10.7444 13.096 11.2558 13.096 11.5 12.8518L13.7989 10.5452C13.7989 10.5452 13.7995 10.5441 13.8 10.5437C13.8 10.5437 13.801 10.5431 13.8015 10.5426L20.873 3.44747C20.6267 3.33352 20.3552 3.26562 20.0664 3.26562Z" fill="#0082C3" />
            <path d="M0.205648 4.34894C0.0782031 4.60666 0 4.89275 0 5.1992V16.8008C0 17.1072 0.0781172 17.3933 0.205605 17.651L6.83495 11.0002L0.205648 4.34894Z" fill="#0082C3" />
            <path d="M21.7944 4.34888L15.1651 11.0002L21.7944 17.6511C21.9218 17.3934 22 17.1073 22 16.8008V5.19923C22 4.89269 21.9218 4.6066 21.7944 4.34888Z" fill="#0082C3" />
        </svg>
    );
};

MesssageIcon.defaultProps = { className: null };

const { string } = PropTypes;

MesssageIcon.propTypes = { className: string };

export default MesssageIcon;
