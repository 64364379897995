import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
} from '../../components';
import { StaticPage, TopbarContainer } from '../../containers';
import { withViewport } from '../../util/contextHelpers';
// import { createSearchResultSchema } from './SportLocationPage.helpers';
import FooterV2 from '../../components/Footer/FooterV2';
import css from './CalculatorPage.css';

const products = [
  {
    name: 'DOMYOS',
    desc: 'TOP FITNESS KARDIO',
    price: '69,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_top.jpg',
    url: 'https://www.decathlon.pl/krotki-top-fct-900--id_8402633.html',
  },
  {
    name: 'DOMYOS',
    desc: 'LEGGINSY FITNESS KARDIO',
    price: '49,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_legginsy.jpg',
    url: 'https://www.decathlon.pl/legginsy-fti-100-czarne-id_8554673.html',
  },
  {
    name: 'DOMYOS',
    desc: 'MATA TONEMAT',
    price: '29,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_mata.jpg',
    url: 'https://www.decathlon.pl/tonemat-s-id_8573748.html',
  },
  {
    name: 'DOMYOS',
    desc: 'TAŚMA ELASTIBAND',
    price: '24,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_tasma.jpg',
    url: 'https://www.decathlon.pl/tama-elastiband-100-light-id_8527896.html',
  },
  {
    name: 'DOMYOS',
    desc: 'KOSZULKA FITNESS CARDIO',
    price: '29,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_koszulka.jpg',
    url: 'https://www.decathlon.pl/koszulka-fts-120-id_8549874.html',
  },
  {
    name: 'DOMYOS',
    desc: 'SPODENKI FITNESS KARDIO',
    price: '24,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_spodenki.jpg',
    url: 'https://www.decathlon.pl/spodenki-fst-120-id_8510183.html',
  },
  {
    name: 'NYAMBA',
    desc: 'OBCIĄŻENIA NA NADGARSTKI I KOSTKI',
    price: '34,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_obciazniki.jpg',
    url:
      'https://www.decathlon.pl/obcienie-na-nadgarstki-i-kostki-gymweight-2-x-05-kg-id_8358970.html',
  },
  {
    name: 'DOMYOS',
    desc: 'UCHWYTY PUSH UP BARS',
    price: '19,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_pushups.jpg',
    url: 'https://www.decathlon.pl/uchwyty-push-up-bars-id_8375469.html',
  },
  {
    name: 'NYAMBA',
    desc: 'ZESTAW MINITAŚM X 3',
    price: '19,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_zestaw_tasm.jpg',
    url: 'https://www.decathlon.pl/mini-bandy-tamy-do-pilatesu-x3-id_8528803.html',
  },
  {
    name: 'DOMYOS',
    desc: 'MATA YOGA 4MM',
    price: '39,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_mata4mm.jpg',
    url: 'https://www.decathlon.pl/mata-yoga-4mm-id_8506526.html',
  },
  {
    name: 'DOMYOS',
    desc: 'Koszulka do fitnessu',
    price: '16,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/mytop.jpg',
    url: 'https://www.decathlon.pl/top-do-fitnessu-damski-id_8125767.html',
  },
  {
    name: 'DOMYOS',
    desc: 'Skakanka',
    price: '19,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/skakanka.jpg',
    url: 'https://www.decathlon.pl/skakanka-fitness-500-id_8556907.html',
  },
  {
    name: 'DOMYOS',
    desc: 'Buty do fitnessu',
    price: '49,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/butdomyos.jpg',
    url: 'https://www.decathlon.pl/buty-100--id_8379422.html',
  },
  {
    name: 'DOMYOS',
    desc: 'Buty fitness 900',
    price: '149,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/f_buty_900.jpg',
    url: 'https://www.decathlon.pl/buty-fitness-cardio-900-id_8405066.html',
  },
  {
    name: 'DOMYOS',
    desc: 'Legginsy do fitnessu',
    price: '79,99',
    imgUrl: 'https://kgcodego.b-cdn.net/products/legginsy500.jpg',
    url: 'https://www.decathlon.pl/legginsy-fti-500a-id_8547528.html',
  },
];

const MAX_MOBILE_SCREEN_WIDTH = 768;
const MAX_TABLET_SCREEN_WIDTH = 1024;

export class SportLocationPageComponent extends Component {
  render() {
    const { scrollingDisabled, calculator, viewport } = this.props;

    // const schema = createSearchResultSchema(listings, city, intl);
    const {
      articles,
      calculatorComponent,
      header,
      lead,
      mainArticle,
      mainImg,
      metaTitle,
      metaDescription,
    } = calculator;

    const isMobileShow = viewport.width < MAX_MOBILE_SCREEN_WIDTH ? 1 : null;
    const isTabletShow =
      viewport.width < MAX_TABLET_SCREEN_WIDTH && viewport.width >= MAX_MOBILE_SCREEN_WIDTH
        ? 3
        : null;

    const slidesToShow = isMobileShow || isTabletShow || 4;

    return (
      <StaticPage
        scrollingDisabled={scrollingDisabled}
        description={metaDescription}
        title={metaTitle}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'MedicalRiskCalculator',
          description: metaDescription,
          name: metaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <div className={css.mainBannerContainer} style={{ backgroundImage: `url(${mainImg})` }}>
              <div className={css.mask} />
              <TopbarContainer
                atHomePage={true}
                className={css.topbarBg}
                currentPage="SportLocationPage"
              />
              <div className={css.mainBannerWrapper}>
                <h1 className={css.mainTitle}>{header}</h1>
                <div className={css.mainTitleDesc}>{lead}</div>
              </div>
            </div>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.containerPanel}>
              <div className={css.calculatorSection}>
                <div>
                  <h2 className={css.paragraphHeader}>{mainArticle.title}</h2>
                  <p className={css.paragraph}>{mainArticle.text}</p>
                </div>
                {calculatorComponent}
              </div>
              {/* <div className={css.externalProducts}>
                <SectionExternalProducts
                  category="Calculator Page"
                  products={products}
                  sport="fitness"
                  urlCampaign="calculators_page"
                  slidesToShow={slidesToShow}
                />
              </div> */}
              <div className={css.textSection}>
                {articles.map((article, i) => (
                  <div key={i}>
                    <h2 className={css.paragraphHeader}>{article.title}</h2>
                    {article.text.map((paragraph, i) => (
                      <p key={i} className={css.paragraph}>
                        {paragraph}
                      </p>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </StaticPage>
    );
    /* eslint-enable  */
  }
}

const SportLocationPage = compose(withRouter, withViewport, injectIntl)(SportLocationPageComponent);

export default SportLocationPage;
