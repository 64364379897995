import React, { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { ReactComponent as CheckIcon } from '../../assets/addEventForm/check.svg';

import css from './styles.css';

export const CustomCheckbox = ({ value, onChange, label }) => {
  return (
    <label className={css.checkboxLabel}>
      <div
        className={css.checkboxContainer}
        // className={`w-5 h-5 ${value ? 'bg-[#3643BA]' : 'bg-white border border-black'} mr-2 flex items-center justify-center cursor-pointer`}
        style={
          value
            ? { background: '#3643BA' }
            : { background: 'white', borderRadius: '4px', border: 'solid 1px #949494' }
        }
        onChange={onChange}
        role="checkbox"
      >
        {value && <CheckIcon />}
      </div>
      <input className={css.checkboxInput} onChange={() => onChange(!value)} type="checkbox" />
      {label}
    </label>
  );
};

const CustomCheckboxFormElement = ({ control, name, label }) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <CustomCheckbox
            value={field.value}
            onChange={() => {
              field.onChange(!field.value);
            }}
            label={label}
          />
        );
      }}
    />
  );
};

export const CustomCheckboxGroup = ({ control, name, options, errors, setError, clearErrors }) => {
  const checkboxes = useWatch({
    control,
    name,
  });

  useEffect(() => {
    if (!checkboxes || Object.values(checkboxes).every(value => !value)) {
      setError(name, {
        type: 'manual',
        message: 'Wybierz przynajmniej jedną opcję',
      });
    } else {
      clearErrors(name);
    }
  }, [checkboxes]);

  return (
    <label
      style={{
        margin: '10px 0',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        fontWeight: '600',
      }}
    >
      <p>
        {name}
        <sup style={{ color: 'red' }}>*</sup>
      </p>
      {options.map(option => (
        <CustomCheckboxFormElement
          key={option}
          label={option}
          control={control}
          name={`${name.split(',').join('')}.${option.split(',').join('')}`}
        />
      ))}
      {!!errors[name] && <p style={{ color: 'red' }}>{errors[name].message}</p>}
    </label>
  );
};

export default CustomCheckboxFormElement;
