import React from 'react';
import { required } from '../../util/validators';
import { Field } from 'react-final-form';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import pl from 'date-fns/locale/pl';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import css from './EditDateTimeField.css';
registerLocale('pl', pl)

const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
  <input
    onClick={onClick}
    value={value}
    readOnly
    ref={ref}
  />
));

const sixMonthsFromNow = new Date();
sixMonthsFromNow.setMonth(sixMonthsFromNow.getMonth() + 6);

const oneMinuteFromNow = new Date();
oneMinuteFromNow.setMinutes(oneMinuteFromNow.getMinutes() + 1); // Dodanie jednej minuty do aktualnego czasu


const filterTime = time => {
  return time.getMinutes() % 5 === 0;
};

const DateTimeField = props => {
  const { name, id, intl, label } = props;
  // const sportLabel = intl.formatMessage({
  //   id: 'SportSelectField.sportLabel',
  // });
  // const sportPlaceholder = intl.formatMessage({
  //   id: 'SportSelectField.sportPlaceholder',
  // });
  // const sportRequired = required(
  //   intl.formatMessage({
  //     id: 'SportSelectField.sportRequired',
  //   })
  // );

  return (
    <div className={css.dateTimeField}>
      <label>{label}</label>
      <DatePicker
        selected={props.date ? new Date(props.date) : new Date()}
        onChange={(x, event) => {
          props.handleChange(x)
        }}
        locale="pl"
        showTimeSelect
        timeCaption="Czas"
        timeFormat="HH:mm"
        timeIntervals={15}
        filterTime={filterTime}
        dateFormat="d MMMM yyyy, HH:mm"
        className={css.dateTimeInput}
        // minDate={new Date()}
        minDate={oneMinuteFromNow}
        shouldCloseOnSelect={true}
        maxDate={sixMonthsFromNow}
        // readOnly={true}
        customInput={<CustomInput />}
      />
    </div>
  );
};

const DateTimeInput = props => {
  return <Field component={DateTimeField} {...props} />;
};

export default DateTimeInput;
