import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import {
  default as facebookImage,
  default as twitterImage,
} from '../../assets/sporteoFacebook.png';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import FooterV2 from '../../components/Footer/FooterV2';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import { sportsConfig } from '../../sportsConfig';
import { withViewport } from '../../util/contextHelpers';
import { removePolishCharacters } from '../SearchPage/utils';
import SimpleSearchForm from './SimpleSearchForm';
import {
  BecomePartner,
  CityEvents,
  DiscoverEvents,
  PopularActivities,
  PopularEvents,
  Reasons,
  RecommendedEvents,
} from './sections';

import css from './LandingPage.css';

export class LandingPageComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      history,
      intl,
      location,
      scrollingDisabled,
      onManageDisableScrolling,
      viewport,
    } = this.props;
    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    const handleSearchSubmit = values => {
      const { search, selectedPlace } = values.location || {};
      const { sport, eventTypes, dates, startDate, endDate } = values;

      const { history } = this.props;
      const { origin, bounds } = selectedPlace || {};
      const originMaybe = config.sortSearchByDistance ? { origin } : {};
      const pub_sport = sport && sport.length ? { pub_sport: sport } : {};
      const pub_event_type =
        eventTypes && eventTypes !== 'all' ? { pub_event_type: eventTypes } : {};
      const pub_date =
        dates && Object.keys(dates).length
          ? {
              pub_date: `${new Date(dates.startDate).toISOString()},${new Date(
                dates.endDate
              ).toISOString()}`,
            }
          : {};

      // const searchParams = {
      //   // ...originMaybe,
      //   // address: search,
      //   bounds,
      //   // ...pub_sport,
      //   // ...pub_date,
      //   start: new Date(startDate).getTime(),
      //   end: new Date(endDate).getTime(),
      //   ...pub_event_type,
      // };

      if (!selectedPlace) {
        const start = startDate ? `start=${new Date(startDate).toISOString()}&` : '';
        const end = endDate ? `end=${new Date(endDate).toISOString()}&` : '';

        const url = `/s/wydarzenia-sportowe/${
          !!sport.length
            ? removePolishCharacters(sportsConfig[sport].name.toLowerCase())
            : 'wszystkie-sporty'
        }/cala-polska?bounds=55.98904748%2C24.05383751%2C47.89042442%2C14.14416954&${start}${end}${
          eventTypes && eventTypes !== 'all' ? 'pub_event_type=' + eventTypes + '&' : ''
        }`;
        history.push(url);
      } else {
        const regex = /\d/;
        const city = !regex.test(selectedPlace.address.split(',')[0])
          ? removePolishCharacters(selectedPlace.address.split(',')[0].toLowerCase())
          : 'twoja-okolica';
        const bounds = `${selectedPlace.bounds.ne.lat},${selectedPlace.bounds.ne.lng},${selectedPlace.bounds.sw.lat},${selectedPlace.bounds.sw.lng}`;
        history.push(
          `/s/wydarzenia-sportowe/${
            !!sport.length
              ? removePolishCharacters(sportsConfig[sport].name.toLowerCase())
              : 'wszystkie-sporty'
          }/${city}/?bounds=${bounds}&${
            startDate ? 'start=' + new Date(startDate).toISOString() + '&' : ''
          }${endDate ? 'end=' + new Date(endDate).toISOString() + '&' : ''}${
            eventTypes && eventTypes !== 'all' ? 'pub_event_type=' + eventTypes + '&' : ''
          }mapSearch=true`
        );

        // history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
      }
    };

    return (
      <Page
        className={css.root}
        scrollingDisabled={scrollingDisabled}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <div className={css.mainBannerContainer}>
              <TopbarContainer />

              <div className={css.mainImageBackground}>
                <div className={css.mainBanner}>
                  <div className={css.mainBannerWrapper}>
                    <h1 className={css.mainTitle}>Zapisz się na sportowe wydarzenia</h1>
                    <div className={css.mainBtnsContainer}>
                      <div className={css.mainBtnsContainer}>
                        <SimpleSearchForm
                          form="TopbarSearchFormDesktop"
                          onSubmit={handleSearchSubmit}
                          initialValues={{ eventTypes: 'all', sport: [] }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <RecommendedEvents />
            <CityEvents />
            <PopularEvents />
            <DiscoverEvents />
            <PopularActivities />
            <Reasons />
            <BecomePartner />
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

const { bool, func, number, object, shape } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  onManageDisableScrolling: func.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});
// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(LandingPageComponent);

export default LandingPage;
