import React from 'react';
import Step from './Step';
import css from './HowToPage.css';

export default function StepsSection({ header, stepId, steps, mainImg, mainImgBig }) {
  const leftColumn = Math.ceil(steps.length / 2);

  return (
    <div className={css.stepsSection} id={stepId}>
      <h2>{header}</h2>
      <div className={css.stepsWrapper}>
        <div>
          { steps.slice(0, leftColumn).map(step => <Step step={step} key={step.header} />)}
        </div>
        <img src={mainImg} srcSet={`${mainImg}, ${mainImgBig} 2x`} alt={header}/>
        <div>
          { steps.slice(leftColumn, steps.length).map(step => <Step step={step} key={step.header} />)}
        </div>
      </div>
    </div>
  );
}