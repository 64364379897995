import React, { useState } from 'react';
import { Formik } from 'formik';
import classNames from 'classnames';

import { NamedLink } from '../../components';

import css from './CalculatorPage.css';

const BMR = () => {
  const [bmrMifflin, setBmrMifflin] = useState(null);
  const [bmrHarris, setBmrHarris] = useState(null);
  const [bmrKatch, setBmrKatch] = useState(null);

  return (
    <div className={css.calculator}>
      <Formik
        initialValues={{ height: '', weight: '', age: '', sex: '' }}
        onSubmit={(values) => {
          const { height, weight, age, sex } = values;

          const bmrMifflin = sex === 'male' ? Math.round((10 * weight + 6.25 * height - 5 * age + 5) * 100) / 100 : Math.round((10 * weight + 6.25 * height - 5 * age - 161) * 100) / 100;

          const bmrHarris = sex === 'male' ? Math.round((13.397 * weight + 4.799 * height - 5.677 * age + 88.362) * 100) / 100 : Math.round((9.247 * weight + 3.098 * height - 4.330 * age + 447.593) * 100) / 100;

          const lbmBoer = sex === 'male' ? 0.407 * weight + 0.267 * height - 19.2 : 0.252 * weight + 0.473 * height - 48.3;
          const bmrKatch = Math.round((370 + (21.6 * lbmBoer)) * 100) / 100;

          setBmrMifflin(bmrMifflin);
          setBmrHarris(bmrHarris);
          setBmrKatch(bmrKatch);    
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>

            <div className={css.label} htmlFor="sex">Płeć</div>
            <div className={css.radioWrapper}>
              <span className={css.radioContainer}>
                <input
                  className={css.radioInput}
                  type="radio"
                  id="female"
                  name="sex"
                  value="female"
                  onChange={handleChange}
                />
                <label htmlFor="female" className={css.radioLabel}>Kobieta</label>
              </span>
              <span className={css.radioContainer}>
                <input
                  className={css.radioInput}
                  type="radio"
                  id="male"
                  name="sex"
                  value="male"
                  onChange={handleChange}
                />
                <label htmlFor="male" className={css.radioLabel}>Mężczyzna</label>
              </span>
            </div>

            <label className={css.label} htmlFor="height">Wzrost</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="height"
                name="height"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.height}
              />
              <span className={css.inputIcon}>CM</span>
            </div>
            
            <label className={css.label} htmlFor="weight">Waga</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="weight"
                name="weight"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wartość"
                type="number"
                value={values.weight}
              />
              <span className={css.inputIcon}>KG</span>
            </div>
            <label className={css.label} htmlFor="age">Wiek</label>
            <div className={css.inputContainer}>
              <input
                className={css.input}
                id="age"
                name="age"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Podaj wiek"
                type="number"
                value={values.age}
              />
            </div>
            <div className={css.btnContainer}>
              <button className={css.submitCalcBtn} type="submit" disabled={!values.height || !values.weight || !values.age || !values.sex}>
                Oblicz
              </button>
            </div>
          </form>
        )}
      </Formik>
      <div className={css.resultsContainer}>
        <div className={css.calcResults}>
          <h2 className={css.paragraphHeader}>Twoja podstawowa przemiana materii wynosi</h2>
          <span className={classNames(css.resultNumber, css.bmrFactor)}>{bmrMifflin ? `${bmrMifflin} kcal` : '-'}</span>
          <p>wg. Mifflin-St. Jeor</p>
          <span className={classNames(css.resultNumber, css.bmrFactor)}>{bmrHarris ? `${bmrHarris} kcal` : '-'}</span>
          <p>wg. Harris-Benedict</p>
          <span className={classNames(css.resultNumber, css.bmrFactor)}>{bmrKatch ? `${bmrKatch} kcal` : '-'}</span>
          <p>wg. Katch-McArdle z oszacowaniem beztłuszczowej masy ciała metoda Boer'a</p>
        </div>
        <div className={css.otherCalculators}>
          <span>Sprawdź inne kalkulatory: </span>
          <NamedLink name="BMICalculator">
            BMI
          </NamedLink>
          <NamedLink name="RFMCalculator">
            RFM
          </NamedLink>
          <NamedLink name="WHRCalculator">
            WHR
          </NamedLink>
        </div>
      </div>
    </div>
  )
}

export default BMR;
