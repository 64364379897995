import React, { Component } from 'react';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
} from '../../components';
import config from '../../config';
import { TopbarContainer } from '../../containers';
import Onboarding from './Onboarding';

import FooterV2 from '../../components/Footer/FooterV2';
import css from './AuthenticationPage.css';

export class OnboardingPage extends Component {
  render() {
    const { intl, scrollingDisabled } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = siteTitle;

    return (
      <Page
        title={schemaTitle}
        scrollingDisabled={scrollingDisabled}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          name: schemaTitle,
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.layoutWrapperMain}>
            <div className={css.root}>
              <Onboarding />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <FooterV2 />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

export default OnboardingPage;
