import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { compose } from 'redux';
import {
  IconSpinner,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  NotificationBadge,
  Page,
  PaginationLinks,
  TabNav,
} from '../../components';
import { Tab } from '../../components/TabNav/TabNav';
import config from '../../config';
import { ManageListingsPage, TopbarContainer } from '../../containers';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';

import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import FooterV2 from '../../components/Footer/FooterV2';
import IconArrowForm from '../../components/iconArrowForm/iconArrowForm';
import { apiSdk } from '../../queries/api';
import InboxItem from './InboxItem';
import css from './InboxPage.css';
import MessagesView from './MessagesView';

const { arrayOf, bool, number, shape, string } = PropTypes;

const MobileNav = React.memo(({ tabs }) => {
  const [selectedTab, setSelectedTab] = useState(() => tabs.filter(tab => !!tab.selected)[0]);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
        <span onClick={() => setIsOpen(prev => !prev)}>
          {tabs.filter(tab => !!tab.selected)[0].text}
          <IconArrowForm />
        </span>
      </div>
      {isOpen &&
        tabs
          .filter(tab => tab.linkProps.params.tab !== selectedTab.linkProps.params.tab)
          .map((tab, index) => {
            const id = typeof tab.id === 'string' ? tab.id : `${index}`;
            return (
              <div
                onClick={e => {
                  e.stopPropagation();
                  setSelectedTab(tab);
                  setIsOpen(false);
                }}
              >
                <Tab key={id} id={id} {...tab} />
              </div>
            );
          })}
    </div>
  );
});

// processState?: 'accepted' | 'pending-payment' | 'payment-expired' | 'cancelled' | 'delivered' | 'reviewed' | 'sent'
// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';
  // console.log('tx.attributes.processState', tx.attributes.processState)
  // if (txIsEnquired(tx)) {
  //   return {
  //     nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
  //     bookingClassName: css.bookingActionNeeded,
  //     lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
  //     stateClassName: css.stateActionNeeded,
  //     state: intl.formatMessage({
  //       id: 'InboxPage.stateEnquiry',
  //     }),
  //   };
  // } else
  // if (txIsRequested(tx)) {
  //   const isAccepted = tx.booking.attributes.state === 'accepted';
  //   const requested = isOrder
  //     ? {
  //       nameClassName: css.nameNotEmphasized,
  //       bookingClassName: css.bookingNoActionNeeded,
  //       lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
  //       stateClassName: isAccepted ? css.stateSucces : css.stateActionNeededRequested,
  //       state: intl.formatMessage({
  //         id: isAccepted ? 'InboxPage.stateAccepted' : 'InboxPage.stateRequested',
  //       }),
  //     }
  //     : {
  //       nameClassName: isAccepted ? css.nameNotEmphasized : css.nameEmphasized,
  //       bookingClassName: isAccepted ? css.bookingNoActionNeeded : css.bookingActionNeeded,
  //       lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
  //       stateClassName: isAccepted ? css.stateSucces : css.stateActionNeededRequested,
  //       state: intl.formatMessage({
  //         id: isAccepted ? 'InboxPage.stateAccepted' : 'InboxPage.statePending',
  //       }),
  //     };

  //   return requested;
  // } else
  if (tx.attributes.processState === 'pending-payment') {
    return {
      nameClassName: isOrder ? css.nameNotEmphasized : css.nameEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.statePendingPayment',
      }),
    };
  } else if (tx.attributes.processState === 'payment-expired') {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateExpired',
      }),
    };
    // } else if () {
    //   return {
    //     nameClassName: css.nameNotEmphasized,
    //     bookingClassName: css.bookingNoActionNeeded,
    //     lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
    //     stateClassName: css.stateNoActionNeeded,
    //     state: intl.formatMessage({
    //       id: 'InboxPage.stateDeclined',
    //     }),
    //   };
  } else if (tx.attributes.processState === 'accepted') {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else if (tx.attributes.processState === 'cancelled') {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCanceled',
      }),
    };
  } else if (tx.attributes.processState === 'delivered') {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else if (tx.attributes.processState === 'reviewed') {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDelivered',
      }),
    };
  } else if (tx.attributes.processState === 'sent') {
    return {
      nameClassName: css.nameNotEmphasized,
      bookingClassName: css.bookingNoActionNeeded,
      lastTransitionedAtClassName: css.lastTransitionedAtNotEmphasized,
      stateClassName: css.stateSucces,
      state: intl.formatMessage({
        id: 'InboxPage.stateAccepted',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

export const InboxPageComponent = props => {
  const {
    unitType,
    // currentUser,
    // fetchInProgress,
    // fetchOrdersOrSalesError,
    intl,
    params,
    providerNotificationCount,
    scrollingDisabled,
    // transactions,
    // onFetchMessages,
    // messages,
    viewport,
  } = props;

  const { search } = useLocation();

  const { tab } = params;

  const searchParams = new URLSearchParams(search);
  const page = Number(searchParams.get('page')) || 1;
  const [totalPages, setTotalPages] = useState(1);

  const per_page = 10;

  const { data: fetchedUSerData, isSuccess: isSuccesUserData } = useQuery('user.current', () =>
    apiSdk.users.currentUser()
  );

  const currentUserId = isSuccesUserData ? fetchedUSerData.data.id.uuid : null;

  const { data: transactions, isSuccess, isFetching, isError } = useQuery(
    ['transactions.query', page, per_page],
    () =>
      apiSdk.transactions.query({
        // providerId: currentUserId,
        customerId: currentUserId,
        include: ['provider', 'customer', 'listing', 'chat', 'date'],
        page,
        per_page,
      }),
    {
      enabled: !!currentUserId,
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    if (isSuccess) {
      setTotalPages(transactions.meta.totalPages);
    }
  }, [isSuccess]);

  const isOrders = tab === 'orders';
  const isMessages = tab === 'messages';

  const ordersTitle = intl.formatMessage({ id: 'InboxPage.ordersTitle' });
  const salesTitle = intl.formatMessage({ id: 'InboxPage.salesTitle' });
  const title = isOrders ? ordersTitle : salesTitle;

  const toTxItem = tx => {
    const type = isOrders ? 'order' : 'sale';
    const providerData = transactions.included.filter(
      item => item.type === 'user' && item.id.uuid === tx.relationships.provider.data.id.uuid
    )[0];
    const customerData = transactions.included.filter(
      item => item.type === 'user' && item.id.uuid === tx.relationships.customer.data.id.uuid
    )[0];
    const listingData = transactions.included.filter(
      item => item.type === 'listing' && item.id.uuid === tx.relationships.listing.data.id.uuid
    )[0];
    const eventDate =
      tx.relationships.date.data &&
      transactions.included.filter(
        item => item.type === 'listing-date' && item.id.uuid === tx.relationships.date.data.id.uuid
      )[0];

    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem
          unitType={unitType}
          type={type}
          tx={{
            ...tx,
            customer: customerData,
            provider: providerData,
            listing: listingData,
            date: eventDate,
          }}
          intl={intl}
          stateData={stateData}
        />
      </li>
    ) : null;
  };

  const error = isError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    isSuccess && transactions.length === 0 ? (
      <li key="noResults" className={css.noResults}>
        {isOrders && <FormattedMessage id={'InboxPage.noOrdersFound'} />}
      </li>
    ) : null;

  const pagination = {
    page,
    totalPages,
  };

  const pagingLinks =
    isSuccess && transactions.data.length > 0 && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const providerNotificationBadge =
    providerNotificationCount > 0 ? <NotificationBadge count={providerNotificationCount} /> : null;

  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.ordersTabTitle" />
        </span>
      ),
      selected: isOrders && !isMessages,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'orders' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.salesTabTitle" />
          {providerNotificationBadge}
        </span>
      ),
      selected: !isOrders && !isMessages,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'sales' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.messagesTabTitle" />
          {/* {providerNotificationBadge} */}
        </span>
      ),
      selected: isMessages,
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'messages' },
      },
    },
  ];

  const MAX_MOBILE_SCREEN_WIDTH = 768;
  const isMobileLayout = viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const nav = isMobileLayout ? (
    <MobileNav tabs={tabs} />
  ) : (
    <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />
  );

  let disaplyedList = null;
  switch (tab) {
    case 'orders':
      disaplyedList = isSuccess ? transactions.data.map(toTxItem) : null;
      break;
    case 'sales':
      disaplyedList = <ManageListingsPage authorId={currentUserId} />;
      break;
    case 'messages':
      disaplyedList = <MessagesView currentUser={fetchedUSerData} />;

      break;
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>
          <h1 className={css.title}>{/* <FormattedMessage id="InboxPage.title" /> */}</h1>
          {nav}
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          {error}
          <ul className={css.itemList}>
            {!isFetching ? (
              disaplyedList
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {noResults}
          </ul>
          {tab !== 'sales' && tab !== 'messages' && pagingLinks}
          {/* {pagingLinks} */}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <FooterV2 />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.bookingUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.bookingUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

// const mapStateToProps = state => {
//   const { fetchInProgress, messages, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
//   const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
//   return {
//     currentUser,
//     messages,
//     fetchInProgress,
//     fetchOrdersOrSalesError,
//     pagination,
//     providerNotificationCount,
//     scrollingDisabled: isScrollingDisabled(state),
//     transactions: getMarketplaceEntities(state, transactionRefs),
//   };
// };

// const mapDispatchToProps = (dispatch) => ({
//   onFetchMessages: (userId) =>
//     dispatch(fetchMessages(userId)),
// });

const InboxPage = compose(
  withViewport,
  // connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(InboxPageComponent);

// InboxPage.loadData = loadData;

export default InboxPage;
