import React from "react";
import FormItem from "../../../components/FormItem";
import css from './styles.css'

const EventType = ({ register, errors }) => <FormItem label={'Rodzaj wydarzenia'}>
    <select defaultValue={""} className={`${css.eventTypeContainer} ${css.select}`} {...register("eventType", { required: true })} >
        <option value={""} disabled >wybierz rodzaj wydarzenia</option>
        {['Zajęcia', 'Treningi', 'Zawody i turnieje', 'Konkursy', 'Mecze', 'Inne']
            .map((eventType, index) => <option value={eventType} key={index}>
                {eventType}
            </option>)}
    </select>
    {errors.eventType && errors.eventType.type === "required" && (
        <p style={{ color: '#f00' }} role="alert">Rodzaj wydarzenia jest wymagany</p>
    )}
</FormItem>

export default EventType;
