import React from "react";
import css from '../styles.css';

const InfoItem = ({ icon, title, description }) => {
    return (
        <div className={css.infoItem}>
            {icon}
            <div>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
        </div>
    );
}

export default InfoItem;